import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import ReactTable, { ReactTableDefaults } from "react-table";
import { Button, Icon } from "semantic-ui-react";

import { Can } from "../";

import ACTIONS from "./actions";
import Context from "./context";
import KeyLockerMechanicQRCodeModal from "./KeyLockerMechanicQRCodeModal";

class KeyLockerTable extends Component {
  static contextType = Context;

  state = { showMechanicKey: null };

  render() {
    const { t } = this.props;
    const { showMechanicKey } = this.state;
    const { controller, keyLockers, action, isLoading, selectedKeyLockerIndex } = this.context;
    const { deactivateKeyLocker, activateKeyLocker, getKeyLockerBoxes, setAction } = controller;

    if (isLoading && action === ACTIONS.LOAD_KEYLOCKERS) {
      return (
        <div className="Table__loading Loader-Placeholder">
          <div className="bounce1"></div>
          <div className="bounce2"></div>
          <div className="bounce3"></div>
          <section>{t("loading_key_lockers").message || "Loading Key Lockers"}</section>
        </div>
      );
    }

    return (
      <div className="KeyLockersTable">
        <KeyLockerMechanicQRCodeModal mechanicKey={showMechanicKey} onClose={() => this.setState({ showMechanicKey: null })} />
        <ReactTable
          className="ReactTable -floated-table -contained-large"
          data={keyLockers}
          showPagination={false}
          showPageSizeOptions={false}
          sortable={false}
          resizable={false}
          defaultPageSize={keyLockers.length}
          pageSize={keyLockers.length}
          noDataText={
            <div className="Table__no-results">
              <Icon disabled name="key" size="big" />
              <p>{t("no_key_lockers").message || "No Key Lockers"}</p>
            </div>
          }
          column={{
            ...ReactTableDefaults.column,
            headerClassName: "ReactTable__column-header",
            className: "ReactTable__column",
          }}
          columns={[
            {
              id: "index",
              Cell: row => {
                return (
                  <div className="key-lockers-table-index" style={{ backgroundColor: row.original.active ? "green" : "orange" }}>
                    {row.index + 1}
                  </div>
                );
              },
              maxWidth: 70,
            },
            {
              id: "name",
              Header: t("name").message || "Name",
              accessor: "name",
            },
            {
              id: "lockers",
              Header: t("lockers_active_total").message || "Lockers (active / total)",
              Cell: row => (
                <span>
                  {row.original.active_box_count} / {row.original.box_count}
                </span>
              ),
            },
            {
              id: "keys",
              Header: t("keys").message || "Keys",
              accessor: "key_count",
            },
            {
              id: "version",
              Header: t("version").message || "Version",
              accessor: "frontend_version",
            },
            {
              id: "2fa_to_drop_key_enabled",
              Header: t("2fa_to_drop_key").message || "2FA to drop key",
              accessor: d => (
                <div className="key-locker-2fa-column">{d.is_2fa_to_drop_key_enabled ? <Icon name="check" color="green" /> : <Icon name="close" color="red" />}</div>
              ),
              width: 100,
              maxWidth: 100,
            },
            {
              id: "actions",
              Cell: row => {
                const isActiveRow = selectedKeyLockerIndex === row.index;
                return (
                  <div className="key-locker-actions-container">
                    {row.original.active && (
                      <Can I="deactivate" the="keylockers">
                        <Button
                          size="mini"
                          icon="pause"
                          color="orange"
                          disabled={row.original.key_count > 0 || isLoading}
                          loading={isLoading && action === ACTIONS.DEACTIVATE_KEYLOCKER && isActiveRow}
                          onClick={() => deactivateKeyLocker(row.index)}
                        />
                      </Can>
                    )}
                    {!row.original.active && (
                      <Can I="activate" the="keylockers">
                        <Button
                          size="mini"
                          icon="play"
                          color="green"
                          disabled={isLoading}
                          loading={isLoading && action === ACTIONS.ACTIVATE_KEYLOCKER && isActiveRow}
                          onClick={() => activateKeyLocker(row.index)}
                        />
                      </Can>
                    )}
                    <Can I="detail" the="keylockers">
                      <Button
                        size="mini"
                        icon="list"
                        color="purple"
                        disabled={isLoading || row.original.box_count < 1}
                        loading={isLoading && action === ACTIONS.GET_KEYLOCKER_DETAILS && isActiveRow}
                        onClick={() => getKeyLockerBoxes(row.index)}
                      />
                    </Can>
                    <Can I="update" the="keylockers">
                      <Button size="mini" icon="edit" color="blue" disabled={isLoading} onClick={() => setAction(ACTIONS.EDIT_MODAL_SHOW, row.index)} />
                    </Can>
                    <Can I="delete" the="keylockers">
                      <Button
                        size="mini"
                        icon="trash"
                        color="red"
                        disabled={isLoading}
                        loading={isLoading && action === ACTIONS.DELETE_KEYLOCKER && isActiveRow}
                        onClick={() => setAction(ACTIONS.CONFIRM_DELETE_KEYLOCKER, row.index)}
                      />
                    </Can>
                  </div>
                );
              },
            },
          ]}
        />
      </div>
    );
  }
}

export default withTranslation()(KeyLockerTable);
