import React from "react";
import { Button } from "semantic-ui-react";
import ReactTable, { ReactTableDefaults } from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport } from "@fortawesome/pro-regular-svg-icons";
import { saveAs } from "file-saver";
import moment from "moment";

const RecurringCars = ({ report, t }) => {
  if (!report.report_data) {
    return (
      <>
        <h3 className="report-heading">{t(report.report_name).message || report.report_name}</h3>
        <p className="no-data">{t("no_data").message || "No data"}</p>
      </>
    );
  }

  const data = report.report_data.map(d => JSON.parse(d));

  const exportData = async () => {
    let csv = "";
    let header = (t("location").message || "Location") + ", ";
    header += (t("total_cars").message || "Total cars") + ", ";
    header += (t("recurring_cars").message || "Recurring cars") + ", ";
    header += (t("percent_recurring_cars").message || "% of recurring cars") + ", ";
    header += t("recurring_cars_not_worked_on").message || "Recurring cars not worked on";

    let values = data
      .map(d => d.location + ", " + d.total_cars + ", " + d.recurring_cars + ", " + d.percent_recurring_cars + ", " + d.recurring_cars_not_worked_on)
      .join("\n");

    csv += header + "\n" + values;
    saveAs(new File([csv], { type: "text/csv;charset=utf-8" }), "RecurringCars_" + moment().format("YYYYMMDD") + ".csv");
  };

  return (
    <>
      <h3 className="report-heading">
        {t(report.report_name).message || report.report_name}
        <Button color="green" floated="right" basic onClick={() => exportData()}>
          <span style={{ marginRight: "10px" }}>{t("export").message || "Export"}</span>
          <FontAwesomeIcon icon={faFileExport} />
        </Button>
      </h3>
      <div className="dealer-reports-table-container">
        <ReactTable
          className="ReactTable -floated-table no-overflow all-data-table -initial-margin"
          data={data}
          showPagination={false}
          showPageSizeOptions={false}
          sortable={false}
          style={{ maxHeight: "600px" }}
          resizable={false}
          defaultPageSize={data.length || 0}
          pageSize={data.length || 0}
          noDataText={
            <div className="Table__no-results">
              <p>{t("no_data").message || "No data"}</p>
            </div>
          }
          column={{
            ...ReactTableDefaults.column,
            headerClassName: "ReactTable__column-header dealer-table-header",
            className: "ReactTable__column",
          }}
          columns={[
            {
              Header: <strong>{t("location").message || "Location"}</strong>,
              accessor: "location",
            },
            {
              Header: <strong>{t("total_cars").message || "Total cars"}</strong>,
              accessor: "total_cars",
            },
            {
              Header: <strong>{t("recurring_cars").message || "Recurring cars"}</strong>,
              accessor: "recurring_cars",
            },
            {
              Header: <strong>{t("percent_recurring_cars").message || "% of recurring cars"}</strong>,
              accessor: "percent_recurring_cars",
              Cell: cell => cell.value + "%",
            },
            {
              Header: <strong>{t("recurring_cars_not_worked_on").message || "Recurring cars not worked on"}</strong>,
              accessor: "recurring_cars_not_worked_on",
            },
          ]}
        />
      </div>
    </>
  );
};

export { RecurringCars };
