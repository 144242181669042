import React, { Component } from "react";
import ReactTable, { ReactTableDefaults } from "react-table";
import { connect } from "react-redux";
import { Button, Icon, Grid, Form } from "semantic-ui-react";
import moment from "moment";
import { withTranslation } from "react-i18next";

import { SubHeader, UserInput } from "./../../components";
import { setAlert } from "../App/store";

import Service from "./service";

class MobileVersions extends Component {
  state = {
    loadingVersions: false,
    versions: [],
    newVersion: {
      label: "",
      minimum_label: "",
      remarks: "",
    },
    isSubmit: false,
  };

  componentDidMount() {
    this.getVersions();
  }

  getVersions = () => {
    this.setState(
      {
        loadingDevices: true,
      },
      () => {
        Service.getVersions()
          .then(response => {
            const versions = response?.data?.data?.app_versions ? response.data.data.app_versions : [];
            this.setState({
              loadingVersions: false,
              versions: versions.sort(function (a, b) {
                return b.created_on > a.created_on ? 1 : -1;
              }),
            });
          })
          .catch(err => {
            console.error("Error getting versions.", err);
            this.setState({ loadingVersions: false }, () => {
              this.props.setAlert({
                type: "error",
                title: err.message,
              });
            });
          });
      }
    );
  };

  handleChange = (event, data) => {
    const { newVersion } = this.state;
    newVersion[data.name] = data.value;
    this.setState({ newVersion, isSubmit: false });
  };

  handleSave = () => {
    const { newVersion } = this.state;
    this.setState({ isSubmit: true });

    if (!newVersion.label || newVersion.label === "") return;

    Service.addVersion(newVersion)
      .then(result => {
        this.props.setAlert({
          type: "success",
          title: this.props.t("new_version_created").message || "New version created",
        });
        this.setState({
          newVersion: {
            label: "",
            minimum_label: "",
            remarks: "",
          },
          isSubmit: false,
        });
        this.getVersions();
      })
      .catch(err => {
        this.props.setAlert({
          type: "error",
          title: err.message,
        });
      });
  };

  renderTable = () => {
    const { versions, loadingVersions } = this.state;
    const { t } = this.props;

    if (loadingVersions) {
      return (
        <div className="Table__loading Loader-Placeholder">
          <div className="bounce1"></div>
          <div className="bounce2"></div>
          <div className="bounce3"></div>
          <section>{t("loading_versions").message || "Loading versions"}</section>
        </div>
      );
    } else {
      return (
        <div className="VersionsTable">
          <ReactTable
            className="ReactTable -floated-table -contained-large"
            data={versions}
            sortable={false}
            resizable={false}
            showPagination={false}
            showPageSizeOptions={false}
            defaultPageSize={versions.length}
            pageSize={versions.length}
            noDataText={
              <div className="Table__no-results">
                <Icon disabled name="mobile alternate" style={{ fontSize: "1.75em" }} />
                <p>{t("no_versions").message || "No versions"}</p>
              </div>
            }
            column={{
              ...ReactTableDefaults.column,
              headerClassName: "ReactTable__column-header",
              className: "ReactTable__column",
            }}
            columns={[
              {
                id: "Label",
                Header: t("label").message || "Label",
                maxWidth: 100,
                accessor: "label",
              },
              {
                id: "MinimumLabel",
                Header: t("minimum_label").message || "Minimum Label",
                maxWidth: 300,
                accessor: "minimum_label",
              },
              {
                id: "Note",
                Header: t("note").message || "Note",
                minWidth: 150,
                accessor: "remarks",
              },
              {
                id: "DateCreated",
                maxWidth: 100,
                Header: t("created_date").message || "Created Date",
                accessor: d => (d.created_on !== "0001-01-01T00:00:00Z" ? <span>{moment(d.created_on).format("DD-MM-YYYY")}</span> : ""),
              },
            ]}
          />
        </div>
      );
    }
  };

  render() {
    const { newVersion, isSubmit } = this.state;
    return (
      <div>
        <SubHeader>
          <Grid.Column width={16}>
            <h1>{this.props.t("mobile_app_versions").message || "Mobile App Versions"}</h1>
          </Grid.Column>
        </SubHeader>

        <Grid className="-contained-large">
          <Grid.Column width={16}>
            <Form>
              <Form.Group>
                <Form.Field width={2}>
                  <UserInput
                    type="text"
                    placeholder={this.props.t("version_label").message || "Version label"}
                    name="label"
                    value={newVersion.label}
                    onChange={this.handleChange}
                    error={isSubmit && !newVersion.label.length > 0}
                  />
                </Form.Field>

                <Form.Field width={4}>
                  <UserInput
                    type="text"
                    placeholder={this.props.t("minimum_label").message || "Minimum label"}
                    name="minimum_label"
                    value={newVersion.minimum_label}
                    onChange={this.handleChange}
                  />
                </Form.Field>

                <Form.Field width={9}>
                  <UserInput
                    type="text"
                    placeholder={this.props.t("remarks").message || "Remarks"}
                    name="remarks"
                    value={newVersion.remarks}
                    onChange={this.handleChange}
                  />
                </Form.Field>

                <Form.Field width={1}>
                  <Button onClick={this.handleSave} disabled={this.state.isSubmit} loading={this.state.isSubmit} color="green" floated="right">
                    Add
                  </Button>
                </Form.Field>
              </Form.Group>
            </Form>
          </Grid.Column>

          <Grid.Column width={16}>{this.renderTable()}</Grid.Column>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    setAlert: alertOptions => dispatch(setAlert(alertOptions)),
  };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(MobileVersions));
