import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { setAlert } from "../App/store";

import ACTIONS from "./actions";
import Service from "./service";

import "./index.scss";
import Context from "./context";

class Controller extends Component {
  state = {
    controller: this,
    keyLockers: [],
    keyLockerBoxes: [],
    action: null,
    selectedKeyLockerIndex: null,
    selectedBoxIndex: null,
    isLoading: false,
    adminPin: null,
    privateKey: null,
  };

  componentDidMount() {
    this.getKeyLockers();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.globalState.selectedLocation.id !== this.props.globalState.selectedLocation.id) {
      this.getKeyLockers();
    }
  }

  setAction = (action, selectedKeyLockerIndex = null, selectedBoxIndex = null) => this.setState({ action, selectedKeyLockerIndex, selectedBoxIndex });

  getKeyLockers = () => {
    const { t, globalState, setAlert } = this.props;

    this.setState({ isLoading: true, action: ACTIONS.LOAD_KEYLOCKERS }, () => {
      Service.getKeyLockers({ location_id: globalState.selectedLocation.id })
        .then(response => {
          const keyLockers = Array.isArray(response.data?.data?.keylockers) ? response.data.data.keylockers : [];
          this.setState({ keyLockers, isLoading: false, action: null });
        })
        .catch(err => {
          this.setState({ keyLockers: [], isLoading: false, action: null });
          console.error("Error getting key lockers:", err);
          setAlert({ type: "error", title: t(err.message).message || err.message });
        });
    });
  };

  submitKeyLocker = keyLocker => {
    const { t, globalState, setAlert } = this.props;
    const { keyLockers, selectedKeyLockerIndex } = this.state;

    this.setState({ isLoading: true, action: ACTIONS.EDIT_MODAL_SAVE }, () => {
      if (keyLocker.id > 0) {
        Service.updateKeyLocker({ keylocker_id: keyLocker.id, name: keyLocker.name, is_2fa_to_drop_key_enabled: keyLocker.is_2fa_to_drop_key_enabled })
          .then(() => {
            keyLockers[selectedKeyLockerIndex] = keyLocker;
            this.setState({ keyLockers, isLoading: false, selectedKeyLockerIndex: null, action: null });
          })
          .catch(err => {
            this.setState({ isLoading: false, action: ACTIONS.EDIT_MODAL_SHOW });
            console.error("Error updating key locker:", err);
            setAlert({ type: "error", title: t(err.message).message || err.message });
          });
      } else {
        Service.createKeyLocker({ location_id: globalState.selectedLocation.id, name: keyLocker.name, is_2fa_to_drop_key_enabled: keyLocker.is_2fa_to_drop_key_enabled })
          .then(response => {
            const newKeylocker = response.data?.data || { id: 0, name: keyLocker.name };
            this.setState({
              keyLockers: keyLockers.concat(newKeylocker),
              isLoading: false,
              selectedKeyLockerIndex: null,
              privateKey: newKeylocker.private_key,
              action: ACTIONS.KEYLOCKER_CREATED,
            });
          })
          .catch(err => {
            this.setState({ isLoading: false, action: ACTIONS.EDIT_MODAL_SHOW });
            console.error("Error creating key locker:", err);
            setAlert({ type: "error", title: t(err.message).message || err.message });
          });
      }
    });
  };

  activateKeyLocker = selectedKeyLockerIndex => {
    const { keyLockers } = this.state;
    const { t, setAlert } = this.props;

    this.setState({ isLoading: true, selectedKeyLockerIndex, action: ACTIONS.ACTIVATE_KEYLOCKER }, () => {
      Service.activateKeyLocker({ keylocker_id: keyLockers[selectedKeyLockerIndex].id })
        .then(response => {
          const newKeyLocker = response.data?.data ? response.data.data : { ...keyLockers[selectedKeyLockerIndex], active: true };
          keyLockers[selectedKeyLockerIndex] = newKeyLocker;
          this.setState({ keyLockers: [...keyLockers], isLoading: false, selectedKeyLockerIndex: null, action: null });
        })
        .catch(err => {
          this.setState({ isLoading: false, action: null, selectedKeyLockerIndex: null });
          console.error("Error activating key locker:", err);
          setAlert({ type: "error", title: t(err.message).message || err.message });
        });
    });
  };

  deactivateKeyLocker = selectedKeyLockerIndex => {
    const { keyLockers } = this.state;
    const { t, setAlert } = this.props;

    this.setState({ isLoading: true, selectedKeyLockerIndex, action: ACTIONS.DEACTIVATE_KEYLOCKER }, () => {
      Service.deactivateKeyLocker({ keylocker_id: keyLockers[selectedKeyLockerIndex].id })
        .then(response => {
          keyLockers[selectedKeyLockerIndex] = { ...keyLockers[selectedKeyLockerIndex], active: false };
          this.setState({ keyLockers: [...keyLockers], isLoading: false, selectedKeyLockerIndex: null, action: null });
        })
        .catch(err => {
          this.setState({ isLoading: false, action: null, selectedKeyLockerIndex: null });
          console.error("Error deactivating key locker:", err);
          setAlert({ type: "error", title: t(err.message).message || err.message });
        });
    });
  };

  deleteKeyLocker = () => {
    const { keyLockers, selectedKeyLockerIndex } = this.state;
    const { t, setAlert } = this.props;

    this.setState({ isLoading: true, action: ACTIONS.DELETE_KEYLOCKER }, () => {
      Service.deleteKeyLocker({ keylocker_id: keyLockers[selectedKeyLockerIndex].id })
        .then(response => {
          keyLockers.splice(selectedKeyLockerIndex, 1);
          this.setState({ keyLockers: [...keyLockers], isLoading: false, selectedKeyLockerIndex: null, action: null });
        })
        .catch(err => {
          this.setState({ isLoading: false, action: null });
          console.error("Error deleting key locker:", err);
          setAlert({
            type: "error",
            title: err?.response?.data?.errors?.length
              ? t(err.response.data.errors[0]).message
              : t("failed_error_message").message || "Something went wrong, please try again",
          });
        });
    });
  };

  getKeyLockerBoxes = selectedKeyLockerIndex => {
    const { t, setAlert } = this.props;

    this.setState({ isLoading: true, action: ACTIONS.GET_KEYLOCKER_DETAILS, selectedKeyLockerIndex }, () => {
      Service.getKeyLockerBoxes({ keylocker_id: this.state.keyLockers[selectedKeyLockerIndex].id })
        .then(response => {
          const keyLockerBoxes = Array.isArray(response.data?.data?.keylocker_boxes) ? response.data.data.keylocker_boxes : [];
          this.setState({ keyLockerBoxes, isLoading: false, action: ACTIONS.DETAILS_MODAL_SHOW });
        })
        .catch(err => {
          this.setState({ keyLockerBoxes: [], isLoading: false, action: null, selectedKeyLockerIndex: null });
          console.error("Error getting key locker details:", err);
          setAlert({ type: "error", title: t(err.message).message || err.message });
        });
    });
  };

  activateBox = selectedBoxIndex => {
    const { keyLockers, selectedKeyLockerIndex, keyLockerBoxes } = this.state;
    const { t, setAlert } = this.props;

    this.setState({ isLoading: true, selectedBoxIndex, action: ACTIONS.ACTIVATE_BOX }, () => {
      Service.activateBox({ keylocker_box_id: keyLockerBoxes[selectedBoxIndex].id })
        .then(response => {
          keyLockers[selectedKeyLockerIndex] = { ...keyLockers[selectedKeyLockerIndex], active_box_count: keyLockers[selectedKeyLockerIndex].active_box_count + 1 };
          keyLockerBoxes[selectedBoxIndex] = { ...keyLockerBoxes[selectedBoxIndex], active: true };
          this.setState({
            keyLockers: [...keyLockers],
            keyLockerBoxes: [...keyLockerBoxes],
            isLoading: false,
            selectedBoxIndex: null,
            action: ACTIONS.DETAILS_MODAL_SHOW,
          });
        })
        .catch(err => {
          this.setState({ isLoading: false, action: ACTIONS.DETAILS_MODAL_SHOW, selectedBoxIndex: null });
          console.error("Error activating key locker box:", err);
          setAlert({ type: "error", title: t(err.message).message || err.message });
        });
    });
  };

  deactivateBox = selectedBoxIndex => {
    const { keyLockers, selectedKeyLockerIndex, keyLockerBoxes } = this.state;
    const { t, setAlert } = this.props;

    this.setState({ isLoading: true, selectedBoxIndex, action: ACTIONS.DEACTIVATE_BOX }, () => {
      Service.deactivateBox({ keylocker_box_id: keyLockerBoxes[selectedBoxIndex].id })
        .then(response => {
          keyLockers[selectedKeyLockerIndex] = { ...keyLockers[selectedKeyLockerIndex], active_box_count: keyLockers[selectedKeyLockerIndex].active_box_count - 1 };
          keyLockerBoxes[selectedBoxIndex] = { ...keyLockerBoxes[selectedBoxIndex], active: false };
          this.setState({
            keyLockers: [...keyLockers],
            keyLockerBoxes: [...keyLockerBoxes],
            isLoading: false,
            selectedBoxIndex: null,
            action: ACTIONS.DETAILS_MODAL_SHOW,
          });
        })
        .catch(err => {
          this.setState({ isLoading: false, action: ACTIONS.DETAILS_MODAL_SHOW, selectedBoxIndex: null });
          console.error("Error deactivating key locker box:", err);
          setAlert({ type: "error", title: t(err.message).message || err.message });
        });
    });
  };

  getAdminPin = () => {
    const { keyLockerBoxes, selectedBoxIndex } = this.state;
    const { t, setAlert } = this.props;

    this.setState({ isLoading: true, selectedBoxIndex, action: ACTIONS.GET_ADMIN_PIN }, () => {
      Service.getAdminPin({ keylocker_box_id: keyLockerBoxes[selectedBoxIndex].id })
        .then(response => {
          this.setState({
            isLoading: false,
            selectedBoxIndex: null,
            adminPin: response?.data?.data?.pin || null,
            action: ACTIONS.ADMIN_PIN_MODAL_SHOW,
          });
        })
        .catch(err => {
          this.setState({ isLoading: false, action: ACTIONS.DETAILS_MODAL_SHOW, selectedBoxIndex: null });
          console.error("Error deactivating key locker box:", err);
          setAlert({ type: "error", title: t(err.message).message || err.message });
        });
    });
  };

  emptyBox = () => {
    const { keyLockers, selectedKeyLockerIndex, keyLockerBoxes, selectedBoxIndex } = this.state;
    const { t, setAlert } = this.props;

    this.setState({ isLoading: true, selectedBoxIndex, action: ACTIONS.EMPTY_BOX }, () => {
      Service.emptyBox({ keylocker_box_id: keyLockerBoxes[selectedBoxIndex].id })
        .then(response => {
          keyLockers[selectedKeyLockerIndex] = { ...keyLockers[selectedKeyLockerIndex], key_count: keyLockers[selectedKeyLockerIndex].key_count - 1 };
          keyLockerBoxes[selectedBoxIndex] = { ...keyLockerBoxes[selectedBoxIndex], is_empty: true, appointment: null, customer_communication: null };
          this.setState({
            keyLockers: [...keyLockers],
            keyLockerBoxes: [...keyLockerBoxes],
            isLoading: false,
            selectedBoxIndex: null,
            action: ACTIONS.DETAILS_MODAL_SHOW,
          });
        })
        .catch(err => {
          this.setState({ isLoading: false, action: ACTIONS.DETAILS_MODAL_SHOW, selectedBoxIndex: null });
          console.error("Error emptying key locker box:", err);
          setAlert({ type: "error", title: t(err.message).message || err.message });
        });
    });
  };

  render() {
    return <Context.Provider value={this.state}>{this.props.children}</Context.Provider>;
  }
}

const mapStateToProps = state => {
  return { globalState: state.global };
};

const mapDispatchToProps = dispatch => {
  return {
    setAlert: alertOptions => dispatch(setAlert(alertOptions)),
  };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Controller));
