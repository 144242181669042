import React from "react";
import { withTranslation } from "react-i18next";
import moment from "moment";
import { Dropdown } from "semantic-ui-react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import "./DateOptionsPicker.css";

const DateOptionsPicker = ({ value, isFluid, selectedOption, selectedDate, onOptionChange, onDateChange, t }) => {
  return (
    <div className="DateOptionsPicker">
      <Dropdown
        selection
        placeholder={t("pick_date_option").message || "Pick a date option"}
        options={[
          {
            text: t("pick_date").message,
            value: "day",
          },
          {
            text: t("last_3_days").message,
            value: 3,
          },
          {
            text: t("last_5_days").message,
            value: 5,
          },
          {
            text: t("last_7_days").message,
            value: 7,
          },
        ]}
        value={selectedOption}
        fluid={isFluid}
        onChange={onOptionChange}
      />

      <div className="DateOptionsPicker_datepicker-wrapper">
        <DatePicker
          className="DateOptionsPicker_datepicker"
          dateFormat="dd-MM-yyyy"
          todayButton={t("today").message || "Today"}
          disabled={selectedOption !== "day"}
          selected={moment(selectedDate).toDate()}
          onChange={onDateChange}
          onChangeRaw={e => e.preventDefault()}
          showMonthDropdown
          showYearDropdown
        />
      </div>
    </div>
  );
};

export default withTranslation()(DateOptionsPicker);
