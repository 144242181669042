import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Checkbox, Form } from "semantic-ui-react";

import { PasswordInput, UserInput } from "../../components";

class PlanITSettings extends Component {
  handleCheckboxChange = (_e, data) => this.props.onUpdateDealer({ ...this.props.dealer, planit_planning_enabled: data.checked });

  handleInputChange = (_e, data) => this.props.onUpdateDealer({ ...this.props.dealer, [data.name]: data.value });

  render() {
    const { dealer, t } = this.props;

    return (
      <Form>
        <Form.Field style={{ marginLeft: "4px", marginRight: 0, maxWidth: "100%" }}>
          <Checkbox
            checked={dealer.planit_planning_enabled}
            name="planit_planning_enabled"
            label={t("planit_planning_enabled").message || "Enable Plan IT Planning"}
            onChange={this.handleCheckboxChange}
          />
        </Form.Field>
        <Form.Field className="full-width-input">
          <label>{t("planit_planning_user_name").message || "Plan IT Username"}</label>
          <UserInput
            value={dealer.planit_planning_user_name}
            placeholder={t("planit_planning_user_name").message || "Plan IT Username"}
            name="planit_planning_user_name"
            error={dealer.planit_planning_enabled && !dealer.planit_planning_user_name}
            onChange={this.handleInputChange}
          />
        </Form.Field>
        <Form.Field className="full-width-input">
          <label>{t("planit_planning_password").message || "Plan IT Password"}</label>
          <PasswordInput
            value={dealer.planit_planning_password}
            placeholder={t("planit_planning_password").message || "Plan IT Password"}
            name="planit_planning_password"
            onChange={this.handleInputChange}
            showAsteriskPlaceholder={dealer.has_planit_planning_password}
          />
        </Form.Field>
      </Form>
    );
  }
}

export default withTranslation()(PlanITSettings);
