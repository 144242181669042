import React, { Component } from "react";
import ReactTable, { ReactTableDefaults } from "react-table";
import { Icon, Label, Dropdown, Popup } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTire } from "@fortawesome/pro-light-svg-icons";
import { faCheck, faExclamationTriangle, faPhoneAlt, faStickyNote } from "@fortawesome/free-solid-svg-icons";
import { faTag } from "@fortawesome/pro-solid-svg-icons";
import "react-table/react-table.css";

import AppointmentPin from "./AppointmentPin";
import AppointmentDate from "./AppointmentDate";
import PinNotification from "../../components/Pin/PinNotification";
import { isWarrantyPinSignatureMissing } from "../../components/Pin/util";
import { CarInspectionDate, StatusCell } from "./../../components";
import { DMS_CAPABILITIES } from "./../../util/common";
import { APPOINTMENT_NOTE_TYPES } from "./util";
import { CHECKLIST_TYPE } from "../Checklists/enum";
import { WO_FILTERS } from "./common";

import "./table.css";

const AppointmentSpecialIndicators = ({ appointment, selectedLocation, t }) => {
  const mainNote = appointment.notes?.find(n => n.appointment_note_type_id === APPOINTMENT_NOTE_TYPES.MAIN);
  const callCustomerNote = appointment.notes?.find(n => n.appointment_note_type_id === APPOINTMENT_NOTE_TYPES.CALL_CUSTOMER);
  const recurringCarNote = appointment.notes?.find(n => n.appointment_note_type_id === APPOINTMENT_NOTE_TYPES.RECURRING_CAR);

  const showKeyIcon =
    (appointment.key_dropped_at && !appointment.key_picked_up_at) ||
    (appointment.sharebox_key_dropped_at && !appointment.sharebox_key_picked_up_at) ||
    (appointment.key_dropped_back_at && !appointment.key_picked_up_back_at) ||
    (appointment.sharebox_key_dropped_back_at && !appointment.sharebox_key_picked_up_back_at) ||
    (appointment.acses_key_dropped_at && !appointment.acses_key_picked_up_at) ||
    (appointment.acses_key_dropped_back_at && !appointment.acses_key_picked_up_back_at);

  return (
    <div className="AppointmentSpecialIndicators" style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
      <div>
        {mainNote && (
          <Popup
            wide
            basic
            content={
              <div className="AppointmentSpecialIndicators-AppointmentNotePopup">
                <div className="AppointmentNoteTitle">
                  <FontAwesomeIcon icon={faStickyNote} color="#DB2828" />
                  <span>{t("main_note").message || "Main Note"}</span>
                </div>
                <div className="AppointmentNoteDescription">
                  {mainNote.updated_by
                    ? `${mainNote.updated_by.first_name} ${mainNote.updated_by.last_name} ${t("updated_main_note").message || "updated Main Note"} `
                    : `${mainNote.user.first_name} ${mainNote.user.last_name} ${t("added_main_note").message || "added Main Note"} `}
                  {`${moment(mainNote.updated_on).format("DD-MM-YYYY HH:mm")}`}
                </div>
                <div className="AppointmentNoteContent">{mainNote.note}</div>
              </div>
            }
            trigger={
              <Label circular color="red">
                <FontAwesomeIcon icon={faStickyNote} color="white" />
              </Label>
            }
          />
        )}

        {callCustomerNote && (
          <Popup
            wide
            basic
            content={
              <div className="AppointmentSpecialIndicators-AppointmentNotePopup">
                <div className="AppointmentNoteTitle">
                  <FontAwesomeIcon icon={faPhoneAlt} color="#21BA45" />
                  <span>{t("call_customer_note").message || "Call Customer Note"}</span>
                </div>
                <div className="AppointmentNoteDescription">
                  {callCustomerNote.updated_by
                    ? `${callCustomerNote.updated_by.first_name} ${callCustomerNote.updated_by.last_name} ${
                        t("updated_call_customer_note").message || "updated Call Customer Note"
                      } `
                    : `${callCustomerNote.user.first_name} ${callCustomerNote.user.last_name} ${t("added_call_customer_note").message || "added Call Customer Note"} `}
                  {`${moment(callCustomerNote.updated_on).format("DD-MM-YYYY HH:mm")}`}
                </div>

                <div className="AppointmentNoteContent">
                  <Label className="CallCustomerPhoneNr">{callCustomerNote.phone_nr}</Label>
                  <div>{callCustomerNote.note}</div>
                </div>
              </div>
            }
            trigger={
              <Label circular color="green">
                <FontAwesomeIcon icon={faPhoneAlt} color="white" />
              </Label>
            }
          />
        )}
      </div>
      <div>
        {appointment.has_dbb && appointment.dbb_appointment_date && (
          <>
            {appointment.final_car_check_dbb_status > 0 && (
              <Label
                className="checkedTire"
                circular
                color={appointment.final_car_check_dbb_status === 1 ? "green" : appointment.final_car_check_dbb_status === 2 ? "orange" : "red"}
              >
                <FontAwesomeIcon className="checkedTire-tire" icon={faTire} />
                <FontAwesomeIcon className="checkedTire-check" icon={faCheck} />
              </Label>
            )}
            {appointment.final_car_check_dbb_status === 0 && appointment.car_check_dbb_status > 0 && (
              <Label className="bigTire" circular color={appointment.car_check_dbb_status === 1 ? "green" : appointment.car_check_dbb_status === 2 ? "orange" : "red"}>
                <FontAwesomeIcon icon={faTire} />
              </Label>
            )}
            {appointment.final_car_check_dbb_status === 0 && appointment.car_check_dbb_status === 0 && (
              <Label className="bigTire" circular color={moment().isAfter(appointment.dbb_appointment_date) ? "green" : "orange"}>
                <FontAwesomeIcon icon={faTire} />
              </Label>
            )}
          </>
        )}
        {(appointment.is_recurring || recurringCarNote) && (
          <>
            <Popup
              wide
              basic
              disabled={!recurringCarNote}
              content={
                recurringCarNote && (
                  <div className="AppointmentSpecialIndicators-AppointmentNotePopup">
                    <div className="AppointmentNoteTitle">
                      <FontAwesomeIcon icon={faExclamationTriangle} color="#DB2828" />
                      <span>{t("recurring_car").message || "Recurring car"}</span>
                    </div>
                    <div className="AppointmentNoteDescription">
                      {recurringCarNote.updated_by
                        ? `${t("updated_by").message || "Updated by"} ${recurringCarNote.updated_by.first_name} ${recurringCarNote.updated_by.last_name} ${
                            t("at").message || "at"
                          } `
                        : `${t("added_by").message || "Added by"}  ${recurringCarNote.user.first_name} ${recurringCarNote.user.last_name} ${t("at").message || "at"} `}
                      {`${moment(recurringCarNote.updated_on).format("DD-MM-YYYY HH:mm")}`}
                    </div>
                    <div className="AppointmentNoteContent">{recurringCarNote.note}</div>
                  </div>
                )
              }
              trigger={
                <Label circular color="red">
                  <Icon name="warning sign" />
                </Label>
              }
            />
          </>
        )}
        {appointment.customer_waiting && (
          <Label circular color="brown">
            <Icon name="coffee" />
          </Label>
        )}
        {appointment.is_money && (
          <Label circular color="green">
            <Icon name="dollar" />
          </Label>
        )}
        {appointment.is_star && (
          <Label
            circular
            color={appointment.is_star_color.startsWith("#") ? null : appointment.is_star_color}
            style={appointment.is_star_color.startsWith("#") ? { color: "white", backgroundColor: appointment.is_star_color } : null}
          >
            <Icon name="star" />
          </Label>
        )}
        {appointment.is_shop && (
          <Label
            circular
            color={appointment.is_shop_color.startsWith("#") ? null : appointment.is_shop_color}
            style={appointment.is_shop_color.startsWith("#") ? { color: "white", backgroundColor: appointment.is_shop_color } : null}
          >
            <Icon name="shop" />
          </Label>
        )}
        {appointment.has_extra_check && (
          <Label circular color="blue">
            <i className="material-icons">format_color_fill</i>
          </Label>
        )}
        {appointment.num_approved_items_not_fixed > 0 && (
          <span className="-fix-items-count">
            <Label circular color="red">
              {appointment.num_approved_items_not_fixed}
            </Label>
          </span>
        )}
        {appointment.final_check_has_remarks && (
          <Label circular color="orange">
            <Icon name="wrench" />
          </Label>
        )}
        <PinNotification appointment={appointment} location={selectedLocation} />
        {appointment.kiosk_label_number > 0 && (
          <Label className="kiosk-label-icon" color="green">
            <FontAwesomeIcon icon={faTag} />
            <span>{appointment.kiosk_label_number}</span>
          </Label>
        )}
        {showKeyIcon && (
          <Label circular className="keylocker-icon">
            <Icon name="key" />
          </Label>
        )}
      </div>
    </div>
  );
};

function getAppointmentChecklistInitials(checklists) {
  return (
    /* eslint-disable array-callback-return */
    <div className="ChecklistInitials -text-ellipsis">
      {checklists &&
        checklists.map((c, k) => {
          if (c.checklist_type !== CHECKLIST_TYPE.EXTRA_PART)
            return (
              <span key={k} className="AppointmentChecklistInitial" title={c.name}>
                {c.name.substring(0, 1)}
              </span>
            );
        })}
    </div>
  );
}

function _getOwnerName(d) {
  let ownerName = d.owner_title || d.owner_initials || "";

  if (d.owner_firstname) ownerName += " " + d.owner_firstname;

  if (d.owner_surname) ownerName += " " + d.owner_surname;

  ownerName = ownerName.trim();

  if (!ownerName) {
    ownerName = d.company;
  }

  return ownerName;
}

function _getDriverName(d) {
  let driverName = d.driver_title || d.driver_initials || "";

  if (d.driver_firstname) driverName += " " + d.driver_firstname;

  if (d.driver_surname) driverName += " " + d.driver_surname;

  driverName = driverName.trim();

  if (!driverName) {
    driverName = d.company;
  }

  return driverName;
}

function _getContractorName(d) {
  let contractorName = d.contractor_title || d.contractor_initials || "";

  if (d.contractor_firstname) contractorName += " " + d.contractor_firstname;

  if (d.contractor_surname) contractorName += " " + d.contractor_surname;

  contractorName = contractorName.trim();

  if (!contractorName) {
    contractorName = d.company;
  }

  return contractorName;
}

function getLastUserFullName(appointment, selectedUpdatedByColumn) {
  const { customer_owner_name, car_in_shop_set_by, last_user, last_receptionist_name } = appointment;

  if (selectedUpdatedByColumn === "updated_by") return last_user;
  if (selectedUpdatedByColumn === "receptionist") return last_receptionist_name;

  return customer_owner_name ? customer_owner_name : car_in_shop_set_by ? `${car_in_shop_set_by.first_name} ${car_in_shop_set_by.last_name}` : "";
}

function renderTable(
  nameColumnClicked,
  selectedNameColumn,
  updatedByColumnClicked,
  selectedUpdatedByColumn,
  selectedIcons,
  onIconClick,
  isLoading,
  appointments,
  statuses,
  onRowClick,
  selectedLocation,
  carMakes,
  onCarMakeChange,
  onWoFilterSelect,
  t,
  appointmentDateTimeClicked,
  dateTimeAppointment,
  enable_car_ready,
  location_enabled,
  locations,
  list_appointments_by_scheduled_range,
  onei_planning_enabled,
  planit_planning_enabled,
  onHandleUpdateAppointments,
  isTodaysList,
  carInspectionDateSelected,
  onHandleChangeCarInspectionDateSelected,
  user
) {
  if (!selectedLocation || Object.keys(selectedLocation).length === 0) {
    return (
      <div className="EmptyState">
        <strong>
          {" "}
          <p>{t("no_selected_location").message || "No location selected"}</p>
        </strong>
      </div>
    );
  }

  if (!selectedNameColumn) {
    selectedNameColumn = selectedLocation.default_customer;
  }

  let isApkDateVisible = selectedLocation.id === "all" ? locations?.some(l => l.apk_visible) : selectedLocation.apk_visible;
  let isHuDateVisible = selectedLocation.id === "all" ? locations?.some(l => l.hu_visible) : selectedLocation.hu_visible;
  let isdatevisible = false;

  if (locations && locations.length) {
    isdatevisible = locations.findIndex(l => l.date_visible) > -1;
  }

  const DC =
    (selectedLocation && selectedLocation.id) !== "all"
      ? selectedLocation //.dashboard_config
      : {};

  const defaultShopCartShown = !(
    user.shop_status_same_day_filter_enabled ||
    user.shop_status_next_day_filter_enabled ||
    user.shop_status_to_order_filter_enabled ||
    user.shop_status_backorder_filter_enabled
  );

  if (isLoading) {
    return (
      <div className="Table__loading Loader-Placeholder">
        <div className="bounce1"></div>
        <div className="bounce2"></div>
        <div className="bounce3"></div>
        <section>{t("loading_appointments").message || "Loading appointments"}</section>
      </div>
    );
  } else {
    return (
      <ReactTable
        className="ReactTable -appointments-table -floated-table -contained-large"
        data={appointments}
        showPagination={false}
        showPageSizeOptions={false}
        sortable={false}
        resizable={false}
        defaultPageSize={appointments ? appointments.length : 0}
        pageSize={appointments ? appointments.length : 0}
        noDataText={
          <div className="Table__no-results">
            <Icon disabled name="list alternate outline" style={{ fontSize: "1.75em" }} />
            <p>{t("no_appointments").message || "No appointments"}</p>
            {!selectedLocation.dms_capability_ids?.includes(DMS_CAPABILITIES.SCHEDULED_IN_OUT) && list_appointments_by_scheduled_range && (
              <p style={{ fontSize: "15px" }}>
                {t("scheduled_range_error_message").message ||
                  "This location cannot list appointments by scheduled range, please set `List appointments by` to `Appointment date` in your account page."}
              </p>
            )}
          </div>
        }
        getTdProps={(state, rowInfo, column, instance) => {
          return {
            onClick: e => {
              onRowClick(column.id, rowInfo);
            },
          };
        }}
        column={{
          ...ReactTableDefaults.column,
          headerClassName: "ReactTable__column-header",
          className: "ReactTable__column",
        }}
        columns={[
          {
            Header: () => {
              return (
                <Dropdown
                  trigger={
                    <span className="-table-header">
                      {t("WO Nr").message || "WO"} <span className="total-appointments">{appointments.length}</span> <Icon name="caret down" />
                    </span>
                  }
                  fluid
                  inline
                  icon={null}
                  floating
                >
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => onWoFilterSelect(WO_FILTERS.ALL)} text={t("All").message || "All"} value={WO_FILTERS.ALL} key={WO_FILTERS.ALL} />
                    <Dropdown.Item onClick={() => onWoFilterSelect(WO_FILTERS.WO)} text={t("WO").message || "WO"} value={WO_FILTERS.WO} key={WO_FILTERS.WO} />
                    <Dropdown.Item
                      onClick={() => onWoFilterSelect(WO_FILTERS.INTERNAL)}
                      text={t("Internal").message || "Internal"}
                      value={WO_FILTERS.INTERNAL}
                      key={WO_FILTERS.INTERNAL}
                    />
                  </Dropdown.Menu>
                </Dropdown>
              );
            },
            headerClassName: "-overflow-inherit -border-right-none",
            minWidth: 90,
            maxWidth: 120,

            Cell: row => (
              <div title={row.original.wo_nr} className="wo-nr-container">
                <AppointmentPin appointment={row.original} onHandleUpdateAppointments={onHandleUpdateAppointments} />
                <div className={`wo-nr-ellipsis ${row.original.internal ? "internal-wo" : ""}`}>{row.original.wo_nr}</div>
              </div>
            ),
            getProps: (_state, rowInfo) => {
              if (rowInfo?.original.internal) {
                return {
                  style: {
                    color: "#fe0202",
                  },
                };
              }

              return {};
            },
            className: "-wo-column",
          },
          {
            Header: t("location").message || "Location",
            id: "location",
            accessor: d => {
              let name = "";
              if (locations && locations.length > 0) {
                let location = locations.find(item => item.id === d.dealer_location_id);
                if (location) name = location.name;
              }

              return <span>{name}</span>;
            },
            show: selectedLocation.id === "all" && location_enabled,
            maxWidth: 130,
          },
          {
            Header: enable_car_ready ? (
              () => {
                return (
                  <Dropdown
                    trigger={
                      <span className="-table-header">
                        {dateTimeAppointment === "date" ? t("appointment_date").message || "Appointment date" : t("scheduled_out").message || "Scheduled out"}{" "}
                        <Icon name="caret down" />
                      </span>
                    }
                    fluid
                    inline
                    icon={null}
                    floating
                  >
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => appointmentDateTimeClicked("date")}
                        title={t("appointment_date").message || "Appointment date"}
                        text={t("appointment_date").message || "Appointment date"}
                      />
                      <Dropdown.Item
                        onClick={() => appointmentDateTimeClicked("time")}
                        title={t("scheduled_out").message || "Scheduled out"}
                        text={t("scheduled_out").message || "Scheduled out"}
                      />
                    </Dropdown.Menu>
                  </Dropdown>
                );
              }
            ) : (
              <span className="-table-header">{t("appointment_date").message || "Appointment date"}</span>
            ),
            id: "appointmentDate",
            accessor: d =>
              dateTimeAppointment === "time" && enable_car_ready ? (
                <span style={moment(new Date()).isAfter(d.car_return_time) ? { color: "red" } : {}}>
                  {d.car_return_time ? (
                    <span>
                      <span>{moment(d.car_return_time).format("DD-MM-YYYY")}</span>
                      <span style={{ fontWeight: "bold" }}>&nbsp;&nbsp;{moment(d.car_return_time).format("HH:mm")}</span>
                    </span>
                  ) : (
                    ""
                  )}
                </span>
              ) : (
                d.time_car_app && <AppointmentDate appointment={d} displayPropagationDays={isTodaysList} />
              ),
            headerClassName: "-overflow-inherit -border-right-none",
            minWidth: 140,
            maxWidth: 140,
            show: selectedLocation.id === "all" ? isdatevisible : DC["date_visible"],
            className: "-date-column",
          },
          {
            id: "reg_number",
            Header: <Popup content={t("Reg Nr").message} trigger={<span>{t("Reg Nr").message}</span>} /> || "Reg Nr",
            accessor: d => (
              <span title={d.reg_number}>
                {d.is_lease_company ? <Icon name="building" /> : <Icon name="info circle" />}
                {d.reg_number}
              </span>
            ),
            className: "-reg-no-column -text-overflow-unset",
            maxWidth: 140,
          },
          {
            id: "CarInspectionDate",
            Header: () =>
              isApkDateVisible && isHuDateVisible ? (
                <Dropdown
                  trigger={
                    <span className="-table-header">
                      {carInspectionDateSelected === "car_apk_date" ? t("apk_date").message || "APK Date" : t("hu_date").message || "HU Date"} <Icon name="caret down" />
                    </span>
                  }
                  fluid
                  inline
                  icon={null}
                  floating
                >
                  <Dropdown.Menu>
                    <Dropdown.Item text={t("apk_date").message || "APK Date"} onClick={() => onHandleChangeCarInspectionDateSelected("car_apk_date")} />
                    <Dropdown.Item text={t("hu_date").message || "HU Date"} onClick={() => onHandleChangeCarInspectionDateSelected("car_hu_date")} />
                  </Dropdown.Menu>
                </Dropdown>
              ) : isApkDateVisible ? (
                <span className="-table-header">{t("car_apk_date").message || "Car APK Date"}</span>
              ) : isHuDateVisible ? (
                <span className="-table-header">{t("car_hu_date").message || "Car HU Date"}</span>
              ) : null,
            headerClassName: "-overflow-inherit -border-right-none",
            className: "-date-column",
            accessor: d => (
              <CarInspectionDate date={carInspectionDateSelected === "car_apk_date" && isApkDateVisible ? d.car_apk_date : isHuDateVisible ? d.car_hu_date : ""} />
            ),
            minWidth: 120,
            maxWidth: 120,
            show: isApkDateVisible || isHuDateVisible,
          },
          {
            Header: () => {
              return (
                <Dropdown
                  trigger={
                    <span className="-table-header" style={{ textTransform: "capitalize" }}>
                      {t(selectedNameColumn.charAt(0).toUpperCase() + selectedNameColumn.slice(1) + " name").message || selectedNameColumn} <Icon name="caret down" />
                    </span>
                  }
                  fluid
                  inline
                  icon={null}
                  floating
                >
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => nameColumnClicked("driver")} title={t("driver_name").message || "Driver"} text={t("driver_name").message || "Driver"} />
                    <Dropdown.Item
                      onClick={() => nameColumnClicked("contractor")}
                      title={t("contractor_name").message || "Contractor"}
                      text={t("contractor_name").message || "Contractor"}
                    />
                    <Dropdown.Item onClick={() => nameColumnClicked("owner")} title={t("owner_name").message || "Owner"} text={t("owner_name").message || "Owner"} />
                  </Dropdown.Menu>
                </Dropdown>
              );
            },
            id: "name_column",
            accessor: d => {
              if (selectedNameColumn === "driver" && _getDriverName(d)) {
                return (
                  <span
                    className={`${
                      isWarrantyPinSignatureMissing(d, selectedLocation) ? "-warranty-signature-missing" : d.check_in_com_answered ? "-customer-sign-wo" : ""
                    }`}
                    title={_getDriverName(d)}
                  >
                    {_getDriverName(d)}
                  </span>
                );
              } else if (selectedNameColumn === "contractor" && _getContractorName(d)) {
                return (
                  <span
                    className={`${
                      isWarrantyPinSignatureMissing(d, selectedLocation) ? "-warranty-signature-missing" : d.check_in_com_answered ? "-customer-sign-wo" : ""
                    }`}
                    title={_getContractorName(d)}
                  >
                    {_getContractorName(d)}
                  </span>
                );
              } else if (selectedNameColumn === "owner" && _getOwnerName(d)) {
                return (
                  <span
                    className={`${
                      isWarrantyPinSignatureMissing(d, selectedLocation) ? "-warranty-signature-missing" : d.check_in_com_answered ? "-customer-sign-wo" : ""
                    }`}
                    title={_getOwnerName(d)}
                  >
                    {_getOwnerName(d)}
                  </span>
                );
              } else {
                return (
                  <div
                    className={`customer-sign-placeholder ${
                      isWarrantyPinSignatureMissing(d, selectedLocation) ? "-warranty-signature-missing" : d.check_in_com_answered ? "-customer-sign-wo" : ""
                    }`}
                  ></div>
                );
              }
            },
            headerClassName: "-overflow-inherit -border-right-none",
            minWidth: 150,
            maxWidth: 150,
          },
          {
            Header: () => {
              return (
                <Dropdown
                  trigger={
                    <span className="-table-header">
                      {t("Make").message || "Make"} <Icon name="caret down" />
                    </span>
                  }
                  fluid
                  inline
                  icon={null}
                  floating
                >
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={onCarMakeChange} text={t("All").message || "All"} value="showall" key="showall" />
                    {carMakes.map(
                      option =>
                        option &&
                        option.name && (
                          <Dropdown.Item
                            onClick={onCarMakeChange}
                            title={option.name}
                            text={option.name}
                            value={option.name}
                            key={option.name}
                            className={option.filter ? "active selected" : ""}
                          />
                        )
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              );
            },
            headerClassName: "-overflow-inherit -border-right-none",
            accessor: "car_make",
            minWidth: 70,
            maxWidth: 90,
          },
          {
            Header: <React.Fragment>{t("model").message || "Model"}</React.Fragment>,
            id: "car_model",
            minWidth: 70,
            className: "-car-model-column",
            accessor: d => (
              <span className="-car-model-column-wrapper">
                <span className="-car-model" title={d.car_model}>
                  {d.car_model}
                </span>
              </span>
            ),
          },
          {
            Header: () => (
              <div className="AppointmentSpecialIndicators" style={{ display: "flex", width: "100%", justifyContent: "space-between", marginTop: "0.3em" }}>
                <div className="AppointmentSpecialIndicators-Header">{t("note").message || "Note"}</div>
                <div>
                  <Label
                    style={{ cursor: "pointer", transition: "background-color 0.3s" }}
                    onClick={() => onIconClick("is_money")}
                    circular
                    color={!selectedIcons.includes("is_money") ? "grey" : "green"}
                  >
                    <Icon name="dollar" />
                  </Label>
                  <Label
                    style={{ cursor: "pointer", transition: "background-color 0.3s" }}
                    onClick={() => onIconClick("is_star")}
                    circular
                    color={!selectedIcons.includes("is_star") ? "grey" : "green"}
                  >
                    <Icon name="star" />
                  </Label>

                  {defaultShopCartShown && (
                    <Label
                      style={{ cursor: "pointer", transition: "background-color 0.3s" }}
                      onClick={() => onIconClick("is_shop")}
                      circular
                      color={!selectedIcons.includes("is_shop") ? "grey" : "green"}
                    >
                      <Icon name="shop" />
                    </Label>
                  )}

                  {user.shop_status_same_day_filter_enabled && (
                    <Label
                      style={{ cursor: "pointer", transition: "background-color 0.3s" }}
                      className="custom-shop-icon-filter green"
                      onClick={() => onIconClick("is_shop_green")}
                      circular
                      color={!selectedIcons.includes("is_shop_green") ? "grey" : "green"}
                    >
                      <Icon name="shop" />
                    </Label>
                  )}

                  {user.shop_status_next_day_filter_enabled && (
                    <Label
                      style={{ cursor: "pointer", transition: "background-color 0.3s" }}
                      className="custom-shop-icon-filter orange"
                      onClick={() => onIconClick("is_shop_orange")}
                      circular
                      color={!selectedIcons.includes("is_shop_orange") ? "grey" : "orange"}
                    >
                      <Icon name="shop" />
                    </Label>
                  )}

                  {user.shop_status_to_order_filter_enabled && (
                    <Label
                      style={{ cursor: "pointer", transition: "background-color 0.3s" }}
                      className="custom-shop-icon-filter blue"
                      onClick={() => onIconClick("is_shop_blue")}
                      circular
                      color={!selectedIcons.includes("is_shop_blue") ? "grey" : "blue"}
                    >
                      <Icon name="shop" />
                    </Label>
                  )}

                  {user.shop_status_backorder_filter_enabled && (
                    <Label
                      style={{ cursor: "pointer", transition: "background-color 0.3s" }}
                      className="custom-shop-icon-filter red"
                      onClick={() => onIconClick("is_shop_red")}
                      circular
                      color={!selectedIcons.includes("is_shop_red") ? "grey" : "red"}
                    >
                      <Icon name="shop" />
                    </Label>
                  )}

                  <Label
                    style={{ cursor: "pointer", transition: "background-color 0.3s" }}
                    onClick={() => onIconClick("is_pin")}
                    circular
                    color={!selectedIcons.includes("is_pin") ? "grey" : "green"}
                  >
                    <Icon name="pin" />
                  </Label>
                  <Label
                    style={{ cursor: "pointer", transition: "background-color 0.3s" }}
                    onClick={() => onIconClick("is_recurring")}
                    circular
                    color={!selectedIcons.includes("is_recurring") ? "grey" : "green"}
                  >
                    <Icon name="warning sign" />
                  </Label>
                </div>
              </div>
            ),
            id: "filter_icons",
            headerClassName: "car-icons-column",
            minWidth: 260,
            maxWidth: 320,
            accessor: d => <AppointmentSpecialIndicators appointment={d} selectedLocation={selectedLocation} t={t} />,
          },
          {
            Header: <Popup content={t("updated").message} trigger={<span>{t("updated").message}</span>} /> || "Update",
            id: "lastUpdate",
            maxWidth: 52,
            accessor: d =>
              d.last_timestamp && (moment.isMoment(d.last_timestamp) || !d.last_timestamp.startsWith("0001-01-01T00:00:00")) // might be time zoned
                ? //accessor: d => (d.last_timestamp !== "0001-01-01T00:00:00Z")
                  moment(d.last_timestamp).format("HH:mm")
                : "",
          },
          {
            id: "last_user",
            maxWidth: 120,
            accessor: d => {
              if (selectedUpdatedByColumn === "assigned_to")
                return (
                  <Popup
                    content={
                      <div>
                        {d.planning_work_start && (
                          <div>
                            <strong>{t("planning_work_start").message || "Planning work start"}</strong>: {moment(d.planning_work_start).format("DD-MM-YYYY HH:mm")}
                          </div>
                        )}
                        {d.planning_work_stop && (
                          <div>
                            <strong>{t("planning_work_stop").message || "Planning work stop"}</strong>: {moment(d.planning_work_stop).format("DD-MM-YYYY HH:mm")}
                          </div>
                        )}
                      </div>
                    }
                    disabled={!d.planning_work_stop && !d.planning_work_start}
                    trigger={
                      <span className={`${d.has_panic ? "-panic-user" : ""}`}>
                        {d.planning_mechanic ? `${d.planning_mechanic.first_name} ${d.planning_mechanic.last_name}` : ""}
                      </span>
                    }
                  />
                );

              const lastUserFullName = getLastUserFullName(d, selectedUpdatedByColumn);
              return (
                <span title={lastUserFullName} className={`${d.has_panic ? "-panic-user" : ""}`}>
                  {lastUserFullName}
                </span>
              );
            },
            headerClassName: "-overflow-inherit -border-right-none",
            Header: () => {
              return (
                <Dropdown
                  trigger={
                    <span className="-table-header">
                      {t(selectedUpdatedByColumn).message || selectedUpdatedByColumn}
                      <Icon name="caret down" />
                    </span>
                  }
                  icon={null}
                  fluid
                  inline
                  floating
                >
                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={updatedByColumnClicked}
                      value="updated_by"
                      title={t("updated_by").message || "Updated by"}
                      text={t("updated_by").message || "Updated by"}
                    />
                    {(onei_planning_enabled || planit_planning_enabled) && (
                      <Dropdown.Item
                        onClick={updatedByColumnClicked}
                        value="assigned_to"
                        title={t("assigned_to").message || "Assigned to"}
                        text={t("assigned_to").message || "Assigned to"}
                      />
                    )}
                    <Dropdown.Item
                      onClick={updatedByColumnClicked}
                      value="customer_owner"
                      title={t("customer_owner").message || "Customer Owner"}
                      text={t("customer_owner").message || "Customer Owner"}
                    />
                    <Dropdown.Item
                      onClick={updatedByColumnClicked}
                      value="receptionist"
                      title={t("receptionist").message || "Receptionist"}
                      text={t("receptionist").message || "Receptionist"}
                    />
                  </Dropdown.Menu>
                </Dropdown>
              );
            },
          },
          {
            Header: t("checklists").message || "Checklists",
            id: "checklists",
            className: "-checklists-column",
            accessor: d => getAppointmentChecklistInitials(d.checklists),
            minWidth: 80,
            maxWidth: 150,
          },
          {
            Header: t("status").message || "Status",
            id: "status",
            className: "-status-column",
            accessor: d => ({
              name: d.appointment_status_name,
              color: d.appointment_status_color,
              id: d.appointment_status_identifier,
              customcom_status: d.customcom_status,
              check_paused_at: d.check_paused_at,
            }),
            Cell: row => <StatusCell value={row.value} statuses={statuses} car_out_of_shop={row.original.car_out_of_shop} />,
            minWidth: 62,
            maxWidth: 62,
          },
        ]}
      />
    );
  }
}

class AppointmentsTable extends Component {
  state = { appointments: this.props.appointments };

  componentWillReceiveProps(nextProps) {
    if (nextProps.appointments) {
      this.setState({ appointments: nextProps.appointments });
    }
  }

  render() {
    let {
      nameColumnClicked,
      selectedNameColumn,
      updatedByColumnClicked,
      selectedUpdatedByColumn,
      selectedIcons,
      onIconClick,
      isLoading,
      statuses,
      onRowClick,
      selectedLocation,
      carMakes,
      onCarMakeChange,
      onWoFilterSelect,
      t,
      appointmentDateTimeClicked,
      dateTimeAppointment,
      enable_car_ready,
      location_enabled,
      locations,
      list_appointments_by_scheduled_range,
      onei_planning_enabled,
      planit_planning_enabled,
      onHandleUpdateAppointments,
      isTodaysList,
      carInspectionDateSelected,
      onHandleChangeCarInspectionDateSelected,
      user,
    } = this.props;

    return renderTable(
      nameColumnClicked,
      selectedNameColumn,
      updatedByColumnClicked,
      selectedUpdatedByColumn,
      selectedIcons,
      onIconClick,
      isLoading,
      this.state.appointments,
      statuses,
      onRowClick,
      selectedLocation,
      carMakes,
      onCarMakeChange,
      onWoFilterSelect,
      t,
      appointmentDateTimeClicked,
      dateTimeAppointment,
      enable_car_ready,
      location_enabled,
      locations,
      list_appointments_by_scheduled_range,
      onei_planning_enabled,
      planit_planning_enabled,
      onHandleUpdateAppointments,
      isTodaysList,
      carInspectionDateSelected,
      onHandleChangeCarInspectionDateSelected,
      user
    );
  }
}

export default withTranslation()(AppointmentsTable);
