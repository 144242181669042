import React, { Component } from "react";
import { Form } from "semantic-ui-react";

import { UserInput } from "../../components";

class DealerSystemForm extends Component {
  handleInputChange = e => {
    let { dealer, onChange } = this.props;
    if (Object.keys(dealer).length === 0) return;
    dealer[e.target.name] = e.target.value;
    onChange(dealer);
  };
  render() {
    const { dealer, t } = this.props;

    return (
      dealer && (
        <Form>
          <Form.Field className="full-width-input">
            <label>{t("system_admin_name").message || "System admin name"}</label>
            <UserInput
              value={dealer.sys_admin_name}
              name="sys_admin_name"
              placeholder={t("system_admin_name").message || "System admin name"}
              onChange={this.handleInputChange}
            />
          </Form.Field>
          <Form.Field className="full-width-input">
            <label>{t("system_admin_email").message || "System admin email"}</label>
            <UserInput
              value={dealer.sys_admin_email}
              name="sys_admin_email"
              placeholder={t("system_admin_email").message || "System admin email"}
              onChange={this.handleInputChange}
            />
          </Form.Field>
          <Form.Field className="full-width-input">
            <label>{t("system_admin_phone").message || "System admin phone"}</label>
            <UserInput
              value={dealer.sys_admin_phone}
              name="sys_admin_phone"
              placeholder={t("system_admin_phone").message || "System admin phone"}
              onChange={this.handleInputChange}
            />
          </Form.Field>

          <Form.Field className="full-width-input">
            <label>{t("dms_server_information").message || "DMS Server information"}</label>
            <UserInput
              value={dealer.dms_server_info}
              name="dms_server_info"
              placeholder={t("dms_server_information").message || "DMS Server information"}
              onChange={this.handleInputChange}
            />
          </Form.Field>

          <Form.Field className="full-width-input">
            <label>{t("general_system_network_information").message || "General system / Network information"}</label>
            <textarea
              className="full-width-input"
              value={dealer.network_info}
              name="network_info"
              placeholder={t("general_system_network_information").message || "General system / Network information"}
              onChange={this.handleInputChange}
            ></textarea>
          </Form.Field>
        </Form>
      )
    );
  }
}

export default DealerSystemForm;
