import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { setAlert } from "../App/store";

import ACTIONS from "./actions";
import Service from "./service";

import "./index.scss";
import Context from "./context";

class Controller extends Component {
  state = {
    controller: this,
    kiosks: [],
    kioskLabels: [],
    action: null,
    selectedKioskIndex: null,
    isLoading: false,
    privateKey: null,
  };

  componentDidMount() {
    this.getKiosks();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.globalState.selectedLocation.id !== this.props.globalState.selectedLocation.id) {
      this.getKiosks();
    }
  }

  setAction = (action, selectedKioskIndex = null) => this.setState({ action, selectedKioskIndex });

  getKiosks = () => {
    const { t, globalState, setAlert } = this.props;

    this.setState({ isLoading: true, action: ACTIONS.LOAD_KIOSK }, () => {
      Service.getKiosks({ dealer_location_id: globalState.selectedLocation.id })
        .then(response => {
          const kiosks = Array.isArray(response.data?.data?.kiosks) ? response.data.data.kiosks : [];
          this.setState({ kiosks, isLoading: false, action: null });
        })
        .catch(err => {
          this.setState({ kiosks: [], isLoading: false, action: null });
          console.error("Error getting kiosk list:", err);
          setAlert({
            type: "error",
            title: err?.response?.data?.errors?.length
              ? t(err.response.data.errors[0]).message
              : t("failed_error_message").message || "Something went wrong, please try again",
          });
        });
    });
  };

  getKioskLabels = selectedKioskIndex => {
    const { t } = this.props;
    const { kiosks } = this.state;

    const selectedKiosk = kiosks[selectedKioskIndex];

    this.setState({ isLoading: true, action: ACTIONS.GET_KIOSK_DETAILS, selectedKioskIndex }, () => {
      Service.getKioskLabels({ kiosk_id: selectedKiosk.id })
        .then(response => {
          const kioskLabels = Array.isArray(response.data?.data?.labels) ? response.data.data.labels : [];
          this.setState({ kioskLabels, isLoading: false, action: ACTIONS.DETAILS_MODAL_SHOW });
        })
        .catch(err => {
          this.setState({ kioskLabels: [], selectedKioskIndex: null, isLoading: false, action: null });
          console.error("Error getting kiosk labels:", err);
          setAlert({
            type: "error",
            title: err?.response?.data?.errors?.length
              ? t(err.response.data.errors[0]).message
              : t("failed_error_message").message || "Something went wrong, please try again",
          });
        });
    });
  };

  submitKiosk = kiosk => {
    const { t, setAlert } = this.props;
    const { selectedKioskIndex } = this.state;

    const kiosks = [...this.state.kiosks];

    this.setState({ isLoading: true, action: ACTIONS.EDIT_MODAL_SAVE }, () => {
      if (kiosk.id) {
        Service.updateKiosk({ ...kiosk })
          .then(() => {
            kiosks[selectedKioskIndex] = kiosk;
            this.setState({ kiosks, isLoading: false, selectedKioskIndex: null, action: null });
          })
          .catch(err => {
            this.setState({ isLoading: false, action: ACTIONS.EDIT_MODAL_SHOW });
            console.error("Error updating kiosk:", err);
            setAlert({
              type: "error",
              title: err?.response?.data?.errors?.length
                ? t(err.response.data.errors[0]).message
                : t("failed_error_message").message || "Something went wrong, please try again",
            });
          });
      } else {
        Service.createKiosk({ ...kiosk, dealer_location_id: this.props.globalState.selectedLocation.id })
          .then(response => {
            let newKiosk = { ...kiosk };
            if (response?.data?.data?.kiosk_id) newKiosk = { ...newKiosk, id: response.data.data.kiosk_id };

            this.setState({
              kiosks: kiosks.concat(newKiosk),
              isLoading: false,
              selectedKioskIndex: null,
              action: ACTIONS.KIOSK_CREATED,
              privateKey: response?.data?.data?.private_key,
            });
          })
          .catch(err => {
            this.setState({ isLoading: false, action: ACTIONS.EDIT_MODAL_SHOW });
            console.error("Error creating kiosk:", err);
            setAlert({
              type: "error",
              title: err?.response?.data?.errors?.length
                ? t(err.response.data.errors[0]).message
                : t("failed_error_message").message || "Something went wrong, please try again",
            });
          });
      }
    });
  };

  activateKiosk = selectedKioskIndex => {
    const { t, setAlert } = this.props;

    const kiosks = [...this.state.kiosks];

    this.setState({ isLoading: true, selectedKioskIndex, action: ACTIONS.ACTIVATE_KIOSK }, () => {
      Service.activateKiosk({ kiosk_id: kiosks[selectedKioskIndex].id })
        .then(response => {
          const newKiosk = response.data?.data ? response.data.data : { ...kiosks[selectedKioskIndex], active: true };

          kiosks[selectedKioskIndex] = newKiosk;
          this.setState({ kiosks, isLoading: false, selectedKioskIndex: null, action: null });
        })
        .catch(err => {
          this.setState({ isLoading: false, action: null, selectedKioskIndex: null });
          console.error("Error activating kiosk:", err);
          setAlert({
            type: "error",
            title: err?.response?.data?.errors?.length
              ? t(err.response.data.errors[0]).message
              : t("failed_error_message").message || "Something went wrong, please try again",
          });
        });
    });
  };

  deactivateKiosk = selectedKioskIndex => {
    const { t, setAlert } = this.props;

    const kiosks = [...this.state.kiosks];

    this.setState({ isLoading: true, selectedKioskIndex, action: ACTIONS.DEACTIVATE_KIOSK }, () => {
      Service.deactivateKiosk({ kiosk_id: kiosks[selectedKioskIndex].id })
        .then(() => {
          kiosks[selectedKioskIndex] = { ...kiosks[selectedKioskIndex], active: false };
          this.setState({ kiosks, isLoading: false, selectedKioskIndex: null, action: null });
        })
        .catch(err => {
          this.setState({ isLoading: false, action: null, selectedKioskIndex: null });
          console.error("Error deactivating kiosk:", err);
          setAlert({
            type: "error",
            title: err?.response?.data?.errors?.length
              ? t(err.response.data.errors[0]).message
              : t("failed_error_message").message || "Something went wrong, please try again",
          });
        });
    });
  };

  deleteKiosk = () => {
    const { selectedKioskIndex } = this.state;
    const { t, setAlert } = this.props;

    const kiosks = [...this.state.kiosks];

    this.setState({ isLoading: true, action: ACTIONS.DELETE_KIOSK }, () => {
      Service.deleteKiosk({ kiosk_id: kiosks[selectedKioskIndex].id })
        .then(() => {
          kiosks.splice(selectedKioskIndex, 1);
          this.setState({ kiosks, isLoading: false, selectedKioskIndex: null, action: null });
        })
        .catch(err => {
          this.setState({ isLoading: false, action: null });
          console.error("Error deleting kiosk:", err);
          setAlert({
            type: "error",
            title: err?.response?.data?.errors?.length
              ? t(err.response.data.errors[0]).message
              : t("failed_error_message").message || "Something went wrong, please try again",
          });
        });
    });
  };

  render() {
    return <Context.Provider value={{ ...this.state, ...this.props }}>{this.props.children}</Context.Provider>;
  }
}

const mapStateToProps = state => {
  return { globalState: state.global };
};

const mapDispatchToProps = dispatch => {
  return {
    setAlert: alertOptions => dispatch(setAlert(alertOptions)),
  };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Controller));
