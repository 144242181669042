import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link, Redirect, Route, Switch, withRouter } from "react-router-dom";
import { Icon, Menu, Segment, Sidebar } from "semantic-ui-react";
import AlertContainer from "react-alert";
import { withTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faParking, faTire } from "@fortawesome/pro-solid-svg-icons";

import { reloadZohoWhenClosed, hideZohoWhenNotNeeded, isAdmin } from "../../util/common";
import ENV from "../../util/env-config";
import { loadProgressBar } from "../../components/ProgressBar";
import { ROLES, EnforceUserLocations } from "../Users/roles";

import Header from "./../../layouts/Header";
import {
  AccountSettings,
  Acl,
  AdminDealers,
  Appointments,
  Brands,
  Can,
  Checklists,
  ChecklistSettings,
  ContractAgreement,
  CommunicationSettings,
  CustomerCommMediums,
  CheckInService,
  Customers,
  DealerOverview,
  LocationOverview,
  Devices,
  KeyLocker,
  LocationReports,
  MobileVersions,
  News,
  PageNotFound,
  PrivacySecurityDashboard,
  PublicAPI,
  QuestionElements,
  Questions,
  Leads,
  StatusListGlobal,
  Tags,
  Templates,
  Translations,
  Tyres,
  Users,
  CameraVersions,
  Zoho,
  DealerReports,
  CommunicationAgreements,
  Webhooks,
  WarrantyDashboard,
  ManufacturerDashboard,
  DMSv3Settings,
  DMSv3CustomQueries,
  DMSv3Monitoring,
  FactoryExports,
  ShareboxDashboard,
  BackorderDashboard,
  TyreTeamDiscount,
  Dayplanner,
  DealerDocumentations,
  AcsesDashboard,
  NoteSuggestions,
  Kiosk,
  ParkingGateCodes,
} from "./../../modules";
import { NewsStripe } from "./../../components";
import KeyLockerCallForHelp from "../KeyLocker/KeyLockerCallForHelp";
import {
  getDealerPrivacyData,
  getDealers,
  getSettings,
  GLOBAL_ACTION_TYPES,
  selectDealer,
  selectLocation,
  processedAlert,
  getNotificationElements,
  resetGlobalStore,
  noUpdateToApply,
} from "./store";
import { logout, getZohoAsapCookie } from "../Auth/store";
import { setWebSocketIsOnline, setWebSocketLocationKey } from "../WebSocket/store";
import { resetAppointmentsStore } from "../Appointments/store";

import APP_CONFIG from "../../util/app-config";
import IncompleteTires from "../IncompleteTires";
import "./App.css";

const LandingPages = {
  Main: 1,
  LeadDashboard: 2,
  WarrantyDashboard: 3,
  ManufacturerDashboard: 4,
  Dayplanner: 5,
};

class App extends Component {
  state = {
    alertToDisplay: null,
    isAlertVisible: false,
    isSidebarOpen: false,
    toggleSettings: false,
  };

  componentWillMount() {
    this.setupNetworkListeners();
  }

  componentWillUnmount() {
    this.removeNetworkListeners();
  }

  componentDidMount() {
    loadProgressBar({ showSpinner: false });

    // eslint-disable-next-line
    this.props.getDealerPrivacyData(this.props.authState.user.dealer_id);

    this.props.getDealers(this.getUrlParam("dealer_reports"), this.getUrlParam("location_reports"));

    this.props.getSettings();

    this.props.getNotificationElements();

    this.setZohoDeskASAP();

    hideZohoWhenNotNeeded(this.props.location.pathname);

    this.setWebSocketLocation(this.props.globalState.selectedLocation);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.setState({ isSidebarOpen: false });
    }
  }

  getUrlParam = param => {
    const m = window.location.hash.match(param + "/([0-9]+)$");
    if (m && m[1]) return parseInt(m[1]);

    return null;
  };

  setZohoDeskASAP = () => {
    if (!window.ZohoHCAsap) {
      window.ZohoHCAsapSettings = {
        hideLauncherIcon: false,
        ticketsSettings: {
          preFillFields: {
            email: { defaultValue: this.props.authState.user.email },
            departmentId: { defaultValue: "22834000000007061" },
            contactId: { defaultValue: this.props.authState.user.first_name + " " + this.props.authState.user.last_name },
          },
        },
        userInfo: { jwtToken: getZohoAsapCookie() },
      };

      window.ZohoHCAsap =
        window.ZohoHCAsap ||
        function (a, b) {
          window.ZohoHCAsap[a] = b;
        };

      window.ZohoHCAsapReady = function (o) {
        window.ZohoHCAsap__asyncalls = window.ZohoHCAsap__asyncalls || [];
        if (window.ZohoHCAsapReadyStatus) {
          o && window.ZohoHCAsap__asyncalls.push(o);
          for (var a = window.ZohoHCAsap__asyncalls, s = 0; s < a.length; s++) {
            var n = a[s];
            n && n();
          }
          window.ZohoHCAsap__asyncalls = null;
        } else o && window.ZohoHCAsap__asyncalls.push(o);
      };

      window.ZohoHCAsapReady(() => {
        window.ZohoHCAsap.Actions.UpdateSettings({ lang: localStorage.getItem("locale") ? localStorage.getItem("locale").replace("-", "_") : "en_GB" });
        reloadZohoWhenClosed(() => hideZohoWhenNotNeeded(this.props.location.pathname));
      });

      let s = document.createElement("script");
      s.type = "text/javascript";
      s.defer = true;
      s.src = ENV.zohoASAP;
      document.getElementsByTagName("head")[0].appendChild(s);
    }
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.globalState.actionType === GLOBAL_ACTION_TYPES.ALERT_SET) {
      this.msg.show(nextProps.globalState.alertOptions.title, {
        time: 4000,
        ...nextProps.globalState.alertOptions,
      });

      this.props.processedAlert();
    }

    if (this.props.globalState.selectedLocation !== nextProps.globalState.selectedLocation) {
      this.setWebSocketLocation(nextProps.globalState.selectedLocation);
    }

    if (nextProps.globalState.actionType === GLOBAL_ACTION_TYPES.WEB_SOCKET_CONNECTION_UPDATE) {
      this.socketConnectionUpdate(nextProps.globalState.webSocketEvent.type !== "disconnected");
    }

    if (
      this.props.globalState.actionType !== nextProps.globalState.actionType &&
      nextProps.globalState.actionType === GLOBAL_ACTION_TYPES.GET_NOTIFICATION_ELEMENTS_FAIL
    ) {
      this.msg.show(this.props.globalState.errorMessages, {
        time: 4000,
        type: "error",
      });
    }

    if (this.props.location.pathname !== nextProps.location.pathname) hideZohoWhenNotNeeded(nextProps.location.pathname);
  }

  setWebSocketLocation = selectedLocation => {
    let selectedLocationKeys = [];

    if (selectedLocation?.id === "all") {
      this.props.globalState.dealers.forEach(dealer => {
        if (dealer.locations)
          dealer.locations.forEach(location => selectedLocation.myLocations.includes(location.id) && selectedLocationKeys.push(location.notifier_key || location.id));
      });
    } else selectedLocationKeys.push(selectedLocation?.notifier_key || selectedLocation?.id || "");

    this.props.setWebSocketLocationKey(selectedLocationKeys.join(","));
  };

  setupNetworkListeners = () => {
    window.addEventListener("online", this.updateOnlineStatus);
    window.addEventListener("offline", this.updateOnlineStatus);
  };

  removeNetworkListeners = () => {
    window.removeEventListener("online", this.updateOnlineStatus);
    window.removeEventListener("offline", this.updateOnlineStatus);
  };

  updateOnlineStatus = e => {
    let body = document.getElementsByTagName("body")[0];
    let c = navigator.onLine ? "online" : "offline";
    body.className = "status-" + c;
  };

  // Set the product version to context so it can be accessed by all subcomponents
  getChildContext() {
    return { productVersion: this.props.globalState.selectedLocation.version };
  }

  hideSidebar = () => {
    if (this.state.isSidebarOpen) {
      this.setState({ isSidebarOpen: false });
    }
  };

  handleSidebarToggle = () => {
    this.setState({
      isSidebarOpen: !this.state.isSidebarOpen,
    });
  };

  handleDealerChange = (event, data) => {
    if (data.value === this.props.globalState.selectedDealer.id) return;

    const dealer = data.options.find(d => d.value === data.value);

    this.props.selectDealer(dealer.detail);
  };

  handleLocationChange = (event, data) => {
    if (data.value === this.props.globalState.selectedLocation.id) {
      return;
    }

    let location = data.options.find(l => l.value === data.value);

    if (location.value === "all") {
      location.detail = {
        id: "all",
        defaultLocationId: this.props.authState.user.dealer_location_id,
        myLocations: data.options.filter(l => l.value !== "all").map(l => l.value) || [],
      };
    }

    this.props.selectLocation(location.detail);
  };

  handleUserMenuItemClick = clickedItem => {
    switch (clickedItem) {
      case "account":
        this.props.history.push("/account");
        break;
      case "logout":
        this.props.resetAppointmentsStore();
        this.props.resetGlobalStore();
        this.props.logout();
        break;
      default:
    }
  };

  handleToggleSettings = () => {
    this.setState({ toggleSettings: !this.state.toggleSettings });
  };

  socketConnectionUpdate = status => {
    this.props.setWebSocketIsOnline(status);

    return this.props.noUpdateToApply();
  };

  renderAlert = () => {
    const props = {
      offset: 20,
      position: "top right",
      theme: "light",
      time: 2000,
      transition: "fade",
    };

    return <AlertContainer ref={a => (this.msg = a)} {...props} />;
  };

  getLandingPage(id) {
    let urlParam;

    if ((urlParam = sessionStorage.getItem("_urlHandlerAppID"))) {
      sessionStorage.removeItem("_urlHandlerAppID");
      return "/appointments/" + urlParam;
    }

    if ((urlParam = sessionStorage.getItem("_urlHandlerWONumber"))) {
      sessionStorage.removeItem("_urlHandlerWONumber");
      return "/appointments/wo/" + urlParam;
    }

    if ((urlParam = sessionStorage.getItem("_urlHandlerCarID"))) {
      sessionStorage.removeItem("_urlHandlerCarID");
      return "/appointments/car/" + urlParam;
    }

    if ((urlParam = sessionStorage.getItem("_urlHandlerDealerID"))) {
      sessionStorage.removeItem("_urlHandlerDealerID");
      return "/dealer_reports/" + urlParam;
    }

    if ((urlParam = sessionStorage.getItem("_urlHandlerLocationID"))) {
      sessionStorage.removeItem("_urlHandlerLocationID");
      return "/location_reports/" + urlParam;
    }

    switch (id) {
      case LandingPages.LeadDashboard:
        return "/leads";
      case LandingPages.WarrantyDashboard:
        return "/warranty";
      case LandingPages.ManufacturerDashboard:
        return "/manufacturer";
      case LandingPages.Dayplanner:
        return "/dayplanner";
      default:
        return "/appointments";
    }
  }

  render() {
    let selectedLocation = this.props.globalState ? this.props.globalState.selectedLocation : {};
    let DASH_CONFIG = selectedLocation && selectedLocation.id !== "all" ? selectedLocation : {};

    let DP = this.props.globalState.dealerPrivacyData;
    const CU = this.props.authState.user;
    if (DP && !DP.signed && DP.data_officer && DP.data_officer.user_id === CU.id) {
      return <Route path="/" component={ContractAgreement}></Route>;
    }

    const isUserDealerAdmin = CU.role_id === ROLES.DEALER_ADMIN;
    const isUserAdmin = isAdmin(CU.role_id);
    const isUserSuperAdmin = CU.role_id === ROLES.SUPER_ADMIN;

    const { locations, showCustomLocations } = EnforceUserLocations(CU, this.props.globalState.selectedDealer?.locations || []);

    const isCustomerCommEnabled = DASH_CONFIG.online_check_in_enabled || DASH_CONFIG.diagnose_overview_enabled || DASH_CONFIG.repair_overview_enabled;

    const isCustomerCheckinEnabled =
      DASH_CONFIG.online_check_in_enabled || DASH_CONFIG.is_keylocker_enabled || DASH_CONFIG.desk_check_in_enabled || DASH_CONFIG.is_kiosk_enabled;

    return (
      <div className="App">
        <Sidebar.Pushable as={Segment}>
          <Sidebar as={Menu} animation="overlay" className="App__sidebar" width="thin" visible={this.state.isSidebarOpen} icon="labeled" vertical>
            <div className="main-sidebar">
              <Can I="browse" the="appointments-page">
                <Link to="/appointments">
                  <Menu.Item name="Appointments">
                    <Icon name="list" className="App__sidebar__menu__item" /> {this.props.t("appointment_list").message}
                  </Menu.Item>
                </Link>
              </Can>
              {DASH_CONFIG.is_dayplanner_enabled && (
                <Can I="browse" the="dayplanner-page">
                  <Link to="/dayplanner">
                    <Menu.Item name="Dayplanner">
                      <Icon name="columns" className="App__sidebar__menu__item" />
                      {this.props.t("dayplanner").message || "Dayplanner"}
                    </Menu.Item>
                  </Link>
                </Can>
              )}

              <Can I="browse" the="leads-dashboard-page">
                <Link to="/leads">
                  <Menu.Item name="Leads">
                    <Icon name="clock" className="App__sidebar__menu__item" /> {this.props.t("leads").message || "Leads"}
                  </Menu.Item>
                </Link>
              </Can>
              <Can I="browse" the="warranty-dashboard-page">
                <Link to="/warranty">
                  <Menu.Item name="Warranty">
                    <Icon name="shield" className="App__sidebar__menu__item" /> {this.props.t("warranty").message || "Warranty"}
                  </Menu.Item>
                </Link>
              </Can>
              <Can I="browse" the="manufacturer-page">
                <Link to="/manufacturer">
                  <Menu.Item name="Manufacturer">
                    <Icon name="industry" className="App__sidebar__menu__item" /> {this.props.t("manufacturer").message || "Manufacturer"}
                  </Menu.Item>
                </Link>
              </Can>
              <Can I="browse" the="backorders-dashboard-page">
                <Link to="/backorders">
                  <Menu.Item name="BackorderDashboard">
                    <span className="backorders-menu-icon">BO</span> {this.props.t("backorders").message || "Backorders"}
                  </Menu.Item>
                </Link>
              </Can>
              <Can I="browse" the="users-page">
                <Link to="/users">
                  <Menu.Item name="Users">
                    <Icon name="users" className="App__sidebar__menu__item" />
                    {this.props.t("users").message || "Users"}
                  </Menu.Item>
                </Link>
              </Can>
              <Can I="browse" the="customers-page">
                <Link to="/customers">
                  <Menu.Item name="Customers">
                    <Icon name="address card" className="App__sidebar__menu__item" /> {this.props.t("customers").message || "Customers"}
                  </Menu.Item>
                </Link>
              </Can>
              {DASH_CONFIG.is_tyre_team_enabled && (
                <Can I="browse" the="tyre-discount-page">
                  <Link to="/tyre-discount">
                    <Menu.Item name="Tyre-Discount">
                      <FontAwesomeIcon icon={faTire} className="App__sidebar__menu__item" style={{ marginRight: "0.75em" }} />
                      {this.props.t("tyre_discount").message || "Tyre Discount"}
                    </Menu.Item>
                  </Link>
                </Can>
              )}

              <Can I="browse" the="webhooks-page">
                <Link to="/webhooks">
                  <Menu.Item name="Webhooks">
                    <Icon name="linkify" className="App__sidebar__menu__item" /> {this.props.t("webhooks").message || "Webhooks"}
                  </Menu.Item>
                </Link>
              </Can>
              <Can I="browse" the="privacy-security-page">
                <Link to="/privacy-security">
                  <Menu.Item name="PrivacyAndSecurity">
                    <Icon name="protect" className="App__sidebar__menu__item" /> {this.props.t("privacy_security").message || "Privacy and Security"}
                  </Menu.Item>
                </Link>
              </Can>
              <Can I="browse" the="access-keys-page">
                <Link to="/access-keys">
                  <Menu.Item name="Claire API">
                    <Icon name="server" className="App__sidebar__menu__item" /> {this.props.t("claire_api").message || "Claire API"}
                  </Menu.Item>
                </Link>
              </Can>
              <Can I="browse" the="news-page">
                <Link to="/news">
                  <Menu.Item name="News">
                    <Icon name="newspaper" className="App__sidebar__menu__item" /> {this.props.t("news").message || "News"}
                  </Menu.Item>
                </Link>
              </Can>
              <Can I="browse" the="dealer-documentation-page">
                <Link to="/dealer-documentation">
                  <Menu.Item name="News">
                    <Icon name="file" className="App__sidebar__menu__item" /> {this.props.t("dealer_documentations").message || "Dealer Documentations"}
                  </Menu.Item>
                </Link>
              </Can>

              <Can
                I="browse"
                the="customer-comm-mediums-page"
                or={[
                  "customer-comm-settings-page",
                  "check-in-service-page",
                  "check-in-agreements-page",
                  "keylocker-page",
                  "kiosk-page",
                  "sharebox-logs-page",
                  "acses-events-page",
                  "note-comment-suggestions-page",
                ]}
              >
                <Link to="" className="menu-section">
                  {this.props.t("customer_communication").message || "Customer Comm"}
                </Link>

                {(isCustomerCommEnabled || DASH_CONFIG.is_keylocker_enabled) && (
                  <Can I="browse" the="customer-comm-mediums-page">
                    <Link to="/customer-comm-mediums">
                      <Menu.Item name="CustomCommDetail">
                        <Icon name="film" className="App__sidebar__menu__item" />
                        {this.props.t("Templates").message || "Templates"}
                      </Menu.Item>
                    </Link>
                  </Can>
                )}

                {(isCustomerCommEnabled || DASH_CONFIG.is_keylocker_enabled || DASH_CONFIG.desk_check_in_enabled) && (
                  <Can I="browse" the="customer-comm-settings-page">
                    <Link to="/customer-comm-settings">
                      <Menu.Item name="CustomCommDetail">
                        <Icon name="creative commons" className="App__sidebar__menu__item" />
                        {this.props.t("Settings").message || "Settings"}
                      </Menu.Item>
                    </Link>
                  </Can>
                )}

                {DASH_CONFIG.online_check_in_enabled && (
                  <Can I="browse" the="check-in-service-page">
                    <Link to="/checkin-services">
                      <Menu.Item name="CheckInService">
                        <Icon name="list layout" className="App__sidebar__menu__item" />
                        {this.props.t("check_in_services").message || "Check-in Services"}
                      </Menu.Item>
                    </Link>
                  </Can>
                )}

                {(isCustomerCheckinEnabled || DASH_CONFIG.diagnose_overview_enabled) && (
                  <Can I="browse" the="check-in-agreements-page">
                    <Link to="/communication/agreements">
                      <Menu.Item name="CommunicationAgreements">
                        <Icon name="check square outline" className="App__sidebar__menu__item" />
                        {this.props.t("agreements").message || "Agreements"}
                      </Menu.Item>
                    </Link>
                  </Can>
                )}

                {DASH_CONFIG.is_keylocker_enabled && (
                  <Can I="browse" the="keylocker-page">
                    <Link to="/key-lockers">
                      <Menu.Item name="KeyLockers">
                        <Icon name="key" className="App__sidebar__menu__item" />
                        {this.props.t("key_lockers").message || "Key Lockers"}
                      </Menu.Item>
                    </Link>
                  </Can>
                )}

                {DASH_CONFIG.is_kiosk_enabled && (
                  <Can I="browse" the="kiosk-page">
                    <Link to="/kiosk-dashboard">
                      <Menu.Item name="Kiosk">
                        <Icon name="key" className="App__sidebar__menu__item" />
                        {this.props.t("kiosk_").message || "Kiosk"}
                      </Menu.Item>
                    </Link>
                  </Can>
                )}

                {DASH_CONFIG.is_sharebox_enabled && (
                  <Can I="browse" the="sharebox-logs-page">
                    <Link to="/sharebox-logs">
                      <Menu.Item name="Sharebox logs">
                        <Icon name="key" className="App__sidebar__menu__item" />
                        {this.props.t("sharebox_logs").message || "Sharebox Logs"}
                      </Menu.Item>
                    </Link>
                  </Can>
                )}

                {DASH_CONFIG.is_acses_enabled && (
                  <Can I="browse" the="acses-events-page">
                    <Link to="/acses-events">
                      <Menu.Item name="ACSES logs">
                        <Icon name="key" className="App__sidebar__menu__item" />
                        {this.props.t("acses_logs").message || "ACSES Logs"}
                      </Menu.Item>
                    </Link>
                  </Can>
                )}

                {DASH_CONFIG.send_parking_gate_code && (
                  <Can I="browse" the="parking_gate_code_page">
                    <Link to="/parking-gate-codes">
                      <Menu.Item name="Parking Gate Codes">
                        <FontAwesomeIcon icon={faParking} className="App__sidebar__menu__item" style={{ marginLeft: "0.25em", marginRight: "0.75em" }} />
                        {this.props.t("parking_gate_codes").message || "Parking Gate Codes"}
                      </Menu.Item>
                    </Link>
                  </Can>
                )}

                <Can I="browse" the="note-comment-suggestions-page">
                  <Link to="/notes-menu">
                    <Menu.Item name="Notes">
                      <Icon name="sticky note" className="App__sidebar__menu__item" />
                      {this.props.t("notes").message || "Notes"}
                    </Menu.Item>
                  </Link>
                </Can>
              </Can>

              <Can I="browse" the="reports-page" or={["locations-reports-page"]}>
                <Link to="" className="menu-section">
                  {this.props.t("reports").message || "Reports"}
                </Link>

                <Can I="browse" the="reports-page">
                  <Link to="/dealer_reports">
                    <Menu.Item name="Reports">
                      <Icon name="bar chart" className="App__sidebar__menu__item" /> {this.props.t("dealer_reports").message || "Dealer Reports"}
                    </Menu.Item>
                  </Link>
                </Can>

                <Can I="browse" the="locations-reports-page">
                  <Link to="/location_reports">
                    <Menu.Item name="Reports">
                      <Icon name="bar chart" className="App__sidebar__menu__item" /> {this.props.t("location_reports").message || "Location Reports"}
                    </Menu.Item>
                  </Link>
                </Can>
              </Can>

              {(isUserAdmin || isUserSuperAdmin) && (
                <Link to="" className="menu-section">
                  {this.props.t("dms_section").message || "DMSv3"}
                </Link>
              )}

              <Can I="browse" the="dmsv3-setting-page">
                <Link to="/dmsv3/settings">
                  <Menu.Item name="DMS Settings">
                    <Icon name="settings" className="App__sidebar__menu__item" />
                    {this.props.t("dms_settings").message || "DMS Settings"}
                  </Menu.Item>
                </Link>
              </Can>

              <Can I="browse" the="dmsv3-customquery-page">
                <Link to="/dmsv3/custom-queries">
                  <Menu.Item name="CustomQueriesPage">
                    <Icon name="database" className="App__sidebar__menu__item" />
                    {this.props.t("custom_queries").message || "Custom queries"}
                  </Menu.Item>
                </Link>
              </Can>

              <Can I="browse" the="dmsv3-monitoring-page">
                <Link to="/dmsv3/monitoring">
                  <Menu.Item name="DMS Monitoring">
                    <Icon name="list" className="App__sidebar__menu__item" />
                    {this.props.t("monitoring").message || "Monitoring"}
                  </Menu.Item>
                </Link>
              </Can>

              {(isUserDealerAdmin || isUserAdmin || isUserSuperAdmin) && (
                <Link to="" className="menu-section">
                  {this.props.t("management").message || "Management"}
                </Link>
              )}
              <Can I="browse" the="checklists-page">
                <Link to="/checklists">
                  <Menu.Item name="Checklists">
                    <Icon name="list ol" className="App__sidebar__menu__item" />
                    {this.props.t("checklists").message || "Checklists"}
                  </Menu.Item>
                </Link>
              </Can>
              <Can I="browse" the="questions-page">
                <Link to="/questions">
                  <Menu.Item name="Questions">
                    <Icon name="talk" className="App__sidebar__menu__item" />
                    {this.props.t("questions").message || "Questions"}
                  </Menu.Item>
                </Link>
              </Can>

              {(isUserDealerAdmin || isUserAdmin || isUserSuperAdmin) && (
                <Link to="" className="menu-section">
                  {this.props.t("overview").message || "Overview"}
                </Link>
              )}
              <Can I="browse" the="dealers-page">
                <Link to="/dealer-overview">
                  <Menu.Item name="DealerOverview">
                    <Icon name="sitemap" className="App__sidebar__menu__item" />
                    {this.props.t("dealer_overview").message || "Dealer Overview"}
                  </Menu.Item>
                </Link>
              </Can>

              <Can I="browse" the="locations-page">
                <Link to="/location-overview">
                  <Menu.Item name="LocationOverview">
                    <Icon name="sitemap" className="App__sidebar__menu__item" />
                    {this.props.t("location_overview").message || "Location Overview"}
                  </Menu.Item>
                </Link>
              </Can>

              {(isUserAdmin || isUserSuperAdmin) && (
                <Link to="" className="menu-section">
                  {this.props.t("admin").message || "Admin"}
                </Link>
              )}
              <Can I="browse" the="checklist-settings-page">
                <Link to="/checklist-settings">
                  <Menu.Item name="ChecklistSettings">
                    <Icon name="settings" className="App__sidebar__menu__item" />
                    {this.props.t("checklist_settings").message || "Checklist Settings"}
                  </Menu.Item>
                </Link>
              </Can>
              <Can I="browse" the="admin-dealers-page">
                <Link to="/admin-dealers">
                  <Menu.Item name="AdminDealers">
                    <Icon name="sitemap" className="App__sidebar__menu__item" />
                    {this.props.t("dealers").message || "Dealers"}
                  </Menu.Item>
                </Link>
              </Can>
              <Can I="browse" the="factory-export-page">
                <Link to="/factory-export">
                  <Menu.Item name="FactoryExports">
                    <Icon name="mail" className="App__sidebar__menu__item" />
                    {this.props.t("factory_exports").message || "Factory exports"}
                  </Menu.Item>
                </Link>
              </Can>
              <Can I="browse" the="devices-page">
                <Link to="/devices">
                  <Menu.Item name="Devices">
                    <Icon name="tablet alternate" className="App__sidebar__menu__item" /> {this.props.t("tablets").message || "Tablets"}
                  </Menu.Item>
                </Link>
              </Can>
              <Can I="browse" the="tires-upload-page">
                <Link to="/incomplete_tires">
                  <Menu.Item name="DealersTyreUpload">
                    <Icon name="upload" className="App__sidebar__menu__item" />
                    {this.props.t("incomplete_tires").message || "Incomplete tires"}
                  </Menu.Item>
                </Link>
              </Can>
              <Can I="browse" the="translations-page">
                <Link to="/translations">
                  <Menu.Item name="Translations">
                    <Icon name="translate" className="App__sidebar__menu__item" /> {this.props.t("translations").message || "Translations"}
                  </Menu.Item>
                </Link>
              </Can>

              <Can I="browse" the="acl-page">
                <Link to="/acl">
                  <Menu.Item name="Acl">
                    <Icon name="universal access" className="App__sidebar__menu__item" /> {this.props.t("acl").message || "Acl"}
                  </Menu.Item>
                </Link>
              </Can>

              {isUserSuperAdmin && (
                <Link to="" className="menu-section">
                  {this.props.t("super_admin").message || "Super Admin"}
                </Link>
              )}
              <Can I="browse" the="question-elements-page">
                <Link to="/question-elements">
                  <Menu.Item name="QuestionElements">
                    <Icon name="clipboard list" className="App__sidebar__menu__item" />
                    {this.props.t("question_elements").message || "Question elements"}
                  </Menu.Item>
                </Link>
              </Can>
              <Can I="browse" the="tags-page">
                <Link to="/tags">
                  <Menu.Item name="Tags">
                    <Icon name="tags" className="App__sidebar__menu__item" />
                    {this.props.t("tags").message || "Tags"}
                  </Menu.Item>
                </Link>
              </Can>
              <Can I="browse" the="brands-page">
                <Link to="/brands">
                  <Menu.Item name="Brands">
                    <Icon name="car" className="App__sidebar__menu__item" /> {this.props.t("brands").message || "Brands"}
                  </Menu.Item>
                </Link>
              </Can>
              <Can I="browse" the="templates-page">
                <Link to="/templates">
                  <Menu.Item name="Templates">
                    <Icon name="file code outline" className="App__sidebar__menu__item" />
                    {this.props.t("report_templates").message || "Report templates"}
                  </Menu.Item>
                </Link>
              </Can>
              {isUserSuperAdmin && (
                <div>
                  <Menu.Item name="Settings" onClick={this.handleToggleSettings} style={{ cursor: "pointer" }}>
                    <Icon name="setting" className="App__sidebar__menu__item" />
                    {this.props.t("settings").message || "Settings"}
                    <Icon name={`angle ${this.state.toggleSettings ? "up" : "down"}`} className="-pull-right" />
                  </Menu.Item>
                  {this.state.toggleSettings && (
                    <div className="-padding-left-10">
                      <Can I="browse" the="status-list-global-page">
                        <Link to="/status-list-global">
                          <Menu.Item name="StatusListGlobal">
                            <Icon name="unordered list" className="App__sidebar__menu__item" /> {this.props.t("status_list").message || "Status list"}
                          </Menu.Item>
                        </Link>
                      </Can>
                      <Can I="browse" the="mobile-versions-page">
                        <Link to="/mobile-versions">
                          <Menu.Item name="MobileVersions">
                            <Icon name="mobile alternate" className="App__sidebar__menu__item" /> {this.props.t("mobile_app_versions").message || "Mobile app versions"}
                          </Menu.Item>
                        </Link>
                      </Can>
                      <Can I="browse" the="camera-app-versions-page">
                        <Link to="/camera-versions">
                          <Menu.Item name="CameraVersions">
                            <Icon name="camera" className="App__sidebar__menu__item" /> {this.props.t("camera_app_versions").message || "Camera App versions"}
                          </Menu.Item>
                        </Link>
                      </Can>
                      <Can I="browse" the="tyres-page">
                        <Link to="/tyres">
                          <Menu.Item name="Tyres">
                            <Icon name="life ring" className="App__sidebar__menu__item" /> {this.props.t("tyres").message || "Tyres"}
                          </Menu.Item>
                        </Link>
                      </Can>
                      <Can I="browse" the="zoho-page">
                        <Link to="/zoho">
                          <Menu.Item name="Zoho">
                            <Icon name="cubes" className="App__sidebar__menu__item" /> {this.props.t("zoho").message || "zoho"}
                          </Menu.Item>
                        </Link>
                      </Can>
                    </div>
                  )}
                </div>
              )}
            </div>

            <Link to="" className="menu-section">
              <p>{`Claire ${this.props.globalState.selectedLocation.version ? this.props.globalState.selectedLocation.version.name : ""}`}</p>
              <p>{APP_CONFIG.title}</p>
            </Link>
          </Sidebar>

          <Sidebar.Pusher>
            <Segment basic>
              <Can I="list_unread" the="articles">
                <NewsStripe />
              </Can>
              <div className={`App__content-overlay -dark -sidebar-open-${this.state.isSidebarOpen}`} onClick={this.hideSidebar}></div>
              <Header
                locationPathname={this.props.location.pathname}
                currentUser={this.props.authState.user}
                appSettings={this.props.globalState.settings}
                onSidebarToggle={this.handleSidebarToggle}
                onUserMenuItemClick={this.handleUserMenuItemClick}
                dealers={
                  this.props.globalState.dealers &&
                  this.props.globalState.dealers.map(d => ({
                    text: d.name,
                    value: d.id,
                    detail: d,
                  }))
                }
                locations={locations}
                selectedDealer={this.props.globalState.selectedDealer}
                selectedLocation={this.props.globalState.selectedLocation}
                onDealerChange={this.handleDealerChange}
                onLocationChange={this.handleLocationChange}
                showCustomLocations={showCustomLocations}
                t={this.props.t}
              />
              {this.renderAlert()}
              <Can I="handle_call_for_help" the="keylockers">
                <KeyLockerCallForHelp />
              </Can>
              <div className="App__module">
                <Switch>
                  <Redirect from="/" exact to={this.getLandingPage(this.props.authState.user.landing_page_id)} />
                  <Route
                    exact
                    path="/appointments"
                    render={props => (
                      <Can I="browse" the="appointments-page" or={["appointments-details-page"]} renderNoAccess={true}>
                        <Appointments {...props} locations={locations} />
                      </Can>
                    )}
                  ></Route>

                  <Route
                    exact
                    path="/appointments/:app_id"
                    render={props => (
                      <Can I="browse" the="appointments-page" or={["appointments-details-page"]} renderNoAccess={true}>
                        <Appointments {...props} locations={locations} isURLHandler />
                      </Can>
                    )}
                  ></Route>

                  <Route
                    exact
                    path="/appointments/wo/:wo?"
                    render={props => (
                      <Can I="browse" the="appointments-page" or={["appointments-details-page"]} renderNoAccess={true}>
                        <Appointments {...props} locations={locations} isURLHandler />
                      </Can>
                    )}
                  ></Route>

                  <Route
                    exact
                    path="/appointments/car/:car_id?"
                    render={props => (
                      <Can I="browse" the="appointments-car-page" renderNoAccess={true}>
                        <Appointments {...props} locations={locations} isURLHandler />
                      </Can>
                    )}
                  ></Route>

                  <Route
                    exact
                    path="/dayplanner"
                    render={() => (
                      <Can I="browse" the="dayplanner-page" renderNoAccess={true}>
                        <Dayplanner />
                      </Can>
                    )}
                  ></Route>

                  <Route
                    exact
                    path="/leads"
                    render={() => (
                      <Can I="browse" the="leads-dashboard-page" renderNoAccess={true}>
                        <Leads />
                      </Can>
                    )}
                  ></Route>

                  <Route
                    exact
                    path="/warranty"
                    render={() => (
                      <Can I="browse" the="warranty-dashboard-page" renderNoAccess={true}>
                        <WarrantyDashboard />
                      </Can>
                    )}
                  ></Route>

                  <Route
                    exact
                    path="/manufacturer"
                    render={() => (
                      <Can I="browse" the="manufacturer-page" renderNoAccess={true}>
                        <ManufacturerDashboard />
                      </Can>
                    )}
                  ></Route>

                  <Route
                    exact
                    path="/backorders"
                    render={() => (
                      <Can I="browse" the="backorders-dashboard-page" renderNoAccess={true}>
                        <BackorderDashboard />
                      </Can>
                    )}
                  ></Route>

                  <Route
                    exact
                    path="/dealer_reports/:dealer_id?"
                    render={() => (
                      <Can I="browse" the="reports-page" renderNoAccess={true}>
                        <DealerReports />
                      </Can>
                    )}
                  ></Route>

                  <Route exact path="/location_reports/:location_id?" render={() => <LocationReports />}></Route>

                  <Route
                    exact
                    path="/customers"
                    render={() => (
                      <Can I="browse" the="customers-page" renderNoAccess={true}>
                        <Customers />
                      </Can>
                    )}
                  ></Route>

                  <Route
                    exact
                    path="/tyre-discount"
                    render={() => (
                      <Can I="browse" the="tyre-discount-page" renderNoAccess={true}>
                        <TyreTeamDiscount />
                      </Can>
                    )}
                  ></Route>

                  <Route
                    exact
                    path="/webhooks"
                    render={() => (
                      <Can I="browse" the="webhooks-page" renderNoAccess={true}>
                        <Webhooks />
                      </Can>
                    )}
                  ></Route>

                  <Route
                    exact
                    path="/privacy-security"
                    render={() => (
                      <Can I="browse" the="privacy-security-page" renderNoAccess={true}>
                        <PrivacySecurityDashboard />
                      </Can>
                    )}
                  ></Route>

                  <Route
                    exact
                    path="/access-keys"
                    render={() => (
                      <Can I="browse" the="access-keys-page" renderNoAccess={true}>
                        <PublicAPI />
                      </Can>
                    )}
                  ></Route>

                  <Route
                    exact
                    path="/brands"
                    render={() => (
                      <Can I="browse" the="brands-page" renderNoAccess={true}>
                        <Brands />
                      </Can>
                    )}
                  ></Route>

                  <Route
                    exact
                    path="/devices"
                    render={() => (
                      <Can I="browse" the="devices-page" renderNoAccess={true}>
                        <Devices />
                      </Can>
                    )}
                  ></Route>

                  <Route
                    exact
                    path="/dmsv3/settings"
                    render={() => (
                      <Can I="browse" the="dmsv3-setting-page" renderNoAccess={true}>
                        <DMSv3Settings />
                      </Can>
                    )}
                  ></Route>

                  <Route
                    exact
                    path="/dmsv3/custom-queries"
                    component={DMSv3CustomQueries}
                    render={() => (
                      <Can I="browse" the="dmsv3-customquery-page" renderNoAccess={true}>
                        <DMSv3CustomQueries />
                      </Can>
                    )}
                  ></Route>

                  <Route
                    exact
                    path="/dmsv3/monitoring"
                    component={DMSv3Monitoring}
                    render={() => (
                      <Can I="browse" the="dmsv3-monitoring-page" renderNoAccess={true}>
                        <DMSv3Monitoring />
                      </Can>
                    )}
                  ></Route>

                  <Route
                    exact
                    path="/checklists"
                    component={Checklists}
                    render={() => (
                      <Can I="browse" the="checklists-page" renderNoAccess={true}>
                        <Checklists />
                      </Can>
                    )}
                  ></Route>

                  <Route
                    exact
                    path="/checklist-settings"
                    render={() => (
                      <Can I="browse" the="checklist-settings-page" renderNoAccess={true}>
                        <ChecklistSettings />
                      </Can>
                    )}
                  ></Route>

                  <Route
                    exact
                    path="/questions"
                    render={() => (
                      <Can I="browse" the="questions-page" renderNoAccess={true}>
                        <Questions />
                      </Can>
                    )}
                  ></Route>

                  <Route
                    exact
                    path="/question-elements"
                    render={() => (
                      <Can I="browse" the="question-elements-page" renderNoAccess={true}>
                        <QuestionElements />
                      </Can>
                    )}
                  ></Route>

                  <Route
                    exact
                    path="/dealer-overview"
                    render={() => (
                      <Can I="browse" the="dealers-page" renderNoAccess={true}>
                        <DealerOverview />
                      </Can>
                    )}
                  ></Route>

                  <Route
                    exact
                    path="/location-overview"
                    render={() => (
                      <Can I="browse" the="locations-page" renderNoAccess={true}>
                        <LocationOverview />
                      </Can>
                    )}
                  ></Route>

                  <Route
                    exact
                    path="/incomplete_tires"
                    render={() => (
                      <Can I="browse" the="tires-upload-page" renderNoAccess={true}>
                        <IncompleteTires />
                      </Can>
                    )}
                  ></Route>

                  <Route
                    exact
                    path="/admin-dealers"
                    render={() => (
                      <Can I="browse" the="admin-dealers-page" renderNoAccess={true}>
                        <AdminDealers />
                      </Can>
                    )}
                  ></Route>

                  <Route
                    exact
                    path="/factory-export"
                    render={() => (
                      <Can I="browse" the="factory-export-page" renderNoAccess={true}>
                        <FactoryExports />
                      </Can>
                    )}
                  ></Route>

                  <Route
                    exact
                    path="/tags"
                    render={() => (
                      <Can I="browse" the="tags-page" renderNoAccess={true}>
                        <Tags />
                      </Can>
                    )}
                  ></Route>

                  <Route
                    exact
                    path="/users"
                    render={() => (
                      <Can I="browse" the="users-page" renderNoAccess={true}>
                        <Users />
                      </Can>
                    )}
                  ></Route>
                  <Route
                    exact
                    path="/templates"
                    render={() => (
                      <Can I="browse" the="templates-page" renderNoAccess={true}>
                        <Templates />
                      </Can>
                    )}
                  ></Route>

                  <Route
                    exact
                    path="/status-list-global"
                    render={() => (
                      <Can I="browse" the="status-list-global-page" renderNoAccess={true}>
                        <StatusListGlobal />
                      </Can>
                    )}
                  ></Route>

                  <Route
                    exact
                    path="/mobile-versions"
                    render={() => (
                      <Can I="browse" the="mobile-versions-page" renderNoAccess={true}>
                        <MobileVersions />
                      </Can>
                    )}
                  ></Route>

                  <Route
                    exact
                    path="/camera-versions"
                    render={() => (
                      <Can I="browse" the="camera-app-versions-page" renderNoAccess={true}>
                        <CameraVersions />
                      </Can>
                    )}
                  ></Route>

                  <Route
                    exact
                    path="/zoho"
                    render={() => (
                      <Can I="browse" the="zoho-page" renderNoAccess={true}>
                        <Zoho />
                      </Can>
                    )}
                  ></Route>

                  <Route
                    exact
                    path="/news"
                    render={() => (
                      <Can I="browse" the="news-page" renderNoAccess={true}>
                        <News language_code={this.props.globalState.selectedLocation.language_code} />
                      </Can>
                    )}
                  ></Route>

                  <Route
                    exact
                    path="/dealer-documentation"
                    render={() => (
                      <Can I="browse" the="dealer-documentation-page" renderNoAccess={true}>
                        <DealerDocumentations />
                      </Can>
                    )}
                  ></Route>

                  <Route
                    exact
                    path="/tyres"
                    render={() => (
                      <Can I="browse" the="tyres-page" renderNoAccess={true}>
                        <Tyres />
                      </Can>
                    )}
                  ></Route>
                  <Route
                    exact
                    path="/translations"
                    render={() => (
                      <Can I="browse" the="translations-page" renderNoAccess={true}>
                        <Translations />
                      </Can>
                    )}
                  ></Route>

                  <Route
                    exact
                    path="/acl"
                    render={() => (
                      <Can I="browse" the="acl-page" renderNoAccess={true}>
                        <Acl />
                      </Can>
                    )}
                  ></Route>

                  <Route
                    exact
                    path="/customer-comm-mediums"
                    render={() => (
                      <Can I="browse" the="customer-comm-mediums-page" renderNoAccess={true}>
                        <CustomerCommMediums />
                      </Can>
                    )}
                  ></Route>

                  <Route
                    exact
                    path="/customer-comm-settings"
                    render={() => (
                      <Can I="browse" the="customer-comm-settings-page" renderNoAccess={true}>
                        <CommunicationSettings />
                      </Can>
                    )}
                  ></Route>

                  <Route
                    exact
                    path="/communication/agreements"
                    render={() => (
                      <Can I="browse" the="check-in-service-page" renderNoAccess={true}>
                        <CommunicationAgreements />
                      </Can>
                    )}
                  ></Route>

                  <Route
                    exact
                    path="/key-lockers"
                    render={() => (
                      <Can I="browse" the="keylocker-page" renderNoAccess={true}>
                        <KeyLocker />
                      </Can>
                    )}
                  ></Route>

                  <Route
                    exact
                    path="/kiosk-dashboard"
                    render={() => (
                      <Can I="browse" the="kiosk-page" renderNoAccess={true}>
                        <Kiosk />
                      </Can>
                    )}
                  ></Route>

                  <Route
                    exact
                    path="/sharebox-logs"
                    render={() => (
                      <Can I="browse" the="sharebox-logs-page" renderNoAccess={true}>
                        <ShareboxDashboard />
                      </Can>
                    )}
                  ></Route>

                  <Route
                    exact
                    path="/acses-events"
                    render={() => (
                      <Can I="browse" the="acses-events-page" renderNoAccess={true}>
                        <AcsesDashboard />
                      </Can>
                    )}
                  ></Route>

                  <Route
                    exact
                    path="/parking-gate-codes"
                    render={() => (
                      <Can I="browse" the="parking_gate_code_page" renderNoAccess={true}>
                        <ParkingGateCodes />
                      </Can>
                    )}
                  ></Route>

                  <Route
                    exact
                    path="/notes-menu"
                    render={() => (
                      <Can I="browse" the="note-comment-suggestions-page" renderNoAccess={true}>
                        <NoteSuggestions />
                      </Can>
                    )}
                  ></Route>

                  <Route
                    exact
                    path="/checkin-services"
                    render={() => (
                      <Can I="browse" the="check-in-service-page" renderNoAccess={true}>
                        <CheckInService />
                      </Can>
                    )}
                  ></Route>

                  <Route
                    exact
                    path="/account"
                    render={() => (
                      <Can I="browse" the="account-page" renderNoAccess={true}>
                        <AccountSettings dealer={this.props.globalState.selectedDealer} onLogout={this.props.logout} />
                      </Can>
                    )}
                  ></Route>
                  <Route component={PageNotFound} />
                </Switch>
              </div>
            </Segment>
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </div>
    );
  }
}

App.childContextTypes = {
  productVersion: PropTypes.object,
};

const mapStateToProps = state => {
  if (state.auth.user && state.auth.user.dealer_id) state.auth.user.dealer_id = parseInt(state.auth.user.dealer_id, 10);

  if (state.auth.user && state.auth.user.dealer_location_id) state.auth.user.dealer_location_id = parseInt(state.auth.user.dealer_location_id, 10);

  return { authState: state.auth, globalState: state.global, webSocket: state.webSocket };
};

const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(logout()),
    getZohoAsapCookie: () => dispatch(getZohoAsapCookie()),
    resetAppointmentsStore: () => dispatch(resetAppointmentsStore()),
    getDealers: (urlDealerID, urlLocationID) => dispatch(getDealers(urlDealerID, urlLocationID)),
    getDealerPrivacyData: dealerID => dispatch(getDealerPrivacyData(dealerID)),
    getSettings: () => dispatch(getSettings()),
    selectDealer: dealer => dispatch(selectDealer(dealer)),
    selectLocation: location => dispatch(selectLocation(location)),
    setWebSocketLocationKey: location_key => dispatch(setWebSocketLocationKey(location_key)),
    processedAlert: () => dispatch(processedAlert()),
    getNotificationElements: () => dispatch(getNotificationElements()),
    setWebSocketIsOnline: status => dispatch(setWebSocketIsOnline(status)),
    resetGlobalStore: () => dispatch(resetGlobalStore()),
    noUpdateToApply: () => dispatch(noUpdateToApply()),
  };
};

export default withRouter(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(App)));
