import React, { Component } from "react";
import moment from "moment";
import { withTranslation } from "react-i18next";
import { Modal, Label, Grid, Icon, Button, Tab } from "semantic-ui-react";

import printJS from "../../lib/print-js";
import { KEYLOCKER_PIN_REQUEST_TYPES } from "../../util/common";
import { KEYLOCKER_COMMUNICATION_EVENTS, CHANNELS, KEYLOCKER_COMMUNICATION_STATUSES } from "./common";
import { getPriceForDisplay } from "./util";

import "./KeylockerCommunicationLog.scss";

class KeylockerCommunicationLog extends Component {
  state = {
    isOpen: false,
    isAgreementModalOpen: false,
    isPrintLoading: false,
    selectedAgreement: null,
  };

  handleToggleAgreement = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  handleOpenAgreementModal = selectedAgreement => {
    this.setState({ isAgreementModalOpen: true, selectedAgreement });
  };

  handleCloseAgreementModal = () => {
    this.setState({ isAgreementModalOpen: false, selectedAgreement: null });
  };

  renderAppointmentInformation = () => {
    const { appointment, t } = this.props;

    return (
      <div className="info-section">
        <div className="section-header">
          <span>{t("appointment_information").message || "Appointment information"}</span>
        </div>
        <div className="section-content">
          <Grid>
            <Grid.Column width={4}>
              <span className="icon-text">
                {t("wo_nr").message || "WO"} <Icon name="hashtag" color="green" />
              </span>
              {appointment.wo_nr}
            </Grid.Column>

            <Grid.Column width={4}>
              <span className="icon-text">
                {t("reg").message || "Reg."} <Icon name="hashtag" color="green" />
              </span>
              {appointment.reg_number}
            </Grid.Column>

            {appointment.due_in && (moment.isMoment(appointment.due_in) || !appointment.due_in.startsWith("0001-01-01T00:00:00")) ? (
              <>
                <Grid.Column width={4}>
                  <Icon name="calendar alternate" color="green" />
                  {moment(appointment.due_in).format("DD-MM-YYYY")}
                </Grid.Column>

                <Grid.Column width={4}>
                  <Icon name="time" color="green" />
                  {moment(appointment.due_in).format("HH:mm")}
                </Grid.Column>
              </>
            ) : (
              appointment.time_car_app &&
              (moment.isMoment(appointment.time_car_app) || !appointment.time_car_app.startsWith("0001-01-01T00:00:00")) && (
                <Grid.Column width={4}>
                  <Icon name="calendar alternate" color="green" />
                  {moment(appointment.time_car_app).format("DD-MM-YYYY")}
                </Grid.Column>
              )
            )}
          </Grid>
        </div>
      </div>
    );
  };

  getInterventionPrice = (price, vat) => {
    if (price <= 0.01) return;

    return <span>{vat ? getPriceForDisplay(price * (1 + vat / 100)) : getPriceForDisplay(price)} €</span>;
  };

  communicationEventName = (event, t) => {
    switch (event.type) {
      case KEYLOCKER_COMMUNICATION_EVENTS.DROP_PIN_SENT:
        return t("drop_pin_sent").message || "Drop pin sent";

      case KEYLOCKER_COMMUNICATION_EVENTS.CHECK_IN_OPENED:
        return t("check_in_opened").message || "Check-in opened";

      case KEYLOCKER_COMMUNICATION_EVENTS.CHECK_IN_ANSWERED:
        return t("check_in_answered").message || "Check-in answered";

      case KEYLOCKER_COMMUNICATION_EVENTS.PICK_UP_PIN_SENT:
        return t("pick_up_pin_sent").message || "Pick up pin sent";

      default:
        return "";
    }
  };

  getPinRequestType = event => {
    const { t } = this.props;

    const CommonText = t("keylocker_pin_requested_using").message || "Pin requested using";

    switch (event.pin_request_type) {
      case KEYLOCKER_PIN_REQUEST_TYPES.PHONE:
        return <span>{`${CommonText}: ${t("keylocker_phone_number").message || "phone number"}`}</span>;
      case KEYLOCKER_PIN_REQUEST_TYPES.REGISTRATION_NUMBER:
        return <span>{`${CommonText}: ${t("keylocker_reg_number").message || "registration number"}`}</span>;
      case KEYLOCKER_PIN_REQUEST_TYPES.ONLINE_CHECK_IN:
        return <span>{`${CommonText}: ${t("keylocker_online_check_in").message || "online check-in"}`}</span>;
      case KEYLOCKER_PIN_REQUEST_TYPES.TWO_FA_DISABLED:
        return <span>{t("2fa_disabled_no_pin_sent").message || "2FA disabled, no PIN sent"}</span>;

      default:
        return null;
    }
  };

  communicationEventInfo = (event, comm) => {
    const { t } = this.props;

    const fullname =
      event.type === KEYLOCKER_COMMUNICATION_EVENTS.DROP_PIN_SENT && comm?.customer && `${comm?.customer?.firstname || ""} ${comm?.customer?.surname || ""}`;

    return (
      <Grid.Row width={16} className="section-rows">
        {event.receivers?.length > 0 &&
          event.receivers.map((receiver, i) => (
            <React.Fragment key={i}>
              <span>
                {receiver.communication_channel_id === CHANNELS.EMAIL ? <Icon name="mail" color="green" /> : <Icon name="phone" color="green" />}
                {receiver.destination}
              </span>
              {receiver.reason && (
                <span className="-margin-left-10">
                  <Icon key={i} name="times circle outline" color="red" />
                  {receiver.reason}
                </span>
              )}
            </React.Fragment>
          ))}

        {fullname && (
          <span>
            <Icon name="user" color="green" />
            {fullname}
          </span>
        )}

        {this.getPinRequestType(event)}

        {event.keylocker_name && <span>{`${t("keylocker").message || "Keylocker"}: ${event.keylocker_name}`}</span>}
        {event.keylocker_box_number && <span>{`${t("box_number").message || "Box number"}: ${event.keylocker_box_number}`}</span>}
      </Grid.Row>
    );
  };

  renderCommunicationEvents = comm => {
    const { t } = this.props;
    const { events } = comm;

    return (
      events?.length > 0 && (
        <div className="info-section">
          <div className="section-header">{t("keylocker_communications_events").message || "Keylocker Communication events"}</div>
          <div className="section-content">
            <Grid>
              {events.map((event, key) => {
                return (
                  <Grid.Row key={key}>
                    <Grid.Column width={12}>
                      {event.pin_request_type !== KEYLOCKER_PIN_REQUEST_TYPES.TWO_FA_DISABLED ? (
                        this.communicationEventName(event, t)
                      ) : (
                        <div className="event-info">{this.communicationEventInfo(event, comm)}</div>
                      )}
                    </Grid.Column>

                    <Grid.Column width={4} className="event-timestamp">
                      {moment(event.created_on).format("HH:mm")} - {moment(event.created_on).format("DD-MM-YYYY")}
                    </Grid.Column>

                    {event.pin_request_type !== KEYLOCKER_PIN_REQUEST_TYPES.TWO_FA_DISABLED && this.communicationEventInfo(event, comm)}
                  </Grid.Row>
                );
              })}
            </Grid>
          </div>
        </div>
      )
    );
  };

  renderAgreementModal = () => {
    const { selectedAgreement, isAgreementModalOpen } = this.state;
    return (
      selectedAgreement && (
        <Modal dimmer={true} closeOnDimmerClick={false} open={isAgreementModalOpen} onClose={this.handleCloseAgreementModal}>
          <Modal.Header>
            {selectedAgreement.name}
            <Button onClick={this.handleCloseAgreementModal} floated="right" color="green">
              {this.props.t("close").message || "Close"}
            </Button>
          </Modal.Header>
          <Modal.Content scrolling>
            <div dangerouslySetInnerHTML={{ __html: selectedAgreement.text }}></div>
          </Modal.Content>
        </Modal>
      )
    );
  };

  renderAgreements = agreements => {
    if (!agreements?.length) return null;
    const { t } = this.props;

    return (
      <div className="info-section">
        <div className="section-header">{t("agreements").message || "Agreements"}</div>
        <div className="section-content">
          <Grid>
            <Grid.Column width={16}>
              {agreements.map((item, key) => (
                <div className="agreements-label" key={key} onClick={() => this.handleOpenAgreementModal(item)}>
                  <Label>
                    <Icon name="check square" />
                  </Label>
                  <span className="-margin-left-10">{item.name}</span>
                </div>
              ))}
            </Grid.Column>
          </Grid>
        </div>
      </div>
    );
  };

  renderAgreedInterventions = agreed_interventions => {
    const { vat, t } = this.props;

    if (!agreed_interventions?.length) return null;

    return (
      <div className="info-section">
        <div className="section-header">{this.props.t("accepted_scheduled_services").message || "Accepted scheduled services"}</div>
        <div className="section-content">
          <Grid>
            <Grid.Column width={16}>
              <div className="services-header">
                <span>{t("title").message || "Title"}</span>
                <span>{vat ? t("price_inc_vat").message || "Price (incl. VAT)" : t("price_excl_vat").message || "Price (excl. VAT)"}</span>
              </div>
              {agreed_interventions.map((i, key) => (
                <div className="service" key={key}>
                  <span>{i.title}</span>
                  {this.getInterventionPrice(i.price, vat)}
                </div>
              ))}
            </Grid.Column>
          </Grid>
        </div>
      </div>
    );
  };

  renderTabContent = comm => {
    const { agreed_interventions, agreements } = comm;

    return (
      <>
        {this.renderCommunicationEvents(comm)}
        {this.renderAgreedInterventions(agreed_interventions)}
        {this.renderAgreements(agreements)}
      </>
    );
  };

  getMenuItemTime = status => {
    const { t } = this.props;

    if (status === KEYLOCKER_COMMUNICATION_STATUSES.PICK_UP_PIN_SENT) return t("pick_up_key").message || "Pick up key";

    return t("drop_key").message || "Drop key";
  };

  renderTabs = () => {
    const { appointment } = this.props;
    const panes = [];

    appointment.keylocker_communications.forEach(comm => {
      panes.push({
        menuItem: this.getMenuItemTime(comm.status),
        render: () => this.renderTabContent(comm),
      });
    });
    return panes.length > 0 && <Tab menu={{ secondary: true }} panes={panes} />;
  };

  stopPrintLoading = () => this.setState({ isPrintLoading: false });

  handlePrint = () => {
    return this.setState({ isPrintLoading: true }, () => {
      printJS({
        printable: "keylocker-communication-agreement-content",
        type: "html",
        copyStyles: true,
        onLoadingEnd: this.stopPrintLoading,
        onError: this.stopPrintLoading,
      });
    });
  };

  render() {
    const { t, appointment } = this.props;

    if (!Array.isArray(appointment.keylocker_communications)) return null;

    return (
      <>
        <Modal
          closeOnDimmerClick={false}
          open={this.state.isOpen}
          onOpen={this.handleToggleAgreement}
          onClose={this.handleToggleAgreement}
          className="KeylockerCommunicationLog-Modal"
          trigger={
            <div className="comm-agreement-label">
              <Label>
                <Icon name="user" />
              </Label>
              <span className="-margin-right-15">{t("keylocker_comm_log").message || "Keylocker communication log"}</span>
            </div>
          }
        >
          <Modal.Header>
            <Icon name="list" />
            {t("keylocker_comm_log").message || "Keylocker communication log"}
            <Button onClick={this.handlePrint} loading={this.state.isPrintLoading} className="print-btn" color="green">
              {t("print").message || "Print"}
            </Button>
          </Modal.Header>
          <Modal.Content scrolling id="keylocker-communication-agreement-content">
            {this.renderAppointmentInformation()}
            {this.renderTabs()}
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.handleToggleAgreement}>{t("close").message || "Close"}</Button>
          </Modal.Actions>
        </Modal>
        {this.renderAgreementModal()}
      </>
    );
  }
}

export default withTranslation()(KeylockerCommunicationLog);
