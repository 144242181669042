import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import ReactTable, { ReactTableDefaults } from "react-table";
import { Icon, Popup } from "semantic-ui-react";
import moment from "moment";
import Lightbox from "react-image-lightbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";

import { Snooze, CustomersCard } from "../../../components";
import { ANSWER_STATUSES, removeGenerationParam } from "../../../util/common";
import { renderMedia } from "../../Appointments/common";

class NotSnoozedTable extends Component {
  state = {
    photoIndex: 0,
    images: [],
    videos: [],
    isImageGalleryVisible: false,
  };

  handleAppointmentClick = result => {
    if (this.props.externalLink) {
      window.open(`${document.location.origin}/#!/appointments/${result.appointment_id}`, "_blank");
      return;
    }
    this.props.onAppointmentClick(result.appointment);
  };

  handleCarClick = result => {
    if (this.props.externalLink) {
      window.open(`${document.location.origin}/#!/appointments/car/${result.car_id}`, "_blank");
      return;
    }

    this.props.onCarClick(result.car_id, result.dealer_location_id);
  };

  renderStatus = status => {
    if (typeof status === "undefined" || status === "" || status === -1) {
      status = 4;
    }

    let backgroundColor = ANSWER_STATUSES[status] ? ANSWER_STATUSES[status].color : "#fff";
    let style = {
      backgroundColor,
      color: "white",
    };

    return (
      <div className="QuestionStatus" style={style}>
        {ANSWER_STATUSES[status].text}
      </div>
    );
  };

  renderMediaButton = d => {
    if (!d.images && !d.video) return null;

    return (
      <Popup
        basic
        on="click"
        hideOnScroll
        className="Snooze_media_popup"
        position="left center"
        wide="very"
        flowing
        style={{ zIndex: "999" }}
        onOpen={() => this.setState({ images: d.images, videos: d.video })}
        content={<div>{renderMedia(true, d, this.state.images, this.state.videos, this.handleMediaToggle, null, false)}</div>}
        trigger={
          <div className="ui teal label" style={{ width: "auto", cursor: "pointer" }}>
            <Icon name="images" style={{ margin: "auto" }} /> {(d.images ? d.images.length : 0) + (d.video ? d.video.length : 0)}
          </div>
        }
      />
    );
  };

  handleMediaToggle = () => {
    this.setState({ isImageGalleryVisible: true });
  };

  renderImageGallery = () => {
    const { photoIndex, images } = this.state;

    return (
      <Lightbox
        mainSrc={removeGenerationParam(images[photoIndex].url)}
        nextSrc={removeGenerationParam(images[(photoIndex + 1) % images.length].url)}
        prevSrc={removeGenerationParam(images[(photoIndex + images.length - 1) % images.length].url)}
        onCloseRequest={() => this.setState({ isImageGalleryVisible: false })}
        onMovePrevRequest={() =>
          this.setState({
            photoIndex: (photoIndex + images.length - 1) % images.length,
          })
        }
        onMoveNextRequest={() =>
          this.setState({
            photoIndex: (photoIndex + 1) % images.length,
          })
        }
      />
    );
  };

  getOptionalColumns = () => {
    const optionalColumns = [];
    const { globalState, t } = this.props;

    if (this.props.showLocationColumn)
      optionalColumns.push({
        Header: t("Location").message || "Location",
        id: "location",
        maxWidth: 100,
        accessor: data => {
          if (Array.isArray(globalState.dealers)) {
            let location = null;
            globalState.dealers.some(d => {
              if (Array.isArray(d.locations)) {
                location = d.locations.find(l => l.id === data.dealer_location_id);
                return !!location;
              }

              return false;
            });

            if (location) return location.name;
          }

          return "";
        },
      });

    return optionalColumns;
  };

  handleOnSnooze = (groupedItem, newSnoozeLog) => {
    const { onSnoozeChanged } = this.props;

    if (newSnoozeLog?.length < 1) return onSnoozeChanged(newSnoozeLog);
    newSnoozeLog[0].appointment = groupedItem.appointment;

    if (groupedItem.intervention) newSnoozeLog[0].intervention = groupedItem.intervention;
    else if (groupedItem.question_result) newSnoozeLog[0].question_result = groupedItem.question_result;

    onSnoozeChanged(newSnoozeLog);
  };

  renderTable = () => {
    const { t, items, page, pages, nb_items, loading, onFetchData, externalLink } = this.props;

    if (!items || items.length < 1) return <div className="Table__no-results">{t("no_leads_found").message || "No leads found"}</div>;

    return (
      <ReactTable
        className="SnoozeTable -floated-table"
        data={items || []}
        page={page - 1}
        pages={pages === null ? -1 : pages}
        showPagination={pages > 1}
        renderPageJump={({ onChange, value, onBlur, onKeyPress, inputType, pageJumpText }) => (
          <div className="-pageJump">
            <input
              aria-label={pageJumpText}
              type={inputType}
              onChange={evt => {
                onChange(evt);

                const newPage = evt.target.value - 0;
                if (!Number.isNaN(newPage) && newPage > 0 && newPage <= pages) onFetchData(newPage);
              }}
              value={page}
              onBlur={onBlur}
              onKeyPress={onKeyPress}
            />
          </div>
        )}
        loading={loading}
        manual
        onFetchData={state => onFetchData(state.page + 1)}
        showPageSizeOptions={false}
        sortable={false}
        resizable={false}
        minRows={0}
        loadingText=""
        nextText={t("next").message || "Next"}
        previousText={t("previous").message || "Previous"}
        pageText={t("page").message || "Page"}
        ofText={t("of").message || "of"}
        onPageChange={() => {
          try {
            document.querySelector(".App__module").scrollTo(0, 0);
          } catch (e) {
            document.querySelector(".App__module").scrollTop = 0; // IE Fix
          }
        }}
        column={{
          ...ReactTableDefaults.column,
          headerClassName: "ReactTable__column-header -text-ellipsis",
          className: "ReactTable__column",
        }}
        columns={[
          {
            Header: t("Appointment_date").message || "Appointment Date",
            id: "appointmentDate",
            accessor: d =>
              d.appointment.time_car_app && (moment.isMoment(d.appointment.time_car_app) || !d.appointment.time_car_app.startsWith("0001-01-01T00:00:00")) // might be time zoned
                ? moment(d.appointment.time_car_app).format("DD-MM-YYYY")
                : "",
            maxWidth: 135,
          },
          {
            id: "woNr",
            Header: (
              <>
                {t("WO Nr").message || "WO"} <span className="total-appointments">{nb_items}</span>
              </>
            ),
            accessor: d =>
              d.appointment.wo_nr === 0 ? (
                ""
              ) : (
                <span className="-cursor-pointer" onClick={() => this.handleAppointmentClick(d)}>
                  {externalLink ? <FontAwesomeIcon icon={faExternalLinkAlt} style={{ marginRight: "5px", fontSize: "15px" }} /> : <Icon name="info circle" />}
                  {d.appointment.wo_nr}
                </span>
              ),
            maxWidth: 100,
          },

          ...this.getOptionalColumns(),

          {
            id: "regNo",
            Header: t("Reg Nr").message,
            accessor: d =>
              d.appointment.reg_number && (
                <span className="-cursor-pointer" onClick={() => this.handleCarClick(d)}>
                  {externalLink ? <FontAwesomeIcon icon={faExternalLinkAlt} style={{ marginRight: "5px", fontSize: "15px" }} /> : <Icon name="info circle" />}
                  {d.appointment.reg_number}
                </span>
              ),
            maxWidth: 120,
          },

          {
            id: "intervention",
            Header: t("intervention_question_result").message || "Intervention/Question Result",
            accessor: d => (d.question_result ? <span>{d.question_result.title}</span> : <span>{d.intervention.title}</span>),
          },
          {
            Header: t("note").message || "Note",
            id: "note",
            accessor: d => d.question_result?.raw || d.intervention?.description || "",
          },
          {
            id: "media",
            Header: "",
            accessor: d => (d.question_result ? this.renderMediaButton(d.question_result) : this.renderMediaButton(d.intervention)),
            maxWidth: 49,
          },
          {
            id: "status",
            Header: "",
            accessor: d => {
              if (d.intervention) {
                return null;
              }
              return this.renderStatus(d.question_result.status);
            },
            maxWidth: 42,
          },
          {
            id: "user",
            Header: "",
            accessor: d =>
              !d.appointment.customer_contract && !d.appointment.customer_driver && !d.appointment.customer_owner ? (
                <div className="QuestionStatus" style={{ visibility: "hidden" }} />
              ) : (
                <CustomersCard contractor={d.appointment.customer_contract} driver={d.appointment.customer_driver} owner={d.appointment.customer_owner} />
              ),
            maxWidth: 42,
          },
          {
            id: "snoozer",
            Header: "",
            className: "-text-overflow-unset",
            Cell: row => (
              <Snooze
                question_result={row.original.question_result}
                intervention={row.original.intervention}
                appointment={row.original.appointment}
                snoozeLog={row.original.history}
                onSnooze={snoozeLog => this.handleOnSnooze(row.original, snoozeLog)}
                onDelete={reason => this.props.onSnoozeDeleted(row.original, reason)}
              />
            ),
            maxWidth: 42,
          },
        ]}
      />
    );
  };

  render() {
    return (
      <div className="SnoozeTableContainer">
        {this.renderTable()}
        {this.state.isImageGalleryVisible && this.renderImageGallery()}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { globalState: state.global, authState: state.auth };
};

export default withTranslation()(connect(mapStateToProps)(NotSnoozedTable));
