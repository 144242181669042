import React from "react";
import ReactTable, { ReactTableDefaults } from "react-table";
import { withTranslation } from "react-i18next";
import { Icon, Label } from "semantic-ui-react";

import { formatDateIfValid } from "../Appointments/util";

const UsersTable = props => {
  let { users, t, rolesArray, dealersArray, locationsArray } = props;

  const renderRole = user => {
    let role = rolesArray[user.role_id];
    return role ? role : "";
  };

  const renderDealer = user => {
    let dealer = dealersArray[user.dealer_id];
    return dealer ? dealer : "";
  };

  const renderLocation = user => {
    let location = locationsArray[user.dealer_location_id];
    return location ? location : "";
  };

  return (
    <div className="UsersTable">
      <ReactTable
        className="ReactTable -floated-table custom-users -contained-large"
        data={users || []}
        showPagination={true}
        showPageSizeOptions={false}
        sortable={false}
        resizable={false}
        minRows={0}
        pageSize={100}
        nextText={t("next").message || "Next"}
        previousText={t("previous").message || "Previous"}
        pageText={t("page").message || "Page"}
        ofText={t("of").message || "of"}
        noDataText={
          <div className="Table__no-results">
            <Icon disabled name="users" style={{ fontSize: "1.75em", marginTop: "250px" }} />
            <p>{t("no_users").message || "No users"}</p>
          </div>
        }
        getTdProps={(state, rowInfo, column, instance) => {
          return {
            onClick: e => {
              props.onRowClick(column.id, rowInfo);
            },
          };
        }}
        column={{
          ...ReactTableDefaults.column,
          headerClassName: "ReactTable__column-header",
          className: "ReactTable__column",
        }}
        columns={[
          {
            id: "index",
            Cell: row => {
              return (
                <div
                  className="users-table-count"
                  style={{ backgroundColor: row.original.status === 3 || row.original.status === 4 ? "red" : row.original.status === 2 ? "orange" : "green" }}
                >
                  {row.index + 1}
                </div>
              );
            },
            minWidth: 60,
          },
          {
            id: "paired",
            Header: t("paired").message || "Paired",
            accessor: d =>
              (d.planit_planning_mechanic_id || d.onei_planning_monteur_id || d.dms_nr) && (
                <div className="paired-icon">
                  <Icon name="linkify" color="green" />
                </div>
              ),
            width: 60,
          },
          {
            id: "name",
            Header: t("name").message || "Name",
            Cell: row => (
              <div
                style={{
                  color: row.original.status === 3 || row.original.status === 4 ? "red" : row.original.status === 2 ? "orange" : "black",
                  fontWeight: row.original.status === 2 || row.original.status === 3 || row.original.status === 4 ? "bold" : "normal",
                }}
              >
                {row.value}
              </div>
            ),
            accessor: d => (
              <span>
                {`${d.first_name} ${d.last_name}`}
                {d.dpo && (
                  <Label style={{ marginLeft: "5px" }} size="mini" color="blue">
                    DPO
                  </Label>
                )}
              </span>
            ),
          },
          {
            Header: t("username").message || "Username",
            accessor: "username",
            minWidth: 100,
          },
          {
            id: "dealer",
            Header: t("dealer").message || "Dealer",
            accessor: d => <span>{renderDealer(d)}</span>,
          },
          {
            id: "location",
            Header: t("location").message || "Location",
            accessor: d => <span>{renderLocation(d)}</span>,
            minWidth: 100,
          },
          {
            id: "role",
            Header: t("role").message || "Role",
            accessor: d => <b>{renderRole(d)}</b>,
          },
          {
            Header: t("email").message || "Email",
            accessor: "email",
          },
          {
            Header: t("mobile").message || "Mobile",
            accessor: "mobile",
            minWidth: 100,
          },
          {
            Header: t("phone").message || "Phone",
            accessor: "phone",
            minWidth: 100,
          },
          {
            Header: t("web_version").message || "Web version",
            accessor: "web_version",
            minWidth: 80,
            maxWidth: 120,
          },
          {
            Header: t("app_version").message || "App version",
            accessor: "app_version",
            minWidth: 80,
            maxWidth: 120,
          },
          {
            Header: t("camera_version").message || "Camera version",
            accessor: "camera_version",
            minWidth: 80,
            maxWidth: 120,
          },
          {
            id: "lastActive",
            Header: t("last_active").message || "Last active",
            accessor: d => <span>{formatDateIfValid(d.last_active, "D.M.YY | HH:mm:ss")}</span>,
            minWidth: 120,
          },
        ]}
      />
    </div>
  );
};

export default withTranslation()(UsersTable);
