import { createStore, applyMiddleware } from "redux";
import { persistStore, persistCombineReducers, persistReducer } from "redux-persist";
import storage from "redux-persist/es/storage";
import thunk from "redux-thunk";

import { AuthStore, AppStore, AppointmentsStore, CarsStore, WebSocketStore, WarrantyStore, DMSStore, TyreTeamDiscountStore } from "./modules";

const PERSIST_CONFIG = {
  key: "root",
  storage,
  blacklist: ["appointments"],
};

const APP_PERSIST_CONFIG = {
  key: "global",
  storage: storage,
  blacklist: ["selectedLocation"],
};

const REDUCER = persistCombineReducers(PERSIST_CONFIG, {
  auth: AuthStore,
  global: persistReducer(APP_PERSIST_CONFIG, AppStore),
  appointments: AppointmentsStore,
  cars: CarsStore,
  webSocket: WebSocketStore,
  warranty: WarrantyStore,
  dms: DMSStore,
  tyreTeamDiscount: TyreTeamDiscountStore,
});

export default function configureStore() {
  let store = createStore(REDUCER, undefined, applyMiddleware(thunk));
  let persistor = persistStore(store);

  return { persistor, store };
}
