import React, { Component } from "react";
import { Button, Icon, Label } from "semantic-ui-react";
import moment from "moment";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTire } from "@fortawesome/pro-light-svg-icons";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

import AppService from "./../../modules/Appointments/service";
import { Can } from "../../modules";
import "./DBBButton.css";

class DBBButton extends Component {
  getDBBLink = () => {
    if (!this.props.onError) return;

    if (!this.props.appointment.id) return this.props.onError("No appointment ID provided");

    AppService.getDBBLink({ id: this.props.appointment.id })
      .then(result => {
        if (!result || !result.data || !result.data.data) {
          this.props.onError("An unknown error occured, please try again");
        }

        let t = window.open(result.data.data, "_blank");
        t.focus();
      })
      .catch(error => {
        let errorMsg = error.response && error.response.data ? error.response.data.errors[0] : "Error getting DBB link";
        this.props.onError(errorMsg);
        console.warn("Error getting DBB link for car.", error);
      });
  };

  refreshDBB = () => {
    if (!this.props.onError) return;

    if (!this.props.appointment.id) return this.props.onError("No appointment ID provided");

    AppService.refreshDBB({ id: this.props.appointment.id })
      .then(result => {
        if (!result || !result.data || !result.data.data) {
          this.props.onError("An unknown error occured, please try again");
        }

        this.props.onCarUpdate(result.data.data);
      })
      .catch(error => {
        let errorMsg = error.response && error.response.data ? error.response.data.errors[0] : "Error refreshing DBB";
        this.props.onError(errorMsg);
        console.warn("Error refreshing DBB", error);
      });
  };

  handlePortalClick = () => {
    this.getDBBLink();
  };

  handleRefreshClick = () => {
    this.refreshDBB();
  };

  render() {
    let { appointment } = this.props;
    let status = "no-dbb";
    let icon = false;
    let iconColor = null;

    if (appointment.car.has_dbb) {
      if (appointment.car.dbb_appointment_date) {
        if (moment().isAfter(appointment.car.dbb_appointment_date)) {
          status = "valid-appointment";
        } else {
          status = "waiting-appointment";
        }

        if (appointment.final_car_check_dbb_status > 0) {
          icon = true;
          iconColor = appointment.final_car_check_dbb_status === 1 ? "green" : appointment.final_car_check_dbb_status === 2 ? "orange" : "red";
        }
      } else {
        status = "no-appointment";
      }
    }

    return (
      <Can I="dbb" the="appointments">
        <Button.Group basic fluid className="DBBButton">
          <Button basic size="large" className={`-status-${status}`} onClick={this.handlePortalClick}>
            {icon && (
              <Label className="checkedTire" circular color={iconColor}>
                <FontAwesomeIcon className="checkedTire-tire" icon={faTire} />
                <FontAwesomeIcon className="checkedTire-check" icon={faCheck} />
              </Label>
            )}

            <div className="button_text">
              <div>DBB</div>
              {status !== "no-dbb" && status !== "no-appointment" && (
                <div className="button_app_date">{moment(appointment.car.dbb_appointment_date).format("DD-MM-YYYY")}</div>
              )}
            </div>
          </Button>

          <Button basic icon onClick={this.handleRefreshClick}>
            <Icon name="refresh" />
          </Button>
        </Button.Group>
      </Can>
    );
  }
}

export default DBBButton;
