export const handleErrorMessage = error =>
  error.response && error.response.data
    ? error.response.data.error && error.response.data.error.message
      ? error.response.data.error.message
      : error.response.data.errors && error.response.data.errors.length > 0
      ? error.response.data.errors[0]
      : ""
    : error.message
    ? error.message
    : "";

export const NOTIFICATION_ENTITY_TEMPLATE = {
  CHECKBOXES: 1,
  DROPDOWN_MULTI: 2,
};

export const NOTIFICATION_ENTITIES = {
  NOTIFICATION_ENTITY_APPOINTMENT: 1,
  NOTIFICATION_ENTITY_STATUS_IDENTIFIER: 2,
};

export const NOTIFICATION_FIELD = {
  NOTIFICATION_FIELD_CAR_IN_SHOP: 1,
  NOTIFICATION_FIELD_CAR_OUT_OF_SHOP: 2,
  NOTIFICATION_FIELD_MECHANIC_NEEDS_HELP: 3,
  NOTIFICATION_FIELD_IS_MONEY: 4,
  NOTIFICATION_FIELD_IS_SHOP: 5,
  NOTIFICATION_FIELD_IS_STAR: 6,
  NOTIFICATION_FIELD_CANCELED_APPOINTMENT_STATUS: 7,
  NOTIFICATION_FIELD_NEWCAR_APPOINTMENT_STATUS: 8,
  NOTIFICATION_FIELD_CARCHECK_APPOINTMENT_STATUS: 9,
  NOTIFICATION_FIELD_PRICINGOK_APPOINTMENT_STATUS: 10,
  NOTIFICATION_FIELD_CUSTOMEROK_APPOINTMENT_STATUS: 11,
  NOTIFICATION_FIELD_CARREADY_APPOINTMENT_STATUS: 12,
  NOTIFICATION_FIELD_DIAGNOSE_APPOINTMENT_STATUS: 13,
  NOTIFICATION_FIELD_AANAME_APPOINTMENT_STATUS: 14,
  NOTIFICATION_FIELD_CALCULATING_APPOINTMENT_STATUS: 15,
  NOTIFICATION_FIELD_CONTACT_APPOINTMENT_STATUS: 16,
  NOTIFICATION_FIELD_CARCHECKSTARTED_APPOINTMENT_STATUS: 17,
  NOTIFICATION_FIELD_QUALITYCHECK_APPOINTMENT_STATUS: 18,
  NOTIFICATION_FIELD_CUSTOMERANSWERED_APPOINTMENT_STATUS: 19,
  NOTIFICATION_FIELD_HANDLECHECKIN_APPOINTMENT_STATUS: 20,
  NOTIFICATION_FIELD_CHECKINDONE_APPOINTMENT_STATUS: 21,
  NOTIFICATION_FIELD_CARINSHOP_APPOINTMENT_STATUS: 22,
  NOTIFICATION_FIELD_CAROUTOFSHOP_APPOINTMENT_STATUS: 23,
};
