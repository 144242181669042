import React from "react";
import { withTranslation } from "react-i18next";
import { Card, Popup, Icon } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

const CustomersCard = ({ contractor, driver, owner, t }) => {
  let noUser = null;

  if (!contractor && !driver && !owner) {
    noUser = true;
  }

  const renderLeaseCompanyTag = isLeaseCompany => {
    if (!isLeaseCompany) return null;

    return (
      <Card.Description
        content={
          <div className="lease-company-tag">
            <span>
              <Icon name="building" />
              {t("lease_company").message || "Lease company"}
            </span>
          </div>
        }
      />
    );
  };

  return (
    <Popup
      basic
      on="hover"
      position="left center"
      className="customer-popup"
      content={
        noUser ? (
          t("unknown_customer").message || "Unknown customer"
        ) : (
          <Card.Group>
            {contractor && (
              <Card>
                <Card.Content>
                  <Card.Header content={contractor.surname} />
                  <Card.Meta content={t("contractor").message || "Contractor"} />
                  {renderLeaseCompanyTag(contractor.is_lease_company)}
                  {contractor.tel_private_nr && <Card.Description content={`${t("private_phone").message || "Private phone"}: ${contractor.tel_private_nr}`} />}
                  {contractor.tel_business_nr && <Card.Description content={`${t("business_phone").message || "Business phone"}: ${contractor.tel_business_nr}`} />}
                  {contractor.tel_mobile_private && <Card.Description content={`${t("private_mobile").message || "Private mobile"}: ${contractor.tel_mobile_private}`} />}
                  {contractor.tel_mobile_business && (
                    <Card.Description content={`${t("business_mobile").message || "Business mobile"}: ${contractor.tel_mobile_business}`} />
                  )}
                  {contractor.email_private && <Card.Description content={`${t("private_email").message || "Private email"}: ${contractor.email_private}`} />}
                  {contractor.email_business && <Card.Description content={`${t("business_email").message || "Business email"}: ${contractor.email_business}`} />}
                </Card.Content>
              </Card>
            )}
            {driver && (
              <Card>
                <Card.Content>
                  <Card.Header content={driver.surname} />
                  <Card.Meta content={t("driver").message || "Driver"} />
                  {renderLeaseCompanyTag(driver.is_lease_company)}
                  {driver.tel_private_nr && <Card.Description content={`${t("private_phone").message || "Private phone"}: ${driver.tel_private_nr}`} />}
                  {driver.tel_business_nr && <Card.Description content={`${t("business_phone").message || "Business phone"}: ${driver.tel_business_nr}`} />}
                  {driver.tel_mobile_private && <Card.Description content={`${t("private_mobile").message || "Private mobile"}: ${driver.tel_mobile_private}`} />}
                  {driver.tel_mobile_business && <Card.Description content={`${t("business_mobile").message || "Business mobile"}: ${driver.tel_mobile_business}`} />}
                  {driver.email_private && <Card.Description content={`${t("private_email").message || "Private email"}: ${driver.email_private}`} />}
                  {driver.email_business && <Card.Description content={`${t("business_email").message || "Business email"}: ${driver.email_business}`} />}
                </Card.Content>
              </Card>
            )}
            {owner && (
              <Card>
                <Card.Content>
                  <Card.Header content={owner.surname} />
                  <Card.Meta content={t("owner").message || "Owner"} />
                  {renderLeaseCompanyTag(owner.is_lease_company)}
                  {owner.tel_private_nr && <Card.Description content={`${t("private_phone").message || "Private phone"}: ${owner.tel_private_nr}`} />}
                  {owner.tel_business_nr && <Card.Description content={`${t("business_phone").message || "Business phone"}: ${owner.tel_business_nr}`} />}
                  {owner.tel_mobile_private && <Card.Description content={`${t("private_mobile").message || "Private mobile"}: ${owner.tel_mobile_private}`} />}
                  {owner.tel_mobile_business && <Card.Description content={`${t("business_mobile").message || "Business mobile"}: ${owner.tel_mobile_business}`} />}
                  {owner.email_private && <Card.Description content={`${t("private_email").message || "Private email"}: ${owner.email_private}`} />}
                  {owner.email_business && <Card.Description content={`${t("business_email").message || "Business email"}: ${owner.email_business}`} />}
                </Card.Content>
              </Card>
            )}
          </Card.Group>
        )
      }
      trigger={
        <div className="QuestionStatus" style={{ backgroundColor: "purple", color: "white" }}>
          <FontAwesomeIcon icon={faUser} />
        </div>
      }
    />
  );
};

export default withTranslation()(CustomersCard);
