import React from "react";

import Controller from "./controller";
import KeyLocker from "./KeyLocker";

export default () => (
  <Controller>
    <KeyLocker />
  </Controller>
);
