import React from "react";
import { Label } from "semantic-ui-react";

import {
  QuestionElementDropdown,
  QuestionElementDatePicker,
  QuestionElementSolution,
  QuestionElementSpinner,
  QuestionElementTextarea,
  QuestionElementTextfield,
} from "./Elements/";

import "./QuestionElementSingle.css";

const QuestionElementSingle = ({
  element,
  onElementConfigurationChanged,
  elementDropdownNewItemLabel,
  onElementDropdownNewItemLabelChanged,
  onElementDropdownItemAdded,
  onElementDropdownItemRemoved,
  onElementDropdownKeyPress,
  onElementDropdownSortEnd,
  elementSolutionNewItemLabels,
  onElementSolutionNewItemLabelsChanged,
  onElementSolutionItemAdded,
  onElementSolutionItemRemoved,
  onElementSolutionSortEnd,
}) => {
  let renderElementConfiguration = () => {
    if (!element.default_configuration_parsed) {
      return <p>No configuration for this element.</p>;
    }

    if (element.template === "textfield") {
      return (
        <QuestionElementTextfield
          id={element.id}
          configuration={element.default_configuration_parsed}
          configurationChanged={onElementConfigurationChanged}
          defaultVisible={true}
        />
      );
    }

    if (element.template === "number") {
      return (
        <QuestionElementTextfield
          id={element.id}
          configuration={element.default_configuration_parsed}
          configurationChanged={onElementConfigurationChanged}
          defaultVisible={true}
          numberOnly={true}
        />
      );
    }

    if (element.template === "datepicker") {
      return (
        <QuestionElementDatePicker
          id={element.id}
          configuration={element.default_configuration_parsed}
          configurationChanged={onElementConfigurationChanged}
          defaultVisible={false}
        />
      );
    }

    if (element.template === "textarea") {
      return <QuestionElementTextarea id={element.id} configuration={element.default_configuration_parsed} configurationChanged={onElementConfigurationChanged} />;
    }

    if (element.template === "spinner") {
      return <QuestionElementSpinner id={element.id} configuration={element.default_configuration_parsed} configurationChanged={onElementConfigurationChanged} />;
    }

    if (element.template === "dropdown" || element.template === "dropdown_multi") {
      return (
        <QuestionElementDropdown
          id={element.id}
          configuration={element.default_configuration_parsed}
          configurationChanged={onElementConfigurationChanged}
          dropdownNewItemLabel={elementDropdownNewItemLabel}
          onDropdownNewItemLabelChanged={onElementDropdownNewItemLabelChanged}
          onDropdownItemAdded={onElementDropdownItemAdded}
          onDropdownItemRemoved={onElementDropdownItemRemoved}
          onDropdownNewItemKeyPress={onElementDropdownKeyPress}
          onDropdownSortEnd={onElementDropdownSortEnd}
        />
      );
    }

    if (element.template === "solution_element") {
      return (
        <QuestionElementSolution
          id={element.id}
          configuration={element.default_configuration_parsed}
          onConfigurationChanged={onElementConfigurationChanged}
          solutionNewItemLabels={elementSolutionNewItemLabels}
          onSolutionNewItemLabelsChanged={onElementSolutionNewItemLabelsChanged}
          onSolutionItemAdded={onElementSolutionItemAdded}
          onSolutionItemRemoved={onElementSolutionItemRemoved}
          onSolutionSortEnd={onElementSolutionSortEnd}
        />
      );
    }
  };

  return (
    <div className="QuestionElement">
      <div className="element-info">
        <h3>{element.name}</h3>
        <Label color="green" className="-pull-right">
          {element.template}
        </Label>
      </div>

      <div className="element-configuration">{renderElementConfiguration()}</div>
    </div>
  );
};

export default QuestionElementSingle;
