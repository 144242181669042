import React from "react";
import { Form } from "semantic-ui-react";
import { withTranslation } from "react-i18next";

import DealerLocationsDropdown from "../../components/DealerLocationsDropdown";

const BackOrderFilters = ({ onChangeSelectedLocationIDs, t }) => {
  return (
    <Form>
      <Form.Field width={6}>
        <DealerLocationsDropdown selectAll={false} selectDealers={true} onChange={onChangeSelectedLocationIDs} />
      </Form.Field>
    </Form>
  );
};

export default withTranslation()(BackOrderFilters);
