import React, { Component } from "react";
import AlertContainer from "react-alert";
import { withTranslation } from "react-i18next";
import { Modal, Form, Button, Checkbox, Icon, Dropdown, Label, Message } from "semantic-ui-react";
import ReactTable, { ReactTableDefaults } from "react-table";

import Service from "./service";
import { SEASONS } from "../Appointments/common";
import { CustomConfirm } from "../../components";
import { getTireSeasonName } from "../../util/common";

import "./TireModal.css";

const PAGE_LENGTH = 8;
class TireModal extends Component {
  state = {
    completeTires: [],
    initialTire: { ...this.props.tire },
    replacementTire: {},
    isLoadingCompleteTires: false,
    missingFields: false,
    showDeleteTireConfirmation: false,
    showReplaceConfirmation: false,
    isDeletingTire: false,
  };

  componentDidMount = () => {
    this.handleSearchTire();
  };

  handleInputChange = e => {
    let { tire, handleChange } = this.props;
    if (Object.keys(tire).length === 0) return;
    tire[e.target.name] = e.target.value.toUpperCase();
    handleChange(tire);

    if (["speed"].includes(e.target.name)) {
      this.handleSearchTire();
    }
  };

  handleDropdownChange = (_, { name, value }) => {
    const { tire, handleChange } = this.props;

    if (Object.keys(tire).length === 0) return;

    tire[name] = value;
    handleChange(tire);

    this.handleSearchTire();
  };

  handleNumberChange = e => {
    let { tire, handleChange } = this.props;
    if (Object.keys(tire).length === 0) return;
    tire[e.target.name] = Number(e.target.value) ? Number(e.target.value) : 0;
    handleChange(tire);

    if (e.target.name !== "carry_capacity") {
      this.handleSearchTire();
    }
  };

  handleFloatChange = e => {
    let { tire, handleChange } = this.props;

    if (Object.keys(tire).length === 0) return;
    tire[e.target.name] = e.target.value.endsWith(".") ? e.target.value : parseFloat(e.target.value);
    handleChange(tire);

    if (!e.target.value.endsWith(".")) this.handleSearchTire();
  };

  handleSeasonChange = (e, data) => {
    let { tire, handleChange } = this.props;
    if (Object.keys(tire).length === 0) return;
    tire[data.name] = data.value;
    handleChange(tire);
    this.handleSearchTire();
  };

  handleCheckboxChange = (e, data) => {
    let { tire, handleChange } = this.props;
    if (Object.keys(tire).length === 0) return;
    tire[data.name] = data.checked;
    handleChange(tire);
  };

  handleDeleteTire = () => {
    const { tire, onClose, onDelete, setAlert } = this.props;
    const { id } = tire;

    this.setState({ isDeletingTire: true }, () => {
      Service.deleteTire(id)
        .then(response => {
          onDelete(id);
          this.setState({ showDeleteTireConfirmation: false, isDeletingTire: false, deletingTireError: "" }, () => {
            onClose();
          });
        })
        .catch(err => {
          this.setState({ isDeletingTire: false }, () => {
            setAlert({ type: "error", title: err.response?.data?.errors?.length ? err.response.data.errors[0] : "Error Occurred" });
          });
        });
    });
  };

  handleSearchTire = () => {
    const { tire, setAlert } = this.props;

    if (tire.season === SEASONS.NO_SEASON) tire.season = null;

    const size = parseFloat(tire.size) ? parseFloat(tire.size) : 0;

    this.setState({ isLoadingCompleteTires: true }, () => {
      Service.getCompleteTires({ ...tire, size })
        .then(response => {
          this.setState({ completeTires: response.data.data, isLoadingCompleteTires: false });
        })
        .catch(err => {
          console.log(err);
          this.setState({ isLoadingCompleteTires: false }, () => {
            setAlert({ type: "error", title: err.response?.data?.errors?.length ? err.response.data.errors[0] : "Error Occurred" });
          });
        });
    });
  };

  validateForm = () => {
    const { manufacturer, description, width, size, speed, height, ean, carry_capacity } = this.props.tire;

    const validateEan = /^[0-9]{13}$/.test(ean);

    const validateManufacturer = this.props.manufacturersList.some(m => m.key === manufacturer);

    return validateManufacturer && description && width && size && speed && height && carry_capacity && validateEan;
  };

  handleSaveTire = () => {
    if (!this.validateForm()) {
      this.setState({ missingFields: true });
    } else {
      this.props.onComplete();
    }
  };

  handleReplaceTire = id => {
    if (!this.state.isLoadingCompleteTires) {
      this.props.onReplace(id);
    }
  };

  handleShowReplaceConfirmation = replacementTire => {
    this.setState({ showReplaceConfirmation: true, replacementTire });
  };

  handleHideReplaceConfirmation = () => {
    this.setState({ showReplaceConfirmation: false, replacementTire: {} });
  };

  toggleShowHideTireDeletionConfirmation = () => {
    this.setState(prevState => ({ showDeleteTireConfirmation: !prevState.showDeleteTireConfirmation }));
  };

  renderTireRow = tire => {
    const { t } = this.props;

    return (
      <>
        <div className="tire-reference-header">
          <label className="row-width-90">{t("manufacturer").message || "Manufacturer"}</label>
          <label className="row-width-70">{t("width").message || "Width"}</label>
          <label className="row-width-70">{t("size").message || "Size"}</label>
          <label className="row-width-70"> {t("speed").message || "Speed"}</label>
          <label className="row-width-70">{t("height").message || "Height"}</label>
          <label className="row-width-70">{t("season").message || "Season"}</label>
          <label className="row-width-70">{t("ean").message || "EAN"}</label>
          <label>{t("description").message || "Description"}</label>
        </div>
        <div className="tire-reference-row">
          <span className="row-width-90">{tire.manufacturer}</span>
          <span className="row-width-70">{tire.width}</span>
          <span className="row-width-70">{tire.size}</span>
          <span className="row-width-70"> {tire.speed}</span>
          <span className="row-width-70">{tire.height}</span>
          <span className="row-width-70">{getTireSeasonName(tire.season, t)}</span>
          <span className="row-width-70">{tire.ean}</span>
          <span>{tire.description}</span>
        </div>
      </>
    );
  };

  renderReplaceConfirmation = () => {
    const { isLoading, t } = this.props;
    const { replacementTire, initialTire, showReplaceConfirmation } = this.state;

    if (replacementTire) {
      return (
        <Modal
          size="small"
          open={showReplaceConfirmation}
          header={<div className={"headerWarning"}>{t("are_you_sure").message || "Are you sure?"}</div>}
          className="confirm-replace-tire"
          closeOnDimmerClick={false}
          content={
            <div className="outer-content-box">
              <div className="tire-reference-group">
                <div className="inner-content-box">
                  <p>{t("incomplete_tire").message || "Incomplete tire"}</p>
                  {this.renderTireRow(initialTire)}
                </div>

                <div className="inner-content-box replace-tire">
                  <p>{t("replace_with").message || "Replace with"}</p>
                  {this.renderTireRow(replacementTire)}
                </div>
                <br />
              </div>
            </div>
          }
          actions={[
            <Button key={1} basic color="grey" className="customButton" content={t("cancel").message || "Cancel"} onClick={this.handleHideReplaceConfirmation} />,
            <Button
              key={2}
              loading={isLoading}
              disabled={isLoading}
              content={t("confirm").message || "Confirm"}
              className={"customButton confirmButtonWarning"}
              onClick={() => this.handleReplaceTire(replacementTire.id)}
            />,
          ]}
        />
      );
    }
  };

  renderCompleteTires = () => {
    const { completeTires, isLoadingCompleteTires } = this.state;
    const { t } = this.props;

    if (isLoadingCompleteTires) {
      return (
        <div className="Table__loading Loader-Placeholder">
          <div className="bounce1"></div>
          <div className="bounce2"></div>
          <div className="bounce3"></div>
          <section>{t("loading_tires").message || "Loading tires"}</section>
        </div>
      );
    } else {
      return (
        <div className="inner-table-wrapper">
          <ReactTable
            className="ReactTable -floated-table -contained-large"
            data={completeTires}
            showPagination={completeTires.length > PAGE_LENGTH}
            showPageSizeOptions={false}
            sortable={false}
            resizable={false}
            defaultPageSize={completeTires.length < PAGE_LENGTH ? completeTires.length : PAGE_LENGTH}
            pageSize={completeTires.length < PAGE_LENGTH ? completeTires.length : PAGE_LENGTH}
            noDataText={
              <div className="Table__no-results">
                <p>{t("no_results").message || "No results"}</p>
              </div>
            }
            column={{
              ...ReactTableDefaults.column,
              headerClassName: "ReactTable__column-header",
              className: "ReactTable__column",
            }}
            columns={[
              {
                Header: completeTires && completeTires.length > 0 ? t("list_complete_tires").message || "List of complete tires" : null,
                columns: [
                  {
                    id: "manufacturer",
                    accessor: "manufacturer",
                    Header: t("manufacturer").message || "Manufacturer",
                    width: 160,
                  },
                  {
                    id: "width",
                    accessor: "width",
                    Header: t("width").message || "Width",
                    width: 60,
                  },
                  {
                    id: "size",
                    accessor: "size",
                    Header: t("size").message || "Size",
                    width: 60,
                  },
                  {
                    id: "speed",
                    accessor: "speed",
                    Header: t("speed").message || "Speed",
                    width: 60,
                  },
                  {
                    id: "height",
                    accessor: "height",
                    Header: t("height").message || "Height",
                    width: 60,
                  },
                  {
                    id: "season_name",
                    accessor: tire => {
                      if (tire.season === SEASONS.ALL_SEASONS) return t("all_seasons").message || "All seasons";
                      else if (tire.season === SEASONS.WINTER) return t("winter_season").message || "Winter";
                      else if (tire.season === SEASONS.SUMMER) return t("summer_season").message || "Summer";
                      else if (tire.season === SEASONS.NOT_AVAILABLE) return t("not_available").message || "Not available";
                    },
                    Header: t("season").message || "Season",
                    width: 80,
                  },
                  {
                    id: "description",
                    accessor: "description",
                    Header: t("description").message || "Description",
                    width: 260,
                  },
                  {
                    id: "confirm_button",
                    accessor: tire => (
                      <div className="confirm-button-wrapper">
                        <Label
                          color="green"
                          className="confirm-button"
                          onClick={() => {
                            this.handleShowReplaceConfirmation(tire);
                          }}
                        >
                          {t("replace").message || "Replace"}
                        </Label>
                      </div>
                    ),
                  },
                ],
              },
            ]}
          />
        </div>
      );
    }
  };

  renderDeleteConfirmation = () => {
    const { showDeleteTireConfirmation, isDeletingTire, deleteError } = this.state;
    const { t } = this.props;

    return (
      <CustomConfirm
        type="danger"
        isOpen={showDeleteTireConfirmation}
        handleCancel={this.toggleShowHideTireDeletionConfirmation}
        handleConfirm={this.handleDeleteTire}
        confirmMsg={t("confirm_delete_message").message || "Are you sure that you want to delete this? You can't undo this action."}
        isLoading={isDeletingTire}
        error={deleteError}
      />
    );
  };

  render() {
    const { t, tire, isOpen, onClose, isLoading, errorMessage, manufacturersList } = this.props;
    const { completeTires, showDeleteTireConfirmation, showReplaceConfirmation, missingFields } = this.state;

    if (!tire) return null;

    return (
      <div>
        <Modal className="incomplete-tires-modal" open={isOpen} onClose={onClose} closeOnDimmerClick={false}>
          <Modal.Header>{t("edit_tire").message || "Edit tire"}</Modal.Header>
          <Modal.Content scrolling>
            <AlertContainer />
            <Form>
              <Form.Group widths="equal">
                <Form.Field required>
                  <label htmlFor="manufacturer">{t("manufacturer").message || "Manufacturer"}</label>
                  <Dropdown
                    id="manufacturer"
                    selectOnBlur={false}
                    selection
                    labeled
                    name="manufacturer"
                    onChange={this.handleDropdownChange}
                    options={manufacturersList}
                    value={tire.manufacturer || ""}
                  />
                  {missingFields && (!manufacturersList.some(m => m.key === tire.manufacturer) || !tire.manufacturer) && (
                    <Label basic color="red" pointing>
                      {t("field_is_required").message || "This field is required."}
                    </Label>
                  )}
                </Form.Field>
                <Form.Field>
                  <Form.Input
                    required
                    name="description"
                    label={t("description").message || "Description"}
                    onChange={this.handleInputChange}
                    value={tire.description || ""}
                  />
                  {missingFields && !tire.description && (
                    <Label basic color="red" pointing>
                      {t("field_is_required").message || "This field is required."}
                    </Label>
                  )}
                </Form.Field>
              </Form.Group>

              <Form.Group>
                <Form.Field width={2}>
                  <Form.Input required name="width" label={t("width").message || "Width"} onChange={this.handleNumberChange} value={tire.width || ""} />
                  {missingFields && !tire.width && (
                    <Label basic color="red" pointing>
                      {t("field_is_required").message || "This field is required."}
                    </Label>
                  )}
                </Form.Field>
                <Form.Field width={2}>
                  <Form.Input required={true} name="size" label={t("size").message || "Size"} onChange={this.handleFloatChange} value={tire.size || ""} />
                  {missingFields && !tire.size && (
                    <Label basic color="red" pointing>
                      {t("field_is_required").message || "This field is required."}
                    </Label>
                  )}
                </Form.Field>
                <Form.Field width={2}>
                  <Form.Input required name="speed" label={t("speed").message || "Speed"} onChange={this.handleInputChange} value={tire.speed || ""} />
                  {missingFields && !tire.speed && (
                    <Label basic color="red" pointing>
                      {t("field_is_required").message || "This field is required."}
                    </Label>
                  )}
                </Form.Field>
                <Form.Field width={2}>
                  <Form.Input required name="height" label={t("height").message || "Height"} onChange={this.handleNumberChange} value={tire.height || ""} />
                  {missingFields && !tire.height && (
                    <Label basic color="red" pointing>
                      {t("field_is_required").message || "This field is required."}
                    </Label>
                  )}
                </Form.Field>
                <Form.Field width={6}>
                  <Form.Input required name="ean" label={t("ean").message || "EAN"} onChange={this.handleInputChange} value={tire.ean || ""} />
                  {missingFields && !tire.ean && (
                    <Label basic color="red" pointing>
                      {t("field_is_required").message || "This field is required."}
                    </Label>
                  )}
                </Form.Field>
                <Form.Field width={2}>
                  <Form.Input
                    required
                    name="carry_capacity"
                    label={t("carry_capacity").message || "Carry capacity"}
                    onChange={this.handleNumberChange}
                    value={tire.carry_capacity || ""}
                  />
                  {missingFields && !tire.carry_capacity && (
                    <Label basic color="red" pointing>
                      {t("field_is_required").message || "This field is required."}
                    </Label>
                  )}
                </Form.Field>
              </Form.Group>

              <Form.Group widths="equal">
                <Form.Field>
                  <label>{t("season").message || "Season"} </label>
                  <Dropdown
                    required
                    name="season"
                    selection
                    fluid
                    options={[
                      {
                        text: t("all_seasons").message || "All Seasons",
                        value: 0,
                      },
                      {
                        text: t("winter_season").message || "Winter",
                        value: 1,
                      },
                      {
                        text: t("summer_season").message || "Summer",
                        value: 2,
                      },
                      {
                        text: t("not_available").message || "Not Available",
                        value: 3,
                      },
                    ]}
                    value={tire.season || 0}
                    onChange={this.handleSeasonChange}
                  />
                </Form.Field>

                <Form.Field>
                  <label>&nbsp;</label>
                  <Checkbox
                    className="tire-modal-checkbox inline"
                    toggle
                    name="run_flat_tire"
                    defaultChecked={tire.run_flat_tire || false}
                    label={t("run_flat_tire").message || "Run flat tire"}
                    onChange={this.handleCheckboxChange}
                  />
                </Form.Field>
              </Form.Group>
              {errorMessage && (
                <Form.Group>
                  <Form.Field width={16}>
                    <Message color="red">{errorMessage}</Message>
                  </Form.Field>
                </Form.Group>
              )}
            </Form>
            {completeTires && this.renderCompleteTires()}
          </Modal.Content>
          <Modal.Actions>
            <Button size="large" onClick={this.toggleShowHideTireDeletionConfirmation} floated="left" color="red">
              <Icon name="trash" className="-no-margin" />
            </Button>
            <Button size="large" onClick={onClose} color="grey">
              {t("discard").message || "Discard"}
            </Button>
            <Button size="large" loading={isLoading} onClick={this.handleSaveTire} color="green">
              {t("save").message || "Save"}
            </Button>
          </Modal.Actions>
        </Modal>
        {showReplaceConfirmation && this.renderReplaceConfirmation()}
        {showDeleteTireConfirmation && this.renderDeleteConfirmation()}
      </div>
    );
  }
}

export default withTranslation()(TireModal);
