import React, { Component } from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, LabelList, ResponsiveContainer } from "recharts";
import { extractAllNameFields } from "../../../util/common";

const COLORS = ["#278ecf", "#4bd762", "#ff402c", "#ff9416", "#d42ae8", "#535ad7", "#83bfff", "#ffca1f", "#6edb8f", "#ffe366", "#ffc266", "#8784db"];

class PercentStackedBarChart extends Component {
  isNumOrStr = value => typeof value === "number" || typeof value === "string";

  defaultFormatter = value => (Array.isArray(value) && this.isNumOrStr(value[0]) && this.isNumOrStr(value[1]) ? value.join(" ~ ") : value);

  CustomTooltip = ({ payload, separator, itemStyle, formatter, label, active }) => {
    if (!active) return null;

    return (
      <div
        className="recharts-default-tooltip"
        style={{ margin: 0, padding: 10, backgroundColor: "white", border: "1px solid rgb(204, 204, 204)", whiteSpace: "nowrap" }}
      >
        <p className="recharts-tooltip-label" style={{ margin: 0 }}>
          {label}
        </p>
        <ul className="recharts-tooltip-item-list" style={{ padding: 0, margin: 0 }}>
          {payload.reverse().map((entry, i) => {
            if (entry.type === "none") {
              return null;
            }

            const finalItemStyle = {
              display: "block",
              paddingTop: 4,
              paddingBottom: 4,
              color: entry.color || "#000",
              ...itemStyle,
            };

            const finalFormatter = entry.formatter || formatter || this.defaultFormatter;
            let { name, value } = entry;
            if (finalFormatter) {
              const formatted = finalFormatter(value, name, entry, i, payload);
              if (Array.isArray(formatted)) {
                [value, name] = formatted;
              } else {
                value = formatted;
              }
            }

            let valueTooltip = null;
            if (entry.payload && entry.payload["value." + entry.name]) {
              valueTooltip = entry.name + ": " + entry.payload["value." + entry.name];
            }

            return (
              <React.Fragment key={`tooltip-item-${i}`}>
                <li className="recharts-tooltip-item" style={finalItemStyle}>
                  {this.isNumOrStr(name) ? <span className="recharts-tooltip-item-name">{name}</span> : null}
                  {this.isNumOrStr(name) ? <span className="recharts-tooltip-item-separator">{separator}</span> : null}
                  <span className="recharts-tooltip-item-value">{value}</span>
                  <span className="recharts-tooltip-item-unit">{entry.unit || ""}</span>
                </li>
                {valueTooltip && (
                  <li className="recharts-tooltip-item" style={finalItemStyle}>
                    <span className="recharts-tooltip-item-value">{valueTooltip}</span>
                  </li>
                )}
              </React.Fragment>
            );
          })}
        </ul>
      </div>
    );
  };

  CustomBarLabel = params => {
    const { x, y, width, height, value } = params;

    if (height < 14) return null;

    return (
      <text x={x + width / 2} y={y + height / 2} fill="#000000" textAnchor="middle" dy={5}>
        {value}
      </text>
    );
  };

  render() {
    const { data, colors, t, unit } = this.props;

    let totals = [];
    data.forEach(d => {
      Object.keys(d).forEach(k => {
        if (k !== "name") totals[d.name] = (totals[d.name] || 0) + (d[k] || 0);
      });
    });

    let percents = [...data];
    percents.forEach((d, i) => {
      Object.keys(d).forEach(k => {
        if (k !== "name") {
          percents[i]["value." + k] = percents[i][k];
          percents[i][k] = ((percents[i][k] / totals[d.name]) * 100).toFixed(2);
        }
      });
    });

    return (
      <ResponsiveContainer width="100%" height={450} className={"full-width-margin-auto"}>
        <BarChart data={percents} margin={{ top: 20, right: 30, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis unit={unit} allowDecimals={false} allowDataOverflow={true} domain={[0, 100]} />
          <Tooltip content={this.CustomTooltip} />
          <Legend />

          {extractAllNameFields(percents)
            .filter(k => k !== "name" && !k.startsWith("value."))
            .map((k, i) => {
              const item = colors.find(col => (t(col.name).message || col.name) === k);
              const color = item ? item.color : null;

              return (
                <Bar stackId="a" key={i} dataKey={k} fill={color || COLORS[i % COLORS.length]} unit={unit} isAnimationActive={false}>
                  <LabelList dataKey={val => val["value." + k] || null} content={this.CustomBarLabel} />
                </Bar>
              );
            })}
        </BarChart>
      </ResponsiveContainer>
    );
  }
}

export default PercentStackedBarChart;
