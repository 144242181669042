import React, { Component } from "react";
import { Button, Form, Header, Label } from "semantic-ui-react";
import { withTranslation } from "react-i18next";

import DomainSegment from "./DomainSegment";
import Can from "../Can";
import { CustomConfirm, UserInput } from "../../components";

import "./DomainSettings.css";

class DomainSettings extends Component {
  state = {
    deleteConfirm: false,
  };

  handleInputChange = e => {
    let { dealer, onUpdateDealer } = this.props;
    if (Object.keys(dealer).length === 0) return;
    if (!dealer.domain) dealer.domain = {};

    dealer.domain[e.target.name] = e.target.value;
    onUpdateDealer(dealer);
  };

  render() {
    const { dealer, t, domainError, updateDomainError } = this.props;

    return (
      <Form className={"DomainSettings"}>
        <Form.Field className="full-width-input">
          <label>{t("domain_name").message || "Domain name"}</label>
          <UserInput
            label="no-reply@"
            value={dealer.domain ? dealer.domain.name : ""}
            name="name"
            placeholder={t("domain_name").message || "Domain name"}
            onChange={this.handleInputChange}
          />

          <div style={{ textAlign: "right" }}>
            <Button
              loading={this.props.isLoading}
              size="tiny"
              color="green"
              style={{ marginRight: "0", marginTop: "10px" }}
              content={t("update_domain").message || "Update domain"}
              disabled={!(dealer.domain && dealer.domain.name)}
              onClick={this.props.domainUpdate}
            />
          </div>

          {domainError && (
            <Label basic color="red" pointing>
              {t(updateDomainError).message || updateDomainError}
            </Label>
          )}
        </Form.Field>

        {dealer.domain && dealer.domain.sending_dns_records ? (
          <Form.Field style={{ marginTop: "40px" }} className="full-width-input">
            <Header as="h5" style={{ marginBottom: "0" }}>
              <Header.Content>{t("dns_records").message || "DNS records"}</Header.Content>
            </Header>

            <DomainSegment verified={dealer.domain.state === "active"} records={dealer.domain.sending_dns_records} t={t} />

            {dealer.domain.state !== "active" ? (
              <Form.Field className="full-width-input">
                <b>{t("domain_explanation").message || "Update your DNS with the above entries, and then click on 'Verify Domain' button"}</b>
              </Form.Field>
            ) : (
              <Form.Field className="full-width-input">
                <b>{t("domain_verified").message || "Domain is verified"}</b>
              </Form.Field>
            )}

            <Button
              loading={this.props.isLoading}
              size="tiny"
              color="yellow"
              icon="refresh"
              disabled={dealer.domain.state === "active"}
              style={{ marginTop: "10px", float: "left" }}
              content={t("verify_domain").message || "Verify domain"}
              onClick={this.props.domainRefresh}
            />

            <Can I="delete" the="dealers">
              <Button
                loading={this.props.isLoading}
                size="tiny"
                color="red"
                icon="trash"
                style={{ marginTop: "10px", float: "right" }}
                content={t("delete_domain").message || "Delete domain"}
                onClick={() => {
                  this.setState({ deleteConfirm: true });
                }}
              />
            </Can>

            <CustomConfirm
              type="danger"
              isOpen={this.state.deleteConfirm}
              handleCancel={() => {
                this.setState({ deleteConfirm: false });
              }}
              handleConfirm={() => {
                this.props.domainDelete();
                this.setState({ deleteConfirm: false });
              }}
              confirmMsg={t("are_you_sure").message || "Are you sure?"}
              confirmButton={t("yes").message || "Yes"}
              cancelButton={t("cancel").message || "Cancel"}
            />
          </Form.Field>
        ) : (
          ""
        )}
      </Form>
    );
  }
}

export default withTranslation()(DomainSettings);
