import React, { Component } from "react";
import { Button, Checkbox, Modal } from "semantic-ui-react";
import { withTranslation } from "react-i18next";

class CopyQuestion extends Component {
  state = {
    question_elements: [],
  };

  componentDidMount() {
    this.setState({ question_elements: this.props.question.question_elements }, () => {
      let question_elements = this.props.question.question_elements;

      if (this.props.question.question_elements && this.props.question.question_elements.length > 0) {
        for (let i = 0; i < this.props.question.question_elements.length; i++) {
          question_elements[i].selected = true;
        }
      }

      this.setState(question_elements);
    });
  }

  handleUpdateCheckbox = (e, index) => {
    let qe = this.state.question_elements;
    if (qe && qe.length > 0) {
      qe[index].selected = e.target.value;
    }
    this.setState({ question_elements: qe });
  };

  handleCopyDefault = () => {
    let question_elements = [];
    if (this.state.question_elements && this.state.question_elements.length > 0) {
      question_elements = this.state.question_elements.filter(item => item.selected);
    }
    let question = this.props.question;
    question.question_elements = question_elements;

    this.props.handleCopyDefault(question);
  };

  handleCopyDealer = () => {
    let question_elements = [];
    if (this.state.question_elements && this.state.question_elements.length > 0) {
      question_elements = this.state.question_elements.filter(item => item.selected);
    }
    let question = this.props.question;
    question.question_elements = question_elements;

    this.props.handleCopyDealer(question);
  };

  render() {
    let questionElements =
      this.props.question.question_elements && this.props.question.question_elements.length > 0
        ? this.props.question.question_elements.map((item, key) => {
            return (
              <Checkbox
                key={key}
                className="-margin-right-15"
                checked={item.selected}
                label={item.name}
                onClick={e => {
                  this.handleUpdateCheckbox(e, key);
                }}
              />
            );
          })
        : [];

    return (
      <Modal open={true} closeOnDimmerClick={false} size="small">
        <Modal.Content>
          <Modal.Description>
            <h4>{this.props.t("select_elements_to_copy").message || "Select elements to copy"}</h4>
            {questionElements}
            <h4>{this.props.t("copy_question_to").message || "Copy question to"}</h4>
            <Button.Group>
              <Button onClick={this.handleCopyDefault} color="green" type="button">
                {this.props.t("default").message || "Default"}
              </Button>
              <Button onClick={this.handleCopyDealer} color="yellow" type="button">
                {this.props.t("dealer").message || "Dealer"}
              </Button>
            </Button.Group>

            <Button className="-pull-right" type="button" color="red" onClick={this.props.discard}>
              {this.props.t("discard").message || "Discard"}
            </Button>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
}

export default withTranslation()(CopyQuestion);
