// eslint-disable-next-line
import React, { Component } from "react";
import ReactDOM from "react-dom";

let searchRoot = document.getElementById("search-root");

class SearchPortal extends Component {
  constructor(props) {
    super(props);

    this.el = document.createElement("div");
  }

  componentDidMount() {
    searchRoot = document.getElementById("search-root");
    searchRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    searchRoot = document.getElementById("search-root");
    searchRoot.removeChild(this.el);
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.el);
  }
}

export default SearchPortal;
