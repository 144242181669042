import axios from "axios";

import ENV from "../../util/env-config";
import { getSessionCookie } from "../../util/common";

class Service {
  static requestConfig() {
    return {
      headers: {
        Authorization: `Bearer ${getSessionCookie()}`,
      },
    };
  }

  static appendPin(pin) {
    return axios.post(ENV.appointmentApiBase + "/pins/append", pin, this.requestConfig());
  }

  static addPinNote(pin) {
    return axios.post(ENV.appointmentApiBase + "/pins/add_note", pin, this.requestConfig());
  }

  static deletePin(pin) {
    return axios.post(ENV.appointmentApiBase + "/pins/delete", pin, this.requestConfig());
  }

  static updateVisibility({ id, visible_important_items, visible_mechanic }) {
    return axios.post(ENV.appointmentApiBase + "/pins/visibility", { pin_id: id, visible_important_items, visible_mechanic }, this.requestConfig());
  }

  static getPinDropdownOptions() {
    return axios.get(ENV.appointmentApiBase + "/pins/list_options", this.requestConfig());
  }

  static getAppointmentChecks(id) {
    return axios.post(ENV.apiBaseB + `/checks/list`, { appointment_id: id, embed: "pin" }, this.requestConfig());
  }

  static getCar(carID) {
    return axios.post(ENV.apiBaseB + "/cars/details", { id: carID }, this.requestConfig());
  }

  static getCustomer(customer_id) {
    return axios.post(ENV.apiBaseB + "/customers/view", { customer_id }, this.requestConfig());
  }

  static uploadFiles(requestData) {
    return axios.post(ENV.apiBaseB + "/files/upload_pin_note_attachment", requestData, this.requestConfig());
  }
}

export default Service;
