import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Modal, Button, Icon, Form, Checkbox } from "semantic-ui-react";

import { UserInput } from "../../components";
import ACTIONS from "./actions";
import Context from "./context";

const EDIT_MODAL_ACTIONS = [ACTIONS.EDIT_MODAL_SHOW, ACTIONS.EDIT_MODAL_SAVE];

const newKeylocker = { name: "", is_2fa_to_drop_key_enabled: true };

class KeyLockerEditModal extends Component {
  static contextType = Context;

  state = {
    selectedKeyLockerIndex: this.context.selectedKeyLockerIndex,
    keyLocker: { ...newKeylocker },
    incompleteForm: false,
  };

  componentDidUpdate() {
    const { action, selectedKeyLockerIndex, keyLockers } = this.context;

    if ([null, ...EDIT_MODAL_ACTIONS].includes(action) && this.state.selectedKeyLockerIndex !== selectedKeyLockerIndex) {
      const keyLocker = [-1, null].includes(selectedKeyLockerIndex) ? { ...newKeylocker } : keyLockers[selectedKeyLockerIndex];
      this.setState({ keyLocker, selectedKeyLockerIndex });
    }
  }

  onSave = () => {
    if (!this.state.keyLocker.name) this.setState({ incompleteForm: true });
    else this.setState({ incompleteForm: false }, () => this.context.controller.submitKeyLocker(this.state.keyLocker));
  };

  close = () => this.context.controller.setAction(null);

  render() {
    const { keyLocker, incompleteForm } = this.state;
    const { t } = this.props;
    const { isLoading, selectedKeyLockerIndex, action } = this.context;

    const isOpen = selectedKeyLockerIndex !== null && EDIT_MODAL_ACTIONS.includes(action);

    return (
      <Modal className="KeyLockerEditModal" open={isOpen} onClose={this.close} closeOnDimmerClick={false} size="mini">
        <Modal.Header>
          {selectedKeyLockerIndex > -1 ? t("edit_key_locker").message || "Edit Key Locker" : t("add_key_locker").message || "Add Key Locker"}
          <Button floated="right" negative icon="close" disabled={isLoading} content={t("cancel").message || "Cancel"} onClick={this.close} />
        </Modal.Header>

        <Modal.Content scrolling>
          <Form>
            <Form.Field error={incompleteForm && !keyLocker.name}>
              <UserInput
                type="text"
                placeholder={t("name").message || "Name"}
                value={keyLocker.name}
                onChange={(event, data) => this.setState({ keyLocker: { ...keyLocker, name: data.value } })}
              />
            </Form.Field>
            <Form.Field>
              <Checkbox
                toggle
                label={t("2_factor_auth_drop_key").message || "2 factor authentication to drop key"}
                value={keyLocker.is_2fa_to_drop_key_enabled}
                checked={keyLocker.is_2fa_to_drop_key_enabled}
                onChange={(event, data) => this.setState({ keyLocker: { ...keyLocker, is_2fa_to_drop_key_enabled: data.checked } })}
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button color="green" onClick={() => this.onSave()} loading={isLoading} disabled={isLoading}>
            <Icon name="checkmark" /> {t("save").message || "SAVE"}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default withTranslation()(KeyLockerEditModal);
