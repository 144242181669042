import React, { Component } from "react";
import AlertContainer from "react-alert";
import { Button, Icon, Modal, Checkbox, Tab, Message, Menu, Grid, Label } from "semantic-ui-react";

import Can from "../Can";
import UserForm from "./UserForm";
import MetricsTable from "./MetricsTable";
import DevicesTable from "./DevicesTable";
import CustomConfirm from "../../components/CustomConfirm";
import { validateEmail, getLocationById, isAdmin } from "../../util/common";
import { ROLES } from "./roles";

class UserDetail extends Component {
  state = {
    user: this.props.selectedUser,
    locationsBySelectedDealer: [],
    passwordChanged: false,
    password: {
      newPassword: "",
      passwordConfirmation: "",
      strength: 0,
    },
    loadingReinstate: this.props,
    isDeleteConfirmedVisible: false,
    isDeletePermanentlyVisible: false,
    isDeletingUser: false,
    deletingUserPermanentError: "",
    isDPOConfirmedVisible: false,
    invalidInput: false,
  };

  escapeModal = evt => {
    const { isDeleteConfirmedVisible, isDeletePermanentlyVisible, isDPOConfirmedVisible } = this.state;
    if (evt.keyCode === 27) {
      if (isDeleteConfirmedVisible) {
        this.setState({
          isDeleteConfirmedVisible: false,
        });
      } else if (isDeletePermanentlyVisible) {
        this.setState({
          isDeletePermanentlyVisible: false,
        });
      } else if (isDPOConfirmedVisible) {
        this.setState({
          isDPOConfirmedVisible: false,
        });
      } else {
        this.props.onHideDetail();
      }
    }
  };

  componentDidMount() {
    const { user } = this.state;
    document.addEventListener("keydown", this.escapeModal, false);

    const { locationsBySelectedDealer, defaultLocation } = this.getDefaultLocationIds(user);

    user.dealer_location_id = defaultLocation;

    this.setState({ locationsBySelectedDealer, user }, () => {
      this.props.getDMSUsers(user.dealer_location_id);
      this.props.getMechanicsPlanningLists(user.dealer_id, user.dealer_location_id);
    });
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escapeModal, false);
  }

  getDefaultLocationIds = user => {
    const { dealers } = this.props;

    let locationsBySelectedDealer = [];

    let i = dealers.findIndex(x => x.id === user.dealer_id);

    if (i !== -1) locationsBySelectedDealer = dealers[i].locations ? dealers[i].locations : [];

    if (user.role_id === ROLES.WARRANTY_MANAGER) {
      locationsBySelectedDealer =
        user.location_ids?.length && locationsBySelectedDealer.length ? locationsBySelectedDealer.filter(l => user.location_ids.includes(l.id)) : [];
    }

    let defaultLocation = user.dealer_location_id;

    if (locationsBySelectedDealer.length) {
      if (!locationsBySelectedDealer.some(l => l.id === defaultLocation)) defaultLocation = locationsBySelectedDealer[0].id;
    } else {
      defaultLocation = 0;
    }

    return { locationsBySelectedDealer, defaultLocation };
  };

  handleDealerChange = (e, data) => {
    const { locationsBySelectedDealer, defaultLocation } = this.getDefaultLocationIds({ ...this.state.user, dealer_id: data.value });

    this.setState(
      state => ({ locationsBySelectedDealer, user: { ...state.user, dms_nr: "", dealer_id: data.value, dealer_location_id: defaultLocation } }),
      () => {
        const { user } = this.state;
        this.props.getDMSUsers(user.dealer_location_id);
        this.props.getMechanicsPlanningLists(user.dealer_id, user.dealer_location_id);
      }
    );
  };

  handleLocationChange = (e, data) => {
    this.setState(
      state => ({ user: { ...state.user, dms_nr: "", dealer_location_id: data.value } }),
      () => {
        const { user } = this.state;
        this.props.getDMSUsers(user.dealer_location_id);
        this.props.getMechanicsPlanningLists(user.dealer_id, user.dealer_location_id);
      }
    );
  };

  handleActiveChange = (e, data) => {
    const { user } = this.state;
    user.status = data.checked ? 1 : 2;
    this.handleUserChange(user);
  };

  handleAllowedLocationsChange = locations => {
    const { user } = this.state;

    const dealer = this.props.dealers.find(d => d.id === user.dealer_id);
    const dealerLocations = dealer?.locations ? dealer.locations : [];
    const locationsBySelectedDealer = locations.length ? dealerLocations.filter(l => locations.includes(l.id)) : [];

    user.location_ids = locations;

    if (!user.dealer_location_id && locationsBySelectedDealer.length > 0) user.dealer_location_id = locationsBySelectedDealer[0].id;

    this.setState({ user, locationsBySelectedDealer });
  };

  handleRoleChange = user => {
    const { locationsBySelectedDealer, defaultLocation } = this.getDefaultLocationIds(user);

    user.dealer_location_id = defaultLocation;

    this.setState({ user, locationsBySelectedDealer });
  };

  handleUserChange = user => {
    this.setState({ user });
  };

  handlePasswordChange = password => {
    this.setState({ password, passwordChanged: true });
  };

  handleHideDeleteConfirmation = () => {
    this.setState(
      {
        isDeleteConfirmedVisible: false,
        isDeletePermanentlyVisible: false,
      },
      () => this.props.onCancelDeleteUser()
    );
  };

  handleSetDDO = () => {
    const { user } = this.state;

    let ddo = {
      name: user.first_name + " " + user.last_name,
      address: user.address1,
      phone: user.phone,
      email: user.email,
      user_id: user.id,
      dealer_id: user.dealer_id,
    };
    this.props.onSetDDO(ddo);
  };

  handleSave = () => {
    let { user, password, passwordChanged } = this.state;
    const { mode } = this.props;

    this.setState({ invalidInput: false }, () => {
      let passIsValid = mode !== "create";
      let emailValid = validateEmail(user.email);

      if (password.newPassword.length > 0 && password.strength >= 1 && password.newPassword === password.passwordConfirmation) {
        passIsValid = true;
        user.password = password.newPassword;
      }

      if (!passwordChanged || !passIsValid) {
        passIsValid = true;
        delete user.password;
      }

      if (
        user &&
        user.username &&
        user.first_name &&
        user.last_name &&
        user.email &&
        user.dealer_id &&
        user.dealer_location_id &&
        user.role_id &&
        (user.role_id !== ROLES.WARRANTY_MANAGER || user.location_ids?.length) &&
        passIsValid &&
        emailValid
      ) {
        user.status = user.status || 2;
        user.onei_planning_monteur_id = user.onei_planning_monteur_id || null;
        user.planit_planning_mechanic_id = user.planit_planning_mechanic_id || null;
        user.dms_nr = user.dms_nr || null;

        const updatedUser = { ...user };

        if (![ROLES.CONSULTANT, ROLES.SUPERVISOR, ROLES.WARRANTY_MANAGER, ROLES.MANUFACTURER].includes(user.role_id)) {
          delete updatedUser.dealers_ids;
          delete updatedUser.location_ids;
        }

        if (user.role_id !== ROLES.WARRANTY_MANAGER) delete updatedUser.location_ids;

        this.props.onSave(updatedUser);
      } else {
        this.setState({ invalidInput: true });
      }
    });
  };

  handleTabChange = (e, data) => {
    const activeTab = data.activeIndex;
    const key = data.panes[activeTab].menuItem.key;

    //load metrics
    if (key === "metrics") {
      if (this.state.user) {
        this.props.onGetMetrics(this.state.user.id);
      }
    }
    //load devices
    if (key === "devices") {
      if (this.state.user) {
        this.props.onGetDevices(this.state.user.id);
      }
    }
  };

  renderMetrics = () => {
    return <MetricsTable loading={this.props.loadingMetrics} metrics={this.props.metrics} />;
  };

  renderDevices = () => {
    return <DevicesTable loading={this.props.loadingDevices} devices={this.props.devices} />;
  };

  renderAlert = () => {
    const props = {
      offset: 20,
      position: "top right",
      theme: "light",
      time: 2000,
      transition: "fade",
    };

    return <AlertContainer ref={a => (this.msg = a)} {...props} />;
  };

  renderPanes = () => {
    const { user } = this.state;

    const panes = [];

    if (this.props.dealers && this.props.dealers.length > 0) {
      const location = getLocationById(this.props.dealers, user.dealer_location_id);
      if (location.accept_network_metrics) {
        panes.push({
          menuItem: (
            <Menu.Item key="metrics">
              <div className="customer_sys_info">
                <strong>{this.props.t("metrics").message || "Metrics"}</strong>
              </div>
            </Menu.Item>
          ),
          render: () => <Tab.Pane attached={false}>{this.renderMetrics()}</Tab.Pane>,
        });
      }
    }

    if (user && user.id) {
      panes.push({
        menuItem: (
          <Menu.Item key="devices">
            <div className="customer_sys_info">
              <strong>{this.props.t("devices").message || "Devices"}</strong>
            </div>
          </Menu.Item>
        ),
        render: () => <Tab.Pane attached={false}>{this.renderDevices()}</Tab.Pane>,
      });
    }

    return (
      panes &&
      panes.length > 0 && (
        <div className="customer_sys">
          <Message size="mini">{this.props.t("click_on_button_in_order_to_load_data").message || "Click on button in order to load data"}</Message>
          <Tab
            grid={{
              paneWidth: 16,
              tabWidth: 8,
            }}
            panes={panes}
            // menu={{ secondary: true }}
            defaultActiveIndex={null}
            onTabChange={this.handleTabChange}
          />
        </div>
      )
    );
  };

  render() {
    const {
      mode,
      t,
      currentUser,
      isDeletingUser,
      deletingUserError,
      errorMessage,
      loading,
      dealers,
      brandsList,
      oneiPlanningMechanicsOptions,
      planItMechanicsOptions,
      dmsUsers,
      loadingDMSUsers,
    } = this.props;

    const { user, locationsBySelectedDealer, isDeleteConfirmedVisible, isDeletePermanentlyVisible, isDPOConfirmedVisible, password, invalidInput } = this.state;

    return (
      <div className="UserDetail">
        <Modal open={true} closeOnDimmerClick={false} size="large">
          <Modal.Header>
            <div>
              {mode === "create" ? t("add_new_user").message || "Add new user" : t("edit_user").message || "Edit user"}
              <Checkbox
                defaultChecked={user.status === 1}
                name="status"
                label={t("active").message || "Active"}
                className="-pull-right"
                onChange={this.handleActiveChange}
              />
            </div>
          </Modal.Header>

          <Modal.Content scrolling>
            <UserForm
              user={{ ...user }}
              dealers={dealers}
              brandsList={brandsList}
              invalidInput={invalidInput}
              locationsBySelectedDealer={locationsBySelectedDealer}
              password={password}
              onPasswordChange={this.handlePasswordChange}
              handleLocationChange={this.handleLocationChange}
              handleDealerChange={this.handleDealerChange}
              handleRoleChange={this.handleRoleChange}
              handleUserChange={this.handleUserChange}
              handleAllowedLocationsChange={this.handleAllowedLocationsChange}
              oneiPlanningMechanicsOptions={oneiPlanningMechanicsOptions}
              planItMechanicsOptions={planItMechanicsOptions}
              errorMessage={errorMessage}
              loadingDMSUsers={loadingDMSUsers}
              dmsUsers={dmsUsers}
              {...this.props}
            />

            {mode !== "create" && (
              <div>
                {this.renderPanes()}
                <Grid columns="equal">
                  {(user.status === 3 || (user.status === 4 && isAdmin(currentUser.role_id))) && (
                    <Grid.Column>
                      <Button
                        color="blue"
                        fluid
                        disabled={this.props.isReinstatingUser}
                        loading={this.props.isReinstatingUser}
                        onClick={() => {
                          this.props.onReinstateUser(user.id);
                        }}
                      >
                        {this.props.t("reinstate").message || "Reinstate"}
                      </Button>
                    </Grid.Column>
                  )}
                  {!isAdmin(currentUser.role_id) && (user.status === 1 || user.status === 2) && (
                    <Grid.Column>
                      <Can I="delete" the="users">
                        <Button
                          color="red"
                          fluid
                          onClick={() => {
                            this.setState({ isDeleteConfirmedVisible: true });
                          }}
                        >
                          {this.props.t("delete").message || "Delete"}
                        </Button>
                      </Can>
                      <CustomConfirm
                        type="danger"
                        isOpen={isDeleteConfirmedVisible}
                        confirmMsg={this.props.t("confirm_delete_message").message || "Are you sure that you want to delete this? You can't undo this action."}
                        error={deletingUserError}
                        isLoading={isDeletingUser}
                        handleCancel={this.handleHideDeleteConfirmation}
                        handleConfirm={() => this.props.onDeleteUser(user.id)}
                      />
                    </Grid.Column>
                  )}
                  {isAdmin(currentUser.role_id) && user.status !== 4 && (
                    <Grid.Column>
                      <Can I="delete" the="users">
                        <Button
                          color="red"
                          fluid
                          onClick={() => {
                            this.setState({ isDeletePermanentlyVisible: true });
                          }}
                        >
                          {this.props.t("delete").message || "Delete"}
                        </Button>
                      </Can>
                      <CustomConfirm
                        type="danger"
                        isOpen={isDeletePermanentlyVisible}
                        confirmMsg={this.props.t("confirm_delete_message").message || "Are you sure that you want to delete this? You can't undo this action."}
                        error={deletingUserError}
                        isLoading={isDeletingUser}
                        handleCancel={this.handleHideDeleteConfirmation}
                        handleConfirm={() => this.props.onDeleteUser(user.id)}
                      />
                    </Grid.Column>
                  )}
                  <Grid.Column>
                    {!user.dpo ? (
                      <Button
                        color="blue"
                        fluid
                        onClick={() => {
                          this.setState({ isDPOConfirmedVisible: true });
                        }}
                      >
                        {this.props.t("set_as_dpo").message || "Set As Data Protection Officer"}
                      </Button>
                    ) : (
                      <Label color="blue" size="big">
                        {this.props.t("This user is Data Protection Officer").message || "This user is Data Protection Officer"}
                      </Label>
                    )}

                    <CustomConfirm
                      type="warning"
                      isOpen={isDPOConfirmedVisible}
                      handleCancel={() => {
                        this.setState({ isDPOConfirmedVisible: false });
                      }}
                      handleConfirm={this.handleSetDDO}
                      isLoading={this.props.isSettingUserAsDPO}
                      confirmMsg={this.props.t("protection_officer_confirmation").message || "Are you sure you want to set this person as the Data Protection Officer?"}
                      confirmText={this.props.t("yes").message || "YES"}
                      cancelText={this.props.t("no").message || "No"}
                    />
                  </Grid.Column>
                </Grid>
              </div>
            )}
          </Modal.Content>
          <Modal.Actions>
            <Button
              color="red"
              onClick={() => {
                this.props.onHideDetail();
              }}
              floated="left"
            >
              <Icon name="close" /> {this.props.t("discard").message || "DISCARD"}
            </Button>
            <Button color="green" onClick={this.handleSave} loading={loading} disabled={loading}>
              <Icon name="checkmark" /> {this.props.t("save").message || "SAVE"}
            </Button>
          </Modal.Actions>

          {this.renderAlert()}
        </Modal>
      </div>
    );
  }
}

export default UserDetail;
