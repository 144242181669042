import React from "react";
import { Label, Icon } from "semantic-ui-react";

import "./ThumbToggle.css";

const ThumbToggle = ({ status, disabled, onClick }) => {
  let p;
  if (status) {
    p = {
      name: "thumbs up",
      color: "green",
    };
  } else {
    p = {
      name: "thumbs down",
      color: "red",
    };
  }

  if (disabled) p.color = "grey";

  return (
    <div className={disabled ? "ThumbToggleDisabled" : "ThumbToggle"}>
      <Label onClick={disabled ? null : onClick}>
        <Icon {...p} />
      </Label>
    </div>
  );
};

export default ThumbToggle;
