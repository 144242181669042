import React from "react";
import { Button } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCar } from "@fortawesome/free-solid-svg-icons";

import Can from "../Can";

const AppointmentRestoreHandle = ({ isRestoringAppointment, onRestoreAppointment }) => {
  return (
    <Can I="restore" the="appointments">
      <Button basic onClick={onRestoreAppointment} disabled={isRestoringAppointment} loading={isRestoringAppointment}>
        <FontAwesomeIcon icon={faCar} style={{ fontSize: "18px" }} />
      </Button>
    </Can>
  );
};

export default AppointmentRestoreHandle;
