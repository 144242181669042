import React from "react";
import propTypes from "prop-types";
import { Icon } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCheck, faCarAlt } from "@fortawesome/free-solid-svg-icons";
import { APPOINTMENT_STATUSES } from "../../modules/Appointments/common";

import "./StatusFilter.css";
import "../../modules/Appointments/Appointments.css";

const StatusFilter = ({ statuses, onStatusClick, diagnose_overview_enabled, online_check_in_enabled }) => {
  if (!statuses) return null;

  let visibleStatuses = statuses.filter(status => {
    return !(
      status.identifier === APPOINTMENT_STATUSES.CONTACT ||
      status.identifier === APPOINTMENT_STATUSES.CAR_CHECK_STARTED ||
      (status.identifier === APPOINTMENT_STATUSES.CUSTOMER_ANSWERED && !diagnose_overview_enabled) ||
      (status.identifier === APPOINTMENT_STATUSES.HANDLE_CHECK_IN && !online_check_in_enabled) ||
      status.identifier === APPOINTMENT_STATUSES.CHECK_IN_DONE ||
      status.identifier === APPOINTMENT_STATUSES.CAR_IN_SHOP ||
      status.identifier === APPOINTMENT_STATUSES.CAR_OK_PLUS_REPAIR_OVERVIEW ||
      status.identifier === APPOINTMENT_STATUSES.QUALITY_CHECK_PLUS_REPAIR_OVERVIEW
    );
  });

  return (
    <div className="StatusFilter">
      {(!visibleStatuses || visibleStatuses.length === 0) && (
        <div className="StatusFilter__Placeholder Loader-Placeholder">
          <div className="bounce1"></div>
          <div className="bounce2"></div>
          <div className="bounce3"></div>
        </div>
      )}
      {visibleStatuses &&
        visibleStatuses.length > 0 &&
        visibleStatuses.map((s, k) => (
          <span
            key={k}
            className={`StatusFilter_status -is-active-${s.isActive}`}
            style={{
              backgroundColor: s.color,
            }}
            title={s.name}
            onClick={() => {
              onStatusClick(s);
            }}
          >
            {s.identifier === APPOINTMENT_STATUSES.NEW_CAR && <FontAwesomeIcon icon={faCarAlt} style={{ fontSize: "1.3em", marginTop: "-2px" }} />}
            {s.identifier === APPOINTMENT_STATUSES.CUSTOMER_ANSWERED && <FontAwesomeIcon icon={faUserCheck} />}
            {s.identifier === APPOINTMENT_STATUSES.CAR_OUT_OF_SHOP && <Icon name="sign-out" style={{ fontSize: "1.2em", marginTop: "-5px" }} />}
            {s.identifier === APPOINTMENT_STATUSES.BACK_ORDER && <span className="back-order-status-icon">BO</span>}
            {![APPOINTMENT_STATUSES.CUSTOMER_ANSWERED, APPOINTMENT_STATUSES.NEW_CAR, APPOINTMENT_STATUSES.CAR_OUT_OF_SHOP, APPOINTMENT_STATUSES.BACK_ORDER].includes(
              s.identifier
            ) && <i className="material-icons">{s.icon}</i>}
          </span>
        ))}
    </div>
  );
};

StatusFilter.propTypes = {
  statuses: propTypes.array.isRequired,
};

export default StatusFilter;
