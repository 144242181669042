import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import ReactTable, { ReactTableDefaults } from "react-table";
import moment from "moment";

import { getDealerAndLocationById, getTireSeasonName } from "../../util/common";

class TireTable extends Component {
  render() {
    const { tires, onRowClick, t } = this.props;

    return (
      <div className="TyreTable container">
        <ReactTable
          className="ReactTable -floated-table -contained-large"
          data={tires}
          showPagination={true}
          nextText={t("next").message || "Next"}
          previousText={t("previous").message || "Previous"}
          pageText={t("page").message || "Page"}
          ofText={t("of").message || "of"}
          onPageChange={() => document.querySelector(".App__module").scrollTo(0, 0)}
          pageSize={100}
          minRows={0}
          showPageSizeOptions={false}
          sortable={false}
          resizable={false}
          noDataText={
            <div className="Table__no-results">
              <p>{t("no_incomplete_tires").message || "No incomplete tires"}</p>
            </div>
          }
          getTdProps={(_, rowInfo) => {
            return {
              onClick: () => {
                onRowClick({ index: rowInfo.index, mode: "edit", selectedTire: rowInfo.original });
              },
            };
          }}
          column={{
            ...ReactTableDefaults.column,
            headerClassName: "ReactTable__column-header",
            className: "ReactTable__column",
          }}
          columns={[
            {
              id: "createdOn",
              Header: t("creation_date").message || "Creation date",
              accessor: d =>
                d.created_on && (moment.isMoment(d.created_on) || !d.created_on.startsWith("0001-01-01T00:00:00")) // might be time zoned
                  ? moment(d.created_on).format("DD-MM-YYYY")
                  : "",
              width: 120,
            },
            {
              id: "manufacturer",
              Header: t("manufacturer").message || "Manufacturer",
              accessor: "manufacturer",
              width: 200,
            },
            {
              id: "width",
              accessor: "width",
              Header: t("width").message || "Width",
              width: 120,
            },
            {
              id: "height",
              accessor: "height",
              Header: t("height").message || "Height",
              width: 120,
            },
            {
              id: "size",
              accessor: "size",
              Header: t("size").message || "Size",
              width: 120,
            },
            {
              id: "speed",
              accessor: "speed",
              Header: t("speed").message || "Speed",
              width: 120,
            },
            {
              id: "season_name",
              accessor: d => getTireSeasonName(d.season, t),
              Header: t("season").message || "Season",
              width: 200,
            },
            {
              id: "description",
              accessor: "description",
              Header: t("description").message || "Description",
              width: 350,
            },
            {
              id: "createdBy",
              Header: t("created_by").message || "Created by",
              accessor: d => d.created_by && <span>{`${d.created_by.first_name} ${d.created_by.last_name}`}</span>,
              width: 200,
            },
            {
              id: "location",
              Header: t("location").message || "Location",
              accessor: d => {
                if (d.created_by) {
                  const { dealer, location } = getDealerAndLocationById(this.props.globalState.dealers, d.created_by.dealer_location_id);

                  if (dealer && location) return <span>{`${dealer.name} - ${location.name}`}</span>;
                }
                return null;
              },
            },
          ]}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { globalState: state.global };
};

export default connect(mapStateToProps)(withTranslation()(TireTable));
