import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Form, Checkbox, Header, Icon, Segment } from "semantic-ui-react";

import PasswordInput from "../PasswordInput";

class ROBNetConfiguration extends Component {
  handleInputChange = e => {
    let { location, handleChange } = this.props;
    if (Object.keys(location).length === 0) return;
    location[e.target.name] = e.target.value;
    handleChange(location);
  };

  handleCheckboxChange = (e, { name, checked }) => {
    let { location, onLocationChange } = this.props;

    onLocationChange({ ...location, [name]: checked });
  };

  render() {
    const { location, t } = this.props;

    return (
      <Segment>
        <Header as="h5">
          <Icon name="settings" />
          <Header.Content>{t("robnet_configuration").message || "Robnet configuration"}</Header.Content>
        </Header>
        <Form>
          <Form.Field>
            <Checkbox checked={location.is_robnet_enabled} name="is_robnet_enabled" label={t("robnet").message || "Robnet"} onChange={this.handleCheckboxChange} />
          </Form.Field>
          {location.is_robnet_enabled && (
            <Form.Field className="full-width-input">
              <label>{t("robnet_api_key").message || "Robnet Api Key"}</label>
              <PasswordInput
                defaultValue={location.robnet_api_key}
                name="robnet_api_key"
                placeholder={t("robnet_api_key").message || "Robnet Api Key"}
                onChange={this.handleInputChange}
                showAsteriskPlaceholder={location.has_robnet_api_key}
              />
            </Form.Field>
          )}
        </Form>
      </Segment>
    );
  }
}

export default withTranslation()(ROBNetConfiguration);
