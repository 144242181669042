import React, { Component } from "react";
import AlertContainer from "react-alert";
import { withTranslation } from "react-i18next";
import { Grid, Button, Icon, Modal } from "semantic-ui-react";

import { copyToClipboard } from "../../util/common";
import { SubHeader, CustomConfirm, UserMenuActionsPortal } from "../../components";

import { Can } from "../";

import ACTIONS from "./actions";
import Context from "./context";
import KeyLockerWarning from "./KeyLockerWarning";
import KeyLockerTable from "./KeyLockerTable";
import KeyLockerEditModal from "./KeyLockerEditModal";
import KeyLockerBoxesModal from "./KeyLockerBoxesModal";

import "./index.scss";

class KeyLocker extends Component {
  static contextType = Context;

  renderAlert = () => {
    const props = {
      offset: 20,
      position: "top right",
      theme: "light",
      time: 2000,
      transition: "fade",
    };
    return <AlertContainer ref={a => (this.msg = a)} {...props} />;
  };

  handleCloseModal = () => {
    this.context.controller.setAction(null);
  };

  handleCopyToClipboard = key => {
    copyToClipboard(key);
    this.msg.show(this.props.t("private_key_copied").message || "Private key copied to clipboard", { type: "success" });
  };

  cancelAction = () => {
    const { controller, action, selectedKeyLockerIndex } = this.context;

    switch (action) {
      case ACTIONS.CONFIRM_GET_ADMIN_PIN:
      case ACTIONS.CONFIRM_EMPTY_BOX:
      case ACTIONS.ADMIN_PIN_MODAL_SHOW:
        controller.setAction(ACTIONS.DETAILS_MODAL_SHOW, selectedKeyLockerIndex);
        break;

      case ACTIONS.CONFIRM_DELETE_KEYLOCKER:
      default:
        controller.setAction(null);
    }
  };

  render() {
    const { t } = this.props;
    const { controller, action, adminPin, isLoading, privateKey } = this.context;
    const { setAction, getKeyLockers, deleteKeyLocker, getAdminPin, emptyBox } = controller;

    let confirmIsOpen = [ACTIONS.CONFIRM_DELETE_KEYLOCKER, ACTIONS.CONFIRM_GET_ADMIN_PIN, ACTIONS.CONFIRM_EMPTY_BOX, ACTIONS.ADMIN_PIN_MODAL_SHOW].includes(action);
    let confirmAction, confirmMsg;

    switch (action) {
      case ACTIONS.CONFIRM_DELETE_KEYLOCKER:
        confirmAction = deleteKeyLocker;
        confirmMsg = t("confirm_delete_message").message || "Are you sure that you want to delete this? You can't undo this action.";
        break;

      case ACTIONS.CONFIRM_EMPTY_BOX:
        confirmAction = emptyBox;
        confirmMsg =
          t("confirm_empty_box_message").message ||
          "This will empty this locker box and allow another customer to use it. Make sure it was emptied. You can't undo this action, are you sure ?";
        break;

      case ACTIONS.CONFIRM_GET_ADMIN_PIN:
        confirmAction = getAdminPin;
        confirmMsg = t("confirm_open_box_message").message || "This will open this locker box, but it will not update the system. Are you sure ?";
        break;

      case ACTIONS.ADMIN_PIN_MODAL_SHOW:
        confirmAction = this.cancelAction;
        confirmMsg =
          (t("keylocker_admin_pin_message").message || "Go to the keylocker and use this PIN as if you wanted to pick up a key, it will expire in 5 minutes:") +
          ` ${adminPin}`;
        break;

      default:
        confirmIsOpen = false;
    }

    return (
      <div className="KeyLockers">
        <UserMenuActionsPortal>
          <Icon name="refresh" onClick={getKeyLockers} />
        </UserMenuActionsPortal>
        <SubHeader>
          <Grid.Column width={14}>
            <h1>{t("key_lockers").message || "Key Lockers"}</h1>
          </Grid.Column>
          <Grid.Column width={2}>
            <Can I="create" the="keylockers">
              <Button color="green" icon labelPosition="left" disabled={isLoading} fluid onClick={() => setAction(ACTIONS.EDIT_MODAL_SHOW, -1)}>
                <Icon name="add" /> {t("add").message || "Add"}
              </Button>
            </Can>
          </Grid.Column>
        </SubHeader>
        <KeyLockerWarning />
        <KeyLockerTable />
        <KeyLockerEditModal />
        <KeyLockerBoxesModal />
        <CustomConfirm // TODO move this to a KeyLockerConfirm component
          type="danger"
          isOpen={confirmIsOpen}
          handleCancel={this.cancelAction}
          handleConfirm={confirmAction}
          confirmMsg={confirmMsg}
          confirmText={t("confirm").message || "Confirm"}
          cancelText={t("cancel").message || "Cancel"}
        />

        <Modal open={action === ACTIONS.KEYLOCKER_CREATED && privateKey} onClose={this.handleCloseModal}>
          <Modal.Header>{t("private_key").message || "Private key"}</Modal.Header>
          <Modal.Content>
            <p style={{ textAlign: "center" }}>
              {t("key_displayed_once_ensure_copy").message || "This key will be displayed only once, make sure to copy it and proceed to setup."}
            </p>
            <div className="KeylockerPrivateKey-container" onClick={() => this.handleCopyToClipboard(privateKey)}>
              <Icon name="copy outline" />
              <div>{privateKey}</div>
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.handleCloseModal}>{t("close").message || "Close"}</Button>
          </Modal.Actions>

          {this.renderAlert()}
        </Modal>
      </div>
    );
  }
}

export default withTranslation()(KeyLocker);
