import React from "react";
import { Button } from "semantic-ui-react";
import ReactTable, { ReactTableDefaults } from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport } from "@fortawesome/pro-regular-svg-icons";
import { saveAs } from "file-saver";
import moment from "moment";

import { round2 } from "../../../util/common";

const GeclairedColors = [
  {
    value1: 0,
    value2: 25,
    color: "red",
  },
  {
    value1: 25,
    value2: 50,
    color: "orange",
  },
  {
    value1: 50,
    value2: 80,
    color: "green",
  },
  {
    value1: 80,
    value2: Infinity,
    color: "darkgreen",
  },
];

const getGeclairedColor = value => {
  const item = GeclairedColors.find(item => value >= item.value1 && value <= item.value2);
  return item ? item.color : "";
};

const LeaseOverview = ({ report, t }) => {
  const data = report.report_data.map(d => JSON.parse(d));

  let total = {
    location: "Total",
    wo_count: 0,
    wo_geclaired: 0,
    geclaired_percent: 0,
    wo_commercial_count: 0,
    wo_commercial_percent: 0,
    wo_lease_count: 0,
    wo_lease_percent: 0,
    check_count: 0,
    commercial_check_count: 0,
    commercial_check_percent: 0,
    point_count: 0,
    avg_advice_point_count: 0,
    avg_advice_point_count_yes: 0,
    percent_advice_sold_items: 0,
    avg_critical_point_count: 0,
    avg_critical_point_count_yes: 0,
    percent_critical_sold_items: 0,
    percent_sold_items: 0,
    advice_point_count_no: 0,
    advice_point_count_yes: 0,
    critical_point_count_no: 0,
    critical_point_count_yes: 0,
  };

  const l = data.length;
  if (l > 0) {
    const addToTotal = (acc, cur) => {
      acc.wo_count += cur.wo_count || 0;
      acc.wo_geclaired += cur.wo_geclaired || 0;
      acc.wo_commercial_count += cur.wo_commercial_count || 0;
      acc.wo_lease_count += cur.wo_lease_count || 0;
      acc.check_count += cur.check_count || 0;
      acc.commercial_check_count += cur.commercial_check_count || 0;
      acc.avg_advice_point_count += cur.avg_advice_point_count || 0;
      acc.point_count += cur.point_count || 0;
      acc.avg_advice_point_count_yes += cur.avg_advice_point_count_yes || 0;
      acc.percent_advice_sold_items += cur.percent_advice_sold_items || 0;
      acc.avg_critical_point_count += cur.avg_critical_point_count || 0;
      acc.avg_critical_point_count_yes += cur.avg_critical_point_count_yes || 0;
      acc.percent_critical_sold_items += cur.percent_critical_sold_items || 0;
      acc.percent_sold_items += cur.percent_sold_items || 0;
      acc.advice_point_count_no += cur.advice_point_count_no || 0;
      acc.advice_point_count_yes += cur.advice_point_count_yes || 0;
      acc.critical_point_count_no += cur.critical_point_count_no || 0;
      acc.critical_point_count_yes += cur.critical_point_count_yes || 0;
      return acc;
    };

    total = data.reduce((acc, cur) => addToTotal(acc, cur), total);
    total.geclaired_percent = round2((total.wo_geclaired / total.wo_lease_count) * 100);
    total.wo_commercial_percent = round2((total.wo_commercial_count / total.wo_geclaired) * 100);
    total.wo_lease_percent = round2((total.wo_lease_count / total.wo_count) * 100);
    total.commercial_check_percent = round2((total.commercial_check_count / total.check_count) * 100);
    total.avg_advice_point_count = round2((total.advice_point_count_no + total.advice_point_count_yes) / total.commercial_check_count);
    total.avg_advice_point_count_yes = round2(total.advice_point_count_yes / total.commercial_check_count);
    total.percent_advice_sold_items = round2((total.advice_point_count_yes / (total.advice_point_count_no + total.advice_point_count_yes)) * 100);
    total.avg_critical_point_count = round2((total.critical_point_count_no + total.critical_point_count_yes) / total.commercial_check_count);
    total.avg_critical_point_count_yes = round2(total.critical_point_count_yes / total.commercial_check_count);
    total.percent_critical_sold_items = round2((total.critical_point_count_yes / (total.critical_point_count_no + total.critical_point_count_yes)) * 100);
    total.percent_sold_items = round2(((total.advice_point_count_yes + total.critical_point_count_yes) / total.point_count) * 100);
  }

  data.push(total);

  const exportData = async () => {
    let csv = "";
    let header = (t("location").message || "Location") + ", ";
    header += (t("wo_count").message || "WO count") + ", ";
    header += (t("wo_lease_count").message || "WO lease") + ", ";
    header += (t("wo_geclaired").message || "WO worked on") + ", ";
    header += (t("wo_commercial_count").message || "WO commercial") + ", ";
    header += (t("check_count").message || "Check count") + ", ";
    header += (t("commercial_check_count").message || "Commercial check count") + ", ";
    header += (t("avg_advice_point_count").message || "AVG advised items found") + ", ";
    header += (t("avg_advice_point_count_yes").message || "AVG advised items sold") + ", ";
    header += (t("percent_advice_sold_items").message || "% Advised sold items") + ", ";
    header += (t("avg_critical_point_count").message || "AVG critical items founds") + ", ";
    header += (t("avg_critical_point_count_yes").message || "AVG critical items sold") + ", ";
    header += (t("percent_critical_sold_items").message || "% Critical sold items") + ", ";
    header += (t("percent_sold_items").message || "% sold items ") + ", ";

    let values = data
      .map(
        d =>
          d.location +
          ", " +
          d.wo_count +
          ", " +
          (d.wo_lease_count || 0) +
          "(" +
          (d.wo_lease_percent || 0) +
          "%)" +
          ", " +
          (d.wo_geclaired || 0) +
          "(" +
          (d.geclaired_percent || 0) +
          "%)" +
          ", " +
          (d.wo_commercial_count || 0) +
          "(" +
          (d.wo_commercial_percent || 0) +
          "%)" +
          ", " +
          d.check_count +
          ", " +
          (d.commercial_check_count || 0) +
          "(" +
          (d.commercial_check_percent || 0) +
          "%)" +
          ", " +
          (d.avg_advice_point_count || 0) +
          ", " +
          (d.avg_advice_point_count_yes || 0) +
          ", " +
          (d.percent_advice_sold_items || 0) +
          ", " +
          (d.avg_critical_point_count || 0) +
          ", " +
          (d.avg_critical_point_count_yes || 0) +
          ", " +
          (d.percent_critical_sold_items || 0) +
          ", " +
          (d.percent_sold_items || 0)
      )
      .join("\n");

    csv += header + "\n" + values;
    saveAs(new File([csv], { type: "text/csv;charset=utf-8" }), "LeaseOverview_" + moment().format("YYYYMMDD") + ".csv");
  };

  return (
    <>
      <h3 className="report-heading">
        {t(report.report_name).message || report.report_name}
        <Button color="green" floated="right" basic onClick={() => exportData()}>
          <span style={{ marginRight: "10px" }}>{t("export").message || "Export"}</span>
          <FontAwesomeIcon icon={faFileExport} />
        </Button>
      </h3>
      <div className="dealer-reports-table-container">
        <ReactTable
          className="ReactTable -floated-table no-overflow all-data-table"
          data={data}
          showPagination={false}
          showPageSizeOptions={false}
          sortable={false}
          resizable={false}
          style={{ maxHeight: "600px" }}
          defaultPageSize={data?.length || 0}
          pageSize={data?.length || 0}
          noDataText={
            <div className="Table__no-results">
              <p>{t("no_data").message || "No data"}</p>
            </div>
          }
          column={{
            ...ReactTableDefaults.column,
            headerClassName: "ReactTable__column-header dealer-table-header",
            className: "ReactTable__column",
          }}
          columns={[
            {
              Header: <strong>{t("location").message || "Location"}</strong>,
              accessor: "location",
            },
            {
              Header: <strong>{t("wo_count").message || "WO count"}</strong>,
              accessor: "wo_count",
            },
            {
              Header: <strong>{t("wo_lease").message || "WO lease"}</strong>,
              id: "wo_lease_count",
              accessor: d => (d.wo_lease_count || 0) + " (" + (d.wo_lease_percent || 0) + "%)",
            },
            {
              Header: <strong>{t("wo_geclaired").message || "WO worked on"}</strong>,
              id: "wo_geclaired",
              accessor: d => (
                <span>
                  {d.wo_geclaired || 0} (<span style={{ color: getGeclairedColor(d.geclaired_percent || 0) }}>{d.geclaired_percent || 0}%</span>)
                </span>
              ),
            },
            {
              Header: <strong>{t("wo_commercial").message || "WO commercial"}</strong>,
              id: "wo_commercial_count",
              accessor: d => (d.wo_commercial_count || 0) + " (" + (d.wo_commercial_percent || 0) + "%)",
            },
            {
              Header: <strong>{t("check_count").message || "Car check count"}</strong>,
              accessor: "check_count",
            },
            {
              Header: <strong>{t("commercial_check").message || "Commercial check"}</strong>,
              id: "commercial_check",
              accessor: d => (d.commercial_check_count || 0) + " (" + (d.commercial_check_percent || 0) + "%)",
            },
            {
              Header: <strong>{t("avg_advice_point_count").message || "AVG advised items found"}</strong>,
              accessor: "avg_advice_point_count",
              Cell: cell => cell.value || 0,
            },
            {
              Header: <strong>{t("avg_advice_point_count_yes").message || "AVG advised items sold"}</strong>,
              accessor: "avg_advice_point_count_yes",
              Cell: cell => cell.value || 0,
            },
            {
              Header: <strong>{t("percent_advice_sold_items").message || "% advised sold items"}</strong>,
              accessor: "percent_advice_sold_items",
              Cell: cell => (cell.value || 0) + "%",
            },
            {
              Header: <strong>{t("avg_critical_point_count").message || "AVG critical items founds"}</strong>,
              accessor: "avg_critical_point_count",
              Cell: cell => cell.value || 0,
            },
            {
              Header: <strong>{t("avg_critical_point_count_yes").message || "AVG critical items sold"}</strong>,
              accessor: "avg_critical_point_count_yes",
              Cell: cell => cell.value || 0,
            },
            {
              Header: <strong>{t("percent_critical_sold_items").message || "% critical sold items"}</strong>,
              accessor: "percent_critical_sold_items",
              Cell: cell => (cell.value || 0) + "%",
            },
            {
              Header: <strong>{t("percent_sold_items").message || "% items sold"}</strong>,
              accessor: "percent_sold_items",
              Cell: cell => (cell.value || 0) + "%",
            },
          ]}
        />
      </div>
    </>
  );
};

export { LeaseOverview };
