import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Dropdown, Grid, List, Icon, Search } from "semantic-ui-react";
import AlertContainer from "react-alert";

import { SubHeader, UserMenuActionsPortal, FloatingActions, SearchPortal } from "../../components";
import { GLOBAL_ACTION_TYPES, setAlert } from "../App/store";
import { isAdmin } from "../../util/common";
import CreateQuestion from "./CreateQuestion";
import EditQuestion from "./EditQuestion";

import Service from "./service";

import "./Questions.css";

const alertOptions = {
  offset: 20,
  position: "top center",
  theme: "light",
  time: 10000,
  transition: "fade",
};
class Questions extends Component {
  state = {
    tags: [],
    selectedTags: [],
    defaultQuestions: [],
    originalDefaultQuestions: [],
    dealerQuestions: [],
    originalDealerQuestions: [],
    showNewQuestionModal: false,
    newQuestionType: "",
    currentQuestion: 0,
    showEditModal: false,
    loadingDealerQuestions: false,
    loadingDefaultQuestions: false,
    isLoading: false,
    questionElements: [],
    searchTerm: "",
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.globalState.actionType === GLOBAL_ACTION_TYPES.DEALER_SELECTED) this.selectDefaultValues(nextProps.globalState.selectedDealer.id);
  }

  componentDidMount() {
    this.handleRefresh();
  }

  handleRefresh = () => {
    this.setState({ selectedTags: [] }, () => {
      this.getTags();
      this.selectDefaultValues(this.props.globalState.selectedDealer.id);
      this.getQuestionElements();
    });
  };

  getTags = () => {
    Service.getTags()
      .then(result => {
        if (result && result.data && result.data.data) {
          this.setState({ tags: result.data.data.tags });
        }
      })
      .catch(error => {
        this.props.setAlert({
          type: "error",
          title: error.message,
        });
        console.error("Error getting tags: ", error);
        this.setState({ tags: [] });
      });
  };

  selectDefaultValues = dealer_id => {
    this.setState({ loadingDefaultQuestions: true }, () => {
      Service.getDefaultQuestions()
        .then(result => {
          if (result && result.data && result.data.data) {
            this.setState({
              loadingDefaultQuestions: false,
              defaultQuestions: result.data.data.sort((a, b) => (a.id < b.id ? 1 : a.id > b.id ? -1 : 0)),
              originalDefaultQuestions: result.data.data.sort((a, b) => (a.id < b.id ? 1 : a.id > b.id ? -1 : 0)),
            });
          }
        })
        .catch(error => {
          this.props.setAlert({
            type: "error",
            title: error.message,
          });
          console.error("Error getting default questions: ", error);
          this.setState({ loadingDefaultQuestions: false, defaultQuestions: [], originalDefaultQuestions: [] });
        });
    });

    this.setState({ loadingDealerQuestions: true }, () => {
      Service.getDealerQuestions(dealer_id)
        .then(result => {
          if (result && result.data && result.data.data) {
            this.setState({
              loadingDealerQuestions: false,
              dealerQuestions: result.data.data.sort((a, b) => (a.id < b.id ? 1 : a.id > b.id ? -1 : 0)),
              originalDealerQuestions: result.data.data.sort((a, b) => (a.id < b.id ? 1 : a.id > b.id ? -1 : 0)),
            });
          }
        })
        .catch(error => {
          this.props.setAlert({
            type: "error",
            title: error.message,
          });
          console.error("Error getting dealer:", error);
          this.setState({ loadingDealerQuestions: false, dealerQuestions: [], originalDealerQuestions: [] });
        });
    });
  };

  getQuestionElements = () => {
    Service.getQuestionElements()
      .then(result => {
        if (result && result.data && result.data.data) {
          this.setState({ questionElements: result.data.data.question_elements });
        }
      })
      .catch(error => {
        this.props.setAlert({
          type: "error",
          title: error.message,
        });
        console.error("error while loading question elements: ", error);
        this.setState({ questionElements: [] });
      });
  };

  handleTagChange = (_event, data) => {
    this.setState({ selectedTags: data.value }, () => this.applyFilters());
  };

  handleSearchChange = (_e, data) => {
    this.setState({ searchTerm: data.value }, () => this.applyFilters());
  };

  applyFilters = () => {
    const { selectedTags, searchTerm, originalDealerQuestions, originalDefaultQuestions } = this.state;

    let filteredDealerQuestions = originalDealerQuestions;
    let filteredDefaultQuestions = originalDefaultQuestions;

    if (searchTerm) {
      filteredDealerQuestions = filteredDealerQuestions.filter(question => question.title.toLowerCase().includes(searchTerm.toLowerCase()));
      filteredDefaultQuestions = filteredDefaultQuestions.filter(question => question.title.toLowerCase().includes(searchTerm.toLowerCase()));
    }

    if (selectedTags.length > 0) {
      for (let i = 0; i < selectedTags.length; i++) {
        const currentTag = selectedTags[i];

        filteredDealerQuestions = filteredDealerQuestions.filter(item => this.computeFilters(currentTag, item.tags));
        filteredDefaultQuestions = filteredDefaultQuestions.filter(item => this.computeFilters(currentTag, item.tags));
      }
    }

    this.setState({
      dealerQuestions: filteredDealerQuestions,
      defaultQuestions: filteredDefaultQuestions,
    });
  };

  computeFilters = (currentTag, tags) => {
    if (tags) {
      for (let i = 0; i < tags.length; i++) {
        if (currentTag === tags[i].id) return true;
      }
    }

    return false;
  };

  handleEditQuestion = question_id => {
    this.setState({ currentQuestion: question_id, showEditModal: true });
  };

  handleNewQuestionModal = type => {
    this.setState({ showNewQuestionModal: true, newQuestionType: type });
  };

  handleStoreQuestion = question => {
    this.setState({ isLoading: true }, () => {
      Service.storeQuestion(question)
        .then(result => {
          if (result && result.data && result.data.data) {
            let { newQuestionType, dealerQuestions, originalDealerQuestions, defaultQuestions, originalDefaultQuestions } = this.state;
            question.id = result.data.data.id;
            question.active = true;

            if (newQuestionType === "dealer") {
              dealerQuestions.unshift(question);
              originalDealerQuestions = dealerQuestions;
            } else {
              defaultQuestions.unshift(question);
              originalDefaultQuestions = defaultQuestions;
            }

            this.setState({
              isLoading: false,
              showNewQuestionModal: false,
              currentQuestion: result.data.data.id,
              showEditModal: true,
              dealerQuestions,
              originalDealerQuestions,
              defaultQuestions,
              originalDefaultQuestions,
            });
          } else {
            this.setState({ isLoading: false });
            console.log("error while storing question");
          }
        })
        .catch(error => {
          this.setState({ isLoading: false }, () => {
            this.modalMsg.show(error.message, { type: "error" });
            console.log("Error while saving question: ", error);
          });
        });
    });
  };

  handleUpdateQuestion = question => {
    this.setState({ isLoading: true }, () => {
      if (question.question_elements && question.question_elements.length > 0) {
        // make sure question element order goes 0, 1, 2, 3....
        question.question_elements = question.question_elements.sort((a, b) => {
          return a > b ? 1 : a < b ? -1 : 0;
        });
        question.question_elements.forEach((qe, index) => (qe.order = index));
      }

      Service.updateQuestion(question)
        .then(result => {
          if (result && result.data && result.data.data) {
            let { defaultQuestions, originalDefaultQuestions, dealerQuestions, originalDealerQuestions } = this.state;
            let i = defaultQuestions.findIndex(q => q.id === question.id);
            if (i !== -1) {
              defaultQuestions[i].title = question.title;
              defaultQuestions[i].active = question.active;
            }

            i = originalDefaultQuestions.findIndex(q => q.id === question.id);
            if (i !== -1) {
              originalDefaultQuestions[i].title = question.title;
              originalDefaultQuestions[i].active = question.active;
            }

            i = dealerQuestions.findIndex(q => q.id === question.id);
            if (i !== -1) {
              dealerQuestions[i].title = question.title;
              dealerQuestions[i].active = question.active;
            }

            i = originalDealerQuestions.findIndex(q => q.id === question.id);
            if (i !== -1) {
              originalDealerQuestions[i].title = question.title;
              originalDealerQuestions[i].active = question.active;
            }

            this.setState({ isLoading: false, showEditModal: false, defaultQuestions, originalDefaultQuestions, dealerQuestions, originalDealerQuestions }, () => {
              this.handleRefresh();
            });
          } else {
            console.log("error while storing question");
            this.setState({ isLoading: false });
          }
        })
        .catch(error => {
          this.setState({ isLoading: false }, () => {
            console.log("Error while saving question: ", error);
            this.modalMsg.show(error.message, { type: "error" });
          });
        });
    });
  };

  handleCancelNewQuestion = () => {
    this.setState({ showNewQuestionModal: false });
  };

  handleCancelEditQuestion = () => {
    this.setState({ showEditModal: false }); /*, () => {
          this.handleRefresh()
        });*/ // I comment this because i don't undertand why we should refresh when the cancel button was clicked, nothing is updated... i still leave it as comment in case i'm missing something here
  };

  renderModalAlert = () => <AlertContainer ref={a => (this.modalMsg = a)} {...alertOptions} />;

  renderLoading = () => {
    return (
      <div className="Table__loading Loader-Placeholder">
        <div className="bounce1"></div>
        <div className="bounce2"></div>
        <div className="bounce3"></div>
        <section>{this.props.t("loading").message || "Loading"}</section>
      </div>
    );
  };

  handleDeleteQuestion = id => {
    let { defaultQuestions, originalDefaultQuestions, dealerQuestions, originalDealerQuestions } = this.state;
    let i = defaultQuestions.findIndex(q => q.id === id);
    if (i !== -1) defaultQuestions.splice(i, 1);

    i = originalDefaultQuestions.findIndex(q => q.id === id);
    if (i !== -1) originalDefaultQuestions.splice(i, 1);

    i = dealerQuestions.findIndex(q => q.id === id);
    if (i !== -1) dealerQuestions.splice(i, 1);

    i = originalDealerQuestions.findIndex(q => q.id === id);
    if (i !== -1) originalDealerQuestions.splice(i, 1);

    this.setState({ showEditModal: false, defaultQuestions, originalDefaultQuestions, dealerQuestions, originalDealerQuestions });
  };

  render() {
    const {
      tags,
      selectedTags,
      defaultQuestions,
      dealerQuestions,
      showNewQuestionModal,
      showEditModal,
      currentQuestion,
      newQuestionType,
      loadingDealerQuestions,
      loadingDefaultQuestions,
      isLoading,
      questionElements,
      searchTerm,
    } = this.state;

    const tagOptions =
      tags && tags.length > 0
        ? tags.map((item, key) => {
            return { text: item.name, value: item.id, key };
          })
        : [];

    let defaultQuestionsList =
      defaultQuestions && defaultQuestions.length > 0
        ? defaultQuestions.map(item => {
            return (
              <List.Item
                className={`QuestionItem ${item.active ? "green" : "red"} -cursor-pointer-no-color`}
                key={item.id}
                onClick={() => this.handleEditQuestion(item.id)}
              >
                <List.Content>
                  <span>{item.title}</span>
                </List.Content>
              </List.Item>
            );
          })
        : [];

    let dealerQuestionsList =
      dealerQuestions && dealerQuestions.length > 0
        ? dealerQuestions.map(item => {
            return (
              <List.Item
                className={`QuestionItem ${item.active ? "green" : "red"} -cursor-pointer-no-color`}
                key={item.id}
                onClick={() => this.handleEditQuestion(item.id)}
              >
                <List.Content>
                  <span>{item.title}</span>
                </List.Content>
              </List.Item>
            );
          })
        : [];

    const currentUser = this.props.authState.user;

    let questionButtons = [{ id: "dealer", icon: "warehouse", label: "Dealer Question" }];
    if (isAdmin(currentUser.role_id)) {
      questionButtons.push({ id: "default", icon: "globe", label: "Default Question" });
    }

    return (
      <div className="Questions">
        <SearchPortal>
          <Search
            className="-large-search"
            input={{
              icon: "search",
              iconPosition: "left",
              placeholder: this.props.t("search_questions").message || "Search questions by title...",
            }}
            loading={false}
            showNoResults={false}
            onSearchChange={this.handleSearchChange}
            value={searchTerm}
            fluid
          />
        </SearchPortal>
        {showNewQuestionModal && (
          <CreateQuestion
            isLoading={isLoading}
            save={this.handleStoreQuestion}
            cancel={this.handleCancelNewQuestion}
            dealerID={newQuestionType === "default" ? "" : this.props.globalState.selectedDealer.id}
            renderAlert={this.renderModalAlert}
          />
        )}
        {showEditModal && (
          <EditQuestion
            tags={tags}
            questionElements={questionElements}
            isLoading={isLoading}
            questionID={currentQuestion}
            dealerID={this.props.globalState.selectedDealer.id}
            update={this.handleUpdateQuestion}
            cancel={this.handleCancelEditQuestion}
            delete={this.handleDeleteQuestion}
            renderAlert={this.renderModalAlert}
            alertOptions={alertOptions}
            user={currentUser}
          />
        )}

        <UserMenuActionsPortal>
          <Icon name="refresh" onClick={this.handleRefresh} />
        </UserMenuActionsPortal>

        <FloatingActions items={questionButtons} onRootClick={this.handleNewQuestionModal} />
        <SubHeader>
          <Grid stackable className="SubHeader_content_filters -no-padding -no-margin">
            <Grid.Column width={4}>
              <h1>{this.props.t("questions").message || "Questions"}</h1>
            </Grid.Column>
            <Grid.Column width={12}>
              <Dropdown
                placeholder={this.props.t("filter_by_tag").message || "Filter by tag"}
                selection
                fluid
                search
                options={tagOptions}
                multiple={true}
                value={selectedTags}
                onChange={this.handleTagChange}
              />
            </Grid.Column>
          </Grid>
        </SubHeader>

        <Grid columns={2} divided className="-contained-large">
          <Grid.Row>
            <Grid.Column>
              <List>
                <List.Header as="h3" block={true}>
                  {this.props.t("dealer_questions").message || "Dealer Questions"}
                </List.Header>
                {loadingDealerQuestions ? this.renderLoading() : dealerQuestionsList}
              </List>
            </Grid.Column>

            <Grid.Column>
              <List>
                <List.Header as="h3" block={true}>
                  {this.props.t("default_questions").message || "Default Questions"}
                </List.Header>
                {loadingDefaultQuestions ? this.renderLoading() : defaultQuestionsList}
              </List>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { authState: state.auth, globalState: state.global };
};

const mapDispatchToProps = dispatch => {
  return {
    setAlert: alertOptions => dispatch(setAlert(alertOptions)),
  };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Questions));
