import React, { Component } from "react";
import { Button } from "semantic-ui-react";
import moment from "moment";

import CarsService from "./../../modules/Cars/service";
import "./MCCButton.css";

class MCCButton extends Component {
  state = {
    label: "MCC",
    shouldRedirect: false,
    status: this.props.appointment.car.mcc_status,
    hasError: false,
    errorMsg: "",
  };

  componentDidMount() {
    // If mcc status currently 0 or mcc status time > 24 h do the MCC request and
    // update the mcc button without openning new tab
    const MCC_TIME = this.props.appointment.car.mcc_status_time;
    const ELAPSED = moment.duration(moment().diff(MCC_TIME)).asHours();

    if (this.props.appointment.car.mcc_status === 0 || ELAPSED > 24) {
      this.setState(
        {
          shouldRedirect: false,
        },
        () => {
          this.setMCC();
        }
      );
    } else {
      this.setState({ shouldRedirect: true });
    }
  }

  setMCC = () => {
    CarsService.setMCCCar({ car_key: this.props.appointment.car_id })
      .then(result => {
        if (!result || !result.data || !result.data.data) {
          throw new Error("No result data from MCC response");
        }

        if (result.data.data.error) throw new Error(result.data.data.error);

        this.setState({ status: result.data.data.status });

        if (result.data.data.redirect_url && this.state.shouldRedirect) {
          this.setState({ label: "MCC*" });
          let t = window.open(result.data.data.redirect_url, "_blank");
          t.focus();
        }

        this.setState({ hasError: false, errorMsg: "" });
      })
      .catch(error => {
        let errorMsg;
        errorMsg = error.response && error.response.data ? error.response.data.errors[0] : "Error setting MCC";

        this.setState({ hasError: true, errorMsg });
        console.warn("Error setting MCC for car.", error);
      });
  };

  handleClick = () => {
    this.setState(
      {
        shouldRedirect: true,
      },
      () => {
        this.setMCC();
      }
    );
  };

  render() {
    let status = this.state.hasError ? "error" : this.state.status;

    return (
      <Button
        basic
        fluid
        size="large"
        title={this.state.hasError && this.state.errorMsg ? this.state.errorMsg : ""}
        className={`MCCButton -status-${status}`}
        onClick={this.handleClick}
      >
        {this.state.label}
      </Button>
    );
  }
}

export default MCCButton;
