import React from "react";
import propTypes from "prop-types";
import { Grid } from "semantic-ui-react";

import "./SubHeader.css";

const SubHeader = ({ pageTitle, children }) => {
  return (
    <div className="SubHeader">
      <Grid className="-contained-large">
        {/* <Grid.Row className="SubHeader_title">
                    {pageTitle}
                </Grid.Row> */}
        <Grid.Row className="SubHeader_content">{children}</Grid.Row>
      </Grid>
    </div>
  );
};

SubHeader.propTypes = {
  pageTitle: propTypes.string,
};

export default SubHeader;
