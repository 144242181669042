import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Grid, Segment, Modal, Button } from "semantic-ui-react";
import moment from "moment";

import { transformCamelCaseToSnakeCase } from "../../util/common";
import ROBListData from "./components/ROBListData";

import Service from "./service";

import "./ROBDetail.scss";

export const ROB_STATUS = {
  CONTRACT: 1,
  NO_DATA: 2,
  ERROR: 3,
};

class ROBDetail extends Component {
  state = {
    leaseContract: null,
    supplierContract: null,
    robStatus: null,
    isModalVisible: false,
  };

  componentDidMount() {
    const { regNr: reg_nr, id: car_id } = this.props.car;

    Service.getROBContractData({ reg_nr, car_id })
      .then(res => {
        const { leaseContract = null, supplierContract = null } = res.data.data;

        if (!leaseContract && !supplierContract) this.setState({ robStatus: ROB_STATUS.NO_DATA });
        else this.setState({ leaseContract, supplierContract, robStatus: ROB_STATUS.CONTRACT });
      })
      .catch(err => {
        console.error("Error geting ROB data", err);
        this.setState({ robStatus: ROB_STATUS.ERROR });
      });
  }

  handleToggleDisplayModal = () => {
    this.setState(({ isModalVisible }) => ({ isModalVisible: !isModalVisible }));
  };

  getValueForDisplayROBModalSection = value => {
    const { t } = this.props;

    if (typeof value === "string") return t(transformCamelCaseToSnakeCase(value)).message || value;
    if (typeof value === "boolean") return value ? t("Yes").message || value : t("No").message || value;

    return value;
  };

  renderSectionDataRow = (key, value, idx) => {
    const { t } = this.props;

    const snakeCaseKey = transformCamelCaseToSnakeCase(key);
    const translatedKey = t(snakeCaseKey).message || snakeCaseKey;
    const valueToDisplay = this.getValueForDisplayROBModalSection(value);

    return (
      <div className={`data-row ${valueToDisplay.length > 12 ? "data-row-long-text" : ""}`} key={idx}>
        <div>{translatedKey}</div>
        <div>{valueToDisplay}</div>
      </div>
    );
  };

  renderROBModalSection = (data, title) => {
    if (!data) return null;

    const { t } = this.props;

    const keyValues = Object.entries(data);

    return (
      <>
        {title && <div className="segment-title">{t(title).message || title}</div>}
        {keyValues.map(([key, value], idx) => {
          if (Array.isArray(value)) {
            return <ROBListData title={key} data={value} onGetValueForDisplayROBModalSection={this.getValueForDisplayROBModalSection} />;
          } else {
            const secondLevelKeyValues = Object.entries(value);
            const snakeCaseKey = transformCamelCaseToSnakeCase(key);
            const translatedKey = t(snakeCaseKey).message || snakeCaseKey;
            return (
              <React.Fragment key={idx}>
                <div className={`section-title ${idx > 0 ? "section-title-separator" : ""}`}>{translatedKey}</div>

                {secondLevelKeyValues.map(([secondLevelKey, secondLevelValue], idx) => {
                  return (
                    <div className="rob-modal-section">
                      <div className="section-content">
                        <div className="rob-modal-section">{this.renderSectionDataRow(secondLevelKey, secondLevelValue, idx)}</div>
                      </div>
                    </div>
                  );
                })}
              </React.Fragment>
            );
          }
        })}
      </>
    );
  };

  prepareDataForROBModal = () => {
    const { leaseContract, supplierContract } = this.state;
    if (!leaseContract && !supplierContract) return null;

    const leaseContractData = leaseContract
      ? {
          leaseContract: {
            endDate: leaseContract.endDate ? moment(leaseContract.endDate).format("YYYY-MM-DD") : null,
            maximumMileage: leaseContract.maximumMileage,
            repairAndMaintenance: leaseContract.repairAndMaintenance.inContract,
          },

          robTires: leaseContract.tires,
        }
      : null;

    const { allowedCosts: allowedCostsTires, ...robTires } = supplierContract.tires;
    const { allowedCosts: allowedCostsRentalVehicle, ...rentalVehicle } = supplierContract.rentalVehicle;

    const supplierContractData = supplierContract
      ? {
          repairAndMaintenance: supplierContract.repairAndMaintenance,
          robTires,
          allowedCostsTires,
          rentalVehicle,
          allowedCostsRentalVehicle,
        }
      : null;

    let rentalVehicleData = null;

    if (leaseContract) {
      const { rentalClassesInContract, rentalClassesNotInContract, ...rentalVehicleRest } = leaseContract.rentalVehicle;
      rentalVehicleData = { rentalVehicle: rentalVehicleRest, rentalClassesInContract, rentalClassesNotInContract };
    }

    return {
      leaseContractData,
      supplierContractData,
      rentalVehicleData,
    };
  };

  renderROBModal = () => {
    const { t } = this.props;
    const data = this.prepareDataForROBModal();

    if (!data) return null;

    const { leaseContractData, supplierContractData, rentalVehicleData } = data;

    return (
      <Modal open={true} className="ROBModal">
        <Modal.Header className="modal-header">
          <div>{t("rob_info").message || "ROB Info"}</div>
          <Button color="green" onClick={this.handleToggleDisplayModal}>
            {t("close").message || "Close"}
          </Button>
        </Modal.Header>
        <Modal.Content scrolling>
          <Grid>
            <Grid.Row>
              <Grid.Column width={supplierContractData ? 6 : 8}>
                <Segment>{this.renderROBModalSection(leaseContractData)}</Segment>
              </Grid.Column>
              <Grid.Column width={leaseContractData ? 5 : 16}>
                <Segment>{this.renderROBModalSection(supplierContractData, "supplier_contract")}</Segment>
              </Grid.Column>
              <Grid.Column width={supplierContractData ? 5 : 8}>
                <Segment>{this.renderROBModalSection(rentalVehicleData)}</Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
      </Modal>
    );
  };

  render() {
    const { robStatus, isModalVisible } = this.state;
    const ROBColor = robStatus === ROB_STATUS.CONTRACT ? "green" : robStatus === ROB_STATUS.ERROR ? "red" : "white";

    return (
      <div className="ROBDetail">
        <Button basic fluid color={ROBColor} onClick={this.handleToggleDisplayModal} className="ROBButton">
          ROB
        </Button>
        {isModalVisible && this.renderROBModal()}
      </div>
    );
  }
}

export default withTranslation()(ROBDetail);
