import React from "react";
import { Button, Icon, Label } from "semantic-ui-react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { withTranslation } from "react-i18next";

import { UserInput } from "../../../components";

import "./QuestionElementSolution.css";

const QuestionElementSolution = ({
  configuration,
  onConfigurationChanged,
  solutionNewItemLabels,
  onSolutionNewItemLabelsChanged,
  onSolutionItemAdded,
  onSolutionItemRemoved,
  onSolutionSortEnd,
  t,
  id,
}) => {
  const SortableItem = SortableElement(({ value, listType }) => {
    return (
      <Label className="ListItem" size="big" color="blue">
        <Icon onClick={() => onSolutionItemRemoved(value.label, listType)} name="delete" /> {value.label}
      </Label>
    );
  });

  const SortableList = SortableContainer(({ items, listType }) => {
    return (
      <div>
        {items.map((item, key) => (
          <SortableItem key={`item-${key}`} index={key} value={item} listType={listType} />
        ))}
      </div>
    );
  });

  function renderItems(items, listType, categoryTitle) {
    let itemsList = null;
    if (items && items.length > 0) {
      itemsList = <SortableList items={items} listType={listType} distance={5} onSortEnd={onSolutionSortEnd} />;
    }

    return (
      <div className="QuestionElementSolution" data-listtype={listType}>
        <h3 className="category-title">{categoryTitle}</h3>
        {itemsList}
        <div>
          <UserInput
            key={id}
            name={listType + "_items"}
            className="NewLabel"
            floated="left"
            label="New label"
            value={solutionNewItemLabels[listType + "_items"]}
            onChange={onSolutionNewItemLabelsChanged}
          />
          <Button className="AddNewLabel" floated="right" color="green" onClick={() => onSolutionItemAdded(listType)}>
            <Icon name="add" />
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="ElementSolution Scrollbar">
      {renderItems(configuration.intervention_items, "intervention", "Intervention")}
      {renderItems(configuration.unit_items, "unit", "Unit")}
      {renderItems(configuration.value_items, "value", "Value")}
      {renderItems(configuration.type_items, "type", "Type")}
    </div>
  );
};

export default withTranslation()(QuestionElementSolution);
