import React from "react";
import { Icon, Label } from "semantic-ui-react";
import "./MechanicNotes.css";

const MechanicNote = ({ note }) => {
  return (
    <div className="MechanicNote">
      <Label color="orange">
        <Icon name="wrench" />
      </Label>
      <span className="MechanicNote_text">{note}</span>
    </div>
  );
};

export default MechanicNote;
