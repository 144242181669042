import React from "react";
import { connect } from "react-redux";
import { NoAccess } from "./../../modules";

const checkPermissions = (acl, resource, activity) => {
  if (!acl[resource]) {
    return false;
  }

  if (Array.isArray(activity)) {
    return activity.every(activity => acl[resource].some(acl => acl.activity === activity));
  }

  return acl[resource].some(acl => acl.activity === activity);
};

const Can = ({ acl, I, a, the, or, children, renderNoAccess }) => {
  let resource = a || the;
  if (!acl || Object.keys(acl).length === 0 || !resource || (Array.isArray(I) && !I.length) || !I) {
    return renderNoAccess ? <NoAccess /> : null;
  }

  if (checkPermissions(acl, resource, I)) {
    return children;
  }

  if (Array.isArray(or)) {
    for (let item of or) {
      if (checkPermissions(acl, item, I)) {
        return children;
      }
    }
  }

  return renderNoAccess ? <NoAccess /> : null;
};

Can.defaultProps = {
  I: "",
  a: "",
  the: "",
  or: [],
  acl: [],
  renderNoAccess: false,
};

export default connect(state => ({
  acl: state.auth.user && state.auth.user.acl,
}))(Can);
