import axios from "axios";

import ENV from "../../util/env-config";
import { getSessionCookie } from "../../util/common";

class Service {
  static requestConfig() {
    return {
      headers: {
        Authorization: `Bearer ${getSessionCookie()}`,
        Pragma: "no-cache",
      },
    };
  }

  static listExports() {
    return axios.get(ENV.reportsBaseUrl + "/exports/list_exports", this.requestConfig());
  }

  static addExport(requestData) {
    return axios.post(ENV.reportsBaseUrl + "/exports/add", requestData, this.requestConfig());
  }

  static updateExport(requestData) {
    return axios.post(ENV.reportsBaseUrl + "/exports/update", requestData, this.requestConfig());
  }

  static deleteExport(factory_export_id) {
    return axios.post(ENV.reportsBaseUrl + "/exports/delete", { factory_export_id }, this.requestConfig());
  }

  static exportData(factory_export_id) {
    return axios.post(ENV.reportsBaseUrl + "/exports/download", { factory_export_id }, this.requestConfig());
  }

  static listReceivers(factory_export_id) {
    return axios.post(ENV.reportsBaseUrl + "/exports/list_receivers", { factory_export_id }, this.requestConfig());
  }

  static listReports() {
    return axios.get(ENV.reportsBaseUrl + "/exports/list_reports", this.requestConfig());
  }
}

export default Service;
