import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Button, Checkbox, Dropdown, Form, Transition, Modal, Icon, Input, Image, Message, Label, Popup } from "semantic-ui-react";
import { faCalendarCheck, faCarAlt, faTimesCircle, faUserCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCarGarage } from "@fortawesome/pro-solid-svg-icons";
import zxcvbn from "zxcvbn";
import moment from "moment";

import Service from "./service";
import { LocationDropdown } from "../../components";
import "./AccountSettings.css";
import { handleErrorMessage, NOTIFICATION_ENTITY_TEMPLATE, NOTIFICATION_FIELD } from "./util";
import { AUTH_ACTION_TYPES, updateUser, updateLocation, resetUserUpdate, updateUserSuccess, updateUserFail } from "./store";
import { getAppointments, resetIconFilters } from "../Appointments/store";
import { logout } from "../Auth/store";
import { ROLES } from "../Users/roles";
import Can from "../Can";

class AccountSettings extends Component {
  constructor(props) {
    super(props);

    const { globalState, authState, dealer } = props;
    const { user } = authState;

    let locations = [];
    if (user.role_id === ROLES.WARRANTY_MANAGER) {
      if (globalState.dealers?.length > 0) {
        let locations_ids = user.location_ids?.length > 0 ? user.location_ids.concat(user.dealer_location_id) : [user.dealer_location_id];

        globalState.dealers.forEach(d => {
          if (d.locations?.length > 0) d.locations.forEach(l => locations_ids.includes(l.id) && locations.push(l));
        });
      }
    } else if (dealer?.locations?.length > 0) locations = dealer.locations.sort((a, b) => (a < b ? 1 : -1));

    locations = locations.filter(l => l.active);

    this.state = {
      account: null,
      password: {
        oldPassword: "",
        newPassword: "",
        passwordConfirmation: "",
        strength: 0,
      },
      passwordVisibility: [false, false, false],
      accountOriginal: null,
      accountOriginalLocation: null,
      passwordConfirmation: null,
      hasMessage: false,
      hasPasswordError: false,
      isLocationChanged: false,
      isChangePasswordVisible: false,
      isLoadingPassword: false,
      error: null,
      locations,
      landingPageOptions: [],
      isUpdatingUser: false,
      listAppointmentsByScheduledRange: false,
      groupedEntities: this.groupEntities(),
    };
  }

  componentDidMount() {
    this.handleGetLandingPages();
    this.setState({
      account: { ...this.props.authState.user, notification_element_ids: this.props.authState.user.notification_element_ids || [] },
      accountOriginal: { ...this.props.authState.user },
      accountOriginalLocation: this.props.authState.user.dealer_location_id,
      listAppointmentsByScheduledRange: this.props.authState.user.list_appointments_by_scheduled_range,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.authState.actionType === AUTH_ACTION_TYPES.UPDATE_USER_SUCCESS) {
      if (nextProps.authState.shouldLogout) {
        this.props.logout();
      }
    }
    if (nextProps.authState.actionType === AUTH_ACTION_TYPES.UPDATE_USER_FAIL) {
      const messageConfig = {
        icon: "warning circle",
        color: "Red",
        header: "Error saving account settings",
      };
      this.setState({ hasMessage: true, messageConfig });
    }
  }

  handleGetLandingPages = () => {
    Service.getLandingPages()
      .then(result => {
        const landingPageOptions = [];
        result?.data?.data?.length &&
          result.data.data.forEach(item => {
            if (this.props.authState.user.acl[item.name]?.some(a => a.activity === "browse"))
              landingPageOptions.push({
                key: item.id,
                value: item.id,
                text: this.props.t(item.name).message || item.name,
              });
          });
        this.setState({ landingPageOptions });
      })
      .catch(error => console.log("error", error));
  };

  goBack = () => {
    this.props.resetUserUpdate();
    this.props.history.goBack();
  };

  isLocationAssigned = locationId => {
    const { account } = this.state;
    return account && account.my_locations && account.my_locations.findIndex(f => f === locationId) > -1;
  };

  validatePasswordForm = () => {
    let pwd = this.state.password;
    let messages = [];

    if (!(pwd.oldPassword && pwd.newPassword && pwd.passwordConfirmation)) {
      messages.push(this.props.t("please_check_your_input_fill_in_all_the_required_fields").message || "Please check your input. Fill in all the required fields.");
    }

    if (pwd.newPassword !== pwd.passwordConfirmation) {
      messages.push(
        this.props.t("new_password_and_new_password_confirmation_must_be_the_same").message || "New password and new password confirmation must be the same."
      );
    }

    if (pwd.oldPassword === pwd.passwordConfirmation) {
      messages.push(this.props.t("new_password_must_be_different_from_your_previous_password").message || "New password must be different from your previous password");
    }

    if (pwd.strength < 1) {
      messages.push(this.props.t("password_not_secure_enough").message || "New Password is not secure enough.");
    }

    return messages;
  };

  resetPasswordForm = () => {
    let password = this.state.password;
    password.newPassword = "";
    password.oldPassword = "";
    password.passwordConfirmation = "";
    password.strength = 0;
    this.setState({ password });
  };

  handleChange = (event, data) => {
    let account = this.state.account;

    if (data.type !== "checkbox") {
      account[data.name] = data.value;
    } else {
      account[data.name] = data.checked;
    }

    this.setState({ account });
  };

  handlePictureChange = event => {
    let $ = document.getElementById("pictureFileInput");

    if ($ && $.files[0]) {
      this.prepareAndUploadPicture($.files[0]);
    }
  };

  handleDeletePicture = e => {
    let account = this.state.account;
    account.profile_picture = "";
    this.setState({ account });
  };

  prepareAndUploadPicture = picture => {
    const {
      dealer,
      authState: { user },
    } = this.props;

    let data = new FormData();

    const date = moment(user.created_on).format("YYYY/MM/DD");
    const path = `${dealer.id}_${dealer.name}/${date}/${user.id}_${user.last_name}`;

    data.append("file", picture);
    data.append("path", path);

    Service.uploadProfilePicture(data)
      .then(res => {
        if (!res.data.data || !res.data.data.URL) {
          return;
        }

        let account = this.state.account;
        account.profile_picture = res.data.data.URL;
        this.setState({ account });
      })
      .catch(err => {});
  };

  handlePasswordChange = (event, data) => {
    let password = this.state.password;
    password[data.name] = data.value;
    if (data.name === "newPassword") {
      password.strength = zxcvbn(data.value, [
        this.state.account.last_name,
        this.state.account.first_name,
        this.state.account.email,
        this.state.account.username,
        "claire",
        "auto",
      ]).score;
    }
    this.setState({ password });
  };

  handleChangeAssignableLocation = (event, data) => {
    const ID = data.value;
    let account = this.state.account;
    let locations = account.my_locations || [];

    if (this.isLocationAssigned(ID)) {
      locations.splice(
        locations.findIndex(l => l === ID),
        1
      );
    } else {
      locations.push(ID);
    }

    account.my_locations = locations;
    this.setState({ account });
  };

  handleLocationChange = (_event, data) => {
    const { account, accountOriginalLocation } = this.state;

    if (data.value === account.dealer_location_id) return;

    let isLocationChanged = accountOriginalLocation !== data.value;
    let hasMessage = false;
    let messageConfig = null;

    if (isLocationChanged) {
      hasMessage = true;
      messageConfig = {
        icon: "info",
        color: "blue",
        header: this.props.t("location_changed").message || "Location changed",
        content: this.props.t("location_changed_msg").message || "Since you changed your location you will be logged-out once you click 'Save'.",
      };
    }

    this.setState(
      state => ({ account: { ...state.account, dealer_location_id: data.value }, isLocationChanged, hasMessage, messageConfig }),
      () => {
        if (isLocationChanged) {
          this.handleUpdateLocation();
        }
      }
    );
  };

  handleReloadAppointments = () => {
    const { list_appointments_by_scheduled_range } = this.state.account;
    const { globalState, appointmentsState } = this.props;

    if (appointmentsState.appointments.length) {
      const requestData = { date: moment(appointmentsState.selectedDate), ...appointmentsState.appointmentsFilters };

      if (list_appointments_by_scheduled_range) requestData.list_by_scheduled_range = true;
      if (appointmentsState.since_date) requestData.since_date = appointmentsState.since_date;

      const LOCATION = globalState.selectedLocation;

      if (LOCATION.id !== "all") {
        requestData.location_id = LOCATION.id;
      } else {
        requestData.location_ids = LOCATION.myLocations;
      }

      this.props.getAppointments(requestData, true);
    }
  };

  handleShowChangePassword = () => {
    this.setState({ isChangePasswordVisible: true });
  };

  handleHideChangePassword = () => {
    this.resetPasswordForm();
    this.setState({ isChangePasswordVisible: false, hasPasswordError: false });
  };

  handleSaveChangePassword = () => {
    this.setState({ isLoadingPassword: true, hasPasswordError: false });
    let formValidation = this.validatePasswordForm();
    if (formValidation.length > 0) {
      const messageConfig = {
        icon: "warning",
        color: "yellow",
        header: this.props.t("invalid_form").message || "Invalid form",
        list: formValidation,
      };
      this.setState({ hasPasswordError: true, messageConfig });
      this.setState({ isLoadingPassword: false });
      return;
    }

    let password = this.state.password;
    Service.updateUserPassword({ old_password: password.oldPassword, new_password: password.newPassword, new_password_confirm: password.passwordConfirmation })
      .then(result => {
        this.setState({ isLoadingPassword: false });
        this.handleHideChangePassword();

        const messageConfig = {
          icon: "check",
          color: "green",
          header: this.props.t("change_password_success").message || "Password has been changed successfully.",
        };
        this.setState({ hasMessage: true, messageConfig });
      })
      .catch(error => {
        console.error("Error saving new password", error);
        let msg = handleErrorMessage(error);

        const messageConfig = {
          icon: "warning",
          color: "yellow",
          header: "Error saving new password",
          content: msg,
        };
        this.setState({ hasPasswordError: true, messageConfig });
        this.setState({ isLoadingPassword: false });
      });
  };

  handleUpdateLocation = () => {
    const { account, isLocationChanged } = this.state;

    this.props.updateLocation({
      location: {
        id: account.id,
        location_id: account.dealer_location_id,
        dealer_id: account.dealer_id,
      },
      shouldLogout: isLocationChanged,
    });
  };

  checkIfShopStatusChanged = (prevAccount, currentAccount) => {
    if (prevAccount.shop_status_same_day_filter_enabled !== currentAccount.shop_status_same_day_filter_enabled) return true;
    if (prevAccount.shop_status_next_day_filter_enabled !== currentAccount.shop_status_next_day_filter_enabled) return true;
    if (prevAccount.shop_status_to_order_filter_enabled !== currentAccount.shop_status_to_order_filter_enabled) return true;
    if (prevAccount.shop_status_backorder_filter_enabled !== currentAccount.shop_status_backorder_filter_enabled) return true;

    return false;
  };

  handleSaveAccount = () => {
    const { account, isLocationChanged, listAppointmentsByScheduledRange, accountOriginal } = this.state;

    this.setState({ isUpdatingUser: true }, () => {
      Service.updateUserAccount(account)
        .then(res => {
          this.props.updateUserSuccess(account, isLocationChanged);

          if (listAppointmentsByScheduledRange !== account.list_appointments_by_scheduled_range) {
            this.handleReloadAppointments();
          }

          if (account.notification_element_ids.length && Notification.permission === "denied") Notification.requestPermission();

          if (this.checkIfShopStatusChanged(accountOriginal, account)) this.props.resetIconFilters();

          this.setState({ listAppointmentsByScheduledRange: account.list_appointments_by_scheduled_range, isUpdatingUser: false });
        })
        .catch(err => {
          // calling handleErrorMessage inside this.props.updateUserFail is causing an infinite loop
          const errMsg = handleErrorMessage(err);
          if (errMsg) this.props.updateUserFail(errMsg);
          this.setState({ isUpdatingUser: false });
        });
    });
  };

  handleChangeNotificationsDropdown = arr => {
    this.setState(({ account }) => ({ account: { ...account, notification_element_ids: [...new Set(arr)] } }));
  };

  handleChangeNotificationsDropdown = (entity, value) => {
    const notification_element_ids = [
      ...this.state.account.notification_element_ids.filter(id => !entity.elements.some(e => e.notification_element_id === id)),
      ...value,
    ];

    this.setState(({ account }) => ({ account: { ...account, notification_element_ids } }));
  };

  handleChangeNotificationsCheckbox = value => {
    const notification_element_ids = [...this.state.account.notification_element_ids];

    const idx = notification_element_ids.findIndex(el => el === value);
    if (idx > -1) notification_element_ids.splice(idx, 1);
    else notification_element_ids.push(value);

    this.setState(({ account }) => ({ account: { ...account, notification_element_ids } }));
  };

  groupEntities = () => {
    const { notificationElements } = this.props.globalState;

    if (!notificationElements?.length) return {};

    const groupedEntities = {};

    notificationElements.forEach(element => {
      if (!groupedEntities[element.notification_entity_id])
        groupedEntities[element.notification_entity_id] = { title: element.entity.name, templateId: element.entity.template_id, elements: [] };

      groupedEntities[element.notification_entity_id].elements.push(element);
    });

    return groupedEntities;
  };

  renderElementIcon = notificationElementId => {
    switch (notificationElementId) {
      case NOTIFICATION_FIELD.NOTIFICATION_FIELD_CAR_IN_SHOP:
        return <FontAwesomeIcon icon={faCarGarage} />;
      case NOTIFICATION_FIELD.NOTIFICATION_FIELD_CAR_OUT_OF_SHOP:
        return <Icon name="sign out" />;
      case NOTIFICATION_FIELD.NOTIFICATION_FIELD_MECHANIC_NEEDS_HELP:
        return <Icon name="help circle" />;
      case NOTIFICATION_FIELD.NOTIFICATION_FIELD_IS_MONEY:
        return <Icon name="dollar" />;
      case NOTIFICATION_FIELD.NOTIFICATION_FIELD_IS_SHOP:
        return <Icon name="shop" />;
      case NOTIFICATION_FIELD.NOTIFICATION_FIELD_IS_STAR:
        return <Icon name="star" />;
      default:
        return null;
    }
  };

  renderStatusIcon = notificationElementId => {
    switch (notificationElementId) {
      case NOTIFICATION_FIELD.NOTIFICATION_FIELD_CANCELED_APPOINTMENT_STATUS:
        return <FontAwesomeIcon icon={faTimesCircle} />;
      case NOTIFICATION_FIELD.NOTIFICATION_FIELD_NEWCAR_APPOINTMENT_STATUS:
        return <FontAwesomeIcon icon={faCarAlt} />;
      case NOTIFICATION_FIELD.NOTIFICATION_FIELD_CARCHECK_APPOINTMENT_STATUS:
        return <i className="material-icons -notification-status-icon">build</i>;
      case NOTIFICATION_FIELD.NOTIFICATION_FIELD_PRICINGOK_APPOINTMENT_STATUS:
        return <i className="material-icons -notification-status-icon">local_offer</i>;
      case NOTIFICATION_FIELD.NOTIFICATION_FIELD_CUSTOMEROK_APPOINTMENT_STATUS:
        return <i className="material-icons -notification-status-icon">face</i>;
      case NOTIFICATION_FIELD.NOTIFICATION_FIELD_CARREADY_APPOINTMENT_STATUS:
        return <i className="material-icons -notification-status-icon">done_all</i>;
      case NOTIFICATION_FIELD.NOTIFICATION_FIELD_DIAGNOSE_APPOINTMENT_STATUS:
        return <i className="material-icons -notification-status-icon">report_problem</i>;
      case NOTIFICATION_FIELD.NOTIFICATION_FIELD_AANAME_APPOINTMENT_STATUS:
        return <i className="material-icons -notification-status-icon">font_download</i>;
      case NOTIFICATION_FIELD.NOTIFICATION_FIELD_CALCULATING_APPOINTMENT_STATUS:
        return <i className="material-icons -notification-status-icon">attach_money</i>;
      case NOTIFICATION_FIELD.NOTIFICATION_FIELD_CONTACT_APPOINTMENT_STATUS:
        return <i className="material-icons -notification-status-icon">mail</i>;
      case NOTIFICATION_FIELD.NOTIFICATION_FIELD_CARCHECKSTARTED_APPOINTMENT_STATUS:
        return <i className="material-icons -notification-status-icon">timelapse</i>;
      case NOTIFICATION_FIELD.NOTIFICATION_FIELD_QUALITYCHECK_APPOINTMENT_STATUS:
        return <i className="material-icons -notification-status-icon">verified_user</i>;
      case NOTIFICATION_FIELD.NOTIFICATION_FIELD_CUSTOMERANSWERED_APPOINTMENT_STATUS:
        return <FontAwesomeIcon icon={faUserCheck} />;
      case NOTIFICATION_FIELD.NOTIFICATION_FIELD_HANDLECHECKIN_APPOINTMENT_STATUS:
        return <i className="material-icons -notification-status-icon">event_available</i>;
      case NOTIFICATION_FIELD.NOTIFICATION_FIELD_CHECKINDONE_APPOINTMENT_STATUS:
        return <FontAwesomeIcon icon={faCalendarCheck} />;
      case NOTIFICATION_FIELD.NOTIFICATION_FIELD_CARINSHOP_APPOINTMENT_STATUS:
        return <FontAwesomeIcon icon={faCarGarage} />;
      case NOTIFICATION_FIELD.NOTIFICATION_FIELD_CAROUTOFSHOP_APPOINTMENT_STATUS:
        return (
          <span className="car_out_of_shop_status_icon">
            <Icon name="sign-out" />
            <FontAwesomeIcon icon={faCarAlt} />
          </span>
        );
      default:
        return null;
    }
  };

  renderNotificationEntities = () => {
    const { groupedEntities, account } = this.state;
    const { t } = this.props;

    const toRender = [];
    const entityKeys = Object.keys(groupedEntities);

    if (!entityKeys?.length) return null;

    entityKeys.forEach(entityId => {
      const uiElements = [];

      const entity = groupedEntities[entityId];

      if (entity.templateId === NOTIFICATION_ENTITY_TEMPLATE.DROPDOWN_MULTI) {
        uiElements.push(
          <Dropdown
            key={entityId}
            placeholder={t("select_an_option").message || "Select an option"}
            options={entity.elements.map(el => ({
              text: t(el.field.name).message || el.field.name,
              icon: (
                <Label basic className="notification-entity-label">
                  {this.renderStatusIcon(el.notification_element_id)}
                </Label>
              ),
              value: el.notification_element_id,
            }))}
            selection
            selectOnBlur={false}
            multiple
            fluid
            upward
            value={account.notification_element_ids.filter(id => entity.elements.some(e => e.notification_element_id === id))}
            onChange={(_, { value }) => this.handleChangeNotificationsDropdown(entity, value)}
            renderLabel={({ text, value }) => ({ content: text, icon: this.renderStatusIcon(value) })}
          />
        );
      } else if (entity.templateId === NOTIFICATION_ENTITY_TEMPLATE.CHECKBOXES) {
        entity.elements.forEach(el =>
          uiElements.push(
            <Form.Field key={el.notification_element_id} className="notification-checkbox-toggle">
              <label>
                <Label className="circular-icon" circular>
                  {this.renderElementIcon(el.notification_element_id)}
                </Label>
                {t(el.field.name).message || el.field.name}
              </label>
              <Checkbox
                toggle
                checked={account.notification_element_ids.includes(el.notification_element_id)}
                onChange={() => this.handleChangeNotificationsCheckbox(el.notification_element_id)}
              />
            </Form.Field>
          )
        );
      }

      toRender.push(
        <div className="DesktopNotifications" key={entityId}>
          <h4>{t(entity.title).message || entity.title}</h4>
          <Form.Group grouped>{uiElements}</Form.Group>
        </div>
      );
    });

    return (
      <div className="FormSection -desktop-notifications">
        <h3 className="FormSection-title">{t("desktop_notifications").message || "Desktop notifications"}</h3>
        <p className="FormSection-subtitle">
          {t("desktop_notification_explanation").message || "You will get a notification on your desktop when one of the following actions is triggered"}
        </p>

        <div className="PinNotifications">
          <Form.Field className="notification-checkbox-toggle">
            <label>
              <Label className="circular-icon" circular>
                <Icon name="pin" />
              </Label>
              {t("pin_status_notifications").message || "Pin status notifications"}
            </label>
            <Checkbox
              toggle
              checked={account.pin_status_notification_enabled}
              onChange={(e, data) => this.setState({ account: { ...account, pin_status_notification_enabled: data.checked } })}
            />
          </Form.Field>
        </div>

        {toRender.map(r => r)}
      </div>
    );
  };

  togglePassword = i => {
    let passwordVisibility = this.state.passwordVisibility;
    passwordVisibility[i] = !passwordVisibility[i];
    this.setState({ passwordVisibility });
  };

  renderMessage = () => {
    if (this.state.hasMessage) {
      return <Message {...this.state.messageConfig} />;
    }
  };

  renderPasswordMessage = () => {
    if (this.state.hasPasswordError) {
      return <Message {...this.state.messageConfig} />;
    }
  };

  renderCustomLocationsSettings = () => {
    const { account, locations } = this.state;
    const { t } = this.props;

    if (account.role_id === ROLES.CALL_CENTER || account.role_id === ROLES.WARRANTY_MANAGER) {
      return (
        <div className="FormSection">
          <h3 className="FormSection-title">{t("locations").message || "Locations"}</h3>

          <Form.Field>
            <Checkbox
              name="location_column_visible"
              checked={account.location_column_visible}
              onChange={this.handleChange}
              label={t("location_column_visible").message || "Location column visible"}
            />
          </Form.Field>

          <div className="AccountSettings__locations-assignable">
            {!locations && <p>This dealer has no locations</p>}

            {locations?.map((l, k) => (
              <Label basic key={k}>
                <Checkbox name="assignable_location" value={l.id} checked={this.isLocationAssigned(l.id)} onChange={this.handleChangeAssignableLocation} label={l.name} />
              </Label>
            ))}
          </div>
        </div>
      );
    }
  };

  renderPassCheckMarkIcon = type => {
    let r = null;
    let c = "";
    let pass = this.state.password;

    let goodStyle = {
      fontSize: "1.15em",
      position: "absolute",
      marginTop: "8px",
      color: "green",
    };

    let badStyle = {
      fontSize: "1.15em",
      position: "absolute",
      marginTop: "8px",
      color: "#f54545",
    };

    if (type === "new" && pass.newPassword && pass.newPassword.length > 0) {
      if (pass.strength > 2) {
        r = <Icon name="check circle outline" style={goodStyle} />;
        c = "New password looks good";
      } else {
        r = <Icon name="times circle outline" style={badStyle} />;
        c = "New password is not secure enough";
      }
    }

    if (type === "confirm" && pass.passwordConfirmation && pass.passwordConfirmation.length > 0) {
      if (pass.strength > 2 && pass.newPassword === pass.passwordConfirmation) {
        r = <Icon name="check circle outline" style={goodStyle} />;
        c = "New password confirmation looks good";
      } else {
        r = <Icon name="times circle outline" style={badStyle} />;
        c = "Confirmation is not the same as password or not secure enough";
      }
    }

    return <Popup trigger={r} content={c} />;
  };

  renderPasswordChange = () => {
    return (
      <Transition visible={this.state.isChangePasswordVisible} animation="fade up" duration={700}>
        <Modal
          className="ChangePasswordModal"
          size="tiny"
          dimmer="inverted"
          open={this.state.isChangePasswordVisible}
          onClose={this.handleHideChangePassword}
          closeOnDimmerClick={false}
        >
          <Modal.Header>{this.props.t("change_password").message || "Change password"}</Modal.Header>
          <Modal.Content scrolling>
            <div className="text-justify -margin-bottom-20">
              {this.props.t("change_password_description").message ||
                "Your new password must be secure. A secure password cannot contain your first na" +
                  "me, last name, email, ‘claire’ or common words like ‘password’. You don’t need t" +
                  "o use any special characters. A secure password contains two or more uncommon wo" +
                  "rds."}
            </div>
            <Form loading={this.state.isLoadingPassword}>
              <Form.Group widths="equal">
                <Form.Field required>
                  <label>{this.props.t("old_password").message || "Old password"}</label>
                  <Input
                    error={this.state.hasPasswordError}
                    name="oldPassword"
                    value={this.state.password.oldPassword || ""}
                    onChange={this.handlePasswordChange}
                    type={this.state.passwordVisibility[0] ? "text" : "password"}
                    icon={<Icon name={this.state.passwordVisibility[0] ? "hide" : "unhide"} link onClick={() => this.togglePassword(0)} />}
                    placeholder={this.props.t("old_password").message || "Old password"}
                  />
                </Form.Field>
              </Form.Group>

              <Form.Group widths="equal">
                <Form.Field required>
                  {this.state.password.newPassword.length > 0 && (
                    <div className="strength-meter">
                      <div className="strength-meter-fill" data-strength={this.state.password.strength}></div>
                    </div>
                  )}
                  <label>{this.props.t("new_password").message || "New password"}</label>
                  <Input
                    error={this.state.hasPasswordError}
                    name="newPassword"
                    value={this.state.password.newPassword || ""}
                    onChange={this.handlePasswordChange}
                    type={this.state.passwordVisibility[1] ? "text" : "password"}
                    icon={<Icon name={this.state.passwordVisibility[1] ? "hide" : "unhide"} link onClick={() => this.togglePassword(1)} />}
                    placeholder={this.props.t("new_password").message || "New password"}
                  />
                  {this.renderPassCheckMarkIcon("new")}
                </Form.Field>
              </Form.Group>

              <Form.Group widths="equal">
                <Form.Field required>
                  <label>{this.props.t("confirm_new_password").message || "Confirm new password"}</label>
                  <Input
                    error={this.state.hasPasswordError}
                    name="passwordConfirmation"
                    value={this.state.password.passwordConfirmation || ""}
                    onChange={this.handlePasswordChange}
                    type={this.state.passwordVisibility[2] ? "text" : "password"}
                    icon={<Icon name={this.state.passwordVisibility[2] ? "hide" : "unhide"} link onClick={() => this.togglePassword(2)}></Icon>}
                    placeholder={this.props.t("confirm_new_password").message || "Confirm new password"}
                  />
                  {this.renderPassCheckMarkIcon("confirm")}
                </Form.Field>
              </Form.Group>
              {this.renderPasswordMessage()}
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <div
              style={{
                height: "50px",
              }}
            >
              <Button className="-margin-bottom-10" size="large" color="red" floated="left" onClick={this.handleHideChangePassword}>
                {this.props.t("close").message || "Close"}
              </Button>

              <Button
                className="-margin-bottom-10"
                size="large"
                color="green"
                floated="right"
                disabled={this.state.password.strength < 3}
                onClick={this.handleSaveChangePassword}
                loading={this.state.isLoadingPassword}
              >
                {/* disabled={this.validatePasswordForm().length > 0} */}
                {this.props.t("save").message || "Save"}
              </Button>
            </div>
          </Modal.Actions>
        </Modal>
      </Transition>
    );
  };

  renderProfilePicture = () => {
    return (
      <section>
        <label htmlFor="pictureFileInput">
          {!this.state.account.profile_picture && <Icon name="user circle" color="grey" className="UserMenu__avatar -cursor-pointer-no-color" size="massive" />}

          {this.state.account.profile_picture && <Image src={this.state.account.profile_picture} className="-cursor-pointer-no-color" />}
        </label>

        {this.state.account.profile_picture && (
          <Button
            style={{
              marginTop: "5px",
            }}
            size="medium"
            onClick={this.handleDeletePicture}
          >
            {this.props.t("delete_picture").message || "Delete picture"}
          </Button>
        )}

        <Can I="upload" the="files">
          <input id="pictureFileInput" type="file" onChange={this.handlePictureChange} />
        </Can>
      </section>
    );
  };

  appointmentListOptions = () => [
    {
      key: "time_car_app",
      text: this.props.t("appointment_date").message || "Appointment date",
      value: false,
    },
    {
      key: "scheduled_in_out",
      text: this.props.t("scheduled_in_out").message || "Scheduled in/out date",
      value: true,
    },
  ];

  renderForm = () => {
    const { account, locations, landingPageOptions } = this.state;
    const { t } = this.props;

    if (!account) {
      return <Message icon="warning" color="yellow" header="No data" content="There was probably a problem with network connection." />;
    }

    return (
      <Form>
        <div className="FormSection">
          <h3 className="FormSection-title">{t("personal").message || "Personal"}</h3>
          <Form.Group widths="equal">
            <Form.Field>
              <label>{t("first_name").message || "First name"}</label>
              <Input name="first_name" value={account.first_name || ""} disabled={true} placeholder={t("first_name").message || "First name"} />
            </Form.Field>
            <Form.Field>
              <label>{t("last_name").message || "Last name"}</label>
              <Input name="last_name" value={account.last_name || ""} disabled={true} placeholder={t("last_name").message || "Last name"} />
            </Form.Field>
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Field>
              <label>{t("username").message || "Username"}</label>
              <Input name="username" value={account.username || ""} disabled={true} placeholder={t("username").message || "Username"} />
            </Form.Field>
            <Form.Field>
              <label>{t("email").message || "Email"}</label>
              <Input name="email" value={account.email || ""} disabled={true} placeholder={t("email").message || "Email"} />
            </Form.Field>
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Field>
              <label>{t("phone").message || "Phone"}</label>
              <Input name="phone" value={account.phone || ""} onChange={this.handleChange} placeholder={t("phone").message || "Phone"} />
            </Form.Field>
            <Form.Field>
              <label>{t("mobile").message || "Mobile"}</label>
              <Input name="mobile" value={account.mobile || ""} onChange={this.handleChange} placeholder={t("mobile").message || "Mobile"} />
            </Form.Field>
          </Form.Group>
        </div>

        <div className="FormSection">
          <h3 className="FormSection-title">{t("settings").message || "Settings"}</h3>
          <Form.Group widths="equal">
            <Form.Field className="form-field-custom-style">
              <label>{t("location").message || "Location"}</label>
              <LocationDropdown
                fluid
                selection
                disabled={account.dms_nr || account.onei_planning_monteur_id || account.planit_planning_mechanic_id}
                options={locations?.map(l => ({ text: l.name, value: l.id, detail: l, key: l.id }))}
                value={account.dealer_location_id}
                onChange={this.handleLocationChange}
                noIcon
              />
            </Form.Field>
            <Form.Field className="form-field-custom-style">
              <label>&nbsp;</label>
              <Checkbox
                toggle
                checked={account.is_counter_tablet_user}
                label={t("use_counter_tablets").message || "Use counter tablets"}
                onChange={(e, data) => this.setState({ account: { ...account, is_counter_tablet_user: data.checked } })}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field className="form-field-custom-style">
              <label>{t("landing_page").message || "Landing page"}</label>
              <Dropdown
                fluid
                search
                floating
                selection
                name="landing_page_id"
                className="icon"
                value={account.landing_page_id || 1}
                onChange={this.handleChange}
                options={landingPageOptions}
              />
            </Form.Field>
            <Form.Field className="form-field-custom-style">
              <label>{t("list_appointments_by").message || "List appointments by"}</label>
              <Dropdown
                fluid
                search
                floating
                selection
                name="list_appointments_by_scheduled_range"
                className="icon"
                value={!!account.list_appointments_by_scheduled_range}
                onChange={this.handleChange}
                options={this.appointmentListOptions()}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Field className="my-role">
              <label>{t("my_role").message || "My role"}</label>
              <span>{account.role_name}</span>
            </Form.Field>

            <Form.Field>
              <label>&nbsp;</label>
              <Button size="medium" color="green" basic onClick={this.handleShowChangePassword}>
                {t("change_password").message || "Change password"}
              </Button>
            </Form.Field>
          </Form.Group>
        </div>

        <div className="FormSection">
          <h3 className="FormSection-title">{t("order_status").message || "Order Status"}</h3>
          <Form.Group className="ShoppingCart-container">
            <Form.Field>
              <label>{t("shopping_cart_same_day").message || "Shopping cart same day (green)"}</label>
              <Checkbox toggle name="shop_status_same_day_filter_enabled" checked={account.shop_status_same_day_filter_enabled} onChange={this.handleChange} />
            </Form.Field>

            <Form.Field>
              <label>{t("shopping_cart_tomorrow").message || "Shopping cart tomorrow (orange)"}</label>
              <Checkbox toggle name="shop_status_next_day_filter_enabled" checked={account.shop_status_next_day_filter_enabled} onChange={this.handleChange} />
            </Form.Field>
          </Form.Group>

          <Form.Group className="ShoppingCart-container">
            <Form.Field>
              <label>{t("shopping_cart_to_order").message || "Shopping cart to order (blue)"}</label>
              <Checkbox toggle name="shop_status_to_order_filter_enabled" checked={account.shop_status_to_order_filter_enabled} onChange={this.handleChange} />
            </Form.Field>

            <Form.Field>
              <label>{t("shopping_cart_backorder").message || "Shopping backorder (red)"}</label>
              <Checkbox toggle name="shop_status_backorder_filter_enabled" checked={account.shop_status_backorder_filter_enabled} onChange={this.handleChange} />
            </Form.Field>
          </Form.Group>
        </div>

        {this.renderCustomLocationsSettings()}
        {this.renderNotificationEntities()}
        {this.renderMessage()}
        <br></br>
      </Form>
    );
  };

  render() {
    const { isUpdatingUser, account, isChangePasswordVisible } = this.state;
    const { t } = this.props;

    return (
      <>
        <div className="AccountSettings">
          <div className="AccountSettings__picture">{account && this.renderProfilePicture()}</div>
          <div className="AccountSettings__content">
            <h1 className="AccountSettings__content-title">{t("my_account").message || "My Account"}</h1>
            {this.renderForm()}

            <div className="FixedButtonSection">
              <div className="FixedButtonSection-inner">
                <Button type="cancel" basic onClick={this.goBack}>
                  {t("cancel").message || "Cancel"}
                </Button>

                <Button loading={isUpdatingUser} disabled={isUpdatingUser} color="green" onClick={this.handleSaveAccount}>
                  {t("save").message || "Save"}
                </Button>
              </div>
            </div>
          </div>

          {isChangePasswordVisible && this.renderPasswordChange()}
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return { authState: state.auth, globalState: state.global, appointmentsState: state.appointments };
};

const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(logout()),
    updateLocation: params => dispatch(updateLocation(params)),
    updateUser: user => dispatch(updateUser(user)),
    resetUserUpdate: () => dispatch(resetUserUpdate()),
    updateUserSuccess: (user, shouldLogout) => dispatch(updateUserSuccess(user, shouldLogout)),
    updateUserFail: err => dispatch(updateUserFail(err)),
    getAppointments: (data, shouldClearAppointments) => dispatch(getAppointments(data, shouldClearAppointments)),
    resetIconFilters: () => dispatch(resetIconFilters()),
  };
};

export default withRouter(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(AccountSettings)));
