import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Button, Icon, Modal, Form, TextArea, Table, Segment, Label } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

import { ThumbToggle, UserInput } from "../../components";
import { DMS } from "../DMSv3/util";
import { renderInterventionElementType } from "./util";
import { INTERVENTION_ELEMENT_TYPE } from "./common";

class AddAppointmentIntervention extends Component {
  constructor(props) {
    super(props);

    const { title = "", description = "", price = "", subitemDescription = "" } = props;
    const elements = [];

    if (subitemDescription) {
      elements.push({ description: subitemDescription, element_type: INTERVENTION_ELEMENT_TYPE.TEXT, dms_nr: `claire_${moment().utc().format("YYYY-MM-DDTHH:mm:ssZ")}` });
    } else if (props.dms_id === DMS.KEYLOOP_JOBS) {
      elements.push({
        description: `Claire job ${props.user?.first_name || ""} ${props.user?.last_name || ""}`,
        element_type: INTERVENTION_ELEMENT_TYPE.TEXT,
        dms_nr: `claire_${moment().utc().format("YYYY-MM-DDTHH:mm:ssZ")}`,
      });
    }

    this.state = {
      newIntervention: {
        customer_ok: false,
        description,
        title,
        appointment_id: this.props.appointment_id,
        is_local: true,
        visible_to_customer: true,
        price,
        elements,
      },
      subitem: {
        description: "",
        element_type: INTERVENTION_ELEMENT_TYPE.TEXT,
      },
      isAddSubitemModalOpen: false,
      hasInterventionMissingFields: false,
      hasSubitemMissingFields: false,
    };
  }

  handleNewInterventionChange = e => {
    const { name, value } = e.target;

    if (name === "price" && (Number(value) < 0 || isNaN(Number(value)) || (!this.state.newIntervention.price.length && value === "0"))) return;

    this.setState(({ newIntervention }) => ({ newIntervention: { ...newIntervention, [name]: value } }));
  };

  handleNewInterventionCustomerOkChange = () => {
    this.setState(({ newIntervention }) => ({ newIntervention: { ...newIntervention, customer_ok: !newIntervention.customer_ok } }));
  };

  handleToggleSubitemModal = () => {
    this.setState(({ isAddSubitemModalOpen }) => ({ isAddSubitemModalOpen: !isAddSubitemModalOpen }));
  };

  handleChangeSubitem = e => {
    const { name, value } = e.target;

    this.setState(({ subitem }) => ({ subitem: { ...subitem, [name]: value } }));
  };

  handleAddSubitem = () => {
    if (!this.state.subitem.description) {
      this.setState({ hasSubitemMissingFields: true });
    } else {
      this.setState(
        ({ newIntervention, subitem }) => ({
          newIntervention: {
            ...newIntervention,
            elements: [...newIntervention.elements, { ...subitem, dms_nr: `claire_${moment().utc().format("YYYY-MM-DDTHH:mm:ssZ")}` }],
          },
          isAddSubitemModalOpen: false,
        }),
        () => {
          this.setState({ subitem: { description: "", element_type: INTERVENTION_ELEMENT_TYPE.TEXT }, hasInterventionMissingFields: false });
        }
      );
    }
  };

  handleSubmitNewIntervention = () => {
    const newIntervention = { ...this.state.newIntervention };
    newIntervention.price = Number(newIntervention.price);

    if (!newIntervention.title || (this.props.dms_id === DMS.KEYLOOP_JOBS && !newIntervention.elements.length)) {
      this.setState({ hasInterventionMissingFields: true });
    } else {
      this.props.onSubmit({ ...newIntervention, dms_nr: `claire_${moment().utc().format("YYYY-MM-DDTHH:mm:ssZ")}` });
    }
  };

  handleDeleteSubitem = idx => {
    const elements = [...this.state.newIntervention.elements];
    elements.splice(idx, 1);

    this.setState(({ newIntervention }) => ({ newIntervention: { ...newIntervention, elements } }));
  };

  renderSubitemModal = () => {
    const { t } = this.props;

    return (
      <Modal open={this.state.isAddSubitemModalOpen} size="small" className="add-intervnetion-subitem-modal">
        <Modal.Header>
          <h3>{t("add_subitem").message || "Add Subitem"}</h3>
          <FontAwesomeIcon icon={faTimes} onClick={this.handleToggleSubitemModal} />
        </Modal.Header>

        <Modal.Content>
          <Form>
            <Form.Field style={{ flex: "5" }}>
              <label>{t("type").message || "Type"}</label>
              <UserInput disabled placeholder={t("note").message || "Note"} />
            </Form.Field>
            <Form.Field required error={this.state.hasSubitemMissingFields && !this.state.subitem.description}>
              <label>{t("description").message || "Description"}</label>
              <TextArea
                value={this.state.subitem.description}
                name="description"
                placeholder={t("description").message || "Description"}
                onChange={this.handleChangeSubitem}
              />
            </Form.Field>
          </Form>
        </Modal.Content>

        <Modal.Actions>
          <Button onClick={this.handleToggleSubitemModal}>
            <Icon name="close" /> {t("cancel").message || "Cancel"}
          </Button>
          <Button color="green" onClick={this.handleAddSubitem}>
            <Icon name="checkmark" /> {t("save").message || "Save"}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };

  renderSubitemsTable = () => {
    const { elements } = this.state.newIntervention;
    const { t } = this.props;

    if (!elements?.length) return null;

    return (
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{t("type").message || "Type"}</Table.HeaderCell>
            <Table.HeaderCell width={8}>{t("description").message || "Description"}</Table.HeaderCell>
            <Table.HeaderCell>{t("unit").message || "Unit"} €</Table.HeaderCell>
            <Table.HeaderCell>{t("quantity").message || "Quantity"}</Table.HeaderCell>
            <Table.HeaderCell>{t("price").message || "Price"} €</Table.HeaderCell>
            <Table.HeaderCell>&nbsp;</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {elements.map((el, idx) => (
            <Table.Row key={idx}>
              <Table.Cell>{renderInterventionElementType(el.element_type)}</Table.Cell>
              <Table.Cell width={8}>{el.description}</Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell textAlign="right">
                <Label style={{ cursor: "pointer" }} onClick={() => this.handleDeleteSubitem(idx)} basic icon={<FontAwesomeIcon icon={faTrash} color="green" />} />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    );
  };

  render() {
    const { newIntervention, hasInterventionMissingFields } = this.state;
    const { t } = this.props;

    return (
      <div className="AddAppointmentIntervention">
        <Modal open={true} onClose={this.props.onClose} closeOnDimmerClick={false} size="big" className="add-intervention-modal">
          <Modal.Header>
            <div className="add-intervention-icon">
              <FontAwesomeIcon icon={faPlus} color="white" />
            </div>
            <h3>{t("adding_intervention").message || "Adding Intervention"}</h3>
          </Modal.Header>

          <Modal.Content>
            <Form>
              <Form.Group>
                <Form.Field required style={{ flex: "1" }} error={hasInterventionMissingFields && !newIntervention.title}>
                  <label>{t("Title").message || "Title"}</label>
                  <UserInput
                    value={newIntervention.title}
                    name="title"
                    onChange={this.handleNewInterventionChange}
                    placeholder={t("enter_title").message || "Enter Title"}
                  />
                </Form.Field>
                <Form.Field>
                  <label>{t("fixed_price").message || "Fixed Price"}</label>
                  <UserInput
                    value={newIntervention.price}
                    name="price"
                    onChange={this.handleNewInterventionChange}
                    label={{ basic: true, content: "€" }}
                    labelPosition="right"
                  />
                </Form.Field>
                <Form.Field className="add-appointment-intervention-thumb-toggle-container">
                  <label>&nbsp;</label>
                  <ThumbToggle status={newIntervention.customer_ok} onClick={this.handleNewInterventionCustomerOkChange} />
                </Form.Field>
              </Form.Group>
              {!this.props.keyloopEnabled && (
                <Form.Field>
                  <label>{t("additional_information").message || "Additional information"}</label>
                  <TextArea
                    value={newIntervention.description}
                    name="description"
                    onChange={this.handleNewInterventionChange}
                    placeholder={t("additional_information").message || "Additional information"}
                  />
                </Form.Field>
              )}

              <Button icon labelPosition="left" color="green" onClick={this.handleToggleSubitemModal}>
                {t("Subitems").message || "Subitems"}
                <Icon name="plus" color="white" />
              </Button>

              {hasInterventionMissingFields && !newIntervention.elements.length && (
                <Segment className="subitem-required-container">
                  <span>{t("at_least_one_subitem_required").message || "At least one subitem is required."}</span>
                </Segment>
              )}
            </Form>
            {this.props.addInterventionsError && (
              <p style={{ marginTop: "10px", color: "red" }}>{`${this.props.addInterventionsError}. ${
                t("please_contact_support").message || "Please contact support"
              }.`}</p>
            )}

            {this.renderSubitemsTable()}
          </Modal.Content>

          <Modal.Actions>
            <div>
              <Icon name="info circle" color="blue" />
              {t("save_intervention_no_edit_or_remove").message || "Once you save this intervention, you can no longer edit or remove subitems."}
            </div>

            <div>
              <Button disabled={this.props.isAddingNewIntervention} onClick={this.props.onClose}>
                <Icon name="close" /> {t("cancel").message || "Cancel"}
              </Button>
              <Button color="green" onClick={this.handleSubmitNewIntervention} disabled={this.props.isAddingNewIntervention} loading={this.props.isAddingNewIntervention}>
                <Icon name="checkmark" /> {t("save").message || "Save"}
              </Button>
            </div>
          </Modal.Actions>
        </Modal>

        {this.renderSubitemModal()}
      </div>
    );
  }
}

export default withTranslation()(AddAppointmentIntervention);
