import React, { Component } from "react";
import AlertContainer from "react-alert";
import { withTranslation } from "react-i18next";
import { Modal, Button, Form, Icon } from "semantic-ui-react";
import ReactTable, { ReactTableDefaults } from "react-table";
import moment from "moment";
import _ from "lodash";

import CustomConfirm from "../../components/CustomConfirm";

import Service from "./service";

class NewManufacturerRequestModal extends Component {
  state = {
    isLoading: false,
    selectedRow: null,
    isManufacturerRequestAccessModalVisible: false,
    reason: "",
    items: [],
    claim_nr: "",
  };

  handleSearch = (e, data) => {
    this.setState({ isLoading: true, claim_nr: data.value }, () => {
      Service.searchRequests({ claim_nr: data.value })
        .then(response => {
          const items = Array.isArray(response.data?.data?.pins) ? response.data.data.pins : [];
          this.setState({ items, isLoading: false });
        })
        .catch(err => {
          console.log(err);
          this.setState({ isLoading: false });
        });
    });
  };

  handleManufacturerRequestAccess = reason => {
    const { selectedRow } = this.state;

    if (!reason) return;

    this.setState({ isLoading: true }, () => {
      const params = { reason };
      if (selectedRow.intervention_id) params.intervention_id = selectedRow.intervention_id;
      else params.question_result_id = selectedRow.question_result_id;

      Service.requestAccess(params)
        .then(response => {
          this.setState({ isLoading: false }, () => this.props.onNewRequest(selectedRow));
        })
        .catch(err => {
          console.log(err);
          this.setState({ isLoading: false });
        });
    });
  };

  renderManufacturerRequestsTable = () => {
    const { items, isLoading, claim_nr } = this.state;
    const { t, pinTypesOptions, warrantyTypeOptions, pinStatusOptions, dealerNames, locationNames } = this.props;

    if (!items?.length && !claim_nr) return null;

    if (isLoading) {
      return (
        <div className="Table__loading Loader-Placeholder">
          <div className="bounce1"></div>
          <div className="bounce2"></div>
          <div className="bounce3"></div>
          <section>{t("loading").message || "Loading"}</section>
        </div>
      );
    } else {
      return (
        <div className="inner-table-wrapper">
          <ReactTable
            className="ReactTable -floated-table -contained-large"
            data={items || []}
            showPagination={false}
            showPageSizeOptions={false}
            sortable={false}
            resizable={false}
            defaultPageSize={items?.length || 0}
            pageSize={items?.length || 0}
            noDataText={
              <div className="Table__no-results">
                <Icon disabled name="list alternate outline" style={{ fontSize: "1.75em" }} />
                <p>{t("no_results").message || "No results"}</p>
              </div>
            }
            getTdProps={(state, rowInfo, column, instance) => {
              return {
                onClick: e => {
                  this.setState({ selectedRow: rowInfo.original, isManufacturerRequestAccessModalVisible: true });
                },
              };
            }}
            loading={isLoading}
            minRows={0}
            column={{
              ...ReactTableDefaults.column,
              headerClassName: "ReactTable__column-header -text-ellipsis",
              className: "ReactTable__column",
            }}
            columns={[
              {
                id: "regNo",
                Header: t("Reg Nr").message,
                accessor: d => <span className="-cursor-pointer">{d.reg_number}</span>,
              },
              {
                id: "dealer",
                Header: t("dealer").message || "Dealer",
                accessor: d => dealerNames[d.dealer_id],
              },
              {
                id: "location",
                Header: t("location").message || "Location",
                accessor: d => locationNames[d.dealer_location_id],
              },
              {
                Header: t("pin_date").message || "Pin Date",
                id: "pinDate",
                accessor: d =>
                  d.updated_on && (moment.isMoment(d.updated_on) || !d.updated_on.startsWith("0001-01-01T00:00:00")) // might be time zoned
                    ? moment(d.updated_on).format("DD-MM-YYYY")
                    : "",
              },
              {
                Header: t("pin_type").message || "Pin Type",
                id: "pinType",
                accessor: d => t(pinTypesOptions[d.pin_type_id]).message || pinTypesOptions[d.pin_type_id],
              },
              {
                Header: t("warranty_type").message || "Warranty Type",
                id: "warrantyType",
                accessor: d => t(warrantyTypeOptions[d.warranty_type_id]).message || warrantyTypeOptions[d.warranty_type_id],
              },
              {
                id: "pinStatus",
                Header: t("pin_status").message || "Pin Status",
                accessor: d => t(pinStatusOptions[d.pin_status_id]).message || pinStatusOptions[d.pin_status_id],
              },
              {
                id: "support",
                Header: t("support_nr").message || "Support #",
                accessor: d => <span>{d.support_nr}</span>,
              },
              {
                id: "claim",
                Header: t("claim_nr").message || "Claim #",
                accessor: d => <span>{d.claim_nr}</span>,
              },
              {
                id: "reference",
                Header: t("reference_nr").message || "Ref #",
                accessor: d => <span>{d.ref_nr}</span>,
              },
            ]}
          />
        </div>
      );
    }
  };

  render() {
    const { t, onCancel } = this.props;
    const { isLoading, isManufacturerRequestAccessModalVisible } = this.state;

    return (
      <>
        <Modal open onClose={onCancel} size="large" closeOnDimmerClick={false} className="NewManufacturerRequestModal">
          <Modal.Header>{t("request_access").message || "Request access"}</Modal.Header>
          <Modal.Content scrolling>
            <AlertContainer />
            <Form>
              <Form.Group>
                <Form.Field>
                  <Form.Input label={t("search_by_claim_number").message || "Search by claim number"} onChange={_.debounce(this.handleSearch, 300)} />
                </Form.Field>
              </Form.Group>
            </Form>
            {this.renderManufacturerRequestsTable()}
          </Modal.Content>
          <Modal.Actions>
            <Button size="large" onClick={onCancel} color="grey">
              {t("cancel").message || "Cancel"}
            </Button>
          </Modal.Actions>
        </Modal>

        <CustomConfirm
          type="warning"
          isOpen={isManufacturerRequestAccessModalVisible}
          handleConfirm={this.handleManufacturerRequestAccess}
          handleCancel={() => this.setState({ isManufacturerRequestAccessModalVisible: false })}
          confirmMsg={t("reason_for_access").message || "Reason for asking access"}
          confirmMandatoryNote={true}
          isLoading={isLoading}
        />
      </>
    );
  }
}

export default withTranslation()(NewManufacturerRequestModal);
