// @flow
import React, { Component } from "react";
import { SketchPicker } from "react-color";
import { Button, Form, Icon, Label, Modal, Table } from "semantic-ui-react";
import { withTranslation } from "react-i18next";

import Can from "../Can";
import { UserInput } from "../../components";

class BrandDetail extends Component {
  state = {
    brand: {
      ...this.props.brand,
      button_label: !this.props.brand.button_label ? "Service" : this.props.brand.button_label,
    },
    photo: "",
    errorNameText: "",
    errorVinPrefixText: "",
    errorButtonLabelText: "",
    errorServiceButtonTabletURL: "",
  };

  handleChange = event => {
    let { brand } = this.state;
    brand[event.target.name] = event.target.value;
    this.setState({ brand });
  };

  handleNewBrandFileChange = event => {
    if (!event || !event.target || !event.target.files) {
      return;
    }
    this.setState({ photo: event.target.files[0] });
  };

  handleChangeComplete = color => {
    let { brand } = this.state;
    brand.color = color.hex;
    this.setState({ brand });
  };

  validateForm = () => {
    let { brand } = this.state;

    let errorNameText = "";
    let errorVinPrefixText = "";
    let errorServiceboxWoText = "";
    let errorServiceboxVinText = "";
    let errorButtonLabelText = "";
    let errorServiceButtonTabletURL = "";
    let urlRegex = /^https?:\/\/[a-z0-9]+([-.][a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)*$/;

    if (!brand.name || !brand.name.trim()) {
      errorNameText = this.props.t("this_field_is_required").message || "This field is required";
    }
    if (!brand.vin_prefix || !brand.vin_prefix.trim()) {
      errorVinPrefixText = this.props.t("this_field_is_required").message || "This field is required";
    }
    if (brand.servicebox_wo) {
      if (!urlRegex.test(brand.servicebox_wo)) {
        errorServiceboxWoText = this.props.t("enter_valid_url").message || "Enter valid URL";
      }
    }
    if (brand.servicebox_vin) {
      if (!urlRegex.test(brand.servicebox_vin)) {
        errorServiceboxVinText = this.props.t("enter_valid_url").message || "Enter valid URL";
      }
    }
    if (brand.tablet_service_button_url) {
      if (!urlRegex.test(brand.tablet_service_button_url)) {
        errorServiceButtonTabletURL = this.props.t("enter_valid_url").message || "Enter valid URL";
      }
    }

    if (!brand.button_label || !brand.button_label.trim()) {
      errorButtonLabelText = this.props.t("this_field_is_required").message || "This field is required";
    }

    this.setState({ errorVinPrefixText, errorNameText, errorServiceboxWoText, errorServiceboxVinText, errorButtonLabelText, errorServiceButtonTabletURL });
    return errorNameText.length === 0 && errorVinPrefixText.length === 0 && errorServiceboxWoText.length === 0 && errorServiceboxVinText.length === 0;
  };

  handleSave = () => {
    let { brand, photo } = this.state;
    if (this.validateForm()) {
      this.props.onSave(brand, photo);
    }
  };

  render() {
    let { brand, errorNameText, errorVinPrefixText, errorServiceboxWoText, errorServiceboxVinText, errorButtonLabelText, errorServiceButtonTabletURL } = this.state;

    return (
      <div className="BrandDetail">
        <Modal
          open={true}
          onClose={() => {
            this.props.onHideDetail();
          }}
          closeOnDimmerClick={false}
          size="small"
        >
          <Modal.Header>
            {this.props.mode === "create" ? this.props.t("add_new_brand").message || "Add new brand" : this.props.t("edit_brand").message || "Edit brand"}
          </Modal.Header>

          <Modal.Content scrolling>
            <Form>
              <Form.Field>
                <label>{this.props.t("brand").message || "Brands"}</label>
                <UserInput type="text" name="name" onChange={this.handleChange} placeholder={this.props.t("Brand").message || "Brand"} value={brand.name} />
                {errorNameText && (
                  <Label basic color="red" pointing>
                    {errorNameText}
                  </Label>
                )}
              </Form.Field>

              <Form.Field>
                <label>{this.props.t("vin_nr").message || "Vin #"}</label>
                <UserInput type="text" name="vin_prefix" onChange={this.handleChange} value={brand.vin_prefix} placeholder={this.props.t("vin_nr").message || "Vin #"} />
                {errorVinPrefixText && (
                  <Label basic color="red" pointing>
                    {errorVinPrefixText}
                  </Label>
                )}
              </Form.Field>
              <Form.Field>
                <label>{this.props.t("button_label").message || "Button label"}</label>
                <UserInput
                  type="text"
                  name="button_label"
                  onChange={this.handleChange}
                  value={brand.button_label}
                  placeholder={this.props.t("button_label").message || "Button label"}
                />
                {errorButtonLabelText && (
                  <Label basic color="red" pointing>
                    {errorButtonLabelText}
                  </Label>
                )}
              </Form.Field>
              <Form.Field>
                <Table celled>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell content="{{.Appointment.VIN}}" />
                      <Table.Cell content="{{.Appointment.WONr}}" />
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell content="{{.Appointment.CurrentKM}}" />
                      <Table.Cell content="{{.Appointment.NextKM}}" />
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell content="{{.JobDate}}" />
                      <Table.Cell />
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Form.Field>
              <Form.Field>
                <label>{this.props.t("tablet_service_button_url").message || "Tablet Service button URL"}</label>
                <UserInput
                  type="text"
                  name="tablet_service_button_url"
                  onChange={this.handleChange}
                  value={brand.tablet_service_button_url}
                  placeholder={this.props.t("tablet_service_button_url").message || "Tablet Service button URL"}
                />
                {errorServiceButtonTabletURL && (
                  <Label basic color="red" pointing>
                    {errorServiceButtonTabletURL}
                  </Label>
                )}
              </Form.Field>
              <Form.Field>
                <label>{this.props.t("appointment_service_button_url").message || "Appointment service button URL"}</label>
                <UserInput
                  type="text"
                  name="servicebox_wo"
                  onChange={this.handleChange}
                  value={brand.servicebox_wo}
                  placeholder={this.props.t("appointment_service_button_url").message || "Appointment service button URL"}
                />
                {errorServiceboxWoText && (
                  <Label basic color="red" pointing>
                    {errorServiceboxWoText}
                  </Label>
                )}
              </Form.Field>
              <Form.Field>
                <label>{this.props.t("car_detail_service_button_url").message || "Car detail service button URL"}</label>
                <UserInput
                  type="text"
                  name="servicebox_vin"
                  onChange={this.handleChange}
                  value={brand.servicebox_vin}
                  placeholder={this.props.t("car_detail_service_button_url").message || "Car detail service button URL"}
                />
                {errorServiceboxVinText && (
                  <Label basic color="red" pointing>
                    {errorServiceboxVinText}
                  </Label>
                )}
              </Form.Field>
              <Can I="upload" the="files">
                <Form.Field>
                  {brand && brand.logo && <img src={brand.logo} className="logo" alt="logo" />}
                  <input id="newBrandFile" className="newBrandFileUploader" type="file" onChange={this.handleNewBrandFileChange} />
                </Form.Field>
              </Can>
              <Form.Field>
                <SketchPicker color={brand.color} onChangeComplete={this.handleChangeComplete} />
              </Form.Field>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button
              color="red"
              disabled={this.props.loading}
              onClick={() => {
                this.props.onHideDetail();
              }}
              floated="left"
            >
              <Icon name="close" /> {this.props.t("discard").message || "DISCARD"}
            </Button>
            <Button color="green" onClick={this.handleSave} loading={this.props.loading} disabled={this.props.loading}>
              <Icon name="checkmark" /> {this.props.t("save").message || "SAVE"}
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

export default withTranslation()(BrandDetail);
