import { Component } from "react";
import PropTypes from "prop-types";

import ENV from "../../util/env-config";

const WS_URL = `${ENV.webSocketEndpoint}/connect?client_id=`;
const SUBSCRIBE = 1;
const UNSUBSCRIBE = 2;
const PING_INTERVAL = 5000;
const USER_ONLINE = 1;
const USER_AWAY = 2;
const USER_UNKNOWN = 3;

export default class Raket extends Component {
  state = {
    WS: null,
    reconnectTimeoutInterval: 500,
    reconnectTimeoutID: null,
    appUsers: [],
    appIntervalID: null,
    pingMessage: "",
  };

  statusColors = this.props.statusColors || {
    closed: "grey",
    open: "green",
    error: "red",
  };

  componentDidMount() {
    this.setupNetworkListeners();
    this.setup();
  }

  componentWillUnmount() {
    this.removeNetworkListeners();
    this.close();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.user?.id !== this.props.user?.id) {
      if (!this.props.user?.id) this.close();
      else this.setup();
    } else if (prevProps.location_key !== this.props.location_key) {
      if (!prevProps.location_key) this.setup();
      else {
        this.unsubscribeLocation(prevProps.location_key);

        if (this.props.location_key) this.subscribeLocation();
        else this.close();
      }
    } else if (this.props.authToken && prevProps.authToken !== this.props.authToken) this.setup();

    if (prevProps.appointment_id !== this.props.appointment_id) {
      if (prevProps.appointment_id) this.unsubscribeAppointment(prevProps.appointment_id);

      if (this.props.appointment_id) this.subscribeAppointment();
    }
  }

  setupNetworkListeners = () => {
    window.addEventListener("online", this.updateOnlineStatus);
    window.addEventListener("offline", this.updateOnlineStatus);
  };

  removeNetworkListeners = () => {
    window.removeEventListener("online", this.updateOnlineStatus);
    window.removeEventListener("offline", this.updateOnlineStatus);
  };

  updateOnlineStatus = e => {
    if (navigator.onLine) this.setup();
    else this.close();
  };

  log = message => {
    if (ENV.envName === "dev") {
      console.log(`Raket | at :  ${new Date().toLocaleTimeString()} | ${JSON.stringify(message)}`);
    }
  };

  setup = () => {
    if (!this.props.user?.id) return;

    if (!this.props.location_key) return;

    this.log("Setting up");

    let { WS } = this.state;
    if (WS) WS.onclose = () => {};

    try {
      WS = new WebSocket(WS_URL + this.props.user.id);
    } catch (err) {
      // happens to IE when we try to recreate WS multiple in a row, when changing location or dealer
      console.log("Error in Racket when creating WS, will retry later:");
      setTimeout(this.setup, 1000);
      return;
    }

    this.setState({ WS }, () => {
      this.setupLifecycle();
    });
  };

  setupLifecycle = () => {
    let WS = this.state.WS;

    WS.onopen = () => {
      this.log("WebSocket opened");
      this.props.onWebSocketConnectionUpdated({ type: "connected" });
      if (this.props.location_key) this.subscribeLocation();
      else {
        this.close();
        return;
      }

      if (this.props.appointment_id) this.subscribeAppointment();
    };

    WS.onclose = closer => {
      this.log(`WebSocket closed (reason: ${JSON.stringify(closer.reason)}) (code: ${JSON.stringify(closer.code)})`);
      this.props.onWebSocketConnectionUpdated({ type: "disconnected" });

      if (this.props.location_key) {
        this.log("WebSocket trying to reconnect");
        this.tryToReconnect();
      }
    };

    WS.onmessage = message => {
      this.log(`WebSocket got message : ${JSON.stringify(message)}`);

      let body = null;
      try {
        body = JSON.parse(message.data);

        if (!body || !body._topic) {
          this.log("Claire Websocket - Invalid message", message);
          return;
        }
      } catch (err) {
        this.log(`Failed to parse message`);
        return;
      }

      const { _topic } = body;
      switch (_topic) {
        case "appointment_detail_users":
          this.updateAppUsers(body);
          break;

        case "UserUpdateMessage":
          this.props.onUserUpdated(body);
          break;

        case "ManufacturerRequestCreatedMessage":
        case "ManufacturerRequestUpdatedMessage":
          this.props.onUpdateManufacturerRequestsCount(body);
          break;

        case "KeyLockerCallForHelpMessage":
        case "KeyLockerCallForHelpHandledMessage":
          this.props.onKeyLockerCallForHelp(body);
          break;

        case "CarUpdateMessage":
          this.props.onCarUpdated(body);
          this.props.onAppointmentUpdated(true, { type: "message", payload: message });
          break;

        case "CarAttachmentAddedMessage":
        case "CarAttachmentRemovedMessage":
          this.props.onCarUpdated(body);
          break;

        case "DMSCustomQueryUpdate":
          this.props.onQueryResolved(body);
          return;

        case "PinUpdateMessage":
        case "PinDeleteMessage":
        case "AppointmentUpdatedMessage":
        case "QuestionResultUpdateMessage":
        case "InterventionUpdatedMessage":
          this.props.onWarrantyUpdated(body);
          this.props.onAppointmentUpdated(true, { type: "message", payload: message });
          break;

        case "ShareboxLogMessage":
          this.props.onWebsocketShareboxUpdate(body);
          break;

        case "PinStatusNotificationMessage":
          this.props.onWebsocketWarrantyNotification(body);
          break;

        case "AppointmentAssignmentUpdatedMessage":
          this.props.onWebSocketAppointmentAssignment(body);
          break;

        case "MechanicAvailabilityUpdatedMessage":
          this.props.onWebSocketMechanicAvailability(body);
          break;

        case "RefreshAppointmentsListMessage":
          this.props.onAppointmentsRefresh(true, { type: "message", payload: message });
          break;

        default:
          this.props.onAppointmentUpdated(true, { type: "message", payload: message });
      }
    };

    WS.onerror = error => {
      this.log(`WebSocket has error : ${JSON.stringify(error)}`);
    };
  };

  removeTimeout = () => {
    let { reconnectTimeoutID } = this.state;

    if (reconnectTimeoutID) {
      clearTimeout(reconnectTimeoutID);
      reconnectTimeoutID = null;
    }

    this.setState({ reconnectTimeoutInterval: 500, reconnectTimeoutID });
  };

  close = () => {
    this.removeTimeout();

    let WS = this.state.WS;
    if (WS) {
      this.setState({ WS: null }, () => {
        WS.onclose = () => {};
        WS.close();
        this.log(`WebSocket closed`);
        this.props.onWebSocketConnectionUpdated({ type: "disconnected" });
      });
    }
  };

  subscribeLocation = () => {
    const { WS } = this.state;
    if (!WS || WS.readyState !== WebSocket.OPEN) return;

    const queues = ("location-" + this.props.location_key).split(",").join(",location-");
    const message = {
      _type: SUBSCRIBE,
      _queues: queues,
    };

    WS.send(JSON.stringify(message));
  };

  unsubscribeLocation = location_key => {
    const { WS } = this.state;
    if (!WS || WS.readyState !== WebSocket.OPEN) return;

    const queues = ("location-" + location_key).split(",").join(",location-");
    const message = {
      _type: UNSUBSCRIBE,
      _queues: queues,
    };

    WS.send(JSON.stringify(message));
  };

  subscribeAppointment = () => {
    const { WS } = this.state;
    const { user, appointment_id } = this.props;
    const queues = "appointment-" + appointment_id;

    if (WS && WS.readyState === WebSocket.OPEN) {
      let message = {
        _type: SUBSCRIBE,
        _queues: queues,
      };

      WS.send(JSON.stringify(message));

      message = {
        _queues: "appointment-" + appointment_id,
        _topic: "appointment_detail_users",
        id: user.id,
        first_name: user.first_name,
        last_name: user.last_name,
        profile_picture: user.profile_picture,
      };

      this.setState({ pingMessage: JSON.stringify(message) }, () => this.ping());
    }
  };

  unsubscribeAppointment = appointment_id => {
    const { user, onAppDetailUsersUpdate } = this.props;
    const { WS, appUsers, appIntervalID } = this.state;
    const queues = "appointment-" + appointment_id;

    if (WS && user && WS.readyState === WebSocket.OPEN) {
      let message = {
        _type: UNSUBSCRIBE,
        _queues: queues,
      };

      WS.send(JSON.stringify(message));

      message = {
        _queues: queues,
        _topic: "appointment_detail_users",
        id: user.id,
        leave: true,
      };

      WS.send(JSON.stringify(message));
    }

    clearInterval(appIntervalID);
    appUsers.forEach(user => clearTimeout(user.timeout));
    this.setState({ appIntervalID: null, appUsers: [] });
    onAppDetailUsersUpdate([]);
  };

  ping = () => {
    const { WS, pingMessage } = this.state;
    if (WS && WS.readyState === WebSocket.OPEN) WS.send(pingMessage);
  };

  updateAppUsers = message => {
    if (!this.props.appointment_id) return;

    let { appUsers, appIntervalID } = this.state;
    let user = appUsers.find(u => u.id === message.id);

    if (message.leave) {
      if (user) {
        clearTimeout(user.timeout);
        appUsers = appUsers.filter(u => u.id !== user.id);
        if (appUsers.length < 1) {
          clearInterval(appIntervalID);
          appIntervalID = null;
        }

        this.setState({ appUsers, appIntervalID });
      }
    } else if (message.id) {
      if (user) {
        clearTimeout(user.timeout);
        user.status = USER_ONLINE;
        user.timeout = setTimeout(this.appUserTimeout, PING_INTERVAL + 1000, user.id);
        this.setState({ appUsers });
      } else {
        let { appIntervalID } = this.state;
        if (appUsers.length < 1) {
          appIntervalID = setInterval(this.ping, PING_INTERVAL);
        }

        let user = {
          id: message.id,
          first_name: message.first_name,
          last_name: message.last_name,
          profile_picture: message.profile_picture,
          status: USER_ONLINE,
        };

        this.ping();
        user.timeout = setTimeout(this.appUserTimeout, PING_INTERVAL + 1000, user.id);
        appUsers.push(user);
        this.setState({ appUsers, appIntervalID });
      }
    }

    this.props.onAppDetailUsersUpdate(appUsers);
  };

  appUserTimeout = user_id => {
    let { appUsers } = this.state;
    let user = appUsers.find(u => u.id === user_id);
    if (user) {
      if (user.status === USER_ONLINE) user.status = USER_AWAY;
      else if (user.status === USER_AWAY) user.status = USER_UNKNOWN;

      user.timeout = setTimeout(this.appUserTimeout, PING_INTERVAL + 1000, user.id);
      this.setState({ appUsers });
      this.props.onAppDetailUsersUpdate(appUsers);
    }
  };

  tryToReconnect = () => {
    let { reconnectTimeoutInterval } = this.state;

    if (reconnectTimeoutInterval < 8000) reconnectTimeoutInterval *= 2;

    this.log(`Reopening the connection in ${reconnectTimeoutInterval / 1000} seconds ...`);

    const reconnectTimeoutID = setTimeout(() => {
      this.setup();
    }, reconnectTimeoutInterval);

    this.setState({ reconnectTimeoutID });
  };

  render() {
    return null;
  }
}

Raket.defaultProps = {
  className: "",
  shouldLog: false,
  showIndicator: false,
  statusColors: null,
  style: null,
};

Raket.propTypes = {
  url: PropTypes.string,
  location_key: PropTypes.any,
  shouldLog: PropTypes.bool,
  onAppointmentUpdated: PropTypes.func.isRequired,
};
