import React, { Component } from "react";
import ReactTable, { ReactTableDefaults } from "react-table";
import { connect } from "react-redux";
import { Icon, Grid, Modal, Form, Button } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import AlertContainer from "react-alert";

import { SubHeader, CustomConfirm, UserInput } from "../../components";
import { setAlert } from "../App/store";
import Service from "./service";
import DefaultTemplate from "./DefaultTemplate";
import SmsTemplate from "./SmsTemplate";
import { SMS_GATEWAYS } from "../../util/common";
import { COMMUNICATION_EVENTS, CHANNELS, KEYLOCKER_COMMUNICATION_EVENTS } from "../Appointments/common";

const defaultBrandModelTemplate = {
  car_brand: "",
  car_model: "",
};

class CustomerCommMediums extends Component {
  state = {
    isLoading: true,
    mediums: [],
    defaultTemplates: [],
    isVisibleMedium: false,
    selectedMedium: null,
    customComDetail: {},
    isSubmit: false,
    isBrandModalVisible: false,
    brandModelTemplate: { ...defaultBrandModelTemplate },
    isDuplicatingMedium: false,
    hasValidationError: false,
    showDeleteConfirmation: false,
    isDeleting: false,
    errorMessage: null,
  };

  alertRef = React.createRef();

  componentDidMount() {
    this.getMediums();
    this.getDefaultTemplates();
    this.getDetail(this.props.globalState.selectedLocation.id);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.globalState.selectedLocation.id !== nextProps.globalState.selectedLocation.id) {
      this.getMediums(nextProps.globalState.selectedLocation.id);
      this.getDetail(nextProps.globalState.selectedLocation.id);
    }
  }

  getDetail = dealer_location_id => {
    this.setState({ isLoading: true }, async () => {
      try {
        const response = await Service.getDetail(dealer_location_id);

        this.setState({ customComDetail: response?.data?.data?.settings || {}, isLoading: false });
      } catch (err) {
        console.error("Failed to get customcom detail object", err);
        this.setState({ isLoading: false });
        this.props.setAlert({
          type: "error",
          title: err?.response?.data?.errors?.length ? err.response.data.errors[0] : "Failed to get customcom detail object",
        });
      }
    });
  };

  getCommStatus = (event_type, keylocker_communication_event_type, channel) => {
    const { is_sms_for_online_check_in_enabled, send_confirmation_emails } = this.state.customComDetail;
    const { online_check_in_enabled, diagnose_overview_enabled, repair_overview_enabled, is_keylocker_enabled, third_party_send_communications } =
      this.props.globalState.selectedLocation;
    const { sms_gateway_id } = this.props.globalState.selectedDealer;

    const [active, notActive] = [<Icon name="eye" color="green" />, <Icon name="eye slash" color="grey" />];

    if (
      third_party_send_communications &&
      (channel === CHANNELS.SMS ||
        ![
          COMMUNICATION_EVENTS.DIAGNOSE_OVERVIEW_SENT,
          COMMUNICATION_EVENTS.DIAGNOSE_OVERVIEW_RESENT,
          COMMUNICATION_EVENTS.REPAIR_OVERVIEW_SENT,
          COMMUNICATION_EVENTS.REPAIR_OVERVIEW_RESENT,
        ].includes(event_type))
    )
      return notActive;

    if ([COMMUNICATION_EVENTS.ONLINE_CHECKIN_SENT, COMMUNICATION_EVENTS.ONLINE_CHECKIN_ANSWERED].includes(event_type) && online_check_in_enabled) {
      if (
        (channel === CHANNELS.SMS && (!is_sms_for_online_check_in_enabled || sms_gateway_id === SMS_GATEWAYS.DISABLED)) ||
        (event_type === COMMUNICATION_EVENTS.ONLINE_CHECKIN_ANSWERED && !send_confirmation_emails)
      )
        return notActive;

      return active;
    }

    if (
      [COMMUNICATION_EVENTS.DIAGNOSE_OVERVIEW_SENT, COMMUNICATION_EVENTS.DIAGNOSE_OVERVIEW_RESENT, COMMUNICATION_EVENTS.DIAGNOSE_OVERVIEW_ANSWERED].includes(
        event_type
      ) &&
      diagnose_overview_enabled
    ) {
      if (
        (event_type === COMMUNICATION_EVENTS.DIAGNOSE_OVERVIEW_ANSWERED && !send_confirmation_emails) ||
        (channel === CHANNELS.SMS && sms_gateway_id === SMS_GATEWAYS.DISABLED)
      )
        return notActive;

      return active;
    }

    if ([COMMUNICATION_EVENTS.REPAIR_OVERVIEW_SENT, COMMUNICATION_EVENTS.REPAIR_OVERVIEW_RESENT].includes(event_type) && repair_overview_enabled) {
      if (channel === CHANNELS.SMS && sms_gateway_id === SMS_GATEWAYS.DISABLED) return notActive;

      return active;
    }

    if (
      [KEYLOCKER_COMMUNICATION_EVENTS.DROP_PIN_SENT, KEYLOCKER_COMMUNICATION_EVENTS.PICK_UP_PIN_SENT].includes(keylocker_communication_event_type) &&
      is_keylocker_enabled
    )
      return active;

    return notActive;
  };

  getDefaultTemplates = () => {
    Service.getDefaultTemplates()
      .then(result => {
        if (result?.data?.data?.communication_mediums) this.setState({ defaultTemplates: result.data.data.communication_mediums });
      })
      .catch(err => {
        err?.response?.data?.errors?.length &&
          this.props.setAlert({
            type: "error",
            title: err.response.data.errors[0],
          });
      });
  };

  getMediums = location_id => {
    this.setState({ isLoading: true }, () => {
      Service.getMediums({ dealer_location_id: location_id || this.props.globalState.selectedLocation.id })
        .then(result => {
          let mediums = [];
          if (result?.data?.data?.communication_mediums) mediums = result.data.data.communication_mediums;

          mediums.sort((a, b) => (a.event_type > b.event_type ? 1 : a.event_type < b.event_type ? -1 : a.channel > b.channel ? 1 : a.channel < b.channel ? -1 : 0));
          this.setState({ mediums, isLoading: false });
        })
        .catch(err => {
          err?.response?.data?.errors?.length &&
            this.props.setAlert({
              type: "error",
              title: err.response.data.errors[0],
            });

          this.setState({ isLoading: false });
        });
    });
  };

  handleMediumRowClick = selectedMedium => {
    this.setState({ selectedMedium, isVisibleMedium: true, isSubmit: false });
  };

  handleEmailMediumUpdate = mediumUpdate => {
    const { selectedMedium } = this.state;
    selectedMedium.subject = mediumUpdate.subjectTitle;
    selectedMedium.message = mediumUpdate.templateBody;

    if (selectedMedium.car_brand) {
      selectedMedium.car_brand = mediumUpdate.carBrand;
      selectedMedium.car_model = mediumUpdate.carModel;
    }

    this.setState({ selectedMedium }, () => this.handleSubmitMediums());
  };

  handleSMSMediumUpdate = mediumUpdate => {
    const { selectedMedium } = this.state;
    selectedMedium.message = mediumUpdate.smsText;

    if (selectedMedium.car_brand) {
      selectedMedium.car_brand = mediumUpdate.carBrand;
      selectedMedium.car_model = mediumUpdate.carModel;
    }

    this.setState({ selectedMedium }, () => this.handleSubmitMediums());
  };

  handleCloseMedium = () => {
    this.setState({ isVisibleMedium: false });
  };

  handleSubmitMediums = () => {
    const { selectedMedium } = this.state;

    this.setState({ isSubmit: true }, () => {
      Service.updateMedium(selectedMedium)
        .then(() => {
          this.setState({ selectedMedium: null, isSubmit: false, isVisibleMedium: false });
        })
        .catch(err => {
          this.setState({ isSubmit: false }, () => {
            err?.response?.data?.errors?.length && this.alertRef.show(err.response.data.errors[0], { type: "error" });
          });
        });
    });
  };

  toggleDeleteConfirmation = () => this.setState(state => ({ showDeleteConfirmation: !state.showDeleteConfirmation }));

  renderDeleteConfirmation = () => {
    const { showDeleteConfirmation, isDeleting, errorMessage, selectedMedium } = this.state;
    const { t } = this.props;

    return (
      <CustomConfirm
        type="danger"
        isOpen={showDeleteConfirmation}
        handleConfirm={() => this.handleDeleteDuplicateMedium(selectedMedium.communication_medium_id)}
        handleCancel={this.toggleDeleteConfirmation}
        confirmMsg={t("confirm_delete_message").message || "Are you sure that you want to delete this? You can't undo this action."}
        isLoading={isDeleting}
        error={errorMessage}
      />
    );
  };

  handleDeleteDuplicateMedium = communication_medium_id => {
    this.setState({ isDeleting: true, errorMsg: "" }, async () => {
      try {
        await Service.deleteDuplicateMedium({ communication_medium_id });
        this.setState({ isDeleting: false, showDeleteConfirmation: false, isVisibleMedium: false }, () => {
          this.getMediums();
        });
      } catch (error) {
        const errorMessage =
          typeof error.response?.data === "string"
            ? error.response.data
            : error.response.data.errors
            ? error.response.data.errors[0]
            : "Failed to delete duplicate medium";
        this.setState({ isDeleting: false, errorMessage });
      }
    });
  };

  stripHtml = html => {
    let tmpDivElement = document.createElement("div");
    tmpDivElement.innerHTML = html;

    return tmpDivElement.textContent || tmpDivElement.innerText || "";
  };

  getCommType = (event_type, keylocker_communication_event_type) => {
    const { t } = this.props;

    switch (event_type) {
      case COMMUNICATION_EVENTS.ONLINE_CHECKIN_SENT:
        return t("online_check_in").message || "Online check-in";

      case COMMUNICATION_EVENTS.ONLINE_CHECKIN_ANSWERED:
        return t("online_check_in_confirmation").message || "Online check-in confirmation";

      case COMMUNICATION_EVENTS.DIAGNOSE_OVERVIEW_SENT:
        return t("new_diagnose_overview").message || "New diagnose overview";

      case COMMUNICATION_EVENTS.DIAGNOSE_OVERVIEW_ANSWERED:
        return t("diagnose_overview_confirmation").message || "Diagnose overview confirmation";

      case COMMUNICATION_EVENTS.DIAGNOSE_OVERVIEW_RESENT:
        return t("updated_diagnose_overview").message || "Updated diagnose overview";

      case COMMUNICATION_EVENTS.REPAIR_OVERVIEW_SENT:
        return t("new_repair_overview").message || "New repair overview";

      case COMMUNICATION_EVENTS.REPAIR_OVERVIEW_RESENT:
        return t("updated_repair_overview").message || "Updated repair overview";

      default:
        if (keylocker_communication_event_type === KEYLOCKER_COMMUNICATION_EVENTS.DROP_PIN_SENT) return t("keylocker_check_in").message || "Keylocker check-in";
        else if (keylocker_communication_event_type === KEYLOCKER_COMMUNICATION_EVENTS.PICK_UP_PIN_SENT)
          return t("keylocker_key_dropped_back").message || "Keylocker key dropped back";

        return "";
    }
  };

  getChannel = channel => {
    const { t } = this.props;

    switch (channel) {
      case CHANNELS.EMAIL:
        return t("email").message || "Email";

      case CHANNELS.SMS:
        return t("sms").message || "SMS";

      default:
        return "";
    }
  };

  handleOpenBrandModal = template => {
    this.setState({ brandModelTemplate: { ...this.state.brandModelTemplate, ...template }, isBrandModalVisible: true });
  };

  handleCloseBrandModal = () => {
    this.setState({ brandModelTemplate: { ...defaultBrandModelTemplate }, isBrandModalVisible: false, hasValidationError: false });
  };

  handleInputChange = (_e, data) => {
    this.setState(({ brandModelTemplate }) => ({ brandModelTemplate: { ...brandModelTemplate, [data.name]: data.value } }));
  };

  handleDuplicateMedium = () => {
    const { car_brand, car_model, communication_medium_id } = this.state.brandModelTemplate;

    if (!car_brand) {
      this.setState({ hasValidationError: true });
      return;
    }

    const requestData = {
      communication_medium_id,
      car_brand: car_brand
        .split(",")
        .map(s => s.trim())
        .join(","),
      car_model: car_model
        .split(",")
        .map(s => s.trim())
        .join(","),
    };

    this.setState({ isDuplicatingMedium: true }, async () => {
      try {
        await Service.duplicateMedium(requestData);
        this.setState({ brandModelTemplate: defaultBrandModelTemplate, isBrandModalVisible: false, isDuplicatingMedium: false }, () => {
          this.getMediums();
        });
      } catch (error) {
        const errorMsg =
          typeof error.response?.data === "string" ? error.response.data : error.response.data.errors ? error.response.data.errors[0] : "Failed to create a new template";
        this.modalAlert.show(errorMsg, { type: "error" });
        this.setState({ isDuplicatingMedium: false });
        console.error(error);
      }
    });
  };

  renderBrandModal = () => {
    const { t } = this.props;
    const { isBrandModalVisible, brandModelTemplate, hasValidationError, isDuplicatingMedium } = this.state;

    return (
      <Modal open={isBrandModalVisible} onClose={this.handleCloseCCModal}>
        <Modal.Header>{t("duplicate_template").message || "Duplicate Template"}</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field>
              <label>{t("communication_type").message || "Communication Type"}</label>
              <UserInput disabled value={this.getCommType(brandModelTemplate.event_type, brandModelTemplate.keylocker_communication_event_type)} />
            </Form.Field>

            <Form.Field>
              <label>{t("channel").message || "Channel"}</label>
              <UserInput disabled value={this.getChannel(brandModelTemplate.channel)} />
            </Form.Field>

            <Form.Field required error={!brandModelTemplate.car_brand && hasValidationError}>
              <label>{t("brand").message || "Brand"}</label>
              <UserInput value={brandModelTemplate.car_brand} name="car_brand" onChange={this.handleInputChange} />
            </Form.Field>

            <Form.Field>
              <label>{t("model").message || "Model"}</label>
              <UserInput value={brandModelTemplate.car_model} name="car_model" onChange={this.handleInputChange} />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button disabled={isDuplicatingMedium} onClick={this.handleCloseBrandModal}>
            {t("cancel").message || "Cancel"}
          </Button>
          <Button loading={isDuplicatingMedium} disabled={isDuplicatingMedium} color="green" onClick={this.handleDuplicateMedium}>
            {t("create").message || "Create"}
          </Button>
        </Modal.Actions>
        {this.renderModalAlert()}
      </Modal>
    );
  };

  renderTable = () => {
    const { mediums, isLoading } = this.state;
    const { t } = this.props;

    if (isLoading) {
      return (
        <div className="Table__loading Loader-Placeholder">
          <div className="bounce1"></div>
          <div className="bounce2"></div>
          <div className="bounce3"></div>
          <section>{t("loading_mediums").message || "Loading Mediums"}</section>
        </div>
      );
    } else {
      return (
        <div className="MediumsTable">
          <ReactTable
            className="ReactTable -floated-table -contained-large"
            data={mediums}
            showPagination={false}
            showPageSizeOptions={false}
            sortable={false}
            resizable={false}
            defaultPageSize={mediums.length}
            pageSize={mediums.length}
            noDataText={
              <div className="Table__no-results">
                <Icon disabled name="file alternate" style={{ fontSize: "1.75em", marginTop: "250px" }} />
                <p>{t("no_mediums").message || "No mediums"}</p>
              </div>
            }
            getTdProps={(state, rowInfo, column, instance) => {
              return {
                onClick: e => {
                  this.handleMediumRowClick(rowInfo.original);
                },
              };
            }}
            column={{
              ...ReactTableDefaults.column,
              headerClassName: "ReactTable__column-header",
              className: "ReactTable__column",
            }}
            columns={[
              {
                id: "event_type",
                Header: t("comm_type").message || "Communication type",
                accessor: d => (
                  <span className="comm_type">
                    {this.getCommStatus(d.event_type, d.keylocker_communication_event_type, d.channel)}
                    {this.getCommType(d.event_type, d.keylocker_communication_event_type)}
                  </span>
                ),
                maxWidth: 300,
              },
              {
                id: "channel",
                Header: t("channel").message || "Channel",
                accessor: d => this.getChannel(d.channel),
                maxWidth: 130,
              },
              {
                id: "brand",
                Header: t("brand").message || "brand",
                accessor: "car_brand",
                maxWidth: 130,
              },
              {
                id: "model",
                Header: t("model").message || "Model",
                accessor: "car_model",
                maxWidth: 130,
              },
              {
                id: "subject",
                Header: t("Subject").message || "Subject",
                accessor: "subject",
                maxWidth: 300,
              },

              {
                id: "message",
                Header: t("Message").message || "Message",
                accessor: d => this.stripHtml(d.message),
              },
              {
                id: "action",
                Header: "",
                maxWidth: 80,
                accessor: d => {
                  return (
                    <div className="-pull-right">
                      <Icon
                        color="green"
                        name="plus circle"
                        size="large"
                        onClick={e => {
                          e.stopPropagation();
                          this.handleOpenBrandModal(d);
                        }}
                      />
                    </div>
                  );
                },
              },
            ]}
          />
        </div>
      );
    }
  };

  renderAlert = () => {
    const props = {
      offset: 20,
      position: "top right",
      theme: "light",
      time: 2000,
      transition: "fade",
    };

    return <AlertContainer ref={a => (this.alertRef = a)} {...props} />;
  };

  renderModalAlert = () => {
    const props = {
      offset: 20,
      position: "top right",
      theme: "light",
      time: 2000,
      transition: "fade",
    };

    return <AlertContainer ref={a => (this.modalAlert = a)} {...props} />;
  };

  render() {
    const { id, language_code } = this.props.globalState.selectedLocation;
    const { selectedMedium, customComDetail, defaultTemplates, isSubmit, isVisibleMedium } = this.state;
    return (
      <div className="Mediums">
        <SubHeader>
          <Grid.Column width={16}>
            <h1>{this.props.t("Templates").message || "Templates"}</h1>
          </Grid.Column>
        </SubHeader>

        <Grid className="-contained-large">
          <Grid.Column width={16}>{this.renderTable()}</Grid.Column>
          {isVisibleMedium && selectedMedium.channel === 1 && (
            <DefaultTemplate
              location_id={id}
              language_code={language_code}
              defaultTemplates={defaultTemplates}
              handleEmailMediumUpdate={this.handleEmailMediumUpdate}
              handleCloseMedium={this.handleCloseMedium}
              renderAlert={this.renderAlert}
              isSubmit={isSubmit}
              selectedMedium={selectedMedium}
              toggleDeleteConfirmation={this.toggleDeleteConfirmation}
              renderDeleteConfirmation={this.renderDeleteConfirmation}
              settings={customComDetail}
            />
          )}
          {isVisibleMedium && selectedMedium.channel === 2 && (
            <SmsTemplate
              language_code={language_code}
              defaultTemplates={defaultTemplates}
              handleSMSMediumUpdate={this.handleSMSMediumUpdate}
              handleCloseMedium={this.handleCloseMedium}
              renderAlert={this.renderAlert}
              isSubmit={isSubmit}
              selectedMedium={selectedMedium}
              toggleDeleteConfirmation={this.toggleDeleteConfirmation}
              renderDeleteConfirmation={this.renderDeleteConfirmation}
              settings={customComDetail}
            />
          )}
        </Grid>
        {this.renderBrandModal()}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { globalState: state.global };
};

const mapDispatchToProps = dispatch => {
  return {
    setAlert: alertOptions => dispatch(setAlert(alertOptions)),
  };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(CustomerCommMediums));
