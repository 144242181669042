import axios from "axios";

import ENV from "../../util/env-config";
import { getSessionCookie } from "../../util/common";

class Service {
  static requestConfig() {
    return {
      headers: {
        Authorization: `Bearer ${getSessionCookie()}`,
        Pragma: "no-cache",
      },
    };
  }

  static getDevices(requestData) {
    return axios.post(ENV.systemBase + "/devices/list", requestData, this.requestConfig());
  }

  static updateLease(requestData) {
    return axios.post(ENV.systemBase + "/devices/lease/update", requestData, this.requestConfig());
  }

  static getExportData(requestData) {
    return axios.post(ENV.systemBase + "/devices/export", requestData, this.requestConfig());
  }

  static getModels() {
    return axios.get(ENV.systemBase + "/devices/models/list", this.requestConfig());
  }

  static getBrands() {
    return axios.get(ENV.systemBase + "/devices/brands/list", this.requestConfig());
  }

  static getOsVersions() {
    return axios.get(ENV.systemBase + "/devices/os_versions/list", this.requestConfig());
  }
}

export default Service;
