// @flow

import React, { PureComponent } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Grid, Icon, TextArea, Modal, Table, Label } from "semantic-ui-react";
import moment from "moment";

import { SubHeader, CustomConfirm, UserInput } from "../../components";
import AppService from "./../App/service";
import { setAlert } from "../App/store";
import { copyToClipboard } from "../../util/common";
import { Can } from "..";
import Service from "./service";

import "./PublicAPI.css";

const PUBLIC_ACCOUNT_STATUSES = {
  ACTIVE: 1,
  REVOKED: 2,
};

const PUBLIC_ACCOUNT_ACTIONS = {
  SHOW_KEY: 1,
  REVOKE_KEY: 2,
};

class PublicAPI extends PureComponent {
  state = {
    isLoading: false,
    deactivateAccID: "",
    dealer: {
      accepted_api_agreement: true,
      version_id: "",
    },
    hasPublicAccountError: false,
    isConfirmDeactivateVisible: false,
    isCreateVisible: false,
    newAccName: "",
    contactName: "",
    contactEmail: "",
    contactPhone: "",
    purposeForApiKey: "",
    isNewAccError: false,
    publicAccounts: [],
    selectedPubAccount: null,
    isCreatingApiKey: false,
    isDeactivatingApiKey: false,
    isConfirmGetApiKeyVisible: false,
    requestApiPublicID: null,
    isRequestingApiKey: false,
    errorRequestinApiKey: "",
    requestLogs: [],
    showRequestedLogs: false,
    isRequestingLogs: false,
    requestLogsTitle: "",
  };

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.globalState.selectedDealer.id !== this.props.globalState.selectedDealer.id) {
      this.loadData();
    }
  }

  loadData = () => {
    let dealer_id = this.props.globalState.selectedDealer.id;
    this.setState({ isLoading: true }, () => {
      AppService.getDealer(dealer_id)
        .then(res => {
          if (!res?.data?.data) {
            this.setState({ isLoading: false });
            this.props.setAlert({
              type: "error",
              title: this.props.t("cant_get_api_keys").message || "Can not get API keys",
            });
            return;
          }

          let dealer = res.data.data;
          delete dealer.version_id;

          this.setState({ dealer }, () => {
            Service.listAccounts(dealer_id)
              .then(res => {
                this.setState({
                  publicAccounts: res?.data?.data?.sort((acc_1, acc_2) => (acc_1.created_on > acc_2.created_on ? -1 : 1)) || [],
                  hasPublicAccountError: false,
                  isLoading: false,
                });
              })
              .catch(error => {
                this.setState({ isLoading: false, hasPublicAccountError: true });
                this.props.setAlert({
                  type: "error",
                  title: this.props.t("cant_get_api_keys").message || "Can not get API keys",
                });
              });
          });
        })
        .catch(error => {
          this.setState({ isLoading: false });
          this.props.setAlert({
            type: "error",
            title: this.props.t("cant_get_dealer").message || "Can not get dealer",
          });
        });
    });
  };

  handleCopyKey = apiKey => {
    copyToClipboard(apiKey);

    this.props.setAlert({
      type: "success",
      title: this.props.t("api_key_copied").message || "API key copied to clipboard",
    });
  };

  handleDeclineAgreement = () => {
    this.props.history.push("/");
  };

  handleAcceptAgreement = async () => {
    let dealer = this.state.dealer;
    dealer.accepted_api_agreement = true;
    this.setState({ isLoading: true }, () => {
      AppService.updateDealer(dealer)
        .then(res => {
          this.setState({ isLoading: false });
          this.props.setAlert({
            dealer,
            type: "success",
            title: this.props.t("api_agreement_accepted").message || "API agreement accepted",
          });
        })
        .catch(error => {
          dealer.accepted_api_agreement = false;
          this.setState({ dealer, isLoading: false, hasPublicAccountError: true });
          this.props.setAlert({
            type: "error",
            title: this.props.t("cant_accept_api_agreement").message || "Can not accept API agreement",
          });
        });
    });
  };

  handleCreateClick = () => {
    this.setState({ isCreateVisible: true });
  };

  handleDeactivateAccount = account => {
    if (!account || !account.id) {
      return;
    }

    this.setState({ isConfirmDeactivateVisible: true, deactivateAccID: account.id });
  };

  renderDeactivateConfirm = () => {
    const { isConfirmDeactivateVisible, isDeactivatingApiKey } = this.state;
    const { t } = this.props;
    return (
      <CustomConfirm
        type="warning"
        isOpen={isConfirmDeactivateVisible}
        handleCancel={this.handleHideConfirm}
        handleConfirm={this.handleConfirmDeactivate}
        confirmLoading={isDeactivatingApiKey}
        confirmMsg={t("confirm_account_deactivate").message || "Are you sure you want to revoke this key? Once revokeded it can not be activated again."}
        confirmText={t("yes_revoke_key").message || "Yes, revoke this API key"}
        cancelText={t("cancel").message || "Cancel"}
      />
    );
  };

  handleConfirmDeactivate = () => {
    this.setState({ isDeactivatingApiKey: true }, async () => {
      try {
        await Service.revokeAccount(this.state.deactivateAccID);
        const publicAccounts = this.state.publicAccounts;
        const account = publicAccounts.find(acc => acc.id === this.state.deactivateAccID);
        if (!account) throw new Error();

        account.status = PUBLIC_ACCOUNT_STATUSES.REVOKED;

        this.setState(
          {
            publicAccounts: [...publicAccounts],
            isConfirmDeactivateVisible: false,
            deactivateAccID: "",
            isDeactivatingApiKey: false,
          },
          () => {
            this.props.setAlert({
              type: "success",
              title: this.props.t("api_key_revoked").message || "API key revoked",
            });
          }
        );
      } catch (error) {
        this.setState({ isDeactivatingApiKey: false });
        this.props.setAlert({
          type: "error",
          title: this.props.t("cant_revoke_this_key").message || "Can not revoke this API keys",
        });
      }
    });
  };

  handleHideConfirm = () => {
    this.setState({ isConfirmDeactivateVisible: false, deactivateAccID: "" });
  };

  handleOnChangeForCreateAcc = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleRequestApiKey = reason => {
    const { requestApiPublicID: public_account_id } = this.state;
    Service.requestApiKey({ public_account_id, reason })
      .then(res => {
        let { publicAccounts } = this.state;
        publicAccounts = publicAccounts.map(acc => (acc.id === public_account_id ? { ...acc, api_key: res?.data?.data?.api_key } : acc));
        this.setState({ publicAccounts, isRequestingApiKey: false, errorRequestinApiKey: "", isConfirmGetApiKeyVisible: false });
      })
      .catch(error => {
        const errorRequestinApiKey = error?.response?.data?.errors[0];

        this.setState({ isRequestingApiKey: false, errorRequestinApiKey });
        console.error(errorRequestinApiKey);
      });
  };

  handleShowGetApiKeyModal = requestApiPublicID => {
    this.setState({ isConfirmGetApiKeyVisible: true, requestApiPublicID });
  };

  handleHideGetApiKeyModal = () => {
    this.setState({ isConfirmGetApiKeyVisible: false, requestApiPublicID: null });
  };

  renderRequestApiKeyConfirm = () => {
    const { isConfirmGetApiKeyVisible, isRequestingApiKey, errorRequestinApiKey } = this.state;
    const { t } = this.props;
    return (
      <CustomConfirm
        type="info"
        isOpen={isConfirmGetApiKeyVisible}
        handleConfirm={this.handleRequestApiKey}
        handleCancel={this.handleHideGetApiKeyModal}
        confirmMsg={t("confirm_request_api_key_message").message || "Access to API Keys are logged, please give the reason for displaying it"}
        confirmMandatoryNote={t("reason_for_api_key_request").message || "Reason for requesting API key"}
        confirmLoading={isRequestingApiKey}
        error={errorRequestinApiKey}
      />
    );
  };

  handleCreateAccount = () => {
    const { newAccName, contactName, contactEmail, contactPhone, purposeForApiKey: purpose, dealer } = this.state;
    const contact = contactName + ", " + contactEmail + ", " + contactPhone;
    this.setState({ isCreatingApiKey: true }, async () => {
      try {
        let res = await Service.createAccount({ name: newAccName, contact, purpose, dealer_id: dealer.id });
        if (!res || !res.data.data) {
          return;
        }

        let publicAccounts = this.state.publicAccounts ? this.state.publicAccounts : [];
        let createdAcc = res.data.data;
        publicAccounts.unshift(createdAcc);

        this.setState(
          {
            publicAccounts,
            newAccName: "",
            contactName: "",
            contactEmail: "",
            contactPhone: "",
            purposeForApiKey: "",
            isNewAccError: false,
            isCreateVisible: false,
            isCreatingApiKey: false,
          },
          () => {
            this.props.setAlert({ type: "success", title: `API key ${createdAcc.name} created` });
          }
        );
      } catch (error) {
        this.setState({ isNewAccError: true, isCreatingApiKey: false });
      }
    });
  };

  handleGetRequestLogs = (public_account_id, requestLogsTitle) => {
    this.setState({ isRequestingLogs: public_account_id }, () => {
      Service.requestLogs({ public_account_id })
        .then(res => {
          const requestLogs = res?.data?.data ? res.data.data.sort((a, b) => (a.created_on > b.created_on ? -1 : 1)) : [];
          this.setState({ requestLogs, showRequestedLogs: true, isRequestingLogs: false, requestLogsTitle });
        })
        .catch(error => {
          let errorMessage = error?.response?.data?.errors?.shift();
          this.props.setAlert({ type: "error", title: errorMessage });
          this.setState({ requestLogs: [], showRequestedLogs: false, isRequestingLogs: false, requestLogsTitle: "" });
          console.log("error getting logs", error);
        });
    });
  };

  handleHideCreate = () => {
    this.setState({ isCreateVisible: false, newAccName: "", isNewAccError: false, contactName: "", contactEmail: "", contactPhone: "", purposeForApiKey: "" });
  };

  displayLogAction = action => {
    switch (action) {
      case PUBLIC_ACCOUNT_ACTIONS.SHOW_KEY:
        return this.props.t("key_shown").message || "Key shown";
      case PUBLIC_ACCOUNT_ACTIONS.REVOKE_KEY:
        return this.props.t("key_revoked").message || "Key revoked";
      default:
        return "";
    }
  };

  renderLogs = () => {
    const { requestLogs, showRequestedLogs, requestLogsTitle } = this.state;

    return (
      <Modal open={showRequestedLogs} closeOnDimmerClick={false}>
        <h2 style={{ padding: "1.5rem 0rem 0rem 1.5rem" }}>
          {this.props.t("request_logs_of").message || "Request logs of"} {requestLogsTitle}
        </h2>
        <Modal.Content style={{ paddingTop: "0rem" }}>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>{this.props.t("action_by").message || "Action by"}</Table.HeaderCell>
                <Table.HeaderCell>{this.props.t("action_at").message || "Action at"}</Table.HeaderCell>
                <Table.HeaderCell>{this.props.t("action").message || "Action"}</Table.HeaderCell>
                <Table.HeaderCell>{this.props.t("reason").message || "reason"}</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {requestLogs.map(log => (
                <Table.Row key={log.id}>
                  <Table.Cell>{log.created_by_name}</Table.Cell>
                  <Table.Cell>{moment(log.created_on).format("DD-MM-YYYY")}</Table.Cell>
                  <Table.Cell>{this.displayLogAction(log.action)}</Table.Cell>
                  <Table.Cell>{log.reason}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Modal.Content>
        <Modal.Actions>
          <Button color="blue" onClick={() => this.setState({ showRequestedLogs: false, requestLogs: [], requestLogsTitle: "" })}>
            {this.props.t("close").message || "Close"}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };

  renderPublicAccounts = (accounts, handleDeactivateAccount) => {
    if (this.state.hasPublicAccountError) {
      return (
        <div className="EmptyState">
          <div>{this.props.t("problem_loading_keys").message || "Problem loading your API keys"}</div>
        </div>
      );
    }

    if (!accounts || accounts.length === 0) {
      return (
        <div className="EmptyState">
          <div>{this.props.t("no_api_keys").message || "You have no API keys. Create one by using the button above."}</div>
        </div>
      );
    }

    return (
      <Table style={{ width: "98%", margin: "auto" }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{this.props.t("name").message || "Name"}</Table.HeaderCell>
            <Table.HeaderCell>{this.props.t("key").message || "Key"}</Table.HeaderCell>
            <Table.HeaderCell className="-text-left">{this.props.t("status").message || "Status"}</Table.HeaderCell>
            <Table.HeaderCell>{this.props.t("created_by").message || "Created by"}</Table.HeaderCell>
            <Table.HeaderCell>{this.props.t("created_on").message || "Created on"}</Table.HeaderCell>
            <Table.HeaderCell>{this.props.t("contact").message || "Contact"}</Table.HeaderCell>
            <Table.HeaderCell>{this.props.t("last_used_on_day").message || "Last day used"}</Table.HeaderCell>
            <Can I="logs" the="publicapi">
              <Table.HeaderCell className="-text-left">{this.props.t("request_logs").message || "Request logs"}</Table.HeaderCell>
            </Can>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {accounts.map((acc, k) => (
            <Table.Row key={k}>
              <Table.Cell>{acc.name}</Table.Cell>
              <Table.Cell>
                {acc.api_key ? (
                  <span
                    className="-cursor-pointer"
                    onClick={() => {
                      this.handleCopyKey(acc.api_key);
                    }}
                  >
                    <Icon name="copy outline"></Icon>
                    {acc.api_key}
                  </span>
                ) : (
                  <>
                    {acc.status === PUBLIC_ACCOUNT_STATUSES.ACTIVE && (
                      <Button basic color="teal" size="mini" onClick={() => this.handleShowGetApiKeyModal(acc.id)}>
                        {this.props.t("show_api_key").message || "Show the API Key"}
                      </Button>
                    )}
                  </>
                )}
              </Table.Cell>
              <Table.Cell>
                <Button
                  basic
                  disabled={acc.status === PUBLIC_ACCOUNT_STATUSES.REVOKED}
                  color={acc.status === PUBLIC_ACCOUNT_STATUSES.ACTIVE ? "yellow" : "grey"}
                  size="mini"
                  onClick={() => handleDeactivateAccount(acc)}
                >
                  {acc.status === PUBLIC_ACCOUNT_STATUSES.REVOKED ? this.props.t("revoked").message || "Revoked" : this.props.t("revoke").message || "Revoke"}
                </Button>
              </Table.Cell>
              <Table.Cell>{acc.created_by_name}</Table.Cell>
              <Table.Cell>{moment(acc.created_on).format("DD-MM-YYYY")}</Table.Cell>
              <Table.Cell>{acc.contact}</Table.Cell>
              <Table.Cell>{acc.last_used_at ? moment(acc.last_used_at).format("DD-MM-YYYY") : this.props.t("not_used_yet").message || "Not used yet"}</Table.Cell>
              <Can I="logs" the="publicapi">
                <Table.Cell className="-text-left">
                  <Button
                    basic
                    color="teal"
                    disabled={!!this.state.isRequestingLogs}
                    loading={this.state.isRequestingLogs === acc.id}
                    size="mini"
                    onClick={() => this.handleGetRequestLogs(acc.id, acc.name)}
                  >
                    {this.props.t("request_logs").message || "Request Logs"}
                  </Button>
                </Table.Cell>
              </Can>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    );
  };

  renderCreateAccount = () => {
    const { newAccName, contactName, contactEmail, contactPhone, purposeForApiKey, isCreatingApiKey } = this.state;
    const { t } = this.props;
    const handleCreateDisabled = !newAccName || !contactName || !contactEmail || !contactPhone || !purposeForApiKey || isCreatingApiKey;
    return (
      <Modal size="mini" open={true} dimmer={false} closeOnDimmerClick={false} closeOnEscape={true} onClose={this.handleHideCreate}>
        <Modal.Header>
          <Icon name="key"></Icon>
          {t("create_new_key").message || "Create new API key"}
        </Modal.Header>
        <Modal.Content>
          <label>{t("new_key_name").message || "Unique name of your new API key"}</label>
          <UserInput
            value={newAccName}
            onChange={this.handleOnChangeForCreateAcc}
            style={{
              width: "100%",
              marginBottom: "10px",
            }}
            name="newAccName"
          />

          <label>{t("api_key_contact_name").message || "API Key contact name"}</label>
          <UserInput
            value={contactName}
            onChange={this.handleOnChangeForCreateAcc}
            style={{
              width: "100%",
              marginBottom: "10px",
            }}
            name="contactName"
          />

          <label>{t("api_key_contact_email").message || "API Key contact email"}</label>
          <UserInput
            value={contactEmail}
            onChange={this.handleOnChangeForCreateAcc}
            style={{
              width: "100%",
              marginBottom: "10px",
            }}
            name="contactEmail"
          />

          <label>{t("api_key_contact_phone").message || "API Key contact phone"}</label>
          <UserInput
            value={contactPhone}
            onChange={this.handleOnChangeForCreateAcc}
            style={{
              width: "100%",
              marginBottom: "10px",
            }}
            name="contactPhone"
          />

          <label>{t("purpose_for_api_key").message || "Purpose for api key"}</label>
          <TextArea value={purposeForApiKey} onChange={this.handleOnChangeForCreateAcc} className="public-api-text-area" name="purposeForApiKey" />
          {this.state.isNewAccError && (
            <Label basic color="red" pointing>
              {t("cant_create_this_key").message || "Can not create this API key"}
            </Label>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={this.handleHideCreate}>
            {t("cancel").message || "Cancel"}
          </Button>
          <Button positive disabled={handleCreateDisabled} loading={this.state.isCreatingApiKey} onClick={this.handleCreateAccount}>
            {t("create").message || "Create"}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };

  renderAgreement = (handleDecline, handleAccept) => {
    return (
      <div className="API-Agreement">
        <Modal open={this.state.dealer && !this.state.dealer.accepted_api_agreement} closeOnDimmerClick={false} size="large">
          <Modal.Content>
            <article>
              <h1>
                <Icon name="file alternate outline"></Icon>
                API Agreement
              </h1>

              <div
                style={{
                  height: "450px",
                  overflow: "auto",
                }}
              >
                <embed src="https://storage.googleapis.com/claire-dpo-resources/GDPR-AVG-Claire.pdf" width="100%" height="100%" />
              </div>
            </article>
          </Modal.Content>
          <Modal.Actions>
            <Button negative onClick={handleDecline}>
              <Icon name="close"></Icon>
              {this.props.t("decline").message || "Decline"}
            </Button>
            <Button positive onClick={handleAccept}>
              <Icon name="check"></Icon>
              {this.props.t("accept").message || "Accept"}
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  };

  render() {
    return (
      <div className="PublicAPI-DealerAdminDashboard">
        <SubHeader>
          <Grid.Column width={3} className="SubHeader_title -no-padding">
            {this.props.t("claire_api").message || "Claire API"}
          </Grid.Column>
          <Grid.Column width={3} floated="right" className="-text-right">
            <a href="https://apidocs.claireit.com/#introduction" target="_blank" rel="noopener noreferrer" className="-link-plain">
              <Button size="small" color="green" basic>
                <Icon name="book"></Icon>
                {this.props.t("api_documentation").message || "API Docs"}
              </Button>
            </a>
          </Grid.Column>
        </SubHeader>

        <Grid style={{ width: "98%", margin: "auto" }}>
          <Grid.Column width={3} className="-no-padding">
            <h1 style={{ marginTop: "10px" }}>
              <Icon name="key"></Icon>
              {this.props.t("api_keys").message || "API Keys"}
            </h1>
          </Grid.Column>

          <Grid.Column width={3} floated="right" className="-text-right -no-padding-right">
            <Button size="small" color="green" onClick={this.handleCreateClick}>
              <Icon name="add circle"></Icon>
              {this.props.t("create_public_account").message || "Create API key"}
            </Button>
          </Grid.Column>
        </Grid>

        {this.state.isLoading && (
          <div className="Table__loading Loader-Placeholder" style={{ minHeight: "95vh" }}>
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
            <section>{this.props.t("loading").message || "Loading"}</section>
          </div>
        )}

        {this.state.dealer && !this.state.dealer.accepted_api_agreement && this.renderAgreement(this.handleDeclineAgreement, this.handleAcceptAgreement)}
        {!this.state.isLoading && this.renderPublicAccounts(this.state.publicAccounts, this.handleDeactivateAccount)}
        {this.state.isCreateVisible && this.renderCreateAccount()}
        {this.state.isConfirmDeactivateVisible && this.renderDeactivateConfirm()}
        {this.state.isConfirmGetApiKeyVisible && this.renderRequestApiKeyConfirm()}
        {this.state.showRequestedLogs && this.renderLogs()}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return { authState: state.auth, globalState: state.global };
};

const mapDispatchToProps = dispatch => {
  return {
    setAlert: alertOptions => dispatch(setAlert(alertOptions)),
  };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(PublicAPI)));
