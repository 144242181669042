import React, { Component } from "react";
import AlertContainer from "react-alert";
import moment from "moment";
import Papa from "papaparse";
import ReactTable, { ReactTableDefaults } from "react-table";
import { withTranslation } from "react-i18next";
import { Modal, Button, Icon } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { faCopy } from "@fortawesome/free-solid-svg-icons";

import { copyToClipboard } from "../../util/common";
import Can from "../Can";

import Service from "./service";

import "./AppointmentDetailLog.css";

class AppointmentDetailLog extends Component {
  state = {
    logData: [],
    isLogVisible: false,
    isLoading: false,
    isUploadedImagesModalOpen: false,
    attachments: [],
  };

  componentDidMount() {
    this.getAllAppointmentAttachments();
  }

  getAllAppointmentAttachments = () => {
    if (this.state.attachments.length) return;

    Service.getAllAppointmentAttachments({ appointment_id: this.props.appointmentId })
      .then(res => {
        this.setState({ attachments: res?.data?.data?.data || [] });
      })
      .catch(err => {
        console.log("Error getting appointment attachments", err);

        this.msg.show(this.props.t("failed_to_load_attachments").message || "Failed to load attachments", {
          time: 4000,
          type: "error",
        });
      });
  };

  getDetailLog = () => {
    this.setState({ isLoading: true }, async () => {
      try {
        const response = await Service.getAppointmentDetailLog(this.props.appointmentId);
        const csvWithHeaders = "time,user_id,user_name,affected_element,affected_element_id,appointment_id,description\n" + (response?.data?.data || "");

        Papa.parse(csvWithHeaders, {
          delimiter: ",",
          header: true,
          skipEmptyLines: true,
          complete: result => {
            const logData = result.data.map(({ user_id, affected_element_id, appointment_id, ...rest }) => rest).sort((a, b) => new Date(b.time) - new Date(a.time));

            this.setState({ logData, isLoading: false });
          },
        });
      } catch (err) {
        console.error("Failed to get detail log", err);
        this.msg.show(this.props.t("error_loading_detail_log").message || "Failed to get detail log.", {
          time: 4000,
          type: "error",
        });
        this.setState({ isLoading: false });
      }
    });
  };

  handleToggleLog = () =>
    this.setState(
      state => ({ isLogVisible: !state.isLogVisible }),
      () => {
        if (this.state.isLogVisible) this.getDetailLog();
      }
    );

  renderAlert = () => {
    const alertOptions = {
      offset: 20,
      position: "top center",
      theme: "light",
      time: 2000,
      transition: "fade",
    };

    return <AlertContainer ref={a => (this.msg = a)} {...alertOptions} />;
  };

  renderCopyAttachmentsAlert = () => {
    const alertOptions = {
      offset: 20,
      position: "top center",
      theme: "light",
      time: 2000,
      transition: "fade",
    };

    return <AlertContainer ref={a => (this.copyAttachmentsMsg = a)} {...alertOptions} />;
  };

  parseDescription = desc => {
    if (!desc) return "";

    const parsedDesc = desc
      .replace(/#([a-f0-9]{3}){1,2}\b/gi, match => `<span style="color: ${match}">${match}</span>`)
      .replace(/(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)((-(\d{2}):(\d{2})|Z)?)/gi, match => moment(match).format("DD-MM-YYYY HH:mm"));

    return <div dangerouslySetInnerHTML={{ __html: parsedDesc }}></div>;
  };

  copyAllAttachmentUrls = () => {
    copyToClipboard(this.state.attachments.join("\n"));

    this.copyAttachmentsMsg.show(this.props.t("all_attachments_copied").message || "All attachments copied", {
      time: 4000,
      type: "success",
    });
  };

  toggleIsUploadedImagesModal = () => {
    this.setState(({ isUploadedImagesModalOpen }) => ({ isUploadedImagesModalOpen: !isUploadedImagesModalOpen }));
  };

  renderImageInNewTab = url => {
    window.open("", "_blank").document.write(`<img src="${url}" />`);
  };

  renderUploadedImages = () => {
    const { t } = this.props;

    return (
      <Modal open={this.state.isUploadedImagesModalOpen} size="small" className="uploaded-images-modal">
        <Modal.Header>
          <h3>{t("all_pictures").message || "All pictures"}</h3>
          <FontAwesomeIcon icon={faTimes} onClick={this.toggleIsUploadedImagesModal} />
        </Modal.Header>

        <Modal.Content scrolling>
          {this.state.attachments.map(attachment => (
            <a href={attachment} target="_blank" rel="noopener noreferrer">{attachment}</a>
          ))}
        </Modal.Content>

        <Modal.Actions>
          <Button className="copy-all-links-button" onClick={this.copyAllAttachmentUrls}>
            <FontAwesomeIcon icon={faCopy} />
            <span>{t("copy_all_links").message || "Copy all links"}</span>
          </Button>
        </Modal.Actions>

        {this.renderCopyAttachmentsAlert()}
      </Modal>
    );
  };

  renderLog() {
    const { isLogVisible, logData, isLoading, attachments } = this.state;
    const { t } = this.props;

    return (
      <Modal className="AppointmentDetailLog" closeOnEscape open={isLogVisible} onClose={this.handleToggleLog} closeOnDimmerClick={false} size="large">
        <Modal.Header>
          {t("appointment_detail_log").message || "Appointment Detail Log"}

          <div>
            {attachments.length > 0 && (
              <Can I="list_appointment_attachments" the="files">
                <span onClick={this.toggleIsUploadedImagesModal} className="uploaded-files-text">
                  {t("car_check_files").message || "Car check files"}
                </span>
              </Can>
            )}

            <Button floated="right" color="green" onClick={this.handleToggleLog}>
              {t("close").message || "Close"}
            </Button>
          </div>
        </Modal.Header>
        <Modal.Content scrolling>
          <ReactTable
            className="DetailLogTable -floated-table"
            data={logData}
            minRows={0}
            showPagination
            pageSize={50}
            loading={isLoading}
            showPageSizeOptions={false}
            loadingText={t("loading_logs").message || "Loading logs"}
            sortable={false}
            resizable={false}
            nextText={t("next").message || "Next"}
            previousText={t("previous").message || "Previous"}
            pageText={t("page").message || "Page"}
            ofText={t("of").message || "of"}
            noDataText={
              <div className="Table__no-results">
                <Icon disabled name="list" style={{ fontSize: "1.25em" }} />
                <p>{t("no_log_data").message || "No log data"}</p>
              </div>
            }
            column={{
              ...ReactTableDefaults.column,
              headerClassName: "ReactTable__column-header -text-ellipsis",
              className: "ReactTable__column",
            }}
            columns={[
              {
                Header: t("time").message || "Time",
                id: "time",
                width: 150,
                accessor: d => moment(d.time).format("DD-MM-YYYY HH:mm"),
              },
              {
                Header: t("user_name").message || "Name",
                id: "user_name",
                width: 120,
                accessor: d => d.user_name,
              },
              {
                Header: t("affected_element").message || "Affected element",
                id: "affected_element",
                width: 140,
                accessor: d => d.affected_element,
              },
              {
                Header: t("description").message || "Description",
                id: "description",
                className: "full-description-row",
                accessor: d => this.parseDescription(d.description),
              },
            ]}
          />
        </Modal.Content>

        {this.renderAlert()}
      </Modal>
    );
  }

  render() {
    return (
      <>
        <Button basic fluid size="large" className="LogButton" onClick={this.handleToggleLog}>
          {this.props.t("logs").message || "Logs"}
        </Button>

        {this.renderLog()}
        {this.renderUploadedImages()}
      </>
    );
  }
}

export default withTranslation()(AppointmentDetailLog);
