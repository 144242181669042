import axios from "axios";

import ENV from "../../util/env-config";
import { getSessionCookie } from "../../util/common";

class Service {
  static requestConfig() {
    return {
      headers: {
        Authorization: `Bearer ${getSessionCookie()}`,
        Pragma: "no-cache",
      },
    };
  }

  static getTires(requestData) {
    return axios.post(`${ENV.apiBaseB}/tyres/list`, requestData, this.requestConfig());
  }

  static searchTires(term, limit, page) {
    return axios.post(`${ENV.apiBaseB}/tyres/search_by_term`, { term, limit, page }, this.requestConfig());
  }

  static createTire(requestData) {
    return axios.post(`${ENV.apiBaseB}/tyres/create`, requestData, this.requestConfig());
  }

  static updateTire(requestData, tyre_id) {
    return axios.post(`${ENV.apiBaseB}/tyres/complete`, { ...requestData, tyre_id }, this.requestConfig());
  }
}

export default Service;
