import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Modal, Label, Grid, Icon, Button, Tab } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTag } from "@fortawesome/pro-solid-svg-icons";
import moment from "moment";

import printJS from "../../lib/print-js";
import { KIOSK_COMMUNICATION_EVENTS, KIOSK_COMMUNICATION_STATUSES } from "./common";
import { getPriceForDisplay } from "./util";

import "./KioskCommunicationLog.scss";

class KioskCommunicationLog extends Component {
  state = {
    isOpen: false,
    isAgreementModalOpen: false,
    isPrintLoading: false,
    selectedAgreement: null,
  };

  handleToggleAgreement = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  handleOpenAgreementModal = selectedAgreement => {
    this.setState({ isAgreementModalOpen: true, selectedAgreement });
  };

  handleCloseAgreementModal = () => {
    this.setState({ isAgreementModalOpen: false, selectedAgreement: null });
  };

  renderAppointmentInformation = () => {
    const { appointment, t } = this.props;

    return (
      <div className="info-section">
        <div className="section-header">
          <span>{t("appointment_information").message || "Appointment information"}</span>
        </div>
        <div className="section-content">
          <Grid>
            <Grid.Column width={4}>
              <span className="icon-text">
                {t("wo_nr").message || "WO"} <Icon name="hashtag" color="green" />
              </span>
              {appointment.wo_nr}
            </Grid.Column>

            <Grid.Column width={4}>
              <span className="icon-text">
                {t("reg").message || "Reg."} <Icon name="hashtag" color="green" />
              </span>
              {appointment.reg_number}
            </Grid.Column>

            {appointment.due_in && (moment.isMoment(appointment.due_in) || !appointment.due_in.startsWith("0001-01-01T00:00:00")) ? (
              <>
                <Grid.Column width={4}>
                  <Icon name="calendar alternate" color="green" />
                  {moment(appointment.due_in).format("DD-MM-YYYY")}
                </Grid.Column>

                <Grid.Column width={4}>
                  <Icon name="time" color="green" />
                  {moment(appointment.due_in).format("HH:mm")}
                </Grid.Column>
              </>
            ) : (
              appointment.time_car_app &&
              (moment.isMoment(appointment.time_car_app) || !appointment.time_car_app.startsWith("0001-01-01T00:00:00")) && (
                <Grid.Column width={4}>
                  <Icon name="calendar alternate" color="green" />
                  {moment(appointment.time_car_app).format("DD-MM-YYYY")}
                </Grid.Column>
              )
            )}
          </Grid>
        </div>
      </div>
    );
  };

  getInterventionPrice = (price, vat) => {
    if (price <= 0.01) return;

    return <span>{vat ? getPriceForDisplay(price * (1 + vat / 100)) : getPriceForDisplay(price)} €</span>;
  };

  communicationEventName = (event, t) => {
    switch (event.type) {
      case KIOSK_COMMUNICATION_EVENTS.CHECKIN_OPENED:
        return t("opened").message || "Opened";

      case KIOSK_COMMUNICATION_EVENTS.CHECKIN_ANSWERED:
        return t("answered").message || "Answered";

      case KIOSK_COMMUNICATION_EVENTS.LABEL_DETACHED:
        return t("kiosk_key_label_detached").message || "Kiosk key label detached";

      default:
        return "";
    }
  };

  communicationEventInfo = (event, comm) => {
    const fullname = comm.customer && `${comm.customer.firstname} ${comm.customer.surname}`;

    return (
      <Grid.Row width={16} className="section-rows">
        {event.type === KIOSK_COMMUNICATION_EVENTS.CHECKIN_ANSWERED && (
          <div className="kiosk-comm-answered-event">
            <Label className="kiosk-label-icon" color="green">
              <FontAwesomeIcon icon={faTag} />
              <span>{event.kiosk_label_number}</span>
            </Label>
            {event.kiosk_name}
          </div>
        )}
        {event.type === KIOSK_COMMUNICATION_EVENTS.LABEL_DETACHED && event.user && (
          <span>
            <Icon name="user" color="green" /> {`${event.user.first_name} ${event.user.last_name}`.trim()}
          </span>
        )}
        {event.type === KIOSK_COMMUNICATION_EVENTS.CHECKIN_OPENED && fullname && (
          <span>
            <Icon name="user" color="green" />
            {fullname}
          </span>
        )}
      </Grid.Row>
    );
  };

  renderCommunicationEvents = comm => {
    const { t } = this.props;
    const { events } = comm;

    return (
      events?.length > 0 && (
        <div className="info-section">
          <div className="section-header">{t("kiosk_communications_events").message || "Kiosk Communication events"}</div>
          <div className="section-content">
            <Grid>
              {events.map((event, key) => {
                return (
                  <Grid.Row key={key}>
                    <Grid.Column width={12}>{this.communicationEventName(event, t)}</Grid.Column>

                    <Grid.Column width={4} className="event-timestamp">
                      {moment(event.created_on).format("HH:mm")} - {moment(event.created_on).format("DD-MM-YYYY")}
                    </Grid.Column>

                    {this.communicationEventInfo(event, comm)}
                  </Grid.Row>
                );
              })}
            </Grid>
          </div>
        </div>
      )
    );
  };

  renderAgreementModal = () => {
    const { selectedAgreement, isAgreementModalOpen } = this.state;
    return (
      selectedAgreement && (
        <Modal dimmer={true} closeOnDimmerClick={false} open={isAgreementModalOpen} onClose={this.handleCloseAgreementModal}>
          <Modal.Header>
            {selectedAgreement.name}
            <Button onClick={this.handleCloseAgreementModal} floated="right" color="green">
              {this.props.t("close").message || "Close"}
            </Button>
          </Modal.Header>
          <Modal.Content scrolling>
            <div dangerouslySetInnerHTML={{ __html: selectedAgreement.text }}></div>
          </Modal.Content>
        </Modal>
      )
    );
  };

  renderAgreements = agreements => {
    if (!agreements?.length) return null;
    const { t } = this.props;

    return (
      <div className="info-section">
        <div className="section-header">{t("agreements").message || "Agreements"}</div>
        <div className="section-content">
          <Grid>
            <Grid.Column width={16}>
              {agreements.map((item, key) => (
                <div className="agreements-label" key={key} onClick={() => this.handleOpenAgreementModal(item)}>
                  <Label>
                    <Icon name="check square" />
                  </Label>
                  <span className="-margin-left-10">{item.name}</span>
                </div>
              ))}
            </Grid.Column>
          </Grid>
        </div>
      </div>
    );
  };

  renderAgreedInterventions = agreed_interventions => {
    const { vat, t } = this.props;

    if (!agreed_interventions?.length) return null;

    return (
      <div className="info-section">
        <div className="section-header">{this.props.t("accepted_scheduled_services").message || "Accepted scheduled services"}</div>
        <div className="section-content">
          <Grid>
            <Grid.Column width={16}>
              <div className="services-header">
                <span>{t("title").message || "Title"}</span>
                <span>{vat ? t("price_inc_vat").message || "Price (incl. VAT)" : t("price_excl_vat").message || "Price (excl. VAT)"}</span>
              </div>
              {agreed_interventions.map((i, key) => (
                <div className="service" key={key}>
                  <span>{i.title}</span>
                  {this.getInterventionPrice(i.price, vat)}
                </div>
              ))}
            </Grid.Column>
          </Grid>
        </div>
      </div>
    );
  };

  renderSignature = events => {
    const event = events.find(e => e.type === KIOSK_COMMUNICATION_EVENTS.CHECKIN_ANSWERED);

    if (!event) return null;

    return (
      <div className="info-section">
        <div className="section-header">{this.props.t("signature").message || "Signature"}</div>
        <div className="section-content">
          <Grid>
            {event.customer_name && (
              <Grid.Row>
                <Grid.Column width={16}>
                  <span>
                    <Icon name="user" color="green" />
                    {event.customer_name}
                  </span>
                </Grid.Column>
              </Grid.Row>
            )}
            {event.customer_signature && (
              <Grid.Row>
                <Grid.Column width={4}>
                  <img src={event.customer_signature} className="signature-img" alt="signature" />
                </Grid.Column>
              </Grid.Row>
            )}
          </Grid>
        </div>
      </div>
    );
  };

  renderTabContent = comm => {
    const { events, agreed_interventions, agreements } = comm;

    return (
      <>
        {this.renderCommunicationEvents(comm)}
        {this.renderAgreedInterventions(agreed_interventions)}
        {this.renderAgreements(agreements)}
        {this.renderSignature(events)}
      </>
    );
  };

  renderTabs = () => {
    const { appointment, t } = this.props;
    const panes = [];

    appointment.kiosk_communications.forEach(comm => {
      panes.push({
        menuItem: comm.status === KIOSK_COMMUNICATION_STATUSES.CHECKIN_OPENED ? t("opened").message || "Opened" : t("answered").message || "Answered",
        render: () => this.renderTabContent(comm),
      });
    });
    return panes.length > 0 && <Tab menu={{ secondary: true }} panes={panes} />;
  };

  stopPrintLoading = () => this.setState({ isPrintLoading: false });

  handlePrint = () => {
    return this.setState({ isPrintLoading: true }, () => {
      printJS({
        printable: "kiosk-communication-agreement-content",
        type: "html",
        copyStyles: true,
        onLoadingEnd: this.stopPrintLoading,
        onError: this.stopPrintLoading,
      });
    });
  };

  render() {
    const { t, appointment } = this.props;

    if (!Array.isArray(appointment.kiosk_communications)) return null;

    return (
      <>
        <Modal
          closeOnDimmerClick={false}
          open={this.state.isOpen}
          onOpen={this.handleToggleAgreement}
          onClose={this.handleToggleAgreement}
          className="KioskCommunicationLog-Modal"
          trigger={
            <div className="comm-agreement-label">
              <Label>
                <Icon name="user" />
              </Label>
              <span className="-margin-right-15">{t("kiosk_comm_log").message || "Kiosk communication log"}</span>
            </div>
          }
        >
          <Modal.Header>
            <Icon name="list" />
            {t("kiosk_comm_log").message || "Kiosk communication log"}
            <Button onClick={this.handlePrint} loading={this.state.isPrintLoading} className="print-btn" color="green">
              {t("print").message || "Print"}
            </Button>
          </Modal.Header>
          <Modal.Content scrolling id="kiosk-communication-agreement-content">
            {this.renderAppointmentInformation()}
            {this.renderTabs()}
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.handleToggleAgreement}>{t("close").message || "Close"}</Button>
          </Modal.Actions>
        </Modal>
        {this.renderAgreementModal()}
      </>
    );
  }
}

export default withTranslation()(KioskCommunicationLog);
