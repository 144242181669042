import axios from "axios";

import ENV from "../../util/env-config";
import { getSessionCookie } from "../../util/common";

class Service {
  static requestConfig() {
    return {
      headers: {
        Authorization: `Bearer ${getSessionCookie()}`,
        Pragma: "no-cache",
      },
    };
  }

  static getKeyLockers(requestData) {
    return axios.post(ENV.keylockerBase + "/dashboard/list", requestData, this.requestConfig());
  }

  static createKeyLocker(requestData) {
    return axios.post(ENV.keylockerBase + "/dashboard/create", requestData, this.requestConfig());
  }

  static updateKeyLocker(requestData) {
    return axios.post(ENV.keylockerBase + "/dashboard/update", requestData, this.requestConfig());
  }

  static activateKeyLocker(requestData) {
    return axios.post(ENV.keylockerBase + "/dashboard/enable", requestData, this.requestConfig());
  }

  static deactivateKeyLocker(requestData) {
    return axios.post(ENV.keylockerBase + "/dashboard/disable", requestData, this.requestConfig());
  }

  static deleteKeyLocker(requestData) {
    return axios.post(ENV.keylockerBase + "/dashboard/delete", requestData, this.requestConfig());
  }

  static getCCDetail(requestData) {
    return axios.post(ENV.dealerBaseUrl + "/communications/settings/read", requestData, this.requestConfig());
  }

  static getKeyLockerBoxes(requestData) {
    return axios.post(ENV.keylockerBase + "/dashboard/details", requestData, this.requestConfig());
  }

  static activateBox(requestData) {
    return axios.post(ENV.keylockerBase + "/dashboard/box/enable", requestData, this.requestConfig());
  }

  static deactivateBox(requestData) {
    return axios.post(ENV.keylockerBase + "/dashboard/box/disable", requestData, this.requestConfig());
  }

  static emptyBox(requestData) {
    return axios.post(ENV.keylockerBase + "/dashboard/admin/empty_box", requestData, this.requestConfig());
  }

  static getAdminPin(requestData) {
    return axios.post(ENV.keylockerBase + "/dashboard/admin/pin", requestData, this.requestConfig());
  }

  static handleCallForHelp(requestData) {
    return axios.post(ENV.keylockerBase + "/dashboard/handle_call_for_help", requestData, this.requestConfig());
  }
}

export default Service;
