import axios from "axios";

import ENV from "../../util/env-config";
import { getSessionCookie } from "../../util/common";

class Service {
  static requestConfig() {
    return {
      headers: {
        Authorization: `Bearer ${getSessionCookie()}`,
        Pragma: "no-cache",
      },
    };
  }

  static getROBContractData(requestData) {
    return axios.post(ENV.carBaseUrl + "/robnet/get_contract", requestData, this.requestConfig());
  }
}

export default Service;
