import React, { Component } from "react";
import { Grid, Icon } from "semantic-ui-react";
import { DealerDropdown, LocationDropdown, UserMenu } from "../../components";
import { connect } from "react-redux";
import APP_CONFIG from "../../util/app-config";
import { WEB_SOCKET_ACTION_TYPES } from "../../modules/WebSocket/store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChromecast } from "@fortawesome/free-brands-svg-icons";
import "./Header.css";
import { ROLES } from "../../modules/Users/roles";
import { isAdmin } from "../../util/common";

const LOCATION_DROPDOWN_PATHS_NOT_VISIBLE = [
  "/leads",
  "/users",
  "/warranty",
  "/news",
  "/manufacturer",
  "/dmsv3/settings",
  "/dmsv3/custom-queries",
  "/dmsv3/monitoring",
  "/factory-export",
];
const DEALER_DROPDOWN_PATHS_NOT_VISIBLE = [
  "/leads",
  "/users",
  "/warranty",
  "/news",
  "/manufacturer",
  "/dmsv3/settings",
  "/dmsv3/custom-queries",
  "/dmsv3/monitoring",
  "/factory-export",
];

class Header extends Component {
  state = {
    isOnline: false,
    hasInternetConnection: false,
  };

  componentDidMount() {
    this.updateNetworkStatus();
    this.setUpNetworkListeners();
  }

  componentWillUnmount() {
    this.removeNetworkListeners();
  }

  updateNetworkStatus = () => {
    if (navigator.onLine) {
      this.setState({
        hasInternetConnection: true,
      });
    } else {
      this.setState({
        hasInternetConnection: false,
      });
    }
  };

  setUpNetworkListeners() {
    window.addEventListener("online", this.updateNetworkStatus);
    window.addEventListener("offline", this.updateNetworkStatus);
  }

  removeNetworkListeners() {
    window.removeEventListener("online", this.updateNetworkStatus);
    window.removeEventListener("offline", this.updateNetworkStatus);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.webSocket.actionType === WEB_SOCKET_ACTION_TYPES.SET_IS_ONLINE) {
      const { isOnline } = this.state;
      if (nextProps.webSocket.connected !== isOnline) {
        this.setState({ isOnline: nextProps.webSocket.connected });
      }
    }
  }

  render() {
    let {
      currentUser,
      onUserMenuItemClick,
      dealers,
      locations,
      selectedDealer,
      selectedLocation,
      onDealerChange,
      onLocationChange,
      onSidebarToggle,
      showCustomLocations,
      locationPathname,
    } = this.props;

    let selectedLocationID = selectedLocation ? selectedLocation.id : "";
    let isDealerDropdownVisible = [ROLES.CONSULTANT, ROLES.SUPERVISOR, ROLES.WARRANTY_MANAGER].includes(currentUser.role_id) || isAdmin(currentUser.role_id);

    return (
      <div id="header" className="Header" style={this.state.hasInternetConnection ? {} : { backgroundColor: "gray" }}>
        <Grid className="-contained-large">
          <Grid.Column className="Header__column -brand-column" computer={3} widescreen={4}>
            <Icon name="content" onClick={onSidebarToggle} />
            <div className="Header__brand">
              <span>{APP_CONFIG.title}</span>
            </div>
            <div style={{ display: "inline", marginLeft: "1em" }}>
              {this.state.isOnline ? (
                <FontAwesomeIcon icon={faChromecast} style={{ fontSize: "1.4em", color: "#03D551" }} />
              ) : (
                <FontAwesomeIcon icon={faChromecast} style={{ fontSize: "1.4em", color: "#C33231" }} />
              )}
            </div>
          </Grid.Column>
          <Grid.Column className="Header__column -search-column" width={6}>
            <div id="search-root" className="Header__search">
              {/* Content is attached using the <SearchPortal/> */}
            </div>
          </Grid.Column>
          <Grid.Column className="Header__column -filter-column -user-menu-column -no-padding-left" computer={7} widescreen={6} floated="right" textAlign="right">
            {!LOCATION_DROPDOWN_PATHS_NOT_VISIBLE.includes(locationPathname) && (
              <LocationDropdown
                button
                fluid={false}
                labeled
                floating
                optionAllVisible={showCustomLocations}
                options={locations && locations.map(l => ({ text: l.name, value: l.id, detail: l, key: l.id }))}
                value={selectedLocationID}
                onChange={onLocationChange}
              />
            )}

            {isDealerDropdownVisible && !DEALER_DROPDOWN_PATHS_NOT_VISIBLE.includes(locationPathname) && (
              <DealerDropdown
                className="-margin-left-5 -margin-right-15"
                isFluid={false}
                options={dealers}
                value={selectedDealer ? selectedDealer.id : ""}
                trigger={selectedDealer ? selectedDealer.name : "Select a dealer"}
                onChange={onDealerChange}
              />
            )}

            {currentUser && <UserMenu onMenuItemClick={onUserMenuItemClick} user={currentUser} className="-margin-left-15" />}
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    webSocket: state.webSocket,
  };
};

export default connect(mapStateToProps, null)(Header);

// productManualUrl={localStorage.getItem('manual_url')}
