import { collectStyles, addHeader } from "./functions";
import Print from "./print";

export default {
  print: (params, printFrame) => {
    let printElement = document.getElementById(params.printable);

    if (!printElement) {
      window.console.error("Invalid HTML element id: " + params.printable);
      return;
    }

    params.printableElement = cloneElement(printElement, params);

    if (params.header) {
      addHeader(params.printableElement, params);
    }

    Print.send(params, printFrame);
  },
};

function cloneElement(element, params) {
  const clone = element.cloneNode();

  const childNodesArray = Array.prototype.slice.call(element.childNodes);
  for (let i = 0; i < childNodesArray.length; i++) {
    if (params.ignoreElements.indexOf(childNodesArray[i].id) !== -1) {
      continue;
    }

    const clonedChild = cloneElement(childNodesArray[i], params);

    clone.appendChild(clonedChild);
  }

  if (params.scanStyles && element.nodeType === 1) {
    clone.setAttribute("style", collectStyles(element, params));
  }

  switch (element.tagName) {
    case "SELECT":
      clone.value = element.value;
      break;
    case "CANVAS":
      clone.getContext("2d").drawImage(element, 0, 0);
      break;
    default:
      break;
  }

  return clone;
}
