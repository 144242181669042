import React from "react";
import { withTranslation } from "react-i18next";
import { Tab, Icon } from "semantic-ui-react";
import ReactTable, { ReactTableDefaults } from "react-table";
import moment from "moment";

import "./CustomerEntities.css";

const CustomerEntities = props => {
  let { location, t } = props;

  let _panes = [
    {
      menuItem: t("appointments").message || "Appointments",
      render: () => <Tab.Pane>{_renderAppointments(props.appointments)}</Tab.Pane>,
    },
    {
      menuItem: t("cars").message || "Cars",
      render: () => <Tab.Pane>{_renderCars(props.cars)}</Tab.Pane>,
    },
  ];

  let _renderAppointments = apps => {
    if (!apps || apps.length === 0) {
      return (
        <p
          style={{
            padding: "1em",
            textAlign: "center",
          }}
        >
          {t("no_appointments").message || "No appointments"}
        </p>
      );
    }

    apps.sort((a, b) => (a.time_car_app > b.time_car_app ? -1 : 1));

    return (
      <ReactTable
        className="ReactTable -floated-table -contained-large -short-rows"
        data={apps}
        showPagination={false}
        showPageSizeOptions={false}
        sortable={false}
        resizable={false}
        defaultPageSize={apps.length}
        pageSize={apps.length}
        noDataText="No appointments"
        getTdProps={(state, rowInfo, column, instance) => {
          return {
            onClick: e => {
              window.open(`#!/appointments/${rowInfo.original.id}`, "_blank");
            },
          };
        }}
        column={{
          ...ReactTableDefaults.column,
          headerClassName: "ReactTable__column-header",
          className: "ReactTable__column",
        }}
        columns={[
          {
            Header: t("date").message || "Date",
            id: "date",
            accessor: d => (
              <span>
                <Icon name="external" /> {d.time_car_app ? moment(d.time_car_app).format("DD.MM.YYYY") : ""}
              </span>
            ),
            maxWidth: 150,
          },
          {
            Header: t("wo_nr").message || "WO",
            accessor: "wo_nr",
            maxWidth: 90,
          },
          {
            Header: t("reg_nr").message || "Registration",
            accessor: "reg_number",
            maxWidth: 90,
          },
          {
            id: "apkDate",
            Header: t("apk_date").message || "APK date",
            accessor: d => <span>{d.car_apk_date ? moment(d.car_apk_date).format("DD.MM.YYYY") : ""}</span>,
            maxWidth: 90,
            show: location.apk_visible,
          },
          {
            id: "carHuDate",
            Header: t("car_hu_date").message || "Car hu date",
            accessor: d => <span>{d.car_hu_date ? moment(d.car_hu_date).format("DD.MM.YYYY") : ""}</span>,
            maxWidth: 90,
            show: location.hu_visible,
          },
          {
            Header: t("car_make").message || "Car make",
            accessor: "car_make",
            maxWidth: 100,
          },
          {
            Header: t("car_model").message || "Car model",
            accessor: "car_model",
            maxWidth: 450,
          },
          {
            Header: t("km_state").message || "KM State",
            accessor: "current_km",
            maxWidth: 100,
          },
        ]}
      />
    );
  };

  let _renderCars = cars => {
    if (!cars || cars.length === 0) {
      return (
        <p
          style={{
            padding: "1em",
            textAlign: "center",
          }}
        >
          {t("no_cars").message || "No cars"}
        </p>
      );
    }

    return (
      <ReactTable
        className="ReactTable -floated-table -contained-large -short-rows"
        data={cars}
        sortable={false}
        resizable={false}
        showPagination={false}
        showPageSizeOptions={false}
        defaultPageSize={cars.length}
        pageSize={cars.length}
        noDataText="No cars"
        getTdProps={(state, rowInfo, column, instance) => {
          return {
            onClick: e => {
              props.onCarClick(rowInfo.original.id);
            },
          };
        }}
        column={{
          ...ReactTableDefaults.column,
          headerClassName: "ReactTable__column-header",
          className: "ReactTable__column",
        }}
        columns={[
          {
            Header: t("make_model").message || "Make and model",
            id: "makeModel",
            accessor: d => <span>{`${d.make} ${d.model}`}</span>,
            width: 200,
          },
          {
            Header: t("fuel").message || "Fuel",
            accessor: "fuel",
            maxWidth: 100,
          },
          {
            Header: t("dms_nr").message || "DMS",
            accessor: "dms_nr",
            maxWidth: 150,
          },
          {
            Header: t("reg_nr").message || "Registration",
            accessor: "reg_nr",
            maxWidth: 150,
          },
          {
            Header: t("vin_nr").message || "VIN",
            accessor: "vin_nr",
            maxWidth: 300,
          },
          {
            id: "regDate",
            Header: t("reg1_date").message || "1st Reg. date",
            accessor: d => <span>{d.reg1_date ? moment(d.reg1_date).format("DD.MM.YYYY") : ""}</span>,
          },
          {
            id: "apkDate",
            Header: t("apk_date").message || "APK date",
            accessor: d => <span>{d.apk_date_dms ? moment(d.apk_date_dms).format("DD.MM.YYYY") : ""}</span>,
          },
          {
            id: "huDateDms",
            Header: t("hu_date_dms").message || "HU date dms",
            accessor: d => <span>{d.hu_date_dms ? moment(d.hu_date_dms).format("DD.MM.YYYY") : ""}</span>,
            maxWidth: 90,
          },
        ]}
      />
    );
  };

  return (
    <div
      className="CustomerEntities"
      style={{
        padding: "1em",
      }}
    >
      <Tab
        style={{
          padding: "0",
        }}
        panes={_panes}
        grid={{
          paneWidth: 16,
          tabWidth: 8,
        }}
      />
    </div>
  );
};

export default withTranslation()(CustomerEntities);
