import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, Grid, Button, Icon, Checkbox } from "semantic-ui-react";
import { withTranslation } from "react-i18next";

import Can from "../Can";
import { setAlert } from "../App/store";
import { SubHeader, ColorPicker, UserInput } from "./../../components";
import CustomConfirm from "../../components/CustomConfirm";

import Service from "./service";

const newTag = {
  name: "",
  color: "#FFFFFF",
  system: false,
};

class Tags extends Component {
  state = {
    tags: [],
    isLoadingTags: false,
    isSubmit: false,
    newTag: {
      name: "",
      color: "#e91e63",
      system: false,
    },
    currentTag: "",
    showConfirmation: false,
    deleteTagError: "",
    isCreatingTag: false,
    isDeletingTag: false,
  };

  componentDidMount() {
    this.getTags();
  }

  getTags = () => {
    if (this.state.isLoadingTags) {
      return;
    }
    this.setState(
      {
        isLoadingTags: true,
      },
      () => {
        Service.getTags()
          .then(result => {
            if (result && result.data && result.data.data) {
              this.setState({
                tags: result.data.data.tags || [],
                isLoadingTags: false,
              });
            }
          })
          .catch(error => {
            console.error("Error getting tags.", error);
            this.setState({ tags: [], isLoadingTags: false });
            this.props.setAlert({
              type: "error",
              title: error.message,
            });
          });
      }
    );
  };

  createTag = tag => {
    if (!this.state.isLoadingTags) {
      this.setState({ isLoadingTags: true, isCreatingTag: true }, () => {
        Service.createTag(tag)
          .then(result => {
            tag.id = result.data.data;
            let tags = this.state.tags;
            tags.push(tag);
            this.setState({
              tags: tags,
              isLoadingTags: false,
              isSubmit: false,
              newTag,
              isCreatingTag: false,
            });
          })
          .catch(err => {
            console.error(err);
            this.setState({ isLoadingTags: false, isCreatingTag: false });
            this.props.setAlert({
              type: "error",
              title: err.message,
            });
          });
      });
    }
  };

  updateTags = tags => {
    if (!this.state.isLoadingTags) {
      this.setState({ isLoadingTags: true }, () => {
        Service.updateTags({ tags })
          .then(result => {
            this.setState({
              isLoadingTags: false,
              isSubmit: false,
              newTag,
            });
          })
          .catch(err => {
            console.error(err);
            this.setState({ isLoadingTags: false });
            this.props.setAlert({
              type: "error",
              title: err.message,
            });
          });
      });
    }
  };

  deleteTag = () => {
    let id = this.state.currentTag;

    if (!this.state.isLoadingTags) {
      this.setState({ isLoadingTags: true, deleteTagError: "", isDeletingTag: true }, () => {
        Service.deleteTag({ id })
          .then(() => {
            let tags = this.state.tags;
            let i = tags.findIndex(t => {
              return t.id === id;
            });
            if (i > -1) {
              tags.splice(i, 1);
            }

            this.setState({
              tags: tags,
              isLoadingTags: false,
              isSubmit: false,
              newTag,
              isDeletingTag: false,
              showConfirmation: false,
              currentTag: "",
            });
          })
          .catch(err => {
            console.log(err);
            const errMsg = err?.response?.data?.errors?.length ? err.response.data.errors[0] : "unknown error occured";
            this.setState({ isLoadingTags: false, deleteTagError: errMsg, isDeletingTag: false });
          });
      });
    }
  };

  handleNewChange = (event, data) => {
    let { newTag } = this.state;
    newTag.name = data.value;
    this.setState({ newTag });
  };

  handleNewColorChange = color => {
    let { newTag } = this.state;
    newTag.color = color;
    this.setState({ newTag });
  };

  handleNewCheckboxChange = (event, data) => {
    let { newTag } = this.state;
    newTag.system = data.checked;
    this.setState({ newTag });
  };

  handleInputChanged = (event, data) => {
    const { tags } = this.state;
    const i = tags.findIndex(x => x.id === data.id);
    if (i !== -1) {
      tags[i].name = data.value.replace(" ", "_").toLowerCase();
      this.setState({ tags });
    }
  };

  handleColorChange = (color, id) => {
    const { tags } = this.state;
    const i = tags.findIndex(x => x.id === id);
    if (i !== -1) {
      tags[i].color = color;
      this.setState({ tags });
    }
  };

  handleCheckboxChange = (data, tag) => {
    const { tags } = this.state;
    const i = tags.findIndex(x => x.id === tag.id);
    if (i !== -1) {
      tags[i].system = data.checked;
      this.setState({ tags });
    }
  };

  handleSave = () => {
    this.updateTags(this.state.tags);
  };

  handleAddNewTag = () => {
    let { newTag } = this.state;

    this.setState({ isSubmit: true }, () => {
      if (newTag.name && newTag.color) {
        newTag.name = newTag.name.replace(" ", "_").toLowerCase();
        this.createTag(newTag);
      }
    });
  };

  handleRemoveTag = id => {
    this.deleteTag(id);
  };

  renderTags = tags => {
    return (
      tags &&
      tags.map((i, k) => {
        return (
          <Form.Group key={k}>
            <Form.Field width={13}>
              <UserInput type="text" placeholder="Name" id={i.id} value={i.name} onChange={this.handleInputChanged} />
            </Form.Field>

            <Form.Field width={1} style={{ textAlign: "center" }}>
              <ColorPicker id={i.id + "color"} className="-margin-top-5" color={i.color} onChange={color => this.handleColorChange(color, i.id)} />
            </Form.Field>

            <Form.Field width={1}>
              <Checkbox
                className="-margin-top-10"
                label={this.props.t("system").message || "System"}
                name="system"
                checked={i.system}
                onChange={(e, data) => this.handleCheckboxChange(data, i)}
              />
            </Form.Field>

            <Can I="delete" the="tags">
              <Form.Field width={1} style={{ textAlign: "center" }}>
                <Button color="red" icon onClick={() => this.handleShowConfirmation(i.id)}>
                  <Icon name="trash" />
                </Button>
              </Form.Field>
            </Can>
          </Form.Group>
        );
      })
    );
  };

  handleShowConfirmation = tag_id => {
    this.setState({ currentTag: tag_id, showConfirmation: true, deleteTagError: "" });
  };

  handleCancelDelete = () => {
    this.setState({ showConfirmation: false, deleteTagError: "" });
  };

  render() {
    const { tags, isLoadingTags, newTag, isSubmit } = this.state;

    return (
      <div className="TagsPage page_inner_wrapper">
        <CustomConfirm
          type="danger"
          isOpen={this.state.showConfirmation}
          handleCancel={this.handleCancelDelete}
          handleConfirm={this.deleteTag}
          isLoading={this.state.isDeletingTag}
          confirmMsg={this.props.t("delete_tags_confirmation").message || "Deleting tags deletes all uses of tags on Questions and Checklists as well."}
          error={this.state.deleteTagError}
        />

        <SubHeader>
          <Grid.Column>
            <h1>{this.props.t("tags").message || "Tags"}</h1>
          </Grid.Column>
        </SubHeader>
        <div style={{ marginTop: "20px" }}>
          <Grid className="-contained-large">
            <Grid.Column width={16}>
              <Form>
                <Form.Group>
                  <Form.Field width={13}>
                    <UserInput
                      type="text"
                      placeholder={this.props.t("tag").message || "Tag"}
                      name="name"
                      value={newTag.name}
                      onChange={this.handleNewChange}
                      error={isSubmit && !newTag.name.length > 0}
                    />
                  </Form.Field>

                  <Form.Field width={1} style={{ textAlign: "center" }}>
                    <ColorPicker className="-margin-top-5" color={newTag.color} onChange={this.handleNewColorChange} />
                  </Form.Field>

                  <Form.Field width={1}>
                    <Checkbox
                      className="-margin-top-10"
                      label={this.props.t("system").message || "System"}
                      name="system"
                      checked={newTag.system}
                      onChange={this.handleNewCheckboxChange}
                    />
                  </Form.Field>

                  <Form.Field width={1}>
                    <Button onClick={this.handleAddNewTag} loading={this.state.isCreatingTag} disabled={this.state.isCreatingTag} color="green">
                      {this.props.t("add").message || "Add"}
                    </Button>
                  </Form.Field>
                </Form.Group>
              </Form>
            </Grid.Column>
          </Grid>
          {tags && tags.length > 0 && (
            <Grid className="-contained-large" style={{ background: "#fff" }}>
              <Grid.Column width={16}>
                <Form>
                  {this.renderTags(tags)}
                  <Form.Group>
                    <Form.Field width={15} />
                    <Form.Field width={1}>
                      <Button color="green" disabled={isLoadingTags} loading={isLoadingTags} onClick={this.handleSave}>
                        {this.props.t("save").message || "Save"}
                      </Button>
                    </Form.Field>
                  </Form.Group>
                </Form>
              </Grid.Column>
            </Grid>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    setAlert: alertOptions => dispatch(setAlert(alertOptions)),
  };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Tags));
