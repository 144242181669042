import React, { Component } from "react";
import { Form } from "semantic-ui-react";

import { UserInput } from "../../components";

class KeyloopConfiguration extends Component {
  handleInputChange = e => {
    let { dealer, handleChange } = this.props;

    if (Object.keys(dealer).length === 0) return;

    dealer[e.target.name] = e.target.value;

    handleChange(dealer);
  };

  render() {
    const { dealer, t } = this.props;

    return (
      <div>
        <Form>
          <Form.Field className="full-width-input">
            <label>{t("contract_code").message || "Contract code"}</label>

            <UserInput
              defaultValue={dealer.keyloop_contract_code}
              name="keyloop_contract_code"
              placeholder={t("contract_code").message || "Contract code"}
              onChange={this.handleInputChange}
            />
          </Form.Field>
        </Form>
      </div>
    );
  }
}

export default KeyloopConfiguration;
