import React, { Component } from "react";

import DatePicker from "react-datepicker";
import { Icon } from "semantic-ui-react";
import moment from "moment";

import UserInput from "../UserInput";

import "./index.css";

class DateRange extends Component {
  render() {
    const { clearable, dateFrom, dateTo, onChange, maxDate, placeholder } = this.props;

    return (
      <div className="custom-datepicker-wrapper">
        <DatePicker
          selectsRange
          startDate={dateFrom}
          endDate={dateTo}
          value={`${dateFrom ? moment(dateFrom).format("DD-MM-YYYY") : ""}${dateTo ? " - " + moment(dateTo).format("DD-MM-YYYY") : ""}`}
          onChange={onChange}
          maxDate={maxDate || moment(dateFrom).add(12, "M").toDate()}
          shouldCloseOnSelect={false}
          placeholderText={placeholder}
          customInput={<CustomDateRange onClear={() => onChange([null, null])} clearable={clearable} placeholder={placeholder} />}
        />
      </div>
    );
  }
}

class CustomDateRange extends Component {
  render() {
    const { onClick, onClear, value, className, placeholder, clearable } = this.props;

    return (
      <div className="daterange-input-container">
        <UserInput onClick={onClick} value={value} className={className} placeholder={placeholder} />
        {clearable && value && <Icon name="x" onClick={onClear} />}
      </div>
    );
  }
}

export default DateRange;
