import axios from "axios";

import ENV from "../../util/env-config";
import { getSessionCookie } from "../../util/common";

class Service {
  static requestConfig() {
    return {
      headers: {
        Authorization: `Bearer ${getSessionCookie()}`,
        Pragma: "no-cache",
      },
    };
  }

  static getElements() {
    return axios.get(`${ENV.apiBaseB}/question_elements/list`, this.requestConfig());
  }

  static createElement(question_element) {
    return axios.post(`${ENV.apiBaseB}/question_elements/create`, question_element, this.requestConfig());
  }

  static saveElement(REQUEST_DATA) {
    return axios.post(`${ENV.apiBaseB}/question_elements/update`, REQUEST_DATA, this.requestConfig());
  }

  static deleteElement(REQUEST_DATA) {
    return axios.post(`${ENV.apiBaseB}/question_elements/delete`, REQUEST_DATA, this.requestConfig());
  }
}

export default Service;
