const ACTIONS = {
  LOAD_KEYLOCKERS: 1,
  EDIT_MODAL_SHOW: 2,
  EDIT_MODAL_SAVE: 3,
  ACTIVATE_KEYLOCKER: 4,
  DEACTIVATE_KEYLOCKER: 5,
  CONFIRM_DELETE_KEYLOCKER: 6,
  DELETE_KEYLOCKER: 7,
  GET_KEYLOCKER_DETAILS: 8,
  DETAILS_MODAL_SHOW: 9,
  ACTIVATE_BOX: 10,
  DEACTIVATE_BOX: 11,
  CONFIRM_EMPTY_BOX: 12,
  CONFIRM_GET_ADMIN_PIN: 13,
  EMPTY_BOX: 14,
  GET_ADMIN_PIN: 15,
  ADMIN_PIN_MODAL_SHOW: 16,
  KEYLOCKER_CREATED: 17,
};

export default ACTIONS;
