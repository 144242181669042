import React from "react";
import { Form } from "semantic-ui-react";

import { UserInput } from "../../../components";

const QuestionElementSpinner = ({ configuration, configurationChanged, id }) => {
  return (
    <div className="QuestionElementSpinner">
      <Form.Field>
        <UserInput key={"label" + id} name="label" placeholder="Label" defaultValue={configuration.label} onChange={configurationChanged} />
      </Form.Field>
      <Form.Field>
        <UserInput key={"unit" + id} name="unit" placeholder="Unit" defaultValue={configuration.unit} onChange={configurationChanged} />
      </Form.Field>
      <Form.Field>
        <UserInput key={"digits" + id} name="digits" placeholder="Digits" defaultValue={configuration.digits} onChange={configurationChanged} />
      </Form.Field>
      <Form.Field>
        <UserInput key={"decimals" + id} name="decimals" placeholder="Decimals" defaultValue={configuration.decimals} onChange={configurationChanged} />
      </Form.Field>
    </div>
  );
};

export default QuestionElementSpinner;
