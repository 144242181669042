// @flow
/*
    Timer
        component to display times (timeslots) with live countdowns (T-) until each given time has arrived(passed/expired, ie. T=0)
*/
import React, { Component } from "react";
import moment from "moment";

import "./Timer.css";

class Timer extends Component {
  state = {
    timesSorted: [],
    timesFormatted: [],
    endingSoon: [],
    mainTimerID: null,
    timerIDs: [],
  };

  componentWillUnmount() {
    this.clearMainTimerInterval();
  }

  componentWillReceiveProps = nextProps => {
    if (!nextProps.times) {
      return;
    }

    this.setupTimers(nextProps.times);
  };

  clearMainTimerInterval = () => {
    if (this.state.mainTimerID) {
      clearInterval(this.state.mainTimerID);
      this.setState({ mainTimerID: null });
    }
  };

  setupTimers = times => {
    this.clearMainTimerInterval();
    this.clearTimerIntervals();

    times.sort(function (a, b) {
      if (a.deadline > b.deadline) return -1;
      if (a.deadline < b.deadline) return 1;
      return 0;
    });

    this.setState({ timesSorted: times });
    this.setupTimeSlots(times);

    let mainTimerID = setInterval(() => {
      this.setupTimeSlots(times);
    }, 60000);

    this.setState({ mainTimerID });
  };

  setupTimeSlots = times => {
    let formatted = [];
    let endingSoon = [];
    let now = moment();

    // eslint-disable-next-line
    times.map((slot, index) => {
      if (!slot.active) {
        // eslint-disable-next-line
        return;
      }

      let thenA = moment(slot.deadline);
      let timeValue = thenA.format("HH:mm");
      let then = moment(timeValue, "HH:mm");
      let color_class = "";
      let is_soon = false;

      if (now.isAfter(moment(timeValue, "HH:mm:ss"))) {
        color_class = "timeslot-passed";
        timeValue = "00:00";
      } else if ((then.diff(now, "minutes") > 0 && then.diff(now, "minutes") < 30) || (then.diff(now, "seconds") > 0 && then.diff(now, "seconds") <= 60)) {
        let diff = moment.duration(then.diff(now));
        let seconds = diff.seconds() >= 10 ? diff.seconds() : "0" + diff.seconds();
        timeValue = "-" + diff.minutes() + ":" + seconds;
        color_class = "timeslot-soon";
        is_soon = true;
      }

      formatted.push({ value: timeValue, color_class: color_class, is_soon: is_soon });

      if (is_soon) {
        endingSoon.push({
          id: formatted.length - 1,
          deadline: slot.deadline,
        });
      }
    });

    this.setState({ timesFormatted: formatted, endingSoon });

    const HAS_NON_EXPIRED = formatted.find(function (t) {
      return t.color_class !== "timeslot-passed";
    });

    if (!HAS_NON_EXPIRED) {
      // All timers have expired
      this.clearMainTimerInterval();
      this.clearTimerIntervals();
      return;
    }

    this.setCountdownTimer();
  };

  setCountdownTimer = () => {
    if (!this.state.endingSoon || this.state.endingSoon.length === 0) {
      return;
    }

    let timerIDs = [];

    this.state.endingSoon
      // eslint-disable-next-line
      .map(slot => {
        let cd = moment(slot.deadline);
        let cdd = cd.format("HH:mm");
        let countDownDate = moment(cdd, "HH:mm");

        let x = setInterval(() => {
          let now = new Date().getTime();
          let diff = countDownDate - now;
          let min = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
          let sec = Math.floor((diff % (1000 * 60)) / 1000);
          let val = "-";

          min >= 10 ? (val += min) : (val += "0" + min);
          sec >= 10 ? (val += ":" + sec) : (val += ":0" + sec);
          let el = document.getElementById("countdown-" + slot.id);
          if (el) el.innerHTML = val;

          if (diff < 0) {
            clearInterval(x);
            if (el) el.innerHTML = "00:00";
            if (el) el.classList.add("timeslot-passed");
          }
        }, 1000);

        timerIDs.push(x);
      });

    this.setState({ timerIDs });
  };

  clearTimerIntervals = () => {
    if (this.state.timerIDs && this.state.timerIDs.length > 0) {
      this.state.timerIDs
        // eslint-disable-next-line
        .map(id => {
          clearInterval(id);
        });

      this.setState({ timerIDs: [] });
    }
  };

  render() {
    let shouldRender = !(!this.props.times || this.props.times.length === 0 || !this.state.timesFormatted || this.state.timesFormatted.length === 0);

    if (!shouldRender) {
      return "";
    }

    const SLOTS = this.state.timesFormatted;

    return (
      <div className="Timer">
        {SLOTS.map((s, k) => {
          let id = s.is_soon ? `countdown-${k}` : `static-${k}`;
          return (
            <div key={k} id={id} className={`Timer__Slot ${s.color_class}`}>
              {s.value}
            </div>
          );
        })}
      </div>
    );
  }
}

export default Timer;
