import { connect } from "react-redux";

import Appointments from "./Appointments";
import {
  applyUpdateToAppointment,
  hideTitleBarNotif,
  showTitleBarNotif,
  noUpdateToApply,
  deselectAppointment,
  filterAppointments,
  getAppointment,
  getAppointments,
  resetStatusFilters,
  searchAppointments,
  statusFilterClicked,
  carMakesClicked,
  updateAppointment,
  getChecks,
  iconFilterClicked,
  appointmentDateTimeClicked,
  nameColumnClicked,
  updatedByColumnClicked,
  getAppointmentByWo,
  clearAppointments,
  handleUpdateAppointments,
  woFilterSelect,
} from "./store";
import { getCar, deselectCar } from "./../Cars/store";
import { setAlert, selectDealer, selectLocation } from "../App/store";
import { setWebSocketAppointmentID } from "../WebSocket/store";

const mapStateToProps = state => {
  let appointmentsState = state.appointments;
  appointmentsState.isLoadingAppointment = false; //IE fix

  return { authState: state.auth, globalState: state.global, carsState: state.cars, appointmentsState };
};

const mapDispatchToProps = dispatch => {
  return {
    applyUpdateToAppointment: (updates, skipMainTableUpdate) => dispatch(applyUpdateToAppointment(updates, skipMainTableUpdate)),
    hideTitleBarNotif: () => dispatch(hideTitleBarNotif()),
    showTitleBarNotif: () => dispatch(showTitleBarNotif()),
    setWebSocketAppointmentID: id => dispatch(setWebSocketAppointmentID(id)),
    noUpdateToApply: () => dispatch(noUpdateToApply()),
    deselectAppointment: () => dispatch(deselectAppointment()),
    deselectCar: () => dispatch(deselectCar()),
    filterAppointments: filters => dispatch(filterAppointments(filters)),
    getAppointmentByWo: (dmsnr, location_id) => dispatch(getAppointmentByWo(dmsnr, location_id)),
    getAppointment: appID => dispatch(getAppointment(appID)),
    getAppointments: (filters, shouldClearAppointments) => dispatch(getAppointments(filters, shouldClearAppointments)),
    getCar: carID => dispatch(getCar(carID)),
    getChecks: appID => dispatch(getChecks(appID)),
    resetStatusFilters: () => dispatch(resetStatusFilters()),
    searchAppointments: filters => dispatch(searchAppointments(filters)),
    selectDealer: dealer => dispatch(selectDealer(dealer)),
    selectLocation: location => dispatch(selectLocation(location)),
    setAlert: alertOptions => dispatch(setAlert(alertOptions)),
    statusFilterClicked: status => dispatch(statusFilterClicked(status)),
    updateAppointment: requestData => dispatch(updateAppointment(requestData)),
    carMakesClicked: car => dispatch(carMakesClicked(car)),
    iconFilterClicked: icon => dispatch(iconFilterClicked(icon)),
    appointmentDateTimeClicked: dateTime => dispatch(appointmentDateTimeClicked(dateTime)),
    nameColumnClicked: columnName => dispatch(nameColumnClicked(columnName)),
    updatedByColumnClicked: columnOption => dispatch(updatedByColumnClicked(columnOption)),
    handleUpdateAppointments: appointment => dispatch(handleUpdateAppointments(appointment)),
    woFilterSelect: woFilterSelected => dispatch(woFilterSelect(woFilterSelected)),
    clearAppointments: () => dispatch(clearAppointments()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Appointments);
