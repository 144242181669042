import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { HashRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/es/integration/react";
import moment from "moment";
import locale_en from "moment/locale/en-gb";
import fetchIntercept from "fetch-intercept";
import axios from "axios";
import { Helmet } from "react-helmet";

import { getSessionCookie } from "./util/common";
import configureStore from "./configureStore";
import Root from "./Root";
import { isLocalhost, unregisterServiceWorker } from "./registerServiceWorker";
import setupInterceptors from "./setupInterceptors";
import "./util/i18n";
import "./util/helper";

import ENV from "./util/env-config";
import APP_CONFIG from "./util/app-config";

import "semantic-ui-css/semantic.min.css";
import "./styles/semantic-overrides.css";
import "./styles/index.css";
import "./styles/helpers.css";

if ((window.location.protocol !== "https:" && !isLocalhost) || window.location.host.startsWith("www.")) {
  window.location.replace(window.location.href.replace("http://", "https://").replace("://www.", "://"));
}

const getLastUserID = () => {
  let t,
    ID = "";
  const LU = JSON.parse(localStorage.getItem("_cl-last-user"));

  if (LU && LU.id) {
    t = new Date().getTime().toString();
    ID = LU.id;
  }

  return { lastUserID: ID, timestamp: t };
};

const setDefaultHeaders = ({ lastUserID, timestamp }) => {
  axios.defaults.headers.common["X-Claire-ClientID"] = lastUserID + "" + timestamp;
  axios.defaults.headers.common["X-Claire-Clientversion"] = APP_CONFIG.version_client;
  axios.defaults.headers.common["X-Claire-Clientplatform"] = "web";
};

// App setup
const { lastUserID, timestamp } = getLastUserID();
setDefaultHeaders({ lastUserID, timestamp });
moment.locale("en", locale_en);
const { persistor, store } = configureStore();
setupInterceptors(store);

fetchIntercept.register({
  request: function (req, config) {
    if (req.url.includes("twirp/claireit")) {
      return [
        new Request(req, {
          headers: {
            Authorization: `Bearer ${getSessionCookie()}`,
            "Content-Type": "application/json",
            "X-Claire-ClientID": lastUserID + "" + timestamp,
            "X-Claire-Clientversion": APP_CONFIG.version_client,
            "X-Claire-Clientplatform": "web",
          },
        }),
        config,
      ];
    }

    return [req, config];
  },
});

ReactDOM.render(
  <Provider store={store}>
    <GoogleOAuthProvider clientId={ENV.clientID}>
      <PersistGate persistor={persistor}>
        <Helmet>
          <title>{APP_CONFIG.title}</title>
        </Helmet>
        <HashRouter hashType="hashbang">
          <Root persistor={persistor} />
        </HashRouter>
      </PersistGate>
    </GoogleOAuthProvider>
  </Provider>,
  document.getElementById("root")
);

unregisterServiceWorker();

// IE CustomEvent polyfill
const addCustomEventPolyfill = () => {
  if (typeof window.CustomEvent === "function") {
    return false;
  }

  function CustomEvent(event, params) {
    params = params || {
      bubbles: false,
      cancelable: false,
      detail: undefined,
    };

    var evt = document.createEvent("CustomEvent");
    evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
    return evt;
  }

  CustomEvent.prototype = window.Event.prototype;
  window.CustomEvent = CustomEvent;
};

addCustomEventPolyfill();

const isElectron = () => {
  // Renderer process
  if (typeof window !== "undefined" && typeof window.process === "object" && window.process.type === "renderer") {
    return true;
  }

  // Main process
  if (typeof process !== "undefined" && typeof process.versions === "object" && !!process.versions.electron) {
    return true;
  }

  // Detect the user agent when the `nodeIntegration` option is set to true
  return typeof navigator === "object" && typeof navigator.userAgent === "string" && navigator.userAgent.indexOf("Electron") >= 0;
};

const checkBrowserUpdate = () => {
  if (isElectron()) return;

  window.$buoop = { required: { e: -4, i: 11, f: -3, o: -3, s: -1, c: -3 }, insecure: false, api: 2019.1 };
  function $buo_f() {
    var e = document.createElement("script");
    e.src = "//browser-update.org/update.min.js";
    document.body.appendChild(e);
  }
  try {
    document.addEventListener("DOMContentLoaded", $buo_f, false);
  } catch (e) {
    window.attachEvent("onload", $buo_f);
  }
};

checkBrowserUpdate();
