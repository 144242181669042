const Modal = {
  show(params) {
    let modalStyle =
      "font-family:sans-serif; " +
      "display:table; " +
      "text-align:center; " +
      "font-weight:300; " +
      "font-size:30px; " +
      "left:0; top:0;" +
      "position:fixed; " +
      "z-index: 9990;" +
      "color: #0460B5; " +
      "width: 100%; " +
      "height: 100%; " +
      "background-color:rgba(255,255,255,.9);" +
      "transition: opacity .3s ease;";

    let printModal = document.createElement("div");
    printModal.setAttribute("style", modalStyle);
    printModal.setAttribute("id", "printJS-Modal");

    let contentDiv = document.createElement("div");
    contentDiv.setAttribute("style", "display:table-cell; vertical-align:middle; padding-bottom:100px;");

    let closeButton = document.createElement("div");
    closeButton.setAttribute("class", "printClose");
    closeButton.setAttribute("id", "printClose");
    contentDiv.appendChild(closeButton);

    let spinner = document.createElement("span");
    spinner.setAttribute("class", "printSpinner");
    contentDiv.appendChild(spinner);

    let messageNode = document.createTextNode(params.modalMessage);
    contentDiv.appendChild(messageNode);

    printModal.appendChild(contentDiv);

    document.getElementsByTagName("body")[0].appendChild(printModal);

    document.getElementById("printClose").addEventListener("click", function() {
      Modal.close();
    });
  },
  close() {
    let printFrame = document.getElementById("printJS-Modal");

    printFrame.parentNode.removeChild(printFrame);
  },
};

export default Modal;
