import axios from "axios";

import ENV from "../../../util/env-config";
import { getSessionCookie } from "../../../util/common";

class Service {
  static requestConfig() {
    return {
      headers: {
        Authorization: `Bearer ${getSessionCookie()}`,
      },
    };
  }

  static getDealers() {
    return axios.post(`${ENV.dmsv3BaseUrl}/config/dealer/list`, {}, this.requestConfig());
  }

  static addDealer(requestData) {
    return axios.post(`${ENV.dmsv3BaseUrl}/config/dealer/create`, requestData, this.requestConfig());
  }

  static deleteDealer(requestData) {
    return axios.post(`${ENV.dmsv3BaseUrl}/config/dealer/delete`, requestData, this.requestConfig());
  }

  static updateDealer(requestData) {
    return axios.post(`${ENV.dmsv3BaseUrl}/config/dealer/update`, requestData, this.requestConfig());
  }

  static addOrUpdateLocation(requestData) {
    return axios.post(`${ENV.dmsv3BaseUrl}/config/location/update`, requestData, this.requestConfig());
  }
}

export default Service;
