import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import ReactTable, { ReactTableDefaults } from "react-table";
import { Dropdown } from "semantic-ui-react";

import { SEASONS } from "../Appointments/common";
import { getTireSeasonName } from "../../util/common";

class TireTable extends Component {
  render() {
    const { onGetTires, onFilterTires, onSeasonChange, onRowClick, tires, page, nb_items, nb_pages, loading, t } = this.props;

    return (
      <ReactTable
        className="ReactTable -floated-table -contained-large"
        showPageSizeOptions={false}
        pageSize={nb_items}
        data={tires}
        page={page - 1}
        pages={nb_pages}
        minRows={tires.length}
        showPagination={nb_pages > 1}
        sortable={false}
        filterable={true}
        loading={loading}
        noDataText={
          <div className="Table__no-results">
            <p>{t("no_tires").message || "No tires"}</p>
          </div>
        }
        renderPageJump={({ onChange, value, onBlur, onKeyPress, inputType, pageJumpText }) => (
          <div className="-pageJump">
            <input
              aria-label={pageJumpText}
              type={inputType}
              onChange={evt => {
                onChange(evt);

                const newPage = evt.target.value - 0;
                if (!Number.isNaN(newPage) && newPage > 0 && newPage <= nb_pages) onGetTires(newPage);
              }}
              value={page}
              onBlur={onBlur}
              onKeyPress={onKeyPress}
            />
          </div>
        )}
        nextText={t("next").message || "Next"}
        previousText={t("previous").message || "Previous"}
        pageText={t("page").message || "Page"}
        ofText={t("of").message || "of"}
        getTdProps={(_state, rowInfo) => ({
          onClick: () => onRowClick({ mode: "edit", selectedTire: rowInfo.original }),
        })}
        manual
        onFilteredChange={(_arr, { id }, value) => onFilterTires(id, value)}
        onFetchData={state => onGetTires(state.page + 1)}
        onPageChange={() => {
          try {
            document.querySelector(".App__module").scrollTo(0, 0);
          } catch (e) {
            document.querySelector(".App__module").scrollTop = 0; // IE Fix
          }
        }}
        column={{
          ...ReactTableDefaults.column,
          headerClassName: "ReactTable__column-header",
          className: "ReactTable__column",
        }}
        columns={[
          {
            id: "manufacturer",
            Header: t("manufacturer").message || "Manufacturer",
            accessor: "manufacturer",
          },

          {
            id: "description",
            Header: t("description").message || "Description",
            accessor: "description",
          },

          {
            id: "ean",
            Header: "EAN",
            accessor: "ean",
          },

          {
            id: "carry_capacity",
            Header: t("carry_capacity").message || "Carry capacity",
            accessor: "carry_capacity",
          },

          {
            id: "width",
            Header: "W",
            accessor: "width",
          },

          {
            id: "height",
            Header: "H",
            accessor: "height",
          },

          {
            id: "size",
            Header: "S",
            accessor: "size",
          },

          {
            id: "speed",
            Header: t("speed").message || "Speed",
            accessor: "speed",
          },

          {
            id: "season",
            Header: t("season").message || "Season",
            accessor: d => getTireSeasonName(d.season, t),
            headerClassName: "-overflow-inherit -border-right-none",
            Filter: (
              <Dropdown
                fluid
                selection
                clearable
                className="season-dropdown"
                options={[
                  { key: 1, value: SEASONS.NO_SEASON, text: t("no_season").message || "No season" },
                  { key: 2, value: SEASONS.ALL_SEASONS, text: t("all_seasons").message || "All seasons" },
                  { key: 3, value: SEASONS.WINTER, text: t("winter").message || "Winter" },
                  { key: 4, value: SEASONS.SUMMER, text: t("summer").message || "Summer" },
                  { key: 5, value: SEASONS.NOT_AVAILABLE, text: t("not_available").message || "Not available" },
                ]}
                onChange={onSeasonChange}
              />
            ),
          },
        ]}
      />
    );
  }
}

export default withTranslation()(TireTable);
