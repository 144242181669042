import React from "react";
import { Dropdown, Form } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import moment from "moment";
import DatePicker from "react-datepicker";

import DealerLocationsDropdown from "../../../components/DealerLocationsDropdown";

const CarFilters = ({ customDateFrom, onChangeCustomDates, customDateTo, onChangeSelectedLocationIDs, onChangeIsLease, selectedIsLease, shouldReset, t }) => {
  const leaseOptions = [
    {
      key: "lease",
      text: t("lease").message || "Lease",
      value: true,
    },
    {
      key: "no_lease",
      text: t("no_lease").message || "No lease",
      value: false,
    },
  ];

  return (
    <Form>
      <Form.Group>
        <Form.Field width={4}>
          <DealerLocationsDropdown selectAll={false} selectDealers={true} onChange={onChangeSelectedLocationIDs} shouldReset={shouldReset} />
        </Form.Field>

        <Form.Field width={5} style={{ position: "relative" }}>
          <DatePicker
            selectsRange
            startDate={customDateFrom}
            endDate={customDateTo}
            value={`${moment(customDateFrom).format("DD-MM-YYYY")}${customDateTo ? " - " + moment(customDateTo).format("DD-MM-YYYY") : ""}`}
            onChange={onChangeCustomDates}
            maxDate={moment(customDateFrom).add(12, "M").toDate()}
            shouldCloseOnSelect={false}
          />
        </Form.Field>
        <Form.Field width={2}>
          <Dropdown
            className="wide-dropdown"
            selectOnBlur={false}
            placeholder={t("company_type").message || "Company type"}
            value={selectedIsLease}
            onChange={(e, data) => onChangeIsLease(data.value)}
            selection
            fluid
            clearable
            options={leaseOptions}
          />
        </Form.Field>
      </Form.Group>
    </Form>
  );
};

export default withTranslation()(CarFilters);
