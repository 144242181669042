import React from "react";
import { Label, Icon } from "semantic-ui-react";
import { isWarrantyPinSignatureMissing } from "./util";

const PinNotification = ({ appointment, location }) => {
  if (!appointment.recall_pin_count && !appointment.remark_pin_count && !appointment.warranty_pin_count) return null;

  const getPinColor = () => {
    if (isWarrantyPinSignatureMissing(appointment, location)) return "red";

    if (location.is_warranty_pin_support_warning_enabled && appointment.warranty_pin_support_nr_missing_count) return "orange";
    if (location.is_warranty_pin_claim_warning_enabled && appointment.warranty_pin_claim_nr_missing_count) return "orange";

    if (location.is_recall_pin_support_warning_enabled && appointment.recall_pin_support_nr_missing_count) return "orange";
    if (location.is_recall_pin_claim_warning_enabled && appointment.recall_pin_claim_nr_missing_count) return "orange";

    return "blue";
  };

  return (
    <Label circular color={getPinColor()}>
      <Icon name={appointment.warranty_pin_count > 0 ? "shield" : "pin"} />
    </Label>
  );
};

export default PinNotification;
