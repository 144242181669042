import React from "react";
import { Popup } from "semantic-ui-react";
import { ThumbToggle, Pin } from "../../../../components";
import { renderReadyStatus } from "../../../Appointments/common";

import "./InterventionQuickView.scss";

const InterventionQuickView = ({ appointment, popupTrigger }) => {
  if (!appointment.interventions) return popupTrigger;

  const content = appointment.interventions.map(i => (
    <div className="InterventionQuickView-interventions-container" key={i.id}>
      <div className="title-description-container">
        <div className="title">{i.title}</div>
      </div>

      <div className="special-indicators-container">
        <Pin disabled appointment={appointment} intervention={i} pinLog={i.pin_history || []} />
        <ThumbToggle status={i.customer_ok} onClick={null} />
        <span className="AnswerReadyStatus">{i.customer_ok && renderReadyStatus(i.mechanic_fixed)}</span>
      </div>
    </div>
  ));

  return (
    <Popup
      trigger={popupTrigger}
      children={<div className="InterventionQuickViewContent">{content}</div>}
      hoverable
      style={{ padding: "0 5px" }}
      popperModifiers={{
        preventOverflow: {
          boundariesElement: "offsetParent",
        },
        flip: {
          flipVariationsByContent: true,
        },
      }}
    />
  );
};

export default InterventionQuickView;
