import React, { Component } from "react";
import { Form, Header, Icon, Segment } from "semantic-ui-react";
import { withTranslation } from "react-i18next";

import UserInput from "../UserInput";

class DmsSettings extends Component {
  handleInputChange = e => {
    let { location, onLocationChange } = this.props;
    if (Object.keys(location).length === 0) return;

    onLocationChange({ ...location, [e.target.name]: e.target.value });
  };

  render() {
    const { location, t } = this.props;

    return (
      <Segment>
        <Header as="h5">
          <Icon name="wrench" />
          <Header.Content>{t("dms_settings").message || "DMS settings"}</Header.Content>
        </Header>
        <Form>
          <Form.Field className="full-width-input">
            <label>{t("data_source").message || "Data source"}</label>
            <UserInput
              value={location.dms_location_source}
              name="dms_location_source"
              placeholder={t("data_source").message || "Data source"}
              onChange={this.handleInputChange}
            />
          </Form.Field>
        </Form>
      </Segment>
    );
  }
}

export default withTranslation()(DmsSettings);
