import Service from "./service";

const TYRE_TEAM_DISCOUNT_ACTIONS = {
  GET_DISCOUNTS: "tyreteam/GET_DISCOUNTS",
  UPDATE_DISCOUNTS: "tyreteam/UPDATE_DISCOUNTS",
};

export function getDiscounts(requestData) {
  return dispatch => {
    Service.getDiscounts(requestData)
      .then(res => {
        return dispatch({ type: TYRE_TEAM_DISCOUNT_ACTIONS.GET_DISCOUNTS, items: res?.data?.data || [] });
      })
      .catch(error => {
        console.log("Error getting tyre team discounts", error);
      });
  };
}

export function addDiscount(payload) {
  return (dispatch, getState) => {
    const items = [...getState().tyreTeamDiscount.items, payload];
    dispatch(updateDiscounts(items));
  };
}

export function addBrandDiscount(id, payload) {
  return (dispatch, getState) => {
    const items = [...getState().tyreTeamDiscount.items];
    const item = items.find(i => i.id === id);
    if (item) {
      if (!item.tyre_brand_discounts) item.tyre_brand_discounts = [];
      item.tyre_brand_discounts.push(payload);
    }

    dispatch(updateDiscounts(items));
  };
}

export function updateDiscount(payload) {
  return (dispatch, getState) => {
    const items = [...getState().tyreTeamDiscount.items];
    const itemIDX = items.findIndex(i => i.id === payload.id);
    if (items[itemIDX].tyre_brand_discounts) payload.tyre_brand_discounts = items[itemIDX].tyre_brand_discounts;
    items[itemIDX] = payload;

    dispatch(updateDiscounts(items));
  };
}

export function updateBrandDiscount(payload) {
  return (dispatch, getState) => {
    const items = [...getState().tyreTeamDiscount.items];
    const item = items.find(i => i.id === payload.tyre_discount_id);

    const itemIDX = item.tyre_brand_discounts.findIndex(i => i.id === payload.id);
    item.tyre_brand_discounts[itemIDX] = payload;
    dispatch(updateDiscounts(items));
  };
}

export function deleteDiscount(id) {
  return (dispatch, getState) => {
    const items = [...getState().tyreTeamDiscount.items].filter(i => i.id !== id);
    dispatch(updateDiscounts(items));
  };
}

export function deleteBrandDiscount(tyre_discount_id, tyre_brand_discount_id) {
  return (dispatch, getState) => {
    const items = [...getState().tyreTeamDiscount.items];
    const item = items.find(i => i.id === tyre_discount_id);

    item.tyre_brand_discounts = item.tyre_brand_discounts.filter(i => i.id !== tyre_brand_discount_id);
    dispatch(updateDiscounts(items));
  };
}

export function updateDiscounts(items) {
  return dispatch => dispatch({ type: TYRE_TEAM_DISCOUNT_ACTIONS.UPDATE_DISCOUNTS, items });
}

const INITIAL_STATE = {
  items: [],
  actionType: "",
  loading: false,
};

const tyreTeamDiscountReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYRE_TEAM_DISCOUNT_ACTIONS.GET_DISCOUNTS: {
      return {
        ...state,
        actionType: action.type,
        items: action.items,
      };
    }

    case TYRE_TEAM_DISCOUNT_ACTIONS.UPDATE_DISCOUNTS: {
      return {
        ...state,
        actionType: action.type,
        items: action.items,
      };
    }

    default:
      return state;
  }
};

export default tyreTeamDiscountReducer;
