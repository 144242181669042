import React, { Component, createRef } from "react";
import AlertContainer from "react-alert";
import { withTranslation } from "react-i18next";
import { Button, Checkbox, Dropdown, Form, Header, Icon, Message, Modal, Segment } from "semantic-ui-react";

import UserInput from "../UserInput";
import CustomConfirm from "../CustomConfirm";

import Service from "./service";

import "./TyreTeamConfiguration.scss";

const TYRE_TEAM_CHANNEL = {
  WHEEL_TYRE: 1,
  BANDEN_EXPRESS: 2,
};

const credentialFields = {
  login: "",
  password: "",
  location_id: "",
  customer_id: "",
  channel: TYRE_TEAM_CHANNEL.WHEEL_TYRE,
};

const CREDENTIALS = {
  CREATE: 1,
  EDIT: 2,
};

class TyreTeamConfiguration extends Component {
  constructor(props) {
    super(props);

    this.state = {
      credentials: { ...credentialFields },
      credentialsList: [],
      showModal: false,
      showDeleteConfirmation: false,
      isLoading: false,
      isLoadingList: false,
      errorMsg: "",
      validationError: false,
      canValidate: false,
      isValidationLoading: false,
      mode: CREDENTIALS.CREATE,
    };

    this.msg = createRef();
    this.modalMsg = createRef();
  }

  componentDidMount() {
    this.getCredentialsList();
  }

  getCredentialsList = () => {
    this.setState({ isLoadingList: true }, async () => {
      try {
        const response = await Service.listCredentials(this.props.location.id);

        this.setState({ isLoadingList: false, credentialsList: response?.data?.data || [] });
      } catch (error) {
        const errorMsg =
          typeof error.response?.data === "string"
            ? error.response.data
            : error.response.data.errors
            ? error.response.data.errors[0]
            : "Failed to load list of Tyre team credentials";
        this.modalMsg.show(errorMsg, { type: "error" });
        this.setState({ isLoadingList: false });
      }
    });
  };

  renderAlert = () => {
    const props = {
      offset: 20,
      position: "top right",
      theme: "light",
      time: 2000,
      transition: "fade",
    };

    return <AlertContainer ref={a => (this.msg = a)} {...props} />;
  };

  renderModalAlert = () => {
    const props = {
      offset: 20,
      position: "top center",
      theme: "light",
      time: 2000,
      transition: "fade",
    };

    return <AlertContainer ref={a => (this.modalMsg = a)} {...props} />;
  };

  renderDeleteConfirmation = () => {
    const { showDeleteConfirmation, isLoading, errorMsg } = this.state;
    const { t } = this.props;

    return (
      <CustomConfirm
        type="danger"
        isOpen={showDeleteConfirmation}
        handleConfirm={this.handleDeleteCredentials}
        handleCancel={this.toggleDeleteConfirmation}
        confirmMsg={t("confirm_delete_message").message || "Are you sure that you want to delete this? You can't undo this action."}
        isLoading={isLoading}
        error={errorMsg}
      />
    );
  };

  toggleDeleteConfirmation = () => this.setState(state => ({ showDeleteConfirmation: !state.showDeleteConfirmation }));

  handleDeleteCredentials = () => {
    const { credentialsList, credentials } = this.state;

    this.setState({ isLoading: true, errorMsg: "" }, async () => {
      try {
        await Service.deleteCredential({ dealer_location_id: this.props.location.id, id: credentials.id });

        const list = credentialsList.filter(c => c.id !== credentials.id);
        this.setState({ isLoading: false, credentialsList: list, showDeleteConfirmation: false, showModal: false });
      } catch (error) {
        const errorMsg =
          typeof error.response?.data === "string"
            ? error.response.data
            : error.response.data.errors
            ? error.response.data.errors[0]
            : "Failed to delete Tyre team credentials";
        this.setState({ isLoading: false, errorMsg });
      }
    });
  };

  handleCheckboxChange = (_e, data) => this.props.onLocationChange({ ...this.props.location, [data.name]: data.checked });

  handleChange = (_e, data) => this.setState(state => ({ credentials: { ...state.credentials, [data.name]: data.value }, canValidate: false }));

  handleOpenCredentialsModal = (credentials, mode) =>
    this.setState({ validationError: false, showModal: true, credentials: credentials, mode, canValidate: mode === CREDENTIALS.EDIT });

  handleCloseCredentialsModal = () => this.setState({ showModal: false });

  handleValidateCredentials = async () => {
    const { credentials } = this.state;

    if (!credentials.id) return;

    this.setState({ isValidationLoading: true }, async () => {
      try {
        await Service.validateCredentials({ credential_id: credentials.id });

        this.setState({ isValidationLoading: false });
        this.modalMsg.show(this.props.t("tyreteam_credentials_valid").message || "TyreTeam credentials are valid", { type: "success" });
      } catch (error) {
        const errorMsg =
          typeof error.response?.data === "string"
            ? error.response.data
            : error.response.data.errors
            ? error.response.data.errors[0]
            : "Failed to validate Tyre team credentials";

        this.modalMsg.show(errorMsg, { type: "error" });
        this.setState({ isValidationLoading: false });
      }
    });
  };

  handleSaveCredentials = () => {
    const { credentials } = this.state;

    if (!credentials.login || !credentials.password || !credentials.location_id || !credentials.customer_id) {
      this.setState({ validationError: true });
      return;
    }

    this.setState({ isLoading: true }, async () => {
      if (this.state.mode === CREDENTIALS.CREATE) {
        try {
          const response = await Service.addCredential({ ...credentials, dealer_location_id: this.props.location.id });

          if (response?.data?.data) {
            this.setState(state => ({
              validationError: false,
              credentialsList: [...state.credentialsList, { ...credentials, id: response.data.data.id }],
              isLoading: false,
              showModal: false,
            }));
          }

          this.setState({ isLoading: false });
        } catch (error) {
          const errorMsg =
            typeof error.response?.data === "string"
              ? error.response.data
              : error.response.data.errors
              ? error.response.data.errors[0]
              : "Failed to add Tyre team credential";
          this.modalMsg.show(errorMsg, { type: "error" });
          this.setState({ isLoading: false, validationError: false });
        }

        return;
      }

      try {
        const { credentialsList } = this.state;

        await Service.updateCredential({ ...credentials, dealer_location_id: this.props.location.id });
        const credentialsIndex = credentialsList.findIndex(c => c.id === credentials.id);

        if (credentialsIndex > -1) {
          credentialsList[credentialsIndex] = credentials;
          this.setState({ credentialsList });
        }

        this.setState({ isLoading: false, showModal: false, validationError: false });
      } catch (error) {
        const errorMsg =
          typeof error.response?.data === "string"
            ? error.response.data
            : error.response.data.errors
            ? error.response.data.errors[0]
            : "Failed to update Tyre team credential";
        this.modalMsg.show(errorMsg, { type: "error" });
        this.setState({ isLoading: false, validationError: false });
      }
    });
  };

  getDeliveryChannel = channel => {
    switch (channel) {
      case TYRE_TEAM_CHANNEL.WHEEL_TYRE:
        return "Wheel-Tyre";

      case TYRE_TEAM_CHANNEL.BANDEN_EXPRESS:
        return "Banden Express";

      default:
        return "Unknown channel";
    }
  };

  render() {
    const { credentialsList, credentials, mode, showModal, isLoading, canValidate, isValidationLoading, validationError } = this.state;
    const { location, t } = this.props;

    return (
      <>
        <Segment>
          <Header as="h5">
            <Icon name="wrench" />
            <Header.Content>{this.props.t("tyre_team_configuration").message || "Tyre team configuration"}</Header.Content>
          </Header>
          <div className="TyreTeamConfigurationHeader">
            <Form.Field style={{ marginLeft: "4px", marginRight: 0, maxWidth: "100%" }}>
              <Checkbox
                checked={location.is_tyre_team_enabled}
                name="is_tyre_team_enabled"
                label={t("tyre_team").message || "Tyre team"}
                onChange={this.handleCheckboxChange}
              />
            </Form.Field>

            {location.is_tyre_team_enabled && (
              <Button onClick={() => this.handleOpenCredentialsModal({ ...credentialFields }, CREDENTIALS.CREATE)}>
                {t("add_new_credentials").message || "Add new credentials"}
              </Button>
            )}
          </div>

          {location.is_tyre_team_enabled && (
            <Form.Field style={{ margin: "10px 0px 10px 4px", maxWidth: "100%", display: "block" }}>
              <Checkbox
                toggle
                checked={location.is_tyre_team_autofill_price_ref_on_order}
                name="is_tyre_team_autofill_price_ref_on_order"
                label={t("autofill_price_ref_on_order").message || "Autofill price ref on order"}
                onChange={this.handleCheckboxChange}
              />
            </Form.Field>
          )}

          {location.is_tyre_team_enabled && (
            <Segment>
              {!credentialsList.length && <div className="CredentialsListContainer">{t("no_credentials_added").message || "No credentials added"}</div>}

              <div className="list_of_credentials">
                {credentialsList.map(c => (
                  <div className="TyreTeamItem" onClick={() => this.handleOpenCredentialsModal(c, CREDENTIALS.EDIT)} key={c.id}>
                    {this.getDeliveryChannel(c.channel)}
                  </div>
                ))}
              </div>
            </Segment>
          )}
        </Segment>

        <Modal size="tiny" open={showModal} onClose={this.handleCloseCredentialsModal} closeOnDimmerClick={false} className="TyreTeamCredentialsModal">
          <Modal.Header>
            {mode === CREDENTIALS.CREATE ? t("Add new credentials").message || "Add new credentials" : t("edit_credentials").message || "Edit credentials"}
          </Modal.Header>
          <Modal.Content scrolling>
            <Form>
              <Form.Field>
                <label htmlFor="channel">{t("channel").message || "Channel"}</label>
                <Dropdown
                  selection
                  name="channel"
                  options={[
                    {
                      text: "Wheel-Tyre",
                      value: TYRE_TEAM_CHANNEL.WHEEL_TYRE,
                    },
                    {
                      text: "Banden Express",
                      value: TYRE_TEAM_CHANNEL.BANDEN_EXPRESS,
                    },
                  ]}
                  value={credentials.channel}
                  onChange={this.handleChange}
                />
              </Form.Field>

              <Form.Field error={validationError && !credentials.login}>
                <label htmlFor="login">{t("login").message || "Login"} (*)</label>
                <UserInput name="login" value={credentials.login} onChange={this.handleChange} />
              </Form.Field>

              <Form.Field error={validationError && !credentials.password}>
                <label htmlFor="password">{t("password").message || "Password"} (*)</label>
                <UserInput type="password" name="password" value={credentials.password} onChange={this.handleChange} />
              </Form.Field>

              <Form.Field error={validationError && !credentials.location_id}>
                <label htmlFor="location_id">{t("location_id").message || "Location ID"} (*)</label>
                <UserInput name="location_id" value={credentials.location_id} onChange={this.handleChange} />
              </Form.Field>

              <Form.Field error={validationError && !credentials.customer_id}>
                <label htmlFor="customer_id">{t("customer_id").message || "Customer ID"} (*)</label>
                <UserInput name="customer_id" value={credentials.customer_id} onChange={this.handleChange} />
              </Form.Field>

              {validationError && (
                <Message negative>
                  <p>{t("required_fields_error").message || "Please fill all the required fields"}</p>
                </Message>
              )}
            </Form>
            {this.renderModalAlert()}
            {this.renderDeleteConfirmation()}
          </Modal.Content>
          <Modal.Actions>
            {mode === CREDENTIALS.EDIT && (
              <Button color="red" loading={isLoading} disabled={isLoading} onClick={this.toggleDeleteConfirmation} floated="left">
                <Icon name="trash" className="-no-margin" />
              </Button>
            )}

            <Button onClick={this.handleCloseCredentialsModal} color="grey">
              {t("close").message || "Close"}
            </Button>

            {mode === CREDENTIALS.EDIT && (
              <Button onClick={this.handleValidateCredentials} loading={isValidationLoading} disabled={!canValidate || isValidationLoading || isLoading} color="yellow">
                {t("validate").message || "Validate"}
              </Button>
            )}

            <Button color="green" onClick={this.handleSaveCredentials} loading={isLoading} disabled={isLoading || isValidationLoading}>
              <Icon name="checkmark" /> {mode === CREDENTIALS.EDIT ? t("update").message || "Update" : t("save").message || "Save"}
            </Button>
          </Modal.Actions>
        </Modal>

        {this.renderAlert()}
      </>
    );
  }
}

export default withTranslation()(TyreTeamConfiguration);
