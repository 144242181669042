import React from "react";
import { Icon } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCarAlt, faUserCheck, faPauseCircle } from "@fortawesome/free-solid-svg-icons";
import { faCalendarCheck, faCarGarage, faEnvelope } from "@fortawesome/pro-regular-svg-icons";
import { COMMUNICATION_STATUSES, APPOINTMENT_STATUSES } from "../../../../modules/Appointments/common";

import "./AppointmentCardStatus.scss";

const AppointmentCardStatus = ({ value, statuses, car_out_of_shop }) => {
  let status = { icon: "", color: "", name: "" };

  if (statuses?.length > 0) {
    status = statuses.find(i => i.identifier === value.id);
    if (!status) {
      status = { icon: "settings" };
    }
  }

  if (value.id === APPOINTMENT_STATUSES.CONTACT) {
    status.icon = value.customcom_status >= COMMUNICATION_STATUSES.DIAGNOSE_OVERVIEW_SENT ? "mail" : "phone";
  }

  if (status.identifier === APPOINTMENT_STATUSES.NEW_CAR) {
    return (
      <div
        className="-status-column_cell status-column-icons-container"
        style={{
          backgroundColor: status.color,
        }}
      >
        <FontAwesomeIcon title={status.name} icon={faCarAlt} className="new-car-icon" />
      </div>
    );
  }

  if (status.identifier === APPOINTMENT_STATUSES.CUSTOMER_ANSWERED) {
    return (
      <div
        className={`-status-column_cell status-column-icons-container ${car_out_of_shop ? "composite-icon" : ""}`}
        style={{
          backgroundColor: status.color,
        }}
      >
        {car_out_of_shop && <Icon name="sign-out" className="car-out-of-shop-icon" />}
        <FontAwesomeIcon className="customer-answered-icon" title={status.name} icon={faUserCheck} />
      </div>
    );
  }

  if (status.identifier === APPOINTMENT_STATUSES.CHECK_IN_DONE) {
    return (
      <div
        className={`-status-column_cell status-column-icons-container ${car_out_of_shop ? "composite-icon" : ""}`}
        style={{
          backgroundColor: status.color,
        }}
      >
        {car_out_of_shop && <Icon name="sign-out" className="car-out-of-shop-icon" />}
        <span className="fa-layers fa-fw">
          <FontAwesomeIcon icon={faCarAlt} title={status.name} />
          <FontAwesomeIcon icon={faCalendarCheck} title={status.name} className="check-in-done-second-icon" />
        </span>
      </div>
    );
  }

  if (status.identifier === APPOINTMENT_STATUSES.CAR_IN_SHOP) {
    return (
      <div
        className={`-status-column_cell status-column-icons-container ${car_out_of_shop ? "composite-icon" : ""}`}
        style={{
          backgroundColor: status.color,
        }}
      >
        <FontAwesomeIcon className="car-in-shop-icon" title={status.name} icon={faCarGarage} />
      </div>
    );
  }

  if (status.identifier === APPOINTMENT_STATUSES.CAR_OK_PLUS_REPAIR_OVERVIEW) {
    return (
      <div
        className={`-status-column_cell status-column-icons-container ${car_out_of_shop ? "composite-icon" : ""}`}
        style={{
          backgroundColor: status.color,
        }}
      >
        {car_out_of_shop && <Icon name="sign-out" className="car-out-of-shop-icon" />}
        <span className="fa-layers icons-container">
          <i className="material-icons" title={status.name}>
            {"done_all"}
          </i>
          <FontAwesomeIcon icon={faEnvelope} title={status.name} />
        </span>
      </div>
    );
  }

  if (status.identifier === APPOINTMENT_STATUSES.QUALITY_CHECK_PLUS_REPAIR_OVERVIEW) {
    return (
      <div
        className={`-status-column_cell status-column-icons-container ${car_out_of_shop ? "composite-icon" : ""}`}
        style={{
          backgroundColor: status.color,
        }}
      >
        {car_out_of_shop && <Icon name="sign-out" className="car-out-of-shop-icon" />}
        <span className="fa-layers icons-container">
          <i className="material-icons" title={status.name}>
            {"verified_user"}
          </i>
          <FontAwesomeIcon icon={faEnvelope} title={status.name} />
        </span>
      </div>
    );
  }

  if (status.identifier === APPOINTMENT_STATUSES.CAR_CHECK_STARTED && value.check_paused_at) {
    return (
      <div
        className={`-status-column_cell status-column-icons-container ${car_out_of_shop ? "composite-icon" : ""}`}
        style={{
          backgroundColor: status.color,
        }}
      >
        {car_out_of_shop && <Icon name="sign-out" className="car-out-of-shop-icon" />}
        <span className={`icons-container ${car_out_of_shop ? "pause-check-car-in-shop-icons-container" : "pause-check-icons-container"}`}>
          <FontAwesomeIcon title={status.name} icon={faPauseCircle} className="car-check-started-icon" />
        </span>
      </div>
    );
  }

  if (status.identifier === APPOINTMENT_STATUSES.BACK_ORDER) {
    return (
      <div
        className={`-status-column_cell status-column-icons-container ${car_out_of_shop ? "composite-icon" : ""}`}
        style={{
          backgroundColor: status.color,
        }}
      >
        {car_out_of_shop && <Icon name="sign-out" className="car-out-of-shop-icon" />}
        <span className="fa-layers icons-container backorder-composite">
          <span className="back-order-icon">BO</span>
        </span>
      </div>
    );
  }

  return (
    <div
      className={`-status-column_cell status-column-icons-container ${car_out_of_shop ? "composite-icon" : ""}`}
      style={{
        position: "relative",
        backgroundColor: status.color,
        color: "#000",
      }}
    >
      {car_out_of_shop && <Icon name="sign-out" className="car-out-of-shop-icon" />}

      <i className="material-icons" title={status.name}>
        {status.icon}
      </i>
    </div>
  );
};

export default AppointmentCardStatus;
