import React, { Component } from "react";
import { Button, Icon, Modal, Form, Dropdown } from "semantic-ui-react";

export default class TemplateDetail extends Component {
  state = {
    selectedDealerID: this.props.template.dealer_id || null,
  };

  handleDropdownChange = (evt, data) => {
    this.setState({ selectedDealerID: data.value });
  };

  render() {
    const { selectedDealerID } = this.state;
    let { template, dealers } = this.props;

    const allDealers = [{ key: 0, text: "No selection", value: null }].concat(
      dealers.map(dealer => {
        return {
          key: dealer.id,
          text: dealer.name,
          value: dealer.id,
        };
      })
    );

    return (
      <div className="TemplateDetail">
        <Modal
          open={true}
          onClose={() => {
            this.props.onHideDetail();
          }}
          closeOnDimmerClick={false}
          size="large"
        >
          <Modal.Header>{this.props.t("report_template").message || "Report template"}</Modal.Header>

          <Modal.Content>
            <Form>
              <Form.Field>
                <label>{this.props.t("template_name").message || "Template name"}</label>
                <span>{template.name}</span>
              </Form.Field>
              <Form.Field>
                <label>{this.props.t("template_description").message || "Template description"}</label>
                <span>{template.description}</span>
              </Form.Field>
              <Dropdown
                placeholder={this.props.t("select_dealer").message || "Select dealer"}
                fluid
                search
                selection
                value={selectedDealerID}
                options={allDealers}
                onChange={this.handleDropdownChange}
              />
            </Form>
          </Modal.Content>

          <Modal.Actions>
            <Button
              color="red"
              loading={this.props.loading}
              disabled={this.props.loading}
              onClick={() => {
                this.props.onHideDetail();
              }}
            >
              <Icon name="close" /> {this.props.t("close").message || "Close"}
            </Button>
            <Button
              color="green"
              loading={this.props.loading}
              disabled={this.props.loading}
              onClick={() => {
                this.props.onAssignDealer(template.id, selectedDealerID);
              }}
            >
              {this.props.t("save").message || "Save"}
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}
