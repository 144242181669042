import React from "react";
import { Grid, Item, Label, Segment } from "semantic-ui-react";

const DomainSegment = props => {
  let t = props.t;
  let verified = props.verified;

  let records = props.records
    ? props.records
        .filter(item => item.record_type === "TXT")
        .map((item, i) => {
          let hashColor;
          let color;
          let icon;

          if (verified) {
            hashColor = "#21ba45";
            color = "green";
            icon = "check";
          } else {
            hashColor = "#767676";
            color = "grey";
            icon = "question circle outline";
          }

          return (
            <Segment key={i} style={{ borderColor: hashColor, paddingLeft: "2em" }}>
              <Item>
                <label className="-display-inline-block">{t("record_type").message || "Record type"}: </label> <span className={"Segment"}>{item.record_type}</span>{" "}
                <br />
              </Item>

              {item.Name ? (
                <Item className="-margin-top-5">
                  <label className="-display-inline-block">{t("name").message || "Name"}: </label> <span className={"Segment"}>{item.Name}</span> <br />
                </Item>
              ) : (
                ""
              )}

              <Item className="-margin-top-5">
                <Grid container>
                  <Grid.Row columns={2} style={{ padding: "0" }}>
                    <Grid.Column width={2} className="-no-padding-left">
                      <label className="-display-inline-block">{t("value").message || "Value"}: </label>
                    </Grid.Column>

                    <Grid.Column width={14} className="-no-padding-right">
                      <span className="Segment" style={{ wordBreak: "break-all" }}>
                        {item.Value}
                      </span>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Item>

              <Label corner={"left"} size={"mini"} icon={icon} color={color}></Label>
            </Segment>
          );
        })
    : "";

  return <React.Fragment>{records}</React.Fragment>;
};

export default DomainSegment;
