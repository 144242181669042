import React, { Component } from "react";
import { Button, Icon, Label } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import ReactTable, { ReactTableDefaults } from "react-table";

class WebhooksTable extends Component {
  render() {
    const { hooks, authUser, triggers, onOpenTestModal, onToggleErrorLog, t } = this.props;
    if (!hooks.length) return <div className="Table__no-results -margin-top-20">{t("no_webhooks").message || "No webhooks"}</div>;

    const locations = {};
    this.props.locations.forEach(l => (locations[l.value] = l.text));

    return (
      <div className="container">
        <ReactTable
          className="ReactTable WebhooksTable -floated-table -contained-large"
          data={hooks}
          showPagination={false}
          showPageSizeOptions={false}
          sortable={false}
          resizable={false}
          defaultPageSize={hooks.length}
          pageSize={hooks.length}
          getTdProps={(_, rowInfo) => {
            if (!authUser.acl["webhooks"]?.some(({ activity }) => activity === "update")) return null;

            return {
              onClick: () =>
                this.props.onRowClick({
                  index: rowInfo.index,
                  detailViewMode: "edit",
                  selectedHook: { ...rowInfo.original, headers: rowInfo.original.headers?.length ? [...rowInfo.original.headers] : [] },
                }),
            };
          }}
          getTrProps={(_, rowInfo) => {
            if (!rowInfo?.original) return {};

            return {
              style: {
                borderLeft: `4px solid ${rowInfo.original.active ? "green" : "red"}`,
              },
            };
          }}
          column={{
            ...ReactTableDefaults.column,
            headerClassName: "ReactTable__column-header",
            className: "ReactTable__column",
          }}
          columns={[
            {
              id: "name",
              Header: t("name").message || "Name",
              accessor: "name",
              width: 300,
            },
            {
              id: "url",
              Header: t("url").message || "URL",
              accessor: "url",
            },
            {
              id: "trigger",
              accessor: h => {
                const triggerName = triggers.find(t => t.id === h.webhook_trigger_id)?.name;
                return t(triggerName).message || triggerName;
              },
              Header: t("trigger").message || "Trigger",
            },
            {
              id: "headers",
              accessor: h => (h.headers ? h.headers.length : 0),
              Header: t("headers").message || "Headers",
            },
            {
              id: "location",
              accessor: h => (h.dealer_location_id ? locations[h.dealer_location_id] || t("invalid").message || "Invalid" : t("all").message || "All"),
              Header: t("location").message || "Location",
            },
            {
              id: "Queued",
              accessor: h =>
                h.queued ? (
                  <div className="queued-cell">
                    <Icon name="check" color="green" />
                  </div>
                ) : null,
              Header: t("queued").message || "Queued",
            },
            {
              id: "created_by",
              accessor: h => {
                if (h.public_account?.name) return `${t("public_api").message || "Public API"}: ${h.public_account.name}`;
                else if (h.user) return `${t("user").message || "User"}: ${h.user.first_name} ${h.user.last_name}`;
              },
              Header: t("created_by").message || "Created by",
            },
            {
              id: "send_support_tickets",
              accessor: h =>
                h.block_creating_support_ticket ? (
                  <div className="send-support-tickets-cell">
                    <Icon name="close" color="red" />
                  </div>
                ) : (
                  <div className="send-support-tickets-cell">
                    <Icon name="check" color="green" />
                  </div>
                ),
              Header: t("send_support_tickets").message || "Send support tickets",
            },
            {
              id: "test_button",
              className: "test-button-column",
              width: 180,
              accessor: h => (
                <div className="buttons-column">
                  <Label basic onClick={e => onToggleErrorLog(e, h.id)} className="-margin-right-10">
                    <Icon name="list alternate" color={h.has_recent_error ? "red" : "green"} style={{ margin: "0" }} />
                  </Label>
                  <Button className="test-button" color="green" onClick={e => onOpenTestModal(e, h.id)}>
                    {t("test").message || "Test"}
                  </Button>
                </div>
              ),
            },
          ]}
        />
      </div>
    );
  }
}

export default withTranslation()(WebhooksTable);
