import axios from "axios";
import ENV from "../../util/env-config";
import { getSessionCookie } from "../../util/common";

class Service {
  static requestConfig() {
    return {
      headers: {
        Authorization: `Bearer ${getSessionCookie()}`,
      },
    };
  }
  static getUnreadArticles(requestData) {
    return axios.post(ENV.apiBaseB + "/articles/list_unread", requestData, this.requestConfig());
  }

  static setNewsStripeClicked(requestData) {
    return axios.post(ENV.apiBaseB + "/articles/stripe_clicked", requestData, this.requestConfig());
  }
}
export default Service;
