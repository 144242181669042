import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Icon } from "semantic-ui-react";
import AlertContainer from "react-alert";

import AppointmentChecklistGroup from "./AppointmentChecklistGroup";
import AppointmentInterventions from "./AppointmentInterventions";
import AppointmentChecklist from "./AppointmentChecklist";
import { round2 } from "../../util/common";
import { APPOINTMENT_STATUSES, KEYLOCKER_COMMUNICATION_STATUSES, KIOSK_COMMUNICATION_EVENTS } from "./common";
import { CCRemarks, CheckInOptionalServices } from "../../components";
import { DMS } from "../DMSv3/util";
import { CHECKLIST_TYPE } from "../Checklists/enum";
import { handleUpdateAppointments } from "./store";
import { APPOINTMENT_NOTE_TYPES } from "./util";

import Service from "./service";

import "./AppointmentLists.css";

class AppointmentLists extends Component {
  state = {
    checks: this.props.checks,
    totalPrice: 0,
    originalPrice: 0,
    totalPriceApproved: 0,
    tyreSizes: [],
    interventionIdsToMagnify: [],
  };

  componentDidMount() {
    Service.getTyresSizes()
      .then(result => {
        if (result && result.data && result.data.data && Array.isArray(result.data.data.sizes)) {
          this.setState({ tyreSizes: result.data.data.sizes.sort() });
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  componentDidUpdate(prevProps) {
    if (this.props.checks !== this.state.checks) {
      this.setState({ checks: this.props.checks }, () => this.getTotalPrice());
    } else if (this.props.appointment.interventions !== prevProps.appointment.interventions) {
      this.getTotalPrice();
    }
  }

  getTotalPrice = () => {
    const { checks } = this.state;
    const { interventions } = this.props.appointment;
    const { include_vat, vat: vatPercentage } = this.props.location;

    const vat = include_vat ? 1 + vatPercentage / 100 : 1;

    if (Array.isArray(checks)) {
      let totalPriceApproved = 0;
      let totalPrice = 0;
      let originalPrice = 0;

      checks.forEach(check => {
        Array.isArray(check.question_items) &&
          check.question_items.forEach(item => {
            if (item.tyre_replacements?.length) {
              item.tyre_replacements.forEach(rep => {
                totalPrice += round2(rep.price * vat);

                if (include_vat) originalPrice += round2(rep.price);
                if (rep.customer_answer) totalPriceApproved += round2(rep.price * vat);
              });
            } else {
              totalPrice += round2(item.price * vat);

              if (include_vat) originalPrice += round2(item.price);
              if (item.customer_approved) totalPriceApproved += round2(item.price * vat);
            }
          });
      });

      const { dms_price_enabled } = this.props;
      if (Array.isArray(interventions) && dms_price_enabled) {
        interventions.forEach(intervention => {
          totalPrice += round2(intervention.price * vat);

          if (include_vat) originalPrice += round2(intervention.price);
          if (intervention.customer_ok) totalPriceApproved += round2(intervention.price * vat);
        });
      }

      this.setState({ totalPriceApproved: totalPriceApproved.toFixed(2), totalPrice: totalPrice.toFixed(2), originalPrice: originalPrice.toFixed(2) });
    }
  };

  renderAlert = () => {
    const props = {
      offset: 20,
      position: "top right",
      theme: "light",
      time: 2000,
      transition: "fade",
    };

    return <AlertContainer ref={a => (this.msg = a)} {...props} />;
  };

  updateOnlineCheckinRemark = remark => {
    return Service.updateOnlineCheckinRemark(remark)
      .then(() => {
        const check_in_remarks = this.props.appointment.check_in_remarks.map(r => (r.id === remark.id ? remark : r));

        this.props.handleUpdateAppointments({ ...this.props.appointment, check_in_remarks });
      })
      .catch(error => {
        const errorMsg =
          typeof error.response?.data === "string"
            ? error.response.data
            : error.response.data.errors
            ? error.response.data.errors[0]
            : "Failed to update online checkin remark";

        this.msg.show(errorMsg, { type: "error" });
      });
  };

  updateDiagnoseOverviewRemark = remark => {
    return Service.updateDiagnoseRemark(remark)
      .then(() => {
        const diagnose_overview_remarks = this.props.appointment.diagnose_overview_remarks.map(r => (r.id === remark.id ? remark : r));

        this.props.handleUpdateAppointments({ ...this.props.appointment, diagnose_overview_remarks });
      })
      .catch(error => {
        const errorMsg =
          typeof error.response?.data === "string"
            ? error.response.data
            : error.response.data.errors
            ? error.response.data.errors[0]
            : "Failed to update diagnose remark";

        this.msg.show(errorMsg, { type: "error" });
      });
  };

  updateKeylockerCheckinRemark = keylocker_remark => {
    return Service.updateKeylockerRemark({ keylocker_remark })
      .then(() => {
        const keylocker_communications = this.props.appointment.keylocker_communications.map(com => {
          if (com.remark?.id === keylocker_remark.id) return { ...com, remark: keylocker_remark };
          return com;
        });

        this.props.handleUpdateAppointments({ ...this.props.appointment, keylocker_communications });
      })
      .catch(error => {
        const errorMsg =
          typeof error.response?.data === "string"
            ? error.response.data
            : error.response.data.errors
            ? error.response.data.errors[0]
            : "Failed to update keylocker remark";

        this.msg.show(errorMsg, { type: "error" });
      });
  };

  updateKiosCheckinRemark = kiosk_remark => {
    return Service.updateKioskCheckinRemark(kiosk_remark)
      .then(() => {
        const kiosk_communications = this.props.appointment.kiosk_communications.map(com => {
          if (com.remark?.id === kiosk_remark.id) return { ...com, remark: kiosk_remark };
          return com;
        });

        this.props.handleUpdateAppointments({ ...this.props.appointment, kiosk_communications });
      })
      .catch(error => {
        const errorMsg =
          typeof error.response?.data === "string"
            ? error.response.data
            : error.response.data.errors
            ? error.response.data.errors[0]
            : "Failed to update kiosk checkin remark";

        this.msg.show(errorMsg, { type: "error" });
      });
  };

  handleUpdateInterventionIdsToMagnify = interventionID => {
    let interventionIdsToMagnify = [...this.state.interventionIdsToMagnify];

    if (interventionIdsToMagnify.includes(interventionID)) interventionIdsToMagnify = interventionIdsToMagnify.filter(id => id !== interventionID);
    else interventionIdsToMagnify.push(interventionID);

    this.setState({ interventionIdsToMagnify });
  };

  handleChecksFilter = () => {
    const { isCustomerCommunicationVisible } = this.props;
    const { checks, interventionIdsToMagnify } = this.state;

    if (!checks) return;

    const initialChecks = checks.filter(c => !isCustomerCommunicationVisible || c.checklist?.checklist_type !== CHECKLIST_TYPE.EXTRA_PART);

    if (!interventionIdsToMagnify.length) return initialChecks;

    return initialChecks.filter(check => interventionIdsToMagnify.includes(check.intervention_id));
  };

  render() {
    let {
      dms_price_enabled,
      interventions,
      appointment,
      dashboardConfig,
      onHandleCheckInCom,
      onHandleCustomCom,
      onChecklistAnswerUpdate,
      onInterventionAnswerUpdate,
      onInterventionDelete,
      onMediaClick,
      onShowAddInterventionClick,
      onChangeCustomerCommunication,
      onHandleKeyLockerRemark,
      onHandleKioskRemark,
      isEditable,
      isPriceEditable,
      currentUser,
      t,
      openCarDetail,
      isCustomerCommunicationVisible,
      selectedCustomerComm,
      isDeletingIntervention,
      location,
    } = this.props;
    let { checks } = this.state;
    let pinned = [];
    let snoozed = [];

    if (checks && typeof checks === "object") {
      checks.forEach(check => {
        Object.values(check.sublists).forEach(list => {
          list.items.forEach(question => {
            question.importantQuestionTemplate = check.checklist.template;
            if (question.pinned) pinned.push(question);
            else if (question.snoozed) snoozed.push(question);
          });
        });
      });
    }

    let importantQuestions = [].concat(pinned, snoozed);
    let keyloop_enabled = [DMS.KEYLOOP_MENUS, DMS.KEYLOOP_JOBS].includes(location.dms_id);

    const keylockerRemarks = appointment.keylocker_communications
      ?.filter(com => com.status === KEYLOCKER_COMMUNICATION_STATUSES.CHECK_IN_ANSWERED && com.remark)
      ?.map(com => com.remark);

    const kioskRemarks = appointment.kiosk_communications?.filter(kc => !!kc.remark).map(kc => kc.remark);

    const isFinalCheckDone = !!appointment.status_history?.some(
      s => s.identifier === APPOINTMENT_STATUSES.CAR_READY || s.identifier === APPOINTMENT_STATUSES.CAR_OK_PLUS_REPAIR_OVERVIEW
    );

    const interventionsIdsConnectedToChecks = checks ? checks.map(check => check.intervention_id) : [];

    const filteredChecks = this.handleChecksFilter();

    const keylockerPhones = [],
      kioskPhones = [];

    appointment.keylocker_communications &&
      appointment.keylocker_communications.forEach(k => {
        if (k.status === KEYLOCKER_COMMUNICATION_STATUSES.CHECK_IN_ANSWERED) {
          k.events.forEach(event => {
            if (event.correct_phone) keylockerPhones.push(event.correct_phone);
          });
        }
      });

    appointment.kiosk_communications &&
      appointment.kiosk_communications.forEach(k => {
        if (k.status === KIOSK_COMMUNICATION_EVENTS.CHECKIN_ANSWERED) {
          k.events.forEach(event => {
            if (event.correct_phone) kioskPhones.push(event.correct_phone);
          });
        }
      });

    return (
      <div className={`AppointmentLists -is-editable-${isEditable}`} style={{ position: "relative" }}>
        {!isCustomerCommunicationVisible &&
          (importantQuestions.length > 0 ||
            (Array.isArray(appointment.notes) && appointment.notes.some(n => n.appointment_note_type_id === APPOINTMENT_NOTE_TYPES.DMS)) ||
            appointment.is_recurring ||
            appointment.customcom_correct_phone ||
            appointment.customcom_correct_email ||
            appointment.desk_communications?.length > 0 ||
            (Array.isArray(appointment.notes) && appointment.notes.length > 0) ||
            appointment.customcom_status > 0 ||
            (Array.isArray(appointment.car?.dms_notes) && appointment.car.dms_notes.length > 0) ||
            (Array.isArray(appointment.keylocker_communications) && appointment.keylocker_communications.length > 0) ||
            keylockerPhones.length > 0 ||
            (Array.isArray(appointment.kiosk_communications) && appointment.kiosk_communications.length > 0) ||
            kioskPhones.length > 0) && (
            <AppointmentChecklistGroup
              isImportantItem={true}
              title={t("important_items").message || "Important items"}
              titleIcon="attention"
              titleClassName="-top-questions-list"
              displayFrontIcon={true}
              appointment={appointment}
              isFinalCheckDone={isFinalCheckDone}
              dashboardConfig={dashboardConfig}
              onMediaClick={onMediaClick}
              onAnswerUpdate={onChecklistAnswerUpdate}
              items={importantQuestions}
              isEditable={isEditable}
              isPriceEditable={isPriceEditable}
              openCarDetail={openCarDetail}
              getTotalPrice={this.getTotalPrice}
              currentUser={currentUser}
              includeVAT={location.include_vat}
              vat={location.vat}
              location={location}
              keyloopEnabled={keyloop_enabled}
              keylockerPhones={keylockerPhones}
              kioskPhones={kioskPhones}
            />
          )}

        {!isCustomerCommunicationVisible && (
          <div className="outter-container">
            {Array.isArray(appointment.check_in_results) && appointment.check_in_results.length > 0 && (
              <CheckInOptionalServices
                disabled={!isEditable}
                services={appointment.check_in_results}
                includeVAT={location.include_vat}
                vat={location.vat}
                interventions={appointment.interventions}
                onHandleCheckInCom={onHandleCheckInCom}
              />
            )}

            {Array.isArray(appointment.check_in_remarks) && appointment.check_in_remarks.length > 0 && (
              <CCRemarks
                title={t("remarks_checkin_communication").message || "Clients remarks: Online Check-in"}
                disabled={!isEditable}
                remarks={appointment.check_in_remarks}
                interventions={appointment.interventions}
                only_visible
                resource="check_in_remarks"
                canCreateIntervention={true}
                onHandleCommunication={onHandleCheckInCom}
                onUpdateRemark={this.updateOnlineCheckinRemark}
              />
            )}

            {Array.isArray(appointment.diagnose_overview_remarks) && appointment.diagnose_overview_remarks.length > 0 && (
              <CCRemarks
                title={t("remarks_diagnose_communication").message || "Clients remarks: Diagnose overview"}
                disabled={!isEditable}
                remarks={appointment.diagnose_overview_remarks}
                interventions={appointment.interventions}
                resource="diagnose_overview_remarks"
                // CLA-I406 -> Comments: Per discussion between Franck and Antoine interventions will not be
                //                       created out of customcom remarks, they can only be marked as handled.
                //                       By setting cdk_enabled to false we'll get that result
                canCreateIntervention={false}
                onHandleCommunication={onHandleCustomCom}
                onUpdateRemark={this.updateDiagnoseOverviewRemark}
              />
            )}

            {keylockerRemarks?.length > 0 && (
              <CCRemarks
                title={t("keylocker_remarks").message || "Client remarks: Keylocker"}
                disabled={!isEditable}
                remarks={keylockerRemarks}
                interventions={appointment.interventions}
                resource="keylocker_remarks"
                canCreateIntervention={false}
                onHandleCommunication={onHandleKeyLockerRemark}
                onUpdateRemark={this.updateKeylockerCheckinRemark}
              />
            )}

            {kioskRemarks?.length > 0 && (
              <CCRemarks
                title={t("kiosk_remarks").message || "Client remarks: Kiosk"}
                disabled={!isEditable}
                remarks={kioskRemarks}
                interventions={appointment.interventions}
                resource="kiosk-remark"
                canCreateIntervention={false}
                onHandleCommunication={onHandleKioskRemark}
                onUpdateRemark={this.updateKiosCheckinRemark}
              />
            )}
          </div>
        )}

        <AppointmentInterventions
          appointment_status_identifier={appointment.appointment_status_identifier}
          dms_price_enabled={dms_price_enabled}
          isCustomerCommunicationVisible={isCustomerCommunicationVisible}
          isReadOnly={!isEditable}
          isPriceEditable={isPriceEditable}
          vat={location.vat}
          includeVAT={location.include_vat}
          appointment={appointment}
          dashboardConfig={dashboardConfig}
          items={interventions}
          interventionIdsToMagnify={this.state.interventionIdsToMagnify}
          interventionsIdsConnectedToChecks={interventionsIdsConnectedToChecks}
          onMediaClick={onMediaClick}
          onShowAddInterventionClick={onShowAddInterventionClick}
          onHandleUpdateInterventionIdsToMagnify={this.handleUpdateInterventionIdsToMagnify}
          isAppointmentLocal={appointment.is_local}
          isFinalCheckDone={isFinalCheckDone}
          onAnswerUpdate={onInterventionAnswerUpdate}
          onInterventionDelete={onInterventionDelete}
          isDeletingIntervention={isDeletingIntervention}
          currentUser={currentUser}
          locationDmsId={location.dms_id}
        />

        {checks === "error" && (
          <div className="Checks-Placeholder">
            <section>{t("problem_loading_checks").message || "Problem loading checks of this appointment"}</section>
          </div>
        )}

        {checks === null && (
          <div className="Checks-Placeholder Loader-Placeholder">
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
            <section>{t("loading_checks").message || "Loading checks"}</section>
          </div>
        )}

        {checks && checks !== "error" && checks.length === 0 && (
          <div className="Checks-Placeholder">
            <p>
              <Icon disabled name="tasks" />
            </p>
            {t("no_checks_in_appointment").message || "No checks in this appointment"}
          </div>
        )}

        {checks !== "error" &&
          filteredChecks?.map((c, k) => (
            <AppointmentChecklist
              selectedCustomerComm={selectedCustomerComm}
              getTotalPrice={this.getTotalPrice}
              dashboardConfig={dashboardConfig}
              appointment={appointment}
              key={c.id}
              checklist={c}
              isFinalCheckDone={isFinalCheckDone}
              onMediaClick={onMediaClick}
              onAnswerUpdate={onChecklistAnswerUpdate}
              isEditable={isEditable}
              isPriceEditable={isPriceEditable}
              currentUser={currentUser}
              isCustomerCommunicationVisible={isCustomerCommunicationVisible}
              onChangeCustomerCommunication={onChangeCustomerCommunication}
              tyreSizes={this.state.tyreSizes}
              location={location}
              keyloopEnabled={keyloop_enabled}
            />
          ))}

        {!isCustomerCommunicationVisible && (this.state.totalPrice > 0 || this.state.totalPriceApproved > 0) && (
          <div style={{ backgroundColor: "#b3ffe6", padding: "0.5em", textAlign: "right", marginTop: "5px" }}>
            {this.state.totalPrice > 0 && (
              <h5 style={{ display: "inline", fontStyle: "italic", marginRight: this.state.totalPrice > 0 ? "3em" : "0em" }}>
                {location.include_vat && (
                  <>
                    <span>{`${this.props.t("total_amount_excluding_vat").message || "Total amount, excluding VAT"}: ${this.state.originalPrice} €`}</span>
                    <span className="-margin-left-15">{`${this.props.t("total_amount_include_vat").message || "Total amount, including VAT"}: ${
                      this.state.totalPrice
                    } €`}</span>
                  </>
                )}
                {!location.include_vat && `${this.props.t("total_amount").message || "Total amount"}: ${this.state.totalPrice} €`}
              </h5>
            )}

            {this.state.totalPriceApproved > 0 && (
              <h5 style={{ fontStyle: "italic", textAlign: "right", marginRight: "0.5em", display: "inline" }}>
                {location.include_vat &&
                  `${this.props.t("customer_approved_total_amount_including_vat").message || "Customer approved total, including VAT"}: ${
                    this.state.totalPriceApproved
                  } €`}
                {!location.include_vat && `${this.props.t("customer_approved_total_amount").message || "Customer approved total"}: ${this.state.totalPriceApproved} €`}
              </h5>
            )}
          </div>
        )}

        {this.renderAlert()}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    globalState: state.global,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handleUpdateAppointments: appointment => dispatch(handleUpdateAppointments(appointment)),
  };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(AppointmentLists));
