import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Checkbox, Form, Header, Icon, Segment } from "semantic-ui-react";

class CommunicationSettings extends Component {
  handleCheckboxChange = (e, { name, checked }) => {
    let { location, onLocationChange } = this.props;

    onLocationChange({ ...location, [name]: checked });
  };

  render() {
    const { t, location } = this.props;

    return (
      <Segment>
        <Header as="h5">
          <Icon name="settings" />
          <Header.Content>{t("customer_communication_settings").message || "Customer Communication Settings"}</Header.Content>
        </Header>
        <Segment>
          <Form>
            <Form.Field>
              <Checkbox
                checked={location.third_party_send_communications}
                name="third_party_send_communications"
                label={t("third_party_send_communications").message || "Third party send communications"}
                onChange={this.handleCheckboxChange}
              />
            </Form.Field>
            <Form.Field>
              <Checkbox
                checked={location.online_check_in_enabled}
                name="online_check_in_enabled"
                label={t("online_check_in_communication").message || "Online Check-in Communication"}
                onChange={this.handleCheckboxChange}
              />
            </Form.Field>
            <Form.Field>
              <Checkbox
                checked={location.desk_check_in_enabled}
                name="desk_check_in_enabled"
                label={t("desk_check_in").message || "Desk Check-in"}
                onChange={this.handleCheckboxChange}
              />
            </Form.Field>
            <Form.Field>
              <Checkbox
                checked={location.is_open_desk_checkout_on_car_out_of_shop_enabled}
                name="is_open_desk_checkout_on_car_out_of_shop_enabled"
                label={t("show_desk_checkout_on_car_out_of_shop").message || "Show desk check-out on car out of shop"}
                onChange={this.handleCheckboxChange}
              />
            </Form.Field>
            <Form.Field>
              <Checkbox
                checked={location.diagnose_overview_enabled}
                name="diagnose_overview_enabled"
                label={t("diagnose_overview").message || "Diagnose overview"}
                onChange={this.handleCheckboxChange}
              />
            </Form.Field>
            <Form.Field>
              <Checkbox
                checked={location.repair_overview_enabled}
                name="repair_overview_enabled"
                label={t("repair_overview").message || "Repair overview"}
                onChange={this.handleCheckboxChange}
              />
            </Form.Field>

            <Form.Field>
              <Checkbox
                checked={location.is_keylocker_enabled}
                name="is_keylocker_enabled"
                label={t("keylocker").message || "Keylocker"}
                onChange={this.handleCheckboxChange}
              />
            </Form.Field>

            <Form.Field>
              <Checkbox checked={location.is_kiosk_enabled} name="is_kiosk_enabled" label={t("kiosk").message || "Kiosk"} onChange={this.handleCheckboxChange} />
            </Form.Field>
          </Form>
        </Segment>
      </Segment>
    );
  }
}

export default withTranslation()(CommunicationSettings);
