import { addHeader } from "./functions";
import Print from "./print";

export default {
  print: (params, printFrame) => {
    if (params.printable.constructor !== Array) {
      params.printable = [params.printable];
    }

    params.printableElement = document.createElement("div");

    params.printable.forEach(src => {
      let img = document.createElement("img");
      img.setAttribute("style", params.imageStyle);

      img.src = src;

      let imageWrapper = document.createElement("div");

      imageWrapper.appendChild(img);

      params.printableElement.appendChild(imageWrapper);
    });

    if (params.header) addHeader(params.printableElement, params);

    Print.send(params, printFrame);
  },
};
