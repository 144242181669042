import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Checkbox, Form, Header, Icon, Segment } from "semantic-ui-react";

class DayplannerConfiguration extends Component {
  handleCheckboxChange = (_e, { name, checked }) => {
    const { location, onLocationChange } = this.props;

    onLocationChange({ ...location, [name]: checked });
  };

  render() {
    const { location, t } = this.props;

    return (
      <Segment>
        <Header as="h5">
          <Icon name="columns" />
          <Header.Content>{t("dayplanner_configuration").message || "Dayplanner configuration"}</Header.Content>
        </Header>
        <Form>
          <Form.Field className="full-width-input">
            <Checkbox
              name="is_dayplanner_enabled"
              checked={location.is_dayplanner_enabled}
              onChange={this.handleCheckboxChange}
              label={t("dayplanner").message || "Dayplanner"}
            />
          </Form.Field>
        </Form>

        {location.is_dayplanner_enabled && (
          <Segment>
            <Form>
              <Form.Field className="full-width-input">
                <Checkbox
                  name="automatically_assign_wo_when_check_started"
                  checked={location.automatically_assign_wo_when_check_started}
                  onChange={this.handleCheckboxChange}
                  label={t("assign_on_check_started").message || "Assign to mechanic on check started"}
                />
              </Form.Field>

              <Form.Field className="full-width-input">
                <Checkbox
                  name="automatically_assign_wo_when_intervention_fixed"
                  checked={location.automatically_assign_wo_when_intervention_fixed}
                  onChange={this.handleCheckboxChange}
                  label={t("assign_on_intervention_fixed").message || "Assign to mechanic on intervention fixed"}
                />
              </Form.Field>
            </Form>
          </Segment>
        )}
      </Segment>
    );
  }
}

export default withTranslation()(DayplannerConfiguration);
