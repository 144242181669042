import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Button, Form, Icon, List, Label } from "semantic-ui-react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";

import { UserInput } from "../../../components";

class Solution extends Component {
  constructor(props) {
    super(props);

    let label = "";
    let unit_items = [];
    let value_items = [];
    let type_items = [];

    const { default_configuration } = this.props;

    if (default_configuration) {
      label = default_configuration.label || "";
      unit_items = default_configuration.unit_items || [];
      value_items = default_configuration.value_items || [];
      type_items = default_configuration.type_items || [];
    }

    this.state = {
      unitItem: "",
      valueItem: "",
      typeItem: "",
      label,
      unit_items,
      value_items,
      type_items,
    };
  }

  handleLabelInput = e => {
    this.setState({ label: e.target.value }, () => {
      let { label, unit_items, value_items, type_items } = this.state;
      this.props.handleQuestionElementUpdate({ label, unit_items, value_items, type_items });
    });
  };

  handleUnitNameInput = e => {
    this.setState({ unitItem: e.target.value });
  };

  handleAppendUnitItem = () => {
    let name = this.state.unitItem;

    if (name !== "") {
      let unit_items = this.state.unit_items;
      unit_items = unit_items.concat({ label: name });
      this.setState({ unit_items, unitItem: "" }, () => {
        let { label, unit_items, value_items, type_items } = this.state;
        this.props.handleQuestionElementUpdate({ label, unit_items, value_items, type_items });
      });
    }
  };

  handleRemoveUnitItem = index => {
    let unit_items = [...this.state.unit_items];
    unit_items.splice(index, 1);

    this.setState({ unit_items }, () => {
      let { label, unit_items, value_items, type_items } = this.state;
      this.props.handleQuestionElementUpdate({ label, unit_items, value_items, type_items });
    });
  };

  handleValueNameInput = e => {
    this.setState({ valueItem: e.target.value });
  };

  handleAppendValueItem = () => {
    let name = this.state.valueItem;

    if (name !== "") {
      let value_items = this.state.value_items;
      value_items = value_items.concat({ label: name });
      this.setState({ value_items, valueItem: "" }, () => {
        let { label, unit_items, value_items, type_items } = this.state;
        this.props.handleQuestionElementUpdate({ label, unit_items, value_items, type_items });
      });
    }
  };

  handleRemoveValueItem = index => {
    let value_items = [...this.state.value_items];
    value_items.splice(index, 1);

    this.setState({ value_items }, () => {
      let { label, unit_items, value_items, type_items } = this.state;
      this.props.handleQuestionElementUpdate({ label, unit_items, value_items, type_items });
    });
  };

  handleTypeNameInput = e => {
    this.setState({ typeItem: e.target.value });
  };

  handleAppendTypeItem = () => {
    let name = this.state.typeItem;

    if (name !== "") {
      let type_items = this.state.type_items;
      type_items = type_items.concat({ label: name });
      this.setState({ type_items, typeItem: "" }, () => {
        let { label, unit_items, value_items, type_items } = this.state;
        this.props.handleQuestionElementUpdate({ label, unit_items, value_items, type_items });
      });
    }
  };

  handleRemoveTypeItem = index => {
    let type_items = [...this.state.type_items];
    type_items.splice(index, 1);

    this.setState({ type_items }, () => {
      let { label, unit_items, value_items, type_items } = this.state;
      this.props.handleQuestionElementUpdate({ label, unit_items, value_items, type_items });
    });
  };

  onSortUnitEnd = ({ oldIndex, newIndex }, event) => {
    if (!event) return;

    let unit_items = [...this.state.unit_items];
    const item = unit_items[oldIndex];
    unit_items.splice(oldIndex, 1);
    unit_items.splice(newIndex, 0, item);
    this.setState({ unit_items }, () => {
      let { label, unit_items, value_items, type_items } = this.state;
      this.props.handleQuestionElementUpdate({ label, unit_items, value_items, type_items });
    });
  };

  onSortValueEnd = ({ oldIndex, newIndex }, event) => {
    if (!event) return;

    let value_items = [...this.state.value_items];
    const item = value_items[oldIndex];
    value_items.splice(oldIndex, 1);
    value_items.splice(newIndex, 0, item);
    this.setState({ value_items }, () => {
      let { label, unit_items, value_items, type_items } = this.state;
      this.props.handleQuestionElementUpdate({ label, unit_items, value_items, type_items });
    });
  };

  onSortTypeEnd = ({ oldIndex, newIndex }, event) => {
    if (!event) return;

    let type_items = [...this.state.type_items];
    const item = type_items[oldIndex];
    type_items.splice(oldIndex, 1);
    type_items.splice(newIndex, 0, item);
    this.setState({ type_items }, () => {
      let { label, unit_items, value_items, type_items } = this.state;
      this.props.handleQuestionElementUpdate({ label, unit_items, value_items, type_items });
    });
  };

  renderItems = (items, removeCallback, sortCallback) => {
    if (items && items.length > 0) {
      const SortableItem = SortableElement(({ offset, value }) => {
        return (
          <Label className="ListItem" size="big" color="blue">
            <Icon
              onClick={() => {
                removeCallback(offset);
              }}
              name="delete"
            />{" "}
            {value.label}
          </Label>
        );
      });

      const SortableList = SortableContainer(({ items }) => {
        return (
          <div>
            {items.map((item, key) => (
              <SortableItem key={`item-${key}`} index={key} offset={key} value={item} />
            ))}
          </div>
        );
      });

      return <SortableList items={items} distance={5} lockAxis="y" onSortEnd={sortCallback} />;
    }
  };

  render() {
    return (
      <div>
        <Form.Field>
          <UserInput value={this.state.label} onChange={this.handleLabelInput} label={this.props.t("label").message || "Label"} />
        </Form.Field>

        <Form.Field>
          <h4>{this.props.t("unit_items").message || "Unit items"}</h4>
          <List>{this.renderItems(this.state.unit_items, this.handleRemoveUnitItem, this.onSortUnitEnd)}</List>
          <UserInput placeholder={this.props.t("add_new_item").message || "Add new item"} value={this.state.unitItem} onChange={this.handleUnitNameInput} />
          <Button type="button" fluid color="green" onClick={this.handleAppendUnitItem}>
            <Icon name="plus" /> {this.props.t("add_item").message || "Add item"}
          </Button>
        </Form.Field>

        <Form.Field>
          <h4>{this.props.t("value_items").message || "Value items"}</h4>
          <List>{this.renderItems(this.state.value_items, this.handleRemoveValueItem, this.onSortValueEnd)}</List>
          <UserInput placeholder={this.props.t("add_new_item").message || "Add new item"} value={this.state.valueItem} onChange={this.handleValueNameInput} />
          <Button type="button" fluid color="green" onClick={this.handleAppendValueItem}>
            <Icon name="plus" /> {this.props.t("add_item").message || "Add item"}
          </Button>
        </Form.Field>

        <Form.Field>
          <h4>{this.props.t("type_items").message || "Type items"}</h4>
          <List>{this.renderItems(this.state.type_items, this.handleRemoveTypeItem, this.onSortTypeEnd)}</List>
          <UserInput placeholder={this.props.t("add_new_item").message || "Add new item"} value={this.state.typeItem} onChange={this.handleTypeNameInput} />
          <Button type="button" fluid color="green" onClick={this.handleAppendTypeItem}>
            <Icon name="plus" /> {this.props.t("add_item").message || "Add item"}
          </Button>
        </Form.Field>
      </div>
    );
  }
}

export default withTranslation()(Solution);
