import axios from "axios";

import ENV from "../../util/env-config";
import { getSessionCookie } from "../../util/common";

class Service {
  static requestConfig() {
    return {
      headers: {
        Authorization: `Bearer ${getSessionCookie()}`,
      },
    };
  }

  static getImportLogForLocation(id) {
    return axios.post(`${ENV.systemBase}/import_location_logs`, { location_id: id }, this.requestConfig());
  }

  static createDealer(requestData) {
    return axios.post(`${ENV.dealerBaseUrl}/dealers/create`, requestData, this.requestConfig());
  }

  static createLocation(requestData) {
    return axios.post(`${ENV.apiBaseB}/locations/create`, requestData, this.requestConfig());
  }

  static updateLocation(requestData) {
    return axios.put(ENV.apiBaseB + "/locations/update", requestData, this.requestConfig());
  }

  static getDealerDetail(requestData) {
    return axios.post(`${ENV.dealerBaseUrl}/dealers/details`, requestData, this.requestConfig());
  }

  static createSvcAccRequest(requestData) {
    return axios.post(ENV.apiBaseB + "/iam/create_service_account", requestData, this.requestConfig());
  }

  static getBrands() {
    return axios.get(ENV.apiBaseB + "/brands/list", this.requestConfig());
  }

  static getLanguages() {
    return axios.get(ENV.systemBase + "/languages/list", this.requestConfig());
  }

  static uploadLogo(requestData) {
    return axios.post(ENV.apiBaseB + "/files/upload_logo", requestData, this.requestConfig());
  }

  static deleteDealer(id) {
    return axios.post(ENV.dealerBaseUrl + "/dealers/delete", { id: id }, this.requestConfig());
  }

  static deleteLocation(id) {
    return axios.post(ENV.apiBaseB + "/locations/delete", { id: id }, this.requestConfig());
  }

  static getVersions() {
    return axios.get(ENV.apiBaseB + "/versions/list", this.requestConfig());
  }
}

export default Service;
