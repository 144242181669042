import React from "react";
import { withTranslation } from "react-i18next";
import moment from "moment";
import DatePicker from "react-datepicker";

import { formatDateIfValid } from "./util";

import "./AppointmentDate.scss";

const AppointmentDate = ({ appointment, displayPropagationDays, canChangeAppointmentDate, onChangeDate, t }) => {
  const { time_car_app, is_pinned } = appointment;

  if (time_car_app.startsWith("0001-01-01T00:00:00")) return null;
  const dateDiff = Number(moment(Date.now()).startOf("day").diff(moment(time_car_app).startOf("day"), "days"));

  const renderDateDiff = () => (is_pinned && dateDiff && displayPropagationDays ? (dateDiff > 0 ? `+${dateDiff}` : dateDiff) : null);

  if (canChangeAppointmentDate) {
    let appDate = formatDateIfValid(appointment.time_car_app);

    return (
      <div className="appointment-date-picker-container">
        <DatePicker
          dateFormat="dd-MM-yyyy"
          todayButton={t("today").message || "Today"}
          selected={appDate && moment(appDate, "DD-MM-YYYY").toDate()}
          onChange={onChangeDate}
          dropdownMode={"select"}
          onChangeRaw={e => e.preventDefault()}
          showMonthDropdown
          showYearDropdown
          withPortal
        />

        <span className="dateDiff">{renderDateDiff()}</span>
      </div>
    );
  }

  return (
    <div className="appointment-date-container">
      <div>{moment(time_car_app).format("DD-MM-YYYY")}</div>
      <div className="-margin-left-5 -detail-value">{renderDateDiff()}</div>
    </div>
  );
};

export default withTranslation()(AppointmentDate);
