import React, { Component } from "react";
import DatePicker from "react-datepicker";
import { Icon, Label, Form, Checkbox, Dropdown, Popup, Message } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import zxcvbn from "zxcvbn";
import moment from "moment";

import { ROLES } from "./roles";
import { validateEmail } from "../../util/common";
import { UserInput } from "../../components";

const ALL_BRANDS = 0;
class UserForm extends Component {
  constructor(props) {
    super(props);

    const selectedDealer = this.props.dealers?.find(dealer => dealer.id === props.user.dealer_id);

    this.state = {
      extraDealers: props.user.dealers_ids?.length ? props.user.dealers_ids : [],
      allowedLocations: props.user.location_ids?.length ? props.user.location_ids : [],
      brands: props.user.brands?.length ? props.user.brands.map(brand => brand.id) : [ALL_BRANDS],
      showDealers: false,
      showLocations: false,
      passwordVisibility: [false, false],
      oneiPlanningEnabled: selectedDealer?.onei_planning_enabled,
      planitPlanningEnabled: selectedDealer?.planit_planning_enabled,
      errorMessage: "",
    };
  }

  componentDidMount() {
    this.renderBasedOnSelectedRole(this.props.user.role_id);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.user.dealer_id !== this.props.user.dealer_id) {
      const dealer = this.props.dealers?.find(dealer => dealer.id === this.props.user.dealer_id);
      if (dealer) {
        this.setState({ oneiPlanningEnabled: dealer.onei_planning_enabled, planitPlanningEnabled: dealer.planit_planning_enabled });
      }
    }
  }

  handleInputChange = e => {
    let { user } = this.props;
    if (Object.keys(user).length === 0) return;
    user[e.target.name] = e.target.value;
    this.props.handleUserChange(user);
  };

  handleCheckboxChange = (e, data) => {
    let { user } = this.props;
    user[data.name] = data.checked;
    this.props.handleUserChange(user);
  };

  handleDateChange = e => {
    const { user } = this.props;
    user.birthday = moment(e).format();
    this.props.handleUserChange(user);
  };

  handleRoleChange = (e, data) => {
    const { user } = this.props;
    user.role_id = data.value;

    this.renderBasedOnSelectedRole(data.value);
    this.props.handleRoleChange(user);
  };

  handlePasswordChange = (event, data) => {
    const { user, password } = this.props;
    password[data.name] = data.value;
    if (data.name === "newPassword") {
      password.strength = zxcvbn(data.value, [user.last_name, user.first_name, user.email, user.username, "claire", "auto"]).score;
    }
    this.props.onPasswordChange(password);
  };

  handleBrandsChange = (e, data) => {
    const { user } = this.props;

    if (data.value.length > 1 && data.value.includes(ALL_BRANDS) && !this.state.brands.includes(ALL_BRANDS)) data.value = [ALL_BRANDS];
    if (data.value.length > this.state.brands.length && this.state.brands.includes(ALL_BRANDS)) data.value = data.value.filter(brand => brand !== ALL_BRANDS);

    this.setState({ brands: data.value }, () => {
      const { brands } = this.state;
      user.brand_ids = brands.includes(ALL_BRANDS) ? [] : brands;
      this.props.handleUserChange({ ...user });
    });
  };

  handleDealersChange = (e, data) => {
    this.setState({ extraDealers: data.value }, () => {
      const { user, dealers } = this.props;
      user.dealers_ids = this.state.extraDealers;
      if (user.location_ids?.length) {
        const locations = [];
        const dealer = dealers.find(d => d.id === user.dealer_id);
        if (dealer && dealer.locations?.length) locations.push(...dealer.locations);

        if (user.dealers_ids?.length) {
          user.dealers_ids.forEach(dealer_id => {
            const dealer = dealers.find(d => d.id === dealer_id);
            if (dealer && dealer.locations?.length) locations.push(...dealer.locations);
          });
        }

        user.location_ids = user.location_ids.filter(location_id => locations.some(l => l.id === location_id));
        this.setState({ allowedLocations: user.location_ids });
      }

      this.props.handleUserChange({ ...user });
    });
  };

  handleLocationsChange = (e, data) => {
    this.setState({ allowedLocations: data.value }, () => this.props.handleAllowedLocationsChange(this.state.allowedLocations));
  };

  togglePassword = i => {
    let passwordVisibility = this.state.passwordVisibility;
    passwordVisibility[i] = !passwordVisibility[i];
    this.setState({ passwordVisibility });
  };

  renderBasedOnSelectedRole = role => {
    this.setState({
      showDealers: [ROLES.CONSULTANT, ROLES.SUPERVISOR, ROLES.WARRANTY_MANAGER, ROLES.MANUFACTURER].includes(role),
      showLocations: role === ROLES.WARRANTY_MANAGER,
    });
  };

  renderExtraDealers = () => {
    const { t } = this.props;

    const dealers = this.props.dealers
      .sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      })
      .filter(d => d.id !== this.props.user.dealer_id);

    return (
      <Form.Field>
        <label>{t("extra_dealers").message || "Extra dealers"}</label>
        <Dropdown
          placeholder={t("extra_dealers").message || "Extra dealers"}
          options={
            dealers.map((a, key) => {
              return { text: a.name, value: a.id, key, description: a.active ? "" : t("inactive").message || "Inactive" };
            }) || []
          }
          selection
          search
          defaultValue={this.state.extraDealers}
          onChange={this.handleDealersChange}
          multiple
          upward={false}
        />
      </Form.Field>
    );
  };

  renderAllowedLocations = () => {
    const { t, user, invalidInput } = this.props;
    const { allowedLocations } = this.state;

    const allSelectedDealers = [],
      dealerLocations = [],
      dealers = [];

    user.dealer_id && allSelectedDealers.push(user.dealer_id);
    this.state.extraDealers && allSelectedDealers.push(...this.state.extraDealers);

    if (allSelectedDealers.length) {
      allSelectedDealers.forEach(d => {
        const dealer = this.props.dealers.find(l => l.id === d);

        if (dealer?.locations) dealers[dealer.id] = { name: dealer.name, locations: dealer.locations };
      });

      dealers.sort((a, b) => a.name.localeCompare(b.name));
      dealers.forEach(d => {
        d.locations.sort((a, b) => a.name.localeCompare(b.name));
      });
    }

    if (dealers) {
      dealers.forEach((d, i) => {
        dealerLocations.push({
          key: `${d.name}-${i}`,
          disabled: true,
          children: (
            <Dropdown.Menu scrolling>
              <Dropdown.Header content={d.name} />
              <Dropdown.Divider />
            </Dropdown.Menu>
          ),
        });

        d.locations.forEach(l => {
          dealerLocations.push({
            key: l.id,
            text: l.name,
            value: l.id,
            fulltext: `${d.name.substring(0, 3)} - ${l.name}`,
            description: l.active ? "" : t("inactive").message || "Inactive",
          });
        });
      });
    }

    return (
      <Form.Field>
        <label>{t("allowed_locations").message || "Allowed locations"}</label>
        <Dropdown
          placeholder={t("allowed_location").message || "Allowed locations"}
          options={dealerLocations}
          multiple
          value={allowedLocations}
          onChange={this.handleLocationsChange}
          selection
          upward={false}
          renderLabel={label => ({
            content: label.fulltext,
          })}
        />
        {invalidInput && !allowedLocations.length && (
          <Label basic color="red" pointing>
            {t("field_is_required").message || "This field is required."}
          </Label>
        )}
      </Form.Field>
    );
  };

  renderBrands = () => {
    const { t, brandsList, invalidInput } = this.props;
    const { brands } = this.state;

    const brandsOptions = [{ text: t("all_brands").message || "All brands", value: 0, key: 0 }];

    if (brandsList.length > 0) {
      brandsList.forEach(brand => {
        brandsOptions.push({ text: brand.name, value: brand.id, key: brand.id });
      });
    }

    return (
      <Form.Field>
        <label>{t("brands").message || "Brands"}</label>
        <Dropdown
          placeholder={t("brands").message || "Brands"}
          options={brandsOptions}
          multiple
          value={this.state.brands}
          onChange={this.handleBrandsChange}
          selection
          search
          upward={false}
        />
        {invalidInput && !brands.length && (
          <Label basic color="red" pointing>
            {t("field_is_required").message || "This field is required."}
          </Label>
        )}
      </Form.Field>
    );
  };

  renderPassCheckMarkIcon = type => {
    const { t, password } = this.props;
    let r = null;
    let c = "";
    let goodStyle = {
      fontSize: "1.15em",
      position: "absolute",
      marginTop: "8px",
      color: "green",
    };

    let badStyle = {
      fontSize: "1.15em",
      position: "absolute",
      marginTop: "8px",
      color: "#f54545",
    };

    if (type === "new" && password.newPassword && password.newPassword.length > 0) {
      if (password.strength > 2) {
        r = <Icon name="check circle outline" style={goodStyle} />;
        c = t("new_password_secure").message || "New password looks good";
      } else {
        r = <Icon name="times circle outline" style={badStyle} />;
        c = t("new_password_not_secure").message || "New password is not secure enough";
      }
    }

    if (type === "confirm" && password.passwordConfirmation && password.passwordConfirmation.length > 0) {
      if (password.strength > 2 && password.newPassword === password.passwordConfirmation) {
        r = <Icon name="check circle outline" style={goodStyle} />;

        c = t("new_confirm_password_secure").message || "New password confirmation looks good";
      } else {
        r = <Icon name="times circle outline" style={badStyle} />;
        c = t("new_confirm_password_not_secure").message || "Confirmation is not the same as password or not secure enough";
      }
    }

    return <Popup trigger={r} content={c} />;
  };

  appointmentListOptions = () => [
    {
      key: "time_car_app",
      text: this.props.t("appointment_date").message || "Appointment date",
      value: false,
    },
    {
      key: "scheduled_in_out",
      text: this.props.t("scheduled_in_out").message || "Scheduled in/out date",
      value: true,
    },
  ];

  render() {
    const {
      user,
      roleOptions,
      dealers,
      dmsUsers,
      loadingDMSUsers,
      t,
      locationsBySelectedDealer,
      loadingPlanningMechanicsLists,
      oneiPlanningMechanicsOptions,
      planItMechanicsOptions,
      handleUserChange,
      password,
      mode,
      invalidInput,
      errorMessage,
    } = this.props;
    const { passwordVisibility, oneiPlanningEnabled, planitPlanningEnabled } = this.state;

    return (
      user && (
        <Form>
          <Form.Group widths="equal">
            <Form.Field>
              <label>{t("role").message || "Role"}</label>
              <Dropdown
                placeholder={t("role").message || "Role"}
                options={roleOptions.filter(r => r.value !== -99)}
                selection
                value={user.role_id}
                onChange={this.handleRoleChange}
              />
              {invalidInput && user.role_id === null && (
                <Label basic color="red" pointing>
                  {t("field_is_required").message || "This field is required."}
                </Label>
              )}
            </Form.Field>
            {!this.props.isDealersHidden && (
              <Form.Field>
                <label>{t("default_dealer").message || "Default Dealer"}</label>
                <Dropdown
                  placeholder={t("default_dealer").message || "Default Dealer"}
                  options={
                    dealers.map((a, key) => {
                      return { text: a.name, value: a.id, key, description: a.active ? "" : t("inactive").message || "Inactive" };
                    }) || []
                  }
                  selection
                  search
                  value={user.dealer_id}
                  onChange={this.props.handleDealerChange}
                />
                {invalidInput && !user.dealer_id && (
                  <Label basic color="red" pointing>
                    {t("field_is_required").message || "This field is required."}
                  </Label>
                )}
              </Form.Field>
            )}
            <Form.Field>
              <label>{t("default_location").message || "Default Location"}</label>
              <Dropdown
                placeholder={t("default_location").message || "Default Location"}
                options={locationsBySelectedDealer.map((a, key) => ({
                  text: a.name,
                  value: a.id,
                  key,
                  description: a.active ? "" : t("inactive").message || "Inactive",
                }))}
                search
                selection
                noResultsMessage={
                  user.role_id === ROLES.WARRANTY_MANAGER
                    ? t("assign_allowed_locations_message").message || "Please assign allowed locations first."
                    : t("no_locations_found").message || "No locations found on selected dealer."
                }
                value={user.dealer_location_id}
                onChange={this.props.handleLocationChange}
              />
              {invalidInput && !user.dealer_location_id && (
                <Label basic color="red" pointing>
                  {t("field_is_required").message || "This field is required."}
                </Label>
              )}
            </Form.Field>
          </Form.Group>

          <Form.Group widths={"equal"}>
            {this.renderBrands()}
            {this.state.showDealers ? this.renderExtraDealers() : <Form.Field></Form.Field>}
            {this.state.showLocations ? this.renderAllowedLocations() : <Form.Field></Form.Field>}
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Field>
              <label>{t("username").message || "Username"}</label>
              <UserInput value={user.username} name="username" onChange={this.handleInputChange} />
              {invalidInput && !user.username && (
                <Label basic color="red" pointing>
                  {t("field_is_required").message || "This field is required."}
                </Label>
              )}
            </Form.Field>
            <Form.Field>
              <label>{t("first_name").message || "Firstname"}</label>
              <UserInput value={user.first_name} name="first_name" onChange={this.handleInputChange} />
              {invalidInput && !user.first_name && (
                <Label basic color="red" pointing>
                  {t("field_is_required").message || "This field is required."}
                </Label>
              )}
            </Form.Field>
            <Form.Field>
              <label>{t("last_name").message || "Lastname"}</label>
              <UserInput value={user.last_name} name="last_name" onChange={this.handleInputChange} />
              {invalidInput && !user.last_name && (
                <Label basic color="red" pointing>
                  {t("field_is_required").message || "This field is required."}
                </Label>
              )}
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field>
              <label>{t("email").message || "Email"}</label>
              <UserInput value={user.email} name="email" onChange={this.handleInputChange} />
              {invalidInput && !user.email && (
                <Label basic color="red" pointing>
                  {t("field_is_required").message || "This field is required."}
                </Label>
              )}

              {invalidInput && user.email && !validateEmail(user.email) && (
                <Label basic color="red" pointing>
                  {t("email_not_valid").message || "Email is not valid"}
                </Label>
              )}
            </Form.Field>

            <Form.Field required>
              <label>{this.props.t("new_password").message || "New password"}</label>
              <UserInput
                name="newPassword"
                style={{ width: "90%" }}
                value={password.newPassword || ""}
                onChange={this.handlePasswordChange}
                type={passwordVisibility[0] ? "text" : "password"}
                icon={<Icon name={passwordVisibility[0] ? "hide" : "unhide"} link onClick={() => this.togglePassword(0)} />}
                placeholder={this.props.t("new_password").message || "New password"}
              />
              {this.renderPassCheckMarkIcon("new")}
              {password.newPassword.length > 0 && (
                <div className="strength-meter">
                  <div className="strength-meter-fill" data-strength={password.strength}></div>
                </div>
              )}
              {invalidInput && mode === "create" && !password["newPassword"] && (
                <Label basic color="red" pointing>
                  {t("field_is_required").message || "This field is required."}
                </Label>
              )}
            </Form.Field>
            <Form.Field required>
              <label>{this.props.t("confirm_new_password").message || "Confirm new password"}</label>
              <UserInput
                name="passwordConfirmation"
                style={{ width: "90%" }}
                value={password.passwordConfirmation || ""}
                onChange={this.handlePasswordChange}
                type={passwordVisibility[2] ? "text" : "password"}
                icon={<Icon name={passwordVisibility[2] ? "hide" : "unhide"} link onClick={() => this.togglePassword(2)}></Icon>}
                placeholder={this.props.t("confirm_new_password").message || "Confirm new password"}
              />
              {this.renderPassCheckMarkIcon("confirm")}
              {invalidInput && mode === "create" && !password["passwordConfirmation"] && (
                <Label basic color="red" pointing>
                  {t("field_is_required").message || "This field is required."}
                </Label>
              )}
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field>
              <label>{t("address_1").message || "Address 1"}</label>
              <UserInput value={user.address1} name="address1" placeholder={t("address").message || "Address"} onChange={this.handleInputChange} />
            </Form.Field>

            <Form.Field>
              <label>{t("address_2").message || "Address 2"}</label>
              <UserInput value={user.address2} name="address2" placeholder={t("address").message || "Address"} onChange={this.handleInputChange} />
            </Form.Field>

            <Form.Field>
              <label>{t("phone").message || "Phone"}</label>
              <UserInput value={user.phone} name="phone" placeholder={t("phone").message || "Phone"} onChange={this.handleInputChange} />
            </Form.Field>
            <Form.Field>
              <label>{t("mobile").message || "Mobile"}</label>
              <UserInput value={user.mobile} name="mobile" placeholder={t("mobile").message || "Mobile"} onChange={this.handleInputChange} />
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field>
              <label>{t("birthday_date").message || "Birthday"}</label>
              <DatePicker
                dateFormat="dd-MM-yyyy"
                placeholderText={t("birthday_date").message || "Birthday date"}
                name="birthday"
                selected={user?.birthday && moment(user.birthday).toDate()}
                onChange={this.handleDateChange}
                onChangeRaw={e => e.preventDefault()}
                showMonthDropdown
                showYearDropdown
              />
            </Form.Field>

            <Form.Field>
              <label>{t("multi_device_login").message || "Multi-device login"}</label>
              <Checkbox
                checked={user.multi_device_login_enabled}
                name="multi_device_login_enabled"
                label={t("multi_device_login").message || "Multi-device login"}
                onChange={this.handleCheckboxChange}
              />
            </Form.Field>
            <Form.Field>
              <label>{t("can_download_csv").message || "Can download CSV files"}</label>
              <Checkbox checked={user.csv_download} name="csv_download" label={t("allowed").message || "Allowed"} onChange={this.handleCheckboxChange} />
            </Form.Field>
            <Form.Field>
              <label>{t("keylocker_allowed").message || "Keylocker allowed"}</label>
              <Checkbox checked={user.is_keylocker_allowed} name="is_keylocker_allowed" label={t("allowed").message || "Allowed"} onChange={this.handleCheckboxChange} />
            </Form.Field>
            <Form.Field>
              <label>{this.props.t("use_counter_tablets").message || "Use counter tablets"}</label>
              <Checkbox
                checked={user.is_counter_tablet_user}
                toggle
                onChange={(e, data) => {
                  user.is_counter_tablet_user = data.checked;
                  handleUserChange({ ...user });
                }}
              />
            </Form.Field>
            <Form.Field>
              <label>{this.props.t("enable_tyre_ordering").message || "Enable tyre ordering"}</label>
              <Checkbox checked={user.tyre_ordering_enabled} name="tyre_ordering_enabled" toggle onChange={this.handleCheckboxChange} />
            </Form.Field>
            <Form.Field>
              <label>{this.props.t("list_appointments_by").message || "List appointments by"}</label>
              <Dropdown
                name="list_appointments_by_scheduled_range"
                className="icon"
                value={!!user.list_appointments_by_scheduled_range}
                button
                floating
                icon="list"
                labeled
                onChange={(event, data) => {
                  user.list_appointments_by_scheduled_range = data.value;
                  handleUserChange({ ...user });
                }}
                options={this.appointmentListOptions()}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group>
            {dmsUsers.length > 0 && (
              <Form.Field width={6}>
                <label>{t("dms_user").message || "DMS User"}</label>
                <Dropdown
                  fluid
                  search
                  clearable
                  selection
                  selectOnBlur={false}
                  placeholder={t("dms_user").message || "DMS User"}
                  name="dms_user"
                  loading={loadingDMSUsers}
                  value={user.dms_nr}
                  onChange={(_e, data) => handleUserChange({ ...user, dms_nr: data.value })}
                  options={dmsUsers}
                />
              </Form.Field>
            )}

            {[ROLES.MECHANIC].includes(user.role_id) && oneiPlanningEnabled && (
              <Form.Field width={5}>
                <label>{t("onei_planning_monteur").message || "1i Planning Monteur"}</label>
                <Dropdown
                  fluid
                  search
                  clearable
                  selection
                  placeholder={t("onei_planning_monteur").message || "1i Planning Monteur"}
                  name="onei_planning_monteur_id"
                  loading={loadingPlanningMechanicsLists}
                  value={user.onei_planning_monteur_id}
                  onChange={(_e, data) => handleUserChange({ ...user, onei_planning_monteur_id: data.value })}
                  options={oneiPlanningMechanicsOptions}
                />
              </Form.Field>
            )}

            {[ROLES.MECHANIC].includes(user.role_id) && planitPlanningEnabled && (
              <Form.Field width={5}>
                <label>{t("planit_mechanic").message || "Plan IT Mechanic"}</label>
                <Dropdown
                  fluid
                  search
                  clearable
                  selection
                  selectOnBlur={false}
                  placeholder={t("planit_mechanic").message || "Plan IT Mechanic"}
                  name="planit_planning_mechanic_id"
                  loading={loadingPlanningMechanicsLists}
                  value={user.planit_planning_mechanic_id}
                  onChange={(_e, data) => handleUserChange({ ...user, planit_planning_mechanic_id: data.value })}
                  options={planItMechanicsOptions}
                />
              </Form.Field>
            )}
          </Form.Group>

          {errorMessage && (
            <Form.Group>
              <Form.Field width={16}>
                <Message color="red">{errorMessage}</Message>
              </Form.Field>
            </Form.Group>
          )}
        </Form>
      )
    );
  }
}

export default withTranslation()(UserForm);
