import React, { Component } from "react";
import { Form, Checkbox, Header, Icon, Segment, Dropdown } from "semantic-ui-react";
import { withTranslation } from "react-i18next";

import UserInput from "../UserInput";
import { APPOINTMENT_STATUSES } from "../../modules/Appointments/common";

class AppointmentUnpinConfiguration extends Component {
  handleCheckboxChange = (e, { name, checked }) => {
    const { location, onLocationChange } = this.props;

    onLocationChange({ ...location, [name]: checked });
  };

  handleDropdownChange = (_e, { name, value }) => {
    const { location, onLocationChange } = this.props;

    onLocationChange({ ...location, [name]: value });
  };

  handleInputNumberChange = (_e, { name, value }) => {
    const { location, onLocationChange } = this.props;

    if (value < 0 || isNaN(Number(value))) return;

    onLocationChange({ ...location, [name]: Number(value) });
  };

  render() {
    const { location, t } = this.props;

    const appointmentStatusOptions = Object.keys(APPOINTMENT_STATUSES)
      .filter(key => APPOINTMENT_STATUSES[key] < APPOINTMENT_STATUSES.LAST_REAL_STATUS)
      .map(key => {
        const value = APPOINTMENT_STATUSES[key];
        const text = key.toString().toLowerCase();

        return { text: t(text).message || text.replaceAll("_", " "), value, key: value };
      });

    return (
      <Segment>
        <Header as="h5">
          <Icon name="wrench" />
          <Header.Content>{t("unpin_appointments").message || "Unpin appointments"}</Header.Content>
        </Header>
        <Segment>
          <Form>
            <Form.Field className="full-width-input">
              <label htmlFor="unpin_appointments_on_selected_statuses">
                {t("auto_unpin_appointments_on_selected_status").message || "Automatically unpin appointments on selected statuses"}
              </label>
              <Dropdown
                selection
                multiple
                clearable
                id="unpin_appointments_on_selected_statuses"
                name="auto_unpin_appointment_statuses"
                placeholder={t("auto_unpin_appointments_on_selected_status").message || "Automatically unpin appointments on selected statuses"}
                options={appointmentStatusOptions}
                value={location.auto_unpin_appointment_statuses}
                onChange={this.handleDropdownChange}
              />
            </Form.Field>

            <Form.Field className="full-width-input">
              <label htmlFor="unpin_past_appointments_older_than">{t("unpin_past_appointments_older_than").message || "Unpin appointments older than [x] days"}</label>
              <UserInput
                type="number"
                id="unpin_past_appointments_older_than"
                name="unpin_past_appointments_older_than"
                onChange={this.handleInputNumberChange}
                value={location.unpin_past_appointments_older_than}
              />
            </Form.Field>

            <Form.Field className="full-width-input">
              <label htmlFor="unpin_future_appointments_further_than">
                {t("unpin_future_appointments_further_than").message || "Unpin appointments further than [x] days"}
              </label>
              <UserInput
                type="number"
                id="unpin_future_appointments_further_than"
                name="unpin_future_appointments_further_than"
                onChange={this.handleInputNumberChange}
                value={location.unpin_future_appointments_further_than}
              />
            </Form.Field>

            <Form.Field className="full-width-input">
              <Checkbox
                name="unpin_appointments_on_is_billed"
                checked={location.unpin_appointments_on_is_billed}
                onChange={this.handleCheckboxChange}
                label={t("unpin_appointments_on_is_billed").message || "Unpin Appointments On Is Billed"}
              />
            </Form.Field>
            <Form.Field className="full-width-input">
              <Checkbox
                name="unpin_appointments_on_car_out_of_shop"
                checked={location.unpin_appointments_on_car_out_of_shop}
                onChange={this.handleCheckboxChange}
                label={t("unpin_appointments_on_car_out_of_shop").message || "Unpin Appointments On Car Out Of Shop"}
              />
            </Form.Field>
          </Form>
        </Segment>
      </Segment>
    );
  }
}

export default withTranslation()(AppointmentUnpinConfiguration);
