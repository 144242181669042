import React, { Component } from "react";
import { Grid, Search, Form, Modal, Button, Icon, Popup } from "semantic-ui-react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import Loader from "react-loader-spinner";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport } from "@fortawesome/pro-regular-svg-icons";
import moment from "moment";

import { SubHeader, SearchPortal, UserMenuActionsPortal } from "../../../components";
import SnoozeOptions from "../../../components/Snooze/SnoozeOptions";
import { getDealerAndLocationById, getLocationById } from "../../../util/common";
import { setAlert } from "../../../modules/App/store.js";
import { getAppointment, deselectAppointment, getChecks, applyUpdateToAppointment, handleUpdateAppointments, APPOINTMENTS_ACTION_TYPES } from "../../Appointments/store";
import { getCar, deselectCar, CARS_ACTION_TYPES } from "../../Cars/store";
import AppointmentDetail from "../../Appointments/AppointmentDetail";
import CarDetail from "../../Cars/CarDetail";
import CarFilters from "./CarFilters";
import CarTable from "./CarTable";
import { normalizeResults, prepareQuestionDropdownOptions } from "../common";

import Service from "../service";

import "../Leads.css";

class CarLeads extends Component {
  state = {
    items: [],
    page: 0,
    nb_pages: null,
    nb_items: null,
    isLoading: false,
    isLoadingLeads: false,
    isAppointmentDetailVisible: false,
    selectedAppointment: null,
    isCarDetailVisible: false,
    selectedCar: null,
    searchTerm: "",
    selectedCarInfo: {},
    customDateFrom: new Date(),
    customDateTo: new Date().setMonth(new Date().getMonth() + 1),
    selectedIsLease: null,
    exportURL: "",
    exportModalOpen: false,
    isLoadingExport: false,
    exportError: false,
    shouldReset: false,
    selectedLocationsIDs: [],
    selectedDealersIDs: [],
    tags: [],
    questions: [],
    isLoadingTags: false,
  };

  componentDidMount() {
    SnoozeOptions.getDropdownOptions()
      .then(result => {
        const { _snoozeOptions, ...rest } = result;

        this.setState({ ...rest });
      })
      .catch(error => {
        console.log("Error getting snooze options", error);
        this.props.setAlert({ type: "error", title: "Cannot load snooze options" });
      });

    this.getTags();
  }

  componentDidUpdate = prevProps => {
    const { selectedAppointment, selectedCar } = this.state;
    const { appointmentsState, carsState, setAlert, t } = this.props;

    if (appointmentsState.actionType !== prevProps.appointmentsState.actionType) {
      if (selectedAppointment) {
        if (appointmentsState.actionType === APPOINTMENTS_ACTION_TYPES.GET_APPOINTMENT_FAIL) {
          setAlert({ type: "error", title: "Cannot load this appointment" });
          this.setState({ selectedAppointment: null, isLoading: false });
        }

        if (appointmentsState.actionType === APPOINTMENTS_ACTION_TYPES.GET_APPOINTMENT_SUCCESS) this.setState({ isAppointmentDetailVisible: true, isLoading: false });

        if (appointmentsState.actionType === APPOINTMENTS_ACTION_TYPES.WEB_SOCKET_APPOINTMENTS_UPDATE && appointmentsState.webSocketUpdate) {
          this.socketAppointmentUpdate(appointmentsState.webSocketEvent);
        }
      }
    }

    if (carsState.actionType !== prevProps.carsState.actionType) {
      if (selectedCar) {
        if (carsState.actionType === CARS_ACTION_TYPES.GET_CAR_FAIL) {
          this.setState({ selectedCar: null, isLoading: false }, () => setAlert({ type: "error", title: t("can_not_load_this_car").message || "Can not load this car" }));
        } else if (carsState.actionType === CARS_ACTION_TYPES.GET_CAR_NOT_AUTHORIZED) {
          this.setState({ selectedCar: null, isLoading: false }, () =>
            setAlert({ type: "error", title: t("car_location_no_access_err_msg").message || "This car has moved to a location you cannot access" })
          );
        }

        if (carsState.actionType === CARS_ACTION_TYPES.GET_CAR_SUCCESS) {
          if (!carsState.selectedCar) {
            this.setState({ selectedCar: null, isLoading: false });
          } else {
            this.setState({
              isLoading: false,
              isCarDetailVisible: true,
              selectedCarInfo: {
                car: carsState.selectedCar,
                customer: carsState.selectedCustomer,
                appointmentHistory: carsState.selectedCarAppointments,
                snoozedQuestions: carsState.selectedCarSnoozedQuestions,
              },
            });
          }
        }
      }
    }
  };

  socketAppointmentUpdate = event => {
    let updatePayload = null;
    if (event.payload?.data) updatePayload = JSON.parse(event.payload.data);

    // apply update to appointmen list and appointment detail page if open
    this.props.applyUpdateToAppointment(updatePayload, true);
  };

  handleSearchChange = (_e, data) => {
    this.setState({ searchTerm: data.value }, () => this.getLeadsItems());
  };

  getTags = () => {
    this.setState({ isLoadingTags: true }, () => {
      Service.getTags()
        .then(res => {
          let tags = [];

          if (Array.isArray(res.data?.data?.tags)) tags = res.data.data.tags.filter(t => t.system);
          this.setState({ tags, isLoadingTags: false });
        })
        .catch(err => {
          this.setState({ isLoadingTags: false });
          this.props.setAlert({ type: "error", title: "Cannot load tags" });
        });
    });
  };

  getCarLeads = () => {
    const { page, searchTerm, selectedDealersIDs, selectedLocationsIDs, customDateFrom, customDateTo, selectedIsLease } = this.state;

    const params = {
      page,
      date_from: moment(customDateFrom).format("YYYY-MM-DDT00:00:00Z"),
      date_to: moment(customDateTo).format("YYYY-MM-DDT23:59:59Z"),
      dealer_ids: selectedDealersIDs,
      location_ids: selectedLocationsIDs,
      search_term: searchTerm,
      is_lease_company: selectedIsLease === true || selectedIsLease === false ? selectedIsLease : null,
    };

    return Service.getCarLeads(params);
  };

  getLeadsItems = page => {
    if (!page) page = 1;
    else if (page === this.state.page || this.state.isLoadingLeads) return; // react table is going to fire this incorrectly

    this.setState({ isLoadingLeads: true, page }, async () => {
      const { selectedLocationsIDs, selectedDealersIDs } = this.state;

      if (selectedLocationsIDs.length < 1 && selectedDealersIDs.length < 1) {
        this.setState({ items: [], nb_pages: 0, nb_items: 0, isLoadingLeads: false });
        return;
      }

      try {
        const response = await this.getCarLeads();
        const { items, nb_pages, nb_items } = normalizeResults(response);

        this.setState({ items, page, nb_pages, nb_items, isLoadingLeads: false });
      } catch (error) {
        console.log("Error getting leads", error);
        this.setState({ isLoadingLeads: false });
        this.props.setAlert({ type: "error", title: "Cannot load leads" });
        return;
      }
    });
  };

  handleChangeCustomDate = dates => {
    const [start, end] = dates;
    this.setState({ customDateFrom: start, customDateTo: end }, () => {
      if (!end) return;
      this.getLeadsItems();
    });
  };

  handleChangeSelectedLocationsIDs = ({ location_ids, dealer_ids }) => {
    const prevSelectedLocationIDs = [...this.state.selectedLocationsIDs];
    const prevSelectedDealerIDs = [...this.state.selectedDealersIDs];

    this.setState({ selectedLocationsIDs: location_ids, selectedDealersIDs: dealer_ids }, () => {
      if (location_ids.length !== prevSelectedLocationIDs.length || dealer_ids.length !== prevSelectedDealerIDs.length) this.getQuestionsByChecklist();

      this.getLeadsItems();
    });
  };

  handleCloseExportModal = () => this.setState({ exportURL: "", exportError: false, isLoadingExport: false, exportModalOpen: false });

  handleChangeIsLease = selectedIsLease => this.setState({ selectedIsLease }, () => this.getLeadsItems());

  getQuestionsByChecklist = () => {
    const { selectedDealersIDs, selectedLocationsIDs } = this.state;

    if (!selectedDealersIDs.length && !selectedLocationsIDs.length) {
      this.setState({ questions: [] });
      return;
    }

    const requestData = {
      dealer_ids: selectedDealersIDs.length ? selectedDealersIDs : null,
      location_ids: selectedLocationsIDs.length ? selectedLocationsIDs : null,
      embed: "question",
    };

    this.setState({ isLoadingQuestions: true }, () => {
      Service.getChecklists(requestData)
        .then(res => {
          if (res?.data?.data?.checklists) this.setState({ questions: prepareQuestionDropdownOptions(res.data.data.checklists), isLoadingQuestions: false });
        })
        .catch(err => {
          this.setState({ isLoadingQuestions: false });
          this.props.setAlert({ type: "error", title: "Cannot load question options" });
        });
    });
  };

  handleExportCarLeads = () => {
    const { searchTerm, selectedLocationsIDs, customDateFrom, customDateTo, selectedIsLease } = this.state;

    const params = {
      date_from: moment(customDateFrom).format("YYYY-MM-DDT00:00:00Z"),
      date_to: moment(customDateTo).format("YYYY-MM-DDT23:59:59Z"),
      location_ids: selectedLocationsIDs,
      search_term: searchTerm,
      is_lease_company: selectedIsLease === true || selectedIsLease === false ? selectedIsLease : null,
    };

    return Service.exportCarLeads(params);
  };

  handleExport = () => {
    this.setState({ exportModalOpen: true, isLoadingExport: true }, async () => {
      try {
        const response = await this.handleExportCarLeads();

        const exportURL = response?.data?.data || "";
        this.setState({ exportURL, isLoadingExport: false });
      } catch (error) {
        this.setState({ isLoadingExport: false, exportError: true });
      }
    });
  };

  handleRefresh = () => {
    this.getLeadsItems();
  };

  handleResetFilters = () => {
    this.setState(
      {
        shouldReset: true,
        customDateFrom: new Date(),
        customDateTo: new Date().setMonth(new Date().getMonth() + 1),
        selectedIsLease: null,
        selectedSnoozedDepartment: null,
      },
      () => {
        this.setState({ shouldReset: false }, () => this.getLeadsItems());
      }
    );
  };

  handleAppointmentClick = appointment => {
    this.setState({ selectedAppointment: { id: appointment.id, dealer_location_id: appointment.dealer_location_id }, isLoading: true }, () =>
      this.props.getAppointment(appointment.id)
    );
  };

  handleUpdateSelectedAppointmentFromCarDetail = appointment_id => {
    this.setState({ selectedAppointment: { appointment_id, dealer_location_id: this.state.selectedCar.dealer_location_id }, isLoading: true }, () =>
      this.props.getAppointment(appointment_id)
    );
  };

  handleCloseAppointmentDetail = () => {
    this.setState({ selectedAppointment: null, isAppointmentDetailVisible: false });
    this.props.deselectAppointment();
  };

  handleCarClick = (car_id, dealer_location_id) => {
    this.setState(
      {
        selectedCar: { car_id, dealer_location_id },
        isLoading: true,
        isAppointmentDetailVisible: false,
        selectedAppointment: null,
      },
      () => {
        this.props.getCar(car_id);
      }
    );
  };

  handleCloseCarDetail = () => {
    this.setState({ selectedCar: null, isCarDetailVisible: false, selectedCarInfo: {} });
    this.props.deselectCar();
  };

  renderAppointmentDetail = () => {
    if (!this.state.isAppointmentDetailVisible || !this.props.appointmentsState.selectedAppointment) return null;

    const { authState, appointmentsState, globalState, getChecks, handleUpdateAppointments } = this.props;
    const { dealer, location } = getDealerAndLocationById(globalState.dealers, appointmentsState.selectedAppointment.dealer_location_id);

    return (
      <AppointmentDetail
        detailPageOnlineUsers={appointmentsState.detailPageOnlineUsers}
        currentUser={authState.user}
        appointment={appointmentsState.selectedAppointment}
        appointmentChecks={appointmentsState.selectedAppointmentChecks}
        location={location}
        dealer={dealer}
        getChecks={getChecks}
        onRegistrationClick={car_id => this.handleCarClick(car_id, appointmentsState.selectedAppointment.dealer_location_id)}
        onClose={this.handleCloseAppointmentDetail}
        onHandleUpdateAppointments={handleUpdateAppointments}
      />
    );
  };

  renderCarDetail = () => {
    const { selectedCarInfo } = this.state;
    const { globalState } = this.props;

    const location = getLocationById(globalState.dealers, selectedCarInfo.car.dealer_location_id);

    return (
      <CarDetail
        visible={true}
        car={selectedCarInfo.car}
        customer={selectedCarInfo.customer}
        appointmentHistory={selectedCarInfo.appointmentHistory}
        snoozedQuestions={selectedCarInfo.snoozedQuestions}
        location={location}
        onHide={() => this.handleCloseCarDetail()}
        onSelectAppointment={id => this.handleUpdateSelectedAppointmentFromCarDetail(id)}
      />
    );
  };

  render() {
    const {
      isAppointmentDetailVisible,
      isCarDetailVisible,
      isLoading,
      customDateFrom,
      customDateTo,
      items,
      page,
      nb_pages,
      nb_items,
      isLoadingLeads,
      selectedLocationsIDs,
      isLoadingExport,
      exportURL,
      exportModalOpen,
      exportError,
      shouldReset,
    } = this.state;

    const { authState, t } = this.props;
    const { csv_download } = authState.user;

    return (
      <>
        <SearchPortal>
          <Search
            minCharacters={4}
            className="-large-search"
            input={{
              icon: "search",
              iconPosition: "left",
              placeholder: t("car_leads_search").message || "start searching leads by Make, model or registration...",
            }}
            loading={false}
            showNoResults={false}
            onSearchChange={_.debounce(this.handleSearchChange, 500)}
            fluid
          />
        </SearchPortal>
        <UserMenuActionsPortal>
          <Icon name="refresh" onClick={this.handleRefresh} />
        </UserMenuActionsPortal>
        <SubHeader>
          <Grid.Column width={16} className="header-container" style={{ justifyContent: "right" }}>
            <div className="export-btn-leads-type-container">
              <Form.Field>
                <Button icon size="small" labelPosition="left" className="reset-filters-btn" onClick={this.handleResetFilters}>
                  <Icon name="trash" color="green" style={{ backgroundColor: "white", borderRight: "1px solid #E8E8E8" }} />
                  <b>{t("reset_filter").message || "Reset Filter"}</b>
                </Button>
              </Form.Field>

              {csv_download && (
                <Popup
                  position="top right"
                  content={t("leads_export_disabled").message || "Export disabled as more than one location is selected."}
                  disabled={selectedLocationsIDs.length === 1}
                  trigger={
                    <div style={{ display: "inline-block" }}>
                      <Button onClick={this.handleExport} disabled={selectedLocationsIDs.length !== 1} className="export-button" floated="right" basic color="green">
                        <span style={{ marginRight: "5px" }}>{t("export").message || "Export"}</span>
                        <FontAwesomeIcon icon={faFileExport} />
                      </Button>
                    </div>
                  }
                />
              )}
            </div>

            <Modal size="tiny" open={exportModalOpen} onClose={this.handleCloseExportModal} closeOnDimmerClick={false} className="ExportLeads-Modal">
              <Modal.Content className="export-modal-content">
                {isLoadingExport && <p>{t("export_in_progress").message || "Export in progress..."}</p>}
                {!isLoadingExport && exportURL && <p>{t("export_ready").message || "Export ready to be downloaded"}</p>}
                {!isLoadingExport && (exportError || !exportURL) && <p>{t("failed_error_message").message || "Something went wrong, please try again."}</p>}
              </Modal.Content>
              <Modal.Actions>
                <Button onClick={this.handleCloseExportModal} color="grey">
                  {t("close").message || "Close"}
                </Button>
                <Button onClick={() => window.open(exportURL, "_blank")} disabled={isLoadingExport || !exportURL} color="green">
                  {t("download").message || "Download"}
                </Button>
              </Modal.Actions>
            </Modal>
          </Grid.Column>
          <Grid.Column width={16}>
            <CarFilters
              onChangeCustomDates={this.handleChangeCustomDate}
              customDateFrom={customDateFrom}
              customDateTo={customDateTo}
              onChangeIsLease={this.handleChangeIsLease}
              onChangeSelectedLocationIDs={this.handleChangeSelectedLocationsIDs}
              shouldReset={shouldReset}
            />
          </Grid.Column>
        </SubHeader>

        <CarTable
          page={page}
          pages={nb_pages}
          nb_items={nb_items}
          loading={isLoadingLeads}
          onFetchData={this.getLeadsItems}
          items={items}
          onAppointmentClick={this.handleAppointmentClick}
          onCarClick={this.handleCarClick}
        />

        {isAppointmentDetailVisible && this.renderAppointmentDetail()}
        {isCarDetailVisible && this.renderCarDetail()}

        {(isLoading || isLoadingLeads) && (
          <div className="Loader">
            <Loader type="Oval" color="#46923d" height="100" width="100" />
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = state => {
  return { globalState: state.global, appointmentsState: state.appointments, carsState: state.cars, authState: state.auth };
};

const mapDispatchToProps = dispatch => {
  return {
    applyUpdateToAppointment: (updates, skipMainTableUpdate) => dispatch(applyUpdateToAppointment(updates, skipMainTableUpdate)),
    deselectAppointment: () => dispatch(deselectAppointment()),
    deselectCar: () => dispatch(deselectCar()),
    getAppointment: appID => dispatch(getAppointment(appID)),
    getCar: carID => dispatch(getCar(carID)),
    getChecks: appID => dispatch(getChecks(appID)),
    handleUpdateAppointments: appointment => dispatch(handleUpdateAppointments(appointment)),
    setAlert: alertOptions => dispatch(setAlert(alertOptions)),
  };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(CarLeads));
