Object.defineProperty(exports, "__esModule", {
  value: true,
});

var _extends =
  Object.assign ||
  function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];
      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }
    return target;
  };

var _createClass = (function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }
  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
})();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _scroller = require("./scroller");

var _scroller2 = _interopRequireDefault(_scroller);

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj };
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }
  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }
  subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : (subClass.__proto__ = superClass);
}

exports.default = function (Component) {
  var Element = (function (_React$Component) {
    _inherits(Element, _React$Component);

    function Element(props) {
      _classCallCheck(this, Element);

      var _this = _possibleConstructorReturn(this, (Element.__proto__ || Object.getPrototypeOf(Element)).call(this, props));

      _this.childBindings = {
        domNode: null,
      };
      return _this;
    }

    _createClass(Element, [
      {
        key: "componentDidMount",
        value: function componentDidMount() {
          if (typeof window === "undefined") {
            return false;
          }
          this.registerElems(this.props.name);
        },
      },
      {
        key: "componentDidUpdate",
        value: function componentDidUpdate(prevProps) {
          if (this.props.name !== prevProps.name) {
            this.registerElems(this.props.name);
          }
        },
      },
      {
        key: "componentWillUnmount",
        value: function componentWillUnmount() {
          if (typeof window === "undefined") {
            return false;
          }
          _scroller2.default.unregister(this.props.name);
        },
      },
      {
        key: "registerElems",
        value: function registerElems(name) {
          _scroller2.default.register(name, this.childBindings.domNode);
        },
      },
      {
        key: "render",
        value: function render() {
          return _react2.default.createElement(Component, _extends({}, this.props, { parentBindings: this.childBindings }));
        },
      },
    ]);

    return Element;
  })(_react2.default.Component);

  Element.propTypes = {
    name: _propTypes2.default.string,
    id: _propTypes2.default.string,
  };

  return Element;
};
