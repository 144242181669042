import React, { Component } from "react";
import { PieChart, Pie, Tooltip, Legend, Cell, Text, Curve } from "recharts";

const COLORS = ["#278ecf", "#4bd762", "#ff402c", "#ff9416", "#d42ae8", "#535ad7", "#83bfff", "#ffca1f", "#6edb8f", "#ffe366", "#ffc266", "#8784db"];

class TotalPieChart extends Component {
  state = {
    activeLegend: null,
  };

  handleMouseEnter = props => {
    this.setState({ activeLegend: props.value });
  };

  handleMouseLeave = props => {
    this.setState({ activeLegend: null });
  };

  render() {
    const { data, t, colors } = this.props;

    return (
      <PieChart className={"full-width-margin-auto"} width={1000} height={350}>
        <Pie
          data={data}
          dataKey="value"
          isAnimationActive={false}
          cx={400}
          cy={175}
          innerRadius={115 - 30}
          outerRadius={115}
          labelLine={props => <Curve {...props} stroke="#595959" type="linear" className="recharts-pie-label-line" />}
          label={props => (
            <Text {...props} fill="#595959" alignmentBaseline="middle" className="recharts-pie-label-text">
              {(props.percent * 100).toFixed(0) + "%"}
            </Text>
          )}
        >
          {data
            .filter(d => d !== "total")
            .map((entry, index) => (
              <Cell
                key={index}
                label
                fillOpacity={this.state.activeLegend === entry.name ? 0.5 : 1}
                fill={colors.find(col => (t(col.name).message || col.name) === entry.name)?.color || COLORS[index % COLORS.length]}
              />
            ))}
        </Pie>
        <text x={400} y={175} dy={11} textAnchor="middle" fontSize="22" fontWeight="bold" fill="#595959">
          <tspan x="400" dy={data.length > 0 && data[0]["total"] ? 0 : 15}>
            {this.props.t("total").message || "Total"} {data.reduce((a, b) => a + (b.value || 0), 0)}
          </tspan>
          {data.length > 0 && data[0]["total"] && (
            <tspan x="400" dy="30">
              {this.props.t("WO").message || "WO"} {data[0]["total"]}
            </tspan>
          )}
        </text>
        <Tooltip label />
        <Legend layout="vertical" verticalAlign="middle" align="right" onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave} />
      </PieChart>
    );
  }
}

export default TotalPieChart;
