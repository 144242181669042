import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Icon } from "semantic-ui-react";
import { connect } from "react-redux";

import { SnoozeTable } from "../../components";

import { getSnoozeAppointmentHistory, handleSnoozeChanged, handleSnoozeDeleted } from "./store";

import "./AppointmentSnoozedItems.css";

class AppointmentSnoozedItems extends Component {
  state = {
    error: null,
    contentDisplayed: false,
  };

  handleCarClick = ({ car_id }) => {
    window.open(`#!/appointments/car/${car_id}`, "_blank");
  };

  toggleContent = () => {
    this.setState(state => ({ contentDisplayed: !state.contentDisplayed }));
  };

  render() {
    const { contentDisplayed } = this.state;
    const { t, items, page, nb_pages, nb_items, isLoading, handleSnoozeDeleted, handleSnoozeChanged, getSnoozeAppointmentHistory } = this.props;

    return (
      <div className="AppointmentSnoozedItems">
        <div className="appointment-snoozed-container" style={{ display: items.length > 0 ? "block" : "none" }}>
          <div className="appointment-snoozed-title">
            <h1 onClick={this.toggleContent}>
              <Icon name="clock" />
              {t("active_snoozed_items_history").message || "Active snoozed items history"}
              {` (${nb_items})`}
              <Icon name={`angle ${contentDisplayed ? "up" : "down"}`} />
            </h1>
          </div>

          <div style={{ display: !contentDisplayed ? "none" : "" }}>
            <SnoozeTable
              externalLink
              page={page}
              pages={nb_pages}
              items={items}
              loading={isLoading}
              onCarClick={this.handleCarClick}
              onSnoozeDeleted={handleSnoozeDeleted}
              onSnoozeChanged={handleSnoozeChanged}
              onFetchData={getSnoozeAppointmentHistory}
              selectedIsSnoozed={true}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    page: state.appointments.selected_appointment_history_snoozed_questions_page,
    items: state.appointments.selected_appointment_history_snoozed_questions,
    nb_pages: state.appointments.selected_appointment_history_snoozed_questions_nb_pages,
    nb_items: state.appointments.selected_appointment_history_snoozed_questions_nb_items,
    isLoading: state.appointments.loading_selected_appointment_history_snoozed_questions,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getSnoozeAppointmentHistory: page => dispatch(getSnoozeAppointmentHistory(page)),
    handleSnoozeChanged: updatedSnoozeLog => dispatch(handleSnoozeChanged(updatedSnoozeLog)),
    handleSnoozeDeleted: groupedItem => dispatch(handleSnoozeDeleted(groupedItem)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AppointmentSnoozedItems));
