import React, { Component } from "react";
import ReactTable from "react-table";
import { Icon } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

import AppointmentQuestionResult from "./AppointmentQuestionResult";
import { getAdvisedCriticalHistory, handleAnswerUpdateAdvisedCriticalHistory, handleMediaToggleAdvisedCriticalHistory } from "./store";

import Service from "./service";

import "./AppointmentAdvisedCriticalHistory.scss";

class AppointmentAdvisedCriticalHistory extends Component {
  state = {
    contentDisplayed: false,
    historyDisplayed: false,
  };

  handleAppointmentClick = appointmentId => {
    window.open(`${document.location.origin}/#!/appointments/${appointmentId}`, "_blank");
  };

  toggleContent = () => this.setState(prevState => ({ contentDisplayed: !prevState.contentDisplayed }));

  toggleHistory = () => {
    this.setState(
      state => ({ historyDisplayed: !state.historyDisplayed }),
      () => {
        this.props.getAdvisedCriticalHistory(null, this.state.historyDisplayed);
      }
    );
  };

  toggleHistoryItem = question => {
    const { id, appointment_id, dealer_location_id } = question;

    Service.updateQuestion({ id, appointment_id, dealer_location_id, is_hidden_in_history: !question.is_hidden_in_history }).catch(err => {
      console.log("Failed to update question item", err);
    });
  };

  render() {
    const { contentDisplayed, historyDisplayed } = this.state;
    const { t, appointment, question_results, dashboardConfig, onMediaClick, location, nb_count, nb_pages, isLoading, page } = this.props;

    const { vat, include_vat } = location;

    return (
      <div className="AdvisedCriticalItemsHistory">
        <div className="advised-critical-items-container">
          <div className="advised-critical-items-title">
            <h1 onClick={this.toggleContent}>
              <Icon name="history" />
              {`${t("advised_critical_items_history").message || "Advised and Critical Items History"} (${nb_count})`}
              <Icon name={`angle ${contentDisplayed ? "up" : "down"}`} />
            </h1>
          </div>

          <div style={{ display: !contentDisplayed ? "none" : "" }}>
            <AppointmentQuestionResult
              readOnly
              vat={vat}
              isEditable={false}
              includeVAT={include_vat}
              appointment={appointment}
              questions={question_results}
              dashboardConfig={dashboardConfig}
              onMediaClick={onMediaClick}
              onMediaToggle={this.props.handleMediaToggleAdvisedCriticalHistory}
              onAppointmentClick={this.handleAppointmentClick}
              onAnswerUpdate={this.props.handleAnswerUpdateAdvisedCriticalHistory}
              onToggleHistory={this.toggleHistory}
              onToggleHistoryItem={this.toggleHistoryItem}
              historyDisplayed={historyDisplayed}
              isAdvisedCriticalItemsSection
              isLoading={isLoading}
              location={location}
            />

            <ReactTable
              className="-floated-table"
              page={page - 1}
              pages={nb_pages === null ? -1 : nb_pages}
              showPagination={nb_pages > 1}
              loading={isLoading}
              showPageSizeOptions={false}
              manual
              onFetchData={state => this.props.getAdvisedCriticalHistory(state.page + 1)}
              loadingText=""
              nextText={t("next").message || "Next"}
              previousText={t("previous").message || "Previous"}
              pageText={t("page").message || "Page"}
              ofText={t("of").message || "of"}
              columns={[]}
              noDataText={question_results.length ? "" : t("no_items").message || "No Items"}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isLoading: state.appointments.loading_selected_appointment_history_pin_questions,
    question_results: state.appointments.selected_appointment_history_pin_questions,
    nb_pages: state.appointments.selected_appointment_history_pin_questions_nb_pages,
    nb_count: state.appointments.selected_appointment_history_pin_questions_nb_count,
    page: state.appointments.selected_appointment_history_pin_questions_page,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getAdvisedCriticalHistory: (page, hiddenHistory) => dispatch(getAdvisedCriticalHistory(page, hiddenHistory)),
    handleAnswerUpdateAdvisedCriticalHistory: update => dispatch(handleAnswerUpdateAdvisedCriticalHistory(update)),
    handleMediaToggleAdvisedCriticalHistory: questionIndex => dispatch(handleMediaToggleAdvisedCriticalHistory(questionIndex)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AppointmentAdvisedCriticalHistory));
