import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Form, Dropdown, Checkbox, Header, Icon, Segment } from "semantic-ui-react";

class PinConfiguration extends Component {
  handleInputChange = e => {
    let { location, onLocationChange } = this.props;

    if (Object.keys(location).length === 0) return;

    onLocationChange({ ...location, [e.target.name]: e.target.value });
  };

  handleCheckboxChange = (e, { name, checked }) => {
    let { location, onLocationChange } = this.props;

    onLocationChange({ ...location, [name]: checked });
  };

  handleBrandsDropdownChange = (e, { value }) => {
    let { location, brands, onLocationChange } = this.props;
    const warranty_pin_signature_brands = brands?.filter(brand => value.includes(brand.id));
    onLocationChange({ ...location, warranty_pin_signature_brands });
  };

  render() {
    const { location, brands, t } = this.props;

    const brandOptions = brands.map(brand => ({ key: brand.id, value: brand.id, text: brand.name }));

    return (
      <Segment>
        <Header as="h5">
          <Icon name="settings" />
          <Header.Content>{t("pin_configuration").message || "Pin Configuration"}</Header.Content>
        </Header>

        <Form>
          <Form.Field>
            <Checkbox checked={location.pin_visible} name="pin_visible" label={t("pinning").message || "Pinning"} onChange={this.handleCheckboxChange} />
          </Form.Field>

          {location.pin_visible && (
            <>
              <Form.Field>
                <Checkbox
                  checked={location.is_warranty_pin_signature_missing_warning_enabled}
                  name="is_warranty_pin_signature_missing_warning_enabled"
                  label={t("warranty_signature_missing_red_warning").message || "Warranty signature missing (Red warning)"}
                  onChange={this.handleCheckboxChange}
                />

                {location.is_warranty_pin_signature_missing_warning_enabled && (
                  <Form.Field>
                    <Dropdown
                      options={brandOptions}
                      onChange={this.handleBrandsDropdownChange}
                      value={location.warranty_pin_signature_brands?.map(brand => brand.id)}
                      placeholder={t("select_brands").message || "Select Brands"}
                      selection
                      multiple
                      selectOnBlur={false}
                      fluid
                    />
                  </Form.Field>
                )}
              </Form.Field>

              <Segment>
                <Header as="h5">
                  <Header.Content>{t("pin_warranty_options").message || "Pin warranty options"}</Header.Content>
                </Header>
                <Segment.Inline>
                  <Form>
                    <Form.Field>
                      <Checkbox
                        checked={location.is_warranty_pin_support_warning_enabled}
                        name="is_warranty_pin_support_warning_enabled"
                        label={t("missing_support_nr").message || "Missing support # (Orange warning)"}
                        onChange={this.handleCheckboxChange}
                      />
                    </Form.Field>
                    <Form.Field>
                      <Checkbox
                        checked={location.is_warranty_pin_claim_warning_enabled}
                        name="is_warranty_pin_claim_warning_enabled"
                        label={t("missing_claim_nr").message || "Missing claim # (Orange warning)"}
                        onChange={this.handleCheckboxChange}
                      />
                    </Form.Field>
                  </Form>
                </Segment.Inline>
              </Segment>

              <Segment>
                <Header as="h5">
                  <Header.Content>{t("pin_recall_options").message || "Pin recall options"}</Header.Content>
                </Header>
                <Segment.Inline>
                  <Form>
                    <Form.Field>
                      <Checkbox
                        checked={location.is_recall_pin_support_warning_enabled}
                        name="is_recall_pin_support_warning_enabled"
                        label={t("missing_support_nr").message || "Missing support # (Orange warning)"}
                        onChange={this.handleCheckboxChange}
                      />
                    </Form.Field>
                    <Form.Field>
                      <Checkbox
                        checked={location.is_recall_pin_claim_warning_enabled}
                        name="is_recall_pin_claim_warning_enabled"
                        label={t("missing_claim_nr").message || "Missing claim # (Orange warning)"}
                        onChange={this.handleCheckboxChange}
                      />
                    </Form.Field>
                  </Form>
                </Segment.Inline>
              </Segment>
            </>
          )}
        </Form>
      </Segment>
    );
  }
}

export default withTranslation()(PinConfiguration);
