import Modal from "./modal";
import Browser from "./browser";

export function addWrapper(htmlData, params) {
  let bodyStyle = "font-family:" + params.font + " !important; font-size: " + params.font_size + " !important; width:100%;";
  return '<div style="' + bodyStyle + '">' + htmlData + "</div>";
}

export function capitalizePrint(obj) {
  return obj.charAt(0).toUpperCase() + obj.slice(1);
}

export function collectStyles(element, params) {
  let win = document.defaultView || window;

  let elementStyle = "";

  let styles = win.getComputedStyle(element, "");

  Object.keys(styles).forEach(key => {
    if (params.targetStyles.indexOf("*") !== -1 || params.targetStyle.indexOf(styles[key]) !== -1 || targetStylesMatch(params.targetStyles, styles[key])) {
      if (styles.getPropertyValue(styles[key])) elementStyle += styles[key] + ":" + styles.getPropertyValue(styles[key]) + ";";
    }
  });

  elementStyle += "max-width: " + params.maxWidth + "px !important;" + params.font_size + " !important;";

  return elementStyle;
}

function targetStylesMatch(styles, value) {
  for (let i = 0; i < styles.length; i++) {
    if (typeof value === "object" && value.indexOf(styles[i]) !== -1) return true;
  }
  return false;
}

export function addHeader(printElement, params) {
  let headerContainer = document.createElement("div");

  if (isRawHTML(params.header)) {
    headerContainer.innerHTML = params.header;
  } else {
    let headerElement = document.createElement("h1");

    let headerNode = document.createTextNode(params.header);

    headerElement.appendChild(headerNode);
    headerElement.setAttribute("style", params.headerStyle);
    headerContainer.appendChild(headerElement);
  }

  printElement.insertBefore(headerContainer, printElement.childNodes[0]);
}

export function cleanUp(params) {
  if (params.showModal) Modal.close();

  if (params.onLoadingEnd) params.onLoadingEnd();

  if (params.showModal || params.onLoadingStart) window.URL.revokeObjectURL(params.printable);

  if (params.onPrintDialogClose) {
    let event = "mouseover";

    if (Browser.isChrome() || Browser.isFirefox()) {
      event = "focus";
    }
    const handler = () => {
      window.removeEventListener(event, handler);

      params.onPrintDialogClose();
    };

    window.addEventListener(event, handler);
  }
}

export function isRawHTML(raw) {
  let regexHtml = new RegExp("<([A-Za-z][A-Za-z0-9]*)\\b[^>]*>(.*?)</\\1>");
  return regexHtml.test(raw);
}
