import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Grid } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSun } from "@fortawesome/free-solid-svg-icons";
import { faSnowflake } from "@fortawesome/free-solid-svg-icons";
import { faSunDust } from "@fortawesome/pro-solid-svg-icons";

import { SEASONS, TYRE_POSITION } from "../../modules/Appointments/common";

import "./Tyres.css";

class TruckTyres extends Component {
  getTyreStatus = (tyreProfile, season) => {
    const {
      all_season_a_threshold,
      all_season_n_threshold,
      winter_n_threshold,
      winter_a_threshold,
      summer_n_threshold,
      summer_a_threshold,
      truck_tyre_a_threshold,
      truck_tyre_n_threshold,
    } = this.props.selectedLocation;

    switch (season) {
      case SEASONS.ALL_SEASONS:
        if (tyreProfile < all_season_n_threshold) return "red-status";
        else if (tyreProfile < all_season_a_threshold) return "orange-status";
        else return "green-status";

      case SEASONS.WINTER:
        if (tyreProfile < winter_n_threshold) return "red-status";
        else if (tyreProfile < winter_a_threshold) return "orange-status";
        else return "green-status";

      case SEASONS.SUMMER:
        if (tyreProfile < summer_n_threshold) return "red-status";
        else if (tyreProfile < summer_a_threshold) return "orange-status";
        else return "green-status";

      default:
        if (tyreProfile < truck_tyre_n_threshold) return "red-status";
        else if (tyreProfile < truck_tyre_a_threshold) return "orange-status";
        else return "green-status";
    }
  };

  renderSeasonIcon = season => {
    switch (season) {
      case SEASONS.ALL_SEASONS:
        return <FontAwesomeIcon icon={faSunDust} className="tyre-season-icon" color={"#21ba45"} />;
      case SEASONS.SUMMER:
        return <FontAwesomeIcon icon={faSun} className="tyre-season-icon" color={"#fbbd08"} />;
      case SEASONS.WINTER:
        return <FontAwesomeIcon icon={faSnowflake} className="tyre-season-icon" color={"#2185d0"} />;
      default:
        return null;
    }
  };

  getTyrePosition = position => {
    switch (position) {
      case TYRE_POSITION.TRUCK_LEFT:
        return "L";

      case TYRE_POSITION.TRUCK_RIGHT:
        return "R";

      case TYRE_POSITION.TRUCK_EXTERIOR_LEFT:
        return "EL";

      case TYRE_POSITION.TRUCK_EXTERIOR_RIGHT:
        return "ER";

      case TYRE_POSITION.TRUCK_INTERIOR_LEFT:
        return "IL";

      case TYRE_POSITION.TRUCK_INTERIOR_RIGHT:
        return "IR";

      case TYRE_POSITION.TRUCK_SPARE:
        return "S";

      default:
        break;
    }
  };

  renderTyres = tyre => {
    const leftSideRender = [TYRE_POSITION.TRUCK_RIGHT, TYRE_POSITION.TRUCK_EXTERIOR_RIGHT, TYRE_POSITION.TRUCK_INTERIOR_LEFT].includes(tyre.position);

    return (
      <div className="tyre-inner-container truck-tyre-inner-container" key={tyre.tyre_id + tyre.position}>
        <div className="tyre-details">
          {leftSideRender && <span>{tyre.profile} mm</span>}
          <div className={`tyre-details-box ${this.getTyreStatus(tyre.profile, tyre.tyre.season)}`}>
            <span>{this.getTyrePosition(tyre.position)}</span>
          </div>
          {!leftSideRender && <span>{tyre.profile} mm</span>}
        </div>
        <div className="tyre-manufacturer">
          <div>
            <span className="left-season-icon">{leftSideRender && this.renderSeasonIcon(tyre.tyre.season)}</span>
            <span>{tyre.tyre.manufacturer}</span>
            <span className="right-season-icon">{!leftSideRender && this.renderSeasonIcon(tyre.tyre.season)}</span>
          </div>
          <div>
            {tyre.tyre.description}{" "}
            {!tyre.tyre.description.includes("R") &&
              !tyre.tyre.description.includes("/") &&
              `${tyre.tyre.width}/${tyre.tyre.height}R${tyre.tyre.size} ${tyre.tyre.speed}`}
          </div>
        </div>
      </div>
    );
  };

  getAxle = tyre => {
    const { t } = this.props;

    if (tyre.position === TYRE_POSITION.TRUCK_SPARE) return `${t("spare_tyre").message || "Spare Tyre"} ${tyre.spareTiresCount}`;
    else return `${t("axle").message || "Axle"} ${tyre.axle}`;
  };

  renderTyresSection = truck_tyres => {
    return (
      <Grid.Row>
        {Object.keys(truck_tyres).map((key, idx) => (
          <Grid.Column width={16} key={key + idx}>
            <div className="tyres-heading-section">
              <span>{this.getAxle(truck_tyres[key][0])}</span>
            </div>
            <div className="tyre-container">{truck_tyres[key].map(tyre => this.renderTyres(tyre))}</div>
          </Grid.Column>
        ))}
      </Grid.Row>
    );
  };

  render() {
    const { truck_tyres, t } = this.props;

    return (
      <div className="CarTyres CarDetailContainer">
        <h3>{t("tyres").message || "Tyres"}</h3>

        {!Object.keys(truck_tyres)?.length && <div className="NoDataAvailable">{t("no_data_available").message || "No data available"}</div>}

        <Grid>
          <>
            <div className="tyres-details">
              <strong>
                {[SEASONS.ALL_SEASONS, SEASONS.WINTER, SEASONS.SUMMER].includes(Object.values(truck_tyres)[0][0]?.tyre.season)
                  ? t("on_vehicle").message || "ON VEHICLE"
                  : t("on_truck").message || "ON TRUCK"}
              </strong>
            </div>
            {this.renderTyresSection(truck_tyres)}
          </>
        </Grid>
      </div>
    );
  }
}

export default withTranslation()(TruckTyres);
