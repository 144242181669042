import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import ReactTable, { ReactTableDefaults } from "react-table";
import { Dropdown, Form, Grid, Icon, Label } from "semantic-ui-react";
import moment from "moment";

import { setAlert } from "../../App/store";
import { DMS, SEVERITY, SOURCE, getDMSName } from "../util";
import { SubHeader, DateRange } from "../../../components";

import { UserMenuActionsPortal } from "../../../components";

import Service from "./service";

import "./index.scss";

const defaultFilters = {
  dealer_id: null,
  dms_id: null,
  date_from: null,
  date_to: null,
  severity: null,
  source: null,
};

class Monitoring extends Component {
  state = {
    warnings: [],
    selectedFilters: defaultFilters,
    page: 0,
    nb_pages: 0,
    isLoading: false,
  };

  handleChangeDropdown = (_e, { name, value }) =>
    this.setState(
      state => ({ selectedFilters: { ...state.selectedFilters, [name]: value } }),
      () => {
        this.getWarningsList();
      }
    );

  handleChangeDates = ([start, end]) =>
    this.setState(
      state => ({ selectedFilters: { ...state.selectedFilters, date_from: start, date_to: end } }),
      () => {
        if (start && !end) return;
        this.getWarningsList();
      }
    );

  getWarningsList = (page, isRefresh) => {
    if (!page) page = 1;
    else if ((page === this.state.page || this.state.isLoading) && !isRefresh) return;

    const selectedFilters = { ...this.state.selectedFilters };
    Object.keys(selectedFilters).forEach(k => !selectedFilters[k] && selectedFilters[k] !== undefined && delete selectedFilters[k]);

    const params = {
      page,
      ...selectedFilters,
    };

    this.setState({ isLoading: true }, () => {
      Service.getWarningsList(params)
        .then(res => {
          const { items: warnings = [], nb_pages = 0, nb_items = 0 } = res?.data?.data || {};

          this.setState({ warnings, page, nb_pages, nb_items, isLoading: false });
        })
        .catch(err => {
          const title = typeof err?.response?.data === "string" ? err.response.data : err.response.data?.errors[0];
          this.props.setAlert({ type: "error", title });
          this.setState({ isLoading: false });
        });
    });
  };

  getEnumTitle = (key, enumeration) => {
    const arr = Object.entries(enumeration).map(e => {
      const name = e[0].toLowerCase();
      const fallback = name.charAt(0).toUpperCase() + name.slice(1);

      return {
        key: e[1],
        name,
        fallback: fallback.replaceAll("_", " "),
      };
    });

    return arr.find(o => o.key === key) || { name: "", fallback: "" };
  };

  getSeverityLabel = severity => {
    const { t } = this.props;

    const e = this.getEnumTitle(severity, SEVERITY);

    switch (severity) {
      case SEVERITY.LOG:
        return (
          <Label color="grey">
            <Icon name="comment outline"></Icon>
            {t(e.name).message || e.fallback}
          </Label>
        );
      case SEVERITY.WARNING:
        return (
          <Label color="yellow">
            <Icon name="warning sign"></Icon>
            {t(e.name).message || e.fallback}
          </Label>
        );
      case SEVERITY.ERROR:
        return (
          <Label color="red">
            <Icon name="warning sign"></Icon>
            {t(e.name).message || e.fallback}
          </Label>
        );
      case SEVERITY.CRITICAL:
        return (
          <Label color="black">
            <Icon name="warning sign"></Icon>
            {t(e.name).message || e.fallback}
          </Label>
        );

      default:
        return null;
    }
  };

  renderMonitoringTable = () => {
    const { warnings, nb_pages, page, isLoading } = this.state;
    const { t } = this.props;

    return (
      <div className="MonitoringTableContainer">
        <ReactTable
          manual
          data={warnings || []}
          showPagination={nb_pages > 1}
          page={page - 1}
          pages={nb_pages === null ? -1 : nb_pages}
          className="MonitoringTable -floated-table"
          renderPageJump={({ onChange, onBlur, onKeyPress, inputType, pageJumpText }) => (
            <div className="-pageJump">
              <input
                aria-label={pageJumpText}
                type={inputType}
                onChange={evt => {
                  onChange(evt);

                  const newPage = Number(evt.target.value);
                  if (!Number.isNaN(newPage) && newPage > 0 && newPage <= nb_pages) this.getWarningsList(newPage);
                }}
                value={page}
                onBlur={onBlur}
                onKeyPress={onKeyPress}
              />
            </div>
          )}
          loading={isLoading}
          onFetchData={state => this.getWarningsList(state.page + 1)}
          showPageSizeOptions={false}
          sortable={false}
          minRows={0}
          loadingText={t("loading_monitoring_page").message || "Loading monitoring page"}
          nextText={t("next").message || "Next"}
          previousText={t("previous").message || "Previous"}
          pageText={t("page").message || "Page"}
          ofText={t("of").message || "of"}
          noDataText={
            <div className="Table__no-results">
              <Icon disabled name="database" style={{ fontSize: "1.25em" }} />
              <p>{t("no_data").message || "No data"}</p>
            </div>
          }
          onPageChange={() => {
            try {
              document.querySelector(".App__module").scrollTo(0, 0);
            } catch (e) {
              document.querySelector(".App__module").scrollTop = 0; // IE Fix
            }
          }}
          column={{
            ...ReactTableDefaults.column,
            headerClassName: "ReactTable__column-header -text-ellipsis",
            className: "ReactTable__column",
          }}
          columns={[
            {
              id: "dealer",
              Header: t("dealer").message || "Dealer",
              accessor: "dealer_name",
            },
            {
              id: "dms",
              Header: t("dms").message || "DMS",
              accessor: d => getDMSName(d.dms_id),
            },
            {
              id: "location",
              Header: t("location").message || "Location",
              accessor: "location_name",
            },
            {
              id: "date",
              Header: t("date").message || "Date",
              accessor: d => moment(d.created_on).format("YYYY-MM-DD HH:mm"),
              width: 140,
            },
            {
              id: "severity",
              Header: t("severity").message || "Severity",
              accessor: d => this.getSeverityLabel(d.severity),
              width: 130,
            },
            {
              id: "source",
              Header: t("source").message || "Source",
              accessor: d => {
                const e = this.getEnumTitle(d.source, SOURCE);

                return t(e.name).message || e.fallback;
              },
              width: 130,
            },
            {
              id: "message",
              className: "monitoring-message-column",
              Header: t("message").message || "Message",
              accessor: "message",
              minWidth: 200,
            },
          ]}
        />
      </div>
    );
  };

  renderMonitoringFilters = () => {
    const { selectedFilters } = this.state;
    const { t, globalState } = this.props;

    const dealerOptions = globalState.dealers.map(d => ({ key: d.id, value: d.id, text: d.name }));
    const dmsOptions = Object.keys(DMS).map(key => ({ key: DMS[key], value: DMS[key], text: getDMSName(DMS[key]) }));

    return (
      <Grid.Row className="MonitoringFilters">
        <Grid.Column width={16}>
          <Form>
            <Form.Group>
              <div className="filter-group">
                <div className="filter-dropdown">
                  <Dropdown
                    fluid
                    selection
                    selectOnBlur={false}
                    clearable
                    search
                    name="dealer_id"
                    options={dealerOptions}
                    onChange={this.handleChangeDropdown}
                    value={selectedFilters.dealer_id}
                    placeholder={t("select_dealer").message || "Select dealer"}
                  />
                </div>

                <div className="filter-dropdown">
                  <Dropdown
                    fluid
                    selection
                    clearable
                    selectOnBlur={false}
                    name="dms_id"
                    options={dmsOptions}
                    onChange={this.handleChangeDropdown}
                    value={selectedFilters.dms_id}
                    placeholder={t("select_dms").message || "Select DMS"}
                  />
                </div>

                <div className="filter-dropdown">
                  <DateRange
                    clearable
                    dateFrom={selectedFilters.date_from}
                    dateTo={selectedFilters.date_to}
                    onChange={this.handleChangeDates}
                    placeholder={t("select_dates").message || "Select dates"}
                  />
                </div>

                <div className="filter-dropdown">
                  <Dropdown
                    fluid
                    selection
                    clearable
                    name="severity"
                    options={Object.keys(SEVERITY).map(key => {
                      const e = this.getEnumTitle(SEVERITY[key], SEVERITY);
                      return { key: SEVERITY[key], value: SEVERITY[key], text: t(e.name).message || e.fallback };
                    })}
                    onChange={this.handleChangeDropdown}
                    value={selectedFilters.severity}
                    placeholder={t("severity").message || "Severity"}
                  />
                </div>

                <div className="filter-dropdown">
                  <Dropdown
                    fluid
                    selection
                    clearable
                    name="source"
                    options={Object.keys(SOURCE).map(key => {
                      const e = this.getEnumTitle(SOURCE[key], SOURCE);
                      return { key: SOURCE[key], value: SOURCE[key], text: t(e.name).message || e.fallback };
                    })}
                    onChange={this.handleChangeDropdown}
                    value={selectedFilters.source}
                    placeholder={t("source").message || "Source"}
                  />
                </div>
              </div>
            </Form.Group>
          </Form>
        </Grid.Column>
      </Grid.Row>
    );
  };

  render() {
    const { page } = this.state;
    const { t } = this.props;

    return (
      <div className="DMSMonitoringPage">
        <UserMenuActionsPortal>
          <Icon name="refresh" onClick={() => this.getWarningsList(page, true)} />
        </UserMenuActionsPortal>

        <SubHeader>
          <Grid stackable className="SubHeader_monitoring_filters">
            <Grid.Row>
              <Grid.Column width={16}>
                <h1>{t("monitoring_page").message || "DMS Monitoring"}</h1>
              </Grid.Column>
            </Grid.Row>
            {this.renderMonitoringFilters()}
          </Grid>
        </SubHeader>

        {this.renderMonitoringTable()}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  globalState: state.global,
});

const mapDispatchToProps = dispatch => ({
  setAlert: alertOptions => dispatch(setAlert(alertOptions)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Monitoring));
