import React from "react";
import { Button } from "semantic-ui-react";
import ReactTable, { ReactTableDefaults } from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport } from "@fortawesome/pro-regular-svg-icons";
import { saveAs } from "file-saver";
import moment from "moment";

const OnlineCheckInPerformances = ({ report, t }) => {
  if (!report.report_data) {
    return (
      <>
        <h3 className="report-heading">{t(report.report_name).message || report.report_name}</h3>
        <p className="no-data">{t("no_data").message || "No data"}</p>
      </>
    );
  }

  const data = report.report_data.map(d => JSON.parse(d));
  const tags = data.map(({ location, services_count, services_accepted, Others, ...tags }) => tags);
  const headers = [];

  tags.forEach(tag => {
    Object.keys(tag).forEach(tagName => {
      if (!headers.includes(tagName)) headers.push(tagName);
    });
  });

  headers.sort((a, b) => a.localeCompare(b));

  const exportData = () => {
    let csv = "";
    let header = (t("location").message || "Location") + ", ";
    header += (t("services_count").message || "Services Count") + ", ";
    header += (t("services_accepted").message || "Services Accepted") + ", ";
    headers.forEach(tag => {
      header += (t("tag_accepted", { tag }).message || `${tag} accepted`) + ", ";
    });
    header += t("others_accepted").message || "Others Accepted";

    let values = data
      .map(d => d.location + ", " + d.services_count + ", " + d.services_accepted + ", " + headers.map(header => d[header] && d[header] + ", ").join("") + d["Others"])
      .join("\n");

    csv += header + "\n" + values;
    saveAs(new File([csv], { type: "text/csv;charset=utf-8" }), "OnlineCheckinPerformances_" + moment().format("YYYYMMDD") + ".csv");
  };

  const getTagColumns = () => {
    const tagColumns = [];

    headers.forEach(h =>
      tagColumns.push({
        Header: <strong>{t("tag_accepted", { tag: h }).message || `${h} accepted`}</strong>,
        accessor: d => (d[h] ? d[h] : ""),
        id: `${h}_accepted`,
      })
    );

    return tagColumns;
  };

  return (
    <>
      <h3 className="report-heading">
        {t(report.report_name).message || report.report_name}
        <Button color="green" floated="right" basic onClick={() => exportData()}>
          <span style={{ marginRight: "10px" }}>{t("export").message || "Export"}</span>
          <FontAwesomeIcon icon={faFileExport} />
        </Button>
      </h3>
      <div className="dealer-reports-table-container">
        <ReactTable
          className="ReactTable -floated-table no-overflow all-data-table -initial-margin"
          data={data}
          showPagination={false}
          showPageSizeOptions={false}
          sortable={false}
          style={{ maxHeight: "600px" }}
          resizable={false}
          defaultPageSize={data.length || 0}
          pageSize={data.length || 0}
          noDataText={
            <div className="Table__no-results">
              <p>{t("no_data").message || "No data"}</p>
            </div>
          }
          column={{
            ...ReactTableDefaults.column,
            headerClassName: "ReactTable__column-header dealer-table-header",
            className: "ReactTable__column",
          }}
          columns={[
            {
              Header: <strong>{t("location").message || "Location"}</strong>,
              accessor: "location",
            },
            {
              Header: <strong>{t("services_count").message || "Services Count"}</strong>,
              accessor: "services_count",
            },
            {
              Header: <strong>{t("services_accepted").message || "Service Accepted"}</strong>,
              accessor: "services_accepted",
            },
            ...getTagColumns(),
            {
              Header: <strong>{t("others_accepted").message || "Others Accepted"}</strong>,
              accessor: "Others",
            },
          ]}
        />
      </div>
    </>
  );
};

export { OnlineCheckInPerformances };
