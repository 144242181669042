import React, { Component } from "react";
import { Form, Button, Modal } from "semantic-ui-react";
import { withTranslation } from "react-i18next";

const webhookForm = {
  appointment_id: "",
  dealer_location_id: "",
  wo_nr: "",
  communication_key: "",
  customer_dms_nr: "",
  customer_type: "",
};

class WebhooksTestModal extends Component {
  state = {
    form: { ...webhookForm },
  };

  handleInputChange = e => {
    const { name, value } = e.target;
    this.setState(state => ({ form: { ...state.form, [name]: value } }));
  };

  render() {
    const { webhookId, onSubmit, onCancel, renderAlert, isLoading, t } = this.props;
    const { form } = this.state;

    return (
      <div>
        <Modal open={!!webhookId} onClose={onCancel} closeOnDimmerClick={false}>
          <Modal.Header>{t("webhook_test").message || "Webhook Test"}</Modal.Header>
          <Modal.Content scrolling>
            <Form>
              <Form.Group widths="equal">
                <Form.Field>
                  <Form.Input
                    name="appointment_id"
                    label={t("appointment_id").message || "Appointment ID"}
                    onChange={this.handleInputChange}
                    value={form.appointment_id}
                  />
                </Form.Field>
                <Form.Field>
                  <Form.Input
                    name="dealer_location_id"
                    label={t("dealer_location_id").message || "Dealer Location ID"}
                    onChange={this.handleInputChange}
                    value={form.dealer_location_id}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field>
                  <Form.Input name="wo_nr" label={t("wo_number").message || "WO Number"} onChange={this.handleInputChange} value={form.wo_nr} />
                </Form.Field>
                <Form.Field>
                  <Form.Input
                    name="communication_key"
                    label={`${t("communication_key").message || "Communication Key"} (${
                      t("for_communication_triggers_only").message || "for communication triggers only"
                    })`}
                    onChange={this.handleInputChange}
                    value={form.communication_key}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field>
                  <Form.Input
                    name="customer_dms_nr"
                    label={`${t("customer_dms_nr").message || "Customer DMS Number"} (${
                      t("for_communication_triggers_only").message || "for communication triggers only"
                    })`}
                    onChange={this.handleInputChange}
                    value={form.customer_dms_nr}
                  />
                </Form.Field>
                <Form.Field>
                  <Form.Input
                    name="customer_type"
                    label={`${t("customer_type").message || "Customer Type"} (${t("for_communication_triggers_only").message || "for communication triggers only"})`}
                    onChange={this.handleInputChange}
                    value={form.customer_type}
                  />
                </Form.Field>
              </Form.Group>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button size="large" onClick={onCancel} color="grey">
              {t("cancel").message || "Cancel"}
            </Button>
            <Button size="large" loading={isLoading} disabled={isLoading} onClick={() => onSubmit({ ...form })} color="green">
              {t("submit").message || "Submit"}
            </Button>
          </Modal.Actions>

          {renderAlert()}
        </Modal>
      </div>
    );
  }
}

export default withTranslation()(WebhooksTestModal);
