import Print from "./print";
import { cleanUp } from "./functions";

export default {
  print: (params, printFrame) => {
    if (params.base64) {
      const bytesArray = Uint8Array.from(atob(params.printable), c => c.charCodeAt(0));
      createBlobAndPrint(params, printFrame, bytesArray);
      return;
    }

    params.printable = /^(blob|http)/i.test(params.printable)
      ? params.printable
      : window.location.origin + (params.printable.charAt(0) !== "/" ? "/" + params.printable : params.printable);

    let req = new window.XMLHttpRequest();
    req.responseType = "arraybuffer";

    req.addEventListener("load", () => {
      if ([200, 201].indexOf(req.status) === -1) {
        cleanUp(params);
        params.onError(req.statusText);

        return;
      }

      createBlobAndPrint(params, printFrame, req.response);
    });

    req.open("GET", params.printable, true);
    req.send();
  },
};

function createBlobAndPrint(params, printFrame, data) {
  let localPdf = new window.Blob([data], { type: "application/pdf" });
  localPdf = window.URL.createObjectURL(localPdf);

  printFrame.setAttribute("src", localPdf);

  Print.send(params, printFrame);
}
