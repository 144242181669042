import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Button, Form, Modal, Icon } from "semantic-ui-react";

import { UserInput } from "../../components";

class CreateQuestion extends Component {
  state = {
    question: {
      title: "",
    },
  };

  handleQuestionNameChange = e => {
    let { question } = this.state;
    question.title = e.target.value;
    if (this.props.dealerID) question.dealer_id = this.props.dealerID;

    this.setState({ question });
  };

  render() {
    return (
      <Modal open={true} closeOnDimmerClick={false}>
        <Modal.Header>{this.props.t("create").message || "Create"}</Modal.Header>
        <Modal.Content>
          {this.props.renderAlert()}
          <Modal.Description>
            <Form>
              <Form.Field>
                <UserInput placeholder={this.props.t("question").message || "Question"} value={this.state.question.title} onChange={this.handleQuestionNameChange} />
                {this.state.errorMessage}
              </Form.Field>
              <Form.Field>
                <Button
                  color="green"
                  floated="right"
                  onClick={() => {
                    this.props.save(this.state.question);
                  }}
                  loading={this.props.isLoading}
                  disabled={this.props.isLoading}
                >
                  <Icon name="checkmark" />
                  {this.props.t("confirm").message || "CONFIRM"}
                </Button>
                <Button color="red" onClick={this.props.cancel} loading={this.props.isLoading} disabled={this.props.isLoading}>
                  <Icon name="close" />
                  {this.props.t("cancel").message || "CANCEL"}
                </Button>
              </Form.Field>
            </Form>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
}

export default withTranslation()(CreateQuestion);
