import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Modal, Icon, Button, Form, Grid } from "semantic-ui-react";
import { ColorPicker, UserInput } from "../../components";
import { getDefaultStatusLabel } from "../../util/common";

class StatusEdit extends Component {
  state = {
    dealerLocation: JSON.parse(JSON.stringify(this.props.dealerLocation)),
    dealerStatuses: [],
    colors: [],
  };

  componentDidMount() {
    let { dealerLocation } = this.state;

    if (dealerLocation.statuses == null || dealerLocation.statuses.length < 1) {
      this.props.discard();
      return;
    }

    const dealerStatuses = dealerLocation.statuses.sort((a, b) => a.order - b.order);
    const colors = dealerStatuses.map(s => s.color.toLowerCase());

    this.setState({ dealerStatuses, colors });
  }

  handleStatusChange = (e, id) => {
    const { dealerStatuses } = this.state;
    const i = dealerStatuses.findIndex(x => x.id === id);
    if (i !== -1) {
      dealerStatuses[i].name = e.target.value;
      this.setState({ dealerStatuses });
    }
  };

  handleColorChange = (color, id) => {
    const { dealerStatuses } = this.state;
    const i = dealerStatuses.findIndex(x => x.id === id);
    if (i !== -1) {
      dealerStatuses[i].color = color;
      this.setState({ dealerStatuses });
    }
  };

  handleSave = () => {
    const { dealerLocation } = this.state;
    dealerLocation.statuses = this.state.dealerStatuses;
    this.setState({ dealerLocation }, () => this.props.handleStatusChange(this.state.dealerLocation));
  };

  render() {
    const { dealerStatuses, colors } = this.state;

    const statusList = dealerStatuses.map((stat, k) => (
      <Form.Field key={k}>
        <label>{getDefaultStatusLabel(stat.identifier)}</label>
        <Form.Group>
          <Form.Field width={2}>
            <ColorPicker className="-margin-top-5" color={stat.color} extraColors={colors} onChange={color => this.handleColorChange(color, stat.id)} />
          </Form.Field>

          <Form.Field width={14}>
            <UserInput type="text" placeholder="Name" value={stat.name} onChange={e => this.handleStatusChange(e, stat.id)} />
          </Form.Field>
        </Form.Group>
      </Form.Field>
    ));

    return (
      <div>
        <Modal
          size="tiny"
          onClose={() => {
            this.props.discard();
          }}
          open={true}
          closeOnDimmerClick={false}
        >
          <Modal.Header>
            <div>{this.props.t("status_list").message || "Status List"}</div>
          </Modal.Header>
          <Modal.Content scrolling>
            <Grid className="-contained-large" style={{ background: "#fff" }}>
              <Grid.Column width={16}>
                <Form>{statusList}</Form>
              </Grid.Column>
            </Grid>
          </Modal.Content>
          <Modal.Actions>
            <Button
              color="red"
              loading={this.props.isLoading}
              disabled={this.props.isLoading}
              onClick={() => {
                this.props.discard();
              }}
              floated="left"
            >
              <Icon name="close" /> {this.props.t("discard").message || "DISCARD"}
            </Button>
            <Button color="green" loading={this.props.isLoading} disabled={this.props.isLoading} onClick={this.handleSave}>
              <Icon name="checkmark" /> {this.props.t("save").message || "SAVE"}
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

export default withTranslation()(StatusEdit);
