import axios from "axios";

import ENV from "../../util/env-config";
import { getSessionCookie } from "../../util/common";

class Service {
  static requestConfig() {
    return {
      headers: {
        Authorization: `Bearer ${getSessionCookie()}`,
        Pragma: "no-cache",
      },
    };
  }

  static listRequests(requestData) {
    return axios.post(ENV.apiBaseB + "/manufacturers/list_requests", requestData, this.requestConfig());
  }

  static requestAccess(requestData) {
    return axios.post(ENV.apiBaseB + "/manufacturers/request_access", requestData, this.requestConfig());
  }

  static searchRequests(requestData) {
    return axios.post(ENV.appointmentApiBase + "/pins/search_by_claim_nr", requestData, this.requestConfig());
  }
}

export default Service;
