import React, { Component } from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ReferenceLine } from "recharts";
import moment from "moment";
import "moment-duration-format";

import { extractAllNameFields } from "../../../util/common";

const COLORS = ["#278ecf", "#4bd762", "#ff402c", "#ff9416", "#d42ae8", "#535ad7", "#83bfff", "#ffca1f", "#6edb8f", "#ffe366", "#ffc266", "#8784db"];

class SimpleBarChart extends Component {
  isNumOrStr = value => typeof value === "number" || typeof value === "string";

  defaultFormatter = value => (Array.isArray(value) && this.isNumOrStr(value[0]) && this.isNumOrStr(value[1]) ? value.join(" ~ ") : value);

  CustomTooltip = ({ payload, separator, itemStyle, formatter, label, active }) => {
    if (!active) return null;

    return (
      <div
        className="recharts-default-tooltip"
        style={{ margin: 0, padding: 10, backgroundColor: "white", border: "1px solid rgb(204, 204, 204)", whiteSpace: "nowrap" }}
      >
        <p className="recharts-tooltip-label" style={{ margin: 0 }}>
          {label}
        </p>
        <ul className="recharts-tooltip-item-list" style={{ padding: 0, margin: 0 }}>
          {payload.map((entry, i) => {
            if (entry.type === "none") {
              return null;
            }

            const finalItemStyle = {
              display: "block",
              paddingTop: 4,
              paddingBottom: 4,
              color: entry.color || "#000",
              ...itemStyle,
            };

            const finalFormatter = entry.formatter || formatter || this.defaultFormatter;
            let { name, value, unit } = entry;
            if (finalFormatter) {
              const formatted = finalFormatter(value, name, entry, i, payload);
              if (Array.isArray(formatted)) {
                [value, name] = formatted;
              } else {
                value = formatted;
              }
            }

            let totalTooltip = null;
            let percentTooltip = null;

            if (entry.payload) {
              let tkey;
              let key = Object.keys(entry.payload).find(k => k.startsWith("total." + name + "."));
              if (key) tkey = key.replace("total." + name + ".", "");
              else if (i === payload.length - 1) {
                key = Object.keys(entry.payload).find(k => k.startsWith("total._last_."));
                if (key) tkey = key.replace("total._last_.", "");
              }

              if (tkey && key) {
                tkey = this.props.t(tkey).message || tkey;
                totalTooltip = tkey + ": " + entry.payload[key];
              }

              tkey = null;
              key = Object.keys(entry.payload).find(k => k === "percent." + name);
              if (key) tkey = key.replace("percent.", "");

              if (tkey && key) {
                percentTooltip = tkey + ": " + entry.payload[key] + "%";
              }
            }

            if (unit === " min") {
              value = moment.duration(value, "seconds").format("HH:mm:ss", { trim: false });
              unit = "";
            }

            return (
              <React.Fragment key={`tooltip-item-${i}`}>
                <li className="recharts-tooltip-item" style={finalItemStyle}>
                  {this.isNumOrStr(name) ? <span className="recharts-tooltip-item-name">{name}</span> : null}
                  {this.isNumOrStr(name) ? <span className="recharts-tooltip-item-separator">{separator}</span> : null}
                  <span className="recharts-tooltip-item-value">{value}</span>
                  <span className="recharts-tooltip-item-unit">{unit || ""}</span>
                </li>
                {totalTooltip && (
                  <li className="recharts-tooltip-item" style={finalItemStyle}>
                    <span className="recharts-tooltip-item-value">{totalTooltip}</span>
                  </li>
                )}
                {percentTooltip && (
                  <li className="recharts-tooltip-item" style={finalItemStyle}>
                    <span className="recharts-tooltip-item-value">{percentTooltip}</span>
                  </li>
                )}
              </React.Fragment>
            );
          })}
        </ul>
      </div>
    );
  };

  CustomBarLabel = params => {
    const { data } = this.props;
    const { dataKey, index, x, y, width } = params;

    let label = null;
    if (data[index]) {
      let key = Object.keys(data[index]).find(k => k.startsWith("total." + dataKey + "."));
      if (!key) key = Object.keys(data[index]).find(k => k.startsWith("total._last_."));

      if (key) label = data[index][key];
    }

    if (!label) return null;

    return (
      <text x={x + width / 2} y={y} fill="#000000" textAnchor="middle" dy={-6}>
        {label}
      </text>
    );
  };

  CustomThresholdsLegend = props => {
    const { thresholds, t } = this.props;

    if (!thresholds?.length) return null;

    return (
      <ul className="recharts-default-legend" style={{ padding: 0, margin: 0, textAlign: "center" }}>
        {thresholds.map((th, index) => (
          <li key={index} className="recharts-legend-item" style={{ display: "inline-block", marginRight: "10px" }}>
            <svg
              className="recharts-surface"
              width="14"
              height="14"
              viewBox="0 0 32 32"
              version="1.1"
              style={{ display: "inline-block", verticalAlign: "middle", marginRight: "4px" }}
            >
              <line strokeWidth="4" fill="none" stroke={th.color} x1="0" y1="16" x2="32" y2="16" className="recharts-legend-icon"></line>
            </svg>
            <span className="recharts-legend-item-text">{t(th.name).message || th.name}</span>
          </li>
        ))}
      </ul>
    );
  };

  render() {
    const { data, colors, t, unit, thresholds } = this.props;
    let iBar = 0;

    return (
      <>
        <ResponsiveContainer width="100%" height={450} className={"full-width-margin-auto simple-bar-chart"}>
          <BarChart data={data} margin={{ top: 20, right: 30, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            {unit === " min" && <YAxis width={80} tickFormatter={value => moment.duration(value, "seconds").format("HH:mm:ss", { trim: false })} />}
            {unit !== " min" && <YAxis unit={unit === "%" ? "%" : null} domain={unit === "%" ? [0, 100] : ["auto", "auto"]} />}
            <Tooltip content={this.CustomTooltip} />
            <Legend />

            {extractAllNameFields(data)
              .filter(k => k !== "name" && !k.startsWith("total.") && !k.startsWith("percent."))
              .map((k, i) => {
                const item = colors && colors.find(col => (t(col.name).message || col.name) === k);
                const color = item ? item.color : null;

                return (
                  <Bar
                    key={"bar" + i}
                    maxBarSize={100}
                    dataKey={k}
                    fill={color || COLORS[iBar++ % COLORS.length]}
                    unit={t(unit).message || unit}
                    label={params => this.CustomBarLabel({ dataKey: k, ...params })}
                    isAnimationActive={false}
                  />
                );
              })}

            {thresholds && thresholds.map((t, i) => <ReferenceLine key={"threshold" + i} y={t.y} isFront={true} stroke={t.color} />)}
          </BarChart>
        </ResponsiveContainer>
        <ResponsiveContainer width="100%" height={30} className={"full-width-margin-auto"}>
          <BarChart margin={{ top: 10, right: 30, bottom: 5 }}>
            <Legend content={this.CustomThresholdsLegend} />
          </BarChart>
        </ResponsiveContainer>
      </>
    );
  }
}

const SimpleBarChartExport = t => {};

export { SimpleBarChart, SimpleBarChartExport };
