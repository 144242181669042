import React from "react";
import { Button, Icon, Form, Label, Grid } from "semantic-ui-react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";

import { UserInput } from "../../../components";

import "./QuestionElementDropdown.css";

const QuestionElementDropdown = ({
  configuration,
  configurationChanged,
  dropdownNewItemLabel,
  onDropdownItemAdded,
  onDropdownNewItemKeyPress,
  onDropdownItemRemoved,
  onDropdownNewItemLabelChanged,
  onDropdownSortEnd,
  id,
}) => {
  const SortableItem = SortableElement(({ value }) => {
    return (
      <Label className="ListItem" size="big" color="blue">
        <Icon onClick={() => onDropdownItemRemoved(value.label)} name="delete" /> {value.label}
      </Label>
    );
  });

  const SortableList = SortableContainer(({ items }) => {
    return (
      <div>
        {items.map((item, key) => (
          <SortableItem key={`item-${key}`} index={key} value={item} />
        ))}
      </div>
    );
  });

  function renderItems() {
    if (configuration && configuration.items && configuration.items.length > 0)
      return <SortableList items={configuration.items} distance={5} onSortEnd={onDropdownSortEnd} />;
  }

  return (
    <div className="ElementDropdown">
      <Form.Field>
        <UserInput key={id} name="label" placeholder="Label" defaultValue={configuration.label} onChange={configurationChanged} />
      </Form.Field>

      <div>
        {renderItems()}
        <div>
          <Grid>
            <Grid.Column width={14}>
              <UserInput
                key={id}
                className="NewLabel"
                floated="left"
                placeholder="Item label"
                value={dropdownNewItemLabel}
                onKeyPress={onDropdownNewItemKeyPress}
                onChange={onDropdownNewItemLabelChanged}
              />
            </Grid.Column>
            <Grid.Column width={2}>
              <Button className="AddNewLabel" floated="right" color="green" onClick={onDropdownItemAdded}>
                <Icon name="add" />
              </Button>
            </Grid.Column>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default QuestionElementDropdown;
