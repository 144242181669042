import axios from "axios";
import ENV from "../../util/env-config";
import { getSessionCookie } from "../../util/common";

class Service {
  static requestConfig() {
    return {
      headers: {
        Authorization: `Bearer ${getSessionCookie()}`,
      },
    };
  }

  static getShareboxLog(requestData) {
    return axios.post(ENV.shareboxBaseURL + "/logs/list", requestData, this.requestConfig());
  }
}

export default Service;
