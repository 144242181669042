import { capitalizePrint, addHeader } from "./functions";
import Print from "./print";

export default {
  print: (params, printFrame) => {
    if (typeof params.printable !== "object") {
      throw new Error("Invalid javascript data object (JSON).");
    }

    if (typeof params.repeatTableHeader !== "boolean") {
      throw new Error("Invalid value for repeatTableHeader attribute (JSON).");
    }

    if (!params.properties || !Array.isArray(params.properties)) {
      throw new Error("Invalid properties array for your JSON data.");
    }

    params.properties = params.properties.map(property => {
      return {
        field: typeof property === "object" ? property.field : property,
        displayName: typeof property === "object" ? property.displayName : property,
        columnSize: typeof property === "object" && property.columnSize ? property.columnSize + ";" : 100 / params.properties.length + "%;",
      };
    });

    params.printableElement = document.createElement("div");

    if (params.header) {
      addHeader(params.printableElement, params);
    }

    params.printableElement.innerHTML += jsonToHTML(params);

    Print.send(params, printFrame);
  },
};

function jsonToHTML(params) {
  let data = params.printable;
  let properties = params.properties;

  let htmlData = '<table style="border-collapse: collapse; width: 100%;">';

  if (params.repeatTableHeader) {
    htmlData += "<thead>";
  }

  htmlData += "<tr>";

  for (let a = 0; a < properties.length; a++) {
    htmlData += '<th style="width:' + properties[a].columnSize + ";" + params.gridHeaderStyle + '">' + capitalizePrint(properties[a].displayName) + "</th>";
  }

  htmlData += "</tr>";

  if (params.repeatTableHeader) {
    htmlData += "</thead>";
  }

  htmlData += "<tbody>";

  for (let i = 0; i < data.length; i++) {
    htmlData += "<tr>";

    for (let n = 0; n < properties.length; n++) {
      let stringData = data[i];

      let property = properties[n].field.split(".");
      if (property.length > 1) {
        for (let p = 0; p < property.length; p++) {
          stringData = stringData[property[p]];
        }
      } else {
        stringData = stringData[properties[n].field];
      }

      htmlData += '<td style="width:' + properties[n].columnSize + params.gridStyle + '">' + stringData + "</td>";
    }

    htmlData += "</tr>";
  }

  htmlData += "</tbody></table>";

  return htmlData;
}
