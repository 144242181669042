import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Grid, Button, Label } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSun, faTrash } from "@fortawesome/free-solid-svg-icons";
import { faSnowflake } from "@fortawesome/free-solid-svg-icons";
import { faSunDust } from "@fortawesome/pro-solid-svg-icons";
import AlertContainer from "react-alert";

import { CustomConfirm } from "../../components";
import { round2 } from "../../util/common";
import { SEASONS, APPOINTMENT_STATUSES } from "../Appointments/common";
import { Can } from "../";

import Service from "./service";

import "./Tyres.css";

class CarTyres extends Component {
  state = {
    showDeleteConfirmation: false,
    isLoadingDeleteInStorageTires: false,
    errorDeletingInStorageTires: "",
  };

  alertRef = React.createRef();

  getTyreStatus = (tyreProfile, season) => {
    const { all_season_a_threshold, all_season_n_threshold, winter_n_threshold, winter_a_threshold, summer_n_threshold, summer_a_threshold } =
      this.props.selectedLocation;

    switch (season) {
      case SEASONS.ALL_SEASONS:
        if (tyreProfile < all_season_n_threshold) return "red-status";
        else if (tyreProfile < all_season_a_threshold) return "orange-status";
        else return "green-status";

      case SEASONS.WINTER:
        if (tyreProfile < winter_n_threshold) return "red-status";
        else if (tyreProfile < winter_a_threshold) return "orange-status";
        else return "green-status";

      case SEASONS.SUMMER:
        if (tyreProfile < summer_n_threshold) return "red-status";
        else if (tyreProfile < summer_a_threshold) return "orange-status";
        else return "green-status";

      default:
        return "green-status";
    }
  };

  renderSeasonIcon = season => {
    switch (season) {
      case SEASONS.ALL_SEASONS:
        return <FontAwesomeIcon icon={faSunDust} className="tyre-season-icon" color={"#21ba45"} />;
      case SEASONS.SUMMER:
        return <FontAwesomeIcon icon={faSun} className="tyre-season-icon" color={"#fbbd08"} />;
      case SEASONS.WINTER:
        return <FontAwesomeIcon icon={faSnowflake} className="tyre-season-icon" color={"#2185d0"} />;
      default:
        return null;
    }
  };

  renderTyres = (leftProfile, rightProfile, leftTyre, rightTyre) => {
    if (!leftTyre && !rightTyre) return null;

    return (
      <div className="tyre-container">
        <div className="tyre-inner-container">
          <div className="tyre-details">
            <div className={`tyre-details-box ${this.getTyreStatus(leftProfile, leftTyre.season)}`}>
              <span>L</span>
            </div>
            <span>{round2(leftProfile).toFixed(2)} mm</span>
          </div>
          <div className="tyre-manufacturer">
            <div>
              {leftTyre.manufacturer} {this.renderSeasonIcon(leftTyre.season)}
            </div>
            <div>
              {leftTyre.description}{" "}
              {!leftTyre.description.includes("R") && !leftTyre.description.includes("/") && `${leftTyre.width}/${leftTyre.height}R${leftTyre.size} ${leftTyre.speed}`}
            </div>
          </div>
        </div>
        <div className="tyre-inner-container right-tyre">
          <div className="tyre-details">
            <span className="right-profile">{round2(rightProfile).toFixed(2)} mm</span>
            <div className={`tyre-details-box ${this.getTyreStatus(rightProfile, rightTyre.season)}`}>
              <span>R</span>
            </div>
          </div>
          <div className="tyre-manufacturer">
            <div>
              {this.renderSeasonIcon(rightTyre.season)} {rightTyre.manufacturer}
            </div>
            <div>
              {rightTyre.description}{" "}
              {!rightTyre.description.includes("R") &&
                !rightTyre.description.includes("/") &&
                `${rightTyre.width}/${rightTyre.height}R${rightTyre.size} ${rightTyre.speed}`}
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderTyresSection = tyres => {
    const { t } = this.props;

    if (!tyres) return null;

    const { fl_profile, fr_profile, rl_profile, rr_profile, front_left_tyre, front_right_tyre, rear_left_tyre, rear_right_tyre } = tyres;

    return (
      <Grid.Row>
        <Grid.Column width={8}>
          <div className="tyres-heading-section">
            <span>{t("front_tyres").message || "Front tires"}</span>
          </div>
          {this.renderTyres(fl_profile, fr_profile, front_left_tyre, front_right_tyre)}
        </Grid.Column>
        <Grid.Column width={8}>
          <div className="tyres-heading-section">
            <span>{t("rear_tyres").message || "Rear tires"}</span>
          </div>
          {this.renderTyres(rl_profile, rr_profile, rear_left_tyre, rear_right_tyre)}
        </Grid.Column>
      </Grid.Row>
    );
  };

  toggleDeleteConfirmation = () => {
    this.setState(({ showDeleteConfirmation }) => ({ showDeleteConfirmation: !showDeleteConfirmation, errorDeletingInStorageTires: "" }));
  };

  handleOpenDBBPortal = () => {
    const { lastAppointment } = this.props;

    if (!lastAppointment) return;

    Service.getDBBLink({ id: lastAppointment.id })
      .then(res => {
        const link = res?.data?.data;

        if (link) window.open(link, "_blank");
        else throw Error("Error getting DBB link");
      })
      .catch(err => {
        console.error("Error getting DBB link", err);

        if (err.message) this.alertRef.show(err.message, { type: "error" });
        else if (err?.response?.data?.errors?.length) this.alertRef.show(err.response.data.errors[0], { type: "error" });
      });
  };

  handleDeleteInStorageTires = () => {
    const { car, updateSelectedCar } = this.props;

    this.setState({ isLoadingDeleteInStorageTires: true, errorDeletingInStorageTires: "" }, () => {
      Service.deleteInStorageTires({ car_id: car.id })
        .then(() => {
          this.setState({ isLoadingDeleteInStorageTires: false, showDeleteConfirmation: false }, () => {
            updateSelectedCar({ in_storage_tyres: null });
          });
        })
        .catch(err => {
          const errorDeletingInStorageTires = err?.response?.date?.errors?.length ? err.response.data.errors[0] : "Error deleting in storage tires";
          this.setState({ isLoadingDeleteInStorageTires: false, errorDeletingInStorageTires }, () => {
            console.error("Error deleteing in storage tires", err);
          });
        });
    });
  };

  handleDeleteInStorageTiresButton = () => {
    if (this.props.car.has_dbb) this.handleOpenDBBPortal();
    else this.toggleDeleteConfirmation();
  };

  renderDeleteConfirmation = () => {
    const { showDeleteConfirmation, isLoadingDeleteInStorageTires, errorDeletingInStorageTires } = this.state;
    const { t } = this.props;

    return (
      <CustomConfirm
        type="danger"
        isOpen={showDeleteConfirmation}
        handleConfirm={this.handleDeleteInStorageTires}
        handleCancel={this.toggleDeleteConfirmation}
        confirmMsg={t("confirm_delete_message").message || "Are you sure that you want to delete this? You can't undo this action."}
        isLoading={isLoadingDeleteInStorageTires}
        error={errorDeletingInStorageTires}
      />
    );
  };

  renderAlert = () => {
    const props = {
      offset: 20,
      position: "top right",
      theme: "light",
      time: 2000,
      transition: "fade",
    };

    return <AlertContainer ref={a => (this.alertRef = a)} {...props} />;
  };

  render() {
    const {
      car: { on_car_tyres, in_storage_tyres, alignment_recommended },
      lastAppointment,
      t,
    } = this.props;

    return (
      <div className="CarTyres CarDetailContainer">
        <h3>{t("tyres").message || "Tyres"}</h3>

        {!on_car_tyres && !in_storage_tyres && <div className="NoDataAvailable">{t("no_data_available").message || "No data available"}</div>}

        {(on_car_tyres || in_storage_tyres) && (
          <Grid>
            {on_car_tyres && (
              <>
                <div className="tyres-details on-car-title-container">
                  <strong>{t("on_car_tyres").message || "ON CAR"}</strong>
                  {alignment_recommended && (
                    <Label color="orange" tag>
                        {t("alignment_recommended").message || "Alignment recommended"}
                    </Label>
                  )}
                </div>
                {this.renderTyresSection(on_car_tyres, "on_car")}
              </>
            )}

            {in_storage_tyres && (
              <>
                <div className="tyres-details">
                  <strong>{t("in_storage_tyres").message || "IN STORAGE"}</strong>
                  {in_storage_tyres.dbb_location_name && (
                    <span className="storage-location">
                      {t("storage_location").message || "Storage Location"}: <span className="storage-location-value">{in_storage_tyres.dbb_location_name}</span>
                    </span>
                  )}
                  <Can I="remove-storage-set" the="tyres">
                    <Button
                      basic
                      floated="right"
                      className="in-storage-delete-btn"
                      disabled={lastAppointment?.appointment_status_identifier === APPOINTMENT_STATUSES.CAR_CHECK_STARTED}
                      onClick={this.handleDeleteInStorageTiresButton}
                    >
                      <FontAwesomeIcon icon={faTrash} className="in-storage-delete-icon" />
                    </Button>
                  </Can>
                </div>
                {this.renderTyresSection(in_storage_tyres, "in_storage")}
              </>
            )}
          </Grid>
        )}

        {this.renderDeleteConfirmation()}
        {this.renderAlert()}
      </div>
    );
  }
}

export default withTranslation()(CarTyres);
