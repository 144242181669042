import React, { Component } from "react";
import { Grid, Search, Form, Modal, Button, Icon, Popup } from "semantic-ui-react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import Loader from "react-loader-spinner";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport } from "@fortawesome/pro-regular-svg-icons";
import moment from "moment";

import { SubHeader, SnoozeTable, SearchPortal, UserMenuActionsPortal } from "../../../components";
import SnoozeOptions from "../../../components/Snooze/SnoozeOptions";
import { getDealerAndLocationById, getLocationById } from "../../../util/common";
import { setAlert } from "../../../modules/App/store.js";
import { getAppointment, deselectAppointment, getChecks, applyUpdateToAppointment, handleUpdateAppointments, APPOINTMENTS_ACTION_TYPES } from "../../Appointments/store";
import { QUESTION_RESULT_STATUS } from "../../Appointments/common";
import { getCar, deselectCar, CARS_ACTION_TYPES } from "../../Cars/store";
import AppointmentDetail from "../../Appointments/AppointmentDetail";
import CarDetail from "../../Cars/CarDetail";
import SnoozeFilters from "./SnoozeFilters.js";
import { LEAD_TYPES, normalizeResults, groupResults, prepareQuestionDropdownOptions } from "../common";

import Service from "../service";

import "../Leads.css";

class SnoozeLeads extends Component {
  state = {
    page: 0,
    nb_pages: null,
    nb_items: null,
    isLoading: false,
    isLoadingLeads: false,
    isAppointmentDetailVisible: false,
    selectedAppointment: null,
    isCarDetailVisible: false,
    selectedCar: null,
    searchTerm: "",
    selectedCarInfo: {},
    selectedLeadType: LEAD_TYPES.SNOOZED,
    customDateFrom: new Date().setMonth(new Date().getMonth() - 1),
    customDateTo: new Date(),
    selectedCustomerApproved: false,
    selectedSnoozedType: null,
    selectedSnoozedStatus: null,
    selectedStatuses: [QUESTION_RESULT_STATUS.OK_WITH_REMARKS, QUESTION_RESULT_STATUS.ADVISED, QUESTION_RESULT_STATUS.NECESSARY],
    selectedTags: [],
    selectedQuestions: [],
    selectedInterventions: false,
    items: [],
    selectedLocationsIDs: [],
    selectedDealersIDs: [],
    tags: [],
    questions: [],
    isLoadingTags: false,
    isLoadingSnoozeOptions: false,
    snoozeStatusOptions: [],
    snoozeTypeOptions: [],
    selectedSnoozedDepartment: null,
    snoozeDepartmentOptions: [],
    selectedIsLease: null,
    exportURL: "",
    exportModalOpen: false,
    isLoadingExport: false,
    exportError: false,
    moreFiltersVisible: false,
    shouldReset: false,
  };

  componentDidMount() {
    SnoozeOptions.getDropdownOptions()
      .then(result => {
        const { _snoozeOptions, ...rest } = result;

        this.setState({ ...rest });
      })
      .catch(error => {
        console.log("Error getting snooze options", error);
        this.props.setAlert({ type: "error", title: "Cannot load snooze options" });
      });

    this.getTags();
  }

  componentDidUpdate = prevProps => {
    const { selectedAppointment, selectedCar } = this.state;
    const { appointmentsState, carsState, setAlert, t } = this.props;

    if (appointmentsState.actionType !== prevProps.appointmentsState.actionType) {
      if (selectedAppointment) {
        if (appointmentsState.actionType === APPOINTMENTS_ACTION_TYPES.GET_APPOINTMENT_FAIL) {
          setAlert({ type: "error", title: "Cannot load this appointment" });
          this.setState({ selectedAppointment: null, isLoading: false });
        }

        if (appointmentsState.actionType === APPOINTMENTS_ACTION_TYPES.GET_APPOINTMENT_SUCCESS) this.setState({ isAppointmentDetailVisible: true, isLoading: false });

        if (appointmentsState.actionType === APPOINTMENTS_ACTION_TYPES.WEB_SOCKET_APPOINTMENTS_UPDATE && appointmentsState.webSocketUpdate) {
          this.socketAppointmentUpdate(appointmentsState.webSocketEvent);
        }
      }
    }

    if (carsState.actionType !== prevProps.carsState.actionType) {
      if (selectedCar) {
        if (carsState.actionType === CARS_ACTION_TYPES.GET_CAR_FAIL) {
          this.setState({ selectedCar: null, isLoading: false }, () => setAlert({ type: "error", title: t("can_not_load_this_car").message || "Can not load this car" }));
        } else if (carsState.actionType === CARS_ACTION_TYPES.GET_CAR_NOT_AUTHORIZED) {
          this.setState({ selectedCar: null, isLoading: false }, () =>
            setAlert({ type: "error", title: t("car_location_no_access_err_msg").message || "This car has moved to a location you cannot access" })
          );
        }

        if (carsState.actionType === CARS_ACTION_TYPES.GET_CAR_SUCCESS) {
          if (!carsState.selectedCar) {
            this.setState({ selectedCar: null, isLoading: false });
          } else {
            this.setState({
              isLoading: false,
              isCarDetailVisible: true,
              selectedCarInfo: {
                car: carsState.selectedCar,
                customer: carsState.selectedCustomer,
                appointmentHistory: carsState.selectedCarAppointments,
                snoozedQuestions: carsState.selectedCarSnoozedQuestions,
              },
            });
          }
        }
      }
    }
  };

  socketAppointmentUpdate = event => {
    let updatePayload = null;
    if (event.payload?.data) updatePayload = JSON.parse(event.payload.data);

    // apply update to appointmen list and appointment detail page if open
    this.props.applyUpdateToAppointment(updatePayload, true);
  };

  handleSearchChange = (_e, data) => {
    this.setState({ searchTerm: data.value }, () => this.getLeadsItems());
  };

  getTags = () => {
    this.setState({ isLoadingTags: true }, () => {
      Service.getTags()
        .then(res => {
          let tags = [];

          if (Array.isArray(res.data?.data?.tags)) tags = res.data.data.tags.filter(t => t.system);
          this.setState({ tags, isLoadingTags: false });
        })
        .catch(err => {
          this.setState({ isLoadingTags: false });
          this.props.setAlert({ type: "error", title: "Cannot load tags" });
        });
    });
  };

  getSnoozedLeads = () => {
    const {
      page,
      searchTerm,
      customDateFrom,
      customDateTo,
      selectedDealersIDs,
      selectedLocationsIDs,
      selectedStatuses,
      selectedCustomerApproved,
      selectedSnoozedType,
      selectedSnoozedStatus,
      selectedSnoozedDepartment,
      selectedIsLease,
      selectedTags,
      selectedQuestions,
      selectedInterventions,
    } = this.state;

    const params = {
      page,
      date_from: moment(customDateFrom).format("YYYY-MM-DDT00:00:00Z"),
      date_to: moment(customDateTo).format("YYYY-MM-DDT23:59:59Z"),
      dealer_ids: selectedDealersIDs,
      location_ids: selectedLocationsIDs,
      customer_approved: selectedCustomerApproved,
      snooze_type_id: selectedSnoozedType ? selectedSnoozedType : null,
      snooze_status_id: Number.isInteger(selectedSnoozedStatus) ? selectedSnoozedStatus : null,
      snooze_department_id: selectedSnoozedDepartment ? selectedSnoozedDepartment : null,
      statuses: selectedStatuses.length > 0 ? selectedStatuses : null,
      search_term: searchTerm,
      interventions: selectedInterventions,
      tags: selectedTags.length ? selectedTags : null,
      questions: selectedQuestions.length ? selectedQuestions : null,
      is_lease_company: selectedIsLease === true || selectedIsLease === false ? selectedIsLease : null,
    };

    return Service.getSnoozedLeads(params);
  };

  getLeadsItems = page => {
    if (!page) page = 1;
    else if (page === this.state.page || this.state.isLoadingLeads) return; // react table is going to fire this incorrectly

    this.setState({ isLoadingLeads: true, page }, async () => {
      const { selectedLocationsIDs, selectedDealersIDs } = this.state;

      if (selectedLocationsIDs.length < 1 && selectedDealersIDs.length < 1) {
        this.setState({ items: [], nb_pages: 0, nb_items: 0, isLoadingLeads: false });
        return;
      }

      try {
        const response = await this.getSnoozedLeads();
        const { items, nb_pages, nb_items } = normalizeResults(response);
        const grouped = groupResults(items);

        this.setState({ items: grouped, page, nb_pages, nb_items, isLoadingLeads: false });
      } catch (error) {
        console.log("Error getting leads", error);
        this.setState({ isLoadingLeads: false });
        this.props.setAlert({ type: "error", title: "Cannot load leads" });
        return;
      }
    });
  };

  handleChangeCustomDate = dates => {
    const [start, end] = dates;
    this.setState({ customDateFrom: start, customDateTo: end }, () => {
      if (!end) return;
      this.getLeadsItems();
    });
  };

  handleChangeTags = selectedTags => this.setState({ selectedTags, selectedInterventions: false }, () => this.getLeadsItems());

  handleChangeQuestions = selectedQuestions => this.setState({ selectedQuestions, selectedInterventions: false }, () => this.getLeadsItems());

  handleChangeInterventions = selectedInterventions =>
    this.setState(
      {
        selectedInterventions,
        selectedQuestions: [],
        selectedStatuses: selectedInterventions ? [] : [QUESTION_RESULT_STATUS.OK_WITH_REMARKS, QUESTION_RESULT_STATUS.ADVISED, QUESTION_RESULT_STATUS.NECESSARY],
        selectedTags: [],
      },
      () => this.getLeadsItems()
    );

  handleChangeStatus = status => {
    let { selectedStatuses } = this.state;
    if (status === null) selectedStatuses = [];
    else if (selectedStatuses.includes(status)) selectedStatuses = selectedStatuses.filter(s => s !== status);
    else selectedStatuses = selectedStatuses.concat(status);

    this.setState({ selectedStatuses, selectedInterventions: false }, () => this.getLeadsItems());
  };

  handleChangeSelectedLocationsIDs = ({ location_ids, dealer_ids }) => {
    const prevSelectedLocationIDs = [...this.state.selectedLocationsIDs];
    const prevSelectedDealerIDs = [...this.state.selectedDealersIDs];

    this.setState({ selectedLocationsIDs: location_ids, selectedDealersIDs: dealer_ids }, () => {
      if (location_ids.length !== prevSelectedLocationIDs.length || dealer_ids.length !== prevSelectedDealerIDs.length) this.getQuestionsByChecklist();

      this.getLeadsItems();
    });
  };

  handleChangeSnoozedStatus = selectedSnoozedStatus => this.setState({ selectedSnoozedStatus }, () => this.getLeadsItems());

  handleChangeSnoozedType = selectedSnoozedType => this.setState({ selectedSnoozedType }, () => this.getLeadsItems());

  handleCloseExportModal = () => this.setState({ exportURL: "", exportError: false, isLoadingExport: false, exportModalOpen: false });

  handleChangeIsLease = selectedIsLease => this.setState({ selectedIsLease }, () => this.getLeadsItems());

  handleChangeSnoozedDepartment = selectedSnoozedDepartment => this.setState({ selectedSnoozedDepartment }, () => this.getLeadsItems());

  handleChangeCustomerApproved = selectedCustomerApproved => this.setState({ selectedCustomerApproved }, () => this.getLeadsItems());

  handleChangeSnoozedDepartment = selectedSnoozedDepartment => this.setState({ selectedSnoozedDepartment }, () => this.getLeadsItems());

  getQuestionsByChecklist = () => {
    const { selectedDealersIDs, selectedLocationsIDs } = this.state;

    if (!selectedDealersIDs.length && !selectedLocationsIDs.length) {
      this.setState({ questions: [] });
      return;
    }

    const requestData = {
      dealer_ids: selectedDealersIDs.length ? selectedDealersIDs : null,
      location_ids: selectedLocationsIDs.length ? selectedLocationsIDs : null,
      embed: "question",
    };

    this.setState({ isLoadingQuestions: true }, () => {
      Service.getChecklists(requestData)
        .then(res => {
          if (res?.data?.data?.checklists) this.setState({ questions: prepareQuestionDropdownOptions(res.data.data.checklists), isLoadingQuestions: false });
        })
        .catch(err => {
          this.setState({ isLoadingQuestions: false });
          this.props.setAlert({ type: "error", title: "Cannot load question options" });
        });
    });
  };

  handleExportSnoozed = () => {
    const {
      searchTerm,
      customDateFrom,
      customDateTo,
      selectedLocationsIDs,
      selectedStatuses,
      selectedCustomerApproved,
      selectedSnoozedType,
      selectedSnoozedStatus,
      selectedSnoozedDepartment,
      selectedIsLease,
      selectedTags,
      selectedQuestions,
      selectedInterventions,
    } = this.state;

    const params = {
      date_from: moment(customDateFrom).format("YYYY-MM-DDT00:00:00Z"),
      date_to: moment(customDateTo).format("YYYY-MM-DDT23:59:59Z"),
      location_id: selectedLocationsIDs[0],
      customer_approved: selectedCustomerApproved,
      snooze_type_id: selectedSnoozedType ? selectedSnoozedType : null,
      snooze_status_id: Number.isInteger(selectedSnoozedStatus) ? selectedSnoozedStatus : null,
      snooze_department_id: selectedSnoozedDepartment ? selectedSnoozedDepartment : null,
      statuses: selectedStatuses.length > 0 ? selectedStatuses : null,
      search_term: searchTerm,
      intervention: selectedInterventions,
      tags: selectedTags.length ? selectedTags : null,
      questions: selectedQuestions.length ? selectedQuestions : null,
      is_lease_company: selectedIsLease === true || selectedIsLease === false ? selectedIsLease : null,
    };

    return Service.exportSnoozed(params);
  };

  handleExport = () => {
    this.setState({ exportModalOpen: true, isLoadingExport: true }, async () => {
      try {
        const response = await this.handleExportSnoozed();

        const exportURL = response?.data?.data || "";
        this.setState({ exportURL, isLoadingExport: false });
      } catch (error) {
        this.setState({ isLoadingExport: false, exportError: true });
      }
    });
  };

  handleUpdateMoreFilters = () => {
    this.setState(prevState => ({
      moreFiltersVisible: !prevState.moreFiltersVisible,
    }));
  };

  handleRefresh = () => {
    this.getLeadsItems();
  };

  handleResetFilters = () => {
    this.setState(
      {
        shouldReset: true,
        selectedStatuses: [QUESTION_RESULT_STATUS.OK_WITH_REMARKS, QUESTION_RESULT_STATUS.ADVISED, QUESTION_RESULT_STATUS.NECESSARY],
        selectedCustomerApproved: false,
        selectedSnoozedType: null,
        customDateFrom: new Date().setMonth(new Date().getMonth() - 1),
        customDateTo: new Date(),
        selectedSnoozedStatus: null,
        selectedIsLease: null,
        selectedSnoozedDepartment: null,
        selectedQuestions: [],
        selectedTags: [],
      },
      () => {
        this.setState({ shouldReset: false }, () => this.getLeadsItems());
      }
    );
  };

  handleSnoozeChanged = updatedSnoozeLog => {
    let { items } = this.state;

    const updatedItem = updatedSnoozeLog[0];

    items = items.map(i => {
      if ((i.question_result_id && i.question_result_id === updatedItem.question_result_id) || (i.intervention_id && i.intervention_id === updatedItem.intervention_id)) {
        return { ...updatedItem, history: updatedSnoozeLog };
      }

      return i;
    });

    this.setState({ items });
  };

  handleSnoozeDeleted = (groupedItem, reason) => {
    const { user } = this.props.authState;
    const newLastLog = { ...groupedItem.history[0] };
    newLastLog.snooze_status_id = 0;
    newLastLog.user_id = user.id;
    newLastLog.user = user;
    newLastLog.note = reason;
    newLastLog.id++;
    newLastLog.created_on = moment().format("YYYY-MM-DDTHH:mm:ssZ");

    groupedItem = { ...groupedItem, ...newLastLog, history: [newLastLog, ...groupedItem.history] };
    if (groupedItem.question_result) groupedItem.question_result.snoozed = false;
    if (groupedItem.intervention) groupedItem.intervention.snoozed = false;

    const items = this.state.items.map(i => {
      if ((i.question_result_id && i.question_result_id === groupedItem.question_result_id) || (i.intervention_id && i.intervention_id === groupedItem.intervention_id)) {
        return groupedItem;
      }

      return i;
    });

    this.setState({ items });
  };

  handleAppointmentClick = appointment => {
    this.setState({ selectedAppointment: { id: appointment.id, dealer_location_id: appointment.dealer_location_id }, isLoading: true }, () =>
      this.props.getAppointment(appointment.id)
    );
  };

  handleUpdateSelectedAppointmentFromCarDetail = appointment_id => {
    this.setState({ selectedAppointment: { appointment_id, dealer_location_id: this.state.selectedCar.dealer_location_id }, isLoading: true }, () =>
      this.props.getAppointment(appointment_id)
    );
  };

  handleCloseAppointmentDetail = () => {
    this.setState({ selectedAppointment: null, isAppointmentDetailVisible: false });
    this.props.deselectAppointment();
  };

  handleCarClick = (car_id, dealer_location_id) => {
    this.setState(
      {
        selectedCar: { car_id, dealer_location_id },
        isLoading: true,
        isAppointmentDetailVisible: false,
        selectedAppointment: null,
      },
      () => {
        this.props.getCar(car_id);
      }
    );
  };

  handleCloseCarDetail = () => {
    this.setState({ selectedCar: null, isCarDetailVisible: false, selectedCarInfo: {} });
    this.props.deselectCar();
  };

  renderAppointmentDetail = () => {
    if (!this.state.isAppointmentDetailVisible || !this.props.appointmentsState.selectedAppointment) return null;

    const { authState, appointmentsState, globalState, getChecks, handleUpdateAppointments } = this.props;
    const { dealer, location } = getDealerAndLocationById(globalState.dealers, appointmentsState.selectedAppointment.dealer_location_id);

    return (
      <AppointmentDetail
        detailPageOnlineUsers={appointmentsState.detailPageOnlineUsers}
        currentUser={authState.user}
        appointment={appointmentsState.selectedAppointment}
        appointmentChecks={appointmentsState.selectedAppointmentChecks}
        location={location}
        dealer={dealer}
        getChecks={getChecks}
        onRegistrationClick={car_id => this.handleCarClick(car_id, appointmentsState.selectedAppointment.dealer_location_id)}
        onClose={this.handleCloseAppointmentDetail}
        onHandleUpdateAppointments={handleUpdateAppointments}
      />
    );
  };

  renderCarDetail = () => {
    const { selectedCarInfo } = this.state;
    const { globalState } = this.props;

    const location = getLocationById(globalState.dealers, selectedCarInfo.car.dealer_location_id);

    return (
      <CarDetail
        visible={true}
        car={selectedCarInfo.car}
        customer={selectedCarInfo.customer}
        appointmentHistory={selectedCarInfo.appointmentHistory}
        snoozedQuestions={selectedCarInfo.snoozedQuestions}
        location={location}
        onHide={() => this.handleCloseCarDetail()}
        onSelectAppointment={id => this.handleUpdateSelectedAppointmentFromCarDetail(id)}
      />
    );
  };

  render() {
    const {
      isAppointmentDetailVisible,
      isCarDetailVisible,
      isLoading,
      customDateFrom,
      customDateTo,
      selectedSnoozedType,
      selectedCustomerApproved,
      selectedStatuses,
      items,
      page,
      nb_pages,
      nb_items,
      isLoadingLeads,
      selectedLocationsIDs,
      tags,
      questions,
      isLoadingTags,
      selectedSnoozedStatus,
      isLoadingSnoozeOptions,
      snoozeStatusOptions,
      snoozeTypeOptions,
      selectedSnoozedDepartment,
      snoozeDepartmentOptions,
      selectedIsLease,
      selectedTags,
      selectedQuestions,
      selectedInterventions,
      isLoadingExport,
      exportURL,
      exportModalOpen,
      exportError,
      moreFiltersVisible,
      shouldReset,
    } = this.state;

    const { authState, t } = this.props;
    const { csv_download } = authState.user;

    return (
      <>
        <SearchPortal>
          <Search
            minCharacters={4}
            className="-large-search"
            input={{
              icon: "search",
              iconPosition: "left",
              placeholder: t("start_searching_leads").message || "start searching leads by WO or registration...",
            }}
            loading={false}
            showNoResults={false}
            onSearchChange={_.debounce(this.handleSearchChange, 500)}
            fluid
          />
        </SearchPortal>
        <UserMenuActionsPortal>
          <Icon name="refresh" onClick={this.handleRefresh} />
        </UserMenuActionsPortal>
        <SubHeader>
          <Grid.Column width={16} className="header-container" style={{ justifyContent: "right" }}>
            <div className="export-btn-leads-type-container">
              <Form.Field>
                <Button icon size="small" labelPosition="left" className="reset-filters-btn" onClick={this.handleResetFilters}>
                  <Icon name="trash" color="green" style={{ backgroundColor: "white", borderRight: "1px solid #E8E8E8" }} />
                  <b>{t("reset_filter").message || "Reset Filter"}</b>
                </Button>
              </Form.Field>

              <Form.Field>
                <Button icon size="small" labelPosition="left" active={moreFiltersVisible} className="more-filters-btn" onClick={this.handleUpdateMoreFilters}>
                  <Icon name="filter" color="green" style={{ backgroundColor: "white", borderRight: "1px solid #E8E8E8" }} />
                  <b>{t("more_filters").message || "More Filters"}</b>
                </Button>
              </Form.Field>

              {csv_download && (
                <Popup
                  position="top right"
                  content={t("leads_export_disabled").message || "Export disabled as more than one location is selected."}
                  disabled={selectedLocationsIDs.length === 1}
                  trigger={
                    <div style={{ display: "inline-block" }}>
                      <Button onClick={this.handleExport} disabled={selectedLocationsIDs.length !== 1} className="export-button" floated="right" basic color="green">
                        <span style={{ marginRight: "5px" }}>{t("export").message || "Export"}</span>
                        <FontAwesomeIcon icon={faFileExport} />
                      </Button>
                    </div>
                  }
                />
              )}
            </div>

            <Modal size="tiny" open={exportModalOpen} onClose={this.handleCloseExportModal} closeOnDimmerClick={false} className="ExportLeads-Modal">
              <Modal.Content className="export-modal-content">
                {isLoadingExport && <p>{t("export_in_progress").message || "Export in progress..."}</p>}
                {!isLoadingExport && exportURL && <p>{t("export_ready").message || "Export ready to be downloaded"}</p>}
                {!isLoadingExport && (exportError || !exportURL) && <p>{t("failed_error_message").message || "Something went wrong, please try again."}</p>}
              </Modal.Content>
              <Modal.Actions>
                <Button onClick={this.handleCloseExportModal} color="grey">
                  {t("close").message || "Close"}
                </Button>
                <Button onClick={() => window.open(exportURL, "_blank")} disabled={isLoadingExport || !exportURL} color="green">
                  {t("download").message || "Download"}
                </Button>
              </Modal.Actions>
            </Modal>
          </Grid.Column>
          <Grid.Column width={16}>
            <SnoozeFilters
              isLoadingTags={isLoadingTags}
              tags={tags}
              questions={questions}
              onChangeCustomDates={this.handleChangeCustomDate}
              customDateFrom={customDateFrom}
              customDateTo={customDateTo}
              selectedSnoozedStatus={selectedSnoozedStatus}
              onChangeSnoozedStatus={this.handleChangeSnoozedStatus}
              selectedSnoozedType={selectedSnoozedType}
              onChangeSnoozedType={this.handleChangeSnoozedType}
              selectedCustomerApproved={selectedCustomerApproved}
              onChangeCustomerApproved={this.handleChangeCustomerApproved}
              selectedStatuses={selectedStatuses}
              onChangeStatus={this.handleChangeStatus}
              onChangeSelectedLocationIDs={this.handleChangeSelectedLocationsIDs}
              isLoadingSnoozeOptions={isLoadingSnoozeOptions}
              snoozeStatusOptions={snoozeStatusOptions}
              snoozeTypeOptions={snoozeTypeOptions}
              selectedSnoozedDepartment={selectedSnoozedDepartment}
              onChangeSnoozedDepartment={this.handleChangeSnoozedDepartment}
              snoozeDepartmentOptions={snoozeDepartmentOptions}
              selectedIsLease={selectedIsLease}
              onChangeIsLease={this.handleChangeIsLease}
              selectedTags={selectedTags}
              onChangeTags={this.handleChangeTags}
              selectedQuestions={selectedQuestions}
              onChangeQuestions={this.handleChangeQuestions}
              selectedInterventions={selectedInterventions}
              onChangeInterventions={this.handleChangeInterventions}
              moreFiltersVisible={moreFiltersVisible}
              shouldReset={shouldReset}
            />
          </Grid.Column>
        </SubHeader>

        <SnoozeTable
          showRegNr
          page={page}
          pages={nb_pages}
          nb_items={nb_items}
          loading={isLoadingLeads}
          onFetchData={this.getLeadsItems}
          onSnoozeDeleted={this.handleSnoozeDeleted}
          onSnoozeChanged={this.handleSnoozeChanged}
          items={items}
          onAppointmentClick={this.handleAppointmentClick}
          onCarClick={this.handleCarClick}
          showLocationColumn={selectedLocationsIDs.length > 1 || (selectedLocationsIDs.length > 0 && selectedLocationsIDs[0] === "all")}
          snoozeStatusOptions={snoozeStatusOptions}
          snoozeTypeOptions={snoozeTypeOptions}
        />

        {isAppointmentDetailVisible && this.renderAppointmentDetail()}
        {isCarDetailVisible && this.renderCarDetail()}

        {(isLoading || isLoadingLeads) && (
          <div className="Loader">
            <Loader type="Oval" color="#46923d" height="100" width="100" />
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = state => {
  return { globalState: state.global, appointmentsState: state.appointments, carsState: state.cars, authState: state.auth };
};

const mapDispatchToProps = dispatch => {
  return {
    applyUpdateToAppointment: (updates, skipMainTableUpdate) => dispatch(applyUpdateToAppointment(updates, skipMainTableUpdate)),
    deselectAppointment: () => dispatch(deselectAppointment()),
    deselectCar: () => dispatch(deselectCar()),
    getAppointment: appID => dispatch(getAppointment(appID)),
    getCar: carID => dispatch(getCar(carID)),
    getChecks: appID => dispatch(getChecks(appID)),
    handleUpdateAppointments: appointment => dispatch(handleUpdateAppointments(appointment)),
    setAlert: alertOptions => dispatch(setAlert(alertOptions)),
  };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(SnoozeLeads));
