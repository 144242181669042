import React from "react";
import { withTranslation } from "react-i18next";
import { Icon, Popup } from "semantic-ui-react";

import { removeGenerationParam } from "../../../../util/common";

import "./CarQuickView.scss";

const CarQuickView = ({ appointment, popupTrigger, t }) => {
  const content = (
    <div className="car-info-container">
      <div className="car-info-inner-container">
        <div className="car-image-container">
          {appointment.car_profile_picture ? (
            <img src={removeGenerationParam(appointment.car_profile_picture)} alt="Car Profile" className="car-image" />
          ) : (
            <Icon name="car" />
          )}
        </div>
        <div className="car-details">
          <div className="detail-item">
            <span className="detail-title">{t("brand").message || "Brand"}</span>
            <span className="detail-value">{appointment.car_make}</span>
          </div>
          <div className="detail-item">
            <span className="detail-title">{t("driver").message || "Driver"}</span>
            <span className="detail-value">{`${appointment.driver_firstname || ""} ${appointment.driver_surname || ""}`.trim()}</span>
          </div>
          <div className="detail-item">
            <span className="detail-title">{t("model").message || "Model"}</span>
            <span className="detail-value">{appointment.car_model}</span>
          </div>
          <div className="detail-item">
            <span className="detail-title">{t("contractor").message || "Contractor"}</span>
            <span className="detail-value">{`${appointment.contractor_firstname || ""} ${appointment.contractor_surname || ""}`.trim()}</span>
          </div>
          <div className="detail-item">
            <span className="detail-title">{t("dayplanner_car_vin_nr").message || "Vin number"}</span>
            <span className="detail-value">{appointment.vin_nr}</span>
          </div>
          <div className="detail-item">
            <span className="detail-title">{t("owner").message || "Owner"}</span>
            <span className="detail-value">{`${appointment.owner_firstname || ""} ${appointment.owner_surname || ""}`.trim()}</span>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <Popup
      trigger={popupTrigger}
      content={content}
      hoverable
      style={{ padding: "0 5px" }}
      popperModifiers={{
        preventOverflow: {
          boundariesElement: "offsetParent",
        },
        flip: {
          flipVariationsByContent: true,
        },
      }}
    />
  );
};

export default withTranslation()(CarQuickView);
