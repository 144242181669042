import React from "react";
import { Dropdown } from "semantic-ui-react";

export const LEAD_TYPES = {
  SNOOZED: 1,
  NOT_SNOOZED: 2,
  CARS: 3,
  TIRES: 4,
};

export const normalizeResults = response => {
  const data = response?.data?.data ? response.data.data : { items: [], nb_pages: 0, nb_items: 0 };

  if (!Array.isArray(data.items)) data.items = [];
  if (!data.nb_pages) data.nb_pages = 0;
  if (!data.nb_items) data.nb_items = 0;

  return data;
};

export const groupWOResults = questions => {
  questions.forEach((item, index) => {
    if (questions[index + 1] && item.appointment_id === questions[index + 1].appointment_id) {
      questions[index + 1].appointment = {
        ...questions[index + 1].appointment,
        reg_number: "",
        wo_nr: 0,
        time_car_app: "",
        customer_contract: null,
        customer_driver: null,
        customer_owner: null,
      };
    }
  });

  return questions;
};

export const groupResults = results => {
  const interventions = [],
    questions = [],
    interventions_offsets = [],
    questions_offsets = [];

  let intervention_offset = 0,
    question_offset = 0;

  results.forEach(i => {
    if (i.intervention_id) {
      if (interventions_offsets[i.intervention_id] === undefined) {
        interventions_offsets[i.intervention_id] = intervention_offset++;
        interventions[interventions_offsets[i.intervention_id]] = { ...i, history: [i] };
      } else {
        if (i.created_on > interventions[interventions_offsets[i.intervention_id]].created_on)
          interventions[interventions_offsets[i.intervention_id]] = {
            ...i,
            history: interventions[interventions_offsets[i.intervention_id]].history.concat(i),
          };
        else interventions[interventions_offsets[i.intervention_id]].history.push(i);
      }
    } else if (i.question_result_id) {
      if (questions_offsets[i.question_result_id] === undefined) {
        questions_offsets[i.question_result_id] = question_offset++;
        questions[questions_offsets[i.question_result_id]] = { ...i, history: [i] };
      } else {
        if (i.created_on > questions[questions_offsets[i.question_result_id]].created_on)
          questions[questions_offsets[i.question_result_id]] = { ...i, history: questions[questions_offsets[i.question_result_id]].history.concat(i) };
        else questions[questions_offsets[i.question_result_id]].history.push(i);
      }
    } else {
      console.log("invalid item", i);
    }
  });

  return groupWOResults([...interventions, ...questions].sort((a, b) => (b.appointment.time_car_app > a.appointment.time_car_app ? 1 : -1)));
};

export const prepareQuestionDropdownOptions = checklistResults => {
  const groupedQuestions = [];

  const checklists = checklistResults.filter(c => c.active && c.questions?.length);

  checklists.forEach(checklist => {
    groupedQuestions.push({
      component: <Dropdown.Menu />,
      key: `checklist-${checklist.id}`,
      disabled: true,
      children: (
        <Dropdown.Menu scrolling>
          <Dropdown.Header content={checklist.name} />
          <Dropdown.Divider />
        </Dropdown.Menu>
      ),
    });

    checklist.questions.forEach(q => {
      const key = `${checklist.id}-${q.id}`;

      if (!groupedQuestions.some(gq => gq.key === key)) {
        groupedQuestions.push({
          key,
          value: q.id,
          text: q.title,
        });
      }
    });
  });

  return groupedQuestions;
};

export const dropdownListOptions = options => options.map((value, idx) => ({ key: idx, value: value, text: value }));
