import axios from "axios";

import ENV from "../../util/env-config";
import { getSessionCookie } from "../../util/common";

class Service {
  static requestConfig() {
    return {
      headers: {
        Authorization: `Bearer ${getSessionCookie()}`,
        Pragma: "no-cache",
      },
    };
  }

  static getTemplates() {
    return axios.get(ENV.dealerBaseUrl + "/checklists/template/list_short", this.requestConfig());
  }

  static createTemplate(requestData) {
    return axios.post(ENV.dealerBaseUrl + "/checklists/template/create", requestData, this.requestConfig());
  }

  static updateTemplate(requestData) {
    return axios.post(ENV.dealerBaseUrl + "/checklists/template/update", requestData, this.requestConfig());
  }

  static getReportTemplates(requestData) {
    return axios.post(ENV.apiBaseB + "/report_templates/check_list", requestData, this.requestConfig());
  }

  static getTemplateDetail(requestData) {
    return axios.post(ENV.dealerBaseUrl + "/checklists/template/details", requestData, this.requestConfig());
  }

  static deleteTemplate(requestData) {
    return axios.post(ENV.dealerBaseUrl + "/checklists/template/delete", requestData, this.requestConfig());
  }
}

export default Service;
