import React, { Component } from "react";
import AlertContainer from "react-alert";
import { Icon, Segment, Grid, Header, Form } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWrench } from "@fortawesome/free-solid-svg-icons";
import { faCarSide, faSms } from "@fortawesome/pro-solid-svg-icons";
import { withTranslation } from "react-i18next";

import { UserInput } from "../../components";
import DealerForm from "../DealersAdmin/DealerForm";
import DealerSystemForm from "../DealersAdmin/DealerSystemForm";
import DomainSettings from "./DomainSettings";
import PlanItSettings from "./PlanITSettings";
import OneiPlanningSettings from "./OneiPlanningSettings";
import SMSClientConfiguration from "./SMSClientConfiguration";

import IcarSettings from "./ICARSettings";
import Service from "./service";

class DealerDetail extends Component {
  state = {
    domainError: false,
    isLoading: false, // domain actions are handled differently, that's why isLoading value from index.js is not used,
    alertOptions: {
      offset: 20,
      position: "top right",
      theme: "light",
      time: 10000,
      transition: "fade",
    },
    updateDomainError: "",
    dealer: { ...this.props.dealer },
  };

  componentDidUpdate(prevProps) {
    if (this.props.dealer !== prevProps.dealer) {
      this.setState({ dealer: this.props.dealer });
    }
  }

  error = msg => {
    this.msg.show(msg, { type: "error" });
  };

  renderAlert = () => {
    return <AlertContainer ref={a => (this.msg = a)} {...this.state.alertOptions} />;
  };

  handleDomainUpdate = () => {
    let { dealer } = this.state;

    this.setState({ isLoading: true, updateDomainError: "" }, () => {
      if (this.state.dealer && this.state.dealer.domain && this.state.dealer.domain.name) {
        Service.createDomain(this.state.dealer.domain.name, this.props.dealer.id)
          .then(result => {
            if (result && result.data && result.data.data) {
              dealer.domain = result.data.data;
              this.setState({ dealer, domainError: false, isLoading: false });
            }
          })
          .catch(error => {
            this.setState({ domainError: true, isLoading: false, updateDomainError: error.response?.data?.errors && error.response.data.errors[0] });
            console.log(error);
          });
      }
    });
  };

  handleDomainRefresh = () => {
    let { dealer } = this.state;

    this.setState({ isLoading: true }, () => {
      Service.verifyDomainInfo(this.state.dealer.domain.name, this.state.dealer.id)
        .then(result => {
          if (result && result.data && result.data.data) {
            dealer.domain.state = result.data.data;
            this.setState({ dealer, isLoading: false });
          }
        })
        .catch(error => {
          this.setState({ isLoading: false });
          console.log(error);
        });
    });
  };

  handleDomainDelete = () => {
    let { dealer } = this.state;
    Service.deleteDomain(this.state.dealer.id)
      .then(result => {
        dealer.domain.name = "";
        dealer.domain = null;
        this.setState({ dealer });
        this.props.onUpdateDealer(dealer);
      })
      .catch(error => {
        error.response?.data?.errors && this.error(error.response.data.errors[0]);
        console.log(error);
      });
  };

  render() {
    const { t, user, missingField, onUpdateDealer } = this.props;
    const { dealer, domainError, isLoading } = this.state;

    return (
      <div className="DealerDetail">
        {this.renderAlert()}
        <Grid columns="equal">
          <Grid.Column>
            <Segment placeholder>
              <Header as="h5">
                <Icon name="warehouse" />
                <Header.Content>{t("dealer_settings").message || "Dealer settings"}</Header.Content>
              </Header>
              <Segment>
                <DealerForm missingField={missingField} dealer={dealer} user={user} onChange={onUpdateDealer} t={t} />
              </Segment>
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment placeholder>
              <Header as="h5">
                <Icon name="info" />
                <Header.Content>{t("dealer_sys_info").message || "System info"}</Header.Content>
              </Header>
              <Segment>
                <DealerSystemForm dealer={dealer} t={t} onChange={onUpdateDealer} isAdmin={this.props.isAdmin} />
              </Segment>
            </Segment>

            <Segment placeholder>
              <Header as="h5">
                <FontAwesomeIcon icon={faCarSide} style={{ marginRight: "10px", fontSize: "1.5em" }} />
                <Header.Content>{t("icar_settings").message || "ICAR settings"}</Header.Content>
              </Header>
              <Segment>
                <IcarSettings dealer={dealer} onUpdateDealer={onUpdateDealer} />
              </Segment>
            </Segment>

            <Segment>
              <Header as="h5">
                <Header.Content>{t("planit_planning").message || "Plan IT Planning"}</Header.Content>
              </Header>
              <Segment>
                <PlanItSettings dealer={dealer} onUpdateDealer={onUpdateDealer} />
              </Segment>
            </Segment>

            <Segment placeholder>
              <Header as="h5">
                <FontAwesomeIcon icon={faWrench} style={{ marginRight: "10px", fontSize: "1.5em" }} />
                <Header.Content>{t("onei_planning_settings").message || "1i Planning Settings"}</Header.Content>
              </Header>
              <Segment>
                <OneiPlanningSettings dealer={dealer} onUpdateDealer={onUpdateDealer} />
              </Segment>
            </Segment>

            <Segment placeholder>
              <Header as="h5">
                <FontAwesomeIcon icon={faWrench} style={{ marginRight: "10px", fontSize: "1.5em" }} />
                <Header.Content>WebClock</Header.Content>
              </Header>
              <Segment>
                <Form>
                  <Form.Field className="full-width-input">
                    <label>WebClock URL</label>
                    <UserInput
                      value={dealer.web_clock_url}
                      placeholder="http://demo.com/TruckVision/KlokSysteem/Account/Login.aspx?omgvid=@omgvid&mwnt=@mwnt&dsornr=@dsornr&src=claireit"
                      name="web_clock_url"
                      error={
                        dealer.web_clock_url &&
                        (!dealer.web_clock_url.includes("@omgvid") || !dealer.web_clock_url.includes("@mwnt") || !dealer.web_clock_url.includes("@dsornr"))
                      }
                      onChange={(_e, data) => onUpdateDealer({ ...dealer, web_clock_url: data.value })}
                    />
                  </Form.Field>
                </Form>
              </Segment>
            </Segment>
          </Grid.Column>

          <Grid.Column>
            {/* Domain settings */}
            {dealer.custom_domain_enabled && (
              <Segment placeholder>
                <Header as="h5">
                  <Icon name="globe" />
                  <Header.Content>{this.props.t("email_settings").message || "Email settings"}</Header.Content>
                </Header>
                <Segment>
                  <DomainSettings
                    onUpdateDealer={onUpdateDealer}
                    dealer={dealer}
                    domainError={domainError}
                    domainUpdate={this.handleDomainUpdate}
                    updateDomainError={this.state.updateDomainError}
                    domainRefresh={this.handleDomainRefresh}
                    domainDelete={this.handleDomainDelete}
                    isLoading={isLoading}
                  />
                </Segment>
              </Segment>
            )}

            <Segment placeholder style={{ minHeight: "auto" }}>
              <Header as="h5">
                <FontAwesomeIcon icon={faSms} style={{ marginRight: "10px", fontSize: "1.5em" }} />
                <Header.Content>{this.props.t("sms_configuration").message || "SMS Configuration"}</Header.Content>
              </Header>

              <Segment>
                <SMSClientConfiguration dealer={dealer} onUpdateDealer={onUpdateDealer} missingField={missingField} />
              </Segment>
            </Segment>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

export default withTranslation()(DealerDetail);
