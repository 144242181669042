import React from "react";
import { Popup, Icon, Image, Label } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { Can } from "../../modules";

import "./UserMenu.css";

const Content = ({ onMenuItemClick }) => (
  <ul>
    <Can I="browse" the="account-page">
      <li onClick={() => onMenuItemClick("account")}>Account</li>
    </Can>
    <li onClick={() => onMenuItemClick("logout")}>Log out</li>
  </ul>
);

const UserMenu = ({ user, onMenuItemClick, globalState }) => {
  const TRIGGER =
    user && user.profile_picture ? (
      <Image src={user.profile_picture} title={`${user.first_name} ${user.last_name}`} className="UserMenu__Profile-pic" />
    ) : (
      <Icon name="user circle" title={`${user.first_name} ${user.last_name}`} className="UserMenu__avatar -cursor-pointer-no-color" inverted />
    );

  const openZohoWidget = () => {
    const ZohohcAsapWebLauncherbox = document.getElementById("zohohc-asap-web-launcherbox");
    if (ZohohcAsapWebLauncherbox) ZohohcAsapWebLauncherbox.click();
  };

  return (
    <div className="UserMenu">
      <div id="user-menu-actions-root" className="UserMenu__actions -margin-left-10">
        {/* Content is attached using the <UserMenuActionsPortal/> */}
      </div>

      <Can I="update-request" the="manufacturer">
        {globalState.selectedLocation.manufacturerRequestsCount > 0 && (
          <Link to="/manufacturer" className="UserMenu__actions manufacturer-link">
            <Icon name="industry" className="manufacturer-icon" inverted />
            <Label floating circular color="red">
              {globalState.selectedLocation.manufacturerRequestsCount}
            </Label>
          </Link>
        )}
      </Can>
      <Icon id="pseudo-zoho-icon" name="question" onClick={openZohoWidget} className="pseudo-zoho-icon" />
      <Popup className="UserMenu__popup" trigger={TRIGGER} content={<Content onMenuItemClick={onMenuItemClick} />} on="click" position="bottom right" />
    </div>
  );
};

const mapStateToProps = state => {
  return { globalState: state.global };
};

export default connect(mapStateToProps)(UserMenu);
