import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Form, Label, Dropdown, Checkbox, Button, Modal, Message } from "semantic-ui-react";

class TireDetail extends Component {
  state = {
    missingFields: false,
  };

  handleInputChange = e => {
    let { tire, handleChange } = this.props;
    if (Object.keys(tire).length === 0) return;
    tire[e.target.name] = e.target.value.toUpperCase();
    handleChange(tire);
  };

  handleNumberChange = e => {
    let { tire, handleChange } = this.props;
    if (Object.keys(tire).length === 0) return;
    tire[e.target.name] = Number(e.target.value) ? Number(e.target.value) : "";
    handleChange(tire);
  };

  handleSeasonChange = (e, data) => {
    let { tire, handleChange } = this.props;
    if (Object.keys(tire).length === 0) return;
    tire[data.name] = data.value;
    handleChange(tire);
  };

  handleCheckboxChange = (e, data) => {
    let { tire, handleChange } = this.props;
    if (Object.keys(tire).length === 0) return;
    tire[data.name] = data.checked;
    handleChange(tire);
  };

  validateForm = () => {
    const { manufacturer, description, width, size, speed, height, ean, carry_capacity } = this.props.tire;

    return manufacturer && description && width && size && speed && height && carry_capacity && ean;
  };

  handleSaveTire = () => {
    if (!this.validateForm()) {
      this.setState({ missingFields: true });
    } else {
      this.props.onSave();
    }
  };

  render() {
    const { missingFields } = this.state;
    const { tire, isLoading, isOpen, onClose, mode, errorMessage, t } = this.props;

    if (!tire) return null;

    return (
      <div>
        <Modal open={isOpen} onClose={onClose} closeOnDimmerClick={false}>
          <Modal.Header>{mode === "create" ? t("add_new_tire").message || "Add new tire" : t("edit_tire").message || "Edit tire"}</Modal.Header>
          <Modal.Content>
            <Form>
              <Form.Group widths="equal">
                <Form.Field>
                  <Form.Input
                    required
                    name="manufacturer"
                    label={t("manufacturer").message || "Manufacturer"}
                    onChange={this.handleInputChange}
                    value={tire.manufacturer || ""}
                  />
                  {missingFields && !tire.manufacturer && (
                    <Label basic color="red" pointing>
                      {t("field_is_required").message || "This field is required."}
                    </Label>
                  )}
                </Form.Field>
                <Form.Field>
                  <Form.Input
                    required
                    name="description"
                    label={t("description").message || "Description"}
                    onChange={this.handleInputChange}
                    value={tire.description || ""}
                  />
                  {missingFields && !tire.description && (
                    <Label basic color="red" pointing>
                      {t("field_is_required").message || "This field is required."}
                    </Label>
                  )}
                </Form.Field>
              </Form.Group>

              <Form.Group>
                <Form.Field width={2}>
                  <Form.Input required name="width" label={t("width").message || "Width"} onChange={this.handleNumberChange} value={tire.width || ""} />
                  {missingFields && !tire.width && (
                    <Label basic color="red" pointing>
                      {t("field_is_required").message || "This field is required."}
                    </Label>
                  )}
                </Form.Field>
                <Form.Field width={2}>
                  <Form.Input required={true} name="size" label={t("size").message || "Size"} onChange={this.handleNumberChange} value={tire.size || ""} />
                  {missingFields && !tire.size && (
                    <Label basic color="red" pointing>
                      {t("field_is_required").message || "This field is required."}
                    </Label>
                  )}
                </Form.Field>
                <Form.Field width={2}>
                  <Form.Input required name="speed" label={t("speed").message || "Speed"} onChange={this.handleInputChange} value={tire.speed || ""} />
                  {missingFields && !tire.speed && (
                    <Label basic color="red" pointing>
                      {t("field_is_required").message || "This field is required."}
                    </Label>
                  )}
                </Form.Field>
                <Form.Field width={2}>
                  <Form.Input required name="height" label={t("height").message || "Height"} onChange={this.handleNumberChange} value={tire.height || ""} />
                  {missingFields && !tire.height && (
                    <Label basic color="red" pointing>
                      {t("field_is_required").message || "This field is required."}
                    </Label>
                  )}
                </Form.Field>
                <Form.Field width={6}>
                  <Form.Input required name="ean" label={t("ean").message || "EAN"} onChange={this.handleInputChange} value={tire.ean || ""} />
                  {missingFields && !tire.ean && (
                    <Label basic color="red" pointing>
                      {t("field_is_required").message || "This field is required."}
                    </Label>
                  )}
                </Form.Field>
                <Form.Field width={2}>
                  <Form.Input
                    required
                    name="carry_capacity"
                    label={t("carry_capacity").message || "Carry capacity"}
                    onChange={this.handleNumberChange}
                    value={tire.carry_capacity || ""}
                  />
                  {missingFields && !tire.carry_capacity && (
                    <Label basic color="red" pointing>
                      {t("field_is_required").message || "This field is required."}
                    </Label>
                  )}
                </Form.Field>
              </Form.Group>

              <Form.Group widths="equal">
                <Form.Field>
                  <label>{t("season").message || "Season"} </label>
                  <Dropdown
                    required
                    name="season"
                    selection
                    fluid
                    options={[
                      {
                        text: t("all_seasons").message || "All Seasons",
                        value: 0,
                      },
                      {
                        text: t("winter_season").message || "Winter",
                        value: 1,
                      },
                      {
                        text: t("summer_season").message || "Summer",
                        value: 2,
                      },
                    ]}
                    value={tire.season || 0}
                    onChange={this.handleSeasonChange}
                  />
                </Form.Field>

                <Form.Field>
                  <label>&nbsp;</label>
                  <Checkbox
                    className="Tire__Modal--Checkbox inline"
                    toggle
                    name="run_flat_tire"
                    defaultChecked={tire.run_flat_tire || false}
                    label={t("run_flat_tire").message || "Run flat tire"}
                    onChange={this.handleCheckboxChange}
                  />
                </Form.Field>
              </Form.Group>
              {errorMessage && (
                <Form.Group>
                  <Form.Field width={16}>
                    <Message color="red">{errorMessage}</Message>
                  </Form.Field>
                </Form.Group>
              )}
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button size="large" onClick={onClose} color="grey">
              {t("discard").message || "Discard"}
            </Button>
            <Button size="large" loading={isLoading} onClick={this.handleSaveTire} color="green">
              {mode === "create" ? t("add").message || "Add" : t("save").message || "Save"}
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

export default withTranslation()(TireDetail);
