import React, { Component } from "react";
import Tour from "reactour";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { Button } from "semantic-ui-react";

import "./TourGuide.scss";

const disableBody = target => disableBodyScroll(target);
const enableBody = target => enableBodyScroll(target);

class TourGuide extends Component {
  state = {
    unreadArticles: this.props.unreadArticles,
    error: null,
    isLoading: false,
    currentStep: 1,
    isOpen: false,
  };

  componentDidMount() {
    const { article, unreadArticles } = this.props;

    if (article) {
      this.setState({ isOpen: true, unreadArticles: this.tourConfig([article]), error: null, isLoading: false });
      return;
    } else if (unreadArticles?.length) {
      this.setState({ isOpen: true, unreadArticles: this.tourConfig(this.props.unreadArticles), error: null, isLoading: false });
    }
  }

  closeTour = () => {
    this.setState({ isOpen: false });
  };

  getLocations = item => {
    const { t } = this.props;

    if (item.visible_to_all) return t("all_dealers").message || "All dealers";

    const { dealers } = this.props.globalState;

    const locations = [];

    if (item.location_ids?.length) {
      item.location_ids.forEach(lid => {
        for (let i = 0; i < dealers.length; i++) {
          if (dealers[i].locations?.length) {
            const location = dealers[i].locations.find(l => l.id === lid);
            if (location) {
              locations.push(location.name);
              break;
            }
          }
        }
      });
    }

    if (item.dealer_ids?.length) {
      item.dealer_ids.forEach(did => {
        for (let i = 0; i < dealers.length; i++) {
          if (did === dealers[i].id) {
            locations.push(dealers[i].name);
            break;
          }
        }
      });
    }

    return locations.join(", ");
  };

  tourConfig = data => {
    const { unreadArticles } = this.state;
    const { closeTourGuide, t } = this.props;
    return data
      .sort((a, b) => (a.updated_on > b.updated_on ? -1 : 1))
      .slice(0, 5)
      .map((item, idx) => {
        const { title, content, user, selector, updated_by } = item;

        const extraStyles = {};
        if (selector === ".App") {
          extraStyles.top = "50%";
          extraStyles.left = "50%";
          extraStyles.transform = "translate(-50%, -50%)";
        }

        return {
          selector: selector,
          content: ({ goTo, inDOM }) => {
            return (
              <div className="news-article-container">
                <div id="news-article-inner-container" style={{ maxHeight: "480px", overflowY: "auto", marginBottom: "10px" }}>
                  <h1 className="article-title">{title}</h1>
                  <h4 className="article-subtitle">
                    {this.props.t("To").message || "To"}: {this.getLocations(item)}
                  </h4>
                  <div className="article-content" dangerouslySetInnerHTML={{ __html: content }} />
                  {user && (
                    <div className="article-author-container">
                      <div className="circle" style={{ backgroundImage: `url(${user.profile_picture}) `, backgroundSize: "cover", backgroundPosition: "center" }}></div>
                      <span className="author">
                        {user.first_name} {user.last_name}
                      </span>
                      {updated_by && updated_by.id !== user.id && (
                        <span className="updated-label">
                          {" "}
                          ({t("updated_by").message || "Updated by"}: {updated_by.first_name} {updated_by.last_name})
                        </span>
                      )}
                    </div>
                  )}
                </div>
                <div className="article-footer">
                  {unreadArticles?.length &&
                    this.controlButtons(
                      () => {
                        goTo(idx + 1);
                        this.currentStepIndicator(1);
                      },
                      () => {
                        goTo(idx - 1);
                        this.currentStepIndicator(-1);
                      }
                    )}
                  <div>
                    <Button className="tour-btn read-btn" onClick={closeTourGuide}>
                      {t("close").message || "Close"}
                    </Button>
                  </div>
                </div>
              </div>
            );
          },

          style: {
            backgroundColor: "#FFF",
            minWidth: "675.84px",
            maxWidth: "1140px",
            maxHeight: "560px",
            ...extraStyles,
          },
          stepInteraction: false,
        };
      });
  };

  currentStepIndicator(value) {
    const { unreadArticles } = this.state;
    let currentStep;
    if (value > 0) {
      currentStep = this.state.currentStep + value > unreadArticles?.length ? unreadArticles.length : this.state.currentStep + value;
    } else {
      currentStep = this.state.currentStep + value <= 0 ? 1 : this.state.currentStep + value;
    }
    this.setState({ currentStep }, () => (document.getElementById("news-article-inner-container").scrollTop = 0));
  }

  controlButtons = (next, prev) => {
    const { currentStep, unreadArticles } = this.state;
    const endOfPrevSteps = currentStep <= 1,
      endOfNextSteps = unreadArticles?.length === 1 || currentStep === unreadArticles.length;
    return (
      <div>
        <FontAwesomeIcon onClick={prev} icon={faChevronLeft} className={endOfPrevSteps ? "prev-btn control-btn-disabled" : "prev-btn"} />
        <span className="step-indicator">{`${currentStep} ${this.props.t("of").message || "of"} ${unreadArticles.length}`}</span>
        <FontAwesomeIcon onClick={next} icon={faChevronRight} className={endOfNextSteps ? "next-btn control-btn-disabled" : "next-btn"} />
      </div>
    );
  };

  render() {
    const { unreadArticles, isLoading, isOpen } = this.state;

    if (!isOpen || isLoading || unreadArticles?.length < 1) return null;

    return (
      <Tour
        onRequestClose={this.closeTour}
        steps={unreadArticles}
        isOpen={isOpen}
        className="helper"
        onAfterOpen={disableBody}
        onBeforeClose={enableBody}
        showNumber={false}
        showNavigation={false}
        showButtons={false}
        showCloseButton={false}
        rounded={0}
        closeWithMask={false}
        disableKeyboardNavigation={["esc"]}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    globalState: state.global,
    authState: state.auth,
  };
};

export default withTranslation()(connect(mapStateToProps)(TourGuide));
