import React, { Component } from "react";
import { Button } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";

import { transformCamelCaseToSnakeCase } from "../../../../util/common";

import "./ROBListData.scss";

class ROBListData extends Component {
  state = {
    currentPage: 0,
  };

  handleGetNextPage = () => {
    this.setState(({ currentPage }) => ({ currentPage: ++currentPage }));
  };

  handleGetPreviousPage = () => {
    this.setState(({ currentPage }) => ({ currentPage: --currentPage }));
  };

  render() {
    const { currentPage } = this.state;
    const { title, data, onGetValueForDisplayROBModalSection, t } = this.props;

    if (!data?.length) return null;

    const snakeCaseKey = transformCamelCaseToSnakeCase(title);
    const translatedKey = t(snakeCaseKey).message || snakeCaseKey;

    const dataToRender = Object.entries(data[currentPage]);

    return (
      <div className="ROBListData">
        <div className="section-title">{translatedKey}</div>
        {dataToRender.map(([key, value], idx) => {
          const snakeCaseKey = transformCamelCaseToSnakeCase(key);
          const translatedKey = t(snakeCaseKey).message || snakeCaseKey;
          const valueToDisplay = onGetValueForDisplayROBModalSection(value);

          return (
            <div className="data-row" key={idx}>
              <div>{translatedKey}</div>
              <div>{valueToDisplay}</div>
            </div>
          );
        })}

        {dataToRender.length > 1 && (
          <div className="controls-container">
            <Button disabled={currentPage <= 0} className="controls-box" onClick={this.handleGetPreviousPage}>
              <FontAwesomeIcon icon={faChevronLeft} />
            </Button>
            <Button disabled={currentPage >= data.length - 1} className="controls-box" onClick={this.handleGetNextPage}>
              <FontAwesomeIcon icon={faChevronRight} />
            </Button>
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation()(ROBListData);
