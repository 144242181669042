// @flow

import React from "react";
import { Dropdown } from "semantic-ui-react";

import { ANSWER_STATUSES } from "./../../util/common";

const AnswerStatusSelector = props => {
  let value = props.value;

  if (typeof value === "undefined" || value === "") {
    value = 4;
  }

  const backgroundColor = ANSWER_STATUSES[value] ? ANSWER_STATUSES[value].color : "#fff";

  const trigger = ANSWER_STATUSES[value] ? ANSWER_STATUSES[value].text : null;

  return (
    <Dropdown
      options={props.disabled ? [] : props.options ? props.options : ANSWER_STATUSES}
      className="AppointmentStatusSelector"
      style={{
        backgroundColor,
        color: "white",
        cursor: props.disabled ? "default" : "pointer",
      }}
      value={value}
      trigger={trigger}
      onChange={(e, data) => {
        if (data.value === value) {
          return;
        }
        // eslint-disable-next-line
        props.onChange(parseInt(data.value));
      }}
    />
  );
};

export default AnswerStatusSelector;
