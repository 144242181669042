import React from "react";
import ReactTable, { ReactTableDefaults } from "react-table";
import { withTranslation } from "react-i18next";
import { Icon } from "semantic-ui-react";

import { formatDateIfValid } from "../Appointments/util";

const MetricsTable = props => {
  let { metrics, t, loading } = props;

  if (loading) {
    return (
      <div className="Table__loading Loader-Placeholder">
        <div className="bounce1"></div>
        <div className="bounce2"></div>
        <div className="bounce3"></div>
        <section>{t("loading_user_metrics").message || "Loading User Metrics"}</section>
      </div>
    );
  } else if (!metrics || metrics.length === 0) {
    return (
      <div className="Table__no-results">
        <Icon disabled name="wifi" style={{ fontSize: "1.75em" }} />
        <p>{t("no_metrics").message || "No metrics"}</p>
      </div>
    );
  } else {
    return (
      <div className="UsersTable">
        <ReactTable
          className="ReactTable -floated-table -contained-large"
          data={metrics || []}
          showPagination={false}
          showPageSizeOptions={false}
          sortable={false}
          resizable={false}
          defaultPageSize={metrics.length}
          pageSize={metrics.length}
          noDataText={t("no_metrics").message || "No metrics"}
          getTdProps={(state, rowInfo, column, instance) => {
            return {
              onClick: e => {
                props.onRowClick(column.id, rowInfo);
              },
            };
          }}
          column={{
            ...ReactTableDefaults.column,
            headerClassName: "ReactTable__column-header",
            className: "ReactTable__column",
          }}
          columns={[
            {
              id: "Date",
              Header: t("Date").message || "Date",
              accessor: d => <span>{formatDateIfValid(d.created_on, "D.M.YY | HH:mm:ss")}</span>,
            },
            {
              Header: t("delay_claire").message || "Delay Claire",
              accessor: "delay_claire",
              Cell: data => `${data.value} ms`,
            },
            {
              Header: t("delay_outside").message || "Delay Outside",
              accessor: "delay_outside",
              Cell: data => `${data.value} ms`,
            },
            {
              Header: t("ping_claire").message || "Ping Claire",
              accessor: "ping_claire",
              Cell: data => `${data.value} ms`,
            },
            {
              Header: t("ping_outside").message || "Ping Outside",
              accessor: "ping_outside",
              Cell: data => `${data.value} ms`,
            },
            {
              Header: t("wifi_name").message || "Wi-Fi Name",
              accessor: "wifi_name",
            },
            {
              Header: t("wifi_strength").message || "Wi-Fi Strength",
              accessor: "wifi_strength",
              Cell: data => `${data.value} dBm`,
            },
          ]}
        />
      </div>
    );
  }
};

export default withTranslation()(MetricsTable);
