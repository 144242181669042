import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Button, Form } from "semantic-ui-react";
import { randomString } from "../../util/common";

import { UserInput } from "../../components";

class IcarSettings extends Component {
  handleInputChange = e => {
    const { dealer, onUpdateDealer } = this.props;
    if (Object.keys(dealer).length === 0) return;

    onUpdateDealer({ ...dealer, [e.target.name]: e.target.value });
  };

  handleGenerateKey = () => {
    const { dealer, onUpdateDealer } = this.props;

    onUpdateDealer({ ...dealer, icar_key: randomString(36) });
  };

  render() {
    const { dealer, t } = this.props;

    return (
      <div>
        <Form>
          {/* These fields are given by ICAR, no need to translate */}
          <Form.Field className="full-width-input">
            <label>ICARKey</label>
            <UserInput
              placeholder="ICARKey"
              name="icar_key"
              disabled={!!dealer.icar_key}
              value={dealer.icar_key || ""}
              label={
                !dealer.icar_key && (
                  <Button onClick={this.handleGenerateKey} primary>
                    {t("generate").message || "Generate"}
                  </Button>
                )
              }
            />
          </Form.Field>

          <Form.Field className="full-width-input">
            <label>ICAREmp</label>
            <UserInput placeholder="ICAREmp" name="icar_emp" onChange={this.handleInputChange} defaultValue={dealer.icar_emp ? dealer.icar_emp : ""} />
          </Form.Field>
        </Form>
      </div>
    );
  }
}

export default withTranslation()(IcarSettings);
