import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Form } from "semantic-ui-react";

import { UserInput } from "../../../components";

class Datepicker extends Component {
  constructor(props) {
    super(props);

    let label = "";

    const { default_configuration } = this.props;

    if (default_configuration) {
      label = default_configuration.label || "";
    }

    this.state = {
      label,
    };
  }

  handleLabelInput = e => {
    this.setState({ label: e.target.value }, () => {
      this.props.handleQuestionElementUpdate(this.state);
    });
  };

  render() {
    return (
      <div>
        <Form.Field>
          <UserInput label={this.props.t("label").message || "Label"} value={this.state.label} onChange={this.handleLabelInput} />
        </Form.Field>
      </div>
    );
  }
}

export default withTranslation()(Datepicker);
