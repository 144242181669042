import React, { Component } from "react";
import ReactTable, { ReactTableDefaults } from "react-table";
import { Button, Dropdown, Grid, Icon } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import AceEditor from "react-ace";
import moment from "moment";

import { setAlert } from "../../App/store";
import { SubHeader, UserInput } from "../../../components";
import { addQueryToHistory, DMS_ACTION_TYPES, getQueryHistory } from "../store";
import { setWebSocketLocationKey } from "../../WebSocket/store";
import { DMS, QUERY_STATUS, getDMSName } from "../util";

import Service from "./service";

import "brace/mode/mysql";
import "brace/theme/dracula";
import "brace/ext/language_tools";

import "./index.css";

const defaultQuery = {
  id: null,
  name: "",
  statement: "",
  status: 0,
  result: "",
  estimated_execution: null,
  executed_at: null,
  user: {
    first_name: "",
    last_name: "",
  },
  created_on: null,
  updated_on: null,
};

class CustomQueriesPage extends Component {
  state = {
    query: defaultQuery,
    isLoadingQuery: false,
    dmsLocationOptions: [],
    dmsLocationSelected: null,
    selectedQueryID: null,
  };

  initialLoad = true;

  componentDidMount() {
    const { globalState } = this.props;

    const dmsLocationOptions = [];

    globalState.dealers.forEach(dealer => {
      if (dealer.locations)
        dealer.locations.forEach(location => {
          if (location.dms_id > DMS.UNKNOWN)
            dmsLocationOptions.push({
              key: location.id,
              value: location.id,
              text: `${location.name} - ${getDMSName(location.dms_id)} - ${dealer.name}`,
              notifier_key: location.notifier_key,
            });
        });
    });

    const dmsLocationSelected = dmsLocationOptions.find(l => l.key === globalState.selectedLocation.id)?.key || dmsLocationOptions[0].key;

    this.setState({ dmsLocationOptions, dmsLocationSelected }, () => {
      this.props.getQueryHistory(this.state.dmsLocationSelected);

      this.initialLoad = false;
    });
  }

  componentDidUpdate(prevProps) {
    const { actionType, errorMessage, query } = this.props.dmsState;

    if (prevProps.dmsState.actionType !== actionType) {
      if (actionType === DMS_ACTION_TYPES.WEB_SOCKET_UPDATE && this.state.query.id === query.id) this.setState({ query });

      if (actionType === DMS_ACTION_TYPES.GET_QUERY_HISTORY_FAIL) this.props.setAlert({ type: "error", title: errorMessage });
    }
  }

  componentWillUnmount() {
    this.props.setWebSocketLocationKey(this.props.globalState.selectedLocation.notifier_key);
  }

  getStatusName = status => {
    const { t } = this.props;

    switch (status) {
      case QUERY_STATUS.ERROR:
        return t("error").message || "Error";
      case QUERY_STATUS.EXECUTED:
        return t("executed").message || "Executed";
      case QUERY_STATUS.PENDING:
        return t("pending").message || "Pending";
      default:
        return "";
    }
  };

  handleRunQuery = () => {
    const {
      authState: { user },
    } = this.props;
    const { query, dmsLocationSelected: dealer_location_id } = this.state;

    this.setState({ isLoadingQuery: true }, () => {
      Service.submitQuery({ query: query.statement, dealer_location_id, name: query.name })
        .then(res => {
          const query = res?.data?.data ? { ...res.data.data, user: { first_name: user.first_name, last_name: user.last_name } } : null;

          this.setState({ query, isLoadingQuery: false }, () => {
            if (query) this.props.addQueryToHistory(query);
          });
        })
        .catch(err => {
          this.setState({ isLoadingQuery: false }, () => {
            this.props.setAlert({ type: "error", title: err?.response?.data?.errors[0] || "Failed to run the query" });
            console.error(err, "Failed to run the query");
          });
        });
    });
  };

  handleQueryChange = statement => this.setState(state => ({ query: { ...state.query, statement, id: null, status: 0 } }));

  handleInputChange = (_e, data) => this.setState(state => ({ query: { ...state.query, [data.name]: data.value, id: null, status: 0 } }));

  handleLocationChange = (_e, selection) =>
    this.setState({ dmsLocationSelected: selection.value }, () => {
      this.props.getQueryHistory(this.state.dmsLocationSelected, 1);
      const notifier_key = selection.options.find(o => o.value === selection.value)?.notifier_key;
      if (notifier_key) this.props.setWebSocketLocationKey(notifier_key);
    });

  renderHistoryTable = () => {
    const {
      dmsState: { queryHistory, page, nb_pages, nb_queries, isLoading },
      getQueryHistory,
      t,
    } = this.props;

    const { dmsLocationSelected } = this.state;

    return (
      <div className="QueriesTableContainer">
        <div className="query-table-title">
          <h3>
            {t("query_history").message || "Query history"} {nb_queries > 0 ? `(${nb_queries})` : ""}
          </h3>
        </div>
        <ReactTable
          manual
          data={queryHistory || []}
          showPagination={nb_pages > 1}
          page={page - 1}
          pages={nb_pages === null ? -1 : nb_pages}
          className="QueriesTable -floated-table"
          renderPageJump={({ onChange, onBlur, onKeyPress, inputType, pageJumpText }) => (
            <div className="-pageJump">
              <UserInput
                aria-label={pageJumpText}
                type={inputType}
                onChange={evt => {
                  onChange(evt);

                  const newPage = evt.target.value - 0;
                  if (!Number.isNaN(newPage) && newPage > 0 && newPage <= nb_pages) getQueryHistory(dmsLocationSelected, newPage);
                }}
                value={page}
                onBlur={onBlur}
                onKeyPress={onKeyPress}
              />
            </div>
          )}
          getTrProps={(_state, rowInfo) => ({
            onClick: () => this.setState({ query: rowInfo.original }),
          })}
          getTdProps={(_, rowInfo) => ({
            onClick: () => {
              this.setState({ selectedQueryID: rowInfo.original.id });
            },
            style: {
              background: this.state.selectedQueryID === rowInfo.original.id ? "#ededed" : "#fff",
            },
          })}
          loading={isLoading}
          onFetchData={state => !this.initialLoad && getQueryHistory(dmsLocationSelected, state.page + 1)}
          showPageSizeOptions={false}
          sortable={false}
          minRows={0}
          loadingText={t("loading_query_history").message || "Loading query history"}
          nextText={t("next").message || "Next"}
          previousText={t("previous").message || "Previous"}
          pageText={t("page").message || "Page"}
          ofText={t("of").message || "of"}
          noDataText={
            <div className="Table__no-results">
              <Icon disabled name="database" style={{ fontSize: "1.25em" }} />
              <p>{t("no_query_history").message || "No previous queries"}</p>
            </div>
          }
          onPageChange={() => {
            try {
              document.querySelector(".App__module").scrollTo(0, 0);
            } catch (e) {
              document.querySelector(".App__module").scrollTop = 0; // IE Fix
            }
          }}
          column={{
            ...ReactTableDefaults.column,
            headerClassName: "ReactTable__column-header -text-ellipsis",
            className: "ReactTable__column",
          }}
          columns={[
            {
              id: "query_id",
              Header: t("ID").message || "ID",
              accessor: "id",
              minWidth: 50,
            },
            {
              id: "query_name",
              Header: t("query_name").message || "Query name",
              accessor: "name",
              minWidth: 90,
            },
            {
              id: "query",
              Header: t("query").message || "Query",
              accessor: "statement",
              minWidth: 160,
            },
            {
              id: "status",
              Header: t("status").message || "Status",
              accessor: d => this.getStatusName(d.status),
              width: 80,
            },
            {
              id: "executed_at",
              Header: t("executed_at").message || "Executed at",
              accessor: d => (d.status !== QUERY_STATUS.PENDING ? moment(d.executed_at).format("YYYY-MM-DD HH:mm") : ""),
            },
            {
              id: "execution_duration",
              Header: t("duration").message || "Duration",
              accessor: "execution_duration",
            },
            {
              id: "author",
              Header: t("author").message || "Author",
              accessor: d => `${d.user.first_name} ${d.user.last_name}`.trim(),
            },
          ]}
        />
      </div>
    );
  };

  autoColumnWidth = (rows, accessor, headerText) => {
    const maxWidth = 400;
    const cellLength = Math.max(...rows.map(row => (`${row[accessor]}` || "").length), headerText.length);
    const cellSpacing = cellLength < 10 ? cellLength * 15 : cellLength * 10;
    return Math.min(maxWidth, cellSpacing);
  };

  renderResultTable = result => {
    if (!result) return;

    let tableData = [];

    try {
      tableData = JSON.parse(result);
    } catch (err) {
      console.error(err, "Error parsing the result");
    }

    return (
      <ReactTable
        data={tableData || []}
        className="QueryResultsTable -floated-table"
        showPagination={false}
        loading={this.state.isLoadingQuery}
        showPageSizeOptions={false}
        minRows={0}
        pageSize={tableData.length}
        loadingText={this.props.t("loading_query_response").message || "Loading query response"}
        noDataText={
          <div className="Table__no-results">
            <Icon disabled name="database" style={{ fontSize: "1.25em" }} />
            <p>{this.props.t("no_results").message || "No results"}</p>
          </div>
        }
        column={{
          ...ReactTableDefaults.column,
          headerClassName: "ReactTable__column-header -text-ellipsis",
          className: "ReactTable__column",
        }}
        columns={
          tableData.length
            ? Object.keys(tableData[0]).map((r, idx) => ({
                id: r || `id-${idx}`,
                Header: r || '""',
                accessor: d => (typeof d[r] === "string" ? `"${d[r]}"` : d[r] === null ? "NULL" : d[r]?.toString()),
                width: this.autoColumnWidth(tableData, r, r),
              }))
            : []
        }
      />
    );
  };

  renderResult = () => {
    const { query } = this.state;
    const { t } = this.props;

    switch (query.status) {
      default:
        return <p>{t("submit_query").message || "Submit a query or select on it in the query history"}</p>;
      case QUERY_STATUS.PENDING:
        return (
          <p>
            {t("query_will_run").message || "Query will run at approximately"}: {moment(query.estimated_execution).format("YYYY-MM-DD HH:mm")}
          </p>
        );
      case QUERY_STATUS.EXECUTED:
        return (
          <div className="query-result-table-container">
            <div className="query-result-header">{`${query.id} - ${query.name}`}</div>
            {this.renderResultTable(query.result)}
          </div>
        );
      case QUERY_STATUS.ERROR:
        return (
          <p>
            {t("query_failed_to_run").message || "Query failed to run"}: {query.result}
          </p>
        );
    }
  };

  render() {
    const { query, dmsLocationOptions, dmsLocationSelected } = this.state;
    const { t } = this.props;

    return (
      <div className="CustomQueriesPage">
        <SubHeader>
          <Grid.Column>
            <h1>{t("custom_queries_page").message || "DMS Custom queries"}</h1>
          </Grid.Column>
        </SubHeader>

        <Grid className="CustomQueriesContainer">
          <Grid.Row>
            <Grid.Column width={8}>
              <label htmlFor="dms_location">{t("select_location").message || "Select a location"}</label>
              <Dropdown
                fluid
                selection
                search
                selectOnBlur={false}
                name="dms_location"
                onChange={this.handleLocationChange}
                options={dmsLocationOptions}
                value={dmsLocationSelected}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={8}>
              <div className="editor-table-wrapper">
                <UserInput
                  fluid
                  name="name"
                  className="query-name-input"
                  value={query.name}
                  placeholder={t("enter_query_name").message || "Enter query name"}
                  onChange={this.handleInputChange}
                />

                <AceEditor
                  mode="mysql"
                  name="editor"
                  theme="dracula"
                  width="100%"
                  height="35vh"
                  wrapEnabled
                  fontSize={16}
                  showGutter={false}
                  showPrintMargin={false}
                  value={query.statement}
                  highlightActiveLine={false}
                  setOptions={{ enableBasicAutocompletion: true, enableLiveAutocompletion: true, useSoftTabs: true }}
                  onChange={this.handleQueryChange}
                />

                <Button fluid color="green" onClick={this.handleRunQuery} disabled={!query.statement || !query.name || !dmsLocationSelected}>
                  {t("run_query").message || "Run Query"}
                </Button>
              </div>
            </Grid.Column>

            <Grid.Column width={8}>{this.renderHistoryTable()}</Grid.Column>
          </Grid.Row>

          <Grid.Row className="results-wrapper-row">
            <Grid.Column width={16}>
              <div className="QueriesResultContainer">
                <div className="query-result">{this.renderResult()}</div>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    globalState: state.global,
    dmsState: state.dms,
    authState: state.auth,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setWebSocketLocationKey: location_key => dispatch(setWebSocketLocationKey(location_key)),
    getQueryHistory: (locationId, page) => dispatch(getQueryHistory(locationId, page)),
    addQueryToHistory: query => dispatch(addQueryToHistory(query)),
    setAlert: alertOptions => dispatch(setAlert(alertOptions)),
  };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(CustomQueriesPage));
