import Print from "./print";

export default {
  print: (params, printFrame) => {
    params.printableElement = document.createElement("div");
    params.printableElement.setAttribute("style", "width:100%");

    params.printableElement.innerHTML = params.printable;

    Print.send(params, printFrame);
  },
};
