import React, { Component } from "react";

import "./FloatingActions.css";
import { Icon } from "semantic-ui-react";

class FloatingActions extends Component {
  state = { actions_visible: false };

  handleMain = () => {
    let { actions_visible } = this.state;
    let { items, onRootClick } = this.props;
    if (items && items.length > 0) {
      this.setState({ actions_visible: !actions_visible });
      return;
    }

    if (onRootClick) onRootClick();
  };

  handleAction = id => {
    this.setState({ actions_visible: false });
    let { onRootClick } = this.props;

    if (onRootClick && id) onRootClick(id);
  };

  render() {
    let { items, label } = this.props;
    let { actions_visible } = this.state;
    let actions = [];

    if (items && items.length > 0) {
      actions = items.map(item => {
        return (
          <div
            key={item.id}
            label={item.label}
            className="action"
            onClick={() => {
              this.handleAction(item.id);
            }}
          >
            <Icon className="-cursor-pointer-no-color" name={item.icon} size="mini" />
          </div>
        );
      });
    }

    return (
      <div className="FloatingActions">
        <div style={{ display: actions_visible ? "block" : "none" }}>{actions}</div>
        <div className="main" onClick={this.handleMain}>
          {label || "+"}
        </div>
      </div>
    );
  }
}

export default FloatingActions;
