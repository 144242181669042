import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Modal, Button, Icon } from "semantic-ui-react";
import ReactTable, { ReactTableDefaults } from "react-table";
import moment from "moment";

import { StatusCell } from "../../components";

import { APPOINTMENT_STATUSES } from "../Appointments/common";
import { updateAppointmentStatusIdentifier } from "../Appointments/util";
import { Can } from "../";

import ACTIONS from "./actions";
import Context from "./context";

const DETAIL_MODAL_ACTIONS = [
  ACTIONS.DETAILS_MODAL_SHOW,
  ACTIONS.GET_ADMIN_PIN,
  ACTIONS.ACTIVATE_BOX,
  ACTIONS.DEACTIVATE_BOX,
  ACTIONS.CONFIRM_GET_ADMIN_PIN,
  ACTIONS.CONFIRM_EMPTY_BOX,
  ACTIONS.EMPTY_BOX,
  ACTIONS.ADMIN_PIN_MODAL_SHOW,
];
const FINISH_STATUSES = [APPOINTMENT_STATUSES.CANCELED, APPOINTMENT_STATUSES.CAR_READY, APPOINTMENT_STATUSES.QUALITY_CHECK];

class KeyLockerBoxesModal extends Component {
  static contextType = Context;

  close = () => this.context.controller.setAction(null);

  render() {
    const { t, globalState } = this.props;
    const { controller, keyLockers, selectedKeyLockerIndex, selectedBoxIndex, keyLockerBoxes, action, isLoading } = this.context;
    const { setAction, deactivateBox, activateBox } = controller;

    const keyLocker = keyLockers[selectedKeyLockerIndex] || {};
    const isOpen = DETAIL_MODAL_ACTIONS.includes(action);

    return (
      <Modal className="KeyLockerBoxesModal" open={isOpen} onClose={this.close} closeOnDimmerClick={false} size="large">
        <Modal.Header>
          {keyLocker.name}
          <Button floated="right" negative disabled={isLoading} content={t("close").message || "Close"} onClick={this.close} />
        </Modal.Header>

        <Modal.Content scrolling>
          <ReactTable
            className="ReactTable -floated-table -contained-large"
            data={keyLockerBoxes}
            showPagination={false}
            showPageSizeOptions={false}
            sortable={false}
            resizable={false}
            defaultPageSize={keyLockerBoxes.length}
            pageSize={keyLockerBoxes.length}
            column={{
              ...ReactTableDefaults.column,
              headerClassName: "ReactTable__column-header",
              className: "ReactTable__column",
            }}
            columns={[
              {
                id: "index",
                Header: t("box_nr").message || "Box #",
                Cell: row => {
                  return (
                    <div className="key-lockers-table-index" style={{ backgroundColor: row.original.active ? "green" : "orange" }}>
                      {row.original.box_number}
                    </div>
                  );
                },
                maxWidth: 85,
              },
              {
                id: "used",
                Header: t("key").message || "Key",
                className: "key-locker-box",
                width: 60,
                Cell: row => (
                  <Icon.Group>
                    <Icon name="square outline" size="big" />
                    <Icon name={row.original.is_empty ? "close" : "key"} />
                  </Icon.Group>
                ),
              },
              {
                id: "appointment_status_identifier",
                Header: t("status").message || "Status",
                width: 65,
                className: "keylocker-box-status-column",
                Cell: row => {
                  if (!row.original.appointment) return null;

                  updateAppointmentStatusIdentifier(row.original.appointment);

                  return (
                    <StatusCell
                      value={{ id: row.original.appointment.appointment_status_identifier, customcom_status: row.original.appointment.customcom_status }}
                      statuses={globalState.selectedLocation.statuses}
                    />
                  );
                },
              },
              {
                id: "spacer",
                width: 30,
              },
              {
                id: "wo_nr",
                Header: t("wo_nr").message || "WO",
                width: 100,
                Cell: row => row.original.appointment?.wo_nr || "",
              },
              {
                id: "time_car_app",
                Header: t("appointment_date").message || "Appointment Date",
                width: 135,
                Cell: row => (row.original.appointment ? moment(row.original.appointment.time_car_app).format("DD-MM-YYYY") : ""),
              },
              {
                id: "driver_name",
                Header: t("driver_name").message || "Driver Name",
                width: 200,
                Cell: row => (row.original.appointment ? row.original.appointment.driver_firstname + " " + row.original.appointment.driver_surname : ""),
              },
              {
                id: "owner_name",
                Header: t("owner_name").message || "Owner Name",
                width: 200,
                Cell: row => (row.original.appointment ? row.original.appointment.owner_firstname + " " + row.original.appointment.owner_surname : ""),
              },
              {
                id: "actions",
                Cell: row => {
                  const isActiveRow = selectedBoxIndex === row.index;
                  const box = row.original;

                  return (
                    <div className="key-locker-actions-container">
                      <Can I="get_admin_pin" the="keylockers">
                        <Button
                          size="mini"
                          icon="lock open"
                          color="blue"
                          disabled={isLoading}
                          loading={isLoading && action === ACTIONS.GET_ADMIN_PIN}
                          onClick={() => setAction(ACTIONS.CONFIRM_GET_ADMIN_PIN, selectedKeyLockerIndex, row.index)}
                        />
                      </Can>
                      {box.active && (
                        <Can I="deactivate_box" the="keylockers">
                          <Button
                            size="mini"
                            icon="pause"
                            color="orange"
                            disabled={isLoading || !box.is_empty}
                            loading={isLoading && action === ACTIONS.DEACTIVATE_BOX && isActiveRow}
                            onClick={() => deactivateBox(row.index)}
                          />
                        </Can>
                      )}
                      {!box.active && (
                        <Can I="activate_box" the="keylockers">
                          <Button
                            size="mini"
                            icon="play"
                            color="green"
                            disabled={isLoading}
                            loading={isLoading && action === ACTIONS.ACTIVATE_BOX && isActiveRow}
                            onClick={() => activateBox(row.index)}
                          />
                        </Can>
                      )}
                      <Can I="empty_box" the="keylockers">
                        <Button
                          size="mini"
                          icon="close"
                          color="red"
                          disabled={isLoading || box.is_empty || !FINISH_STATUSES.includes(box.appointment?.appointment_status_identifier)}
                          loading={isLoading && action === ACTIONS.EMPTY_BOX && isActiveRow}
                          onClick={() => setAction(ACTIONS.CONFIRM_EMPTY_BOX, selectedKeyLockerIndex, row.index)}
                        />
                      </Can>
                    </div>
                  );
                },
              },
            ]}
          />
        </Modal.Content>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  return { globalState: state.global };
};

export default withTranslation()(connect(mapStateToProps)(KeyLockerBoxesModal));
