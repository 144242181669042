import React from "react";
import { Form } from "semantic-ui-react";

import { UserInput } from "../../../components";

const QuestionElementTextfield = ({ id, configuration, configurationChanged, defaultVisible, numberOnly }) => {
  return (
    <div>
      <Form.Field>
        <UserInput key={"label" + id} name="label" onChange={configurationChanged} defaultValue={configuration.label || ""} placeholder="Label" />
      </Form.Field>
      <Form.Field>
        <UserInput key={"value" + id} name="default_value" onChange={configurationChanged} defaultValue={configuration.default_value || ""} placeholder="Label" />
      </Form.Field>
    </div>
  );
};

export default QuestionElementTextfield;
