import React, { Component, createRef } from "react";
import { withTranslation } from "react-i18next";
import { Button, Checkbox, Dropdown, Form, Icon, Label, Modal } from "semantic-ui-react";
import DatePicker from "react-datepicker";
import AlertContainer from "react-alert";
import { saveAs } from "file-saver";
import ReactQuill from "react-quill";

import { CustomConfirm, UserInput } from "../../components";
import { DOCUMENTATION_CATEGORIES } from ".";
import { faImage, faTrash } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DealerLocationsDropdown from "../../components/DealerLocationsDropdown";
import Can from "../Can";

import Service from "./service";

class DocumentationModal extends Component {
  state = {
    documentation: this.props.documentation,
    showDeleteConfirmation: false,
    deleteError: this.props.deleteError,
  };

  fileInputRef = createRef();
  msg = createRef();

  componentDidUpdate(prevProps) {
    if (this.props.modalErrorMsg && this.props.modalErrorMsg !== prevProps.modalErrorMsg) this.msg.show(this.props.modalErrorMsg, { type: "error" });

    if (this.props.deleteError && this.props.deleteError !== prevProps.deleteError) this.setState({ deleteError: this.props.deleteError });
  }

  handleChange = (_e, data) => this.setState(state => ({ documentation: { ...state.documentation, [data.name]: data.value } }));

  handleChangeSelectedLocationDealerIDs = selected => {
    this.setState({
      documentation: {
        ...this.state.documentation,
        visible_to_all: selected.isAllSelected,
        dealer_location_ids: selected.location_ids,
        dealer_ids: selected.dealer_ids,
      },
    });
  };

  handleChangeDate = date => {
    this.setState(state => ({ documentation: { ...state.documentation, publish_date: date ? moment(date).format() : null } }));
  };

  handleTogglePin = () => {
    this.setState(state => ({ documentation: { ...state.documentation, is_pinned: !state.documentation.is_pinned } }));
  };

  handleCheckboxChange = (_e, data) => {
    this.setState(state => ({ documentation: { ...state.documentation, [data.name]: data.checked } }));
  };

  handleContentChange = content => this.setState(state => ({ documentation: { ...state.documentation, content } }));

  getCategoryName = category_id => {
    const category = Object.entries(DOCUMENTATION_CATEGORIES).find(([, v]) => v === category_id);
    const categoryName = category[0].toLowerCase();
    return categoryName ? this.props.t(categoryName).message || categoryName : null;
  };

  showDeleteConfirmationModal = () => {
    this.setState({ showDeleteConfirmation: true, deleteError: "" });
  };

  handleCancelDelete = () => {
    this.setState({ showDeleteConfirmation: false, deleteError: "" });
  };

  handleDragOver = e => {
    e.preventDefault();
    this.setState({ fileDraggedOver: true });
  };

  handleDragLeave = e => {
    e.preventDefault();
    this.setState({ fileDraggedOver: false });
  };

  handleDropFiles = e => {
    e.preventDefault();

    this.onUploadFile(e.dataTransfer.files[0]);

    this.setState({ fileDraggedOver: false });
  };

  handleBrowseFiles = () => {
    this.fileInputRef.current.click();
  };

  handleFileChange = e => {
    this.onUploadFile(e.target.files[0]);
  };

  handleDeleteFile = () => {
    this.setState(state => ({ documentation: { ...state.documentation, url: null } }));
  };

  onUploadFile = file => {
    const extension = file.name.lastIndexOf(".") > -1 ? file.name.slice(file.name.lastIndexOf(".") + 1) : "unknown";
    const uploadFile = new File([file], file.name, { type: file.type || extension });
    const formData = new FormData();

    formData.append("file", uploadFile);

    Service.uploadFile(formData)
      .then(res => {
        if (res?.data?.data?.url) {
          this.setState(state => ({ documentation: { ...state.documentation, url: res.data.data.url } }));
        } else throw new Error("Error uploading file(s)");
      })
      .catch(error => {
        console.error(error);
      });
  };

  handleSubmit = () => {
    const { documentation } = this.state;

    documentation.id > 0 ? this.props.onUpdateDocumentation(documentation) : this.props.onAddDocumentation(documentation);
  };

  getUrlFilename = url => {
    const baseUrl = url.split("?")[0];
    return decodeURIComponent(baseUrl).split("/").pop();
  };

  renderAlert = () => {
    const props = {
      offset: 20,
      position: "top center",
      theme: "light",
      transition: "fade",
    };

    return <AlertContainer ref={a => (this.msg = a)} {...props} />;
  };

  renderAttachments = () => {
    const { fileDraggedOver, documentation } = this.state;
    const { t } = this.props;

    const dragFileProps =
      !documentation.url && !documentation.read_only
        ? {
            onDragLeave: this.handleDragLeave,
            onDragOver: this.handleDragOver,
            onDrop: this.handleDropFiles,
          }
        : null;

    return (
      <div className="DocumentationAttachmentsContainer">
        <div className={`DragAndDropBox ${fileDraggedOver ? "-dragged-over" : ""}`} {...dragFileProps}>
          <>
            {documentation.url ? (
              <span>{t("file_uploaded").message || "File uploaded"}</span>
            ) : (
              <>
                <span>
                  {t("drag_here_or").message || "Drag here or"}{" "}
                  <span className="browse-files" onClick={this.handleBrowseFiles}>
                    {t("browse").message || "Browse"}
                  </span>
                  <input type="file" ref={this.fileInputRef} hidden onChange={this.handleFileChange} />
                </span>
                <div className="description">{t("upload_max_file_size").message || "Maximum file size: 32MB"}</div>
              </>
            )}
          </>
        </div>

        <div className="FileList">
          {documentation.url && (
            <div className="FileRow">
              <div className="FileTitle">
                <FontAwesomeIcon icon={faImage} />
                <a
                  href={documentation.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={e => {
                    e.preventDefault();
                    saveAs(documentation.url, this.getUrlFilename(documentation.url));
                  }}
                >
                  {this.getUrlFilename(documentation.url)}
                </a>
              </div>

              <div className="FileInfo">{<FontAwesomeIcon icon={faTrash} onClick={() => this.handleDeleteFile()} />}</div>
            </div>
          )}
        </div>
      </div>
    );
  };

  render() {
    const { documentation, showDeleteConfirmation, deleteError } = this.state;
    const { onClose, categoryOptions, validationError, isSavingDocumentation, isDeletingDocumentation, user, t } = this.props;

    return (
      <>
        <Modal className="DocumentationModal" open onClose={onClose} closeOnDimmerClick={false} size="large">
          <Modal.Header>
            {!documentation.read_only &&
              (documentation.id > 0 ? t("edit_documentation").message || "Edit Documentation" : t("add_documentation").message || "Add Documentation")}

            {documentation.read_only && (
              <div className="read-only-header">
                <span>{documentation.title} </span>
                <span>{documentation.is_pinned && <Icon name="pin" />} </span>
                <Label basic>{this.getCategoryName(documentation.category_id)}</Label>

                <Button color="green" onClick={onClose}>
                  {t("close").message || "Close"}
                </Button>
              </div>
            )}
          </Modal.Header>

          <Modal.Content scrolling>
            {!documentation.read_only && (
              <Form>
                <Form.Field width={16} required error={validationError && !documentation.title}>
                  <UserInput name="title" onChange={this.handleChange} placeholder={t("title").message || "Title"} value={documentation.title} />
                </Form.Field>

                <Form.Group>
                  <Form.Field required error={validationError && !documentation.category_id} width={4}>
                    <Dropdown
                      selection
                      clearable
                      selectOnBlur={false}
                      options={categoryOptions}
                      name="category_id"
                      onChange={this.handleChange}
                      placeholder={t("category").message || "Category"}
                      value={documentation.category_id}
                    />
                  </Form.Field>

                  <Form.Field
                    width={4}
                    error={validationError && !documentation.dealer_location_ids?.length && !documentation.dealer_ids?.length && !documentation.visible_to_all}
                  >
                    <DealerLocationsDropdown
                      options={{ isAllSelected: documentation.visible_to_all, location_ids: documentation.dealer_location_ids, dealer_ids: documentation.dealer_ids }}
                      selectAll
                      selectDealers
                      onChange={this.handleChangeSelectedLocationDealerIDs}
                    />
                  </Form.Field>

                  <Form.Field width={3}>
                    <DatePicker
                      isClearable
                      showYearDropdown
                      showMonthDropdown
                      dateFormat="dd-MM-yyyy"
                      onChangeRaw={e => e.preventDefault()}
                      onChange={this.handleChangeDate}
                      selected={documentation.publish_date ? moment(documentation.publish_date).toDate() : null}
                      placeholderText={t("publish_date").message || "Publish date"}
                    />
                  </Form.Field>

                  <Form.Field width={1}>
                    <div className="PinCheckbox" onClick={this.handleTogglePin}>
                      {documentation.is_pinned ? <Icon name="pin" /> : <Icon name="circle outline" />}
                      {t("pin").message || "Pin"}
                    </div>
                  </Form.Field>

                  <Form.Field width={4}>
                    <Checkbox
                      toggle
                      label={t("visible_to_mechanic").message || "Visible to mechanic"}
                      checked={documentation.visible_to_mechanic}
                      name="visible_to_mechanic"
                      onChange={this.handleCheckboxChange}
                    />
                  </Form.Field>
                </Form.Group>

                <Form.Field width={16}>
                  <ReactQuill
                    modules={{ toolbar: ["bold", "italic", "underline", "link", "image", "video"] }}
                    value={documentation.content}
                    onChange={this.handleContentChange}
                    className={`DocumentationQuill ${validationError && !documentation.content ? "QuillError" : ""}`}
                  />
                </Form.Field>

                <Form.Field width={16}>{this.renderAttachments()}</Form.Field>
              </Form>
            )}

            {documentation.read_only && (
              <div className="UserDetails">
                <div className="User">
                  {documentation.created_by.profile_picture ? (
                    <img src={documentation.created_by.profile_picture} alt="profilepicture" />
                  ) : (
                    <div className="profile-picture-placeholder">{`${documentation.created_by.first_name?.charAt(0) || ""} ${
                      documentation.created_by.last_name?.charAt(0) || ""
                    } `}</div>
                  )}

                  <span>
                    {`${documentation.created_by.first_name} ${documentation.created_by.last_name} `}

                    {documentation.updated_by &&
                      `(${t("updated_on").message || "Updated on"} ${moment(documentation.updated_on).format("DD-MM-YYYY HH:mm")} ${t("by").message || "by"} ${
                        documentation.updated_by.first_name
                      } ${documentation.updated_by.last_name})`}
                  </span>
                  <span>{`${moment(documentation.publish_date).format("DD-MM-YYYY")}`}</span>
                </div>
                {documentation.url && (
                  <div className="DocumentationFile">
                    <FontAwesomeIcon icon={faImage} />
                    <a href={documentation.url} target="_blank" rel="noopener noreferrer">
                      {this.getUrlFilename(documentation.url)}
                    </a>
                  </div>
                )}

                <div className="DocumentationContent" dangerouslySetInnerHTML={{ __html: documentation.content }} />
              </div>
            )}
          </Modal.Content>
          {!documentation.read_only && (
            <Modal.Actions>
              {documentation.id > 0 && (user.role_id < documentation.created_by.role_id || user.id === documentation.created_by.id) && (
                <Can I="delete" the="dealer-documentation">
                  <Button
                    color="red"
                    floated="left"
                    onClick={this.showDeleteConfirmationModal}
                    disabled={isSavingDocumentation || isDeletingDocumentation}
                    loading={isDeletingDocumentation}
                  >
                    <Icon name="trash" /> {t("delete").message || "Delete"}
                  </Button>
                </Can>
              )}

              <Button color="grey" onClick={this.props.onClose}>
                <Icon name="close" />
                {t("cancel").message || "Cancel"}
              </Button>

              <Can I={["create", "update"]} the="dealer-documentation">
                <Button color="green" onClick={this.handleSubmit} disabled={isSavingDocumentation || isDeletingDocumentation} loading={isSavingDocumentation}>
                  <Icon name="checkmark" /> {t("save").message || "Save"}
                </Button>
              </Can>
            </Modal.Actions>
          )}

          {this.renderAlert()}
        </Modal>

        <CustomConfirm
          type="danger"
          isLoading={isDeletingDocumentation}
          isOpen={showDeleteConfirmation}
          handleCancel={this.handleCancelDelete}
          handleConfirm={this.props.onDeleteDocumentation}
          confirmMsg={t("confirm_delete_message").message || "Are you sure that you want to delete this? You can't undo this action."}
          confirmText={t("yes").message || "Yes"}
          cancelText={t("no").message || "No"}
          error={deleteError}
        />
      </>
    );
  }
}

export default withTranslation()(DocumentationModal);
