import React from "react";
import { withTranslation } from "react-i18next";
import { Icon, Step, Popup } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCar, faUserCheck } from "@fortawesome/free-solid-svg-icons";
import { faCarGarage } from "@fortawesome/pro-regular-svg-icons";
import moment from "moment";

import { COMMUNICATION_STATUSES, APPOINTMENT_STATUSES } from "./common";

function getCarReadyStatus(appointmentStatusId) {
  return appointmentStatusId === APPOINTMENT_STATUSES.CAR_READY ||
    appointmentStatusId === APPOINTMENT_STATUSES.QUALITY_CHECK ||
    appointmentStatusId === APPOINTMENT_STATUSES.CAR_OK_PLUS_REPAIR_OVERVIEW ||
    appointmentStatusId === APPOINTMENT_STATUSES.QUALITY_CHECK_PLUS_REPAIR_OVERVIEW
    ? "active"
    : "none";
}

function isStatusActive(appointmentStatusId, statusId) {
  return appointmentStatusId === statusId ? "active" : "none";
}

function getCarInShopStatus(appointment) {
  return appointment.car_in_shop && !appointment.car_out_of_shop;
}

function getStatusName(t, statuses, status_identifier) {
  return statuses?.find(s => s.identifier === status_identifier)?.name || "";
}

const AppointmentSteps = ({ onClick, appointment, onStepClick, dashConfig, commEnabled, isUpdatingAppointmentStatus, location, t }) => {
  let statusesConfig = [];
  statusesConfig[APPOINTMENT_STATUSES.PRICING_OK] = {
    disabled:
      appointment.appointment_status_identifier !== APPOINTMENT_STATUSES.CALCULATING || appointment.appointment_status_identifier === APPOINTMENT_STATUSES.BACK_ORDER,
    active: isStatusActive(appointment.appointment_status_identifier, APPOINTMENT_STATUSES.PRICING_OK),
    name: getStatusName(t, location?.statuses, APPOINTMENT_STATUSES.PRICING_OK),
  };
  statusesConfig[APPOINTMENT_STATUSES.CUSTOMER_OK] = {
    disabled:
      appointment.appointment_status_identifier !== APPOINTMENT_STATUSES.CONTACT &&
      appointment.appointment_status_identifier !== APPOINTMENT_STATUSES.PRICING_OK &&
      appointment.appointment_status_identifier !== APPOINTMENT_STATUSES.CUSTOMER_ANSWERED &&
      appointment.appointment_status_identifier !== APPOINTMENT_STATUSES.BACK_ORDER,
    active: isStatusActive(appointment.appointment_status_identifier, APPOINTMENT_STATUSES.CUSTOMER_OK),
    name: getStatusName(t, location?.statuses, APPOINTMENT_STATUSES.CUSTOMER_OK),
  };
  statusesConfig[APPOINTMENT_STATUSES.CAR_READY] = {
    disabled:
      (appointment.appointment_status_identifier === APPOINTMENT_STATUSES.CAR_READY ||
        appointment.appointment_status_identifier === APPOINTMENT_STATUSES.QUALITY_CHECK ||
        appointment.appointment_status_identifier === APPOINTMENT_STATUSES.CAR_OK_PLUS_REPAIR_OVERVIEW ||
        appointment.appointment_status_identifier === APPOINTMENT_STATUSES.QUALITY_CHECK_PLUS_REPAIR_OVERVIEW) &&
      appointment.appointment_status_identifier !== APPOINTMENT_STATUSES.BACK_ORDER,
    active: isStatusActive(appointment.appointment_status_identifier, APPOINTMENT_STATUSES.CAR_READY),
    name: getStatusName(t, location?.statuses, APPOINTMENT_STATUSES.CAR_READY),
  };
  statusesConfig[APPOINTMENT_STATUSES.CALCULATING] = {
    disabled:
      appointment.appointment_status_identifier !== APPOINTMENT_STATUSES.CAR_CHECK || appointment.appointment_status_identifier === APPOINTMENT_STATUSES.BACK_ORDER,
    active: isStatusActive(appointment.appointment_status_identifier, APPOINTMENT_STATUSES.CALCULATING),
    name: getStatusName(t, location?.statuses, APPOINTMENT_STATUSES.CALCULATING),
  };
  statusesConfig[APPOINTMENT_STATUSES.CONTACT] = {
    disabled:
      appointment.appointment_status_identifier !== APPOINTMENT_STATUSES.PRICING_OK || appointment.appointment_status_identifier === APPOINTMENT_STATUSES.BACK_ORDER,
    active: isStatusActive(appointment.appointment_status_identifier, APPOINTMENT_STATUSES.CONTACT),
    name: getStatusName(t, location?.statuses, APPOINTMENT_STATUSES.CONTACT),
  };
  statusesConfig[APPOINTMENT_STATUSES.CUSTOMER_ANSWERED] = {
    disabled: true,
    active: isStatusActive(appointment.appointment_status_identifier, APPOINTMENT_STATUSES.CUSTOMER_ANSWERED),
    name: getStatusName(t, location?.statuses, APPOINTMENT_STATUSES.CUSTOMER_ANSWERED),
  };

  const carInShop = {
    payload: { type: "step", shouldSave: true, value: APPOINTMENT_STATUSES.CAR_IN_SHOP },
    icon: getCarInShopStatus(appointment) ? faCarGarage : faCar,
    className: getCarInShopStatus(appointment) ? "on" : "off",
    name: getStatusName(t, location?.statuses, APPOINTMENT_STATUSES.CAR_IN_SHOP),
  };

  if (appointment.car_in_shop && !appointment.car_out_of_shop) {
    carInShop.payload.car_out_of_shop = moment().utc().format("YYYY-MM-DDTHH:mm:ss[Z]");
  } else {
    carInShop.payload.car_in_shop = moment().utc().format("YYYY-MM-DDTHH:mm:ss[Z]");
    carInShop.payload.car_out_of_shop = null;
  }

  return (
    <div className="AppointmentSteps">
      <Step.Group size="mini">
        <Step
          id="car-in-shop-step"
          className={`-active-status-${carInShop.className}`}
          link
          onClick={() => onStepClick(carInShop.payload)}
          disabled={isUpdatingAppointmentStatus}
        >
          <FontAwesomeIcon className="hideOnSmallerScreen" icon={carInShop.icon} style={{ fontSize: "22px", marginRight: "12px" }} />
          <Popup content={carInShop.name} trigger={<FontAwesomeIcon className="hideOnBigScreen" icon={carInShop.icon} style={{ fontSize: "24px" }} />}></Popup>
          <Step.Content>
            <Step.Title className="hideOnSmallerScreen">{carInShop.name}</Step.Title>
          </Step.Content>
        </Step>

        <Step
          className={`-active-status-${statusesConfig[APPOINTMENT_STATUSES.CALCULATING].active}`}
          link
          onClick={() => onStepClick({ type: "step", shouldSave: true, value: APPOINTMENT_STATUSES.CALCULATING })}
          disabled={statusesConfig[APPOINTMENT_STATUSES.CALCULATING].disabled || isUpdatingAppointmentStatus}
        >
          <Icon className="hideOnSmallerScreen" name="file outline" />
          <Popup content={statusesConfig[APPOINTMENT_STATUSES.CALCULATING].name} trigger={<Icon className="hideOnBigScreen" name="file outline" />}></Popup>
          <Step.Content>
            <Step.Title className="hideOnSmallerScreen">{statusesConfig[APPOINTMENT_STATUSES.CALCULATING].name}</Step.Title>
          </Step.Content>
        </Step>

        <Step
          className={`-active-status-${statusesConfig[APPOINTMENT_STATUSES.PRICING_OK].active}`}
          link
          onClick={() => onStepClick({ type: "step", shouldSave: true, value: APPOINTMENT_STATUSES.PRICING_OK })}
          disabled={statusesConfig[APPOINTMENT_STATUSES.PRICING_OK].disabled || isUpdatingAppointmentStatus}
        >
          <Icon className="hideOnSmallerScreen" name="check" />
          <Popup content={statusesConfig[APPOINTMENT_STATUSES.PRICING_OK].name} trigger={<Icon className="hideOnBigScreen" name="check" />}></Popup>
          <Step.Content>
            <Step.Title className="hideOnSmallerScreen">{statusesConfig[APPOINTMENT_STATUSES.PRICING_OK].name}</Step.Title>
          </Step.Content>
        </Step>

        <Step
          className={`-active-status-${statusesConfig[APPOINTMENT_STATUSES.CONTACT].active}`}
          link
          onClick={() => onStepClick({ type: "step", shouldSave: true, value: APPOINTMENT_STATUSES.CONTACT })}
          disabled={statusesConfig[APPOINTMENT_STATUSES.CONTACT].disabled || isUpdatingAppointmentStatus}
        >
          <Icon className="hideOnSmallerScreen" name={`${appointment.customcom_status >= COMMUNICATION_STATUSES.DIAGNOSE_OVERVIEW_SENT ? "mail" : "phone"}`} />
          <Popup
            content={statusesConfig[APPOINTMENT_STATUSES.CONTACT].name}
            trigger={<Icon className="hideOnBigScreen" name={`${appointment.customcom_status >= COMMUNICATION_STATUSES.DIAGNOSE_OVERVIEW_SENT ? "mail" : "phone"}`} />}
          ></Popup>
          <Step.Content>
            <Step.Title className="hideOnSmallerScreen">{statusesConfig[APPOINTMENT_STATUSES.CONTACT].name}</Step.Title>
          </Step.Content>
        </Step>

        {commEnabled && (
          <Step
            className={`-active-status-${statusesConfig[APPOINTMENT_STATUSES.CUSTOMER_ANSWERED].active}`}
            link
            disabled={statusesConfig[APPOINTMENT_STATUSES.CUSTOMER_ANSWERED].disabled || isUpdatingAppointmentStatus}
          >
            <FontAwesomeIcon className="hideOnSmallerScreen" icon={faUserCheck} style={{ marginRight: "1rem,", fontSize: "2.3em" }} />
            <Popup
              content={statusesConfig[APPOINTMENT_STATUSES.CUSTOMER_ANSWERED].name}
              trigger={<FontAwesomeIcon className="hideOnBigScreen" icon={faUserCheck} style={{ marginRight: "1rem,", fontSize: "2.3em" }} />}
            ></Popup>
            <Step.Content>
              <Step.Title className="hideOnSmallerScreen">{statusesConfig[APPOINTMENT_STATUSES.CUSTOMER_ANSWERED].name}</Step.Title>
            </Step.Content>
          </Step>
        )}

        <Step
          className={`-active-status-${statusesConfig[APPOINTMENT_STATUSES.CUSTOMER_OK].active}`}
          link
          onClick={() => onStepClick({ type: "step", shouldSave: true, value: APPOINTMENT_STATUSES.CUSTOMER_OK })}
          disabled={statusesConfig[APPOINTMENT_STATUSES.CUSTOMER_OK].disabled || isUpdatingAppointmentStatus}
        >
          <Icon name="smile" className="-no-click hideOnSmallerScreen" />
          <Popup content={statusesConfig[APPOINTMENT_STATUSES.CUSTOMER_OK].name} trigger={<Icon name="smile" className="hideOnBigScreen" />}></Popup>
          <Step.Content>
            <Step.Title className="hideOnSmallerScreen">{statusesConfig[APPOINTMENT_STATUSES.CUSTOMER_OK].name}</Step.Title>
          </Step.Content>
        </Step>

        {dashConfig.car_ready_btn_visible && (
          <Step
            disabled={statusesConfig[APPOINTMENT_STATUSES.CAR_READY].disabled || isUpdatingAppointmentStatus}
            link
            onClick={() => onStepClick({ type: "step", value: APPOINTMENT_STATUSES.CAR_READY })}
            className={`-active-status-${getCarReadyStatus(appointment.appointment_status_identifier)}`}
          >
            <Icon className="hideOnSmallerScreen" name="car" />
            <Popup content={statusesConfig[APPOINTMENT_STATUSES.CAR_READY].name} trigger={<Icon className="hideOnBigScreen" name="car" />}></Popup>
            <Step.Content>
              <Step.Title className="hideOnSmallerScreen">{statusesConfig[APPOINTMENT_STATUSES.CAR_READY].name}</Step.Title>
            </Step.Content>
          </Step>
        )}
      </Step.Group>
    </div>
  );
};

export default withTranslation()(AppointmentSteps);
