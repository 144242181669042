// @flow
import React, { Component } from "react";
import ReactTable, { ReactTableDefaults } from "react-table";
import { Icon, Label, Grid, Button } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import BrandDetail from "./BrandDetail";

import { SubHeader } from "./../../components";
import Service from "./service";
import CustomConfirm from "../../components/CustomConfirm";
import Can from "../Can";
import "./Brands.css";

class Brands extends Component {
  state = {
    brands: [],
    deleteError: null,
    selectedBrand: {},
    isLoadingBrands: false,
    isSubmittingDetail: false,
    isBrandDetailVisible: false,
    isDeleteConfirmationVisible: false,
    detailViewMode: "",
    isDeletingBrand: false,
  };

  componentDidMount() {
    this.getBrands();
  }

  getBrands = () => {
    if (this.state.isLoadingBrands) {
      return;
    }
    this.setState(
      {
        isLoadingBrands: true,
      },
      () => {
        Service.getBrands()
          .then(result => {
            if (result && result.data && result.data.data) {
              this.setState({ brands: result.data.data, isLoadingBrands: false });
            }
          })
          .catch(error => {
            console.error("Error getting news brands.", error);
            this.setState({ brands: [], isLoadingBrands: false });
          });
      }
    );
  };

  handleDeleteBrand = () => {
    let { selectedBrand, isLoadingBrands } = this.state;

    if (isLoadingBrands || !(selectedBrand && selectedBrand.id)) {
      return;
    }

    this.setState(
      {
        isLoadingBrands: true,
        isDeletingBrand: true,
      },
      () => {
        Service.deleteBrand({ id: selectedBrand.id })
          .then(result => {
            if (result && result.data && result.data.code) {
              this.setState({ isDeleteConfirmationVisible: false, selectedBrand: {}, deleteError: null, isLoadingBrands: false, isDeletingBrand: false }, () => {
                this.getBrands();
              });
            }
          })
          .catch(error => {
            let err = error.response && error.response.data && error.response.data.errors ? error.response.data.errors[0] : "Error deleting brand";
            this.setState({ deleteError: err, isLoadingBrands: false, isDeletingBrand: false });
          });
      }
    );
  };

  handleHideDeleteConfirmation = () => {
    this.setState({ isDeleteConfirmationVisible: false, selectedBrand: {}, deleteError: null, isLoadingBrands: false });
  };

  handleShowDeleteConfirmation = brand => {
    this.setState({ isDeleteConfirmationVisible: true, selectedBrand: brand, deleteError: null });
  };

  handleShowBrandDetail = options => {
    this.setState({
      detailViewMode: options.mode,
      isBrandDetailVisible: true,
      isSubmittingDetail: false,
      selectedBrand: { ...options.selectedBrand },
    });
  };

  handleHideBrandDetail = () => {
    this.setState({ isBrandDetailVisible: false, selectedBrand: {}, isSubmittingDetail: false, isLoadingBrands: false });
  };

  handleSaveBrand = (brand, photo) => {
    if (!brand.name.trim()) {
      return;
    }

    this.setState({ isSubmittingDetail: true });

    if (!photo) {
      this.submitBrand(brand);
      return;
    }

    this.uploadLogo(photo).then(response => {
      if (response && response.data && response.data.data && response.data.data.URL) {
        brand.logo = response.data.data.URL;
        this.submitBrand(brand);
      }
    });
  };

  uploadLogo = file => {
    const { selectedBrand } = this.state;

    if (!file) return;

    let fd = new FormData();
    const path = `${selectedBrand.vin_prefix}_${selectedBrand.name}`;

    fd.append("file", file);
    fd.append("path", path);

    return Service.uploadBrandLogo(fd);
  };

  submitBrand = brand => {
    if (this.state.detailViewMode === "create") {
      this.setState(
        {
          isSubmittingDetail: true,
        },
        () => {
          Service.createBrand(brand)
            .then(result => {
              if (result && result.data && result.data.code && result.data.code === 200) {
                this.handleHideBrandDetail();
                this.getBrands();
              }
            })
            .catch(error => {
              console.error("Error on submitting brand.", error);
              this.setState({ isSubmittingDetail: false });
            });
        }
      );
    } else {
      this.setState(
        {
          isLoadingBrands: true,
        },
        () => {
          Service.updateBrand(brand)
            .then(result => {
              if (result && result.data && result.data.code && result.data.code === 200) {
                this.handleHideBrandDetail();
                this.getBrands();
              }
            })
            .catch(error => {
              console.error("Error on submitting brand.", error);
              this.setState({ isSubmittingDetail: false });
            });
        }
      );
    }
  };

  renderDeleteConfirm = () => {
    return (
      <CustomConfirm
        type="danger"
        isOpen={this.state.isDeleteConfirmationVisible}
        handleCancel={this.handleHideDeleteConfirmation}
        handleConfirm={() => this.handleDeleteBrand()}
        isLoading={this.state.isDeletingBrand}
        confirmMsg={this.props.t("confirm_delete_message").message || "Are you sure that you want to delete this? You can't undo this action."}
        error={this.state.deleteError}
      />
    );
  };

  renderTable = () => {
    let { isLoadingBrands, brands } = this.state;
    let { t } = this.props;

    if (isLoadingBrands) {
      return (
        <div className="Table__loading Loader-Placeholder">
          <div className="bounce1"></div>
          <div className="bounce2"></div>
          <div className="bounce3"></div>
          <section>{t("loading_brands").message || "Loading brands"}</section>
        </div>
      );
    } else {
      return (
        <div className="BrandsTable">
          <ReactTable
            className="ReactTable -floated-table -contained-large"
            data={brands}
            showPagination={false}
            showPageSizeOptions={false}
            sortable={false}
            resizable={false}
            defaultPageSize={brands.length}
            pageSize={brands.length}
            noDataText={
              <div className="Table__no-results">
                <Icon disabled name="car" style={{ fontSize: "1.75em" }} />
                <p>{t("no_brands").message || "No brands"}</p>
              </div>
            }
            getTdProps={(state, rowInfo, column, instance) => {
              return {
                onClick: e => {
                  if (column.id !== "delete") {
                    this.handleShowBrandDetail({ mode: "edit", selectedBrand: rowInfo.original });
                  }
                },
              };
            }}
            column={{
              ...ReactTableDefaults.column,
              headerClassName: "ReactTable__column-header",
              className: "ReactTable__column",
            }}
            columns={[
              {
                id: "name",
                Header: t("brand").message || "Brand",
                accessor: "name",
              },
              {
                id: "vin_prefix",
                Header: t("vin_nr").message || "Vin #",
                accessor: "vin_prefix",
              },
              {
                id: "button_label",
                Header: t("button_label").message || "Button label",
                accessor: "button_label",
              },
              {
                id: "delete",
                Header: "",
                className: "icons-snoozed",
                accessor: d => (
                  <Can I="delete" the="brands">
                    <Label color="red" className="BrandDeleteLabel" onClick={() => this.handleShowDeleteConfirmation(d)}>
                      <Icon link name="trash" />
                    </Label>
                  </Can>
                ),
                maxWidth: 55,
              },
            ]}
          />
        </div>
      );
    }
  };

  render() {
    return (
      <div className="Brand page_inner_wrapper">
        <SubHeader>
          <Grid.Column width={9}>
            <h1>{this.props.t("brands").message || "Brands"}</h1>
          </Grid.Column>
          <Grid.Column width={2} floated="right">
            <Button
              color="green"
              icon
              labelPosition="left"
              onClick={() => {
                this.handleShowBrandDetail({
                  mode: "create",
                  selectedBrand: { name: "", vin_prefix: "" },
                });
              }}
              fluid
            >
              <Icon name="add" /> {this.props.t("add").message || "Add"}
            </Button>
          </Grid.Column>
        </SubHeader>

        {this.renderTable()}
        {this.state.isDeleteConfirmationVisible && this.renderDeleteConfirm()}
        {this.state.isBrandDetailVisible && (
          <BrandDetail
            mode={this.state.detailViewMode}
            loading={this.state.isSubmittingDetail}
            brand={this.state.selectedBrand}
            onHideDetail={this.handleHideBrandDetail}
            onSave={this.handleSaveBrand}
          />
        )}
      </div>
    );
  }
}

export default withTranslation()(Brands);
