import React from "react";

import SubHeader from "../SubHeader";

import "./ScrollGrid.scss";

const ScrollGrid = ({ header, children, headerHeight, containerClassName = "" }) => {
  return (
    <div className={`ScrollGrid ${containerClassName}`}>
      <SubHeader>{header}</SubHeader>

      <div style={{ height: `calc(100% - ${headerHeight}px)`, overflowY: "auto" }}>{children}</div>
    </div>
  );
};

export default ScrollGrid;
