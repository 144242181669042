import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Menu } from "semantic-ui-react";
import * as Scroll from "../../lib/react-scroll";

const ScrollLink = Scroll.ScrollLink(Menu.Item);

class CarDetailHeader extends Component {
  state = {
    targeting: null,
    isAtBottom: false,
    container: {},
  };

  ticking = false;

  componentDidMount = () => {
    this.setState({ container: document.getElementById("carDetailModalScrollContainer") }, () => {
      this.state.container.addEventListener("scroll", () => {
        if (!this.ticking) window.requestAnimationFrame(this.handleScroll);

        this.ticking = true;
      });
    });
  };

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    const isAtBottom = this.state.container.scrollHeight - this.state.container.offsetHeight === this.state.container.scrollTop;

    if (isAtBottom) this.setState({ isAtBottom });
    else if (this.state.isAtBottom) this.setState({ isAtBottom: false, targeting: null });

    this.ticking = false;
  };

  render() {
    const { t } = this.props;
    const { targeting } = this.state;

    return (
      <Menu pointing secondary color="green" size="massive" className="CarDetailMenu">
        <ScrollLink
          activeClass="active"
          containerId="carDetailModalScrollContainer"
          name={t("car_details").message || "Car Details"}
          to="cardetailscrollsection-cardetails"
          spy={true}
          smooth={true}
          duration={500}
          onClick={() => this.setState({ targeting: "cardetailscrollsection-cardetails" })}
          targeting={targeting}
        />
        <ScrollLink
          activeClass="active"
          containerId="carDetailModalScrollContainer"
          name={t("dms_notes").message || "DMS Notes"}
          to="cardetailscrollsection-dmsnotes"
          spy={true}
          smooth={true}
          duration={500}
          onClick={() => this.setState({ targeting: "cardetailscrollsection-dmsnotes" })}
          targeting={targeting}
        />
        <ScrollLink
          activeClass="active"
          containerId="carDetailModalScrollContainer"
          name={t("snoozed_items").message || "Snoozed Items"}
          to="cardetailscrollsection-snoozeditems"
          spy={true}
          smooth={true}
          duration={500}
          onClick={() => this.setState({ targeting: "cardetailscrollsection-snoozeditems" })}
          targeting={targeting}
        />
        <ScrollLink
          activeClass="active"
          containerId="carDetailModalScrollContainer"
          name={t("pin_items").message || "Pin Items"}
          to="cardetailscrollsection-pinitems"
          spy={true}
          smooth={true}
          duration={500}
          onClick={() => this.setState({ targeting: "cardetailscrollsection-pinitems" })}
          targeting={targeting}
        />
        <ScrollLink
          activeClass="active"
          containerId="carDetailModalScrollContainer"
          name={t("appointment_history").message || "Appointment History"}
          to="cardetailscrollsection-appointment_history"
          spy={true}
          smooth={true}
          duration={500}
          onClick={() => this.setState({ targeting: "cardetailscrollsection-appointment_history" })}
          targeting={targeting}
        />
        <ScrollLink
          activeClass="active"
          containerId="carDetailModalScrollContainer"
          name={t("driving_trend").message || "Driving Trend"}
          to="cardetailscrollsection-drivingtrend"
          spy={true}
          smooth={true}
          duration={500}
          onClick={() => this.setState({ targeting: "cardetailscrollsection-drivingtrend" })}
          targeting={targeting}
        />
        <ScrollLink
          activeClass="active"
          containerId="carDetailModalScrollContainer"
          name={t("tyres").message || "Tyres"}
          to="cardetailscrollsection-tyres"
          spy={true}
          smooth={true}
          duration={500}
          isLast={true}
          onClick={() => this.setState({ targeting: "cardetailscrollsection-tyres" })}
          targeting={targeting}
        />
      </Menu>
    );
  }
}

export default withTranslation()(CarDetailHeader);
