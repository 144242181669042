import React, { Component } from "react";
import { Grid, Search, Form, Modal, Button, Icon, Popup } from "semantic-ui-react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import Loader from "react-loader-spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import { faFileExport } from "@fortawesome/pro-regular-svg-icons";
import moment from "moment";

import { SubHeader, SearchPortal, UserMenuActionsPortal } from "../../../components";
import { TYRE_POSITION, QUESTION_RESULT_STATUS, SEASONS } from "../../Appointments/common";
import { getDealerAndLocationById, getLocationById, dropdownOptionsFromObjectToList } from "../../../util/common";
import { setAlert } from "../../../modules/App/store.js";
import { getAppointment, deselectAppointment, getChecks, applyUpdateToAppointment, handleUpdateAppointments, APPOINTMENTS_ACTION_TYPES } from "../../Appointments/store";
import { getCar, deselectCar, CARS_ACTION_TYPES } from "../../Cars/store";
import AppointmentDetail from "../../Appointments/AppointmentDetail";
import CarDetail from "../../Cars/CarDetail";
import { normalizeResults, prepareQuestionDropdownOptions, dropdownListOptions, groupWOResults } from "../common";

import Service from "../service";

import TireTable from "./TireTable";
import TireFilters from "./TireFilters";

class TiresLeads extends Component {
  state = {
    items: [],
    page: 0,
    nb_pages: null,
    nb_items: null,
    isLoadingTyreLeads: false,
    isLoading: false,
    isAppointmentDetailVisible: false,
    selectedAppointment: null,
    isCarDetailVisible: false,
    selectedCar: null,
    searchTerm: "",
    selectedCarInfo: {},
    customDateFrom: new Date().setMonth(new Date().getMonth() - 1),
    customDateTo: new Date(),
    selectedStatuses: [QUESTION_RESULT_STATUS.OK_WITH_REMARKS, QUESTION_RESULT_STATUS.ADVISED, QUESTION_RESULT_STATUS.NECESSARY],
    selectedSeasons: [SEASONS.SUMMER, SEASONS.WINTER, SEASONS.ALL_SEASONS],
    selectedSize: "",
    selectedWidth: "",
    selectedHeight: "",
    selectedSpeed: "",
    selectedBrand: "",
    snoozed: false,
    selectedLocationsIDs: [],
    selectedDealersIDs: [],
    selectedPosition: [
      TYRE_POSITION.CAR_FRONT_LEFT,
      TYRE_POSITION.CAR_FRONT_RIGHT,
      TYRE_POSITION.CAR_REAR_LEFT,
      TYRE_POSITION.CAR_REAR_RIGHT,
      TYRE_POSITION.STORAGE_FRONT_LEFT,
      TYRE_POSITION.STORAGE_FRONT_RIGHT,
      TYRE_POSITION.STORAGE_REAR_LEFT,
      TYRE_POSITION.STORAGE_REAR_RIGHT,
    ],
    selectedProfile: "5.0",
    selectedCustomerApproved: false,
    tiresSizeOptions: [],
    tiresBrandOptions: [],
    tiresSpeedOptions: [],
    tiresWidthOptions: [],
    tiresHeightOptions: [],
    exportURL: "",
    exportModalOpen: false,
    isLoadingExport: false,
    exportError: false,
    moreFiltersVisible: false,
    shouldReset: false,
  };

  async componentDidMount() {
    try {
      const [tireSizes, tireBrands, tireSpeed] = await Promise.all([Service.getTiresSize(), Service.getTiresBrands(), Service.getTiresSpeeds()]);

      const tiresSizeOptions = tireSizes?.data?.data?.sizes ? dropdownListOptions(tireSizes.data.data.sizes) : [];
      const tiresBrandOptions = tireBrands?.data?.data ? dropdownOptionsFromObjectToList(tireBrands.data.data) : [];
      const tiresSpeedOptions = tireSpeed?.data?.data ? dropdownListOptions(tireSpeed.data.data) : [];

      this.setState({ tiresSizeOptions, tiresBrandOptions, tiresSpeedOptions });
    } catch (error) {
      this.props.setAlert({ type: "error", title: this.props.t("dropdown_not_loaded").message || "Loading of some dropdowns failed" });
      console.log("Error loading dropdowns", error);
    }
  }

  componentDidUpdate = prevProps => {
    const { selectedAppointment, selectedCar } = this.state;
    const { appointmentsState, carsState, setAlert, t } = this.props;

    if (appointmentsState.actionType !== prevProps.appointmentsState.actionType) {
      if (selectedAppointment) {
        if (appointmentsState.actionType === APPOINTMENTS_ACTION_TYPES.GET_APPOINTMENT_FAIL) {
          setAlert({ type: "error", title: "Cannot load this appointment" });
          this.setState({ selectedAppointment: null, isLoading: false });
        }

        if (appointmentsState.actionType === APPOINTMENTS_ACTION_TYPES.GET_APPOINTMENT_SUCCESS) this.setState({ isAppointmentDetailVisible: true, isLoading: false });

        if (appointmentsState.actionType === APPOINTMENTS_ACTION_TYPES.WEB_SOCKET_APPOINTMENTS_UPDATE && appointmentsState.webSocketUpdate) {
          this.socketAppointmentUpdate(appointmentsState.webSocketEvent);
        }
      }
    }

    if (carsState.actionType !== prevProps.carsState.actionType) {
      if (selectedCar) {
        if (carsState.actionType === CARS_ACTION_TYPES.GET_CAR_FAIL) {
          this.setState({ selectedCar: null, isLoading: false }, () => setAlert({ type: "error", title: t("can_not_load_this_car").message || "Can not load this car" }));
        } else if (carsState.actionType === CARS_ACTION_TYPES.GET_CAR_NOT_AUTHORIZED) {
          this.setState({ selectedCar: null, isLoading: false }, () =>
            setAlert({ type: "error", title: t("car_location_no_access_err_msg").message || "This car has moved to a location you cannot access" })
          );
        }

        if (carsState.actionType === CARS_ACTION_TYPES.GET_CAR_SUCCESS) {
          if (!carsState.selectedCar) {
            this.setState({ selectedCar: null, isLoading: false });
          } else {
            this.setState({
              isLoading: false,
              isCarDetailVisible: true,
              selectedCarInfo: {
                car: carsState.selectedCar,
                customer: carsState.selectedCustomer,
                appointmentHistory: carsState.selectedCarAppointments,
                snoozedQuestions: carsState.selectedCarSnoozedQuestions,
              },
            });
          }
        }
      }
    }
  };

  socketAppointmentUpdate = event => {
    let updatePayload = null;
    if (event.payload?.data) updatePayload = JSON.parse(event.payload.data);

    // apply update to appointmen list and appointment detail page if open
    this.props.applyUpdateToAppointment(updatePayload, true);
  };

  handleSearchChange = (_e, data) => {
    this.setState({ searchTerm: data.value }, () => this.getLeadsItems());
  };

  getTiresLeads = () => {
    const {
      page,
      searchTerm,
      selectedDealersIDs,
      selectedLocationsIDs,
      selectedStatuses,
      customDateFrom,
      customDateTo,
      selectedSeasons,
      selectedSize,
      selectedWidth,
      selectedHeight,
      selectedSpeed,
      selectedBrand,
      selectedPosition,
      snoozed,
      selectedCustomerApproved,
      selectedProfile,
    } = this.state;

    const params = {
      page,
      date_from: moment(customDateFrom).format("YYYY-MM-DDT00:00:00Z"),
      date_to: moment(customDateTo).format("YYYY-MM-DDT00:00:00Z"),
      dealer_ids: selectedDealersIDs,
      location_ids: selectedLocationsIDs,
      season: selectedSeasons ? selectedSeasons : null,
      statuses: selectedStatuses?.length > 0 ? selectedStatuses : null,
      size: selectedSize !== "" ? selectedSize : null,
      width: selectedWidth !== "" ? selectedWidth : null,
      height: selectedHeight !== "" ? selectedHeight : null,
      speed: selectedSpeed ? selectedSpeed : null,
      customer_approved: selectedCustomerApproved,
      profile: selectedProfile ? Number(selectedProfile) : null,
      tyre_position: selectedPosition ? selectedPosition : null,
      manufacturer: selectedBrand ? selectedBrand : null,
      snoozed,
      search_term: searchTerm,
    };

    return Service.getTiresLeads(params);
  };

  prepareResults = results => {
    const questions = [];

    results.forEach((result, idx) => {
      questions[idx] = { ...result, history: result.snooze_history ? result.snooze_history : [] };
      questions[idx].appointment.time_car_app = moment(questions[idx].appointment.time_car_app).startOf("day");
    });

    return groupWOResults(
      [...questions].sort((a, b) =>
        moment(b.appointment.time_car_app).isAfter(moment(a.appointment.time_car_app))
          ? 1
          : moment(b.appointment.time_car_app).isBefore(moment(a.appointment.time_car_app))
          ? -1
          : b.appointment.wo_nr.localeCompare(a.appointment.wo_nr)
      )
    );
  };

  getLeadsItems = page => {
    if (!page) page = 1;
    else if (page === this.state.page || this.state.isLoadingTyreLeads) return; // react table is going to fire this incorrectly

    this.setState({ isLoadingTyreLeads: true, page }, async () => {
      const { selectedLocationsIDs, selectedDealersIDs } = this.state;

      if (selectedLocationsIDs.length < 1 && selectedDealersIDs.length < 1) {
        this.setState({ items: [], nb_pages: 0, nb_items: 0, isLoadingTyreLeads: false });
        return;
      }

      try {
        const response = await this.getTiresLeads();
        const { items, nb_pages, nb_items } = normalizeResults(response);
        const preparedResults = this.prepareResults(items);

        this.setState({ items: preparedResults, page, nb_pages, nb_items, isLoadingTyreLeads: false });
      } catch (error) {
        console.log("Error getting leads", error);
        this.setState({ isLoadingTyreLeads: false }, () => this.props.setAlert({ type: "error", title: "Cannot load leads" }));
        return;
      }
    });
  };

  getQuestionsByChecklist = () => {
    const { selectedDealersIDs, selectedLocationsIDs } = this.state;

    if (!selectedDealersIDs.length && !selectedLocationsIDs.length) {
      this.setState({ questions: [] });
      return;
    }

    const requestData = {
      dealer_ids: selectedDealersIDs.length ? selectedDealersIDs : null,
      location_ids: selectedLocationsIDs.length ? selectedLocationsIDs : null,
      embed: "question",
    };

    this.setState({ isLoadingQuestions: true }, () => {
      Service.getChecklists(requestData)
        .then(res => {
          this.setState({ questions: res?.data?.data?.checklists ? prepareQuestionDropdownOptions(res.data.data.checklists) : [], isLoadingQuestions: false });
        })
        .catch(err => {
          this.setState({ isLoadingQuestions: false });
          this.props.setAlert({ type: "error", title: "Cannot load question options" });
        });
    });
  };

  handleChangeSnoozed = () => this.setState({ snoozed: !this.state.snoozed }, () => this.getLeadsItems());

  handleChangeTiresSize = selectedSize => {
    this.setState({ selectedSize, tiresHeightOptions: [] }, () => {
      Service.getTiresWidths({ size: selectedSize !== "" ? selectedSize : null }).then(res => {
        const tiresWidthOptions = res?.data?.data ? dropdownListOptions(res.data.data) : [];
        this.setState({ tiresWidthOptions, selectedWidth: "", selectedHeight: "" }, () => this.getLeadsItems());
      });
    });
  };

  handleChangeTiresWidth = selectedWidth => {
    this.setState({ selectedWidth }, () => {
      Service.getTiresHeight({ size: this.state.selectedSize, width: selectedWidth !== "" ? selectedWidth : null }).then(res => {
        const tiresHeightOptions = res?.data?.data ? dropdownListOptions(res.data.data) : [];
        this.setState({ tiresHeightOptions, selectedHeight: "" }, () => this.getLeadsItems());
      });
    });
  };

  handleChangeTiresHeight = selectedHeight => this.setState({ selectedHeight }, () => this.getLeadsItems());

  handleChangeBrand = selectedBrand => this.setState({ selectedBrand }, () => this.getLeadsItems());

  handleChangeSpeed = selectedSpeed => this.setState({ selectedSpeed }, () => this.getLeadsItems());

  handleChangeCustomDate = dates => {
    const [start, end] = dates;
    this.setState({ customDateFrom: start, customDateTo: end }, () => {
      if (!end) return;
      this.getLeadsItems();
    });
  };

  handleExportTiresLeads = () => {
    const {
      page,
      searchTerm,
      selectedLocationsIDs,
      selectedStatuses,
      customDateFrom,
      customDateTo,
      selectedSeasons,
      selectedSize,
      selectedWidth,
      selectedHeight,
      selectedSpeed,
      selectedBrand,
      selectedPosition,
      snoozed,
      selectedCustomerApproved,
      selectedProfile,
    } = this.state;

    const params = {
      page,
      date_from: moment(customDateFrom).format("YYYY-MM-DDT00:00:00Z"),
      date_to: moment(customDateTo).format("YYYY-MM-DDT00:00:00Z"),
      location_ids: selectedLocationsIDs,
      season: selectedSeasons ? selectedSeasons : null,
      statuses: selectedStatuses?.length > 0 ? selectedStatuses : null,
      size: selectedSize !== "" ? selectedSize : null,
      width: selectedWidth !== "" ? selectedWidth : null,
      height: selectedHeight !== "" ? selectedHeight : null,
      speed: selectedSpeed ? selectedSpeed : null,
      customer_approved: selectedCustomerApproved,
      profile: selectedProfile ? Number(selectedProfile) : null,
      tyre_position: selectedPosition ? selectedPosition : null,
      manufacturer: selectedBrand ? selectedBrand : null,
      snoozed,
      search_term: searchTerm,
    };

    return Service.exportTiresLeads(params);
  };

  handleChangeSelectedLocationsIDs = ({ location_ids, dealer_ids }) => {
    const prevSelectedLocationIDs = [...this.state.selectedLocationsIDs];
    const prevSelectedDealerIDs = [...this.state.selectedDealersIDs];

    this.setState({ selectedLocationsIDs: location_ids, selectedDealersIDs: dealer_ids }, () => {
      if (location_ids.length !== prevSelectedLocationIDs.length || dealer_ids.length !== prevSelectedDealerIDs.length) this.getQuestionsByChecklist();

      this.getLeadsItems();
    });
  };

  handleChangeStatus = status => {
    let { selectedStatuses } = this.state;
    if (status === null) selectedStatuses = [];
    else if (selectedStatuses.includes(status)) selectedStatuses = selectedStatuses.filter(s => s !== status);
    else selectedStatuses = selectedStatuses.concat(status);

    this.setState({ selectedStatuses }, () => this.getLeadsItems());
  };

  handleChangeSeasons = status => {
    let { selectedSeasons } = this.state;
    if (status === null) selectedSeasons = [];
    else if (selectedSeasons.includes(status)) selectedSeasons = selectedSeasons.filter(s => s !== status);
    else selectedSeasons = selectedSeasons.concat(status);

    this.setState({ selectedSeasons }, () => this.getLeadsItems());
  };

  handleChangeCustomerApproved = selectedCustomerApproved => this.setState({ selectedCustomerApproved }, () => this.getLeadsItems());

  handleChangeTiresProfile = evt => this.setState({ selectedProfile: evt.target.value }, () => this.getLeadsItems());

  handleChangeTiresPosition = positions => {
    let selectedPosition = [...this.state.selectedPosition];

    const shouldFilterOutPositions = positions.some(position => selectedPosition.includes(position));

    if (shouldFilterOutPositions) selectedPosition = selectedPosition.filter(position => !positions.includes(position));
    else selectedPosition = [...selectedPosition, ...positions];

    this.setState({ selectedPosition }, () => this.getLeadsItems());
  };

  handleCloseExportModal = () => this.setState({ exportURL: "", exportError: false, isLoadingExport: false, exportModalOpen: false });

  handleExport = () => {
    this.setState({ exportModalOpen: true, isLoadingExport: true }, async () => {
      try {
        const response = await this.handleExportTiresLeads();

        const exportURL = response?.data?.data || "";
        this.setState({ exportURL, isLoadingExport: false });
      } catch (error) {
        this.setState({ isLoadingExport: false, exportError: true });
      }
    });
  };

  handleUpdateMoreFilters = () => {
    this.setState(prevState => ({
      moreFiltersVisible: !prevState.moreFiltersVisible,
    }));
  };

  handleResetFilters = () => {
    this.setState(
      {
        shouldReset: true,
        isLoading: false,
        searchTerm: "",
        customDateFrom: new Date().setMonth(new Date().getMonth() - 1),
        customDateTo: new Date(),
        selectedStatuses: [QUESTION_RESULT_STATUS.OK_WITH_REMARKS, QUESTION_RESULT_STATUS.ADVISED, QUESTION_RESULT_STATUS.NECESSARY],
        selectedSeasons: [SEASONS.SUMMER, SEASONS.WINTER, SEASONS.ALL_SEASONS],
        selectedSize: null,
        selectedWidth: null,
        selectedHeight: null,
        tiresWidthOptions: [],
        tiresHeightOptions: [],
        selectedSpeed: null,
        selectedBrand: null,
        snoozed: false,
        selectedLocationsIDs: [],
        selectedDealersIDs: [],
        selectedPosition: [
          TYRE_POSITION.CAR_FRONT_LEFT,
          TYRE_POSITION.CAR_FRONT_RIGHT,
          TYRE_POSITION.CAR_REAR_LEFT,
          TYRE_POSITION.CAR_REAR_RIGHT,
          TYRE_POSITION.STORAGE_FRONT_LEFT,
          TYRE_POSITION.STORAGE_FRONT_RIGHT,
          TYRE_POSITION.STORAGE_REAR_LEFT,
          TYRE_POSITION.STORAGE_REAR_RIGHT,
        ],
        selectedProfile: "5.0",
        selectedCustomerApproved: false,
      },
      () => {
        this.setState({ shouldReset: false }, () => this.getLeadsItems());
      }
    );
  };

  handleSnoozeChanged = updatedSnoozeLog => {
    let { items } = this.state;

    const updatedItem = updatedSnoozeLog[0];

    items = items.map(i => {
      if (i.id === updatedItem.question_result.id) {
        return { ...updatedItem.question_result, history: updatedSnoozeLog };
      }

      return i;
    });

    this.setState({ items });
  };

  handleSnoozeDeleted = (question, reason) => {
    const { user } = this.props.authState;
    const newLastLog = { ...question.history[0] };
    newLastLog.snooze_status_id = 0;
    newLastLog.user_id = user.id;
    newLastLog.user = user;
    newLastLog.note = reason;
    newLastLog.id++;
    newLastLog.created_on = moment().format("YYYY-MM-DDTHH:mm:ssZ");

    question = { ...question, ...newLastLog, history: [newLastLog, ...question.history] };
    question.snoozed = false;

    const items = this.state.items.map(i => {
      if (i.id === question.question_result_id) {
        return question;
      }

      return i;
    });

    this.setState({ items });
  };

  handleAppointmentClick = appointment => {
    this.setState({ selectedAppointment: { id: appointment.id, dealer_location_id: appointment.dealer_location_id }, isLoading: true }, () =>
      this.props.getAppointment(appointment.id)
    );
  };

  handleUpdateSelectedAppointmentFromCarDetail = appointment_id => {
    this.setState({ selectedAppointment: { appointment_id, dealer_location_id: this.state.selectedCar.dealer_location_id }, isLoading: true }, () =>
      this.props.getAppointment(appointment_id)
    );
  };

  handleCloseAppointmentDetail = () => {
    this.setState({ selectedAppointment: null, isAppointmentDetailVisible: false });
    this.props.deselectAppointment();
  };

  handleCarClick = (car_id, dealer_location_id) => {
    this.setState(
      {
        selectedCar: { car_id, dealer_location_id },
        isLoading: true,
        isAppointmentDetailVisible: false,
        selectedAppointment: null,
      },
      () => {
        this.props.getCar(car_id);
      }
    );
  };

  handleCloseCarDetail = () => {
    this.setState({ selectedCar: null, isCarDetailVisible: false, selectedCarInfo: {} });
    this.props.deselectCar();
  };

  renderAppointmentDetail = () => {
    if (!this.state.isAppointmentDetailVisible || !this.props.appointmentsState.selectedAppointment) return null;

    const { authState, appointmentsState, globalState, getChecks, handleUpdateAppointments } = this.props;
    const { dealer, location } = getDealerAndLocationById(globalState.dealers, appointmentsState.selectedAppointment.dealer_location_id);

    return (
      <AppointmentDetail
        detailPageOnlineUsers={appointmentsState.detailPageOnlineUsers}
        currentUser={authState.user}
        appointment={appointmentsState.selectedAppointment}
        appointmentChecks={appointmentsState.selectedAppointmentChecks}
        location={location}
        dealer={dealer}
        getChecks={getChecks}
        onRegistrationClick={car_id => this.handleCarClick(car_id, appointmentsState.selectedAppointment.dealer_location_id)}
        onClose={this.handleCloseAppointmentDetail}
        onHandleUpdateAppointments={handleUpdateAppointments}
      />
    );
  };

  renderCarDetail = () => {
    const { selectedCarInfo } = this.state;
    const { globalState } = this.props;

    const location = getLocationById(globalState.dealers, selectedCarInfo.car.dealer_location_id);

    return (
      <CarDetail
        visible={true}
        car={selectedCarInfo.car}
        customer={selectedCarInfo.customer}
        appointmentHistory={selectedCarInfo.appointmentHistory}
        snoozedQuestions={selectedCarInfo.snoozedQuestions}
        location={location}
        onHide={() => this.handleCloseCarDetail()}
        onSelectAppointment={id => this.handleUpdateSelectedAppointmentFromCarDetail(id)}
      />
    );
  };

  handleRefresh = () => {
    this.getLeadsItems();
  };

  render() {
    const { authState, t } = this.props;
    const { csv_download } = authState.user;
    const {
      items,
      page,
      nb_pages,
      nb_items,
      tiresSizeOptions,
      tiresBrandOptions,
      tiresSpeedOptions,
      customDateFrom,
      customDateTo,
      selectedLocationsIDs,
      snoozed,
      selectedSize,
      selectedPosition,
      selectedProfile,
      isLoadingExport,
      exportURL,
      exportModalOpen,
      exportError,
      moreFiltersVisible,
      selectedStatuses,
      selectedSeasons,
      tiresWidthOptions,
      tiresHeightOptions,
      selectedWidth,
      selectedHeight,
      selectedBrand,
      selectedSpeed,
      selectedCustomerApproved,
      isAppointmentDetailVisible,
      isCarDetailVisible,
      isLoading,
      isLoadingTyreLeads,
      shouldReset,
    } = this.state;

    return (
      <>
        <SearchPortal>
          <Search
            minCharacters={4}
            className="-large-search"
            input={{
              icon: "search",
              iconPosition: "left",
              placeholder: t("start_searching_leads").message || "start searching leads by WO or registration...",
            }}
            loading={false}
            showNoResults={false}
            onSearchChange={_.debounce(this.handleSearchChange, 500)}
            fluid
          />
        </SearchPortal>
        <UserMenuActionsPortal>
          <Icon name="refresh" onClick={this.handleRefresh} />
        </UserMenuActionsPortal>
        <SubHeader>
          <Grid.Column width={16} className="header-container" style={{ justifyContent: "right" }}>
            <div className="export-btn-leads-type-container">
              <Form.Field>
                <Button icon size="small" labelPosition="left" className="reset-filters-btn" onClick={this.handleResetFilters}>
                  <Icon name="trash" color="green" style={{ backgroundColor: "white", borderRight: "1px solid #E8E8E8" }} />
                  <b>{t("reset_filter").message || "Reset Filter"}</b>
                </Button>
              </Form.Field>

              <Form.Field>
                <Button icon size="small" labelPosition="left" active={moreFiltersVisible} className="more-filters-btn" onClick={this.handleUpdateMoreFilters}>
                  <Icon name="filter" color="green" style={{ backgroundColor: "white", borderRight: "1px solid #E8E8E8" }} />
                  <b>{t("more_filters").message || "More Filters"}</b>
                </Button>
              </Form.Field>

              {csv_download && (
                <Popup
                  position="top right"
                  content={t("leads_export_disabled").message || "Export disabled as more than one location is selected."}
                  disabled={selectedLocationsIDs.length === 1}
                  trigger={
                    <div style={{ display: "inline-block" }}>
                      <Button onClick={this.handleExport} disabled={selectedLocationsIDs.length !== 1} className="export-button" floated="right" basic color="green">
                        <span style={{ marginRight: "5px" }}>{t("export").message || "Export"}</span>
                        <FontAwesomeIcon icon={faFileExport} />
                      </Button>
                    </div>
                  }
                />
              )}
            </div>

            <Modal size="tiny" open={exportModalOpen} onClose={this.handleCloseExportModal} closeOnDimmerClick={false} className="ExportLeads-Modal">
              <Modal.Content className="export-modal-content">
                {isLoadingExport && <p>{t("export_in_progress").message || "Export in progress..."}</p>}
                {!isLoadingExport && exportURL && <p>{t("export_ready").message || "Export ready to be downloaded"}</p>}
                {!isLoadingExport && (exportError || !exportURL) && <p>{t("failed_error_message").message || "Something went wrong, please try again."}</p>}
              </Modal.Content>
              <Modal.Actions>
                <Button onClick={this.handleCloseExportModal} color="grey">
                  {t("close").message || "Close"}
                </Button>
                <Button onClick={() => window.open(exportURL, "_blank")} disabled={isLoadingExport || !exportURL} color="green">
                  {t("download").message || "Download"}
                </Button>
              </Modal.Actions>
            </Modal>
          </Grid.Column>
          <Grid.Column width={16}>
            <TireFilters
              customDateFrom={customDateFrom}
              customDateTo={customDateTo}
              onChangeCustomDates={this.handleChangeCustomDate}
              selectedStatuses={selectedStatuses}
              onChangeStatus={this.handleChangeStatus}
              selectedSeasons={selectedSeasons}
              onChangeSeason={this.handleChangeSeasons}
              snoozed={snoozed}
              onChangeSnoozed={this.handleChangeSnoozed}
              selectedProfile={selectedProfile}
              onChangeTiresProfile={this.handleChangeTiresProfile}
              selectedPosition={selectedPosition}
              onChangeTiresPosition={this.handleChangeTiresPosition}
              selectedSize={selectedSize}
              onChangeTiresSize={this.handleChangeTiresSize}
              selectedWidth={selectedWidth}
              onChangeTiresWidth={this.handleChangeTiresWidth}
              onChangeTiresHeight={this.handleChangeTiresHeight}
              tiresSizeOptions={tiresSizeOptions}
              tiresWidthOptions={tiresWidthOptions}
              selectedHeight={selectedHeight}
              tiresHeightOptions={tiresHeightOptions}
              selectedCustomerApproved={selectedCustomerApproved}
              onChangeCustomerApproved={this.handleChangeCustomerApproved}
              onChangeBrand={this.handleChangeBrand}
              selectedBrand={selectedBrand}
              onChangeSpeed={this.handleChangeSpeed}
              selectedSpeed={selectedSpeed}
              tiresBrandOptions={tiresBrandOptions}
              tiresSpeedOptions={tiresSpeedOptions}
              onChangeSelectedLocationIDs={this.handleChangeSelectedLocationsIDs}
              moreFiltersVisible={moreFiltersVisible}
              shouldReset={shouldReset}
            />
          </Grid.Column>
        </SubHeader>

        <TireTable
          showRegNr
          page={page}
          pages={nb_pages}
          nb_items={nb_items}
          loading={isLoadingTyreLeads}
          onFetchData={this.getLeadsItems}
          onSnoozeDeleted={this.handleSnoozeDeleted}
          onSnoozeChanged={this.handleSnoozeChanged}
          items={items}
          onAppointmentClick={this.handleAppointmentClick}
          onCarClick={this.handleCarClick}
        />

        {isAppointmentDetailVisible && this.renderAppointmentDetail()}
        {isCarDetailVisible && this.renderCarDetail()}

        {(isLoading || isLoadingTyreLeads) && (
          <div className="Loader">
            <Loader type="Oval" color="#46923d" height="100" width="100" />
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = state => {
  return { globalState: state.global, appointmentsState: state.appointments, carsState: state.cars, authState: state.auth };
};

const mapDispatchToProps = dispatch => {
  return {
    applyUpdateToAppointment: (updates, skipMainTableUpdate) => dispatch(applyUpdateToAppointment(updates, skipMainTableUpdate)),
    deselectAppointment: () => dispatch(deselectAppointment()),
    deselectCar: () => dispatch(deselectCar()),
    getAppointment: appID => dispatch(getAppointment(appID)),
    getCar: carID => dispatch(getCar(carID)),
    getChecks: appID => dispatch(getChecks(appID)),
    handleUpdateAppointments: appointment => dispatch(handleUpdateAppointments(appointment)),
    setAlert: alertOptions => dispatch(setAlert(alertOptions)),
  };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(TiresLeads));
