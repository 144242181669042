import React, { Component, createRef } from "react";
import { Button, Icon } from "semantic-ui-react";
import TextareaAutosize from "react-textarea-autosize";
import NumberFormat from "react-number-format";

import UserInput from "../UserInput";

import "./InlineInputIcon.css";

const getInitialDisplayedValue = (value, displayValueFunc) => {
  if (!displayValueFunc) {
    return value;
  }
  return displayValueFunc(value);
};

class InlineInputIcon extends Component {
  constructor(props) {
    super(props);

    this.state = {
      initialValue: this.props.value,
      newValue: this.props.value || (["number", "price"].includes(this.props.type) ? null : ""),
      displayedValue: getInitialDisplayedValue(this.props.value, this.props.displayValueFunc),
      icon: null,
      error: false,
      overflow: false,
      expanded: false,
    };

    this.textAreaRef = createRef();
  }

  componentDidMount() {
    if (this.textAreaRef) this.setOverflow();
  }

  componentWillReceiveProps = nextProps => {
    if (nextProps.value !== this.state.newValue && nextProps.value !== this.state.initialValue) {
      let newValue = nextProps.value || (["price", "number"].includes(nextProps.type) ? null : "");
      this.setState({ initialValue: newValue, newValue, displayedValue: getInitialDisplayedValue(newValue, nextProps.displayValueFunc) });
    } else if (nextProps.value === this.state.newValue) {
      this.setState({ icon: null });
    }
  };

  limitToScale(numStr, scale, fixedDecimalScale) {
    let str = "";
    const filler = fixedDecimalScale ? "0" : "";

    for (let i = 0; i <= scale - 1; i++) {
      str += numStr[i] || filler;
    }

    return str;
  }

  splitDecimal(numStr, allowNegative) {
    const hasNegation = numStr[0] === "-";
    const addNegation = hasNegation && allowNegative;
    numStr = numStr.replace("-", "");
    const parts = numStr.split(".");
    return {
      beforeDecimal: parts[0],
      afterDecimal: parts[1] || "",
      hasNegation,
      addNegation,
    };
  }

  formatAsNumber = numStr => {
    const { config } = this.props;

    let decimalScale = 2,
      fixedDecimalScale = true,
      prefix = config?.prefix || "",
      suffix = config?.suffix || "",
      allowNegative = config?.allowNegative || true,
      thousandSeparator = config.thousandSeparator || "",
      decimalSeparator = config.decimalSeperator || ".";

    let _splitDecimal = this.splitDecimal(numStr, allowNegative),
      beforeDecimal = _splitDecimal.beforeDecimal,
      afterDecimal = _splitDecimal.afterDecimal,
      addNegation = _splitDecimal.addNegation;

    afterDecimal = this.limitToScale(afterDecimal, decimalScale, fixedDecimalScale);

    if (thousandSeparator) {
      beforeDecimal = beforeDecimal.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1" + thousandSeparator);
    }

    if (prefix) beforeDecimal = prefix + beforeDecimal;
    if (suffix) afterDecimal = afterDecimal + suffix; //restore negation sign

    if (addNegation) beforeDecimal = "-" + beforeDecimal;
    numStr = beforeDecimal + (decimalSeparator || "") + afterDecimal;
    return numStr;
  };

  parsePrice = price => {
    if (price.match(/^(\d{1,3}\.)*\d{3},\d{1,2}$/)) {
      price = price.replace(".", "");
    }

    if (price.match(/,\d{1,2}$/)) price = price.replace(",", ".");

    price = this.formatAsNumber(price);
    return price.replace(",", "");
  };

  handleNumberChange = e => {
    let newValue = e.floatValue;
    let displayedValue = e.value;

    const invalidNumberValue = this.props.invalidNumberValue && this.props.invalidNumberValue(newValue);

    if (newValue < 0 || e.value === "-0" || invalidNumberValue) {
      this.setState({ error: true });
    } else {
      this.setState({ error: false });
    }

    this.handleInputChange(newValue, displayedValue);
  };

  handleOnChange = e => {
    let newValue = e.target.value;
    let displayedValue = e.target.value;

    this.handleInputChange(newValue, displayedValue);
  };

  handleInputChange(newValue, displayedValue) {
    let shouldShowIcon = this.props.value !== newValue;
    let oldIcon = this.state.icon;

    this.setState(
      {
        newValue,
        displayedValue,
        icon: shouldShowIcon ? this.props.icon : null,
      },
      () => {
        if (!this.props.noIcon) {
          if (oldIcon == null && shouldShowIcon) {
            document.dispatchEvent(new CustomEvent("inputChanged", { detail: true }));
          } else if (oldIcon && !shouldShowIcon) {
            document.dispatchEvent(new CustomEvent("inputChanged", { detail: false }));
          }
        }

        this.props.onChange && this.props.onChange(newValue);
      }
    );
  }

  handleSave = () => {
    if (!this.state.error && !this.props.noIcon && this.state.icon) {
      this.setState({ icon: null, initialValue: this.state.newValue }, () => {
        this.props.onSave(this.state.newValue);

        this.setOverflow();
        this.setState({ expanded: false });
      });
    }
  };

  handleEnterKey = e => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      this.handleSave();
      e.target.blur();
    }
  };

  handleOnFocus = () => {
    if (this.props.isImportantItem) this.setState({ overflow: false });
  };

  setOverflow = e => {
    if (this.props.isImportantItem) {
      let overflow = this.textAreaRef?.scrollHeight > 34;
      if (e?.target) overflow = e.target.scrollHeight > 34;
      this.setState({ overflow });
    }
  };

  toggleExpanded = () => this.setState(prev => ({ expanded: !prev.expanded }));

  render() {
    const { disabled, placeholder, config, icon, iconConfig, textAreaClassName, textAreaWidth, firstIcon, firstIconPosition, type, isImportantItem, noIcon } = this.props;

    const { expanded, overflow } = this.state;

    return (
      <div className="InlineInput">
        {(type === "text" || type === "number" || type === "price") && (
          <UserInput
            disabled={!!disabled}
            type="text"
            placeholder={placeholder}
            action
            style={this.state.error ? { border: "1px solid #E84D33", borderRadius: "4px" } : {}}
            iconPosition={firstIconPosition}
          >
            {firstIcon && <Icon name={firstIcon} />}

            {type === "number" && (
              <NumberFormat
                onKeyPress={e => {
                  this.handleEnterKey(e, true);
                }}
                onValueChange={this.handleNumberChange}
                value={this.state.displayedValue}
                {...config}
              />
            )}

            {type === "price" && (
              <NumberFormat
                onKeyPress={e => {
                  this.handleEnterKey(e, true);
                }}
                onValueChange={this.handleNumberChange}
                value={this.state.displayedValue}
                format={val => this.parsePrice(val)}
                removeFormatting={val => val}
                decimalScale={2}
                fixedDecimalScale
                {...config}
              />
            )}

            {type === "text" && (
              <UserInput
                onKeyPress={e => {
                  this.handleEnterKey(e, true);
                }}
                value={this.state.displayedValue}
                onChange={this.handleOnChange}
                {...config}
              />
            )}

            {!this.state.error && !noIcon && (
              <Button
                type="submit"
                style={{
                  padding: "0",
                }}
                disabled={!this.state.icon}
                onClick={this.handleSave}
              >
                {this.state.icon && <Icon name={icon} />}
              </Button>
            )}
          </UserInput>
        )}
        {type === "textarea" && (
          <span className="InlineInputIconTextareaSpan">
            {firstIcon && <Icon name={firstIcon} />}
            <TextareaAutosize
              onKeyPress={this.handleEnterKey}
              className={`InlineInputIconTextarea ${textAreaClassName} ${isImportantItem && overflow && !expanded ? "-collapsed" : ""}`}
              disabled={!!disabled}
              ref={ref => (this.textAreaRef = ref ? ref._ref : null)}
              rows="1"
              placeholder={placeholder}
              style={{
                width: textAreaWidth || "90%",
                minHeight: "19px",
              }}
              value={this.state.displayedValue}
              onChange={this.handleOnChange}
              onFocus={this.handleOnFocus}
              onBlur={isImportantItem && !this.state.icon ? this.setOverflow : null}
              {...config}
            />
            {!noIcon && (
              <Button
                type="submit"
                style={{
                  padding: "0",
                }}
                disabled={!this.state.icon}
                onClick={this.handleSave}
              >
                {this.state.icon && <Icon name={icon} {...iconConfig} />}
              </Button>
            )}
          </span>
        )}
        {isImportantItem && overflow && <Icon style={{ cursor: "pointer" }} name={expanded ? "angle up" : "angle down"} onClick={() => this.toggleExpanded()} />}
      </div>
    );
  }
}

export default InlineInputIcon;
