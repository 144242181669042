import React from "react";
import { Dropdown, Form, Checkbox, Button, Icon } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCar, faWarehouse } from "@fortawesome/free-solid-svg-icons";
import { faSun, faSnowflake, faSunDust } from "@fortawesome/pro-regular-svg-icons";
import moment from "moment";
import DatePicker from "react-datepicker";

import { UserInput } from "../../../components";
import { TYRE_POSITION, SEASONS, QUESTION_RESULT_STATUS } from "../../Appointments/common";
import DealerLocationsDropdown from "../../../components/DealerLocationsDropdown";

const TIRES_ON_CAR = [TYRE_POSITION.CAR_FRONT_LEFT, TYRE_POSITION.CAR_FRONT_RIGHT, TYRE_POSITION.CAR_REAR_LEFT, TYRE_POSITION.CAR_REAR_RIGHT];
const TIRES_IN_STORAGE = [TYRE_POSITION.STORAGE_FRONT_LEFT, TYRE_POSITION.STORAGE_FRONT_RIGHT, TYRE_POSITION.STORAGE_REAR_LEFT, TYRE_POSITION.STORAGE_REAR_RIGHT];

const TireFilters = ({
  selectedStatuses,
  selectedSeasons,
  snoozed,
  customDateFrom,
  customDateTo,
  tiresSizeOptions,
  selectedSize,
  tiresWidthOptions,
  tiresHeightOptions,
  tiresSpeedOptions,
  tiresBrandOptions,
  selectedProfile,
  selectedPosition,
  selectedWidth,
  selectedHeight,
  selectedBrand,
  selectedSpeed,

  selectedCustomerApproved,
  onChangeCustomerApproved,
  moreFiltersVisible,
  onChangeCustomDates,
  onChangeStatus,
  onChangeSeason,
  onChangeTiresSize,
  onChangeTiresProfile,
  onChangeTiresWidth,
  onChangeTiresHeight,
  onChangeTiresPosition,
  onChangeSnoozed,
  onChangeBrand,
  onChangeSpeed,
  onChangeSelectedLocationIDs,
  shouldReset,
  t,
}) => {
  return (
    <Form>
      <Form.Group>
        <Form.Field width={4}>
          <DealerLocationsDropdown selectAll={false} selectDealers={true} onChange={onChangeSelectedLocationIDs} shouldReset={shouldReset} />
        </Form.Field>

        <Form.Field width={3} style={{ position: "relative" }}>
          <DatePicker
            selectsRange
            startDate={customDateFrom}
            endDate={customDateTo}
            onChange={onChangeCustomDates}
            value={`${moment(customDateFrom).format("DD-MM-YYYY")}${customDateTo ? " - " + moment(customDateTo).format("DD-MM-YYYY") : ""}`}
            maxDate={moment(customDateFrom).add(12, "M").toDate()}
            shouldCloseOnSelect={false}
          />
        </Form.Field>

        <Form.Field width={1}>
          <Button.Group className="status-group">
            <Button
              icon
              active={selectedPosition.some(position => TYRE_POSITION.IS_IN_STORAGE(position))}
              style={{
                color: selectedPosition.some(position => TYRE_POSITION.IS_IN_STORAGE(position)) ? "#21BA45" : "#767676",
                backgroundColor: selectedPosition.some(position => TYRE_POSITION.IS_IN_STORAGE(position)) ? "#21BA4533" : "#FFF",
                border: selectedPosition.some(position => TYRE_POSITION.IS_IN_STORAGE(position)) ? "1px solid #21BA45" : "1px solid grey",
                padding: "8.3px",
              }}
              onClick={() => onChangeTiresPosition(TIRES_IN_STORAGE)}
            >
              <FontAwesomeIcon icon={faWarehouse} />
            </Button>
            <Button
              icon
              active={selectedPosition.some(position => TYRE_POSITION.IS_ON_CAR(position))}
              style={{
                color: selectedPosition.some(position => TYRE_POSITION.IS_ON_CAR(position)) ? "#21BA45" : "#767676",
                backgroundColor: selectedPosition.some(position => TYRE_POSITION.IS_ON_CAR(position)) ? "#21BA4533" : "#FFF",
                border: selectedPosition.some(position => TYRE_POSITION.IS_ON_CAR(position)) ? "1px solid #21BA45" : "1px solid grey",
                padding: "8.3px",
              }}
              onClick={() => onChangeTiresPosition(TIRES_ON_CAR)}
            >
              <FontAwesomeIcon icon={faCar} />
            </Button>
          </Button.Group>
        </Form.Field>

        <Form.Field width={3} inline className="tire_profile">
          <label>{t("profile_max").message || "Profile Max"}</label>
          <UserInput type="number" onChange={onChangeTiresProfile} value={selectedProfile} step={0.1} style={{ width: "45%", marginRight: "3px" }} />

          <span>{"mm"}</span>
        </Form.Field>

        <Form.Field width={2} style={{ textAlign: "center" }}>
          <Button.Group className="status-group">
            <Button
              icon
              active={selectedStatuses.includes(QUESTION_RESULT_STATUS.OK)}
              style={{
                color: "#21BA45",
                backgroundColor: selectedStatuses.includes(QUESTION_RESULT_STATUS.OK) ? "#21BA4533" : "#FFF",
                border: selectedStatuses.includes(QUESTION_RESULT_STATUS.OK) ? "1px solid #21BA45" : "1px solid grey",
                padding: "8.3px",
              }}
              onClick={() => {
                onChangeStatus(QUESTION_RESULT_STATUS.OK);
              }}
            >
              <Icon name="checkmark" />
            </Button>
            <Button
              icon
              active={selectedStatuses.includes(QUESTION_RESULT_STATUS.OK_WITH_REMARKS)}
              style={{
                color: "#21BA45",
                backgroundColor: selectedStatuses.includes(QUESTION_RESULT_STATUS.OK_WITH_REMARKS) ? "#21BA4533" : "#FFF",
                border: selectedStatuses.includes(QUESTION_RESULT_STATUS.OK_WITH_REMARKS) ? "1px solid #21BA45" : "1px solid grey",
                padding: "8.3px",
              }}
              onClick={() => {
                onChangeStatus(QUESTION_RESULT_STATUS.OK_WITH_REMARKS);
              }}
            >
              <Icon name="file" />
            </Button>
            <Button
              icon
              active={selectedStatuses.includes(QUESTION_RESULT_STATUS.ADVISED)}
              style={{
                color: "#FFBA39",
                backgroundColor: selectedStatuses.includes(QUESTION_RESULT_STATUS.ADVISED) ? "#FFBA3933" : "#FFF",
                border: selectedStatuses.includes(QUESTION_RESULT_STATUS.ADVISED) ? "1px solid #FFBA39" : "1px solid grey",
                padding: "8.3px",
              }}
              onClick={() => {
                onChangeStatus(QUESTION_RESULT_STATUS.ADVISED);
              }}
            >
              <Icon name="attention" />
            </Button>
            <Button
              icon
              active={selectedStatuses.includes(QUESTION_RESULT_STATUS.NECESSARY)}
              style={{
                color: "#FF0202",
                backgroundColor: selectedStatuses.includes(QUESTION_RESULT_STATUS.NECESSARY) ? "#FF020233" : "#FFF",
                border: selectedStatuses.includes(QUESTION_RESULT_STATUS.NECESSARY) ? "1px solid #FF0202" : "1px solid grey",
                padding: "8.3px",
              }}
              onClick={() => {
                onChangeStatus(QUESTION_RESULT_STATUS.NECESSARY);
              }}
            >
              <Icon name="dont" />
            </Button>
          </Button.Group>
        </Form.Field>

        <Form.Field width={2} className="leads-toggle">
          <Checkbox toggle onChange={onChangeSnoozed} checked={snoozed} label={t("snoozed").message || "Snoozed"} />
        </Form.Field>

        <Form.Field width={3} className="leads-toggle">
          <Checkbox
            toggle
            checked={selectedCustomerApproved}
            label={t("customer_approved").message || "Customer Approved"}
            onChange={(e, data) => onChangeCustomerApproved(data.checked)}
          />
        </Form.Field>
      </Form.Group>

      {moreFiltersVisible && (
        <Form.Group>
          <>
            <Form.Field width={2}>
              <Button.Group className="status-group">
                <Button
                  icon
                  active={selectedSeasons.includes(SEASONS.SUMMER)}
                  style={{
                    color: "white",
                    backgroundColor: selectedSeasons.includes(SEASONS.SUMMER) ? "#FFBA3933" : "#FFF",
                    border: selectedSeasons.includes(SEASONS.SUMMER) ? "1px solid #FFBA39" : "1px solid grey",
                    padding: "8.3px",
                  }}
                  onClick={() => {
                    onChangeSeason(SEASONS.SUMMER);
                  }}
                >
                  <FontAwesomeIcon icon={faSun} style={{ color: "#FFBA00" }} />
                </Button>
                <Button
                  icon
                  active={selectedSeasons.includes(SEASONS.WINTER)}
                  style={{
                    color: "white",
                    backgroundColor: selectedSeasons.includes(SEASONS.WINTER) ? "#2185D033" : "#FFF",
                    border: selectedSeasons.includes(SEASONS.WINTER) ? "1px solid #2185D0" : "1px solid grey",
                    padding: "8.3px",
                  }}
                  onClick={() => {
                    onChangeSeason(SEASONS.WINTER);
                  }}
                >
                  <FontAwesomeIcon icon={faSnowflake} style={{ color: "#2185D0" }} />
                </Button>
                <Button
                  icon
                  active={selectedSeasons.includes(SEASONS.ALL_SEASONS)}
                  style={{
                    color: "white",
                    backgroundColor: selectedSeasons.includes(SEASONS.ALL_SEASONS) ? "#21BA4533" : "#FFF",
                    border: selectedSeasons.includes(SEASONS.ALL_SEASONS) ? "1px solid #2185D0" : "1px solid grey",
                    padding: "8.3px",
                  }}
                  onClick={() => {
                    onChangeSeason(SEASONS.ALL_SEASONS);
                  }}
                >
                  <FontAwesomeIcon icon={faSunDust} style={{ color: "#21BA45" }} />
                </Button>
              </Button.Group>
            </Form.Field>

            <Form.Field width={3}>
              <Dropdown
                selection
                selectOnBlur={false}
                clearable
                fluid
                placeholder={t("size").message || "Size"}
                options={tiresSizeOptions}
                value={selectedSize}
                onChange={(e, data) => onChangeTiresSize(data.value)}
              />
            </Form.Field>

            <Form.Field width={3}>
              <Dropdown
                selection
                selectOnBlur={false}
                clearable
                fluid
                disabled={!selectedSize}
                placeholder={t("width").message || "Width"}
                value={selectedWidth}
                onChange={(e, data) => onChangeTiresWidth(data.value)}
                options={tiresWidthOptions}
              />
            </Form.Field>

            <Form.Field width={3}>
              <Dropdown
                selection
                selectOnBlur={false}
                clearable
                fluid
                disabled={!selectedWidth}
                placeholder={t("height").message || "Height"}
                onChange={(e, data) => onChangeTiresHeight(data.value)}
                options={tiresHeightOptions}
                value={selectedHeight}
              />
            </Form.Field>

            <Form.Field width={3}>
              <Dropdown
                selection
                selectOnBlur={false}
                clearable
                fluid
                placeholder={t("speed").message || "Speed"}
                options={tiresSpeedOptions}
                onChange={(e, data) => onChangeSpeed(data.value)}
                value={selectedSpeed}
              />
            </Form.Field>

            <Form.Field width={3}>
              <Dropdown
                selection
                selectOnBlur={false}
                clearable
                fluid
                placeholder={t("brandh").message || "Brand"}
                options={tiresBrandOptions}
                onChange={(e, data) => onChangeBrand(data.value)}
                value={selectedBrand}
              />
            </Form.Field>
          </>
        </Form.Group>
      )}
    </Form>
  );
};

export default withTranslation()(TireFilters);
