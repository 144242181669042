import React from "react";
import { withTranslation } from "react-i18next";
import Loader from "react-loader-spinner";

import { PinTable } from "../../components";
import Service from "./service";

class CarDetailPins extends React.Component {
  state = {
    isLoadingPinItems: false,
    nb_pages: null,
    page: 0,
    pinItems: [],
  };

  componentDidMount() {
    this.loadPinItems();
  }

  loadPinItems = page => {
    if (!page) page = 1;
    else if (page === this.state.page || this.state.isLoadingPinItems) return;

    this.setState({ isLoadingPinItems: true }, () => {
      Service.getPinsByCarId({ page, car_id: this.props.car.id })
        .then(result => {
          const data = result.data.data ? result.data.data : { items: [], nb_pages: 0 };
          let { items, nb_pages } = data;

          if (!items) items = [];

          let grouped = [];

          let interventions = [],
            questions = [],
            interventions_offsets = [],
            questions_offsets = [],
            intervention_offset = 0,
            question_offset = 0;

          items.forEach(i => {
            if (i.intervention_id) {
              if (interventions_offsets[i.intervention_id] === undefined) {
                interventions_offsets[i.intervention_id] = intervention_offset++;

                interventions[interventions_offsets[i.intervention_id]] = { ...i, history: [i] };
              } else {
                if (i.created_on > interventions[interventions_offsets[i.intervention_id]].created_on)
                  interventions[interventions_offsets[i.intervention_id]] = {
                    ...i,

                    history: interventions[interventions_offsets[i.intervention_id]].history.concat(i),
                  };
                else interventions[interventions_offsets[i.intervention_id]].history.push(i);
              }
            } else {
              if (questions_offsets[i.question_result_id] === undefined) {
                questions_offsets[i.question_result_id] = question_offset++;

                questions[questions_offsets[i.question_result_id]] = { ...i, history: [i] };
              } else {
                if (i.created_on > questions[questions_offsets[i.question_result_id]].created_on)
                  questions[questions_offsets[i.question_result_id]] = { ...i, history: questions[questions_offsets[i.question_result_id]].history.concat(i) };
                else questions[questions_offsets[i.question_result_id]].history.push(i);
              }
            }
          });

          grouped = [...interventions, ...questions];

          grouped.sort((a, b) => (b.appointment.time_car_app > a.appointment.time_car_app ? 1 : -1));

          this.setState({ isLoadingPinItems: false, pinItems: grouped, page, nb_pages });
        })

        .catch(error => {
          console.log("Error getting pin items", error);
          this.setState({ isLoadingPinItems: false });
        });
    });
  };

  handleLogChanged = updatedPinLog => {
    let { pinItems } = this.state;

    const updatedItem = updatedPinLog[0];

    pinItems = pinItems.map(i => {
      if ((i.question_result_id && i.question_result_id === updatedItem.question_result_id) || (i.intervention_id && i.intervention_id === updatedItem.intervention_id)) {
        return { ...updatedItem, history: updatedPinLog };
      }

      return i;
    });

    this.setState({ pinItems });
  };

  handlePinDeleted = groupedItem => {
    const pinItems = this.state.pinItems.map(i => {
      if ((i.question_result_id && i.question_result_id === groupedItem.question_result_id) || (i.intervention_id && i.intervention_id === groupedItem.intervention_id)) {
        i.history = [];
        i.pin_status_id = i.warranty_type_id = i.pin_type_id = i.updated_on = null;
        i.support_nr = i.ref_nr = i.claim_nr = i.note = "";
        if (i.question_result) i.question_result.pinned = false;
        else if (i.intervention) i.intervention.pinned = false;
      }

      return i;
    });

    this.setState({ pinItems });
  };

  render() {
    const { t, car } = this.props;
    const { isLoadingPinItems, page, nb_pages, pinItems } = this.state;

    return (
      <div className="CarDetailContainer">
        <h3>{t("pin_items").message || "Pin items"}</h3>
        {isLoadingPinItems && pinItems.length < 1 && (
          <div className="DataLoader">
            <Loader type="Oval" color="#46923d" height="100" width="100" />
          </div>
        )}
        {!isLoadingPinItems && pinItems.length < 1 && <div className="NoDataAvailable">{t("no_data_available").message || "No data available"}</div>}
        {pinItems.length > 0 && (
          <PinTable
            externalLink
            loading={isLoadingPinItems}
            onFetchData={this.loadPinItems}
            page={page}
            pages={nb_pages}
            items={pinItems}
            car={car}
            onLogChanged={this.handleLogChanged}
            onPinDeleted={this.handlePinDeleted}
          />
        )}
      </div>
    );
  }
}

export default withTranslation()(CarDetailPins);
