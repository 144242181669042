import Browser from "./browser";
import { cleanUp } from "./functions";

const Print = {
  send: (params, printFrame) => {
    document.getElementsByTagName("body")[0].appendChild(printFrame);

    const iframeElement = document.getElementById(params.frameId);

    iframeElement.onload = () => {
      if (params.type === "pdf") {
        performPrint(iframeElement, params);
        return;
      }

      let printDocument = iframeElement.contentWindow || iframeElement.contentDocument;
      if (printDocument.document) printDocument = printDocument.document;

      if (params.type === "full-raw-html") {
        printDocument.open();
        printDocument.write(params.printableElement);
        printDocument.close();
      } else printDocument.body.appendChild(params.printableElement);

      if (params.type !== "pdf" && params.style) {
        const style = document.createElement("style");
        style.innerHTML = params.style;

        printDocument.head.appendChild(style);
      }

      let promises = [];
      let loadedLinks = 0;
      let linksToLoad = 0;
      let linkLoaded = () => {
        loadedLinks++;
      };

      if (params.copyStyles) {
        document.querySelectorAll("style").forEach(style => printDocument.head.appendChild(style.cloneNode(true)));
        document.querySelectorAll("link[rel='stylesheet']").forEach(style => {
          let el = style.cloneNode(true);
          linksToLoad++;
          el.onload = linkLoaded;
          el.onerror = linkLoaded;
          printDocument.head.appendChild(el);
        });

        promises.push(
          new Promise(resolve => {
            const pollLink = () => {
              linksToLoad > loadedLinks ? setTimeout(pollLink, 500) : resolve();
            };

            pollLink();
          })
        );
      }

      const images = Array.from(printDocument.getElementsByTagName("img"));

      if (images.length > 0) {
        promises.push(...loadIframeImages(images));
      }

      if (promises.length > 0) {
        Promise.all(promises)
          .then(() => performPrint(iframeElement, params))
          .catch(err => console.log(err));
      } else {
        performPrint(iframeElement, params);
      }
    };
  },
};

function performPrint(iframeElement, params) {
  try {
    iframeElement.focus();

    if (Browser.isEdge() || Browser.isIE()) {
      try {
        iframeElement.contentWindow.document.execCommand("print", false, null);
      } catch (e) {
        iframeElement.contentWindow.print();
      }
    } else {
      iframeElement.contentWindow.print();
    }
  } catch (error) {
    params.onError(error);
  } finally {
    cleanUp(params);
  }
}

function loadIframeImages(images) {
  const promises = images.map(image => {
    if (image.src && image.src !== window.location.href && image.src !== window.location.href.replace(window.location.hash, "")) {
      return loadIframeImage(image);
    } else return null;
  });

  return promises;
}

function loadIframeImage(image) {
  return new Promise(resolve => {
    const pollImage = () => {
      !image || typeof image.naturalWidth === "undefined" || image.naturalWidth === 0 || !image.complete ? setTimeout(pollImage, 500) : resolve();
    };
    pollImage();
  });
}

export default Print;
