import React, { useState } from "react";
import { Grid, Modal, Button, Checkbox, Icon, Label } from "semantic-ui-react";
import Lightbox from "./react-image-lightbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStickyNote } from "@fortawesome/pro-regular-svg-icons";
import { faCheckCircle, faTimesCircle, faEdit } from "@fortawesome/pro-solid-svg-icons";
import { withTranslation } from "react-i18next";

import { Can } from "../../modules";

import "./CCRemarks.css";

const CCRemarks = ({ disabled, t, remarks, onlyVisible, onHandleCommunication, onUpdateRemark, canCreateIntervention, title, resource, interventions }) => {
  const [mediaVisible, setMediaVisible] = useState([]);
  const [galleryVisible, setGalleryVisible] = useState(false);
  const [media, setMedia] = useState([]);
  const [currentMedia, setCurrentMedia] = useState(null);
  const [mediaType, setMediaType] = useState(null);
  const [editedRemark, setEditedRemark] = useState(null);
  const [isUpdatingRemark, setIsUpdatingRemark] = useState(false);

  if (!Array.isArray(remarks) || !remarks.length) return null;

  if (!!onlyVisible) remarks = remarks.filter(r => r.visible);

  const renderImageGallery = () => {
    if (!galleryVisible || !mediaVisible.length) return null;

    if (mediaType === "video") {
      return (
        <Lightbox
          mainCustomContent={media[currentMedia]}
          prevCustomContent={media[(currentMedia + 1) % media.length]}
          nextCustomContent={media[(currentMedia + media.length - 1) % media.length]}
          onCloseRequest={() => setGalleryVisible(false)}
          onMovePrevRequest={() => setCurrentMedia((currentMedia + media.length - 1) % media.length)}
          onMoveNextRequest={() => setCurrentMedia((currentMedia + 1) % media.length)}
        />
      );
    } else {
      return (
        <Lightbox
          mainSrc={media[currentMedia].url}
          nextSrc={media[(currentMedia + 1) % media.length].url}
          prevSrc={media[(currentMedia + media.length - 1) % media.length].url}
          onCloseRequest={() => setGalleryVisible(false)}
          onMovePrevRequest={() => setCurrentMedia((currentMedia + media.length - 1) % media.length)}
          onMoveNextRequest={() => setCurrentMedia((currentMedia + 1) % media.length)}
        />
      );
    }
  };

  const renderMedia = attachments => {
    const images = attachments.filter(a => a.url.match(/\.(jpeg|jpg|gif|png)/));
    const videos = attachments.filter(a => a.url.match(/\.(mp4|mov|mpeg|mkv|webm)/));
    const files = attachments.filter(a => !a.url.match(/\.(jpeg|jpg|gif|png)/) && !a.url.match(/\.(mp4|mov|mpeg|mkv|webm)/));
    const videoList = videos.map(vid => (
      <video controls className="lightbox__video">
        <source src={vid.url} type="video/mp4" />
      </video>
    ));

    return (
      <Grid.Column width={16} className="remark__media">
        <div>
          {images.map((img, k) => (
            <div className="remark__media__Wrapper" key={"img_" + k}>
              <img
                alt="Attachement"
                src={img.url}
                onClick={() => {
                  setCurrentMedia(k);
                  setMedia(images);
                  setMediaType("image");
                  setGalleryVisible(true);
                }}
              />

              <section>
                <a href={img.url} rel="noopener noreferrer" target="_blank">
                  <Icon name="download" color="blue" className="-pull-right -margin-right-10" />
                </a>
              </section>
            </div>
          ))}

          {videos.map((vid, k) => (
            <div className="remark__media__Wrapper" key={"vid_" + k}>
              <video
                onClick={() => {
                  setCurrentMedia(k);
                  setMedia(videoList);
                  setMediaType("video");
                  setGalleryVisible(true);
                }}
              >
                <source src={vid.url} type="video/mp4" />
                Your browser does not support the video tag.
              </video>

              <section>
                <a href={vid.url} rel="noopener noreferrer" target="_blank">
                  <Icon name="download" color="blue" className="-pull-right -margin-right-10" />
                </a>
              </section>
            </div>
          ))}

          {files.map((file, k) => (
            <div className="remark__media__Wrapper" key={"img_" + k}>
              <div className="file-other">
                <Icon name="file" />
                <p>{file.name.length > 14 ? `...${file.name.slice(-14)}` : file.name}</p>
              </div>
              <section>
                <a href={file.url} rel="noopener noreferrer" target="_blank">
                  <Icon name="download" color="blue" className="-pull-right -margin-right-10" />
                </a>
              </section>
            </div>
          ))}
        </div>
      </Grid.Column>
    );
  };

  const handleChangeEditedRemarkVisibleToMechanic = (_, { checked }) => {
    setEditedRemark(prevEditedRemark => ({ ...prevEditedRemark, visible_to_mechanic: checked }));
  };

  const handleUpdateRemark = async () => {
    if (!editedRemark) return;

    setIsUpdatingRemark(true);
    await onUpdateRemark(editedRemark);
    setEditedRemark(null);
    setIsUpdatingRemark(false);
  };

  const renderEditRemarkModal = () => {
    return (
      <Modal open={!!editedRemark}>
        <Modal.Header>{title}</Modal.Header>

        <Modal.Content>
          <label style={{ fontWeight: "bold" }}>{t("note").message || "Note"}</label>
          <div className="edit-remark-note">
            <div>{editedRemark?.title}</div>
            <div>{editedRemark?.description}</div>
          </div>
          <div style={{ textAlign: "right", marginTop: "10px" }}>
            <Checkbox
              toggle
              checked={editedRemark?.visible_to_mechanic}
              onChange={handleChangeEditedRemarkVisibleToMechanic}
              label={t("visible_to_mechanic").message || "Visible to Mechanic"}
            />
          </div>
        </Modal.Content>

        <Modal.Actions>
          <Button onClick={() => setEditedRemark(null)} disabled={isUpdatingRemark} color="grey">
            <Icon name="close" />
            {t("cancel").message || "Cancel"}
          </Button>

          <Button color="green" icon labelPosition="right" onClick={handleUpdateRemark} loading={isUpdatingRemark} disabled={isUpdatingRemark}>
            <Icon name="check" />

            {t("save").message || "Save"}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };

  return (
    <>
      <Grid className="client-remarks-container">
        <Grid.Row className="client-remarks-header-container">
          <Grid.Column width={16}>
            <FontAwesomeIcon icon={faStickyNote} className="client-remarks-header-icon" />
            <span className="client-remarks-header-title">{title}</span>
          </Grid.Column>
        </Grid.Row>
        {remarks.map((item, k) => (
          <Grid.Row className="client-remarks-item" key={item.id || k}>
            <Grid.Column width={12} className="add-padding">
              {item.title && <div className="client-remarks-subject">{item.title}</div>}
              <div className="client-remarks-text">{item.description}</div>
            </Grid.Column>
            <Grid.Column width={2}>
              {item.attachments && item.attachments.length > 0 && (
                <Label
                  className="-pull-right"
                  color="teal"
                  onClick={() => setMediaVisible(mediaVisible.includes(k) ? mediaVisible.filter(m => m !== k) : mediaVisible.concat(k))}
                  style={{ width: "auto", cursor: "pointer" }}
                >
                  <Icon name="images" /> {item.attachments.length}
                </Label>
              )}
            </Grid.Column>
            <Grid.Column width={2}>
              <div className={"client-remarks-action -pull-right" + (disabled ? " disabled" : "")}>
                <Can I="update" the={resource}>
                  <Label onClick={() => setEditedRemark({ ...item })}>
                    <FontAwesomeIcon icon={faEdit} />
                  </Label>
                </Can>

                <Label
                  onClick={() => {
                    !disabled &&
                      onHandleCommunication({
                        remark: item,
                        openModalConvertToIntervention:
                          !item.receptionist_handled && canCreateIntervention && !interventions?.some(i => i.check_in_remark_id === item.id),
                      });
                  }}
                >
                  <FontAwesomeIcon
                    className={disabled ? "" : "-cursor-pointer-no-color"}
                    icon={item.receptionist_handled ? faCheckCircle : faTimesCircle}
                    color={item.receptionist_handled ? "green" : "red"}
                  />
                </Label>
              </div>
            </Grid.Column>
            {mediaVisible.includes(k) && renderMedia(item.attachments)}
          </Grid.Row>
        ))}
      </Grid>
      {renderEditRemarkModal()}
      {renderImageGallery()}
    </>
  );
};

export default withTranslation()(CCRemarks);
