import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Label, Form, Dropdown } from "semantic-ui-react";

import { UserInput } from "../../components";
import { COUNTRIES } from "../../util/common";
import { ROLES } from "../Users/roles";

class DealerForm extends Component {
  handleInputChange = e => {
    let { dealer, onChange } = this.props;
    if (Object.keys(dealer).length === 0) return;
    dealer[e.target.name] = e.target.value;
    onChange(dealer);
  };

  handleCheckboxChange = (e, data) => {
    let { dealer, onChange } = this.props;
    dealer[data.name] = data.checked;
    onChange(dealer);
  };

  handleCountryChange = (e, data) => {
    let { dealer, onChange } = this.props;
    dealer.country_code = data.value;
    onChange(dealer);
  };

  render() {
    const { dealer, t, missingField, mode, user } = this.props;

    return (
      dealer && (
        <Form>
          <Form.Field className="full-width-input">
            <label>{t("company_name").message || "Company name"}</label>
            <UserInput
              disabled={user.role_id > ROLES.SUPPORT_ADMIN}
              value={dealer.name}
              name="name"
              placeholder={t("company_name").message || "Company name"}
              onChange={this.handleInputChange}
            />
            {!dealer.name && missingField && (
              <Label basic color="red" pointing>
                {t("field_is_required").message || "This field is required."}
              </Label>
            )}
          </Form.Field>
          {this.props.mode !== "create" && (
            <Form.Field className="full-width-input">
              <label>{t("country").message || "Country"}</label>
              <Dropdown
                placeholder={t("country").message || "Country"}
                options={
                  COUNTRIES.map((a, key) => {
                    return { text: a.text, value: a.value, key };
                  }) || []
                }
                search
                selection
                value={dealer.country_code}
                onChange={this.handleCountryChange}
              />
            </Form.Field>
          )}

          <Form.Field className="full-width-input">
            <label>{t("region").message || "Region"}</label>
            <UserInput value={dealer.region} placeholder={t("region").message || "Region"} name="region" onChange={this.handleInputChange} />
          </Form.Field>
          <Form.Field className="full-width-input">
            <label>{t("city").message || "City"}</label>
            <UserInput value={dealer.city} placeholder={t("city").message || "City"} name="city" onChange={this.handleInputChange} />
          </Form.Field>
          <Form.Field className="full-width-input">
            <label>{t("street").message || "Street"}</label>
            <UserInput value={dealer.street} placeholder={t("street").message || "Street"} name="street" onChange={this.handleInputChange} />
          </Form.Field>
          <Form.Field className="full-width-input">
            <label>{t("postal_code").message || "Postal code"}</label>
            <UserInput value={dealer.postal_code} placeholder={t("postal_code").message || "Postal code"} name="postal_code" onChange={this.handleInputChange} />
          </Form.Field>
          <Form.Field className="full-width-input">
            <label>{t("phone").message || "Phone"}</label>
            <UserInput value={dealer.phone_nr} name="phone_nr" placeholder={t("phone").message || "Phone"} onChange={this.handleInputChange} />
          </Form.Field>
          <Form.Field className="full-width-input">
            <label>{t("website_url").message || "Website url"}</label>
            <UserInput value={dealer.website_url} name="website_url" placeholder={t("website_url").message || "Website url"} onChange={this.handleInputChange} />
          </Form.Field>

          {mode !== "create" && this.props.isAdmin && (
            <div>
              <Form.Field className="full-width-input">
                <label>{t("dealer_zoho_key").message || "Dealer zoho key"}</label>
                <UserInput value={dealer.zoho_key} name="zoho_key" placeholder={t("dealer_zoho_key").message || "Dealer zoho key"} onChange={this.handleInputChange} />
              </Form.Field>
            </div>
          )}
        </Form>
      )
    );
  }
}

export default withTranslation()(DealerForm);
