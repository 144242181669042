import axios from "axios";

import ENV from "../../util/env-config";
import { getSessionCookie } from "../../util/common";

class Service {
  static requestConfig() {
    return {
      headers: {
        Authorization: `Bearer ${getSessionCookie()}`,
        Pragma: "no-cache",
      },
    };
  }

  static getKiosks(requestData) {
    return axios.post(ENV.kioskBaseUrl + "/dashboard/list", requestData, this.requestConfig());
  }

  static createKiosk(requestData) {
    return axios.post(ENV.kioskBaseUrl + "/dashboard/create", requestData, this.requestConfig());
  }

  static updateKiosk(requestData) {
    return axios.post(ENV.kioskBaseUrl + "/dashboard/update", requestData, this.requestConfig());
  }

  static activateKiosk(requestData) {
    return axios.post(ENV.kioskBaseUrl + "/dashboard/enable", requestData, this.requestConfig());
  }

  static deactivateKiosk(requestData) {
    return axios.post(ENV.kioskBaseUrl + "/dashboard/disable", requestData, this.requestConfig());
  }

  static deleteKiosk(requestData) {
    return axios.post(ENV.kioskBaseUrl + "/dashboard/delete", requestData, this.requestConfig());
  }

  static addConfirmationImage(requestData) {
    return axios.post(ENV.kioskBaseUrl + "/dashboard/upload_confirmation_image", requestData, this.requestConfig());
  }

  static getKioskLabels(requestData) {
    return axios.post(ENV.kioskBaseUrl + "/dashboard/labels/list", requestData, this.requestConfig());
  }
}

export default Service;
