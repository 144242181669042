import React, { Component } from "react";
import AlertContainer from "react-alert";
import { withTranslation } from "react-i18next";
import { Grid, Button, Icon, Modal } from "semantic-ui-react";

import { copyToClipboard } from "../../util/common";
import { SubHeader, CustomConfirm, UserMenuActionsPortal } from "../../components";

import { Can } from "..";

import ACTIONS from "./actions";
import Context from "./context";
import KioskTable from "./KioskTable";
import KioskEditModal from "./KioskEditModal";
import KioskLabelsModal from "./KioskLabelsModal";

import "./index.scss";

class Kiosk extends Component {
  static contextType = Context;

  renderAlert = () => {
    const props = {
      offset: 20,
      position: "top right",
      theme: "light",
      time: 2000,
      transition: "fade",
    };
    return <AlertContainer ref={a => (this.msg = a)} {...props} />;
  };

  handleCloseModal = () => {
    this.context.controller.setAction(null);
  };

  handleCopyToClipboard = key => {
    copyToClipboard(key);
    this.msg.show(this.props.t("private_key_copied").message || "Private key copied to clipboard", { type: "success" });
  };

  cancelAction = () => {
    this.context.controller.setAction(null);
  };

  render() {
    const { t } = this.props;
    const { controller, action, isLoading, privateKey } = this.context;
    const { setAction, getKiosks, deleteKiosk } = controller;

    let confirmIsOpen = [ACTIONS.CONFIRM_DELETE_KIOSK].includes(action);
    let confirmAction, confirmMsg;

    if (action === ACTIONS.CONFIRM_DELETE_KIOSK) {
      confirmAction = deleteKiosk;
      confirmMsg = t("confirm_delete_message").message || "Are you sure that you want to delete this? You can't undo this action.";
    } else {
      confirmIsOpen = false;
    }

    return (
      <div className="Kiosks">
        <UserMenuActionsPortal>
          <Icon name="refresh" onClick={getKiosks} />
        </UserMenuActionsPortal>
        <SubHeader>
          <Grid.Column width={14}>
            <h1>{t("kiosks").message || "Kiosks"}</h1>
          </Grid.Column>
          <Grid.Column width={2}>
            <Can I="create" the="kiosk">
              <Button color="green" icon labelPosition="left" disabled={isLoading} fluid onClick={() => setAction(ACTIONS.EDIT_MODAL_SHOW, -1)}>
                <Icon name="add" /> {t("add").message || "Add"}
              </Button>
            </Can>
          </Grid.Column>
        </SubHeader>
        <KioskTable />
        <KioskEditModal />
        <KioskLabelsModal />

        <CustomConfirm
          type="danger"
          isOpen={confirmIsOpen}
          handleCancel={this.cancelAction}
          handleConfirm={confirmAction}
          confirmMsg={confirmMsg}
          confirmText={t("confirm").message || "Confirm"}
          cancelText={t("cancel").message || "Cancel"}
        />

        <Modal open={action === ACTIONS.KIOSK_CREATED && privateKey} onClose={this.handleCloseModal}>
          <Modal.Header>{t("private_key").message || "Private key"}</Modal.Header>
          <Modal.Content>
            <p style={{ textAlign: "center" }}>
              {t("key_displayed_once_ensure_copy").message || "This key will be displayed only once, make sure to copy it and proceed to setup."}
            </p>
            <div className="KioskPrivateKey-container" onClick={() => this.handleCopyToClipboard(privateKey)}>
              <Icon name="copy outline" />
              <div>{privateKey}</div>
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.handleCloseModal}>{t("close").message || "Close"}</Button>
          </Modal.Actions>

          {this.renderAlert()}
        </Modal>
      </div>
    );
  }
}

export default withTranslation()(Kiosk);
