import React from "react";
import { withTranslation } from "react-i18next";

import "./NoAccess.css";

const NoAccess = ({ t }) => (
  <div className="NoAccess">
    <h1>{t("no_access").message || "You don't have access to see this page"}</h1>
  </div>
);

export default withTranslation()(NoAccess);
