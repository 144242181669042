import React, { Component } from "react";
import Service from "./service";
import { Form, Dropdown, Checkbox, Button, Modal, Segment, Icon, Message } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import { CustomConfirm, LocationDropdown } from "../../components";
import Can from "../Can";

const CHECK_CREATED_TRIGGER = 1;

class WebhooksModal extends Component {
  state = {
    isDeletingHook: false,
    showDeleteHookConfirmation: false,
    headerButtonDisabled: this.props.hook.headers && this.props.hook.headers.some(h => !h.name || !h.value),
    deleteError: "",
  };

  handleInputChange = e => {
    const { hook, onChange } = this.props;
    hook[e.target.name] = e.target.value;
    onChange(hook);
  };

  handleCheckboxChange = (_, { name, checked }) => {
    const { hook, onChange } = this.props;
    hook[name] = checked;
    onChange(hook);
  };

  handleTriggerChange = (e, data) => {
    const { hook, onChange } = this.props;

    hook.webhook_trigger_id = data.value;
    if (hook.webhook_trigger_id !== CHECK_CREATED_TRIGGER) hook.checklist_id = null;

    onChange(hook);
  };

  handleChecklistChange = (e, data) => {
    const { hook, onChange } = this.props;
    hook.checklist_id = data.value ? data.value : null;
    onChange(hook);
  };

  handleLocationChange = (e, data) => {
    const { hook, onChange } = this.props;
    if (hook.dealer_location_id !== data.value) hook.checklist_id = null;
    hook.dealer_location_id = data.value || null;

    onChange(hook);
  };

  handleHeadersChange = (e, index) => {
    const { hook, onChange } = this.props;
    hook.headers[index][e.target.name] = e.target.value;

    this.setState({ headerButtonDisabled: hook.headers.some(h => !h.name || !h.value) });

    onChange(hook);
  };

  handleAddHeaders = () => {
    const { hook, onChange } = this.props;

    if (!hook.headers) hook.headers = [];

    if (!hook.headers.some(h => !h.name || !h.value)) {
      hook.headers = hook.headers.concat({ header: "", value: "" });
      this.setState({ headerButtonDisabled: true });
    }

    onChange(hook);
  };

  handleRemoveHeader = index => {
    const { hook, onChange } = this.props;
    hook.headers = hook.headers.filter((h, i) => i !== index);
    this.setState({ headerButtonDisabled: hook.headers.some(h => !h.name || !h.value) });
    onChange(hook);
  };

  handleDeleteHook = () => {
    const { hook, onClose, onDelete, setAlert } = this.props;

    this.setState({ isDeletingHook: true }, () => {
      Service.deleteWebhook(hook.id)
        .then(response => {
          onDelete(hook.id);
          this.setState({ showDeleteHookConfirmation: false, isDeletingHook: false, deleteError: "" }, () => onClose());
        })
        .catch(err => {
          this.setState(
            {
              isDeletingHook: false,
              deleteError: err.response?.data?.errors?.length ? err.response.data.errors[0] : "Error Occurred",
            },
            () => setAlert({ type: "error", title: this.state.deleteError })
          );
        });
    });
  };

  toggleDeleteHookConfirmation = () => {
    this.setState(prevState => ({ showDeleteHookConfirmation: !prevState.showDeleteHookConfirmation }));
  };

  renderDeleteHookConfirmation = () => {
    const { showDeleteHookConfirmation, isDeletingHook, deleteError } = this.state;
    const { t } = this.props;

    return (
      <CustomConfirm
        type="danger"
        isOpen={showDeleteHookConfirmation}
        handleCancel={this.toggleDeleteHookConfirmation}
        handleConfirm={this.handleDeleteHook}
        confirmMsg={t("confirm_delete_message").message || "Are you sure that you want to delete this? You can't undo this action."}
        isLoading={isDeletingHook}
        error={deleteError}
      />
    );
  };

  render() {
    const { hook, triggers, mode, isLoading, isSaving, isOpen, onSave, onClose, errorMessage, locations, dealerChecklistOptions, locationsChecklistOptions, t } =
      this.props;
    const { isDeletingHook } = this.state;

    const checklistOptions = !!hook.dealer_location_id ? locationsChecklistOptions[hook.dealer_location_id] : dealerChecklistOptions;
    const displayChecklistOptions = hook.webhook_trigger_id === CHECK_CREATED_TRIGGER;

    if (hook)
      return (
        <div>
          <Modal open={isOpen} onClose={onClose} closeOnDimmerClick={false}>
            <Modal.Header>{mode === "create" ? t("add_new_webhook").message || "Add new webhook" : t("edit_webhook").message || "Edit Webhook"}</Modal.Header>
            <Modal.Content scrolling>
              <Form>
                <Form.Group>
                  <Form.Field width={7} className="webhook-form-field">
                    <Form.Input name="name" required label={t("name").message || "Name"} onChange={this.handleInputChange} value={hook.name || ""} />
                  </Form.Field>

                  <Form.Field width={7} className="webhook-form-field">
                    <label>
                      {t("location").message || "Location"} ({t("optional").message || "Optional"})
                    </label>
                    <LocationDropdown button labeled floating options={locations} value={hook.dealer_location_id} clearable onChange={this.handleLocationChange} />
                  </Form.Field>

                  <Form.Field width={2} className="webhook-form-field">
                    <label>{t("queued").message || "Queued"}</label>
                    <Checkbox className="webhook-modal-checkbox" name="queued" checked={hook.queued} onChange={this.handleCheckboxChange} toggle />
                  </Form.Field>
                </Form.Group>
                <Form.Field required>
                  <label>{t("trigger").message || "Trigger"}</label>
                  <Dropdown
                    name="trigger"
                    placeholder={t("trigger").message || "Trigger"}
                    selection
                    options={
                      triggers?.length > 0 &&
                      triggers.map((trigger, key) => ({
                        text: t(trigger.name).message || trigger.name,
                        description: t(trigger.description).message || trigger.description,
                        value: trigger.id,
                        key,
                      }))
                    }
                    onChange={this.handleTriggerChange}
                    defaultValue={hook.webhook_trigger_id}
                  />
                </Form.Field>

                {displayChecklistOptions && checklistOptions?.length > 0 && (
                  <Form.Field>
                    <label>{t("checklist").message || "Checklist"}</label>
                    <Dropdown
                      name="trigger"
                      placeholder={t("checklist").message || "Checklist"}
                      selection
                      clearable
                      selectOnBlur={false}
                      options={checklistOptions}
                      value={hook.checklist_id}
                      onChange={this.handleChecklistChange}
                    />
                  </Form.Field>
                )}

                <Form.Field>
                  <Form.Input name="url" required label={t("url").message || "URL"} onChange={this.handleInputChange} value={hook.url || ""} />
                </Form.Field>
                <Segment.Group>
                  <Segment>
                    <h5>{t("headers").message || "Headers"}</h5>
                    {hook.headers &&
                      hook.headers.map((header, key) => (
                        <div key={key}>
                          <Form.Group>
                            <Form.Field width={7}>
                              <Form.Input
                                name="name"
                                placeholder={t("name").message || "Name"}
                                onChange={e => this.handleHeadersChange(e, key)}
                                value={header.name || ""}
                              />
                            </Form.Field>
                            <Form.Field width={7}>
                              <Form.Input
                                name="value"
                                placeholder={t("header_value").message || "Value"}
                                onChange={e => this.handleHeadersChange(e, key)}
                                value={header.value || ""}
                              />
                            </Form.Field>
                            <Form.Field className="remove-header-icon-wrapper" width={1}>
                              <Icon link size="large" name="times" onClick={() => this.handleRemoveHeader(key)} />
                            </Form.Field>
                          </Form.Group>
                        </div>
                      ))}
                    <Button className="-margin-top-10" onClick={this.handleAddHeaders} disabled={this.state.headerButtonDisabled}>
                      {t("add_headers").message || "Add headers"}
                    </Button>
                  </Segment>
                </Segment.Group>
                <Form.Group>
                  <Form.Field className="webhook-form-field">
                    <Checkbox
                      className="webhook-modal-checkbox"
                      name="active"
                      checked={hook.active}
                      label={t("enable_webhook").message || "Enable Webhook"}
                      onChange={this.handleCheckboxChange}
                      toggle
                    />
                  </Form.Field>

                  <Can I="update-block-creating-support-ticket" the="webhooks">
                    <Form.Field className="webhook-form-field">
                      <Checkbox
                        name="block_creating_support_ticket"
                        className="webhook-modal-checkbox"
                        label={t("send_support_tickets").message || "Send support tickets"}
                        checked={!hook.block_creating_support_ticket}
                        onChange={(_, { name, checked }) => {
                          this.handleCheckboxChange(null, { name, checked: !checked });
                        }}
                        toggle
                      />
                    </Form.Field>
                  </Can>
                </Form.Group>
                {errorMessage && (
                  <Form.Group>
                    <Form.Field width={16}>
                      <Message color="red">{errorMessage}</Message>
                    </Form.Field>
                  </Form.Group>
                )}
              </Form>
            </Modal.Content>
            <Modal.Actions>
              {mode === "edit" && (
                <Can I="delete" the="webhooks">
                  <Button
                    size="large"
                    onClick={this.toggleDeleteHookConfirmation}
                    loading={isDeletingHook}
                    disabled={isDeletingHook || isSaving}
                    floated="left"
                    color="red"
                  >
                    <Icon name="trash" className="-no-margin" />
                  </Button>
                </Can>
              )}
              <Button size="large" onClick={onClose} color="grey">
                {t("discard").message || "Discard"}
              </Button>
              <Button size="large" loading={isSaving} disabled={isLoading || isSaving} onClick={onSave} color="green">
                {mode === "create" ? t("add").message || "Add" : t("update").message || "Update"}
              </Button>
            </Modal.Actions>
          </Modal>

          {this.renderDeleteHookConfirmation()}
        </div>
      );
  }
}

export default withTranslation()(WebhooksModal);
