const ACTIONS = {
  LOAD_KIOSK: 1,
  EDIT_MODAL_SHOW: 2,
  EDIT_MODAL_SAVE: 3,
  ACTIVATE_KIOSK: 4,
  DEACTIVATE_KIOSK: 5,
  CONFIRM_DELETE_KIOSK: 6,
  DELETE_KIOSK: 7,
  GET_KIOSK_DETAILS: 8,
  DETAILS_MODAL_SHOW: 9,
  KIOSK_CREATED: 10,
};

export default ACTIONS;
