import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/pro-regular-svg-icons";

import "./index.css";

const ImageLetters = ({ images, lettersMap }) => {
  if (!Array.isArray(images) || images.length === 0) return null;

  if (!Array.isArray(lettersMap) || !Object.keys(lettersMap).length) return null;

  const uniqueImages = images.length > 0 && [...new Map(images.map(item => [item.url, item])).values()];

  return (
    <div className="image-video-letter">
      <FontAwesomeIcon icon={faCamera} className="image-video-icon" />
      <span>
        {uniqueImages
          .map(i => lettersMap[i.url])
          .filter(f => f !== undefined)
          .join(",")}
      </span>
    </div>
  );
};

export default ImageLetters;
