import React from "react";
import ReactTable, { ReactTableDefaults } from "react-table";
import { withTranslation } from "react-i18next";
import { Icon } from "semantic-ui-react";

import { formatDateIfValid } from "../Appointments/util";

const DevicesTable = props => {
  let { devices, t, loading } = props;

  if (loading) {
    return (
      <div className="Table__loading Loader-Placeholder">
        <div className="bounce1"></div>
        <div className="bounce2"></div>
        <div className="bounce3"></div>
        <section>{t("loading_tablets").message || "Loading Tablets"}</section>
      </div>
    );
  } else if (!devices || devices.length === 0) {
    return (
      <div className="Table__no-results">
        <Icon disabled name="tablet alternate" style={{ fontSize: "1.75em" }} />
        <p>{t("no_tablets").message || "No tablets"}</p>
      </div>
    );
  } else {
    return (
      <div className="UsersTable">
        <ReactTable
          className="ReactTable -floated-table -contained-large"
          data={devices || []}
          showPagination={false}
          showPageSizeOptions={false}
          sortable={false}
          resizable={false}
          defaultPageSize={devices.length}
          pageSize={devices.length}
          noDataText={t("no_tablets").message || "No tablets"}
          column={{
            ...ReactTableDefaults.column,
            headerClassName: "ReactTable__column-header",
            className: "ReactTable__column",
          }}
          columns={[
            {
              id: "leased",
              Header: t("leased").message || "Leased",
              maxWidth: 42,
              accessor: d => <span>{d.is_leased && <Icon className="-text-center" name="check circle" />}</span>,
            },
            {
              id: "last_used",
              Header: t("last_used").message || "Last used",
              accessor: d => <span>{formatDateIfValid(d.last_used, "DD-MM-YY | HH:mm:ss")}</span>,
            },
            {
              id: "brand",
              Header: t("brand").message || "Brand",
              accessor: "brand",
            },
            {
              id: "model",
              Header: t("model").message || "Model",
              accessor: "model",
            },
            {
              id: "os_version",
              Header: t("os").message || "OS",
              accessor: "os_version",
            },
            {
              id: "free_space",
              Header: t("free_space").message || "Free space(mb)",
              accessor: "free_space",
            },
            {
              id: "mac_address",
              Header: t("mac_address").message || "MAC Address",
              accessor: "mac_address",
            },
          ]}
        />
      </div>
    );
  }
};

export default withTranslation()(DevicesTable);
