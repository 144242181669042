import React, { Component } from "react";
import Chart from "chart.js";
import { withTranslation } from "react-i18next";

class MilageChart extends Component {
  state = {
    isChartVisible: false,
    chartData: null,
    chartOptions: null,
  };

  componentDidMount() {
    this.setupChart();
  }

  setupChart = () => {
    var ctx = this.createCanvas().getContext("2d");
    let { datasets, averageKMData } = this.createLineDatasets();
    datasets.unshift(this.getBubbleDataset());
    let translations = this.props.t;

    let dataCfg = {
      datasets: datasets,
    };

    let legendCfg = {
      display: false,
    };

    let xAxisCfg = {
      type: "time",
      unit: "month",
      gridLines: {
        color: "rgba(0,0,0,0)",
      },
      time: {
        displayFormats: {
          millisecond: "MMM, YYYY",
          second: "MMM, YYYY",
          minute: "MMM, YYYY",
          hour: "MMM, YYYY",
          day: "MMM, YYYY",
          week: "MMM, YYYY",
          month: "MMM, YYYY",
          quarter: "MMM, YYYY",
          year: "MMM, YYYY",
        },
        unitStepSize: 1,
      },
      ticks: {
        autoSkip: true,
        maxTicksLimit: 20,
      },
    };

    let yAxisCfg = {
      type: "linear",
      ticks: {
        beginAtZero: false,
      },
    };

    let scalesCfg = {
      xAxes: [xAxisCfg],
      yAxes: [yAxisCfg],
    };

    let tooltipsCfg = {
      callbacks: {
        beforeLabel: function (tooltipItem) {
          if (tooltipItem.datasetIndex < 1 || tooltipItem.index !== 1) return null;

          const result = averageKMData[tooltipItem.datasetIndex - 1];
          if (!result) return null;

          return (
            translations("previous_period").message +
            " - " +
            result +
            " km / " +
            translations("day").message +
            " (" +
            result * 30 +
            " km / " +
            translations("month").message +
            ")"
          );
        },
        label: function (tooltipItem) {
          return null;
        },
        afterLabel: function (tooltipItem) {
          if (tooltipItem.datasetIndex < 1 || tooltipItem.datasetIndex > averageKMData.length || tooltipItem.index !== 0) return null;

          const result = averageKMData[tooltipItem.datasetIndex - 1];
          if (!result) return null;

          return (
            translations("next_period").message +
            " - " +
            result +
            " km / " +
            translations("day").message +
            " (" +
            result * 30 +
            " km / " +
            translations("month").message +
            ")"
          );
        },
        title: function (tooltipItem) {
          return new Date(tooltipItem[0].xLabel).toString().substr(4, 12) + " - " + tooltipItem[0].yLabel + " km";
        },
      },
      mode: "nearest",
      intersect: true,
    };

    let hoverCfg = {
      animationDuration: 0,
    };

    let optionsCfg = {
      legend: legendCfg,
      scales: scalesCfg,
      tooltips: tooltipsCfg,
      hover: hoverCfg,
      aspectRatio: 3,
    };

    let cfg = {
      type: "line",
      data: dataCfg,
      options: optionsCfg,
    };

    new Chart(ctx, cfg);
  };

  createCanvas = () => {
    var canvas = document.createElement("canvas");
    document.getElementById("MileageChartArea").innerHTML = "";
    document.getElementById("MileageChartArea").appendChild(canvas);
    return canvas;
  };

  createLineDatasets = () => {
    let lineDatasets = [];
    let averageKMData = [];

    for (let i = 0; i < this.props.lineData.length - 1; i++) {
      let averageKM = this.calculateAverageKMDriven(this.props.lineData[i], this.props.lineData[i + 1]);

      let color = this.getColorByAverageKM(averageKM);

      averageKMData.push(averageKM);

      let dataset = {
        type: "line",
        fill: true,
        lineTension: 0.5,
        backgroundColor: color + "0.4)",
        borderColor: color + "1)",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: color + "1)",
        pointBackgroundColor: color + "1)",
        pointBorderWidth: 1,
        pointHoverRadius: 1,
        pointHoverBackgroundColor: color + "1)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: [this.props.lineData[i], this.props.lineData[i + 1]],
        spanGaps: false,
      };

      lineDatasets.push(dataset);
    }

    return { datasets: lineDatasets, averageKMData };
  };

  calculateAverageKMDriven = (datapoint1, datapoint2) => {
    let timeDiff = datapoint2.x - datapoint1.x;
    let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
    let kmDiff = datapoint2.y - datapoint1.y;

    return Math.floor(kmDiff / diffDays);
  };

  getColorByAverageKM = averageKM => {
    if (averageKM <= 30) {
      return "rgba(135, 206, 235,";
    } else if (averageKM >= 30 && averageKM < 50) {
      return "rgba(175,196,131,";
    } else if (averageKM >= 50 && averageKM < 70) {
      return "rgba(252,162,78,";
    } else if (averageKM >= 70 && averageKM < 100) {
      return "rgba(224,126,96,";
    } else {
      return "rgba(191,59,59,";
    }
  };

  getBubbleDataset = () => {
    return {
      type: "bubble",
      data: this.props.bubbleData,
      backgroundColor: this.props.colors.backgroundColors,
      hoverBackgroundColor: this.props.colors.backgroundColors,
      hoverRadius: 0,
      borderWidth: 2,
    };
  };

  render() {
    let { t } = this.props;

    return (
      <div className="MileageChart">
        <div className="MileageChart__Legend">
          <span
            className="MileageChart__Legend__Text"
            style={{
              backgroundColor: "rgba(135, 206, 235, 0.4)",
              border: "3px solid rgba(135, 206, 235, 1)",
            }}
          >
            &#60; 30 km / {t("day").message || "day"}
            &nbsp; &nbsp; &nbsp;
          </span>

          <span
            className="MileageChart__Legend__Text"
            style={{
              backgroundColor: "rgba(175,196,131,0.4)",
              border: "3px solid rgba(175,196,131,1)",
            }}
          >
            30-50 km / {t("day").message || "day"}
            &nbsp; &nbsp; &nbsp;
          </span>

          <span
            className="MileageChart__Legend__Text"
            style={{
              backgroundColor: "rgba(252,162,78,0.4)",
              border: "3px solid rgba(252,162,78,1)",
            }}
          >
            50-70 km / {t("day").message || "day"}
            &nbsp; &nbsp;
          </span>

          <span
            className="MileageChart__Legend__Text"
            style={{
              backgroundColor: "rgba(224,126,96,0.4)",
              border: "3px solid rgba(224,126,96,1)",
            }}
          >
            70-100 km / {t("day").message || "day"}
            &nbsp; &nbsp;
          </span>

          <span
            className="MileageChart__Legend__Text"
            style={{
              backgroundColor: "rgba(191,59,59,0.4)",
              border: "3px solid rgba(191,59,59,1)",
            }}
          >
            &#62; 100 km / {t("day").message || "day"}
            &nbsp; &nbsp;
          </span>
        </div>
        <div id="MileageChartArea"></div>
      </div>
    );
  }
}

export default withTranslation()(MilageChart);
