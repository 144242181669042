import axios from "axios";

import ENV from "../../../util/env-config";
import { getSessionCookie } from "../../../util/common";

class Service {
  static requestConfig() {
    return {
      headers: {
        Authorization: `Bearer ${getSessionCookie()}`,
      },
    };
  }

  static getWarningsList(requestData) {
    return axios.post(`${ENV.apiBaseB}/dmsv3/list_warnings`, requestData, this.requestConfig());
  }
}

export default Service;
