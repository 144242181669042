import axios from "axios";

import ENV from "../../util/env-config";
import { getSessionCookie } from "../../util/common";

class Service {
  static requestConfig() {
    return {
      headers: {
        Authorization: `Bearer ${getSessionCookie()}`,
        Pragma: "no-cache",
      },
    };
  }

  static getReportGroups(dealer_id) {
    return axios.post(ENV.reportsBaseUrl + "/dealers/list_groups", { dealer_id }, this.requestConfig());
  }

  static getReportList(dealer_id, group) {
    return axios.post(ENV.reportsBaseUrl + "/dealers/list", { dealer_id, group }, this.requestConfig());
  }

  static getReportData(dealer_id, report_id, date_from, date_to) {
    return axios.post(ENV.reportsBaseUrl + "/dealers/view", { dealer_id, report_id, date_from, date_to }, this.requestConfig());
  }

  static getEmailStats(dealer_id, period) {
    return axios.post(ENV.reportsBaseUrl + "/dealers/email/view", { dealer_id, period }, this.requestConfig());
  }
}

export default Service;
