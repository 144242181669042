import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Checkbox, Form, Header, Icon, Segment } from "semantic-ui-react";

import PasswordInput from "../PasswordInput";
import UserInput from "../UserInput";

class AcsesConfiguration extends Component {
  handleInputChange = e => {
    let { location, onLocationChange } = this.props;

    if (Object.keys(location).length === 0) return;

    onLocationChange({ ...location, [e.target.name]: e.target.value });
  };

  handleCheckboxChange = (e, { name, checked }) => {
    let { location, onLocationChange } = this.props;

    onLocationChange({ ...location, [name]: checked });
  };

  render() {
    const { t, location, isSubmit } = this.props;

    return (
      <Segment>
        <Header as="h5">
          <Icon name="settings" />
          <Header.Content>{t("acses_configuration").message || "Acses Configuration"}</Header.Content>
        </Header>
        <Segment>
          <Form>
            <Form.Field>
              <Checkbox checked={location.is_acses_enabled} name="is_acses_enabled" label={t("aces").message || "ACSES"} onChange={this.handleCheckboxChange} />
            </Form.Field>

            <Form.Field className="full-width-input" error={isSubmit && location.is_acses_enabled && !location.acses_email}>
              <label>{t("acses_email").message || "Acses email"}</label>
              <UserInput value={location.acses_email} name="acses_email" placeholder={t("acses_email").message || "Acses email"} onChange={this.handleInputChange} />
            </Form.Field>

            <Form.Field className="full-width-input" error={isSubmit && location.is_acses_enabled && !location.has_acses_password && !location.acses_password}>
              <label>{t("acses_password").message || "Acses password"}</label>
              <PasswordInput
                showAsteriskPlaceholder={location.has_acses_password}
                value={location.acses_password}
                name="acses_password"
                placeholder={t("acses_password").message || "Acses password"}
                onChange={this.handleInputChange}
              />
            </Form.Field>
          </Form>
        </Segment>
      </Segment>
    );
  }
}

export default withTranslation()(AcsesConfiguration);
