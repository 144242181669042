import React from "react";
import { Button } from "semantic-ui-react";
import ReactTable, { ReactTableDefaults } from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport } from "@fortawesome/pro-regular-svg-icons";
import { saveAs } from "file-saver";
import moment from "moment";

import { round2 } from "../../../util/common";

const SnoozedMakeOffer = ({ report, t }) => {
  const data = report.report_data.map(d => JSON.parse(d));

  let total = {
    location: "Total",
    wo_count: 0,
    no_department: 0,
    department_parts: 0,
    department_sales: 0,
    department_ccc: 0,
    department_bodyrepair: 0,
    department_external: 0,
    department_digital: 0,
    snooze_total: 0,
    no_department_percent: 0,
    department_parts_percent: 0,
    department_sales_percent: 0,
    department_ccc_percent: 0,
    department_bodyrepair_percent: 0,
    department_external_percent: 0,
    department_digital_percent: 0,
  };

  const l = data.length;
  if (l > 0) {
    const addToTotal = (acc, cur) => {
      acc.wo_count += cur.wo_count || 0;
      acc.no_department += cur.no_department || 0;
      acc.department_parts += cur.department_parts || 0;
      acc.department_sales += cur.make_offer || 0;
      acc.department_ccc += cur.department_ccc || 0;
      acc.department_bodyrepair += cur.department_bodyrepair || 0;
      acc.department_external += cur.department_external || 0;
      acc.department_digital += cur.department_digital || 0;
      acc.snooze_total += cur.snooze_total || 0;
      return acc;
    };

    total = data.reduce((acc, cur) => addToTotal(acc, cur), total);
    total.no_department_percent = round2((total.no_department / total.snooze_total) * 100);
    total.department_parts_percent = round2((total.department_parts / total.snooze_total) * 100);
    total.department_sales_percent = round2((total.department_sales / total.snooze_total) * 100);
    total.department_ccc_percent = round2((total.department_ccc / total.snooze_total) * 100);
    total.department_bodyrepair_percent = round2((total.department_bodyrepair / total.snooze_total) * 100);
    total.department_external_percent = round2((total.department_external / total.snooze_total) * 100);
    total.department_digital_percent = round2((total.department_digital / total.snooze_total) * 100);
  }

  data.push(total);

  const exportData = async () => {
    let csv = "";
    let header = (t("location").message || "Location") + ", ";
    header += (t("wo_count").message || "WO count") + ", ";
    header += (t("snooze_total").message || "Snooze count") + ", ";
    header += (t("no_department").message || "No department") + ", ";
    header += (t("department_parts").message || "Parts") + ", ";
    header += (t("department_sales").message || "Sales") + ", ";
    header += (t("department_ccc").message || "CCC") + ", ";
    header += (t("department_bodyrepair").message || "Body repair") + ", ";
    header += (t("department_external").message || "External") + ", ";
    header += t("department_digital").message || "Digital";

    let values = data
      .map(
        d =>
          d.location +
          ", " +
          d.wo_count +
          ", " +
          (d.snooze_total || 0) +
          ", " +
          (d.no_department || 0) +
          " (" +
          (d.no_department_percent || 0) +
          "%)" +
          ", " +
          (d.department_parts || 0) +
          " (" +
          (d.department_parts_percent || 0) +
          "%)" +
          ", " +
          (d.department_sales || 0) +
          " (" +
          (d.department_sales_percent || 0) +
          "%)" +
          ", " +
          (d.department_ccc || 0) +
          " (" +
          (d.department_ccc_percent || 0) +
          "%)" +
          ", " +
          (d.department_bodyrepair || 0) +
          " (" +
          (d.department_bodyrepair_percent || 0) +
          "%)" +
          ", " +
          (d.department_external || 0) +
          " (" +
          (d.department_external_percent || 0) +
          "%)" +
          ", " +
          (d.department_digital || 0) +
          " (" +
          (d.department_digital_percent || 0) +
          "%)"
      )
      .join("\n");

    csv += header + "\n" + values;
    saveAs(new File([csv], { type: "text/csv;charset=utf-8" }), "SnoozedMakeOffer_" + moment().format("YYYYMMDD") + ".csv");
  };

  return (
    <>
      <h3 className="report-heading">
        {t(report.report_name).message || report.report_name}
        <Button color="green" floated="right" basic onClick={() => exportData()}>
          <span style={{ marginRight: "10px" }}>{t("export").message || "Export"}</span>
          <FontAwesomeIcon icon={faFileExport} />
        </Button>
      </h3>
      <div className="dealer-reports-table-container">
        <ReactTable
          className="ReactTable -floated-table no-overflow all-data-table"
          data={data}
          showPagination={false}
          showPageSizeOptions={false}
          sortable={false}
          resizable={false}
          style={{ maxHeight: "600px" }}
          defaultPageSize={data?.length || 0}
          pageSize={data?.length || 0}
          noDataText={
            <div className="Table__no-results">
              <p>{t("no_data").message || "No data"}</p>
            </div>
          }
          column={{
            ...ReactTableDefaults.column,
            headerClassName: "ReactTable__column-header dealer-table-header",
            className: "ReactTable__column",
          }}
          columns={[
            {
              Header: <strong>{t("location").message || "Location"}</strong>,
              accessor: "location",
            },
            {
              Header: <strong>{t("wo_count").message || "WO count"}</strong>,
              accessor: "wo_count",
            },
            {
              Header: <strong>{t("snooze_total").message || "Snooze count"}</strong>,
              accessor: "snooze_total",
              Cell: cell => cell.value || 0,
            },
            {
              Header: <strong>{t("no_department").message || "No department"}</strong>,
              id: "no_department",
              accessor: d => (d.no_department || 0) + " (" + (d.no_department_percent || 0) + "%)",
            },
            {
              Header: <strong>{t("department_parts").message || "Parts"}</strong>,
              id: "department_parts",
              accessor: d => (d.department_parts || 0) + " (" + (d.department_parts_percent || 0) + "%)",
            },
            {
              Header: <strong>{t("department_sales").message || "Sales"}</strong>,
              id: "department_sales",
              accessor: d => (d.department_sales || 0) + " (" + (d.department_sales_percent || 0) + "%)",
            },
            {
              Header: <strong>{t("department_ccc").message || "CCC"}</strong>,
              id: "department_ccc",
              accessor: d => (d.department_ccc || 0) + " (" + (d.department_ccc_percent || 0) + "%)",
            },
            {
              Header: <strong>{t("department_bodyrepair").message || "Body repair"}</strong>,
              id: "department_bodyrepair",
              accessor: d => (d.department_bodyrepair || 0) + " (" + (d.department_bodyrepair_percent || 0) + "%)",
            },
            {
              Header: <strong>{t("department_external").message || "External"}</strong>,
              id: "department_external",
              accessor: d => (d.department_external || 0) + " (" + (d.department_external_percent || 0) + "%)",
            },
            {
              Header: <strong>{t("department_digital").message || "Digital"}</strong>,
              id: "department_digital",
              accessor: d => (d.department_digital || 0) + " (" + (d.department_digital_percent || 0) + "%)",
            },
          ]}
        />
      </div>
    </>
  );
};

export { SnoozedMakeOffer };
