import React, { Component } from "react";
import moment from "moment";
import { withTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import { Form, Grid, Button, Dropdown } from "semantic-ui-react";

import ENV from "../../util/env-config";
import { SubHeader } from "./../../components";

// https://cmapper-dot-claire-pro.appspot.com/_ah/mapper/start?name=main.CarZoho
// Mapper&bucket=zoho_json_prod&from=2018-02-01
let bucketName = process.env.REACT_APP_ENV === "prod" ? "zoho_json_prod" : "zoho_json";

class Zoho extends Component {
  constructor(props) {
    super(props);

    let yesterday = moment().subtract(1, "days");

    let today = moment();

    this.state = {
      selectedItem: "",
      bucketName: bucketName,
      dates: {
        from: yesterday,
        to: today,
      },
      generatedURL: "",
    };
  }

  handleDropdownChange = (event, data) => {
    let dates = this.state.dates;
    let generatedURL = this._getUrl(dates, data.value);
    this.setState({ selectedItem: data.value, generatedURL });
  };

  handleFromDateChange = from => {
    let { dates, selectedItem } = this.state;
    dates.from = moment(from);
    this.setState({ dates, generatedURL: this._getUrl(dates, selectedItem) });
  };

  handleToDateChange = to => {
    let { dates, selectedItem } = this.state;
    dates.to = moment(to);
    this.setState({ dates, generatedURL: this._getUrl(dates, selectedItem) });
  };

  _getUrl = (dates, selectedItem) => {
    let { generatedURL } = this.state;
    generatedURL = `https://cmapper-dot-claire-${
      ENV.zohoPrefix
    }.appspot.com/_ah/mapper/start?name=main.${selectedItem}ZohoMapper&bucket=${bucketName}&from=${dates.from.format("YYYY-MM-DD")}&to=${dates.to.format("YYYY-MM-DD")}`;
    return generatedURL;
  };

  render() {
    const list = [
      { value: "App", text: "Appointment" },
      { value: "AppStatus", text: "AppStatus" },
      { value: "Car", text: "Car" },
      { value: "CarCheck", text: "Check" },
      { value: "Dealer", text: "Dealer" },
      { value: "DealerLocation", text: "Location" },
      { value: "User", text: "User" },
    ];
    return (
      <div className="page_inner_wrapper">
        <SubHeader>
          <Grid.Column width={9}>
            <h1>{this.props.t("zoho").message || "Zoho"}</h1>
          </Grid.Column>
        </SubHeader>

        <div style={{ marginTop: "20px" }}>
          <Grid className="-contained-large" style={{ background: "#fff" }}>
            <Grid.Column width={16}>
              <p>
                <strong>Exports should be done for 15 days maximum. Exporting longer periods may fail.</strong>
              </p>
            </Grid.Column>
            <Grid.Column width={16}>
              <Form>
                <Form.Group>
                  <Form.Field width={12}>
                    <Dropdown
                      text={this.props.t("generate_report_url_for").message || "Generate report URL for"}
                      options={list.map((d, k) => {
                        return { text: `${d.text}`, value: d.value, key: k };
                      })}
                      selection
                      onChange={this.handleDropdownChange}
                    />
                  </Form.Field>
                  <Form.Field width={2}>
                    <DatePicker
                      dateFormat="dd-MM-yyyy"
                      todayButton={this.props.t("today").message || "Today"}
                      placeholderText={this.props.t("start_date").message || "Start date"}
                      maxDate={moment(this.state.dates.to).toDate()}
                      selected={moment(this.state.dates.from).toDate()}
                      onChange={this.handleFromDateChange}
                      onChangeRaw={e => e.preventDefault()}
                      showMonthDropdown
                      showYearDropdown
                    />
                  </Form.Field>
                  <Form.Field width={2}>
                    <DatePicker
                      dateFormat="dd-MM-yyyy"
                      todayButton={this.props.t("today").message || "Today"}
                      placeholderText={this.props.t("end_date").message || "End date"}
                      minDate={moment(this.state.dates.from).toDate()}
                      maxDate={moment().toDate()}
                      selected={moment(this.state.dates.to).toDate()}
                      onChange={this.handleToDateChange}
                      onChangeRaw={e => e.preventDefault()}
                      showMonthDropdown
                      showYearDropdown
                    />
                  </Form.Field>
                </Form.Group>
              </Form>
            </Grid.Column>
            {this.state.generatedURL && (
              <Grid.Column width={16}>
                <span
                  style={{
                    padding: "9px 8px 6px",
                    backgroundColor: "rgb(249, 249, 249)",
                    border: "1px solid #ccc",
                    float: "left",
                    fontSize: "13px",
                    marginRight: "10px",
                    width: "93%",
                    display: "inline-block",
                  }}
                >
                  {this.state.generatedURL}
                </span>
                <Button
                  color="green"
                  floated="right"
                  onClick={() => {
                    window.open(this.state.generatedURL);
                  }}
                >
                  Open
                </Button>
              </Grid.Column>
            )}
          </Grid>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Zoho);
