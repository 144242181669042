import axios from "axios";

import ENV from "../../util/env-config";
import { getSessionCookie } from "../../util/common";

class Service {
  static requestConfig() {
    return {
      headers: {
        Authorization: `Bearer ${getSessionCookie()}`,
        Pragma: "no-cache",
      },
    };
  }

  static getNews(lang) {
    return axios.get(ENV.apiBaseB + "/settings/articles?language=" + lang);
  }

  static getToken(requestData) {
    return axios.post(ENV.authBaseUrl + "/session/login/credentials", requestData);
  }

  static getTokenViaGoogle(requestData) {
    return axios.post(ENV.authBaseUrl + "/session/login/google_sign_in", requestData);
  }

  static getUserFromToken(token) {
    return axios.post(
      ENV.authBaseUrl + "/session/me",
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  static getLandingPages() {
    return axios.get(ENV.apiBaseB + "/iam/landing_pages", this.requestConfig());
  }

  static updateUser(requestData) {
    return axios.post(ENV.apiBaseB + "/users/update", requestData, this.requestConfig());
  }

  static updateUserAccount(requestData) {
    return axios.post(ENV.apiBaseB + "/iam/update_user", requestData, this.requestConfig());
  }

  static updateLocation(requestData) {
    return axios.post(ENV.apiBaseB + "/iam/update_location", requestData, this.requestConfig());
  }

  static updateUserPassword(requestData) {
    return axios.post(ENV.authBaseUrl + "/password/change", requestData, this.requestConfig());
  }

  static forgotPassword(requestData) {
    // return IAMRPC.forgotPassword(requestData);
    return axios.post(ENV.authBaseUrl + "/password/forgot", requestData);
  }

  static resetPassword(requestData) {
    // return IAMRPC.resetPassword({newPassword: requestData.new_password, newPasswordConfirm: requestData.new_password_confirm, hash: requestData.hash});
    return axios.post(
      ENV.authBaseUrl + "/password/reset",
      { new_password: requestData.new_password, new_password_confirm: requestData.new_password_confirm, hash: requestData.hash },
      this.requestConfig()
    );
  }

  static generateQRCode() {
    return axios.get(ENV.authBaseUrl + "/qr/generate?x=" + Math.floor(Math.random() * 1000));
  }

  static verifyQR(qr_code) {
    return axios.post(ENV.authBaseUrl + "/session/login/qr_code", { token: qr_code });
  }

  static uploadProfilePicture(requestData) {
    return axios.post(ENV.apiBaseB + "/files/upload_profile_picture", requestData, this.requestConfig());
  }

  static loadLatestVersion() {
    return axios.get("/latest_version.json");
  }
}

export default Service;
