import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Modal, Button, Icon } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/pro-solid-svg-icons";
import ReactTable, { ReactTableDefaults } from "react-table";
import moment from "moment";

import { StatusCell } from "../../components";
import { updateAppointmentStatusIdentifier } from "../Appointments/util";

import ACTIONS from "./actions";
import Context from "./context";

class KioskLabelsModal extends Component {
  static contextType = Context;

  close = () => this.context.controller.setAction(null);

  handleAppointmentClick = appointment_id => {
    window.open(`${document.location.origin}/#!/appointments/${appointment_id}`, "_blank");
  };

  render() {
    const { t, globalState } = this.props;
    const { kiosks, kioskLabels, selectedKioskIndex, action, isLoading } = this.context;

    const kiosk = kiosks[selectedKioskIndex] || {};
    const isOpen = action === ACTIONS.DETAILS_MODAL_SHOW;

    return (
      <Modal className="KioskLabelsModal" open={isOpen} onClose={this.close} closeOnDimmerClick={false} size="large">
        <Modal.Header>
          {kiosk.name}
          <Button floated="right" negative disabled={isLoading} content={t("close").message || "Close"} onClick={this.close} />
        </Modal.Header>

        <Modal.Content scrolling>
          <ReactTable
            className="ReactTable -floated-table -contained-large"
            data={kioskLabels}
            showPagination={false}
            showPageSizeOptions={false}
            sortable={false}
            resizable={false}
            defaultPageSize={kioskLabels.length}
            pageSize={kioskLabels.length}
            column={{
              ...ReactTableDefaults.column,
              headerClassName: "ReactTable__column-header",
              className: "ReactTable__column",
            }}
            columns={[
              {
                id: "tag_nr",
                Header: t("tag_nr").message || "Tag #",
                Cell: row => {
                  return (
                    <div className="kiosk-table-index" style={{ backgroundColor: "green" }}>
                      {row.original.number}
                    </div>
                  );
                },
                maxWidth: 85,
              },
              {
                id: "used",
                Header: t("key").message || "Key",
                className: "kiosk-box",
                width: 60,
                Cell: row => (
                  <Icon.Group>
                    <Icon name="square outline" size="big" />
                    <Icon name={row.original.appointment_id ? "key" : "close"} />
                  </Icon.Group>
                ),
              },
              {
                id: "appointment_status_identifier",
                Header: t("status").message || "Status",
                width: 65,
                className: "kiosk-status-column",
                headerClassName: "ReactTable__column-header kiosk-status-header",
                Cell: row => {
                  if (!row.original.appointment) return null;

                  updateAppointmentStatusIdentifier(row.original.appointment);

                  return (
                    <StatusCell
                      value={{ id: row.original.appointment.appointment_status_identifier, customcom_status: row.original.appointment.customcom_status }}
                      statuses={globalState.selectedLocation.statuses}
                    />
                  );
                },
              },
              {
                id: "spacer",
                width: 30,
              },
              {
                id: "wo_nr",
                Header: t("wo_nr").message || "WO",
                width: 100,
                Cell: row =>
                  row.original.appointment?.wo_nr ? (
                    <span className="-cursor-pointer" onClick={() => this.handleAppointmentClick(row.original.appointment.id)}>
                      <FontAwesomeIcon icon={faExternalLinkAlt} style={{ marginRight: "5px", fontSize: "15px" }} />
                      {row.original.appointment?.wo_nr || ""}
                    </span>
                  ) : null,
              },
              {
                id: "time_car_app",
                Header: t("appointment_date").message || "Appointment Date",
                width: 135,
                Cell: row => (row.original.appointment ? moment(row.original.appointment.time_car_app).format("DD-MM-YYYY") : ""),
              },
            ]}
          />
        </Modal.Content>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  return { globalState: state.global };
};

export default withTranslation()(connect(mapStateToProps)(KioskLabelsModal));
