import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Modal, Button, Input, Checkbox, Icon, Label, Header, MessageHeader, Message } from "semantic-ui-react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPlus, faPhoneAlt, faTrash } from "@fortawesome/free-solid-svg-icons";
import AlertContainer from "react-alert";

import { updateLocation } from "../App/store";
import Can from "../Can";

import Service from "./service.js";

import "./NoteSuggestions.scss";
import { faParking } from "@fortawesome/pro-solid-svg-icons";

export const NOTE_TYPES = {
  CALL_CUSTOMER_NOTE: 1,
  REMARK_PIN: 2,
  WARRANTY_PIN: 3,
  RECALL_PIN: 4,
  PARKING_NOTE: 5,
};

class NoteSuggestions extends Component {
  state = {
    selectedNoteType: null,
    showSuggestionsModal: false,
    listOfSuggestions: [],
    isSaving: false,
    focusedInputId: null,
  };

  componentDidUpdate() {
    if (this.state.focusedInputId) document.querySelector(`#order-${this.state.focusedInputId}`).focus();
  }

  renderAlert = () => {
    const props = {
      offset: 20,
      position: "top right",
      theme: "light",
      time: 2000,
      transition: "fade",
    };

    return <AlertContainer ref={a => (this.msg = a)} {...props} />;
  };

  handleOpenShowSuggestionsModal = note_type_id => {
    const { selectedLocation } = this.props.globalState;
    const foundNoteType = selectedLocation.note_types.find(nt => nt.note_type_id === note_type_id);

    this.setState({ selectedNoteType: { ...foundNoteType } }, () => {
      const listOfSuggestions = selectedLocation.note_suggestions
        .filter(suggestion => suggestion.note_type_id === note_type_id)
        .sort((ns1, ns2) => ns1.order - ns2.order);
      this.setState({ listOfSuggestions, showSuggestionsModal: true });
    });
  };

  handleCloseSuggestionsModal = () => {
    this.setState({
      showSuggestionsModal: false,
      selectedNoteType: null,
      listOfSuggestions: [],
    });
  };

  handleDisplayAddNewSuggestionInput = () => {
    const { listOfSuggestions, selectedNoteType } = this.state;
    const { selectedLocation } = this.props.globalState;

    const nextOrder = listOfSuggestions?.length ? listOfSuggestions[listOfSuggestions.length - 1].order + 1 : 1;

    this.setState({
      listOfSuggestions: [
        ...listOfSuggestions,
        { content: "", order: nextOrder, dealer_location_id: selectedLocation.id, note_type_id: selectedNoteType.note_type_id, active: selectedNoteType.active },
      ],
    });
  };

  handleRemoveSuggestion = index => {
    this.setState(({ listOfSuggestions }) => ({ listOfSuggestions: listOfSuggestions.filter((_, idx) => index !== idx) }));
  };

  handleSave = () => {
    this.setState({ isSaving: true }, () => {
      const { selectedNoteType } = this.state;
      const { selectedLocation } = this.props.globalState;

      const listOfSuggestions = this.state.listOfSuggestions.map(s => {
        if (selectedNoteType.note_type_id === s.note_type_id) return { ...s, active: selectedNoteType.active };
        return s;
      });

      Service.updateSuggestions({
        note_type: selectedNoteType,
        note_suggestions: listOfSuggestions,
      })
        .then(() => {
          const note_types = selectedLocation.note_types.map(noteType => (noteType.note_type_id === selectedNoteType.note_type_id ? selectedNoteType : noteType));

          const note_suggestions = selectedLocation.note_suggestions
            .filter(suggestion => suggestion.note_type_id !== selectedNoteType.note_type_id)
            .concat(listOfSuggestions);

          this.props.updateLocation({
            ...selectedLocation,
            note_types,
            note_suggestions,
          });
          this.setState({ isSaving: false, showSuggestionsModal: false, listOfSuggestions: [], selectedNoteType: null });
        })
        .catch(error => {
          console.error("Error updating suggestions", error);
          this.setState({ isSaving: false, showSuggestionsModal: false }, () => {
            const errorMsg =
              typeof error === "string"
                ? error
                : error.message
                ? error.message
                : error.response?.data?.errors?.length
                ? error.response.data.errors[0]
                : this.props.t("error_updating_comments_suggestions").message || "Error updating commnets suggestions";
            this.msg.show(errorMsg, { type: "error" });
          });
        });
    });
  };

  renderModalHeader = note_type_id => {
    const { t } = this.props;

    switch (note_type_id) {
      default:

      case NOTE_TYPES.CALL_CUSTOMER_NOTE:
        return (
          <Header className="modal-header">
            <FontAwesomeIcon icon={faPhoneAlt} color="#21BA45" className="-margin-right-5" />
            <span>{t("call_customer_note").message || "Call Customer Note"}</span>
          </Header>
        );
      case NOTE_TYPES.PARKING_NOTE:
        return (
          <Header className="modal-header">
            <FontAwesomeIcon icon={faParking} color="#21BA45" className="-margin-right-5" />
            <span>{t("parking_location").message || "Parking Location"}</span>
          </Header>
        );
      case NOTE_TYPES.RECALL_PIN:
        return (
          <Header className="modal-header">
            <Icon color="green" name="registered outline" className="-margin-right-5" />
            <span>{t("recall_pin").message || "Recall Pin"}</span>
          </Header>
        );
      case NOTE_TYPES.REMARK_PIN:
        return (
          <Header className="modal-header">
            <Icon name="pin" color="green" className="-margin-right-5" />
            <span>{t("remark_pin").message || "Remark Pin"}</span>
          </Header>
        );

      case NOTE_TYPES.WARRANTY_PIN:
        return (
          <Header className="modal-header">
            <Icon color="green" name="shield" className="-margin-right-5" />
            <span>{t("warranty_pin").message || "Warranty Pin"}</span>
          </Header>
        );
    }
  };

  handleSort = ({ oldIndex, newIndex }) => {
    if (oldIndex === newIndex) return;

    const listOfSuggestions = [...this.state.listOfSuggestions];

    const [removedItem] = listOfSuggestions.splice(oldIndex, 1);
    listOfSuggestions.splice(newIndex, 0, removedItem);

    const newListOfSuggestions = listOfSuggestions.map((suggestion, idx) => ({ ...suggestion, order: idx + 1 }));

    this.setState({ listOfSuggestions: newListOfSuggestions });
  };

  handleInputChange = evt => {
    const index = Number(evt.target.name);

    const listOfSuggestions = this.state.listOfSuggestions.map((suggestion, idx) => {
      if (idx === index) return { ...suggestion, content: evt.target.value };
      else return suggestion;
    });

    this.setState({ listOfSuggestions, focusedInputId: evt.target.name });
  };

  handleChangeActive = active => {
    this.setState(({ selectedNoteType }) => ({ selectedNoteType: { ...selectedNoteType, active } }));
  };

  shouldCancelSort = () => !this.props.authState.user.acl["note-suggestions"]?.some(({ activity }) => activity === "update");

  renderSortableListOfSuggestion = note_type_id => {
    const cancelSort = this.shouldCancelSort();

    const SortableItem = SortableElement(({ content, order }) => {
      return (
        <div className="suggestion-content-container">
          {cancelSort ? (
            <Input disabled value={content} style={{ width: "100%" }} />
          ) : (
            <Input
              id={`order-${order}`}
              name={order}
              value={content}
              onChange={this.handleInputChange}
              onBlur={() => this.setState({ focusedInputId: null })}
              label={{ content: <Icon name="move" /> }}
              labelPosition="right"
            />
          )}

          <Can I="update" the="note-suggestions">
            <Label basic className="suggestion-content-delete-label" onClick={() => this.handleRemoveSuggestion(order)}>
              <FontAwesomeIcon icon={faTrash} color="red" />
            </Label>
          </Can>
        </div>
      );
    });

    const SortableList = SortableContainer(({ items }) => {
      return (
        <ul>
          {items.map((value, index) => {
            return <SortableItem disabled={cancelSort} key={`item-${index}`} index={index} order={index} content={value.content} />;
          })}
        </ul>
      );
    });

    return (
      <SortableList helperClass="sortableHelper" distance={1} items={this.state.listOfSuggestions} shouldCancelSort={this.shouldCancelSort} onSortEnd={this.handleSort} />
    );
  };

  renderSuggestionsModal = () => {
    const { t } = this.props;
    const { selectedNoteType, showSuggestionsModal, listOfSuggestions, isSaving } = this.state;

    if (!selectedNoteType) return null;

    const missingContent = listOfSuggestions.some(suggestion => !suggestion.content);
    const missingSuggestions = listOfSuggestions.length < 3 && selectedNoteType?.active;

    return (
      <Modal open={showSuggestionsModal}>
        <Modal.Header className="NoteSuggestionsModalHeader">{this.renderModalHeader(selectedNoteType.note_type_id)}</Modal.Header>

        <Modal.Content className="NoteSuggestionsModalContent" scrolling>
          <div className="suggestion-modal-header">{t("dropdown_answers").message || "Dropdown Answers"}</div>

          {this.renderSortableListOfSuggestion(selectedNoteType.note_type_id)}
          <Can I="update" the="note-suggestions">
            <div className="add-suggestion-actions-container">
              {listOfSuggestions.length < 10 && (
                <Button color="green" disabled={missingContent} onClick={this.handleDisplayAddNewSuggestionInput}>
                  {t("add_answer").message || "Add answer"}
                </Button>
              )}

              <Checkbox
                name="is_visible"
                toggle
                disabled={isSaving}
                checked={selectedNoteType.active}
                onChange={(_, { checked }) => this.handleChangeActive(checked)}
                label={t("visible_in_note").message || "Visible in note"}
              />
            </div>
          </Can>
          {listOfSuggestions.length < 3 && selectedNoteType.active && (
            <Message negative>
              <MessageHeader>{t("minimum_3_suggestions_required").message || "Minimum of 3 suggestions are required"}</MessageHeader>
            </Message>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button color="grey" disabled={isSaving} onClick={this.handleCloseSuggestionsModal}>
            {t("cancel").message || "Cancel"}
          </Button>

          <Can I="update" the="note-suggestions">
            <Button color="green" disabled={missingContent || missingSuggestions || isSaving} loading={isSaving} onClick={this.handleSave}>
              {t("save").message || "Save"}
            </Button>
          </Can>
        </Modal.Actions>
      </Modal>
    );
  };

  renderNoteTypeElement = ({ note_type_id, active }) => {
    const { t } = this.props;
    const { showSuggestionsModal, selectedNoteType } = this.state;

    switch (note_type_id) {
      case NOTE_TYPES.CALL_CUSTOMER_NOTE:
        return (
          <div className="note-type-container">
            <div className="note-type-info">
              <Icon name="eye" color={active ? "green" : "grey"} />
              <FontAwesomeIcon icon={faPhoneAlt} className="phone-icon" />
              <div>{t("call_customer_note").message || "Call Cusotmer Note"}</div>
            </div>
            <div className="note-type-label-container" onClick={() => this.handleOpenShowSuggestionsModal(note_type_id)}>
              <Label color="green">
                <FontAwesomeIcon icon={showSuggestionsModal ? faPlus : faEdit} />
              </Label>
            </div>
          </div>
        );

      case NOTE_TYPES.PARKING_NOTE:
        return (
          <div className="note-type-container">
            <div className="note-type-info">
              <Icon name="eye" color={active ? "green" : "grey"} />
              <FontAwesomeIcon icon={faParking} className="parking-icon" />
              <div>{t("parking_location_note").message || "Parking Location Note"}</div>
            </div>
            <div className="note-type-label-container" onClick={() => this.handleOpenShowSuggestionsModal(note_type_id)}>
              <Label color="green">
                <FontAwesomeIcon icon={showSuggestionsModal ? faPlus : faEdit} />
              </Label>
            </div>
          </div>
        );

      case NOTE_TYPES.RECALL_PIN:
        return (
          <div className="note-type-container">
            <div className="note-type-info">
              <Icon name="eye" color={active ? "green" : "grey"} />
              <Icon color="green" name="registered outline" />
              <div>{t("recall_pin").message || "Recall Pin"}</div>
            </div>
            <div className="note-type-label-container" onClick={() => this.handleOpenShowSuggestionsModal(note_type_id)}>
              <Label color="green">
                <FontAwesomeIcon icon={showSuggestionsModal ? faPlus : faEdit} />
              </Label>
            </div>
          </div>
        );

      case NOTE_TYPES.REMARK_PIN:
        return (
          <div className="note-type-container">
            <div className="note-type-info">
              <Icon name="eye" color={active ? "green" : "grey"} />
              <Icon name="pin" color="green" />
              <div>{t("remark_pin").message || "Remark Pin"}</div>
            </div>
            <div className="note-type-label-container" onClick={() => this.handleOpenShowSuggestionsModal(note_type_id)}>
              <Label color="green">
                <FontAwesomeIcon icon={showSuggestionsModal ? faPlus : faEdit} />
              </Label>
            </div>
          </div>
        );

      case NOTE_TYPES.WARRANTY_PIN:
        return (
          <div className="note-type-container">
            <div className="note-type-info">
              <Icon name="eye" color={active ? "green" : "grey"} />
              <Icon color="green" name="shield" />
              <div>{t("warranty_pin").message || "Warranty Pin"}</div>
            </div>
            <div className="note-type-label-container" onClick={() => this.handleOpenShowSuggestionsModal(note_type_id)}>
              <Label color="green">
                <FontAwesomeIcon icon={showSuggestionsModal ? faPlus : faEdit} />
              </Label>
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  render() {
    const { t, globalState } = this.props;
    const { selectedLocation } = globalState;

    return (
      <div className="NoteSuggestions">
        <div className="NoteSuggestionsHeader">{t("notes_menu").message || "Notes Menu"}</div>
        <div className="NoteSuggestionsContent">
          <div className="note-type-title">{t("note_type").message || "Note Type"}</div>

          {selectedLocation.note_types?.map(noteType => this.renderNoteTypeElement(noteType))}

          {this.renderSuggestionsModal()}
        </div>
        {this.renderAlert()}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { globalState: state.global, authState: state.auth };
};

const mapDispatchToProps = dispatch => {
  return {
    updateLocation: updatedLocation => dispatch(updateLocation(updatedLocation)),
  };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(NoteSuggestions));
