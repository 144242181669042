import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import AlertContainer from "react-alert";
import { Button } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneAlt } from "@fortawesome/free-solid-svg-icons";

import { GLOBAL_ACTION_TYPES, processedAlert } from "../App/store";

import Service from "./service";
import "./KeyLockerCallForHelp.css";

class KeylockerCallForHelp extends Component {
  state = { callForHelp: [] };

  componentDidUpdate = () => {
    const { actionType } = this.props.globalState;

    if (actionType === GLOBAL_ACTION_TYPES.ADD_KEYLOCKER_ALERT) {
      this.showAlert();
    } else if (actionType === GLOBAL_ACTION_TYPES.REMOVE_KEYLOCKER_ALERT) {
      this.removeAlert(this.props.globalState.keyLockerCallForHelpID);
    }
  };

  showAlert = () => {
    const { keyLockerCallForHelpID } = this.props.globalState;
    const { callForHelp } = this.state;

    const icon = <FontAwesomeIcon icon={faPhoneAlt} color="green" size="2x" />;
    const onClose = () => this.removeAlert(keyLockerCallForHelpID);

    callForHelp[keyLockerCallForHelpID] = this.msg.show(this.renderCallForHelp(keyLockerCallForHelpID), { icon, onClose });
    this.setState({ callForHelp });
    this.props.processedAlert();
  };

  removeAlert = keyLockerCallForHelpID => {
    const { callForHelp } = this.state;

    if (callForHelp[keyLockerCallForHelpID]) {
      const alertID = callForHelp[keyLockerCallForHelpID];
      delete callForHelp[keyLockerCallForHelpID];
      this.setState({ callForHelp }, () => {
        this.msg.removeAlert(alertID); // this will trigger onClose so it need to be done in state callback
        this.props.processedAlert();
      });
    }
  };

  handleCallForHelp = keylocker_call_for_help_id => {
    Service.handleCallForHelp({ keylocker_call_for_help_id })
      .then(() => {
        this.removeAlert(keylocker_call_for_help_id);
      })
      .catch(err => console.error(err));
  };

  renderCallForHelp = keyLockerCallForHelpID => {
    const { t } = this.props;

    return (
      <div className="KeyLockerCallForHelp">
        <p>{t("call_for_help_message").message || "A customer is calling for help at the key locker"}</p>
        <div className="keylocker_buttons">
          <Button color="green" onClick={() => this.handleCallForHelp(keyLockerCallForHelpID)}>
            {t("handle_call").message || "Handle call"}
          </Button>
        </div>
      </div>
    );
  };

  render() {
    const props = {
      position: "top right",
      theme: "light",
      time: 0,
      transition: "fade",
    };

    return <AlertContainer ref={a => (this.msg = a)} {...props} />;
  }
}

const mapStateToProps = state => {
  return { globalState: state.global };
};

const mapDispatchToProps = dispatch => ({
  processedAlert: () => dispatch(processedAlert()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(KeylockerCallForHelp));
