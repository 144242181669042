import React from "react";
import { Button, Form, Icon, Input, Message, Popup } from "semantic-ui-react";
import ResetPasswordHeader from "./ResetPasswordHeader";

const renderPassIcon = (type, newPwd, newPwdCon, strength, t) => {
  let r = null;
  let c = "";

  if (type === "new" && newPwd && newPwd.length > 0) {
    if (strength >= 1) {
      r = <Icon name="check circle outline" className="reset-indicator good" />;
      c = t("new_password_secure").message || "New password looks good";
    } else {
      r = <Icon name="times circle outline" className="reset-indicator bad" />;
      c = t("new_password_not_secure").message || "New password is not secure enough";
    }
  }

  if (type === "confirm" && newPwdCon && newPwdCon.length > 0) {
    if (strength >= 1 && newPwd === newPwdCon) {
      r = <Icon name="check circle outline" className="reset-indicator good" />;
      c = t("new_confirm_password_secure").message || "New password confirmation looks good";
    } else {
      r = <Icon name="times circle outline" className="reset-indicator bad" />;
      c = t("new_confirm_password_not_secure").message || "Confirmation is not the same as password or not secure enough";
    }
  }

  return <Popup trigger={r} content={c} />;
};

const DEFAULT_INSTRUCTIONS =
  "Your new password must be secure. A secure password cannot contain your first na" +
  "me, last name, email, ‘claire’ or common words like ‘password’. You don’t need t" +
  "o use any special characters. A secure password contains two or more uncommon wo" +
  "rds.";

const ResetPassword = ({
  handleNavigateToAction,
  className,
  error,
  errorMessage,
  isLoading,
  isResetSuccessful,
  isSuccessful,
  warning,
  newPassword,
  newPasswordStrength,
  newPasswordConfirmation,
  onNewPasswordChange,
  onResetSubmit,
  t,
  hasNewPasswordConfirmationError,
  hasNewPasswordError,
}) => {
  return (
    <div className={"ResetPassword " + className}>
      <ResetPasswordHeader t={t} handleNavigateToAction={handleNavigateToAction} />

      <Form warning={warning} error={!isSuccessful && error}>
        {!isResetSuccessful && (
          <span>
            <div className="text-justify --margin-bottom-20">{t("reset_password_instructions").message || DEFAULT_INSTRUCTIONS}</div>
            {newPassword.length > 0 && (
              <div className="strength-meter">
                <div className="strength-meter-fill" data-strength={newPasswordStrength}></div>
              </div>
            )}

            <Form.Field
              style={{
                position: "relative",
              }}
            >
              <Input
                name="newPassword"
                placeholder={t("new_password").message || "new password"}
                autoFocus
                error={hasNewPasswordError}
                size="large"
                value={newPassword}
                type="password"
                onChange={onNewPasswordChange}
              />{" "}
              {renderPassIcon("new", newPassword, newPasswordConfirmation, newPasswordStrength, t)}
            </Form.Field>

            <Form.Field
              style={{
                position: "relative",
              }}
            >
              <Input
                name="newPasswordConfirmation"
                placeholder={t("confirm_new_password").message || "Confirm new password"}
                error={hasNewPasswordConfirmationError}
                size="large"
                value={newPasswordConfirmation}
                type="password"
                onChange={onNewPasswordChange}
              />{" "}
              {renderPassIcon("confirm", newPassword, newPasswordConfirmation, newPasswordStrength, t)}
            </Form.Field>

            <Button
              type="submit"
              onClick={onResetSubmit}
              loading={isLoading}
              disabled={newPasswordStrength < 3 || newPassword !== newPasswordConfirmation}
              positive
              fluid
              size="large"
              className="-margin-bottom-10"
            >
              {t("set_new_password").message || "Set new password"}
            </Button>

            <Message warning header={t("please_check_your_input").message || "Check your input"} />
            <Message error header={t("request_problem").message || "There was a problem processing your request"} content={errorMessage} />
          </span>
        )}

        {isResetSuccessful && <Message color="green" header={t("new_password_set").message || "Your new password has been successfully set."} />}
      </Form>
    </div>
  );
};

export default ResetPassword;
