import React from "react";
import { Checkbox, Dropdown, Form, Grid } from "semantic-ui-react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import moment from "moment";
import DatePicker from "react-datepicker";

import { PIN_STATUS, PIN_TYPE } from "../Pin/enum";
import { renderPinTypeIcon } from "../Pin/util";
import DealerLocationsDropdown from "../DealerLocationsDropdown";
import { MANUFACTURER_REQUEST_STATUSES } from "../../modules/ManufacturerDashboard/request_statuses";

import "./PinFilters.css";

const PinFilters = ({
  isLoadingPinOptions,
  filterPeriod,
  selectedPeriod,
  onChangePeriod,
  customDateFrom,
  handleChangeDateFrom,
  customDateTo,
  handleChangeDateTo,
  pinTypesOptions,
  selectedPinType,
  onChangePinType,
  warrantyTypeOptions,
  selectedWarrantyType,
  onChangeWarrantyType,
  pinStatusOptions,
  selectedPinStatus,
  onChangePinStatus,
  selectedMechanicFixed,
  onChangeMechanicFixed,
  selectedQuestionStatus,
  onChangeQuestionStatus,
  filterLocations,
  onChangeSelectedLocationAndDealerIDs,
  onChangeDealerLocationFilter,
  filterManufacturerRequestStatuses,
  selectedManufacturerRequest,
  onChangeManufacturerRequest,
  showDealerLocationDropdown,
  brandOptions,
  selectedBrands,
  onChangeBrands,
  selectedDeleted,
  onChangeDeleted,
  selectedDealersIDs,
  selectedLocationsIDs,
  selectedClaimed,
  onChangeClaimed,
  t,
}) => {
  const timePeriodOptions = [
    {
      key: 1,
      text: t("pinned_in_last_month").message || "Pinned in last month",
      value: 1,
    },
    {
      key: 2,
      text: t("pinned_in_last_3_months").message || "Pinned in last 3 months",
      value: 3,
    },
    {
      key: 3,
      text: t("pinned_in_last_6_months").message || "Pinned in last 6 months",
      value: 6,
    },
    {
      key: 4,
      text: t("pinned_in_last_9_months").message || "Pinned in last 9 months",
      value: 9,
    },
    {
      key: 5,
      text: t("pinned_in_last_12_months").message || "Pinned in last 12 months",
      value: 12,
    },
    {
      key: 6,
      text: t("pinned_in_last_24_months").message || "Pinned in last 24 months",
      value: 24,
    },
    {
      key: 7,
      text: t("custom_pin_dates").message || "Custom pin dates",
      value: -1,
    },
  ];

  const questionStatusOptions = [
    {
      key: 2,
      text: t("advise").message || "Advise",
      value: 2,
    },
    {
      key: 3,
      text: t("critical").message || "Critical",
      value: 3,
    },
  ];

  const mechanicFixedOptions = [
    {
      key: 1,
      text: t("yes").message || "Yes",
      value: true,
    },
    {
      key: 2,
      text: t("no").message || "No",
      value: false,
    },
  ];

  const manufacturerRequestOptions = [
    {
      key: MANUFACTURER_REQUEST_STATUSES.PENDING,
      text: t("pending").message || "Pending",
      value: MANUFACTURER_REQUEST_STATUSES.PENDING,
    },
    {
      key: MANUFACTURER_REQUEST_STATUSES.APPROVED,
      text: t("approved").message || "Approved",
      value: MANUFACTURER_REQUEST_STATUSES.APPROVED,
    },
    {
      key: MANUFACTURER_REQUEST_STATUSES.DECLINED,
      text: t("declined").message || "Declined",
      value: MANUFACTURER_REQUEST_STATUSES.DECLINED,
    },
  ];

  const renderCustomRageDatePicker = () => {
    if (selectedPeriod !== -1) return null;

    return (
      <div className="pin-dropdown-datepicker">
        <h4>{t("date_from").message || "Date from"}: </h4>
        <DatePicker
          dateFormat="dd-MM-yyyy"
          placeholderText={t("date_from").message || "Date from"}
          selected={moment(customDateFrom).toDate()}
          onChange={date => handleChangeDateFrom(moment(date))}
          popperPlacement="bottom"
          popperModifiers={{
            flip: {
              behavior: ["bottom"],
            },
            preventOverflow: {
              enabled: false,
            },
            hide: {
              enabled: false,
            },
          }}
          onChangeRaw={e => e.preventDefault()}
          showMonthDropdown
          showYearDropdown
        />

        <h4>{t("date_to").message || "Date to"}: </h4>
        <DatePicker
          dateFormat="dd-MM-yyyy"
          placeholderText={t("date_to").message || "Date to"}
          selected={moment(customDateTo).toDate()}
          minDate={moment(customDateFrom).add(1, "days").toDate()}
          onChange={date => handleChangeDateTo(moment(date))}
          popperPlacement="bottom"
          popperModifiers={{
            flip: {
              behavior: ["bottom"],
            },
            preventOverflow: {
              enabled: false,
            },
            hide: {
              enabled: false,
            },
          }}
          onChangeRaw={e => e.preventDefault()}
          showMonthDropdown
          showYearDropdown
        />
      </div>
    );
  };

  const dropdownOptions = options => Object.keys(options).map(o => ({ value: Number(o), key: o, text: t(options[o]).message || options[o] }));

  return (
    <Form>
      <Grid>
        <Grid.Row className="PinFilters">
          <Grid.Column width={16}>
            <Form.Group className="pin-filter-group">
              {showDealerLocationDropdown && (
                <Form.Field className="pin-dropdowns wide-dropdown">
                  <DealerLocationsDropdown options={{ noDefault: true }} onChange={onChangeDealerLocationFilter} selectDealers={true} />
                </Form.Field>
              )}

              {filterPeriod && (
                <Form.Field className="pin-dropdowns">
                  <Dropdown
                    className="wide-dropdown"
                    selectOnBlur={false}
                    placeholder={t("time_period").message || "Time period"}
                    value={selectedPeriod}
                    onChange={(e, data) => onChangePeriod(data.value)}
                    selection
                    fluid
                    options={timePeriodOptions}
                  />
                </Form.Field>
              )}

              <Form.Field className="pin-dropdowns">
                <Dropdown
                  selectOnBlur={false}
                  placeholder={isLoadingPinOptions ? t("loading").message || "Loading..." : t("pin_type").message || "Pin Type"}
                  value={selectedPinType}
                  onChange={(e, data) => onChangePinType(data.value)}
                  selection
                  clearable
                  fluid
                  multiple
                  options={dropdownOptions(pinTypesOptions).map(o => ({
                    ...o,
                    label: <span style={{ marginRight: "4.5px" }}>{renderPinTypeIcon(o.value)}</span>,
                  }))}
                />
              </Form.Field>
              <Form.Field className="pin-dropdowns">
                <Dropdown
                  className="wide-dropdown"
                  selectOnBlur={false}
                  placeholder={isLoadingPinOptions ? t("loading").message || "Loading..." : t("warranty_type").message || "Warranty Type"}
                  value={selectedWarrantyType}
                  onChange={(e, data) => onChangeWarrantyType(data.value)}
                  selection
                  clearable
                  fluid
                  disabled={
                    Array.isArray(selectedPinType)
                      ? selectedPinType.length > 0 && !selectedPinType.includes(PIN_TYPE.WARRANTY)
                      : [PIN_TYPE.REMARKS, PIN_TYPE.RECALL].includes(selectedPinType)
                  }
                  options={dropdownOptions(warrantyTypeOptions)}
                />
              </Form.Field>
            </Form.Group>
          </Grid.Column>
          <Grid.Column width={16}>
            <Form.Group className="pin-filter-group">
              <Form.Field className="pin-dropdowns">
                <Dropdown
                  selectOnBlur={false}
                  placeholder={isLoadingPinOptions ? t("loading").message || "Loading..." : t("pin_status").message || "Pin Status"}
                  value={selectedPinStatus}
                  onChange={(e, data) => onChangePinStatus(data.value)}
                  selection
                  clearable
                  fluid
                  multiple
                  disabled={
                    (Array.isArray(selectedPinType) ? selectedPinType.length === 1 && selectedPinType[0] === PIN_TYPE.REMARKS : selectedPinType === PIN_TYPE.REMARKS) ||
                    selectedDeleted
                  }
                  options={onChangeClaimed ? dropdownOptions(pinStatusOptions).filter(status => status.value !== PIN_STATUS.CLAIMED) : dropdownOptions(pinStatusOptions)}
                />
              </Form.Field>

              {onChangeClaimed && (
                <Form.Field className="pin-dropdowns pin-toggle">
                  <Checkbox
                    toggle
                    name="claimed"
                    checked={selectedClaimed}
                    label={t("claimed").message || "Claimed"}
                    disabled={(selectedPinType.length === 1 && selectedPinType[0] === PIN_TYPE.REMARKS) || selectedDeleted}
                    onChange={(e, data) => onChangeClaimed(data.checked)}
                  />
                </Form.Field>
              )}

              {onChangeDeleted && (
                <Form.Field className="pin-dropdowns pin-toggle">
                  <Checkbox
                    toggle
                    name="deleted"
                    checked={selectedDeleted}
                    label={t("deleted").message || "Deleted"}
                    onChange={(e, data) => onChangeDeleted(data.checked)}
                  />
                </Form.Field>
              )}

              <Form.Field className="pin-dropdowns">
                <Dropdown
                  selectOnBlur={false}
                  placeholder={t("question_status").message || "Question Status"}
                  value={selectedQuestionStatus}
                  onChange={(e, data) => onChangeQuestionStatus(data.value)}
                  selection
                  clearable
                  fluid
                  options={questionStatusOptions}
                />
              </Form.Field>

              <Form.Field className="pin-dropdowns">
                <Dropdown
                  selectOnBlur={false}
                  placeholder={t("mechanic_fixed").message || "Mechanic Fixed"}
                  value={selectedMechanicFixed}
                  onChange={(e, data) => onChangeMechanicFixed(data.value)}
                  selection
                  fluid
                  clearable
                  options={mechanicFixedOptions}
                />
              </Form.Field>

              {onChangeBrands && (
                <Form.Field className="pin-dropdowns">
                  <Dropdown
                    selectOnBlur={false}
                    placeholder={t("brands").message || "Brands"}
                    value={selectedBrands}
                    onChange={(e, data) => onChangeBrands(data.value)}
                    selection
                    fluid
                    clearable
                    multiple
                    search
                    options={brandOptions}
                  />
                </Form.Field>
              )}

              {filterManufacturerRequestStatuses && (
                <Form.Field className="pin-dropdowns">
                  <Dropdown
                    className="wide-dropdown"
                    selectOnBlur={false}
                    placeholder={t("manufacturer_requests").message || "Manufacturer requests"}
                    value={selectedManufacturerRequest}
                    onChange={(e, data) => onChangeManufacturerRequest(data.value)}
                    selection
                    fluid
                    clearable
                    options={manufacturerRequestOptions}
                  />
                </Form.Field>
              )}
              {filterLocations && (
                <Form.Field className="pin-dropdowns wide-dropdown">
                  <DealerLocationsDropdown
                    selectAll={false}
                    selectDealers={true}
                    onChange={onChangeSelectedLocationAndDealerIDs}
                    options={selectedDealersIDs.length || selectedLocationsIDs.length ? { dealer_ids: selectedDealersIDs, location_ids: selectedLocationsIDs } : null}
                  />
                </Form.Field>
              )}
            </Form.Group>
          </Grid.Column>
        </Grid.Row>

        {filterPeriod && renderCustomRageDatePicker()}
      </Grid>
    </Form>
  );
};

const mapStateToProps = state => {
  return { globalState: state.global, authState: state.auth };
};

export default withTranslation()(connect(mapStateToProps, null)(PinFilters));
