import React, { Component } from "react";
import Loader from "react-loader-spinner";
import Service from "./service";
import { SimpleBarChart } from "../LocationReports/charts/SimpleBarChart";
import { withTranslation } from "react-i18next";
import moment from "moment";
import { saveAs } from "file-saver";

class EmailStats extends Component {
  state = {
    isLoading: false,
    data: [],
    error: "",
  };

  componentDidMount() {
    this.loadData(this.props.period, this.props.dealerID);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.period !== this.props.period || nextProps.dealerID !== this.props.dealerID) {
      this.loadData(nextProps.period, nextProps.dealerID);
    }
  }

  loadData = (period, dealerID) => {
    this.setState({ isLoading: true }, () => {
      Service.getEmailStats(dealerID, period)
        .then(result => {
          this.setState({ isLoading: false, error: "", data: this.processData(result.data?.data) });
        })
        .catch(error => {
          let err = error.response && error.response.data && Array.isArray(error.response.data.errors) && error.response.data.errors[0];
          if (!err) err = "Unkown error";
          this.setState({ error: this.props.t(err).message || err, isLoading: false });
          console.log("Error while fetching email stats: ", error);
        });
    });
  };

  processData = input => {
    let data = [];

    if (input && input.length > 0) {
      let accepted = 0;
      let delivered = 0;
      let failed = 0;
      let stored = 0;
      let complained = 0;

      input.forEach(item => {
        accepted += item.accepted.total;
        delivered += item.delivered.total;
        failed += item.failed.permanent.total;
        stored += item.stored.total;
        complained += item.complained.total;
      });

      data = [
        { [this.props.t("accepted").message || "Accepted"]: accepted },
        { [this.props.t("delivered").message || "Delivered"]: delivered },
        { [this.props.t("failed").message || "Failed"]: failed },
        { [this.props.t("stored").message || "Stored"]: stored },
        { [this.props.t("complained").message || "Complained"]: complained },
      ];
    }

    return data;
  };

  handleExportReport = () => {
    let data = this.state.data;

    let exportData = [
      [
        this.props.t("accepted").message || "Accepted",
        this.props.t("delivered").message || "Delivered",
        this.props.t("failed").message || "Failed",
        this.props.t("stored").message || "Stored",
        this.props.t("complained").message || "Complained",
      ],
      [Object.values(data[0])[0], Object.values(data[1])[0], Object.values(data[2])[0], Object.values(data[3])[0], Object.values(data[4])[0]],
    ].join("\n");

    saveAs(new Blob([exportData], { type: "text/csv;charset=utf-8" }), "EmailStatistic" + moment().format("Y-m-d H:i:s") + ".csv");
  };

  render() {
    let { isLoading, data, error } = this.state;

    if (error) return <h1 className="no-data-header">{error}</h1>;

    if (isLoading)
      return (
        <div className="Loader">
          <Loader type="Oval" color="#46923d" height="100" width="100" />
        </div>
      );

    return (
      <div className={"report-full-width"}>
        <div className={"report-box-full-width"}>
          <h3 className="report-heading">{this.props.t("email_stats").message || "Email statistics"}</h3>
          {data.length < 1 && <p className="no-data">{this.props.t("no_data").message || "No data"}</p>}
          {data.length > 0 && <SimpleBarChart t={this.props.t} data={data} handleExportReport={this.handleExportReport} />}
        </div>
      </div>
    );
  }
}

export default withTranslation()(EmailStats);
