import React, { Component } from "react";
import { withTranslation } from "react-i18next";

class Picture extends Component {
  render() {
    return <div></div>;
  }
}

export default withTranslation()(Picture);
