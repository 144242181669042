import axios from "axios";
import { resetAppointmentsStore } from "./modules/Appointments/store";
import { resetGlobalStore } from "./modules/App/store";
import { logout } from "./modules/Auth/store";

const setupInterceptors = store => {
  axios.interceptors.response.use(
    res => {
      if (res.status === 401) {
        store.dispatch(logout());
        store.dispatch(resetAppointmentsStore());
        store.dispatch(resetGlobalStore());
        return;
      }

      return res;
    },
    err => {
      if (err.response.status === 401) {
        store.dispatch(logout());
        store.dispatch(resetAppointmentsStore());
        store.dispatch(resetGlobalStore());
        return;
      }

      return Promise.reject(err);
    }
  );
};

export default setupInterceptors;
