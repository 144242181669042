import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import MilageChart from "./MileageChart";
import { formatDateIfValid } from "../../util/common";

import "./CarDrivingTrend.css";

class CarDrivingTrend extends Component {
  constructor(props) {
    super(props);

    this.state = { ...this.prepareData(props) };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.appointmentHistory !== this.props.appointmentHistory) {
      this.setState({ ...this.prepareData(this.props) });
    }
  }

  prepareData = props => {
    const { appointmentHistory } = props;

    if (!Array.isArray(appointmentHistory) || appointmentHistory.length < 2) return { isMilageChartVisible: false };

    const lineData = [];
    const bubbleData = [];
    const relevantDataForAverageCalculation = [];
    const kmChartColors = { backgroundColors: [] };
    let current_km = -1;

    [...appointmentHistory].reverse().forEach(a => {
      const date = new Date(a.date);

      if (a.current_km > current_km) {
        current_km = a.current_km;
        kmChartColors.backgroundColors.push("#2D4473");
        lineData.push({ x: date, y: a.current_km, r: 6 });
        relevantDataForAverageCalculation.push({ km: a.current_km, time: date });
      } else {
        kmChartColors.backgroundColors.push("#FF0000");
      }

      bubbleData.push({ x: date, y: a.current_km, r: 6 });
    });

    if (relevantDataForAverageCalculation.length < 2) return { isMilageChartVisible: false };

    const totalAverageKM = this.getAverageKM(relevantDataForAverageCalculation[0], relevantDataForAverageCalculation.last);

    return {
      isMilageChartVisible: true,
      kmChartColors,
      bubbleData,
      lineData,
      totalAverageKM: {
        color: this.getColorByAverageKM(totalAverageKM),
        day: totalAverageKM,
        month: 30 * totalAverageKM,
        year: 365 * totalAverageKM,
      },
    };
  };

  getAverageKM = (first, second) => {
    let timeDiff = second.time - first.time;
    let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
    let kmDiff = second.km - first.km;
    return Math.floor(kmDiff / diffDays);
  };

  getColorByAverageKM = averageKM => {
    if (averageKM <= 30) {
      return "rgb(135, 206, 235)";
    } else if (averageKM >= 30 && averageKM < 50) {
      return "rgb(175,196,131)";
    } else if (averageKM >= 50 && averageKM < 70) {
      return "rgb(252,162,78)";
    } else if (averageKM >= 70 && averageKM < 100) {
      return "rgb(224,126,96)";
    } else {
      return "rgb(191,59,59)";
    }
  };

  render() {
    const { isMilageChartVisible, totalAverageKM, kmChartColors, bubbleData, lineData } = this.state;
    const { t, car_lead_prediction } = this.props;

    return (
      <div className="CarDrivingTrend CarDetailContainer">
        <h3>{t("driving_trend").message || "Driving trend"}</h3>
        {car_lead_prediction && (
          <p>
            {t("predicted_date").message || "Predicted next maintenance"}: {formatDateIfValid(car_lead_prediction.predicted_date)}
          </p>
        )}

        {!isMilageChartVisible && <div className="NoDataAvailable">{t("no_data_available").message || "No data available"}</div>}

        {isMilageChartVisible && (
          <div className="MileageChart-Wrapper">
            <div style={{ marginBottom: "0.5em", fontSize: "1.2em" }}>
              {t("total_car_driving_trend").message || "Total driving trend"} ( {t("average").message || "Average"}&nbsp;
              <span style={{ color: totalAverageKM.color }}>{totalAverageKM.day}</span> km {t("per_day").message || "Per day"},&nbsp;
              <span style={{ color: totalAverageKM.color }}>{totalAverageKM.month}</span> km {t("per_month").message || "Per month"},&nbsp;
              <span style={{ color: totalAverageKM.color }}>{totalAverageKM.year}</span> km {t("per_year").message || "Per year"} )
            </div>
            <MilageChart colors={kmChartColors} bubbleData={bubbleData} lineData={lineData} />
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation()(CarDrivingTrend);
