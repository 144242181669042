import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { GoogleLogin } from "@react-oauth/google";
import { withTranslation } from "react-i18next";
import { Grid, Input, Button, Form, Message, Divider } from "semantic-ui-react";

import QrLogin from "./QRLogin";

const LoginForm = ({
  handleNavigateToAction,
  className,
  credentials,
  warning,
  error,
  hasUsernameError,
  hasPasswordError,
  errorMessage,
  isLoading,
  onInputChange,
  onSubmit,
  onGoogleResponse,
  handleLoginViaQR,
  t,
}) => {
  const [googleButtonWidth, setGoogleButtonWidth] = useState(null);

  const onSetButtonWidth = () => {
    const loginButton = document.getElementById("google-login-button");

    setGoogleButtonWidth(loginButton?.clientWidth > 400 ? 400 : loginButton.clientWidth);
  };

  useEffect(() => {
    onSetButtonWidth();

    window.addEventListener("resize", onSetButtonWidth);

    return () => {
      window.removeEventListener("resize", onSetButtonWidth);
    };
  }, []);

  return (
    <div className={"LoginForm " + className}>
      <Grid>
        <Grid.Column width={7} className="-no-padding-left -no-padding-top">
          <h2>{t("login").message || "Login"}</h2>
        </Grid.Column>
        <Grid.Column width={9} className="-padding-top-20 -no-padding-right -no-padding-top -text-right">
          <a
            href="#!/reset-password-request"
            id="forgot-password"
            style={{ cursor: "pointer" }}
            onClick={event => {
              event.preventDefault();
              handleNavigateToAction("/reset-password-request");
              return false;
            }}
          >
            {t("forgot_password").message || "Forgot your password"}
          </a>
        </Grid.Column>
      </Grid>

      <Form warning={warning} error={error}>
        <Form.Field>
          <Input
            name="username"
            placeholder={t("username_or_email").message || "username or email"}
            autoFocus
            error={hasUsernameError}
            size="large"
            value={credentials.username}
            onChange={onInputChange}
          />
        </Form.Field>
        <Form.Field>
          <Input
            name="password"
            placeholder={t("password").message || "password"}
            type="password"
            error={hasPasswordError}
            size="large"
            value={credentials.password}
            onChange={onInputChange}
          />
        </Form.Field>

        <Button type="submit" id="submit-login" onClick={onSubmit} loading={isLoading} positive fluid size="large" className="-margin-bottom-10">
          {t("login").message || "Login"}
        </Button>

        <Divider className="LightGray" horizontal>
          {t("or").message || "OR"}
        </Divider>

        <div id="google-login-button">
          <GoogleLogin width={googleButtonWidth} onSuccess={onGoogleResponse} onError={onGoogleResponse} />
        </div>
        <br />

        <QrLogin handleLoginViaQR={handleLoginViaQR} />

        <Message
          warning
          id="required-credentials"
          message="Fill username and password"
          header={t("fill_username_and_password").message || "Fill in username and password"}
        />
        <Message
          error
          id="cant-login"
          message="Can't log you in"
          header={t("cant_login").message || "Can't log you in"}
          content={errorMessage ? (typeof errorMessage === "string" ? t(errorMessage).message || errorMessage : errorMessage) : null}
        />
      </Form>
    </div>
  );
};

LoginForm.propTypes = {
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
};

export default withTranslation()(LoginForm);
