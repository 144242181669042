import { connect } from "react-redux";

import Login from "./Login";
import { getToken, getTokenViaGoogle, logoutSuccess, requestResetPassword, resetPassword, loginViaQR } from "./store";

const mapStateToProps = state => {
  return { authState: state.auth };
};

const mapDispatchToProps = dispatch => {
  return {
    loginViaQR: (token, zoho_asap_jwt, appointment_id) => dispatch(loginViaQR(token, zoho_asap_jwt, appointment_id)),
    getToken: user => dispatch(getToken(user)),
    getTokenViaGoogle: data => dispatch(getTokenViaGoogle(data)),
    logoutSuccess: () => dispatch(logoutSuccess()),
    forgotPassword: data => dispatch(requestResetPassword(data)),
    resetPassword: data => dispatch(resetPassword(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
