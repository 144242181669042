import React, { Component } from "react";
import { Grid } from "semantic-ui-react";
import i18n from "i18next";
import { withTranslation } from "react-i18next";
import { Dropdown } from "semantic-ui-react";
import { Route, withRouter } from "react-router-dom";
import zxcvbn from "zxcvbn";
import { getBrowserLanguage } from "../../util/common";

import APP from "./../../util/app-config";
import LoginPromo from "./LoginPromo";
import LoginForm from "./LoginForm";
import ResetPassword from "./ResetPassword";
import "./Login.css";
import bgImage from "./bg-login-rf-2.jpg";
import UrlSearchParams from "../../util/UrlSearchParams";
import PasswordResetRequest from "./ResetPasswordRequest";

class Login extends Component {
  state = {
    currentAction: "login",
    credentials: {
      username: "",
      password: "",
    },
    requestResetPasswordEmail: "",
    isArticleDetailVisible: false,
    isWarningVisible: false,
    isResetPasswordWarningVisible: false,
    newPassword: "",
    newPasswordStrength: 0,
    newPasswordConfirmation: "",
    selectedLanguage: "",
  };

  componentDidMount() {
    if (this.props.match.path === "/reset-password") {
      this.setState({ currentAction: "password-reset" });
    }

    const LS = localStorage.getItem("_cl-last-user");

    if (LS && typeof LS === "string") {
      const LAST_USER = JSON.parse(LS);
      if (LAST_USER) {
        this.setState({
          credentials: {
            username: LAST_USER.username,
            password: "",
          },
        });
      }
    }

    const language = getBrowserLanguage();
    this.setState({
      selectedLanguage: language,
    });
  }

  isFormValid = () => {
    const hasUsernameError = !this.state.credentials.username;
    const hasPasswordError = !this.state.credentials.password;

    this.setState({ hasUsernameError, hasPasswordError });

    return !(hasUsernameError || hasPasswordError);
  };

  handleLoginSubmit = event => {
    if (!this.isFormValid()) {
      this.setState({ isWarningVisible: true });
      return;
    }

    this.setState(
      {
        isWarningVisible: false,
      },
      () => {
        this.props.getToken(this.state.credentials);
      }
    );
  };

  handleGoogleResponse = token => {
    this.setState({ isWarningVisible: false }, () => this.props.getTokenViaGoogle(token?.credential));
  };

  handleInputChange = event => {
    let credentials = this.state.credentials;
    credentials[event.target.name] = event.target.value;

    this.setState({ credentials });
  };

  // Password Resetting
  handleRequestResetPasswordInputChange = event => {
    this.setState({ requestResetPasswordEmail: event.target.value });
  };

  handleRequestResetPasswordSubmit = event => {
    if (!this.state.requestResetPasswordEmail || !this.isEmailValid(this.state.requestResetPasswordEmail)) {
      this.setState({ isResetPasswordWarningVisible: true });
      return;
    }

    this.setState(
      {
        isResetPasswordWarningVisible: false,
      },
      () => {
        this.props.forgotPassword({
          email: this.state.requestResetPasswordEmail,
          hash: window.location.origin,
          language_code: this.state.selectedLanguage,
        });
      }
    );
  };

  isEmailValid = email => {
    // eslint-disable-next-line
    const R = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return R.test(String(email).toLowerCase());
  };

  // Navigate between available actions
  handleNavigateToAction = nextAction => {
    this.props.logoutSuccess();

    let credentials = this.state.credentials;
    credentials.password = "";

    this.setState(
      {
        hasError: false,
        isWarningVisible: false,
        credentials,
      },
      () => {
        this.props.history.push(nextAction);
      }
    );
  };

  handleNewPasswordInputChange = event => {
    let { newPassword, newPasswordStrength, newPasswordConfirmation } = this.state;

    if (event.target.name === "newPassword") {
      newPassword = event.target.value;
      newPasswordStrength = zxcvbn(newPassword, ["claire", "auto"]).score;
    }

    if (event.target.name === "newPasswordConfirmation") {
      newPasswordConfirmation = event.target.value;
    }

    this.setState({
      newPassword,
      newPasswordStrength,
      newPasswordConfirmation,
    });
  };

  handleResetPasswordSubmit = event => {
    event.preventDefault();

    let hash = new UrlSearchParams(this.props.location.search).get("hash");
    if (!hash) {
      return;
    }

    this.props.resetPassword({
      new_password: this.state.newPassword,
      new_password_confirm: this.state.newPasswordConfirmation,
      hash,
    });
  };

  handleLanguageChange = (e, data) => {
    let selectedLanguage = localStorage.getItem("locale");

    if (selectedLanguage !== data.value) {
      this.setState({ selectedLanguage: data.value }, () => {
        localStorage.setItem("locale", data.value);
        i18n.changeLanguage(data.value);
        i18n.reloadResources();
        window.location.reload();
      });
    }
  };

  render() {
    const isPromoVisible = this.state.currentAction !== "password-reset";

    return (
      <div
        className="Login"
        style={{
          backgroundImage: `url(${bgImage})`,
        }}
      >
        <div className="Login__overlay" />
        <Grid centered={!isPromoVisible} verticalAlign="middle">
          <Grid.Column width={1}></Grid.Column>
          {isPromoVisible && (
            <Grid.Column width={8} className="-no-padding -z-index-100">
              {this.state.fetchedNews && <LoginPromo newsArticles={this.state.newsArticles} onReadMore={this.handleShowArticleDetail} />}
            </Grid.Column>
          )}
          <Grid.Column width={6} floated={isPromoVisible ? "right" : null} className="Login__gate__container -z-index-100">
            <div className="Login__gate__container__inner">
              <h1>
                <span>Claire</span>
                &nbsp; Automotive Support
              </h1>
              <div className="Login__gate__container__inner__actions">
                <Route
                  exact
                  path="/login"
                  render={() => (
                    <LoginForm
                      className="Login__form --login-action"
                      credentials={this.state.credentials}
                      warning={this.state.isWarningVisible}
                      error={this.props.authState.hasError}
                      hasUsernameError={this.state.hasUsernameError}
                      hasPasswordError={this.state.hasPasswordError}
                      errorMessage={this.props.authState.errorMessage}
                      isLoading={this.props.authState.isLoading}
                      onInputChange={this.handleInputChange}
                      onSubmit={this.handleLoginSubmit}
                      t={this.props.t}
                      onGoogleResponse={this.handleGoogleResponse}
                      handleNavigateToAction={this.handleNavigateToAction}
                      handleLoginViaQR={this.props.loginViaQR}
                    />
                  )}
                ></Route>

                <Route
                  exact
                  path="/reset-password"
                  render={() => (
                    <ResetPassword
                      className="--login-action"
                      error={this.props.authState.hasError}
                      errorMessage={this.props.authState.errorMessage}
                      isLoading={this.props.authState.isLoading}
                      isResetSuccessful={this.props.authState.isResetPasswordSuccessful}
                      isSuccessful={this.props.authState.isResetPasswordSuccessful}
                      warning={this.state.isResetPasswordWarningVisible}
                      newPassword={this.state.newPassword}
                      newPasswordStrength={this.state.newPasswordStrength}
                      newPasswordConfirmation={this.state.newPasswordConfirmation}
                      onNewPasswordChange={this.handleNewPasswordInputChange}
                      onResetSubmit={e => this.handleResetPasswordSubmit(e)}
                      t={this.props.t}
                      handleNavigateToAction={this.handleNavigateToAction}
                    />
                  )}
                ></Route>

                <Route
                  exact
                  path="/reset-password-request"
                  render={() => (
                    <PasswordResetRequest
                      className="--login-action"
                      error={this.props.authState.hasError}
                      errorMessage={this.props.authState.errorMessage}
                      isLoading={this.props.authState.isLoading}
                      isRequestSuccessful={this.props.authState.isRequestResetPasswordSuccessful}
                      onInputChange={this.handleRequestResetPasswordInputChange}
                      onSubmit={this.handleRequestResetPasswordSubmit}
                      warning={this.state.isResetPasswordWarningVisible}
                      t={this.props.t}
                      handleNavigateToAction={this.handleNavigateToAction}
                    />
                  )}
                ></Route>
              </div>

              <br />

              <label>{this.props.t("select_language").message || "Select language"}</label>

              <Dropdown
                fluid
                selection
                options={[
                  {
                    text: "Dutch",
                    value: "nl-NL",
                    key: "nl",
                  },
                  {
                    text: "German",
                    value: "de-DE",
                    key: "de",
                  },
                  {
                    text: "French",
                    value: "fr-FR",
                    key: "fr",
                  },
                  {
                    text: "English",
                    value: "en-GB",
                    key: "en",
                  },
                ]}
                value={this.state.selectedLanguage}
                onChange={this.handleLanguageChange}
              />
            </div>
          </Grid.Column>
          <Grid.Column width={1}></Grid.Column>
          <Grid.Column width={16}>
            <div className="Login__info -z-index-99">
              <p>
                {APP.version}
                &nbsp;&nbsp;|&nbsp;&nbsp;
                <a href="https://www.claireautomotive.com" target="_blank" rel="noopener noreferrer" className="-link-plain">
                  clairesoftware.nl
                </a>
                &nbsp;&nbsp;|&nbsp;&nbsp;{" "}
                <a href="https://www.claireautomotive.com/support" target="_blank" rel="noopener noreferrer" className="-link-plain">
                  {this.props.t("support").message || "Support"}
                </a>{" "}
                &nbsp;&nbsp;|&nbsp;&nbsp; +31 (0)85 002 0211
              </p>
            </div>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

export default withRouter(withTranslation()(Login));
