import React from "react";
import { Icon } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCarAlt, faUserCheck, faPauseCircle } from "@fortawesome/free-solid-svg-icons";
import { faCalendarCheck, faCarGarage, faEnvelope } from "@fortawesome/pro-regular-svg-icons";
import { COMMUNICATION_STATUSES, APPOINTMENT_STATUSES } from "../../modules/Appointments/common";

const StatusCell = ({ value, statuses, car_out_of_shop }) => {
  let status = { icon: "", color: "", name: "" };

  if (statuses && statuses.length > 0) {
    status = statuses.find(i => i.identifier === value.id);
    if (!status) {
      status = { icon: "settings" };
    }
  }

  if (value.id === APPOINTMENT_STATUSES.CONTACT) {
    status.icon = value.customcom_status >= COMMUNICATION_STATUSES.DIAGNOSE_OVERVIEW_SENT ? "mail" : "phone";
  }

  if (status.identifier === APPOINTMENT_STATUSES.NEW_CAR) {
    return (
      <div
        className="-status-column_cell"
        style={{
          position: "relative",
          backgroundColor: status.color,
          color: "#000",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "0em",
        }}
      >
        {car_out_of_shop && <Icon name="sign-out" style={{ position: "absolute", bottom: "6px", left: "4px" }} />}
        <FontAwesomeIcon style={{ fontSize: "20px" }} title={status.name} icon={faCarAlt} />
      </div>
    );
  }

  if (status.identifier === APPOINTMENT_STATUSES.CUSTOMER_ANSWERED) {
    return (
      <div
        className="-status-column_cell"
        style={{
          position: "relative",
          backgroundColor: status.color,
          color: "#000",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "0em",
        }}
      >
        {car_out_of_shop && <Icon name="sign-out" style={{ position: "absolute", bottom: "6px", left: "4px" }} />}
        <FontAwesomeIcon style={{ marginLeft: "0.4em", fontSize: "15px" }} title={status.name} icon={faUserCheck} />
      </div>
    );
  }

  if (status.identifier === APPOINTMENT_STATUSES.CHECK_IN_DONE) {
    return (
      <div
        className="-status-column_cell"
        style={{
          position: "relative",
          backgroundColor: status.color,
          color: "#000",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "0em",
        }}
      >
        {car_out_of_shop && <Icon name="sign-out" style={{ position: "absolute", bottom: "6px", left: "4px" }} />}
        <span className="fa-layers fa-fw">
          <FontAwesomeIcon icon={faCarAlt} title={status.name} style={{ fontSize: "20px", marginRight: "-2px" }} />
          <FontAwesomeIcon icon={faCalendarCheck} title={status.name} style={{ fontSize: "10px", marginBottom: "5px", marginLeft: "20px" }} />
        </span>
      </div>
    );
  }

  if (status.identifier === APPOINTMENT_STATUSES.CAR_IN_SHOP) {
    return (
      <div
        className="-status-column_cell"
        style={{
          backgroundColor: status.color,
          color: "#000",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "0em",
        }}
      >
        <FontAwesomeIcon style={{ marginLeft: "0.1em", fontSize: "20px" }} title={status.name} icon={faCarGarage} />
      </div>
    );
  }

  if (status.identifier === APPOINTMENT_STATUSES.CAR_OK_PLUS_REPAIR_OVERVIEW) {
    return (
      <div
        className="-status-column_cell"
        style={{
          position: "relative",
          backgroundColor: status.color,
          color: "#000",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "0em",
        }}
      >
        {car_out_of_shop && <Icon name="sign-out" style={{ position: "absolute", bottom: "6px", left: "4px" }} />}
        <span className="fa-layers" style={{ height: "2em", width: "2em" }}>
          <i className="material-icons" title={status.name}>
            {"done_all"}
          </i>
          <FontAwesomeIcon icon={faEnvelope} title={status.name} style={{ fontSize: "10px", marginBottom: "15px", marginLeft: "25px" }} />
        </span>
      </div>
    );
  }

  if (status.identifier === APPOINTMENT_STATUSES.QUALITY_CHECK_PLUS_REPAIR_OVERVIEW) {
    return (
      <div
        className="-status-column_cell"
        style={{
          position: "relative",
          backgroundColor: status.color,
          color: "#000",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "0em",
        }}
      >
        {car_out_of_shop && <Icon name="sign-out" style={{ position: "absolute", bottom: "6px", left: "4px" }} />}
        <span className="fa-layers" style={{ height: "2em", width: "2em" }}>
          <i className="material-icons" title={status.name}>
            {"verified_user"}
          </i>
          <FontAwesomeIcon icon={faEnvelope} title={status.name} style={{ fontSize: "10px", marginBottom: "15px", marginLeft: "25px" }} />
        </span>
      </div>
    );
  }

  if (status.identifier === APPOINTMENT_STATUSES.CAR_CHECK_STARTED && value.check_paused_at) {
    return (
      <div
        className="-status-column_cell"
        style={{
          position: "relative",
          backgroundColor: status.color,
          color: "#000",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "0em",
        }}
      >
        {car_out_of_shop && <Icon name="sign-out" style={{ position: "absolute", bottom: "6px", left: "4px" }} />}
        <span className="fa-layers" style={{ height: "2em", width: "2em" }}>
          <FontAwesomeIcon title={status.name} icon={faPauseCircle} style={{ marginLeft: "0.1em", fontSize: "20px" }} />
        </span>
      </div>
    );
  }

  if (status.identifier === APPOINTMENT_STATUSES.BACK_ORDER) {
    return (
      <div
        className="-status-column_cell"
        style={{
          position: "relative",
          backgroundColor: status.color,
          color: "#000",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "0em",
        }}
      >
        {car_out_of_shop && <Icon name="sign-out" style={{ position: "absolute", bottom: "6px", left: "4px" }} />}
        <span className="fa-layers" style={{ height: "2em", width: "2em" }}>
          <span className="back-order-status-icon" style={{ fontWeight: "600", fontSize: "14px", top: "2px", position: "relative" }}>
            BO
          </span>
        </span>
      </div>
    );
  }

  return (
    <div
      className="-status-column_cell"
      style={{
        position: "relative",
        backgroundColor: status.color,
        color: "#000",
      }}
    >
      {car_out_of_shop && <Icon name="sign-out" style={{ position: "absolute", bottom: "6px", left: "4px" }} />}

      <i className="material-icons" title={status.name}>
        {status.icon}
      </i>
    </div>
  );
};

export default StatusCell;
