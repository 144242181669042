import React, { Component } from "react";
import { Form, Checkbox, Header, Icon, Segment } from "semantic-ui-react";
import { withTranslation } from "react-i18next";

import UserInput from "../UserInput";

class EVHCConfiguration extends Component {
  handleInputChange = e => {
    const { location, onLocationChange } = this.props;
    if (Object.keys(location).length === 0) return;

    onLocationChange({ ...location, [e.target.name]: e.target.value });
  };

  handleCheckboxChange = (e, { name, checked }) => {
    const { location, onLocationChange } = this.props;

    onLocationChange({ ...location, [name]: checked });
  };

  render() {
    const { location, t } = this.props;

    return (
      <Segment>
        <Header as="h5">
          <Icon name="wrench" />
          <Header.Content>JLR - EVHC - Export</Header.Content>
        </Header>
        <Form>
          <Form.Field className="full-width-input">
            <Checkbox
              name="evhc_export_enabled"
              checked={location.evhc_export_enabled}
              onChange={this.handleCheckboxChange}
              label={t("send_daily_evhc_export").message || "Send daily EVHC export"}
            />
          </Form.Field>
          <Form.Field className="full-width-input">
            <label>{t("evhc_primary_code").message || "EVHC Primary Code"}</label>
            <UserInput
              value={location.evhc_primary_code}
              name="evhc_primary_code"
              placeholder={t("evhc_primary_code").message || "EVHC Primary Code"}
              error={location.evhc_export_enabled && !location.evhc_primary_code}
              onChange={this.handleInputChange}
            />
          </Form.Field>
          <Form.Field className="full-width-input">
            <label>{t("evhc_secondary_code").message || "EVHC Secondary Code"}</label>
            <UserInput
              value={location.evhc_secondary_code}
              name="evhc_secondary_code"
              placeholder={t("evhc_secondary_code").message || "EVHC Secondary Code"}
              error={location.evhc_export_enabled && !location.evhc_secondary_code}
              onChange={this.handleInputChange}
            />
          </Form.Field>
        </Form>
      </Segment>
    );
  }
}

export default withTranslation()(EVHCConfiguration);
