import React, { Component } from "react";
import { Modal, Button, Form, Dropdown } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import "./CustomConfirm.css";

const CUSTOM_REASON = -1;

class CustomConfirm extends Component {
  state = {
    note: "",
    reason_id: null,
    displayCustomReasonField: false,
    clickedButton: "",
  };

  handleInputChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleReasonChange = (e, data) => {
    let displayCustomReasonField, reason_id;

    if (data.value === CUSTOM_REASON) {
      displayCustomReasonField = true;
      reason_id = null;
    } else {
      displayCustomReasonField = false;
      reason_id = data.value;
    }

    this.setState({ displayCustomReasonField, reason_id, note: "" });
  };

  renderCustomReasons = () => {
    const { confirmReasons, allowCustomReason, t } = this.props;
    let options = [];

    if (confirmReasons) {
      options = confirmReasons.map(reason => ({
        key: reason.id,
        text: t(reason.name).message || reason.name,
        value: reason.id,
      }));

      if (allowCustomReason) options.push({ key: CUSTOM_REASON, text: t("custom_reason_other").message || "Other reason", value: CUSTOM_REASON });
    }

    return <Dropdown placeholder={t("pick_reason").message || "Pick reason"} selection options={options} onChange={this.handleReasonChange} />;
  };

  confirmRequest = () => {
    const { note, reason_id } = this.state;
    const { handleConfirm, confirmReasons } = this.props;

    if (confirmReasons) {
      if (note) handleConfirm({ custom_reason: note });
      else handleConfirm({ reason_id });
    } else if (note) handleConfirm(note);
    else handleConfirm();
  };

  cancelConfirm = () => this.setState({ reason_id: null, displayCustomReasonField: false, note: "" }, () => this.props.handleCancel());

  render() {
    const { note, displayCustomReasonField, reason_id } = this.state;
    const {
      t,
      type,
      isOpen,
      confirmMsg,
      error,
      isLoading,
      confirmText,
      confirmNote,
      confirmMandatoryNote,
      cancelText,
      hideCancelButton,
      confirmReasons,
      declineText,
      handleDecline,
      declineMandatoryNote,
    } = this.props;

    return (
      <Modal
        size="tiny"
        open={isOpen}
        closeOnDimmerClick={false}
        header={<div className={type === "danger" ? "headerDanger" : "headerWarning"}>{this.props.t("confirm_required").message || "Confirmation required!"}</div>}
        content={
          <div className="outer-content-box">
            <div className="inner-content-box">
              <FontAwesomeIcon icon={type === "danger" ? faExclamationTriangle : faExclamationCircle} className={type === "danger" ? "iconDanger" : "iconWarning"} />
              <div className="confirm-msg">
                {typeof confirmMsg === "string" ? <p>{confirmMsg}</p> : confirmMsg}

                <Form>
                  {confirmReasons && this.renderCustomReasons()}

                  {(confirmNote || confirmMandatoryNote || displayCustomReasonField || declineMandatoryNote) && (
                    <Form.Field>
                      <label>{confirmNote || confirmMandatoryNote || declineMandatoryNote}</label>
                      <Form.TextArea value={note} name="note" onChange={this.handleInputChange} />
                    </Form.Field>
                  )}
                </Form>
              </div>
            </div>
            <br />
            {error && <p className="error-msg">{error}</p>}
          </div>
        }
        actions={[
          !hideCancelButton && (
            <Button
              key={1}
              basic
              disabled={isLoading}
              color="grey"
              className="customButton"
              content={cancelText ? cancelText : t("cancel").message || "Cancel"}
              onClick={() => {
                this.setState({ clickedButton: "cancel" }, () => this.cancelConfirm());
              }}
            />
          ),
          handleDecline && (
            <Button
              key={2}
              disabled={(declineMandatoryNote && !note) || isLoading}
              basic
              loading={isLoading && this.state.clickedButton === "decline"}
              color="grey"
              className="customButton"
              content={declineText ? declineText : t("decline").message || "Decline"}
              onClick={() => {
                this.setState({ clickedButton: "decline" }, () => handleDecline(note));
              }}
            />
          ),
          <Button
            key={3}
            loading={isLoading && this.state.clickedButton === "confirm"}
            disabled={isLoading || (confirmMandatoryNote && !note) || (confirmReasons && !(reason_id || note))}
            content={confirmText ? confirmText : t("confirm").message || "Confirm"}
            className={type === "danger" ? "customButton confirmButtonDanger" : "customButton confirmButtonWarning"}
            onClick={() => {
              this.setState({ clickedButton: "confirm" }, () => this.confirmRequest());
            }}
          />,
        ]}
      />
    );
  }
}

export default withTranslation()(CustomConfirm);
