import React from "react";
import { Button } from "semantic-ui-react";
import ReactTable, { ReactTableDefaults } from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport } from "@fortawesome/pro-regular-svg-icons";
import { saveAs } from "file-saver";
import moment from "moment";
import "moment-duration-format";

const AvgPhotosColors = [
  {
    value1: 0,
    value2: 5,
    color: "red",
  },
  {
    value1: 5,
    value2: Infinity,
    color: "darkgreen",
  },
];

const AvgSoldColors = [
  {
    value1: 0,
    value2: 40,
    color: "red",
  },
  {
    value1: 40,
    value2: 65,
    color: "orange",
  },
  {
    value1: 65,
    value2: Infinity,
    color: "darkgreen",
  },
];

const getAvgPhotosColor = value => {
  const item = AvgPhotosColors.find(item => value >= item.value1 && value <= item.value2);
  return item ? item.color : "";
};

const getAvgSoldColor = value => {
  const item = AvgSoldColors.find(item => value >= item.value1 && value <= item.value2);
  return item ? item.color : "";
};

const LocationsCommercialOverview = ({ report, t }) => {
  const data = report.report_data.map(d => JSON.parse(d));

  const exportData = async () => {
    let csv = "";
    let header = (t("location").message || "Location") + ", ";
    header += (t("wo_count").message || "WO count") + ", ";
    header += (t("commercial_check_count").message || "Commercial check count") + ", ";
    header += (t("avg_advice_point_count").message || "AVG advised items found") + ", ";
    header += (t("avg_advice_point_count_yes").message || "AVG advised items sold") + ", ";
    header += (t("avg_critical_point_count").message || "AVG critical items founds") + ", ";
    header += (t("avg_critical_point_count_yes").message || "AVG critical items sold") + ", ";
    header += (t("avg_sold_items").message || "AVG sold items") + ", ";
    header += (t("percent_sold_items").message || "% sold items ") + ", ";
    header += (t("avg_photo_taken").message || "AVG photo taken") + ", ";
    header += (t("avg_mechanic_time").message || "AVG mechanic time") + ", ";
    header += (t("avg_offer_ready").message || "AVG offer ready") + ", ";
    header += t("avg_customer_ok").message || "AVG customer OK";

    let values = data
      .map(d => {
        let avg_offer_ready = "00:00:00",
          avg_customer_ok = "00:00:00";
        if (d.avg_receptionist_time) {
          const parsed = JSON.parse(d.avg_receptionist_time);
          if (parsed) {
            if (parsed.avg_offer_ready) avg_offer_ready = moment.duration(parsed.avg_offer_ready, "seconds").format("HH:mm:ss", { trim: false });
            if (parsed.avg_customer_ok) avg_customer_ok = moment.duration(parsed.avg_customer_ok, "seconds").format("HH:mm:ss", { trim: false });
          }
        }

        return (
          d.location +
          ", " +
          d.wo_count +
          ", " +
          d.commercial_check_count +
          ", " +
          d.avg_advice_point_count +
          ", " +
          d.avg_advice_point_count_yes +
          ", " +
          d.avg_critical_point_count +
          ", " +
          d.avg_critical_point_count_yes +
          ", " +
          d.avg_sold_items +
          ", " +
          d.percent_sold_items +
          ", " +
          d.avg_photo_taken +
          ", " +
          moment.duration(d.avg_mechanic_time, "seconds").format("HH:mm:ss", { trim: false }) +
          ", " +
          avg_offer_ready +
          ", " +
          avg_customer_ok
        );
      })
      .join("\n");

    csv += header + "\n" + values;
    saveAs(new File([csv], { type: "text/csv;charset=utf-8" }), "LocationsCommercialOverview_" + moment().format("YYYYMMDD") + ".csv");
  };

  return (
    <>
      <h3 className="report-heading">
        {t(report.report_name).message || report.report_name}
        <Button color="green" floated="right" basic onClick={() => exportData()}>
          <span style={{ marginRight: "10px" }}>{t("export").message || "Export"}</span>
          <FontAwesomeIcon icon={faFileExport} />
        </Button>
      </h3>
      <div className="dealer-reports-table-container">
        <ReactTable
          className="ReactTable -floated-table no-overflow all-data-table"
          data={data}
          showPagination={false}
          showPageSizeOptions={false}
          sortable={false}
          style={{ maxHeight: "600px" }}
          resizable={false}
          defaultPageSize={data?.length || 0}
          pageSize={data?.length || 0}
          noDataText={
            <div className="Table__no-results">
              <p>{t("no_data").message || "No data"}</p>
            </div>
          }
          column={{
            ...ReactTableDefaults.column,
            headerClassName: "ReactTable__column-header dealer-table-header",
            className: "ReactTable__column",
          }}
          columns={[
            {
              Header: <strong>{t("location").message || "Location"}</strong>,
              accessor: "location",
            },
            {
              Header: <strong>{t("wo_count").message || "WO count"}</strong>,
              accessor: "wo_count",
            },
            {
              Header: <strong>{t("commercial_check_count").message || "Commercial check count"}</strong>,
              accessor: "commercial_check_count",
            },
            {
              Header: <strong>{t("avg_advice_point_count").message || "AVG advised items found"}</strong>,
              accessor: "avg_advice_point_count",
            },
            {
              Header: <strong>{t("avg_advice_point_count_yes").message || "AVG advised items sold"}</strong>,
              accessor: "avg_advice_point_count_yes",
            },
            {
              Header: <strong>{t("avg_critical_point_count").message || "AVG critical items founds"}</strong>,
              accessor: "avg_critical_point_count",
            },
            {
              Header: <strong>{t("avg_critical_point_count_yes").message || "AVG critical items sold"}</strong>,
              accessor: "avg_critical_point_count_yes",
            },
            {
              Header: <strong>{t("avg_sold_items").message || "AVG items sold"}</strong>,
              id: "avg_sold_items",
              accessor: row => <span style={{ color: getAvgSoldColor(row.avg_sold_items) }}>{row.avg_sold_items}</span>,
            },
            {
              Header: <strong>{t("percent_sold_items").message || "% items sold"}</strong>,
              accessor: "percent_sold_items",
              Cell: cell => cell.value + "%",
            },
            {
              Header: <strong>{t("avg_photo_taken").message || "AVG photo taken"}</strong>,
              id: "avg_photo_taken",
              accessor: row => <span style={{ color: getAvgPhotosColor(row.avg_photo_taken) }}>{row.avg_photo_taken}</span>,
            },
            {
              Header: <strong>{t("avg_mechanic_time").message || "AVG mechanic time"}</strong>,
              id: "avg_mechanic_time",
              accessor: row => moment.duration(row.avg_mechanic_time, "seconds").format("HH:mm:ss", { trim: false }),
            },
            {
              Header: <strong>{t("avg_offer_ready").message || "AVG offer ready"}</strong>,
              id: "avg_offer_ready",
              accessor: items => {
                if (items["avg_receptionist_time"]) {
                  const parsed = JSON.parse(items["avg_receptionist_time"]);
                  if (parsed && parsed.avg_offer_ready) return moment.duration(parsed.avg_offer_ready, "seconds").format("HH:mm:ss", { trim: false });
                }

                return "00:00:00";
              },
            },
            {
              Header: <strong>{t("avg_customer_ok").message || "AVG customer OK"}</strong>,
              id: "avg_customer_ok",
              accessor: items => {
                if (items["avg_receptionist_time"]) {
                  const parsed = JSON.parse(items["avg_receptionist_time"]);
                  if (parsed && parsed.avg_customer_ok) return moment.duration(parsed.avg_customer_ok, "seconds").format("HH:mm:ss", { trim: false });
                }

                return "00:00:00";
              },
            },
          ]}
        />
      </div>
    </>
  );
};

export { LocationsCommercialOverview };
