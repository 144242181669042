import React, { Component } from "react";
import { Form, Header, Icon, Segment } from "semantic-ui-react";

import UserInput from "../UserInput";

class KeyloopConfiguration extends Component {
  handleInputChange = e => {
    let { location, handleChange } = this.props;

    if (Object.keys(location).length === 0) return;

    handleChange({ ...location, [e.target.name]: e.target.value });
  };

  handleCheckboxChange = (_e, { name, checked }) => {
    const { location, handleChange } = this.props;

    handleChange({ ...location, [name]: checked });
  };

  render() {
    const { location, t } = this.props;

    return (
      <Segment>
        <Header as="h5">
          <Icon name="wrench" />
          <Header.Content>{this.props.t("keyloop_configuration").message || "Keyloop configuration"}</Header.Content>
          <Header.Subheader style={{ color: "gray", marginTop: "10px" }}>
            {this.props.t("keyloop_location_notice").message || "API settings are also required on dealer level for Keyloop"}
          </Header.Subheader>
        </Header>
        <Form>
          <Form.Field className="full-width-input">
            <label>{t("business_unit").message || "Business unit"}</label>
            <UserInput
              defaultValue={location.keyloop_business_unit}
              name="keyloop_business_unit"
              placeholder={t("business_unit").message || "Business unit"}
              onChange={this.handleInputChange}
            />
          </Form.Field>
        </Form>
      </Segment>
    );
  }
}

export default KeyloopConfiguration;
