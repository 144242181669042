import React from "react";
import { withTranslation } from "react-i18next";

import "./PageNotFound.css";

const PageNotFound = ({ t }) => (
  <div className="PageNotFound">
    <h1>{t("page_not_found").message || "Page not found"}</h1>
  </div>
);

export default withTranslation()(PageNotFound);
