import React from "react";
import ReactTable, { ReactTableDefaults } from "react-table";
import { withTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEdit } from "@fortawesome/free-solid-svg-icons";

import "./TranslationsTable.css";

const TranslationsTable = ({ t, loading, items, disabled, handleDeleteClick, handleEditClick }) => {
  return (
    <ReactTable
      className="TranslationsTable -floated-table"
      data={items || []}
      showPagination
      loading={loading}
      sortable={false}
      resizable={false}
      minRows={0}
      nextText={t("next").message || "Next"}
      previousText={t("previous").message || "Previous"}
      pageText={t("page").message || "Page"}
      ofText={t("of").message || "of"}
      column={{
        ...ReactTableDefaults.column,
        headerClassName: "ReactTable__column-header",
        className: "ReactTable__column",
      }}
      columns={[
        {
          Header: t("translation_key").message || "Translation key",
          id: "translationKey",
          accessor: d => d.key,
        },
        {
          Header: t("translation_value").message || "Translation value",
          id: "translationValue",
          accessor: d => d.value,
        },
        {
          Header: "",
          className: "-text-overflow-unset",
          id: "edit",
          accessor: d => (
            <div className="action-icon edit-action" onClick={() => handleEditClick(d.key)}>
              <FontAwesomeIcon icon={faEdit} />
            </div>
          ),
          maxWidth: 42,
        },
        {
          Header: "",
          className: "-text-overflow-unset",
          id: "delete",
          accessor: d => (
            <div className={`action-icon delete-action ${disabled ? "-disabled" : ""}`} onClick={() => handleDeleteClick(d.key)}>
              <FontAwesomeIcon icon={faTrash} />
            </div>
          ),
          maxWidth: 42,
        },
      ]}
    />
  );
};

export default withTranslation()(TranslationsTable);
