import Service from "./service";

class SnoozeOptionsLoader {
  constructor() {
    if (SnoozeOptionsLoader._instance) {
      return SnoozeOptionsLoader._instance;
    }

    SnoozeOptionsLoader._instance = this;

    this.isLoading = false;
    this.options = null;
    this.promises = [];
  }

  getDropdownOptions() {
    return new Promise((resolve, reject) => {
      this.promises.push({ resolve, reject });
      this.load();
    });
  }

  load() {
    if (this.options) return this.resolvePromises();

    if (!this.isLoading) {
      this.isLoading = true;
      Service.getSnoozeDropdownOptions()
        .then(result => {
          if (!result?.data?.data) throw new Error("Invalid response when loading snooze dropdowns options");

          const snoozeTypeOptions = [];
          const snoozeStatusOptions = [];
          const snoozeDepartmentOptions = [];
          const { data } = result.data;

          if (Array.isArray(data.snooze_types)) data.snooze_types.forEach(i => (snoozeTypeOptions[i.id] = i.name));
          if (Array.isArray(data.snooze_statuses)) data.snooze_statuses.forEach(i => (snoozeStatusOptions[i.id] = i.name));
          if (Array.isArray(data.snooze_departments)) data.snooze_departments.forEach(i => (snoozeDepartmentOptions[i.id] = i.name));

          this.options = { snoozeOptions: data, snoozeTypeOptions, snoozeStatusOptions, snoozeDepartmentOptions };
          this.resolvePromises();
          this.isLoading = false;
        })
        .catch(err => {
          if (err?.response?.data?.errors?.length) this.rejectPromises(err.response.data.errors[0]);
          else this.rejectPromises(err.message);

          this.isLoading = false;
        });
    }
  }

  rejectPromises(err) {
    let promise;
    while ((promise = this.promises.shift()) !== undefined) {
      promise.reject(err);
    }
  }

  resolvePromises() {
    let promise;
    while ((promise = this.promises.shift()) !== undefined) {
      promise.resolve(this.options);
    }
  }
}

const SnoozeOptions = new SnoozeOptionsLoader();

export default SnoozeOptions;
