import axios from "axios";

import ENV from "../../util/env-config";
import { getSessionCookie } from "../../util/common";

class Service {
  static requestConfig() {
    return {
      headers: {
        Authorization: `Bearer ${getSessionCookie()}`,
      },
    };
  }

  static listParkingGateCodes(requestData) {
    return axios.post(`${ENV.dealerBaseUrl}/parking_gate/code/list`, requestData, this.requestConfig());
  }

  static createParkingGateCode(requestData) {
    return axios.post(`${ENV.dealerBaseUrl}/parking_gate/code/create`, requestData, this.requestConfig());
  }

  static updateParkingGateCode(requestData) {
    return axios.post(`${ENV.dealerBaseUrl}/parking_gate/code/update`, requestData, this.requestConfig());
  }

  static deleteParkingGateCode(requestData) {
    return axios.post(`${ENV.dealerBaseUrl}/parking_gate/code/delete`, requestData, this.requestConfig());
  }
}

export default Service;
