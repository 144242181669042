import React from "react";
import { withTranslation } from "react-i18next";
import { Button, Modal } from "semantic-ui-react";

import "./CustomerDetail.css";

const CustomerDetail = props => {
  let { customer, visible, onHide, t } = props;

  return (
    <Modal className="CustomerDetail -no-margin" size="tiny" dimmer="inverted" open={visible} onClose={onHide} closeOnDimmerClick={false}>
      <header>
        <h1>{t("customer_detail").message || "Customer detail"}</h1>
        <Button color="green" size="large" floated="right" onClick={onHide} className="-margin-left-20 -margin-left-10">
          {t("close").message}
        </Button>
      </header>
      <Modal.Content>
        <section>
          <p>
            {t("name").message}
            <strong>{`${customer.title} ${customer.initials} ${customer.firstname} ${customer.surname}`}</strong>
          </p>
          <p>
            {t("company_name").message}
            <strong>{customer.company}</strong>
          </p>
          <p>
            {t("place").message}
            <strong>{customer.place}</strong>
          </p>
          <p>
            {t("street").message}
            <strong>{`${customer.place} ${customer.house_nr}`}</strong>
          </p>
          <p>
            {t("postcode").message || "Postcode"}
            <strong>{customer.postcode}</strong>
          </p>
          <p>
            {t("private_email").message}
            <strong>{customer.email_private}</strong>
          </p>
          <p>
            {t("business_email").message}
            <strong>{customer.email_business}</strong>
          </p>
          <p>
            {t("business_number").message}
            <strong>{customer.tel_business_nr}</strong>
          </p>
          <p>
            {t("private_number").message}
            <strong>{customer.tel_private_nr}</strong>
          </p>
          <p>
            {t("business_mobile").message}
            <strong>{customer.tel_mobile_business}</strong>
          </p>
          <p>
            {t("private_mobile").message}
            <strong>{customer.tel_mobile_private}</strong>
          </p>
        </section>
      </Modal.Content>
    </Modal>
  );
};

export default withTranslation()(CustomerDetail);
