import React from "react";
import { Button } from "semantic-ui-react";
import ReactTable, { ReactTableDefaults } from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport } from "@fortawesome/pro-regular-svg-icons";
import { saveAs } from "file-saver";
import moment from "moment";

const CommunicationsOverview = ({ report, t }) => {
  if (!report.report_data) {
    return (
      <>
        <h3 className="report-heading">{t(report.report_name).message || report.report_name}</h3>
        <p className="no-data">{t("no_data").message || "No data"}</p>
      </>
    );
  }

  const data = report.report_data.map(d => JSON.parse(d));

  const exportData = async () => {
    let csv = "";
    let header = (t("location").message || "Location") + ", ";
    header += (t("wo_count").message || "WO Count") + ", ";
    header += (t("answered_desk_checkin_wo_percent").message || "Desk Check-in Answered (WO %)") + ", ";
    header += (t("online_check_in_(wo_%)").message || "Online Check-In (WO%)") + ", ";
    header += (t("online_check_in_opened").message || "Online Check-In Opened") + ", ";
    header += (t("online_check_in_answered").message || "Online Check-In Answered") + ", ";
    header += (t("total_check_in_answered_(wo_%)").message || "Total Check-In Answered (WO%)") + ", ";
    header += (t("diagnose_overview_(wo_%)").message || "Diagnose Overview (WO %)") + ", ";
    header += (t("diagnose_overview_answered").message || "Diagnose Overview Answered") + ", ";
    header += (t("opened_diagnose_overview").message || "Diagnose Overview Opened") + ", ";
    header += (t("repair_overview_(wo_%)").message || "Repair Overview (WO %)") + " ";
    header += t("opened_repair_overview").message || "Repair Overview Opened";

    let values = data
      .map(
        d =>
          d.location +
          ", " +
          d.wo_count +
          ", " +
          d.answered_desk_checkin_wo_percent +
          ", " +
          d.online_checkin_wo_percent +
          ", " +
          d.opened_online_checkin +
          ", " +
          d.answered_online_checkin +
          ", " +
          d.answered_checkin_wo_percent +
          ", " +
          d.diagnose_overview_wo_percent +
          ", " +
          d.answered_diagnose_overview +
          ", " +
          d.opened_diagnose_overview +
          ", " +
          d.repair_overview_wo_percent +
          ", " +
          d.opened_repair_overview
      )
      .join("\n");

    csv += header + "\n" + values;
    saveAs(new File([csv], { type: "text/csv;charset=utf-8" }), "CommunicationsOverview_" + moment().format("YYYYMMDD") + ".csv");
  };

  return (
    <>
      <h3 className="report-heading">
        {t(report.report_name).message || report.report_name}
        <Button color="green" floated="right" basic onClick={() => exportData()}>
          <span style={{ marginRight: "10px" }}>{t("export").message || "Export"}</span>
          <FontAwesomeIcon icon={faFileExport} />
        </Button>
      </h3>
      <div className="dealer-reports-table-container">
        <ReactTable
          className="ReactTable -floated-table no-overflow all-data-table -initial-margin"
          data={data}
          showPagination={false}
          showPageSizeOptions={false}
          sortable={false}
          style={{ maxHeight: "600px" }}
          resizable={false}
          defaultPageSize={data.length || 0}
          pageSize={data.length || 0}
          noDataText={
            <div className="Table__no-results">
              <p>{t("no_data").message || "No data"}</p>
            </div>
          }
          column={{
            ...ReactTableDefaults.column,
            headerClassName: "ReactTable__column-header dealer-table-header",
            className: "ReactTable__column",
          }}
          columns={[
            {
              Header: <strong>{t("location").message || "Location"}</strong>,
              accessor: "location",
            },
            {
              Header: <strong>{t("wo_count").message || "WO Count"}</strong>,
              accessor: "wo_count",
            },
            {
              Header: <strong>{t("answered_desk_checkin_wo_percent").message || "Desk Check-in Answered (WO %)"}</strong>,
              accessor: "answered_desk_checkin_wo_percent",
            },
            {
              Header: <strong>{t("online_check_in_(wo_%)").message || "Online Check-In (WO%)"}</strong>,
              accessor: "online_checkin_wo_percent",
            },
            {
              Header: <strong>{t("online_check_in_opened").message || "Online Check-In Opened"}</strong>,
              accessor: "opened_online_checkin",
            },
            {
              Header: <strong>{t("online_check_in_answered").message || "Online Check-In Answered"}</strong>,
              accessor: "answered_online_checkin",
            },
            {
              Header: <strong>{t("total_check_in_answered_(wo_%)").message || "Total Check-In Answered (WO%)"}</strong>,
              accessor: "answered_checkin_wo_percent",
            },
            {
              Header: <strong>{t("diagnose_overview_(wo_%)").message || "Diagnose Overview (WO %)"}</strong>,
              accessor: "diagnose_overview_wo_percent",
            },
            {
              Header: <strong>{t("opened_diagnose_overview").message || "Diagnose Overview Opened"}</strong>,
              accessor: "opened_diagnose_overview",
            },
            {
              Header: <strong>{t("diagnose_overview_answered").message || "Diagnose Overview Answered"}</strong>,
              accessor: "answered_diagnose_overview",
            },
            {
              Header: <strong>{t("repair_overview_(wo_%)").message || "Repair Overview (WO %)"}</strong>,
              accessor: "repair_overview_wo_percent",
            },
            {
              Header: <strong>{t("opened_repair_overview").message || "Repair Overview Opened"}</strong>,
              accessor: "opened_repair_overview",
            },
          ]}
        />
      </div>
    </>
  );
};

export { CommunicationsOverview };
