import React, { Component } from "react";
import { Icon, Modal, Form, Button, Dropdown, Checkbox } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import Service from "./service";
import AlertContainer from "react-alert";
import { Can } from "../../modules";

const emptyForm = {
  name: "",
  description: "",
  info_url: "",
  price: null,
  tag_id: null,
  can_convert_to_intervention: false,
  brands: [],
};

class ServiceModal extends Component {
  state = {
    form:
      this.props.mode === "create"
        ? { ...emptyForm }
        : { ...this.props.service, brands: this.props.service.brands?.length > 0 ? this.props.service.brands.map(a => a.id) : [] },
    isLoading: false,
    notificationEmail: this.props.notification_email,
    alertOptions: {
      offset: 20,
      position: "top right",
      theme: "light",
      time: 10000,
      transition: "fade",
    },
  };

  error = msg => {
    this.msg.show(msg, { type: "error" });
  };

  handleChange = (e, data) => {
    const { form } = this.state;

    if (e.target.name === "price") {
      if (e.target.value && isNaN(parseFloat(e.target.value))) return;

      let val = e.target.value.match(new RegExp("^(\\d+\\.?\\d{0,2}).*"));
      form[e.target.name] = val === null ? "" : val[1];
    } else {
      form[e.target.name] = e.target.value;
    }

    this.setState({ form });
  };

  handleCheckboxChange = (e, data) => {
    const { form } = this.state;
    form[data.name] = data.checked;

    this.setState({ form });
  };

  handleDropdownSelect = (_e, { name, value }) => {
    this.setState(state => ({ form: { ...state.form, [name]: value } }));
  };

  handleChangeNotificationEmail = e => {
    this.setState({ notificationEmail: e.target.value });
  };

  handleSubmitNotificationEmail = () => {
    const { notificationEmail: notification_email } = this.state;

    this.setState({ isLoading: true }, () => {
      Service.updateNotificationEmail({ notification_email, checkin_service_id: this.props.service.id, dealer_location_id: this.props.dealer_location_id })
        .then(() => {
          this.setState({ isLoading: false, notificationEmail: "" });
          this.props.onUpdateService();
        })
        .catch(error => {
          error?.response?.data?.errors?.length && this.error(error.response.data.errors[0]);
          this.setState({ isLoading: false });
          console.error("Error updating notification email", error);
        });
    });
  };

  handleSubmit = () => {
    const { form } = this.state;

    if (form.info_url && !form.info_url.startsWith("https://")) {
      this.error("Only https link are allowed");
      return;
    }

    this.setState({ isLoading: true });
    const requestData = {
      dealer_id: this.props.dealer_id,
      name: form.name,
      description: form.description,
      info_url: form.info_url,
      price: parseFloat(form.price - 0),
      brands: this.props.brands.filter(brand => form.brands.some(sb => sb === brand.id)),
      hide_for_lease_appointments: form.hide_for_lease_appointments,
      tag_id: form.tag_id || null,
      can_convert_to_intervention: form.can_convert_to_intervention,
    };
    if (this.props.mode === "create") {
      Service.createService(requestData)
        .then(result => {
          if (result && result.data && result.data.data) {
            this.props.onSubmit();
            this.setState({ isLoading: false });
          }
        })
        .catch(error => {
          console.error("Error creating service.", error);
          this.setState({ isLoading: false });
          error.response.data && error.response.data.errors && this.error(error.response.data.errors[0]);
        });
    } else if (this.props.mode === "edit") {
      requestData.id = this.props.service.id;
      Service.updateService(requestData)
        .then(result => {
          this.props.onSubmit();
          this.setState({ isLoading: false });
        })
        .catch(error => {
          console.error("Error updating service.", error);
          this.setState({ isLoading: false });
          error.response.data && error.response.data.errors && this.error(error.response.data.errors[0]);
        });
    }
  };

  handleDeleteService = () => {
    this.setState({ isLoading: true });
    const requestData = {
      id: this.props.service.id,
    };
    Service.deleteService(requestData)
      .then(result => {
        this.props.onSubmit();
        this.setState({ isLoading: false });
      })
      .catch(error => {
        console.error("Error deleting service.", error);
        this.setState({ isLoading: false });
        error.response.data && error.response.data.errors && this.error(error.response.data.errors[0]);
      });
  };

  handleDisableService = () => {
    this.setState({ isLoading: true });
    const requestData = {
      id: this.props.service.id,
      dealer_location_id: this.props.dealer_location_id,
    };
    Service.locationDisable(requestData)
      .then(result => {
        this.props.onSubmit();
        this.setState({ isLoading: false });
        this.props.onUpdateService();
      })
      .catch(error => {
        console.error("Error disabling service.", error);
        this.setState({ isLoading: false });
        error.response.data && error.response.data.errors && this.error(error.response.data.errors[0]);
      });
  };

  render() {
    const { t, brandOptions, tagOptions, mode, list } = this.props;
    const { form, notificationEmail, isLoading } = this.state;

    return (
      <Modal size="small" dimmer="inverted" onClose={this.props.onClose} open={true} closeOnDimmerClick={false}>
        <Modal.Header>
          {mode === "create"
            ? t("add_new_check_in_service").message || "Add new online check-in service"
            : t("edit_check_in_service").message || "Edit online check-in service"}
        </Modal.Header>
        <Modal.Content>
          <AlertContainer ref={a => (this.msg = a)} {...this.state.alertOptions} />
          <Form>
            <Form.Field>
              <Form.Input
                name="name"
                disabled={list === "location"}
                placeholder={t("check_in_service_name").message || "Online Check-in service name"}
                defaultValue={form.name}
                onChange={this.handleChange}
              />
            </Form.Field>
            <Form.Field>
              <Form.Input
                name="description"
                disabled={list === "location"}
                placeholder={t("check_in_service_description").message || "Online Check-in service description"}
                defaultValue={form.description}
                onChange={this.handleChange}
              />
            </Form.Field>
            <Form.Field>
              <Dropdown
                placeholder={t("select_brands").message || "Select brands"}
                selection
                fluid
                search
                name="brands"
                disabled={list === "location"}
                options={brandOptions}
                multiple={true}
                value={form.brands}
                onChange={this.handleDropdownSelect}
              />
            </Form.Field>

            <Form.Field>
              <Dropdown
                placeholder={t("select_tag").message || "Select tag"}
                selection
                fluid
                clearable
                name="tag_id"
                disabled={list === "location"}
                options={tagOptions}
                value={form.tag_id}
                onChange={this.handleDropdownSelect}
              />
            </Form.Field>

            <Form.Field>
              <Form.Input
                name="info_url"
                disabled={list === "location"}
                placeholder={t("info_url").message || "Info URL"}
                defaultValue={form.info_url}
                onChange={this.handleChange}
              />
            </Form.Field>
            <Form.Field>
              <Form.Input
                name="price"
                disabled={list === "location"}
                placeholder={(t("price").message || "Price") + " " + (t("no_vat_price").message || "(excl. VAT)")}
                value={form.price || ""}
                onChange={this.handleChange}
              />
            </Form.Field>
            <Form.Field>
              <Checkbox
                onChange={this.handleCheckboxChange}
                disabled={list === "location"}
                toggle
                label={t("hide_for_lease_appointments").message || "Hide for lease appointments"}
                name="hide_for_lease_appointments"
                checked={form.hide_for_lease_appointments}
              />
            </Form.Field>
            <Form.Field>
              <Checkbox
                onChange={this.handleCheckboxChange}
                disabled={list === "location"}
                toggle
                label={t("convert_to_intervention").message || "Convert to intervention"}
                name="can_convert_to_intervention"
                checked={form.can_convert_to_intervention}
              />
            </Form.Field>
            {list === "location" && mode === "edit" && (
              <Form.Field>
                <Form.Input
                  name="notification_email"
                  placeholder={t("notification_email").message || "Notification email"}
                  value={notificationEmail}
                  onChange={this.handleChangeNotificationEmail}
                />
              </Form.Field>
            )}
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.props.onClose} floated="left" color="red">
            <Icon name="close" />
            {t("cancel").message || "Cancel"}
          </Button>
          {mode === "edit" && list === "dealer" && (
            <Can I="delete" the="check_in_service">
              <Button onClick={this.handleDeleteService} loading={isLoading} color="red">
                <Icon name="trash" />
                {t("delete").message || "Delete"}
              </Button>
            </Can>
          )}

          {mode === "edit" && list === "location" && (
            <Can I="enable_disable" the="check_in_service">
              <Button onClick={this.handleDisableService} loading={isLoading} color="red">
                {t("disable").message || "Disable"}
              </Button>
            </Can>
          )}

          {mode === "create" && list !== "location" && (
            <Can I="create" the="check_in_service">
              <Button loading={isLoading} disabled={isLoading || !form.name || !form.description} onClick={this.handleSubmit} color="green">
                <Icon name="checkmark" />
                {t("save").message || "Save"}
              </Button>
            </Can>
          )}

          {mode === "edit" && list !== "location" && (
            <Can I="update" the="check_in_service">
              <Button loading={isLoading} disabled={isLoading || !form.name || !form.description} onClick={this.handleSubmit} color="green">
                <Icon name="checkmark" />
                {t("save").message || "Save"}
              </Button>
            </Can>
          )}

          {mode === "edit" && list === "location" && (
            <Can I="update" the="check_in_service">
              <Button loading={isLoading} disabled={isLoading} onClick={this.handleSubmitNotificationEmail} color="green">
                <Icon name="checkmark" />
                {t("save").message || "Save"}
              </Button>
            </Can>
          )}
        </Modal.Actions>
      </Modal>
    );
  }
}

export default withTranslation()(ServiceModal);
