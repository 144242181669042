import React from "react";
import moment from "moment";

import "./CarInspectionDate.scss";

const CarInspectionDate = ({ date }) => {
  let formattedDate = "";
  let c = "-apk ";

  if (date && (moment.isMoment(date) || !date.startsWith("0001-01-01T00:00:00"))) {
    // might be time zoned
    //if (date !== "0001-01-01T00:00:00Z") {
    const md = moment(date);

    if (md.isBefore(moment())) {
      c += "-car-inspection-overdue";
    } else if (md.isBefore(moment().add(2, "months"))) {
      c += "-car-inspection-soon";
    }

    formattedDate = md.format("DD-MM-YYYY");
  }

  return <span className={"CarInspectionDate " + c}>{formattedDate}</span>;
};

export default CarInspectionDate;
