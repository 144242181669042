import React, { Component } from "react";
import { Dropdown } from "semantic-ui-react";
import { withTranslation } from "react-i18next";

import { LEAD_TYPES } from "./common";
import SnoozeLeads from "./SnoozeLeads";
import NotSnoozedLeads from "./NotSnoozedLeads";
import CarLeads from "./CarLeads";
import TiresLeads from "./TiresLeads";

import "./Leads.css";

class Leads extends Component {
  state = { selectedLeadType: LEAD_TYPES.SNOOZED };

  handleChangeLeadsType = selectedLeadType => this.setState({ selectedLeadType });

  render() {
    const { selectedLeadType } = this.state;

    const { t } = this.props;

    const leadsTypeOptions = [
      { key: LEAD_TYPES.SNOOZED, value: LEAD_TYPES.SNOOZED, text: t("snoozed").message || "Snoozed" },
      { key: LEAD_TYPES.NOT_SNOOZED, value: LEAD_TYPES.NOT_SNOOZED, text: t("not_snoozed").message || "Not Snoozed" },
      { key: LEAD_TYPES.CARS, value: LEAD_TYPES.CARS, text: t("cars").message || "Cars" },
      { key: LEAD_TYPES.TIRES, value: LEAD_TYPES.TIRES, text: t("tires").message || "Tires" },
    ];

    return (
      <div className="Leads">
        <div style={{ padding: "14px 0px 14px 14px" }}>
          <h1 style={{ display: "inline-block", marginRight: "10px", marginBottom: "0px", verticalAlign: "middle" }}>{t("leads").message || "Leads"}</h1>
          <Dropdown
            selectOnBlur={false}
            placeholder={t("leads_type").message || "Leads Type"}
            value={selectedLeadType}
            onChange={(e, data) => this.handleChangeLeadsType(data.value)}
            selection
            options={leadsTypeOptions}
          />
        </div>

        {selectedLeadType === LEAD_TYPES.SNOOZED && <SnoozeLeads />}
        {selectedLeadType === LEAD_TYPES.NOT_SNOOZED && <NotSnoozedLeads />}
        {selectedLeadType === LEAD_TYPES.CARS && <CarLeads />}
        {selectedLeadType === LEAD_TYPES.TIRES && <TiresLeads />}
      </div>
    );
  }
}

export default withTranslation()(Leads);
