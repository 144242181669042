import Browser from "./browser";
import Modal from "./modal";
import Pdf from "./pdf";
import Html from "./html";
import FullRawHtml from "./full-raw-html";
import RawHtml from "./raw-html";
import Image from "./image";
import Json from "./json";

const printTypes = ["pdf", "html", "image", "json", "raw-html", "full-raw-html"];

export default {
  init() {
    let params = {
      copyStyles: false,
      printable: null,
      fallbackPrintable: null,
      type: "pdf",
      header: null,
      headerStyle: "font-weight: 300;",
      maxWidth: 800,
      font: "TimesNewRoman",
      font_size: "12pt",
      honorMarginPadding: true,
      honorColor: false,
      properties: null,
      gridHeaderStyle: "font-weight: bold; padding: 5px; border: 1px solid #dddddd;",
      gridStyle: "border: 1px solid lightgray; margin-bottom: -1px;",
      showModal: false,
      onError: error => {
        throw error;
      },
      onLoadingStart: null,
      onLoadingEnd: null,
      onPrintDialogClose: null,
      onPdfOpen: null,
      onBrowserIncompatible: () => true,
      modalMessage: "Retrieving Document...",
      frameId: "printJS",
      printableElement: null,
      documentTitle: "Document",
      targetStyle: ["clear", "display", "width", "min-width", "height", "min-height", "max-height"],
      targetStyles: ["border", "box", "break", "text-decoration"],
      ignoreElements: [],
      imageStyle: "max-width: 100%;",
      repeatTableHeader: true,
      css: null,
      style: null,
      scanStyles: true,
      base64: false,
    };

    let args = arguments[0];
    if (args === undefined) throw new Error("printJS expects at least 1 attribute.");

    switch (typeof args) {
      case "string":
        params.printable = encodeURI(args);
        params.fallbackPrintable = params.printable;
        params.type = arguments[1] || params.type;
        break;
      case "object":
        params.printable = args.printable;
        params.base64 = typeof args.base64 !== "undefined";
        params.fallbackPrintable = typeof args.fallbackPrintable !== "undefined" ? args.fallbackPrintable : params.printable;
        params.fallbackPrintable = params.base64 ? `data:application/pdf;base64,${params.fallbackPrintable}` : params.fallbackPrintable;
        for (var k in params) {
          if (k === "printable" || k === "fallbackPrintable" || k === "base64") continue;

          params[k] = typeof args[k] !== "undefined" ? args[k] : params[k];
        }
        break;
      default:
        throw new Error('Unexpected argument type! Expected "string" or "object", got ' + typeof args);
    }

    if (!params.printable) throw new Error("Missing printable information.");

    if (!params.type || typeof params.type !== "string" || printTypes.indexOf(params.type.toLowerCase()) === -1) {
      throw new Error("Invalid print type. Available types are: pdf, html, image and json.");
    }

    if (params.showModal) Modal.show(params);

    if (params.onLoadingStart) params.onLoadingStart();

    let usedFrame = document.getElementById(params.frameId);

    if (usedFrame) usedFrame.parentNode.removeChild(usedFrame);

    let printFrame;

    printFrame = document.createElement("iframe");

    printFrame.setAttribute("style", "visibility: hidden; height: 0; width: 0; position: absolute;");

    printFrame.setAttribute("id", params.frameId);

    if (params.type === "full-raw-html") {
      printFrame.srcdoc = "";
    } else if (params.type !== "pdf") {
      printFrame.srcdoc = "<html><head><title>" + params.documentTitle + "</title>";

      if (params.css) {
        if (!Array.isArray(params.css)) params.css = [params.css];

        params.css.forEach(file => {
          printFrame.srcdoc += '<link rel="stylesheet" href="' + file + '">';
        });
      }

      printFrame.srcdoc += "</head><body></body></html>";
    }

    switch (params.type) {
      case "pdf":
        if (Browser.isFirefox() || Browser.isEdge() || Browser.isIE()) {
          try {
            console.info("PrintJS currently doesn't support PDF printing in Firefox, Internet Explorer and Edge.");
            if (params.onBrowserIncompatible() === true) {
              let win = window.open(params.fallbackPrintable, "_blank");
              win.focus();
              if (params.onPdfOpen) params.onPdfOpen();
            }
          } catch (e) {
            params.onError(e);
          } finally {
            if (params.showModal) Modal.close();
            if (params.onLoadingEnd) params.onLoadingEnd();
          }
        } else {
          Pdf.print(params, printFrame);
        }
        break;
      case "image":
        Image.print(params, printFrame);
        break;
      case "html":
        Html.print(params, printFrame);
        break;
      case "full-raw-html":
        FullRawHtml.print(params, printFrame);
        break;
      case "raw-html":
        RawHtml.print(params, printFrame);
        break;
      case "json":
        Json.print(params, printFrame);
        break;
      default:
        break;
    }
  },
};
