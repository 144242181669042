import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Modal, Button, Icon, Form } from "semantic-ui-react";
import AlertContainer from "react-alert";

import { UserInput } from "../../components";
import ACTIONS from "./actions";
import Context from "./context";

import Service from "./service";

const EDIT_MODAL_ACTIONS = [ACTIONS.EDIT_MODAL_SHOW, ACTIONS.EDIT_MODAL_SAVE];

const newKiosk = { id: 0, name: "", confirmation_title: "", confirmation_image_url: null };

class KioskEditModal extends Component {
  static contextType = Context;

  state = {
    selectedKioskIndex: this.context.selectedKioskIndex,
    kiosk: { ...newKiosk },
    incompleteForm: false,
  };

  componentDidUpdate() {
    const { action, selectedKioskIndex, kiosks } = this.context;

    if ([null, ...EDIT_MODAL_ACTIONS].includes(action) && this.state.selectedKioskIndex !== selectedKioskIndex) {
      const kiosk = [-1, null].includes(selectedKioskIndex) ? { ...newKiosk } : kiosks[selectedKioskIndex];
      this.setState({ kiosk, selectedKioskIndex });
    }
  }

  onSave = () => {
    const { kiosk } = this.state;
    if (!kiosk.name || !kiosk.confirmation_title || !kiosk.confirmation_image_url) this.setState({ incompleteForm: true });
    else this.setState({ incompleteForm: false }, () => this.context.controller.submitKiosk(this.state.kiosk));
  };

  uploadConfirmationImage = file => {
    const { kiosk } = this.state;
    const {
      globalState: { selectedLocation },
    } = this.context;

    const extension = file.name.lastIndexOf(".") > -1 ? file.name.slice(file.name.lastIndexOf(".") + 1) : "unknown";
    const uploadFile = new File([file], file.name, { type: file.type || extension });
    const formData = new FormData();

    formData.append("file", uploadFile);
    formData.append("dealer_location_id", selectedLocation.id);

    Service.addConfirmationImage(formData)
      .then(res => {
        if (res?.data?.data?.url) {
          this.setState({ kiosk: { ...kiosk, confirmation_image_url: res.data.data.url } });
        } else throw new Error("Error uploading image");
      })
      .catch(err => {
        console.error("Error uploading image:", err);
        this.msg.show(err.message, { type: "error" });
      });
  };

  handleFileChange = e => {
    this.uploadConfirmationImage(e.target.files[0]);
  };

  renderAlert = () => {
    const props = {
      offset: 20,
      position: "top right",
      theme: "light",
      time: 2000,
      transition: "fade",
    };
    return <AlertContainer ref={a => (this.msg = a)} {...props} />;
  };

  close = () => this.context.controller.setAction(null);

  render() {
    const { kiosk, incompleteForm } = this.state;
    const { t } = this.props;
    const { isLoading, selectedKioskIndex, action } = this.context;

    const isOpen = selectedKioskIndex !== null && EDIT_MODAL_ACTIONS.includes(action);

    return (
      <Modal className="KioskEditModal" open={isOpen} onClose={this.close} closeOnDimmerClick={false} size="small">
        <Modal.Header>
          <div>{selectedKioskIndex > -1 ? t("edit_kiosk").message || "Edit Kiosk" : t("add_kiosk").message || "Add Kiosk"}</div>
          <Button negative icon="close" disabled={isLoading} content={t("cancel").message || "Cancel"} onClick={this.close} />
        </Modal.Header>

        <Modal.Content scrolling>
          <Form>
            <Form.Field error={incompleteForm && !kiosk.name}>
              <UserInput
                type="text"
                placeholder={t("name").message || "Name"}
                value={kiosk.name}
                onChange={(_event, data) => this.setState({ kiosk: { ...kiosk, name: data.value } })}
              />
            </Form.Field>

            <Form.Field error={incompleteForm && !kiosk.confirmation_title}>
              <UserInput
                type="text"
                placeholder={t("confirmation_title").message || "Confirmation title"}
                value={kiosk.confirmation_title}
                onChange={(_event, data) => this.setState({ kiosk: { ...kiosk, confirmation_title: data.value } })}
              />
            </Form.Field>

            <Form.Field error={incompleteForm && !kiosk.confirmation_image_url}>
              <input type="file" ref={this.fileInputRef} onChange={this.handleFileChange} accept="image/png, image/jpeg, image/jpg" />
              {kiosk.confirmation_image_url && (
                <div className="KioskImageContainer">
                  <img src={kiosk.confirmation_image_url} alt="confirmation" />
                </div>
              )}
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button color="green" onClick={() => this.onSave()} loading={isLoading} disabled={isLoading}>
            <Icon name="checkmark" /> {t("save").message || "SAVE"}
          </Button>
        </Modal.Actions>

        {this.renderAlert()}
      </Modal>
    );
  }
}

export default withTranslation()(KioskEditModal);
