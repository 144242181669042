import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import Service from "./service";
import "./QuestionElements.css";
import { Label, Button, Modal, Form, Icon, Grid, List } from "semantic-ui-react";
import { FloatingActions, UserMenuActionsPortal, SubHeader } from "../../components";
import Can from "../Can";
import QuestionElementSingle from "./QuestionElementSingle";
import CustomConfirm from "../../components/CustomConfirm";

class QuestionElements extends Component {
  state = {
    loadingQuestionElements: false,
    elements: [],
    selectedElement: {},
    isElementSaveVisible: false,
    isNewElementModalVisible: false,
    newElement: {
      name: "",
      template: "dropdown",
    },
    dropdownNewItemLabel: "",
    solutionNewItemLabels: {
      unit_items: "",
      value_items: "",
      type_items: "",
    },
    isDeleteElementModalVisible: false,
    displayRequiredField: false,
    isLoading: false,
    showElementErrorMessage: false,
  };

  templateList = [
    {
      value: "dropdown",
      text: "Dropdown",
    },
    {
      value: "dropdown_multi",
      text: "Multiselect dropdown",
    },
    {
      value: "solution_element",
      text: "Solution",
    },
    {
      value: "textfield",
      text: "Text Field",
    },
    {
      value: "number",
      text: "Number",
    },
    {
      value: "textarea",
      text: "Textarea",
    },
    {
      value: "spinner",
      text: "Spinner",
    },
    {
      value: "datepicker",
      text: "Datepicker",
    },
    {
      value: "tyre",
      text: "Tyre",
    },
    {
      value: "truck_tyre",
      text: "Truck tyre",
    },
    {
      value: "heavy_van_tyre",
      text: "Heavy van tyre",
    },
    {
      value: "picture",
      text: "Picture",
    },
    {
      value: "employee_signature",
      text: "Employee signature",
    },
    {
      value: "customer_signature",
      text: "Customer signature",
    },
    {
      value: "testdrive_mileage",
      text: "Test drive mileage",
    },
    {
      value: "timestamp",
      text: "Timestamp",
    },
    {
      value: "panic",
      text: "Panic",
    },
  ];

  componentDidMount() {
    this.handleRefresh();
  }

  handleRefresh = () => {
    this.getElements();
  };

  getElements = () => {
    this.setState({ loadingQuestionElements: true }, () => {
      Service.getElements()
        .then(response => {
          let elements = response.data && response.data.data ? response.data.data.question_elements : [];
          elements.sort((a, b) => this.sortByName(a, b));

          let selectedElement = elements[0] && elements[0].template ? elements[0] : {};
          if (selectedElement.default_configuration) selectedElement.default_configuration_parsed = JSON.parse(selectedElement.default_configuration);

          this.setState({ loadingQuestionElements: false, elements, selectedElement });
        })
        .catch(error => {
          console.error("failed to load question elements", error);
          this.setState({ loadingQuestionElements: false });
        });
    });
  };

  selectElement = element => {
    element.default_configuration_parsed = JSON.parse(element.default_configuration);
    this.setState({ selectedElement: element, isElementSaveVisible: false });
  };

  toggleElementDeleteModal = () => {
    this.setState({ isDeleteElementModalVisible: !this.state.isDeleteElementModalVisible, showElementErrorMessage: false });
  };

  sortByName = (a, b) => {
    if (a.name.toUpperCase() < b.name.toUpperCase()) return -1;
    if (a.name.toUpperCase() > b.name.toUpperCase()) return 1;
    return 0;
  };

  renderElementsList = () => {
    let { loadingQuestionElements, elements } = this.state;

    if (loadingQuestionElements)
      return (
        <div style={{ height: "100%" }} className="Table__loading Loader-Placeholder">
          <div className="bounce1"></div>
          <div className="bounce2"></div>
          <div className="bounce3"></div>
          <p>{this.props.t("loading").message || "Loading"}</p>
        </div>
      );

    if (elements.length > 0) {
      let list = elements.map((element, index) => {
        return (
          <List.Item className="questionElementsItem" active={this.state.selectedElement.id === element.id} key={index} onClick={() => this.selectElement(element)}>
            <List.Content>
              {element.name}
              <Label color="green" className="-pull-right">
                {element.template}
              </Label>
            </List.Content>
          </List.Item>
        );
      });

      return <List className="Scrollbar">{list}</List>;
    } else {
      return (
        <p style={{ textAlign: "center", padding: "10px" }}>
          <strong>There are no elements</strong>
        </p>
      );
    }
  };

  renderNewElementModal = () => {
    let { isNewElementModalVisible, newElement, displayRequiredField, isLoading } = this.state;
    let { t } = this.props;

    return (
      <Modal size="tiny" dimmer="inverted" onClose={this.handleCloseNewElementModal} open={isNewElementModalVisible} closeOnDimmerClick={false}>
        <Modal.Header>{t("add_new_element").message || "Add new element"}</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field>
              <Form.Input name="name" placeholder={t("element_name").message || "Element name"} defaultValue={newElement.name} onChange={this.handleNewElementChanged} />
              {displayRequiredField && (
                <Label basic color="red" pointing>
                  {t("required_field").message || "Field is required"}
                </Label>
              )}
            </Form.Field>
            <Form.Select options={this.templateList} onChange={this.handleNewElementTemplateChanged} />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.handleCloseNewElementModal} floated="left" color="red">
            <Icon name="close" />
            {t("cancel").message || "Cancel"}
          </Button>
          <Button loading={isLoading} disabled={isLoading} onClick={this.handleNewElementCreate} color="green">
            <Icon name="checkmark" />
            {t("save").message || "Save"}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };

  handleOpenNewElementModal = () => {
    this.setState({ displayRequiredField: false, isNewElementModalVisible: true, newElement: { name: "", template: "" } });
  };

  handleCloseNewElementModal = () => {
    this.setState({ isNewElementModalVisible: false });
  };

  handleNewElementChanged = event => {
    let newElement = this.state.newElement;
    newElement["name"] = event.target.value;

    this.setState({ newElement });
  };

  handleNewElementTemplateChanged = (event, data) => {
    let newElement = this.state.newElement;
    newElement["template"] = data.value;

    this.setState({ newElement });
  };

  handleNewElementCreate = () => {
    let { newElement } = this.state;
    if (!newElement.name) return this.setState({ displayRequiredField: true });

    this.setState({ isLoading: true }, () => {
      Service.createElement(newElement)
        .then(response => {
          let { elements } = this.state;
          newElement.id = response.data.data;
          newElement.default_configuration = "{}";
          elements.push(newElement);
          elements.sort(this.sortByName);

          this.setState({
            elements,
            isNewElementModalVisible: false,
            isLoading: false,
          });
        })
        .catch(error => {
          console.error("failed to create new question element", error);
          this.setState({ isLoading: false });
        });
    });
  };

  handleElementConfigurationChanged = event => {
    let { selectedElement } = this.state;
    selectedElement.default_configuration_parsed[event.target.name] = event.target.value;

    this.setState({ selectedElement, isElementSaveVisible: true });
  };

  handleElementDropdownNewItemLabelChanged = event => {
    this.setState({ dropdownNewItemLabel: event.target.value });
  };

  handleElementDropdownNewItemAdded = event => {
    let { selectedElement, dropdownNewItemLabel } = this.state;

    if (!dropdownNewItemLabel) return;

    if (!selectedElement.default_configuration_parsed.items) selectedElement.default_configuration_parsed.items = [];

    selectedElement.default_configuration_parsed.items.push({ label: dropdownNewItemLabel });

    this.setState({ selectedElement, dropdownNewItemLabel: "", isElementSaveVisible: true });
  };

  handleElementDropdownKeyPress = event => {
    if (event && event.charCode === 13) {
      event.preventDefault();

      this.handleElementDropdownNewItemAdded();
    }
  };

  handleElementDropdownItemRemoved = removedItemLabel => {
    let { selectedElement } = this.state;
    let index = selectedElement.default_configuration_parsed.items.findIndex(e => e.label === removedItemLabel);

    if (index !== -1) selectedElement.default_configuration_parsed.items.splice(index, 1);

    this.setState({ selectedElement, isElementSaveVisible: true });
  };

  handleElementDropdownSortEnd = ({ oldIndex, newIndex }, event) => {
    if (!event) return;

    let { selectedElement } = this.state;
    let list = selectedElement.default_configuration_parsed.items;

    const item = list.splice(oldIndex, 1);
    list.splice(newIndex, 0, item[0]);

    this.setState({ selectedElement, isElementSaveVisible: true });
  };

  handleElementSolutionNewItemLabelsChanged = (event, data) => {
    let { solutionNewItemLabels } = this.state;
    solutionNewItemLabels[data.name] = data.value;
    this.setState({ solutionNewItemLabels });
  };

  handleElementSolutionNewItemAdded = listType => {
    const T = listType + "_items";
    let { selectedElement, solutionNewItemLabels } = this.state;

    if (!solutionNewItemLabels[T]) return;

    if (!selectedElement.default_configuration_parsed[T]) selectedElement.default_configuration_parsed[T] = [];

    selectedElement.default_configuration_parsed[T].push({ label: solutionNewItemLabels[T] });

    // clear the input label
    solutionNewItemLabels[T] = "";
    this.setState({ selectedElement, solutionNewItemLabels, isElementSaveVisible: true });
  };

  handleElementSolutionItemRemoved = (removedItemLabel, listType) => {
    const T = listType + "_items";
    let { selectedElement } = this.state;

    let index = selectedElement.default_configuration_parsed[T].findIndex(e => e.label === removedItemLabel);

    if (index !== -1) selectedElement.default_configuration_parsed[T].splice(index, 1);

    this.setState({ selectedElement, isElementSaveVisible: true });
  };

  handleElementSolutionSortEnd = ({ oldIndex, newIndex }, event) => {
    if (!event || !event.srcElement.parentElement.dataset.listtype) return;

    const T = event.srcElement.parentElement.dataset.listtype + "_items";
    let { selectedElement } = this.state;
    let list = selectedElement.default_configuration_parsed[T];

    const item = list.splice(oldIndex, 1);
    list.splice(newIndex, 0, item[0]);

    this.setState({ selectedElement, isElementSaveVisible: true });
  };

  handleElementUpdate = () => {
    let { selectedElement } = this.state;
    selectedElement.default_configuration = JSON.stringify(selectedElement.default_configuration_parsed);
    this.setState({ selectedElement });

    this.setState({ isLoading: true }, () => {
      Service.saveElement(selectedElement)
        .then(response => {
          this.setState({ isLoading: false, isElementSaveVisible: false });
        })
        .catch(error => {
          this.setState({ isLoading: true });
          console.error("error updating question element", error);
        });
    });
  };

  handleElementDelete = () => {
    this.setState({ isLoading: true }, () => {
      Service.deleteElement({ id: this.state.selectedElement.id })
        .then(response => {
          let { elements, selectedElement } = this.state;
          let index = elements.findIndex(e => e.id === selectedElement.id);

          if (index !== 1) {
            elements.splice(index, 1);
            elements.sort(this.sortByName);
          }

          this.setState({
            isLoading: false,
            elements,
            isDeleteElementModalVisible: false,
            selectedElement: elements[0] ? elements[0] : null,
          });
        })
        .catch(error => {
          this.setState({ isLoading: false, showElementErrorMessage: true });
          console.error("error deleting question element", error);
        });
    });
  };

  render() {
    let {
      isDeleteElementModalVisible,
      loadingQuestionElements,
      selectedElement,
      isElementSaveVisible,
      isLoading,
      dropdownNewItemLabel,
      solutionNewItemLabels,
      showElementErrorMessage,
    } = this.state;
    let { t } = this.props;

    return (
      <div className="QuestionElements">
        <UserMenuActionsPortal>
          <Icon name="refresh" onClick={this.handleRefresh} />
        </UserMenuActionsPortal>

        {this.renderNewElementModal()}

        <CustomConfirm
          type="danger"
          isOpen={isDeleteElementModalVisible}
          handleCancel={this.toggleElementDeleteModal}
          handleConfirm={this.handleElementDelete}
          isLoading={this.state.isLoading}
          confirmMsg={this.props.t("confirm_delete_message").message || "Are you sure that you want to delete this? You can't undo this action."}
          error={showElementErrorMessage ? this.props.t("element_is_used_by_some_questions").message || "This element is used by some questions" : ""}
        />

        <SubHeader>
          <h1 style={{ marginLeft: "28px" }}>{t("question_elements").message || "Question Elements"}</h1>
        </SubHeader>

        <Grid divided className="-contained-large">
          <Grid.Column width={2}></Grid.Column>
          <Grid.Column width={5}>
            <h3>{t("elements").message || "Elements"}</h3>
            {this.renderElementsList()}
          </Grid.Column>

          <Grid.Column width={7}>
            {!loadingQuestionElements && selectedElement && selectedElement.template && (
              <Form>
                <QuestionElementSingle
                  element={selectedElement}
                  onElementConfigurationChanged={this.handleElementConfigurationChanged}
                  elementDropdownNewItemLabel={dropdownNewItemLabel}
                  onElementDropdownNewItemLabelChanged={this.handleElementDropdownNewItemLabelChanged}
                  onElementDropdownItemAdded={this.handleElementDropdownNewItemAdded}
                  onElementDropdownItemRemoved={this.handleElementDropdownItemRemoved}
                  onElementDropdownKeyPress={this.handleElementDropdownKeyPress}
                  onElementDropdownSortEnd={this.handleElementDropdownSortEnd}
                  elementSolutionNewItemLabels={solutionNewItemLabels}
                  onElementSolutionNewItemLabelsChanged={this.handleElementSolutionNewItemLabelsChanged}
                  onElementSolutionItemAdded={this.handleElementSolutionNewItemAdded}
                  onElementSolutionItemRemoved={this.handleElementSolutionItemRemoved}
                  onElementSolutionSortEnd={this.handleElementSolutionSortEnd}
                />

                <Form.Field>
                  <Can I="delete" the="questionelements">
                    <Button loading={isLoading} disabled={isLoading} onClick={this.toggleElementDeleteModal} color="red">
                      <Icon name="delete" />
                      {t("delete").message || "Delete"}
                    </Button>
                  </Can>
                  {isElementSaveVisible && (
                    <Button loading={isLoading} disabled={isLoading} floated="right" onClick={this.handleElementUpdate} color="green">
                      <Icon name="checkmark" />
                      {t("save").message || "Save"}
                    </Button>
                  )}
                </Form.Field>
              </Form>
            )}
          </Grid.Column>
          <Grid.Column width={2}></Grid.Column>
        </Grid>

        <FloatingActions items={[]} onRootClick={this.handleOpenNewElementModal} />
      </div>
    );
  }
}

export default withTranslation()(QuestionElements);
