import axios from "axios";

import ENV from "../../util/env-config";
import { getSessionCookie } from "../../util/common";

class Service {
  static requestConfig() {
    return {
      headers: {
        Authorization: `Bearer ${getSessionCookie()}`,
        Pragma: "no-cache",
      },
    };
  }

  static getAppointments(requestData) {
    return axios.post(ENV.dayplannerBaseUrl + "/appointments/list", requestData, this.requestConfig());
  }

  static getMechanics(requestData) {
    return axios.post(ENV.dayplannerBaseUrl + "/mechanics/list", requestData, this.requestConfig());
  }

  static assignAppointment(requestData) {
    return axios.post(ENV.dayplannerBaseUrl + "/appointments/assign", requestData, this.requestConfig());
  }

  static reassignAppointment(requestData) {
    return axios.post(ENV.dayplannerBaseUrl + "/appointments/reassign", requestData, this.requestConfig());
  }

  static unassignAppointment(requestData) {
    return axios.post(ENV.dayplannerBaseUrl + "/appointments/unassign", requestData, this.requestConfig());
  }

  static setMechanicActive(requestData) {
    return axios.post(ENV.dayplannerBaseUrl + "/mechanics/set_available", requestData, this.requestConfig());
  }

  static searchAppointments(requestData) {
    return axios.post(ENV.apiBaseB + "/appointments/search", requestData, this.requestConfig());
  }
}

export default Service;
