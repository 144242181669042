import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import ReactTable, { ReactTableDefaults } from "react-table";
import { Icon, Popup } from "semantic-ui-react";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";

import { CustomersCard, StatusCell } from "../../components";
import { APPOINTMENT_NOTE_TYPES } from "../Appointments/util";

class BackOrderTable extends Component {
  handleAppointmentClick = result => {
    if (this.props.externalLink) {
      window.open(`${document.location.origin}/#!/appointments/${result.id}`, "_blank");
      return;
    }
    this.props.onAppointmentClick(result);
  };

  handleCarClick = result => {
    if (this.props.externalLink) {
      window.open(`${document.location.origin}/#!/appointments/car/${result.car_id}`, "_blank");
      return;
    }

    this.props.onCarClick(result.car_id, result.dealer_location_id);
  };

  renderTable = () => {
    const { t, items, nb_items, page, pages, loading, onFetchData, externalLink } = this.props;

    if (!loading && (!items || items.length < 1)) return <div className="Table__no-results">{t("no_backorder_leads_found").message || "No back order leads found"}</div>;

    return (
      <ReactTable
        className="BackorderDashboardTable -floated-table"
        data={items || []}
        page={page - 1}
        pages={pages === null ? -1 : pages}
        showPagination={pages > 1}
        renderPageJump={({ onChange, value, onBlur, onKeyPress, inputType, pageJumpText }) => (
          <div className="-pageJump">
            <input
              aria-label={pageJumpText}
              type={inputType}
              onChange={evt => {
                onChange(evt);

                const newPage = evt.target.value - 0;
                if (!Number.isNaN(newPage) && newPage > 0 && newPage <= pages) onFetchData(newPage);
              }}
              value={value}
              onBlur={onBlur}
              onKeyPress={onKeyPress}
            />
          </div>
        )}
        loading={loading}
        manual
        onFetchData={state => onFetchData(state.page + 1)}
        showPageSizeOptions={false}
        sortable={false}
        resizable={false}
        minRows={0}
        loadingText=""
        nextText={t("next").message || "Next"}
        previousText={t("previous").message || "Previous"}
        pageText={t("page").message || "Page"}
        ofText={t("of").message || "of"}
        onPageChange={() => {
          try {
            document.querySelector(".App__module").scrollTo(0, 0);
          } catch (e) {
            document.querySelector(".App__module").scrollTop = 0; // IE Fix
          }
        }}
        column={{
          ...ReactTableDefaults.column,
          headerClassName: "ReactTable__column-header -text-ellipsis",
          className: "ReactTable__column",
        }}
        columns={[
          {
            Header: t("location").message || "Location",
            id: "location",
            accessor: d => {
              let dealerLocation = "";

              this.props.globalState.dealers.some(dealer => {
                const location = dealer.locations?.find(l => l.id === d.dealer_location_id);
                if (location) dealerLocation = `${dealer.name} - ${location.name}`;
                return location;
              });

              return dealerLocation;
            },
            maxWidth: 300,
          },
          {
            Header: t("Appointment_date").message || "Appointment Date",
            id: "appointmentDate",
            accessor: d =>
              d.time_car_app && (moment.isMoment(d.time_car_app) || !d.time_car_app.startsWith("0001-01-01T00:00:00")) // might be time zoned
                ? moment(d.time_car_app).format("DD-MM-YYYY")
                : "",
            maxWidth: 135,
          },
          {
            Header: t("bo_date").message || "BO Date",
            id: "bo_date",
            accessor: d => {
              const boNote = d.notes.findLast(n => n.appointment_note_type_id === APPOINTMENT_NOTE_TYPES.BACK_ORDER);
              return moment(boNote.backorder_date).format("DD-MM-YYYY");
            },
            maxWidth: 135,
          },
          {
            id: "woNr",
            Header: (
              <>
                {t("WO Nr").message || "WO"} <span className="total-appointments">{nb_items}</span>
              </>
            ),
            accessor: d =>
              d.wo_nr === 0 ? (
                ""
              ) : (
                <span className="-cursor-pointer" onClick={() => this.handleAppointmentClick(d)}>
                  {externalLink ? <FontAwesomeIcon icon={faExternalLinkAlt} style={{ marginRight: "5px", fontSize: "15px" }} /> : <Icon name="info circle" />}
                  {d.wo_nr}
                </span>
              ),
            maxWidth: 100,
          },
          {
            id: "regNo",
            Header: t("Reg Nr").message,
            accessor: d =>
              d.reg_number && (
                <span className="-cursor-pointer" onClick={() => this.handleCarClick(d)}>
                  {externalLink ? <FontAwesomeIcon icon={faExternalLinkAlt} style={{ marginRight: "5px", fontSize: "15px" }} /> : <Icon name="info circle" />}
                  {d.reg_number}
                </span>
              ),
            maxWidth: 120,
          },

          {
            Header: t("bo_note").message || "BO Note",
            id: "bo_note",
            accessor: d => {
              const boNote = d.notes.findLast(n => n.appointment_note_type_id === APPOINTMENT_NOTE_TYPES.BACK_ORDER);
              return <Popup basic wide="very" content={<span>{boNote.note}</span>} trigger={<span>{boNote.note}</span>} />;
            },
          },

          {
            id: "user",
            Header: "",
            accessor: d =>
              !d.customer_contract && !d.customer_driver && !d.customer_owner ? (
                <div className="QuestionStatus" style={{ visibility: "hidden" }} />
              ) : (
                <CustomersCard contractor={d.customer_contract} driver={d.customer_driver} owner={d.customer_owner} />
              ),
            maxWidth: 42,
          },
          {
            Header: t("status").message || "Status",
            id: "status",
            className: "-status-column",
            accessor: d => ({
              name: d.appointment_status_name,
              color: d.appointment_status_color,
              id: d.appointment_status_identifier,
              customcom_status: d.customcom_status,
              check_paused_at: d.check_paused_at,
            }),
            Cell: row => <StatusCell value={row.value} statuses={this.props.globalState.selectedLocation.statuses} car_out_of_shop={row.original.car_out_of_shop} />,
            minWidth: 62,
            maxWidth: 62,
          },
        ]}
      />
    );
  };

  render() {
    return <div className="BackOrderLeadsTable">{this.renderTable()}</div>;
  }
}

const mapStateToProps = state => {
  return { globalState: state.global };
};

export default withTranslation()(connect(mapStateToProps)(BackOrderTable));
