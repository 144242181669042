export const SNOOZE_TYPE = {
  NEXT_VISIT_CUSTOMER: 1,
  MAKE_OFFER: 2,
  MAKE_NEW_APPOINTMENT: 3,
  REMIND_CUSTOMER: 4,
};

export const SNOOZE_STATUS = {
  DELETED: 0,
};
