import React, { Component } from "react";
import { Button, Icon, Modal, Form, Dropdown, Checkbox } from "semantic-ui-react";
import moment from "moment";
import { withTranslation } from "react-i18next";
import ReactQuill from "react-quill";
import DatePicker from "react-datepicker";

import "react-quill/dist/quill.snow.css";

import { UserInput } from "../../components";
import DealerLocationsDropdown from "../../components/DealerLocationsDropdown";

const toolbarOptions = ["bold", "italic", "underline", "link", "image", "video"];

const newArticle = {
  title: "",
  content: "",
  category_id: null,
  selector: ".App",
  language_code: "nl-NL",
  publish_date: null,
  expire_date: null,
  visible_to_all: false,
  visible_on_tablet: true,
  dealer_ids: [],
  location_ids: [],
  user: {
    first_name: "",
    last_name: "",
  },
};

class NewsDetail extends Component {
  constructor(props) {
    super(props);

    const { article } = props;

    if (article) {
      if (article.publish_date) article.publish_date = moment(article.publish_date);
      if (article.expire_date) article.expire_date = moment(article.expire_date);
    }

    this.state = {
      article: article ? { ...article } : { ...newArticle },
    };
  }

  handleChange = (_e, data) => this.setState(state => ({ article: { ...state.article, [data.name]: data.value } }));

  handleContentChange = content => this.setState(state => ({ article: { ...state.article, content } }));

  handleChangePublishDate = publish_date => {
    const { article } = this.state;
    if (publish_date) {
      article.publish_date = moment(publish_date);
      if (article.expire_date && article.publish_date.isAfter(article.expire_date)) article.expire_date = null;
    } else {
      article.publish_date = null;
    }

    this.setState({ article });
  };

  handleChangeExpireDate = expire_date => {
    const { article } = this.state;
    if (expire_date) {
      article.expire_date = moment(expire_date);
      if (article.publish_date && article.expire_date.isBefore(article.publish_date)) article.publish_date = null;
    } else {
      article.expire_date = null;
    }

    this.setState({ article });
  };

  handleChangeSelectedLocationDealerIDs = selected => {
    this.setState({ article: { ...this.state.article, visible_to_all: selected.isAllSelected, location_ids: selected.location_ids, dealer_ids: selected.dealer_ids } });
  };

  handleChangeCheckbox = (e, { name, checked }) => {
    this.setState({ article: { ...this.state.article, [name]: checked } });
  };

  renderCustomRageDatePicker = () => {
    const { article } = this.state;
    const { t } = this.props;

    let publish_date = null;
    let expire_date = null;

    if (article.publish_date) publish_date = article.publish_date.toDate();
    if (article.expire_date) expire_date = article.expire_date.toDate();

    return (
      <Form.Group>
        <Form.Field width={3}>
          <label>{t("publish_date").message || "Publish date"}</label>
          <DatePicker
            dateFormat="dd-MM-yyyy"
            placeholderText={t("date_from").message || "Date from"}
            maxDate={expire_date}
            selected={publish_date}
            onChange={date => this.handleChangePublishDate(date)}
            popperPlacement="bottom"
            popperModifiers={{
              flip: {
                behavior: ["bottom"],
              },
              preventOverflow: {
                enabled: false,
              },
              hide: {
                enabled: false,
              },
            }}
            onChangeRaw={e => e.preventDefault()}
            showMonthDropdown
            showYearDropdown
            isClearable
          />
        </Form.Field>

        <Form.Field width={3}>
          <label>{t("expire_date").message || "Expire date"}</label>
          <DatePicker
            dateFormat="dd-MM-yyyy"
            placeholderText={t("date_to").message || "Date to"}
            minDate={publish_date}
            selected={expire_date}
            onChange={date => this.handleChangeExpireDate(date)}
            popperPlacement="bottom"
            popperModifiers={{
              flip: {
                behavior: ["bottom"],
              },
              preventOverflow: {
                enabled: false,
              },
              hide: {
                enabled: false,
              },
            }}
            onChangeRaw={e => e.preventDefault()}
            showMonthDropdown
            showYearDropdown
            isClearable
          />
        </Form.Field>
        <Form.Field>
          <label>{t("visible_on_tablet").message || "Visible on tablet"}</label>
          <Checkbox toggle name="visible_on_tablet" checked={article.visible_on_tablet} onChange={this.handleChangeCheckbox} />
        </Form.Field>
      </Form.Group>
    );
  };

  render() {
    const { mode, categories, onSave, onToggle, renderAlert, isLoading, isUserAdmin, onHide, t, isSubmitted } = this.props;
    const { article } = this.state;

    return (
      <Modal
        className="NewsDetail"
        open={true}
        onClose={() => {
          onHide();
        }}
        closeOnDimmerClick={false}
        size="large"
      >
        <Modal.Header>
          {mode === "create" ? t("add_article").message || "Add Article" : t("edit_article").message || "Edit Article"}
          <Button
            floated="right"
            positive
            content={t("close").message || "Close"}
            onClick={() => {
              onHide();
            }}
          />
        </Modal.Header>

        <Modal.Content scrolling>
          <Form>
            {isUserAdmin && (
              <Form.Field error={isSubmitted && !article.selector}>
                <UserInput type="text" placeholder={t("selector").message || "Selector"} name="selector" value={article.selector} onChange={this.handleChange} />
              </Form.Field>
            )}

            <Form.Field error={isSubmitted && !article.location_ids?.length && !article.dealer_ids?.length && !article.visible_to_all}>
              <DealerLocationsDropdown
                options={{ isAllSelected: article.visible_to_all, location_ids: article.location_ids, dealer_ids: article.dealer_ids }}
                selectAll={true}
                selectDealers={true}
                onChange={this.handleChangeSelectedLocationDealerIDs}
              />
            </Form.Field>

            <Form.Field error={isSubmitted && !article.title}>
              <UserInput type="text" placeholder={t("title").message || "Title"} name="title" value={article.title} onChange={this.handleChange} />
            </Form.Field>
            <Form.Field>
              <Dropdown
                selection
                clearable
                name="category_id"
                value={article.category_id}
                options={categories}
                onChange={this.handleChange}
                placeholder={t("category").message || "Category"}
              />
            </Form.Field>
            <Form.Field width={2}>
              <Dropdown
                name="language_code"
                placeholder={t("language").message || "Language"}
                selection
                options={[
                  {
                    text: t("dutch").message || "Dutch",
                    value: "nl-NL",
                    key: "nl",
                  },
                  {
                    text: t("german").message || "German",
                    value: "de-DE",
                    key: "de",
                  },
                  {
                    text: t("french").message || "French",
                    value: "fr-FR",
                    key: "fr",
                  },
                  {
                    text: t("english").message || "English",
                    value: "en-GB",
                    key: "en",
                  },
                ]}
                value={article.language_code}
                onChange={this.handleChange}
              />
            </Form.Field>

            {this.renderCustomRageDatePicker()}

            <Form.Field>
              <ReactQuill
                className={isSubmitted && !article.content ? "quill_error" : ""}
                modules={{ toolbar: toolbarOptions }}
                value={article.content}
                onChange={this.handleContentChange}
              />
            </Form.Field>

            <Form.Field>
              {mode === "edit" && (
                <div>
                  <p>
                    Published by
                    <strong>{` ${article.user.first_name} ${article.user.last_name}`}</strong>
                  </p>
                  <p>
                    Created on
                    <strong>{` ${moment(article.created_on).format("DD-MM-YYYY")}`}</strong>
                  </p>
                  <p>
                    Last update on
                    <strong>{` ${moment(article.updated_on).format("DD-MM-YYYY")}`}</strong>
                  </p>
                </div>
              )}
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          {mode === "edit" && (
            <Button
              color={article.disabled ? "green" : "yellow"}
              disabled={isLoading}
              loading={isLoading}
              onClick={() => {
                onToggle(article);
              }}
              floated="left"
            >
              <Icon name="warning" />
              {article.disabled ? t("enable").message || "Enable" : t("disable").message || "Disable"}
            </Button>
          )}
          <Button color="green" onClick={() => onSave(article)} loading={isLoading} disabled={isLoading}>
            <Icon name="checkmark" /> {t("save").message || "SAVE"}
          </Button>
        </Modal.Actions>

        {renderAlert()}
      </Modal>
    );
  }
}

export default withTranslation()(NewsDetail);
