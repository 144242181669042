import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Grid, Modal, Icon, Label, Button, Checkbox } from "semantic-ui-react";
import moment from "moment";

import { ImageGallery, ImageLetters } from "../../components";
import { ANSWER_STATUSES, getLocationById } from "../../util/common";
import { TYRE_POSITION } from "../../modules/Appointments/common";
import { renderPinTypeIcon } from "./util";
import { PIN_STATUS } from "./enum";
import printJS from "../../lib/print-js";
import Service from "./service";

import "./PinOverview.css";
class PinOverview extends Component {
  state = {
    isOpen: false,
    isPrintLoading: false,
    showCustomerInformation: true,
    isLoadingChecks: false,
    isLoadingCarDetail: false,
    isLoadingCustomer: false,
    appointmentChecks: [],
    car: null,
    customer: null,
    location: {},
  };

  componentDidMount() {
    const { globalState, appointment } = this.props;

    this.setState({ location: getLocationById(globalState.dealers, appointment.dealer_location_id) });
  }

  componentDidUpdate(prevProps) {
    const { showPrintOverview } = this.props;

    if (prevProps.showPrintOverview !== showPrintOverview && showPrintOverview) {
      this.loadChecks();
      this.loadCarDetails();
      this.loadCustomer();
    }
  }

  loadCarDetails = () => {
    if (this.state.car) return;

    const { car_id, car } = this.props.appointment;

    if (car) this.setState({ car });
    else {
      this.setState({ isLoadingCarDetail: true }, () => {
        Service.getCar(car_id)
          .then(res => this.setState({ car: res?.data?.data, isLoadingCarDetail: false }))
          .catch(err => {
            this.setState({ isLoadingCarDetail: false });
            console.error("Error loading car detail", err);
          });
      });
    }
  };

  loadCustomer = () => {
    const { customer_driver, customer_owner, customer_contract, customer_driver_id, customer_owner_id, customer_contract_id } = this.props.appointment;

    const customer = customer_driver || customer_owner || customer_contract;

    if (!customer) {
      this.setState({ isLoadingCustomer: true }, () => {
        Service.getCustomer(customer_driver_id || customer_owner_id || customer_contract_id)
          .then(res => this.setState({ isLoadingCustomer: false, customer: res?.data?.data || {} }))
          .catch(err => this.setState({ isLoadingCustomer: false }, () => console.error("Error loading customer details", err)));
      });

      return;
    }

    this.setState({ customer });
  };

  loadChecks = () => {
    if (this.state.appointmentChecks.length) return;

    const { questionResult, intervention, appointmentsState } = this.props;
    const appointmentId = questionResult?.appointment_id || intervention?.appointment_id;

    if (appointmentsState.selectedAppointment) {
      this.setState({ appointmentChecks: appointmentsState.selectedAppointmentChecks });
    } else {
      this.getAppointmentChecks(appointmentId);
    }
  };

  stopPrintLoading = e => this.setState({ isPrintLoading: false });

  handlePrint = () => {
    return this.setState({ isPrintLoading: true }, () => {
      printJS({
        printable: "pin-overview-content",
        type: "html",
        copyStyles: true,
        onLoadingEnd: this.stopPrintLoading,
        onError: this.stopPrintLoading,
      });
    });
  };

  toggleShowCustomerInformation = () => {
    this.setState({ showCustomerInformation: !this.state.showCustomerInformation });
  };

  getAppointmentChecks = id => {
    this.setState({ isLoadingChecks: true }, () => {
      Service.getAppointmentChecks(id)
        .then(result => {
          if (result?.data?.data?.checks) this.setState({ isLoadingChecks: false, appointmentChecks: result.data.data.checks });
        })
        .catch(errorMessage => {
          this.setState({ isLoadingChecks: false });
          console.error(errorMessage);
        });
    });
  };

  renderCarRegistration = (country, car) => {
    const countryCode = ["BE", "FR", "LU"].includes(country) ? country[0] : country;

    return (
      <div className={`reg-container ${country || ""}`}>
        <div className="reg-country">
          <div>{countryCode || ""}</div>
        </div>
        <div className="reg-nr">{car?.reg_nr.split("-").join(" - ")}</div>
      </div>
    );
  };

  renderCarInformation = () => {
    const { car, location } = this.state;

    return (
      <Grid.Column width={6}>
        <div className="section-content-row page-break-inside-avoid">{this.renderCarRegistration(location.country, car)}</div>
        {car?.vin_nr && (
          <div className="section-content-row page-break-inside-avoid">
            <Icon name="info circle" color="green" className="section-icon" />
            {car.vin_nr}
          </div>
        )}
        {(car?.make || car?.model) && (
          <div className="section-content-row page-break-inside-avoid">
            <Icon name="car" className="section-icon" />
            {car.make || ""} {car.model || ""}
          </div>
        )}
      </Grid.Column>
    );
  };

  renderAppointmentInformation = () => {
    const { appointment } = this.props;
    const { location } = this.state;

    return (
      <Grid.Column width={5}>
        <div className="section-content-row page-break-inside-avoid">
          <Icon name="calendar alternate" className="section-icon" />
          {appointment.due_in ? moment(appointment.due_in).format("DD-MM-YYYY HH:mm") : moment(appointment.time_car_app).format("DD-MM-YYYY")}
        </div>
        <div className="section-content-row page-break-inside-avoid">
          <Icon name="info circle" color="green" className="section-icon" />
          {appointment.wo_nr}
        </div>
        <div className="section-content-row page-break-inside-avoid">
          <Icon name="map" className="section-icon" />
          {`${location.name}, ${location.city}`}
        </div>
      </Grid.Column>
    );
  };

  getCustomerInformation = customer => {
    if (!customer) return;

    return {
      full_name: `${customer.title && customer.title + " "}${customer.firstname && customer.firstname + " "}${customer.surname && customer.surname + " "}`,
      email: customer.email_business || customer.email_private,
      phone_nr: customer.tel_private_nr || customer.tel_business_nr || customer.tel_mobile_private || customer.tel_mobile_business,
    };
  };

  renderCustomerInformation = () => {
    if (!this.state.showCustomerInformation) return null;

    const customer = this.getCustomerInformation(this.state.customer);

    return (
      <Grid.Column width={5}>
        {customer && (
          <>
            <div className="section-content-row page-break-inside-avoid">
              <Icon name="user" className="section-icon" />
              {customer.full_name}
            </div>

            {customer.email && (
              <div className="section-content-row page-break-inside-avoid">
                <Icon name="mail" color="green" className="section-icon" />
                {customer.email}
              </div>
            )}

            {customer.phone_nr && (
              <div className="section-content-row page-break-inside-avoid">
                <Icon name="phone" className="section-icon" />
                {customer.phone_nr}
              </div>
            )}
          </>
        )}
      </Grid.Column>
    );
  };

  renderHeaderSection = () => {
    const { t } = this.props;

    return (
      <div className="info-section">
        <div className="section-header fix-printed-dimensions page-break-inside-avoid">
          <Grid>
            <Grid.Column width={5}>{t("appointment_information").message || "Appointment information"}</Grid.Column>
            {this.state.showCustomerInformation && <Grid.Column width={5}>{t("customer_information").message || "Customer information"}</Grid.Column>}
            <Grid.Column width={6}>{t("car_information").message || "Car information"}</Grid.Column>
          </Grid>
        </div>
        <div className="section-content fix-printed-dimensions">
          <Grid>
            {this.renderAppointmentInformation()}
            {this.renderCustomerInformation()}
            {this.renderCarInformation()}
          </Grid>
        </div>
      </div>
    );
  };

  renderPinSection = () => {
    const { pin } = this.props;
    const { t, pinStatusOptions } = this.props;

    const columnSize = pin.pin_status_id ? 4 : 5;

    if (!Object.keys(pin).length) return null;

    return (
      <div className="pin-section">
        <div className="section-header fix-printed-dimensions page-break-inside-avoid">
          <Grid>
            <Grid.Column width={columnSize}>{t("support_nr").message || "Support number"}</Grid.Column>
            <Grid.Column width={columnSize}>{t("claim_nr").message || "Claim number"}</Grid.Column>
            <Grid.Column width={columnSize}>{t("reference").message || "Reference"}</Grid.Column>
            {pin.pin_status_id && <Grid.Column width={columnSize}>{t("status").message || "Status"}</Grid.Column>}
          </Grid>
        </div>
        <div className="section-content fix-printed-dimensions">
          <Grid>
            <Grid.Column width={columnSize}>{pin.support_nr || ""}</Grid.Column>
            <Grid.Column width={columnSize}>{pin.claim_nr || ""}</Grid.Column>
            <Grid.Column width={columnSize}>{pin.ref_nr || ""}</Grid.Column>
            {pin.pin_status_id && <Grid.Column width={columnSize}>{pinStatusOptions[pin.pin_status_id]}</Grid.Column>}
          </Grid>
        </div>
      </div>
    );
  };

  renderPinnedItemSection = () => {
    const { questionResult, intervention, t } = this.props;

    const check = questionResult || intervention || null;

    if (!check) return null;

    return (
      <div className="full-width-section item-section">
        <div className="section-header header-green fix-printed-dimensions page-break-inside-avoid">
          {questionResult && (t("question_result").message || "Question result")}
          {intervention && (t("intervention").message || "Intervention")}
        </div>
        <div className="section-content fix-printed-dimensions">
          <div className="content-row fix-printed-dimensions">
            <div className="content-title fix-printed-dimensions">
              <h2>{check.title}</h2>
              {ANSWER_STATUSES[check.status] && (
                <Button className={`item-status`} color={ANSWER_STATUSES[check.status].color || "#fff"}>
                  {ANSWER_STATUSES[check.status].text}
                </Button>
              )}
            </div>
            {questionResult?.raw && <div className="content-text fix-printed-dimensions">{questionResult.raw}</div>}
            {intervention?.description && <div className="content-text fix-printed-dimensions">{intervention.description}</div>}
          </div>
        </div>
      </div>
    );
  };

  renderImportantNotesSection = () => {
    const { pinLog, t } = this.props;

    if (!pinLog.length || !pinLog.some(pin => pin.visible_important_items)) return null;

    return (
      <div className="full-width-section important-notes-section">
        <div className="section-header header-yellow fix-printed-dimensions page-break-inside-avoid">{t("important_notes").message || "Important notes"}</div>
        <div className="section-content fix-printed-dimensions">
          {pinLog.map(
            (pin, key) =>
              pin.visible_important_items && (
                <div className="content-note page-break-inside-avoid fix-printed-dimensions" key={key}>
                  {pin.pin_type_id && <Label>{renderPinTypeIcon(pin.pin_type_id)}</Label>}
                  {pin.keep_parts && (t("keep_parts_prefix").message || "KEEP PARTS") + " "}
                  {pin.note}
                </div>
              )
          )}
        </div>
      </div>
    );
  };

  renderLogSection = () => {
    const { pinLog, renderInitialLogValues, renderPinChanges, t } = this.props;

    if (!pinLog.length) return null;

    return (
      <div className="full-width-section log-section">
        <div className="section-header header-grey fix-printed-dimensions page-break-inside-avoid">{t("logs").message || "Logs"}</div>
        <div className="section-content fix-printed-dimensions page-break-inside-avoid">
          {pinLog.map((pin, i) => (
            <div className="pin-log page-break-inside-avoid" key={pin.id}>
              <div>
                <strong>
                  {pin.is_dms ? (
                    "DMS"
                  ) : (
                    <>
                      {pin.user.first_name} {pin.user.last_name}
                    </>
                  )}
                </strong>
                {" - "}
                {moment(pin.created_on).format("HH:mm DD-MM-YYYY")}
                {pin.manufacturer_id > 0 && (
                  <>
                    {" - "}
                    {
                      <strong>
                        {t("granted_access_to_the_manufacturer").message || "granted access to the manufacturer"} {pin.manufacturer?.first_name}{" "}
                        {pin.manufacturer?.last_name}
                      </strong>
                    }
                  </>
                )}
                {pin.visible_mechanic && (
                  <>
                    {" - "}
                    <strong>{t("visible_mechanic_text").message || "Visible for mechanic"}</strong>
                  </>
                )}
                {pin.pin_status_id === PIN_STATUS.DELETED && (
                  <>
                    {" - "}
                    {<strong style={{ color: "red" }}>{t("deleted").message || "Deleted"}</strong>}
                  </>
                )}
                {pin.mechanic_fixed && !pinLog[i + 1]?.mechanic_fixed && (
                  <>
                    {" - "}
                    {<strong style={{ color: "blue" }}>{t("marked_as_fixed").message || "Marked as fixed"}</strong>}
                  </>
                )}
                {pin.info && (
                  <>
                    {" - "}
                    {<strong style={{ color: "blue" }}>{t("marked_as_info").message || "Marked as info"}</strong>}
                  </>
                )}
              </div>
              <div>
                {i === pinLog.length - 1 || pinLog[i - 1]?.pin_status_id === PIN_STATUS.DELETED ? renderInitialLogValues(pin) : renderPinChanges(pin, pinLog[i + 1])}
              </div>
              {pin.note && (
                <div>
                  {pin.keep_parts && pin.pin_status_id !== PIN_STATUS.DELETED && (t("keep_parts_prefix").message || "KEEP PARTS") + " "}
                  {pin.note}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    );
  };

  renderCheckSection = () => {
    const { questionResult, intervention, t } = this.props;
    const { isLoadingChecks, isLoadingCarDetail, appointmentChecks } = this.state;

    let linkedChecks = [];

    if (isLoadingChecks || isLoadingCarDetail)
      return (
        <div className="Loader-Placeholder checks-loading-container">
          <div className="bounce1"></div>
          <div className="bounce2"></div>
          <div className="bounce3"></div>
          <section>{t("loading_checks").message || "Loading checks"}</section>
        </div>
      );

    if (questionResult) {
      const check = appointmentChecks.find(check => check.id === questionResult.check_id);
      if (check) linkedChecks.push(check);
    } else if (intervention) linkedChecks = appointmentChecks.filter(check => check.intervention_id === intervention.id);

    if (linkedChecks.length < 1) return null;

    return linkedChecks.map(check => {
      const lettersMap = ImageGallery.getLettersMap(check.question_items);

      return (
        <div className="full-width-section fix-printed-dimensions" key={check.id}>
          <div className="section-header header-blue fix-printed-dimensions page-break-inside-avoid">
            {check.checklist.name || ""} - <span>{moment(check.created_on).format("DD-MM-YYYY HH:mm")}</span>
          </div>

          <div className="check-content fix-printed-dimensions">
            <ImageGallery lettersMap={lettersMap} />

            <div className="section-content fix-printed-dimensions">
              {check.question_items.map(item => {
                if (TYRE_POSITION.IS_ON_CAR(item.tyre_position) || TYRE_POSITION.IS_IN_STORAGE(item.tyre_position)) return this.renderTyre(item);

                if (item.status === 4) return null;

                return (
                  <div className="content-row fix-printed-dimensions" key={item.id}>
                    <div className="content-title page-break-inside-avoid fix-printed-dimensions">
                      <div className="fix-printed-dimensions">
                        {item.images?.length > 0 && <ImageLetters images={item.images} lettersMap={lettersMap} />}
                        <h2>{item.title}</h2>
                      </div>
                      {ANSWER_STATUSES[item.status] && (
                        <Button className={`item-status`} color={ANSWER_STATUSES[item.status].color || "#fff"}>
                          {ANSWER_STATUSES[item.status].text}
                        </Button>
                      )}
                    </div>
                    {item.raw && <div className="content-text page-break-inside-avoid fix-printed-dimensions">{item.raw}</div>}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      );
    });
  };

  getTitle = position => {
    const { t } = this.props;
    switch (position) {
      case TYRE_POSITION.CAR_FRONT_LEFT:
      case TYRE_POSITION.STORAGE_FRONT_LEFT:
        return t("front_left").message || "Front Left";

      case TYRE_POSITION.CAR_FRONT_RIGHT:
      case TYRE_POSITION.STORAGE_FRONT_RIGHT:
        return t("front_right").message || "Front Right";

      case TYRE_POSITION.CAR_REAR_LEFT:
      case TYRE_POSITION.STORAGE_REAR_LEFT:
        return t("rear_left").message || "Rear Left";

      case TYRE_POSITION.CAR_REAR_RIGHT:
      case TYRE_POSITION.STORAGE_REAR_RIGHT:
        return t("rear_right").message || "Rear Right";
      default:
        break;
    }
  };

  renderTyre = item => {
    const { t } = this.props;

    return (
      <div className="tyres-container">
        <div className="content-row fix-printed-dimensions">
          <div className="content-title page-break-inside-avoid fix-printed-dimensions">
            <div className="fix-printed-dimensions">
              <h2>
                {TYRE_POSITION.IS_ON_CAR(item.tyre_position) && (t("on_car").message || "On car") + " "}
                {TYRE_POSITION.IS_IN_STORAGE(item.tyre_position) && (t("in_storage").message || "In storage") + " "}
                {this.getTitle(item.tyre_position)}
              </h2>
            </div>
            {ANSWER_STATUSES[item.status] && (
              <Button className={`item-status`} color={ANSWER_STATUSES[item.status].color || "#fff"}>
                {ANSWER_STATUSES[item.status].text}
              </Button>
            )}
          </div>
          {item.tyre?.description && <div className="content-text page-break-inside-avoid fix-printed-dimensions">{item.tyre.description}</div>}
          {item.mechanic_notes && <div className="content-text page-break-inside-avoid fix-printed-dimensions">{item.mechanic_notes.trim()}</div>}
          {item.tyre_replacements &&
            item.tyre_replacements.map((tr, tri) => (
              <div key={tr.id + "_" + tri} className="page-break-inside-avoid fix-printed-dimensions">
                <h4>
                  {t("replace_with").message || "Replace with"}: {tr.tyre?.description}
                </h4>
              </div>
            ))}
        </div>
      </div>
    );
  };

  render() {
    const { isPrintLoading, isLoadingChecks, isLoadingCarDetail, isLoadingCustomer, showCustomerInformation } = this.state;
    const { t, showPrintOverview, onToggle } = this.props;

    return (
      <Modal open={showPrintOverview} onClose={onToggle} closeOnDimmerClick={false} className="PinOverview-Modal">
        <Modal.Header>
          <Checkbox
            toggle
            label={t("show_customer_information").message || "Show Customer Information"}
            onChange={this.toggleShowCustomerInformation}
            checked={showCustomerInformation}
          />
          <div className="pin-overview-header">
            <Button
              onClick={this.handlePrint}
              disabled={isPrintLoading || isLoadingChecks || isLoadingCarDetail || isLoadingCustomer}
              loading={isPrintLoading || isLoadingChecks || isLoadingCarDetail || isLoadingCustomer}
              className="print-btn"
              color="green"
            >
              {t("print").message || "Print"}
            </Button>
            <Button onClick={onToggle} color="blue">
              {t("close").message || "Close"}
            </Button>
          </div>
        </Modal.Header>
        <Modal.Content scrolling id="pin-overview-content">
          {this.renderHeaderSection()}
          {this.renderPinSection()}
          {this.renderPinnedItemSection()}
          {this.renderCheckSection()}
          {this.renderImportantNotesSection()}
          {this.renderLogSection()}
        </Modal.Content>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  return { globalState: state.global, appointmentsState: state.appointments };
};

export default withTranslation()(connect(mapStateToProps)(PinOverview));
