import React from "react";
import { withTranslation } from "react-i18next";
import moment from "moment";

import "./CarNotes.scss";

const CarNotes = ({ car, t }) => {
  const { dms_notes } = car;

  return (
    <div className="CarDetailContainer">
      <h3>{t("dms_notes").message || "DMS Notes"}</h3>
      {!dms_notes?.length && <div className="NoDataAvailable">{t("no_data_available").message || "No data available"}</div>}
      {dms_notes?.length > 0 &&
        dms_notes.map(({ dms_car_note_id, created_on, note }) => (
          <div className="note-container" key={dms_car_note_id}>
            <div>{moment(created_on).format("DD-MM-YYYY HH:mm")}</div>
            <div>{note}</div>
          </div>
        ))}
    </div>
  );
};

export default withTranslation()(CarNotes);
