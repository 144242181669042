import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Grid, Transition, Modal, Button } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

import "./RDWDetail.css";
class RDWDetail extends Component {
  state = {
    isDetailVisible: false,
  };

  handleToggleRDW = () => {
    this.setState(prevState => ({ isDetailVisible: !prevState.isDetailVisible }));
  };

  renderDetail() {
    if (this.state.isDetailVisible) {
      const { detail } = this.props;

      return (
        <Transition visible={this.state.isDetailVisible} animation="fade up" duration={700}>
          <Modal
            closeOnEscape
            open={this.state.isDetailVisible}
            onClose={this.handleToggleRDW}
            closeOnDimmerClick={false}
            size="small"
            style={{ paddingTop: 0, margin: 0 }}
          >
            <Modal.Header>
              <div>
                RDW Info
                <Button floated="right" color="green" onClick={this.handleToggleRDW}>
                  {this.props.t("close").message || "Close"}
                </Button>
                <Button floated="right" color="green" onClick={this.props.getRDWDetails}>
                  <FontAwesomeIcon icon={faSync} size="sm" />
                </Button>
              </div>
            </Modal.Header>
            <Modal.Content className="-no-padding-top">
              <div className="RDWDetail">
                <Grid columns={2}>
                  <Grid.Row>
                    <Grid.Column className="-no-padding-left">
                      <strong>Merk</strong>
                      <span>{detail.merk}</span>
                    </Grid.Column>
                    <Grid.Column>
                      <strong>Maximumtetrekkenmassaongeremd</strong>
                      <span>{detail.maximum_massa_trekken_ongeremd}</span>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column className="-no-padding-left">
                      <strong>Handelsbenaming</strong>
                      <span>{detail.handelsbenaming}</span>
                    </Grid.Column>
                    <Grid.Column>
                      <strong>Toegestanemaximummassavoertuig</strong>
                      <span>{detail.toegestane_maximum_massa_voertuig}</span>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column className="-no-padding-left">
                      <strong>Aantalcilinders</strong>
                      <span>{detail.aantal_cilinders}</span>
                    </Grid.Column>
                    <Grid.Column>
                      <strong>Tweedekleur</strong>
                      <span>{detail.tweede_kleur}</span>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column className="-no-padding-left">
                      <strong>Aantalzitplaatsen</strong>
                      <span>{detail.aantal_zitplaatsen}</span>
                    </Grid.Column>
                    <Grid.Column>
                      <strong>Vermogen</strong>
                      <span>{detail.vermogen_massarijklaar}</span>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column className="-no-padding-left">
                      <strong>BPM</strong>
                      <span>{detail.bruto_bpm}</span>
                    </Grid.Column>
                    <Grid.Column>
                      <strong>Voertuigsoort</strong>
                      <span>{detail.voertuigsoort}</span>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column className="-no-padding-left">
                      <strong>Catalogusprijs</strong>
                      <span>{detail.catalogusprijs}</span>
                    </Grid.Column>
                    <Grid.Column>
                      <strong>WAMverzekerdgeregistreerd</strong>
                      <span>{detail.wam_verzekerd}</span>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column className="-no-padding-left">
                      <strong>Cilinderinhoud</strong>
                      <span>{detail.cilinderinhoud}</span>
                    </Grid.Column>
                    <Grid.Column>
                      <strong>Wachtopkeuren</strong>
                      <span>{detail.wacht_op_keuren}</span>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column className="-no-padding-left">
                      <strong>Eerstekleur</strong>
                      <span>{detail.eerste_kleur}</span>
                    </Grid.Column>
                    <Grid.Column>
                      <strong>Zuinigheidsclassificatie</strong>
                      <span>{detail.zuinigheidsclassificatie}</span>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column className="-no-padding-left">
                      <strong>Inrichting</strong>
                      <span>{detail.inrichting}</span>
                    </Grid.Column>
                    <Grid.Column>
                      <strong>VervaldatumAPK</strong>
                      <span className={moment().isAfter(moment(detail.vervaldatum_apk)) ? "-expired" : ""}>{this.displayDate(detail.vervaldatum_apk)}</span>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column className="-no-padding-left">
                      <strong>Massaleegvoertuig</strong>
                      <span>{detail.massa_ledig_voertuig}</span>
                    </Grid.Column>
                    <Grid.Column>
                      <strong>Datumaanvangtenaamstelling</strong>
                      <span>{this.displayDate(detail.datum_tenaamstelling)}</span>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column className="-no-padding-left">
                      <strong>Massarijklaar</strong>
                      <span>{detail.massa_rijklaar}</span>
                    </Grid.Column>
                    <Grid.Column>
                      <strong>DatumeersteafgifteNederland</strong>
                      <span>{this.displayDate(detail.datum_eerste_tenaamstelling_in_nederland)}</span>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column className="-no-padding-left">
                      <strong>Maximumtetrekkenmassageremd</strong>
                      <span>{detail.maximum_trekken_massa_geremd}</span>
                    </Grid.Column>
                    <Grid.Column>
                      <strong>Datumeerstetoelating</strong>
                      <span>{this.displayDate(detail.datum_eerste_toelating)}</span>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column className="-no-padding-left">
                      <strong>RDW eerste registratie NL</strong>
                      <span>{this.displayDate(detail.datum_eerste_tenaamstelling_in_nederland)}</span>
                    </Grid.Column>
                    {detail.vervaldatum_tachograaf && (
                      <Grid.Column>
                        <strong>Vervaldatum Tachograph</strong>
                        <span className={moment().isAfter(moment(detail.vervaldatum_tachograaf)) ? "-expired" : ""}>
                          {this.displayDate(detail.vervaldatum_tachograaf)}
                        </span>
                      </Grid.Column>
                    )}
                  </Grid.Row>
                </Grid>
              </div>
            </Modal.Content>
          </Modal>
        </Transition>
      );
    }
  }

  displayDate = date => {
    if (date && date.length === 8) return date.substring(6) + "-" + date.substring(4, 6) + "-" + date.substring(0, 4);

    return "";
  };

  render() {
    const { detail } = this.props;

    const expiredDate = (detail.vervaldatum_tachograaf && moment().isAfter(moment(detail.vervaldatum_tachograaf))) || moment().isAfter(moment(detail.vervaldatum_apk));

    return (
      <>
        <Button basic fluid size="large" className={`RDWDetail ${expiredDate ? "buttonExpiredDate" : ""}`} onClick={this.handleToggleRDW}>
          RDW
        </Button>
        {this.renderDetail()}
      </>
    );
  }
}

export default withTranslation()(RDWDetail);
