import React, { Component } from "react";
import { Form } from "semantic-ui-react";
import { withTranslation } from "react-i18next";

import { UserInput } from "../../../components";

class Spinner extends Component {
  constructor(props) {
    super(props);

    let label = "";
    let unit = "";
    let digits = "";
    let decimals = "";

    const { default_configuration } = this.props;

    if (default_configuration) {
      label = default_configuration.label || "";
      unit = default_configuration.unit || "";
      digits = default_configuration.digits || "";
      decimals = default_configuration.decimals || "";
    }

    this.state = {
      label,
      unit,
      digits,
      decimals,
    };
  }

  handleLabelInput = e => {
    this.setState({ label: e.target.value }, () => {
      this.props.handleQuestionElementUpdate(this.state);
    });
  };

  handleUnitInput = e => {
    this.setState({ unit: e.target.value }, () => {
      this.props.handleQuestionElementUpdate(this.state);
    });
  };

  handleDigitsInput = e => {
    this.setState({ digits: e.target.value }, () => {
      this.props.handleQuestionElementUpdate(this.state);
    });
  };

  handleDecimalsInput = e => {
    this.setState({ decimals: e.target.value }, () => {
      this.props.handleQuestionElementUpdate(this.state);
    });
  };

  render() {
    return (
      <div>
        <Form.Field>
          <UserInput label={this.props.t("label").message || "Label"} value={this.state.label} onChange={this.handleLabelInput} />
        </Form.Field>

        <Form.Field>
          <UserInput label={this.props.t("unit").message || "Unit"} value={this.state.unit} onChange={this.handleUnitInput} />
        </Form.Field>

        <Form.Field>
          <UserInput type="number" label={this.props.t("digits").message || "Digits"} value={this.state.digits} onChange={this.handleDigitsInput} />
        </Form.Field>

        <Form.Field>
          <UserInput type="number" label={this.props.t("decimals").message || "Decimals"} value={this.state.decimals} onChange={this.handleDecimalsInput} />
        </Form.Field>
      </div>
    );
  }
}

export default withTranslation()(Spinner);
