import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Grid, Icon } from "semantic-ui-react";

import { formatDateIfValid, removeGenerationParam } from "./../../util/common";
import CustomerModal from "./../Customers/CustomerModal";
import { CarInspectionDate } from "./../../components";

class CarDetailHeader extends Component {
  state = {
    customerID: null,
  };

  renderGeneralInfo = () => {
    const { reg_nr, make, model, engine_number } = this.props.car;
    const { t } = this.props;

    return (
      <span>
        <p>
          <span className="InfoType">{t("license_plate").message || "License plate"}</span>
          <span className="InfoValue">{reg_nr}</span>
        </p>
        <p>
          <span className="InfoType">{t("brand").message || "Brand"}</span>
          <span className="InfoValue">{make}</span>
        </p>
        <p>
          <span className="InfoType">{t("model").message || "Model"}</span>
          <span className="InfoValue">{model}</span>
        </p>
        <p>
          <span className="InfoType">{t("engine_nr").message || "Engine number"}</span>
          <span className="InfoValue">{engine_number}</span>
        </p>
      </span>
    );
  };

  renderDates = () => {
    const { selectedLocation, car, t } = this.props;
    const { apk_date_dms, hu_date_dms, vin_nr, dms_registration, car_lead_prediction } = car;

    return (
      <span>
        {selectedLocation.apk_visible && apk_date_dms && (
          <p>
            <span className="InfoType">{t("apk_date").message || "APK Date"}</span>
            <span className="InfoValue">
              <CarInspectionDate date={apk_date_dms} />
            </span>
          </p>
        )}
        {selectedLocation.hu_visible && hu_date_dms && (
          <p>
            <span className="InfoType">{t("hu_date").message || "HU Date"}</span>
            <span className="InfoValue">
              <CarInspectionDate date={hu_date_dms} />
            </span>
          </p>
        )}

        {car_lead_prediction && (
          <p>
            <span className="InfoType">{t("predicted_date").message || "Predicted next maintenance"}</span>
            <span className="InfoValue">{formatDateIfValid(car_lead_prediction.predicted_date)}</span>
          </p>
        )}
        <p>
          <span className="InfoType">{t("vin_number").message || "Vin nr"}</span>
          <span className="InfoValue">{vin_nr}</span>
        </p>
        <p>
          <span className="InfoType">{t("dms_registration").message || "DMS Registration"}</span>
          <span className="InfoValue">{formatDateIfValid(dms_registration)}</span>
        </p>
      </span>
    );
  };

  renderWarranty = () => {
    const { warranty_date_start, warranty_date_end, warranty_extended, warranty_extended_road } = this.props.car;
    const { t } = this.props;

    return (
      <span>
        <p>
          <span className="InfoType">{t("warranty_date_start").message || "Warranty date start"}</span>
          <span className="InfoValue">{formatDateIfValid(warranty_date_start)}</span>
        </p>
        <p>
          <span className="InfoType">{t("warranty_date_end").message || "Warranty date end"}</span>
          <span className="InfoValue">{formatDateIfValid(warranty_date_end)}</span>
        </p>
        <p>
          <span className="InfoType">{t("extended_warranties").message || "Extended warranties"}</span>
          <span className="InfoValue">{warranty_extended}</span>
        </p>
        <p>
          <span className="InfoType">{t("extended_road_warranties").message || "Extended road warranties"}</span>
          <span className="InfoValue">{warranty_extended_road}</span>
        </p>
      </span>
    );
  };

  getCustomerName = customer => {
    if (!customer) return null;

    let customerName = `${customer.title || ""} ${customer.initials || ""} ${customer.surname || ""}`.trim();

    if (!customerName && customer.company) return customer.company;

    return customerName;
  };

  renderCustomer = () => {
    const { driver, driver_id, contract, contract_id, owner, owner_id } = this.props.customer;
    const { t } = this.props;

    return (
      <span>
        <p>
          <span className="InfoType">{t("driver_name").message || "Driver"}</span>
          <span className="InfoValue">
            {driver_id && <Icon name="info circle" className="CustomerInfoTrigger" onClick={() => this.handleShowCustomerDetail(driver_id)} />}
            {this.getCustomerName(driver)}
          </span>
        </p>
        <p>
          <span className="InfoType">{t("contractor_name").message || "Contractor"}</span>
          <span className="InfoValue">
            {contract_id && <Icon name="info circle" className="CustomerInfoTrigger" onClick={() => this.handleShowCustomerDetail(contract_id)} />}
            {this.getCustomerName(contract)}
          </span>
        </p>
        <p>
          <span className="InfoType">{t("owner_name").message || "Owner"}</span>
          <span className="InfoValue">
            {owner_id && <Icon name="info circle" className="CustomerInfoTrigger" onClick={() => this.handleShowCustomerDetail(owner_id)} />}
            {this.getCustomerName(owner)}
          </span>
        </p>
      </span>
    );
  };

  handleShowCustomerDetail = customerID => {
    if (!customerID) {
      return;
    }

    this.setState({ customerID });
  };

  handleAppointmentClick = appointmentId => {
    window.open(`${document.location.origin}/#!/appointments/${appointmentId}`, "_blank");
  };

  handleCarClick = carId => {
    window.open(`${document.location.origin}/#!/appointments/car/${carId}`, "_blank");
  };

  renderCustomerModal = () => {
    const { customerID } = this.state;

    if (!customerID) return null;

    return <CustomerModal customerID={customerID} onClose={this.handleCloseCustomerModal} location={this.props.selectedLocation} />;
  };

  handleCloseCustomerModal = () => {
    this.setState({ customerID: null });
  };

  render() {
    const { car, customer, t } = this.props;

    return (
      <Grid>
        <div className="CarDetailContainer">
          <h3>{t("car_details").message || "Car Details"}</h3>
          <Grid>
            <Grid.Row className="CarDetailModal__Info">
              <Grid.Column width={3}>
                {car.profile_picture ? <img alt="Car profile" src={removeGenerationParam(car.profile_picture)} /> : <Icon name="car" />}
              </Grid.Column>
              <Grid.Column width={3}>{this.renderGeneralInfo()}</Grid.Column>
              <Grid.Column width={3}>{this.renderDates()}</Grid.Column>
              <Grid.Column width={3}>{this.renderWarranty()}</Grid.Column>
              {customer && <Grid.Column width={4}>{this.renderCustomer()}</Grid.Column>}
            </Grid.Row>
          </Grid>
        </div>
        {this.renderCustomerModal()}
      </Grid>
    );
  }
}

export default withTranslation()(CarDetailHeader);
