import React from "react";
import { withTranslation } from "react-i18next";
import { Modal, Button, Icon } from "semantic-ui-react";
import QRCode from "qrcode.react";

import "./KeyLockerMechanicQRCodeModal.scss";

const KeyLockerMechanicQRCodeModal = ({ mechanicKey, onClose, t }) => (
  <Modal className="KeyLockerMechanicQRCodeModal" closeOnDimmerClick={false} open={!!mechanicKey} size="mini">
    <Modal.Content>
      <QRCode value={mechanicKey} renderAs="svg" fgColor="#000000" />
    </Modal.Content>
    <Modal.Actions>
      <Button color="red" onClick={onClose}>
        <Icon name="close" />
        {t("close").message || "Close"}
      </Button>
    </Modal.Actions>
  </Modal>
);

export default withTranslation()(KeyLockerMechanicQRCodeModal);
