export const WEB_SOCKET_ACTION_TYPES = {
  SET_IS_ONLINE: "SET_IS_ONLINE",
  SET_LOCATION_KEY: "web_socket/SET_LOCATION_KEY",
  SET_APPOINTMENT_ID: "web_socket/SET_APPOINTMENT_ID",
};

export function setWebSocketLocationKey(location_key) {
  return (dispatch, getState) => {
    const { global: globalState, auth: authState } = getState();

    const location = globalState.dealers.find(d => d.id === authState.user?.dealer_id)?.locations.find(l => l.id === authState.user?.dealer_location_id);

    if (location && !location_key.includes(location.notifier_key)) location_key += "," + location.notifier_key;

    if (globalState.selectedLocation && !location_key.includes(globalState.selectedLocation.notifier_key))
      location_key += "," + globalState.selectedLocation.notifier_key;

    dispatch({ type: WEB_SOCKET_ACTION_TYPES.SET_LOCATION_KEY, location_key });
  };
}

export function setWebSocketAppointmentID(appointment_id) {
  return dispatch => dispatch({ type: WEB_SOCKET_ACTION_TYPES.SET_APPOINTMENT_ID, appointment_id });
}

export function setWebSocketIsOnline(status) {
  return dispatch => dispatch({ type: WEB_SOCKET_ACTION_TYPES.SET_IS_ONLINE, status });
}

const INITIAL_STATE = {
  connected: false,
  location_key: null,
  appointment_id: null,
};

const webSocketReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case WEB_SOCKET_ACTION_TYPES.SET_IS_ONLINE:
      return {
        ...state,
        actionType: action.type,
        connected: action.status,
      };

    case WEB_SOCKET_ACTION_TYPES.SET_LOCATION_KEY:
      return {
        ...state,
        actionType: action.type,
        location_key: action.location_key,
      };

    case WEB_SOCKET_ACTION_TYPES.SET_APPOINTMENT_ID:
      return {
        ...state,
        actionType: action.type,
        appointment_id: action.appointment_id,
      };

    case "persist/REHYDRATE":
      var incoming = action.payload;
      if (incoming && incoming.webSocket) {
        return {
          ...state,
          ...incoming.webSocket,
          actionType: state.actionType,
          connected: state.connected,
          location_key: state.location_key,
          appointment_id: state.appointment_id,
        };
      }

      return state;

    default:
      return state;
  }
};

export default webSocketReducer;
