// @flow
import React, { PureComponent } from "react";
import { withTranslation } from "react-i18next";
import { Button, Checkbox, Icon, Modal } from "semantic-ui-react";
import { saveAs } from "file-saver";
import moment from "moment";

import { Can } from "../../modules";

import "./FileDropDown.css";
import { getPreviewUrl } from "../../util/common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/pro-solid-svg-icons";

const KEY_CODES = {
  LEFT_ARROW: 37,
  RIGHT_ARROW: 39,
};

class FileDropDown extends PureComponent {
  state = {
    isListVisible: false,
    fileDraggedOver: false,
    previewModalAttachment: null,
    lastFilesOpened: [],
    filesSelected: [],
  };

  filePreviewContentRef = React.createRef();

  componentDidUpdate() {
    if (this.filePreviewContentRef.current) this.filePreviewContentRef.current.focus();
  }

  _handleDragOver = e => {
    e.preventDefault();
    if (this.props.disableUpload) return;
    this.setState({ fileDraggedOver: true });
  };

  _handleDragLeave = () => {
    this.setState({ fileDraggedOver: false });
  };

  _handleDropFiles = e => {
    e.preventDefault();

    const { multiple, onFileAdded, disableUpload } = this.props;

    if (disableUpload) return;

    if (multiple) {
      for (const file of e.dataTransfer.files) {
        onFileAdded(file);
      }
    } else onFileAdded(e.dataTransfer.files[0]);

    this.setState({ fileDraggedOver: false });
  };

  _showList = () => {
    if (this.props.disabled) return;

    this.setState({ isListVisible: true });
  };

  _hideList = () => {
    this.setState({ isListVisible: false, filesSelected: [] });
  };

  _handleAddFileClick = () => {
    const INPUT = document.getElementById("FileInput"); // since ref does not work
    if (!INPUT) return;
    INPUT.click();
  };

  _handleFileChange = e => {
    const { multiple, onFileAdded } = this.props;

    if (multiple) {
      for (const file of e.target.files) {
        onFileAdded(file);
      }
    } else onFileAdded(e.target.files[0]);
  };

  handleSelectFile = (e, data) => {
    const { filesSelected } = this.state;

    const newSelection = filesSelected.includes(data.value) ? filesSelected.filter(url => url !== data.value) : [...filesSelected, data.value];

    this.setState({ filesSelected: newSelection });
  };

  handleDownloadSelectedFiles = e => {
    const { filesSelected } = this.state;

    if (!filesSelected.length) return;

    filesSelected.forEach(url => {
      const file = this.props.files.find(f => f.url === url);

      if (file) saveAs(file.url, file.name);
    });
  };

  handleShowPreviewModal = previewModalAttachment => {
    this.setState({ previewModalAttachment, lastFilesOpened: [...this.state.lastFilesOpened, previewModalAttachment.url] });
  };

  handlePreviewUsingKeyboard = evt => {
    evt.stopPropagation();
    const keyCode = evt.keyCode;

    if (![KEY_CODES.LEFT_ARROW, KEY_CODES.RIGHT_ARROW].includes(keyCode)) return;

    const previewModalAttachmentIndex = this.props.files.findIndex(f => f.url === this.state.previewModalAttachment.url);

    if (keyCode === KEY_CODES.LEFT_ARROW && previewModalAttachmentIndex > 0) this.handleShowPreviewModal(this.props.files[previewModalAttachmentIndex - 1]);
    else if (keyCode === KEY_CODES.RIGHT_ARROW && previewModalAttachmentIndex < this.props.files.length - 1)
      this.handleShowPreviewModal(this.props.files[previewModalAttachmentIndex + 1]);
  };

  handleClosePreviewModal = () => {
    this.setState({ previewModalAttachment: null });
  };

  renderPreviewModal = () => {
    const { previewModalAttachment } = this.state;
    const { t } = this.props;

    const previewModalAttachmentIndex = this.props.files.findIndex(f => f.url === previewModalAttachment.url);

    const isFileImage =
      ["image/png", "image/jpeg", "image/jpg", "jpg", "jpeg", "png"].includes(previewModalAttachment.type) || /\.(jpg|png)(\?.*)?$/.test(previewModalAttachment.name);

    const shouldRenderIFrame = [
      "text/html",
      "text/css",
      "application/javascript",
      "text/plain",
      "application/xml",
      "application/pdf",
      "video/mp4",
      "video/webm",
      "video/ogg",
      "audio/mpeg",
      "audio/wav",
      "audio/ogg",
      "application/rss+xml",
      "text/csv",
      "pdf",
    ].includes(previewModalAttachment.type);

    return (
      <Modal open={!!previewModalAttachment} className="FilePreviewModal" centered tabIndex="0" onKeyDown={this.handlePreviewUsingKeyboard} closeOnDimmerClick={false}>
        {this.props.files.length > 1 && (
          <>
            {previewModalAttachmentIndex > 0 && (
              <FontAwesomeIcon
                icon={faChevronLeft}
                size={18}
                className="left-arrow"
                onClick={() => this.handleShowPreviewModal(this.props.files[previewModalAttachmentIndex - 1])}
              />
            )}

            {previewModalAttachmentIndex < this.props.files.length - 1 && (
              <FontAwesomeIcon
                icon={faChevronRight}
                size={18}
                className="right-arrow"
                onClick={() => this.handleShowPreviewModal(this.props.files[previewModalAttachmentIndex + 1])}
              />
            )}
          </>
        )}

        <Modal.Header>
          <div className="FilePreviewHeader">
            <div>
              <div className="FilePreviewTitle">{this.props.t("file_preview").message || "File preview"}</div>
              <div className="FilePreviewName">{previewModalAttachment.name}</div>
            </div>

            <div>
              <Button color="teal" onClick={() => saveAs(previewModalAttachment.url, previewModalAttachment.name)}>
                {t("download").message || "Download"}
              </Button>

              <Button color="green" onClick={this.handleClosePreviewModal}>
                {t("close").message || "Close"}
              </Button>
            </div>
          </div>
        </Modal.Header>

        <Modal.Content>
          <div className="FilePreviewContent" tabIndex={0} ref={this.filePreviewContentRef}>
            {isFileImage ? (
              <img src={previewModalAttachment.url} alt="preview" />
            ) : shouldRenderIFrame ? (
              <iframe src={getPreviewUrl(previewModalAttachment.url)} title={previewModalAttachment.url} width="100%" height="100%" />
            ) : (
              <h2>{t("file_format_is_not_supported_for_preview").message || "File format is not supported for preview"}</h2>
            )}
          </div>
        </Modal.Content>
      </Modal>
    );
  };

  _renderList = r => {
    return (
      <section
        className={`List${r.position === "left" ? " PositionLeft" : ""}`}
        onDragLeave={this._handleDragLeave}
        onDragOver={this._handleDragOver}
        onDrop={this._handleDropFiles}
      >
        <span style={{ display: "inline-block", marginBottom: "10px" }}>{this.props.t("attachments").message || "Attachments"}: </span>
        {(!r.files || r.files.length === 0) && <p className="Empty">{r.noFilesText || "No files"}</p>}
        {r.files &&
          r.files.length > 0 &&
          r.files.map((f, k) => {
            if (f.failed)
              return (
                <div className="failed-attachment">
                  <span className="-text-ellipsis failed">{f.name}</span>
                  <Icon className="failed-icon" name="exclamation triangle" color="red" />
                </div>
              );

            const URL = typeof f === "string" ? f : f.url;
            const isFileImage = ["image/png", "image/jpeg", "image/jpg", "jpg", "jpeg", "png"].includes(f.type);
            const renderIcon = (
              <span>
                <Icon name={isFileImage ? "image outline" : "file outline"} />
              </span>
            );

            return (
              <div className="FileList" key={k}>
                <div className="FileTitle">
                  <Checkbox value={f.url} checked={this.state.filesSelected.includes(f.url)} onChange={this.handleSelectFile} />
                  <a
                    href={URL}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={e => {
                      e.preventDefault();

                      this.props.showPreview ? this.handleShowPreviewModal(f) : saveAs(URL, f.name);
                    }}
                  >
                    {r.showIcon && renderIcon}
                    <span className={`FileName -text-ellipsis ${this.state.lastFilesOpened.includes(URL) ? "-opened" : ""}`}>{f.name}</span>
                  </a>
                </div>

                {f.user_id && (
                  <span className="FileAuthor">
                    {this.props.t("uploaded_by").message || "Uploaded by"} {f.username}
                  </span>
                )}

                <span className="FileDate">{moment(f.created_on).format("DD-MM-YYYY HH:mm")}</span>
                <Can I="delete-attachment" the={this.props.resource}>
                  <Icon
                    className="delete"
                    onClick={e => {
                      e.stopPropagation();
                      this.props.deleteFile(URL);
                    }}
                    name="trash alternate outline"
                    color="red"
                  />
                </Can>
              </div>
            );
          })}
        {!this.props.disableUpload && (
          <Can I="append-attachment" the={this.props.resource}>
            <button className={`Adder ${this.state.fileDraggedOver ? "dragOver" : ""}`} onClick={this._handleAddFileClick}>
              {r.addFileText || this.props.t("drag_here_or_browse").message || "Drag here or browse"}
            </button>
          </Can>
        )}

        {this.state.filesSelected.length > 0 && (
          <Button color="green" onClick={this.handleDownloadSelectedFiles} className="downloadButton">
            {this.props.t("download").message || "Download"}
          </Button>
        )}

        <button className="Closer" onClick={this._hideList}>
          <Icon name="close"></Icon>
        </button>
        <input id="FileInput" type="file" multiple={this.props.multiple} hidden disabled={this.props.disabled} onChange={this._handleFileChange} />
      </section>
    );
  };

  render() {
    const FILES = this.props.files;

    return (
      <div className="FileDropDown">
        <div className="Initiator" onClick={this._showList}>
          {this.props.trigger || "Files"}
          {this.props.showCount && FILES && FILES.length > 0 && <span>{FILES.length}</span>}
        </div>

        {this.state.isListVisible && this._renderList(this.props)}

        {this.state.previewModalAttachment && this.renderPreviewModal()}
      </div>
    );
  }
}

export default withTranslation()(FileDropDown);
