import React, { Component } from "react";
import { Grid, Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { formatDateIfValid } from "../../util/common";
import { APPOINTMENT_STATUSES } from "../Appointments/common";

class AppointmentHistory extends Component {
  renderAppointmentDetails = appointment => {
    const { t } = this.props;

    if (appointment.appointment_status_identifier === APPOINTMENT_STATUSES.CANCELED) {
      return <p className="CarAppointmentHistory__list__Item__appointment_canceled">{t("appointment_canceled").message || "This appointment was canceled."}</p>;
    }

    if (!appointment.checklists || appointment.checklists.length === 0) {
      return <p className="CarAppointmentHistory__List__Item__Checks-None">{t("no_checklists_appointment").message || "No checklists in the appointment"}</p>;
    }

    return (
      <section className="CarAppointmentHistory__List__Item__Checks">
        <Grid>
          <Grid.Row className="CarAppointmentHistory__List__Item__Checks__Header">
            <Grid.Column width={5}>{t("name").message || "Name"}</Grid.Column>
            <Grid.Column width={2} className="-text-center">
              {t("photo").message || "Photo"}
            </Grid.Column>
            <Grid.Column width={2} className="-text-center">
              {t("critical").message || "Critical"}
            </Grid.Column>
            <Grid.Column width={2} className="-text-center">
              {t("critical_approved").message || "Critical Approved"}
            </Grid.Column>
            <Grid.Column width={2} className="-text-center">
              {t("advisable").message || "Advisable"}
            </Grid.Column>
            <Grid.Column width={3} className="-text-right">
              {t("advisable_approved").message || "Advisable Approved"}
            </Grid.Column>
          </Grid.Row>
          {appointment.checklists.map((c, k) => (
            <Grid.Row key={k} className="CarAppointmentHistory__List__Item__Checks__Row">
              <Grid.Column width={5}>{c.name}</Grid.Column>
              <Grid.Column width={2} className="-text-center">
                {c.photo_count}
              </Grid.Column>
              <Grid.Column width={2} className="-text-center">
                {c.n_count}
              </Grid.Column>
              <Grid.Column width={2} className="-text-center">
                {c.n_count_sold}
              </Grid.Column>
              <Grid.Column width={2} className="-text-center">
                {c.a_count}
              </Grid.Column>
              <Grid.Column width={2} className="-text-right">
                {c.a_count_sold}
              </Grid.Column>
            </Grid.Row>
          ))}
        </Grid>
      </section>
    );
  };

  render() {
    const { appointmentHistory, isManufacturer, t } = this.props;

    return (
      <div className="CarDetailContainer">
        {!appointmentHistory?.length > 0 ? (
          <h3>{t("no_appointments_for_this_car").message || "No appointments for this car"}</h3>
        ) : (
          <div className="CarAppointmentHistory">
            <h3 className="CarAppointmentHistory__header">{t("appointments_history").message || "Appointment history"}</h3>
            {appointmentHistory.map((a, k) => (
              <article className="CarAppointmentHistory__List__Item" key={k}>
                <Grid className="CarAppointmentHistory__List__Item__Header" columns="equal">
                  <Grid.Column>
                    <span className="CarAppointmentHistory__Property">{`${t("Date").message || "Date"}:`}</span>
                    <span className="CarAppointmentHistory__Property-Value">{formatDateIfValid(a.date)}</span>
                  </Grid.Column>
                  <Grid.Column>
                    <span className="CarAppointmentHistory__Property">{`${t("location").message || "Location"}:`}</span>
                    <span className="CarAppointmentHistory__Property-Value">{a.location_name}</span>
                  </Grid.Column>
                  <Grid.Column>
                    <span className="CarAppointmentHistory__Property">{`${t("next_date").message || "Next date"}:`}</span>
                    <span className="CarAppointmentHistory__Property-Value">{formatDateIfValid(a.next_date)}</span>
                  </Grid.Column>
                  <Grid.Column>
                    <span className="CarAppointmentHistory__Property">{`${t("current_km").message || "Current km"}:`}</span>
                    <span className="CarAppointmentHistory__Property-Value">{a.current_km}</span>
                  </Grid.Column>
                  <Grid.Column>
                    <span className="CarAppointmentHistory__Property">{`${t("next_km").message || "Next km"}:`}</span>
                    <span className="CarAppointmentHistory__Property-Value">{a.next_km}</span>
                  </Grid.Column>
                  <Grid.Column>
                    <span className="CarAppointmentHistory__Property">{`${t("reg_nr").message || "Registration"}:`}</span>
                    <span className="CarAppointmentHistory__Property-Value">{a.reg_number}</span>
                  </Grid.Column>
                  <Grid.Column>
                    {!isManufacturer && (
                      <Link to={`/appointments/${a.id}`} target="_blank">
                        <span style={{ color: "#1CBE5D", marginRight: "5px" }}>{t("see_appointment").message || "See Appointment"}</span>
                        <Icon name="external" style={{ color: "#1CBE5D" }} />
                      </Link>
                    )}
                  </Grid.Column>
                </Grid>
                {this.renderAppointmentDetails(a)}
              </article>
            ))}
          </div>
        )}
      </div>
    );
  }
}
export default withTranslation()(AppointmentHistory);
