import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Icon, Popup } from "semantic-ui-react";
import Lightbox from "react-image-lightbox";
import ReactTable, { ReactTableDefaults } from "react-table";
import moment from "moment";

import { CustomersCard } from "../../../components";
import { removeGenerationParam } from "../../../util/common";
import { renderMedia } from "../../Appointments/common";

class CarLeadsTable extends Component {
  state = {
    photoIndex: 0,
    images: [],
    videos: [],
    isImageGalleryVisible: false,
  };

  renderMediaButton = d => {
    if (!d.images && !d.video) return null;

    return (
      <Popup
        basic
        on="click"
        hideOnScroll
        className="Snooze_media_popup"
        position="left center"
        wide="very"
        flowing
        style={{ zIndex: "999" }}
        onOpen={() => this.setState({ images: d.images, videos: d.video })}
        content={<div>{renderMedia(true, d, this.state.images, this.state.videos, this.handleMediaToggle, null, false)}</div>}
        trigger={
          <div className="ui teal label" style={{ width: "auto", cursor: "pointer" }}>
            <Icon name="images" style={{ margin: "auto" }} /> {(d.images ? d.images.length : 0) + (d.video ? d.video.length : 0)}
          </div>
        }
      />
    );
  };

  handleMediaToggle = () => {
    this.setState({ isImageGalleryVisible: true });
  };

  renderImageGallery = () => {
    const { photoIndex, images } = this.state;

    return (
      <Lightbox
        mainSrc={removeGenerationParam(images[photoIndex].url)}
        nextSrc={removeGenerationParam(images[(photoIndex + 1) % images.length].url)}
        prevSrc={removeGenerationParam(images[(photoIndex + images.length - 1) % images.length].url)}
        onCloseRequest={() => this.setState({ isImageGalleryVisible: false })}
        onMovePrevRequest={() =>
          this.setState({
            photoIndex: (photoIndex + images.length - 1) % images.length,
          })
        }
        onMoveNextRequest={() =>
          this.setState({
            photoIndex: (photoIndex + 1) % images.length,
          })
        }
      />
    );
  };

  render() {
    const { t, items, page, pages, nb_items, loading, onFetchData } = this.props;
    if (!items || items.length < 1) return <div className="Table__no-results">{t("no_car_leads_found").message || "No car leads found"}</div>;

    return (
      <div className="CarLeadsTableContainer">
        <ReactTable
          className="CarLeadsTable -floated-table"
          data={items}
          page={page - 1}
          pages={pages === null ? -1 : pages}
          showPagination={pages > 1}
          renderPageJump={({ onChange, value, onBlur, onKeyPress, inputType, pageJumpText }) => (
            <div className="-pageJump">
              <input
                aria-label={pageJumpText}
                type={inputType}
                onChange={evt => {
                  onChange(evt);

                  const newPage = evt.target.value - 0;
                  if (!Number.isNaN(newPage) && newPage > 0 && newPage <= pages) onFetchData(newPage);
                }}
                value={page}
                onBlur={onBlur}
                onKeyPress={onKeyPress}
              />
            </div>
          )}
          loading={loading}
          manual
          onFetchData={(state, instance) => onFetchData(state.page + 1)}
          showPageSizeOptions={false}
          sortable={false}
          resizable={false}
          minRows={0}
          loadingText=""
          nextText={t("next").message || "Next"}
          previousText={t("previous").message || "Previous"}
          pageText={t("page").message || "Page"}
          ofText={t("of").message || "of"}
          onPageChange={() => {
            try {
              document.querySelector(".App__module").scrollTo(0, 0);
            } catch (e) {
              document.querySelector(".App__module").scrollTop = 0; // IE Fix
            }
          }}
          column={{
            ...ReactTableDefaults.column,
            headerClassName: "ReactTable__column-header -text-ellipsis",
            className: "ReactTable__column",
          }}
          columns={[
            {
              Header: t("Appointment_date").message || "Appointment Date",
              id: "appointmentDate",
              accessor: d =>
                d.time_car_app && (moment.isMoment(d.time_car_app) || !d.time_car_app.startsWith("0001-01-01T00:00:00")) // might be time zoned
                  ? moment(d.time_car_app).format("DD-MM-YYYY")
                  : "",
              maxWidth: 135,
            },
            {
              id: "woNr",
              Header: (
                <>
                  {t("WO Nr").message || "WO"} <span className="total-appointments">{nb_items}</span>
                </>
              ),
              accessor: d =>
                d.wo_nr === 0 ? (
                  ""
                ) : (
                  <span className="-cursor-pointer" onClick={() => this.props.onAppointmentClick(d)}>
                    {<Icon name="info circle" />}
                    {d.wo_nr}
                  </span>
                ),
              maxWidth: 100,
            },

            {
              id: "regNo",
              Header: t("Reg Nr").message,
              accessor: d => (
                <span className="-cursor-pointer" onClick={() => this.props.onCarClick(d.car_id, d.dealer_location_id)}>
                  {<Icon name="info circle" />}
                  {d.reg_number}
                </span>
              ),
              maxWidth: 120,
            },

            { Header: t("current_km").message || "Current km", id: "current_km", accessor: d => d.current_km },

            { Header: t("next_km").message || "Next km", id: "next_km", accessor: d => d.next_km },

            { Header: t("next_date").message || "Next date", id: "next_date", accessor: d => d.next_date && moment(d.next_date).format("DD-MM-YYYY") },

            {
              Header: t("predicted_next_date").message || "Predicted Next Date",
              id: "predicted_next_date",
              accessor: d => moment(d.predicted_next_date).format("DD-MM-YYYY"),
            },

            {
              id: "media",
              Header: "",
              accessor: d => this.renderMediaButton(d.car),
              maxWidth: 49,
            },

            {
              id: "user",
              Header: "",
              accessor: d => <CustomersCard contractor={d.customer_contract} driver={d.customer_driver} owner={d.customer_owner} />,
              maxWidth: 42,
            },
          ]}
        />
      </div>
    );
  }
}

export default withTranslation()(CarLeadsTable);
