import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Button, Icon, Modal as SemanticModal } from "semantic-ui-react";

import AppointmentLists from "./AppointmentLists";

class DeskCheckInQuestionModal extends Component {
  render() {
    const {
      t,
      dms_price_enabled,
      onInterventionDelete,
      isDeletingIntervention,
      appointment,
      dashboardConfig,
      checks,
      onInterventionAnswerUpdate,
      onChecklistAnswerUpdate,
      onMediaClick,
      onShowAddInterventionClick,
      isEditable,
      isPriceEditable,
      currentUser,
      openCarDetail,
      selectedDeskComm,
      isDeskCommunicationVisible,
      location,
      isCarReadyOrQualityCheckDone,
      getDeskComTotalPrice,
      handleCloseDeskCheckinQuestionsModal,
      onChangeDeskCommunication,
      renderDeskCheckInModal,
    } = this.props;

    return (
      <SemanticModal open={true} size="fullscreen" dimmer="blurring" closeOnDimmerClick={false} closeOnEscape>
        <SemanticModal.Content scrolling>
          <div className="AppointmentDetail__content__inner">
            <AppointmentLists
              dms_price_enabled={dms_price_enabled}
              onInterventionDelete={onInterventionDelete}
              isDeletingIntervention={isDeletingIntervention}
              appointment={appointment}
              dashboardConfig={dashboardConfig}
              interventions={appointment.interventions}
              checks={checks}
              selectedCustomerComm={selectedDeskComm}
              onInterventionAnswerUpdate={onInterventionAnswerUpdate}
              onChecklistAnswerUpdate={onChecklistAnswerUpdate}
              onMediaClick={onMediaClick}
              onShowAddInterventionClick={onShowAddInterventionClick}
              isEditable={isEditable}
              isPriceEditable={isPriceEditable}
              currentUser={currentUser}
              openCarDetail={openCarDetail}
              isCustomerCommunicationVisible={isDeskCommunicationVisible}
              onChangeCustomerCommunication={onChangeDeskCommunication}
              location={location}
            />
            <div className="TotalItems">
              {isCarReadyOrQualityCheckDone ? (
                <h5>
                  {t("total_fixed_items").message || "Total amount of items fixed by mechanic"}: {getDeskComTotalPrice(true)} €
                </h5>
              ) : (
                <h5>
                  {t("total_selected_items").message || "Total amount of selected items"}: {getDeskComTotalPrice(false)} €
                </h5>
              )}
            </div>
          </div>
        </SemanticModal.Content>
        <SemanticModal.Actions>
          <Button color="red" onClick={handleCloseDeskCheckinQuestionsModal} floated="left">
            <Icon name="close" /> {t("close").message || "CLOSE"}
          </Button>

          <Button color="green" onClick={renderDeskCheckInModal}>
            <Icon name="checkmark" /> {t("send").message || "Send"}
          </Button>
        </SemanticModal.Actions>
      </SemanticModal>
    );
  }
}

export default withTranslation()(DeskCheckInQuestionModal);
