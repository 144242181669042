import React from "react";

const LoginPromo = ({ newsArticles, onReadMore }) => {
  const SHOULD_RENDER = !!(newsArticles && newsArticles.length > 0);
  return (
    <div className="Login__promo">
      <section className="Login__promo__news">
        {!SHOULD_RENDER && <h1>Supercharge your workshop</h1>}
        {SHOULD_RENDER && renderNewsArticles(newsArticles, onReadMore)}
      </section>
    </div>
  );
};

const renderNewsArticles = (articles, onReadMore) => {
  const MAIN_ARTICLE = articles[0];
  let articlesFeed = [];
  if (articles.length > 1) {
    for (let i = 1; i <= 3; i++) {
      if (articles[i]) {
        articlesFeed.push(articles[i]);
      }
    }
  }

  let isMainReadMoreVisible = MAIN_ARTICLE ? MAIN_ARTICLE.content.length > 300 : false;

  return (
    <span>
      <h1
        onClick={() => {
          onReadMore(MAIN_ARTICLE);
        }}
        className="-cursor-pointer-no-color"
      >
        {MAIN_ARTICLE.title}
      </h1>
      <div className="Login__promo__news__feed">
        <article className="MainArticle">
          {!isMainReadMoreVisible && (
            <div
              dangerouslySetInnerHTML={{
                __html: MAIN_ARTICLE.content,
              }}
            ></div>
          )}
          {isMainReadMoreVisible && (
            <div>
              <div
                style={{
                  marginBottom: "10px",
                }}
                dangerouslySetInnerHTML={{
                  __html: MAIN_ARTICLE.content.substr(0, 300) + "...",
                }}
              ></div>

              <div
                className="-read-more"
                onClick={() => {
                  onReadMore(MAIN_ARTICLE);
                }}
              >
                READ MORE
              </div>
            </div>
          )}
        </article>

        {articlesFeed && articlesFeed.length > 0 && (
          <div className="Login__promo__news__feed__older">
            {articlesFeed.map((a, k) => {
              let W = 100 / articlesFeed.length - 1;
              let width = W + "%";
              let isReadMoreVisible = a.content ? a.content.length > W * 3 : false;

              return (
                <article
                  key={k}
                  style={{
                    width,
                  }}
                >
                  <p
                    className="-cursor-pointer-no-color"
                    onClick={() => {
                      onReadMore(a);
                    }}
                  >
                    <strong>{a.title.length > W - 10 ? a.title.substring(0, W - 10) + "..." : a.title}</strong>
                  </p>
                  {!isReadMoreVisible && (
                    <section
                      className="-content"
                      dangerouslySetInnerHTML={{
                        __html: a.content,
                      }}
                    ></section>
                  )}

                  {isReadMoreVisible && (
                    <section
                      className="-content"
                      dangerouslySetInnerHTML={{
                        __html: a.content.substr(0, 80) + "...",
                      }}
                    ></section>
                  )}

                  {isReadMoreVisible && (
                    <div
                      className="-read-more"
                      onClick={() => {
                        onReadMore(a);
                      }}
                    >
                      READ MORE
                    </div>
                  )}
                </article>
              );
            })}
          </div>
        )}
      </div>
    </span>
  );
};

export default LoginPromo;
