import Service from "./service";

class PinOptionsLoader {
  constructor() {
    if (PinOptionsLoader._instance) {
      return PinOptionsLoader._instance;
    }

    PinOptionsLoader._instance = this;

    this.isLoading = false;
    this.options = null;
    this.promises = [];
  }

  getPinOptions() {
    return new Promise((resolve, reject) => {
      this.promises.push({ resolve, reject });
      this.load();
    });
  }

  load() {
    if (this.options) return this.resolvePromises();

    if (!this.isLoading) {
      this.isLoading = true;
      Service.getPinOptions()
        .then(result => {
          if (!result?.data?.data?.pin_options) throw new Error("invalid response when loading pin dropdowns options");

          let pinStatusOptions = [];
          let warrantyTypeOptions = [];
          let pinTypesOptions = [];
          const { pin_options } = result.data.data;
          if (Array.isArray(pin_options.pin_statuses)) pin_options.pin_statuses.forEach(i => (pinStatusOptions[i.id] = i.name));
          if (Array.isArray(pin_options.warranty_types)) pin_options.warranty_types.forEach(i => (warrantyTypeOptions[i.id] = i.name));
          if (Array.isArray(pin_options.pin_types)) pin_options.pin_types.forEach(i => (pinTypesOptions[i.id] = i.name));

          this.options = { pinStatusOptions, warrantyTypeOptions, pinTypesOptions };
          this.resolvePromises();
          this.isLoading = false;
        })
        .catch(err => {
          if (err?.response?.data?.errors?.length) this.rejectPromises(err.response.data.errors[0]);
          else this.rejectPromises(err.message);

          this.isLoading = false;
        });
    }
  }

  rejectPromises(err) {
    let promise;
    while ((promise = this.promises.shift()) !== undefined) {
      promise.reject(err);
    }
  }

  resolvePromises() {
    let promise;
    while ((promise = this.promises.shift()) !== undefined) {
      promise.resolve(this.options);
    }
  }
}

const PinOptions = new PinOptionsLoader();

export default PinOptions;
