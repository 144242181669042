import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Dropdown } from "semantic-ui-react";

import { EnforceUserLocations } from "../../modules/Users/roles";
import { isAdmin } from "../../util/common";

class DealerLocationsDropdown extends React.Component {
  constructor(props) {
    super(props);

    let dealer_ids = [];
    let location_ids = props.globalState.selectedLocation?.id ? [props.globalState.selectedLocation.id] : [];

    if (location_ids[0] === "all") {
      location_ids = props.selectDealers ? [] : props.globalState.selectedDealer.locations.map(l => l.id);
      dealer_ids = props.selectDealers ? [props.globalState.selectedDealer.id] : [];
    }

    if (props.options) {
      location_ids = props.options.location_ids || [];
      dealer_ids = props.options.dealer_ids || [];
    }

    this.state = {
      groupedLocationsOptions: [],
      selectedDealersLocations: {
        dealer_ids,
        location_ids,
        isAllSelected: props.options?.isAllSelected || false,
      },
      searchQuery: "",
    };

    props.onChange(this.state.selectedDealersLocations);
  }

  canSelectAll = () => this.props.selectAll && isAdmin(this.props.authState.user.role_id);

  prepareGroupedLocationOptions = () => {
    const { t } = this.props;
    const dealers = this.getUserDealersLocations();
    const groupedLocationsOptions = [];

    if (this.canSelectAll()) groupedLocationsOptions.push({ key: "all", icon: "home", text: t("all_dealers").message || "All dealers", value: "all" });

    dealers
      .sort((a, b) => a.name.localeCompare(b.name))
      .forEach(dealer => {
        groupedLocationsOptions.push({
          key: `dealer-menu-${dealer.id}`,
          dealer: dealer,
          disabled: true,
          children: (
            <Dropdown.Menu scrolling>
              <Dropdown.Header content={dealer.name} />
              <Dropdown.Divider />
            </Dropdown.Menu>
          ),
        });

        if (this.props.selectDealers)
          groupedLocationsOptions.push({
            key: `dealer-${dealer.id}`,
            dealer: dealer,
            icon: "home",
            text: t("all_locations").message || "All locations",
            value: `dealer-${dealer.id}`,
          });

        dealer.locations
          .sort((a, b) => a.name.localeCompare(b.name))
          .forEach(location => {
            groupedLocationsOptions.push({ key: location.id, dealer: dealer, icon: "marker", text: location.name, value: location.id });
          });
      });

    this.setState({ groupedLocationsOptions });
  };

  componentDidMount() {
    this.prepareGroupedLocationOptions();
  }

  renderLabel = option => {
    let content = option.text;

    if (typeof option.value === "string" && option.value.startsWith("dealer-")) content = option.dealer.name;
    else if (option.value !== "all") content = option.dealer?.name.substr(0, 3) + " - " + option.text;

    return {
      content,
      icon: option.icon,
    };
  };

  componentDidUpdate(prevProps) {
    const { shouldReset, options, globalState, onChange } = this.props;

    if (globalState.dealers !== prevProps.globalState.dealers) this.prepareGroupedLocationOptions();

    if (globalState.selectedLocation.id !== prevProps.globalState.selectedLocation.id && globalState.selectedLocation.active) {
      this.setState(
        {
          selectedDealersLocations: {
            dealer_ids: [],
            location_ids: globalState.selectedLocation?.id ? [globalState.selectedLocation.id] : [],
            isAllSelected: false,
          },
        },
        () => {
          onChange(this.state.selectedDealersLocations);
        }
      );
    }

    let location_ids = globalState.selectedLocation?.id ? [globalState.selectedLocation.id] : [];
    if (options) location_ids = options.location_ids || [];

    if (shouldReset && prevProps.shouldReset !== shouldReset) {
      this.setState(
        {
          selectedDealersLocations: {
            location_ids,
            dealer_ids: options?.dealer_ids || [],
            isAllSelected: options?.isAllSelected || false,
          },
        },
        () => onChange(this.state.selectedDealersLocations)
      );
    }
  }

  getUserDealersLocations = () => {
    const allDealers = [];
    const {
      authState: { user },
      globalState: { dealers },
    } = this.props;

    if (isAdmin(user.role_id)) {
      dealers.forEach(dealer => {
        if (Array.isArray(dealer.locations)) {
          const { locations } = EnforceUserLocations(user, dealer.locations);
          if (locations.length) {
            dealer.locations = locations;
            allDealers.push(dealer);
          }
        }
      });

      return allDealers;
    }

    const userDealers = [{ id: user.dealer_id }];
    if (Array.isArray(user.dealers)) userDealers.push(...user.dealers);

    dealers.forEach(dealer => {
      if (userDealers.some(d => d.id === dealer.id) && Array.isArray(dealer.locations)) {
        const { locations } = EnforceUserLocations(user, dealer.locations);
        if (locations.length) {
          dealer.locations = locations;
          allDealers.push(dealer);
        }
      }
    });

    return allDealers;
  };

  handleChange = (e, data) => {
    let dealer_ids = [];
    let location_ids = [];
    const isAllSelected = data.value.includes("all") && !this.state.selectedDealersLocations.isAllSelected;
    const { onChange } = this.props;

    if (!isAllSelected) {
      data.value.forEach(element => {
        if (element === "all") return;

        if (typeof element === "string" && element.startsWith("dealer-")) {
          const dealer_id = Number(element.substr(7));
          dealer_ids.push(dealer_id);

          const option = data.options.find(o => o.dealer?.id === dealer_id);
          if (option?.dealer.locations?.length) location_ids = location_ids.filter(lid => !option.dealer.locations.some(l => l.id === lid));
        } else {
          location_ids.push(element);

          const option = data.options.find(o => o.dealer?.locations?.length && o.dealer.locations.some(l => l.id === element));
          if (option?.dealer) dealer_ids = dealer_ids.filter(d => d !== option.dealer.id);
        }
      });
    }

    this.setState({ selectedDealersLocations: { isAllSelected, dealer_ids, location_ids } }, () => {
      onChange(this.state.selectedDealersLocations);
    });
  };

  onSearchChange = e => {
    this.setState({ searchQuery: e.target.value });
  };

  searchDealersAndLocations = () => {
    const { searchQuery, groupedLocationsOptions } = this.state;

    const regEx = new RegExp(searchQuery, "gi");
    let results = [];

    for (let i = 0; i < groupedLocationsOptions.length; ) {
      if (!groupedLocationsOptions[i].children && groupedLocationsOptions[i].key !== "all") break;

      if (regEx.test(groupedLocationsOptions[i].dealer?.name)) {
        results.push(groupedLocationsOptions[i]);
        ++i;

        while (i < groupedLocationsOptions.length && !groupedLocationsOptions[i].children) {
          results.push(groupedLocationsOptions[i]);

          ++i;
        }
      } else {
        const tmp = [groupedLocationsOptions[i]];
        ++i;

        while (i < groupedLocationsOptions.length && !groupedLocationsOptions[i].children) {
          if (regEx.test(groupedLocationsOptions[i].text)) {
            tmp.push(groupedLocationsOptions[i]);
          }

          ++i;
        }

        if (tmp.length > 1) results = results.concat(...tmp);
      }
    }

    return results;
  };

  render() {
    const { t, selectDealers } = this.props;
    const { selectedDealersLocations, groupedLocationsOptions, searchQuery } = this.state;

    return (
      <Dropdown
        selectOnBlur={false}
        placeholder={selectDealers ? t("dealers_locations").message || "Dealers/Locations" : t("locations").message || "Locations"}
        value={
          selectedDealersLocations.isAllSelected ? ["all"] : [...selectedDealersLocations.location_ids, ...selectedDealersLocations.dealer_ids.map(d => "dealer-" + d)]
        }
        multiple
        onChange={this.handleChange}
        selection
        searchQuery={searchQuery}
        onSearchChange={this.onSearchChange}
        search={this.searchDealersAndLocations}
        fluid
        options={groupedLocationsOptions}
        renderLabel={this.renderLabel}
      />
    );
  }
}

const mapStateToProps = state => {
  return { globalState: state.global, authState: state.auth };
};

export default connect(mapStateToProps)(withTranslation()(DealerLocationsDropdown));
