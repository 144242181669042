import React from "react";
import ReactTable, { ReactTableDefaults } from "react-table";
import { Button, Icon } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import Can from "../Can";
import { ROLES } from "../Users/roles";

const CustomersTable = props => {
  let { customers, t, user } = props;
  return (
    <div className="CustomersTable">
      <ReactTable
        className="ReactTable -floated-table -contained-large"
        data={customers}
        showPagination={false}
        showPageSizeOptions={false}
        sortable={false}
        resizable={false}
        defaultPageSize={customers.length}
        pageSize={customers.length}
        noDataText="No customers"
        getTdProps={(state, rowInfo, column, instance) => {
          return {
            onClick: e => {
              props.onRowClick(column.id, rowInfo);
            },
          };
        }}
        column={{
          ...ReactTableDefaults.column,
          headerClassName: "ReactTable__column-header",
          className: "ReactTable__column",
        }}
        columns={[
          {
            Header: t("dms_nr").message || "DMS#",
            accessor: "dms_nr",
            maxWidth: 150,
          },
          {
            Header: t("crm_nr").message || "CRM#",
            accessor: "customer_crm_nr",
            maxWidth: 150,
          },
          {
            Header: t("name").message || "Name",
            id: "fullName",
            accessor: d => <span>{`${d.title} ${d.firstname} ${d.surname} `}</span>,
            maxWidth: 200,
          },
          {
            Header: t("company_name").message || "Company name",
            id: "companyName",
            accessor: d => (
              <>
                {d.is_lease_company && (
                  <span className="lease-company-tag">
                    <Icon name="building" />
                  </span>
                )}
                <span>{d.company}</span>
              </>
            ),
          },
          {
            Header: t("street").message || "Street",
            accessor: "street",
            maxWidth: 250,
          },
          {
            Header: t("postal_code").message || "Postal code",
            accessor: "postcode",
            maxWidth: 100,
          },
          {
            Header: t("location").message || "Location",
            accessor: "place",
            maxWidth: 250,
          },
          {
            Header: t("email").message || "Email",
            accessor: "email_private",
            maxWidth: 250,
          },
          {
            Header: t("phone").message || "Mobile",
            accessor: "tel_mobile_private",
            maxWidth: 150,
          },

          {
            id: "action",
            Header: "",
            maxWidth: 80,
            accessor: d =>
              user?.role_id === ROLES.SUPER_ADMIN ? (
                <Can I="delete" the="customers">
                  <div className="-pull-right">
                    <Icon
                      name="trash alternate outline"
                      size="large"
                      color="red"
                      onClick={e => {
                        e.stopPropagation();
                        props.deleteCustomer(d.id);
                      }}
                    />
                  </div>
                </Can>
              ) : null,
          },
        ]}
      />

      <Button
        color="green"
        disabled={props.loadingCustomers}
        loading={props.loadingCustomers}
        style={{
          borderRadius: "3px",
          position: "absolute",
          left: "45%",
          width: "10%",
          bottom: "10px",
        }}
        onClick={props.onGetMore}
      >
        {props.t("load_more").message || "Load more"}
      </Button>
    </div>
  );
};

export default withTranslation()(CustomersTable);
