import { connect } from "react-redux";

import Checklists from "./Checklists";
import { setAlert } from "../App/store";

const mapStateToProps = state => {
  return { authState: state.auth, globalState: state.global };
};

const mapDispatchToProps = dispatch => {
  return { setAlert: alertOptions => dispatch(setAlert(alertOptions)) };
};

export default connect(mapStateToProps, mapDispatchToProps)(Checklists);
