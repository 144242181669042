import React, { Component } from "react";
import ReactTable, { ReactTableDefaults } from "react-table";
import moment from "moment";

class StackedBarChart extends Component {
  render() {
    const { data, t } = this.props;

    const columns = [];
    for (const column in data[0])
      columns.push({
        Header: <strong>{t(column).message || column}</strong>,
        accessor: column,
        Cell: cell => (column.includes("date") ? moment(cell.value).format("DD-MM-YYYY") : t(cell.value).message || cell.value),
      });

    return (
      <ReactTable
        className="ReactTable -floated-table no-overflow all-data-table -initial-margin"
        data={data}
        showPagination={false}
        showPageSizeOptions={false}
        sortable={false}
        style={{ maxHeight: "600px" }}
        resizable={false}
        defaultPageSize={data.length || 0}
        pageSize={data.length || 0}
        noDataText={
          <div className="Table__no-results">
            <p>{t("no_data").message || "No data"}</p>
          </div>
        }
        column={{
          ...ReactTableDefaults.column,
          headerClassName: "ReactTable__column-header dealer-table-header",
          className: "ReactTable__column",
        }}
        columns={columns}
      />
    );
  }
}

export default StackedBarChart;
