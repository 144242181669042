import React, { Component } from "react";
import ReactTable, { ReactTableDefaults } from "react-table";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Icon, Grid } from "semantic-ui-react";

import { SubHeader } from "./../../components";
import { setAlert } from "../App/store";
import TemplateDetail from "./TemplateDetail";
import Service from "./service";
import "./Templates.css";

class Templates extends Component {
  state = {
    templates: [],
    selectedTemplate: null,
    isLoadingTemplates: false,
    isAssigningDealer: false,
  };

  componentDidMount() {
    this.getTemplates();
  }

  getTemplates = () => {
    if (this.state.isLoadingTemplates) return;

    this.setState({ isLoadingTemplates: true }, () => {
      Service.getTemplates()
        .then(result => {
          let templates = [];
          if (result && result.data && result.data.data && Array.isArray(result.data.data)) templates = result.data.data;

          this.setState({ templates, isLoadingTemplates: false });
        })
        .catch(error => {
          console.error("Error getting templates.", error);
          this.props.setAlert({ type: "error", title: error.message });
        });
    });
  };

  handleShowTemplateDetail = template => {
    this.setState({ selectedTemplate: template });
  };

  handleHideTemplateDetail = () => {
    this.setState({ selectedTemplate: null });
  };

  handleAssignDealer = (template_id, dealer_id) => {
    this.setState({ isAssigningDealer: true }, () => {
      Service.assignTemplate({ id: template_id, dealer_id: dealer_id ? dealer_id : null })
        .then(() => {
          let { templates } = this.state;
          let template = templates.find(t => t.id === template_id);
          if (template) template.dealer_id = dealer_id;

          this.setState({ templates, isAssigningDealer: false, selectedTemplate: null });
        })
        .catch(error => {
          console.error("Error on assigning template dealer.", error);
          this.setState({ isAssigningDealer: false });
          this.props.setAlert({ type: "error", title: error.message });
        });
    });
  };

  renderTable = () => {
    const { isLoadingTemplates, templates } = this.state;
    const { t } = this.props;

    if (isLoadingTemplates) {
      return (
        <div className="Table__loading Loader-Placeholder">
          <div className="bounce1"></div>
          <div className="bounce2"></div>
          <div className="bounce3"></div>
          <section>{t("loading_templates").message || "Loading templates"}</section>
        </div>
      );
    } else {
      return (
        <div className="TemplatesTable">
          <ReactTable
            className="ReactTable -floated-table -contained-large"
            data={templates}
            showPagination={false}
            showPageSizeOptions={false}
            sortable={false}
            resizable={false}
            defaultPageSize={templates.length}
            pageSize={templates.length}
            noDataText={
              <div className="Table__no-results">
                <Icon disabled name="car" style={{ fontSize: "1.75em" }} />
                <p>{t("no_templates").message || "No templates"}</p>
              </div>
            }
            getTdProps={(state, rowInfo, column, instance) => {
              return {
                onClick: e => {
                  this.handleShowTemplateDetail(rowInfo.original);
                },
              };
            }}
            column={{
              ...ReactTableDefaults.column,
              headerClassName: "ReactTable__column-header",
              className: "ReactTable__column",
            }}
            columns={[
              {
                id: "name",
                Header: t("name").message || "Name",
                accessor: "name",
              },
              {
                id: "description",
                Header: t("description").message || "Description",
                accessor: d => {
                  return d.description.length > 50 ? d.description.substring(0, 50) + "..." : d.description;
                },
              },
            ]}
          />
        </div>
      );
    }
  };

  render() {
    const { selectedTemplate } = this.state;

    return (
      <div className="Templates page_inner_wrapper">
        <SubHeader>
          <Grid.Column>
            <h1>{this.props.t("report_templates").message || "Report Templates"}</h1>
          </Grid.Column>
        </SubHeader>

        {this.renderTable()}
        {selectedTemplate && (
          <TemplateDetail
            dealers={this.props.dealers}
            mode={this.state.detailViewMode}
            loading={this.state.isAssigningDealer}
            t={this.props.t}
            template={selectedTemplate}
            onHideDetail={this.handleHideTemplateDetail}
            onAssignDealer={this.handleAssignDealer}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    dealers: state.global.dealers,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setAlert: alertOptions => dispatch(setAlert(alertOptions)),
  };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Templates));
