export const MODE = {
  CREATE: 1,
  EDIT: 2,
};

export const FREQUENCY = {
  WEEK: 1,
  TWO_WEEKS: 2,
  EVERY_MONTH: 3,
  EVERY_DAY: 4,
};

export const QUERY_RANGE = {
  ONE_DAY: 1,
  ONE_WEEK: 2,
  TWO_WEEKS: 3,
  ONE_MONTH: 4,
  TWO_MONTHS: 5,
  THREE_MONTHS: 6,
};

export const STATUS = {
  PENDING: 1,
  SUCCESS: 2,
  FAILED: 3,
};
