import axios from "axios";

import ENV from "../../util/env-config";
import { getSessionCookie } from "../../util/common";

class Service {
  static requestConfig() {
    return {
      headers: {
        Authorization: `Bearer ${getSessionCookie()}`,
        Pragma: "no-cache",
      },
    };
  }

  static getBrands() {
    return axios.get(ENV.apiBaseB + "/brands/list", this.requestConfig());
  }

  static viewService(requestData) {
    return axios.post(ENV.appointmentApiBase + "/check_in/service/view", requestData, this.requestConfig());
  }

  static listDealerServices(requestData) {
    return axios.post(ENV.appointmentApiBase + "/check_in/service/list", requestData, this.requestConfig());
  }

  static listLocationServices(requestData) {
    return axios.post(ENV.appointmentApiBase + "/check_in/service/location_list", requestData, this.requestConfig());
  }

  static createService(requestData) {
    return axios.post(ENV.appointmentApiBase + "/check_in/service/create", requestData, this.requestConfig());
  }

  static updateService(requestData) {
    return axios.post(ENV.appointmentApiBase + "/check_in/service/update", requestData, this.requestConfig());
  }

  static deleteService(requestData) {
    return axios.post(ENV.appointmentApiBase + "/check_in/service/delete", requestData, this.requestConfig());
  }

  static locationEnable(requestData) {
    return axios.post(ENV.appointmentApiBase + "/check_in/service/enable", requestData, this.requestConfig());
  }

  static locationDisable(requestData) {
    return axios.post(ENV.appointmentApiBase + "/check_in/service/disable", requestData, this.requestConfig());
  }

  static getTags() {
    return axios.get(ENV.systemBase + "/tags/list", this.requestConfig());
  }

  static updateNotificationEmail(requestData) {
    return axios.post(ENV.appointmentApiBase + "/check_in/service/update_notification_email", requestData, this.requestConfig());
  }
}

export default Service;
