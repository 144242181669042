import React from "react";
import { Form, TextArea } from "semantic-ui-react";

import { UserInput } from "../../../components";

const QuestionElementTextarea = ({ id, configuration, configurationChanged }) => {
  return (
    <div>
      <Form.Field>
        <UserInput key={"label" + id} name="label" onChange={configurationChanged} defaultValue={configuration.label || ""} placeholder="Label" />
      </Form.Field>
      <TextArea
        key={"value" + id}
        name="default_value"
        onChange={configurationChanged}
        defaultValue={configuration.default_value || ""}
        rows={3}
        placeholder="Default value"
      ></TextArea>
    </div>
  );
};

export default QuestionElementTextarea;
