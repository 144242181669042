import Service from "./service";

class TyreBrandLoader {
  constructor() {
    if (TyreBrandLoader._instance) {
      return TyreBrandLoader._instance;
    }

    TyreBrandLoader._instance = this;

    this.isLoading = false;
    this.options = null;
    this.promise = null;
  }

  getDropdownOptions() {
    return new Promise((resolve, reject) => {
      this.promise = { resolve, reject };
      this.load();
    });
  }

  load() {
    if (this.options) return this.resolvePromises();

    if (!this.isLoading) {
      this.isLoading = true;
      Service.getTiresBrands()
        .then(result => {
          if (!result?.data?.data) throw new Error("invalid response when loading brands dropdown options");

          this.options = result.data.data.brands;
          this.resolvePromises();
          this.isLoading = false;
        })
        .catch(err => {
          if (err?.response?.data?.errors?.length) this.rejectPromises(err.response.data.errors[0]);
          else this.rejectPromises(err.message);

          this.isLoading = false;
        });
    }
  }

  rejectPromises(err) {
    this.promise.reject(err);
  }

  resolvePromises() {
    this.promise.resolve(this.options);
  }
}

const Brands = new TyreBrandLoader();

export default Brands;
