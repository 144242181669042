import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Form, Grid, Button } from "semantic-ui-react";
import _ from "lodash";

import { SubHeader, ColorPicker, UserInput } from "./../../components";
import { getDefaultStatusLabel } from "../../util/common";
import { setAlert } from "../App/store";

import Service from "./service";

class StatusListGlobal extends Component {
  state = {
    statuses: [],
    isLoadingStatuses: false,
    initialStatuses: [],
    colors: [],
  };

  componentDidMount() {
    this.getStatuses();
  }

  getStatuses = () => {
    if (this.state.isLoadingStatuses) return;

    this.setState({ isLoadingStatuses: true }, () => {
      Service.getStatuses()
        .then(result => {
          if (result?.data?.data) {
            this.setState({
              statuses: result.data.data.statuses.sort((a, b) => a.order - b.order),
              colors: result.data.data.statuses.map(s => s.color.toLowerCase()),
              isLoadingStatuses: false,
            });
          }
        })
        .catch(error => {
          console.error("Error getting statuses.", error);
          this.setState({ statuses: [], isLoadingStatuses: false });
          this.props.setAlert({
            type: "error",
            title: error.message,
          });
        });
    });
  };

  handleInputChange = (e, id) => {
    const { statuses } = this.state;
    const i = statuses.findIndex(x => x.id === id);
    if (i !== -1) {
      statuses[i].name = e.target.value;
      this.setState({ statuses });
    }
  };

  handleColorChange = (color, id) => {
    const { statuses } = this.state;
    const i = statuses.findIndex(x => x.id === id);
    if (i !== -1) {
      statuses[i].color = color;
      this.setState({ statuses });
    }
  };

  handleSave = () => {
    this.setState({ isLoadingStatuses: true }, () => {
      Service.saveStatus({ statuses: this.state.statuses })
        .then(result => {
          this.setState({ isLoadingStatuses: false });
        })
        .catch(err => {
          console.error(err);
          this.setState({ isLoadingStatuses: false });
          this.props.setAlert({
            type: "error",
            title: err.message,
          });
        });
    });
  };

  renderStatuses = statuses => {
    return statuses.map((i, k) => {
      return (
        <Form.Field key={k}>
          <label>{getDefaultStatusLabel(i.identifier)}</label>
          <Form.Group>
            <Form.Field>
              <ColorPicker className="-margin-top-5" color={i.color} extraColors={this.state.colors} onChange={color => this.handleColorChange(color, i.id)} />
            </Form.Field>

            <Form.Field width={16}>
              <UserInput type="text" placeholder="Status name" value={i.name} onChange={e => this.handleInputChange(e, i.id)} />
            </Form.Field>
          </Form.Group>
        </Form.Field>
      );
    });
  };

  render() {
    const { statuses, isLoadingStatuses } = this.state;
    return (
      <div className="page_inner_wrapper">
        <SubHeader>
          <Grid.Column>
            <h1>{this.props.t("status_list").message || "Status List"}</h1>
          </Grid.Column>
        </SubHeader>
        <div style={{ marginTop: "20px" }}>
          <Grid className="-contained-large" style={{ background: "#fff" }}>
            <Grid.Column width={16}>
              <Form>
                {this.renderStatuses(_.clone(statuses))}
                <Form.Group>
                  <Form.Field width={16}>
                    <Button floated="right" color="green" disabled={isLoadingStatuses} loading={isLoadingStatuses} onClick={this.handleSave}>
                      Save
                    </Button>
                  </Form.Field>
                </Form.Group>
              </Form>
            </Grid.Column>
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    setAlert: alertOptions => dispatch(setAlert(alertOptions)),
  };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(StatusListGlobal));
