import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { Button } from "semantic-ui-react";

import { logout } from "../Auth/store";
import { acceptPrivacyAgreement, GLOBAL_ACTION_TYPES } from "../App/store";
import Can from "../Can";
import "./ContractAgreement.css";

class ContractAgreement extends Component {
  componentWillReceiveProps(nextProps) {
    if (
      this.props.globalState.actionType !== nextProps.globalState.actionType &&
      nextProps.globalState.actionType === GLOBAL_ACTION_TYPES.ACCEPT_PRIVACY_AGREEMENT_SUCCESS
    ) {
      window.location.reload();
    }
  }

  handleNotAgreed = () => {
    this.props.logout();
  };

  handleAgreed = () => {
    this.props.acceptPrivacyAgreement(this.props.authState.user.dealer_id);
  };

  render() {
    let t = this.props.t;

    return (
      <div className="ContractAgreement">
        <div className="ContractAgreement__Intro">
          <div className="ContractAgreement__Intro__Content">{t("accept_before_continuing").message || "Please accept the agreement before continuing to Claire"}</div>
        </div>

        <div className="ContractAgreement__Content">
          <h1>
            {t("claire_agreement").message || "Claire Automotive Support Agreement"}

            <span className="UserInfo">{`${this.props.authState.user.first_name} ${this.props.authState.user.last_name}`}</span>
          </h1>

          <div
            style={{
              height: "600px",
            }}
          >
            <embed src="https://storage.googleapis.com/claire-dpo-resources/GDPR-AVG-Claire.pdf" width="100%" height="100%" />
          </div>

          <div className="ContractAgreement__Actions">
            <Button onClick={this.handleNotAgreed} style={{ backgroundColor: "#27a2ea", color: "#fff" }}>
              {t("decline_and_logout").message || "DECLINE AND LOG OUT"}
            </Button>

            <Can I="accept_privacy" the="agreements">
              <Button
                onClick={this.handleAgreed}
                floated="right"
                style={{
                  backgroundColor: "green",
                  color: "#fff",
                }}
              >
                {t("accept_the_terms").message || "Accept the terms"}
              </Button>
            </Can>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { authState: state.auth, globalState: state.global };
};

const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(logout()),
    acceptPrivacyAgreement: dealer_id => dispatch(acceptPrivacyAgreement(dealer_id)),
  };
};

export default withRouter(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ContractAgreement)));
