import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Form, Checkbox, Header, Icon, Segment } from "semantic-ui-react";

class RdwConfiguration extends Component {
  handleCheckboxChange = (e, { name, checked }) => {
    let { location, onLocationChange } = this.props;

    onLocationChange({ ...location, [name]: checked });
  };

  render() {
    const { location, t } = this.props;

    return (
      <Segment>
        <Header as="h5">
          <Icon name="settings" />
          <Header.Content>{t("rdw_configuration").message || "RDW configuration"}</Header.Content>
        </Header>
        <Form>
          <Form.Field>
            <Checkbox
              checked={location.upgrade_car_with_rdw}
              name="upgrade_car_with_rdw"
              label={t("upgrade_car").message || "Upgrade car"}
              onChange={this.handleCheckboxChange}
            />
          </Form.Field>
        </Form>
      </Segment>
    );
  }
}

export default withTranslation()(RdwConfiguration);
