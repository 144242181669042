import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Button, Icon, Modal } from "semantic-ui-react";
import ReactTable, { ReactTableDefaults } from "react-table";

import { setAlert } from "../App/store";
import Service from "./service";

import "./AcsesDashboard.scss";
import moment from "moment";

class AcsesDashboard extends Component {
  state = {
    events: [],
    isLoading: false,
    responseContentModal: null,
  };

  componentDidMount() {
    this.loadEventHistory();
  }

  componentDidUpdate(prevProps) {
    if (this.props.globalState.selectedLocation.id !== prevProps.globalState.selectedLocation.id) this.loadEventHistory();
  }

  loadEventHistory = () => {
    this.setState({ isLoading: true }, async () => {
      try {
        const response = await Service.getEventHistory({ dealer_location_id: this.props.globalState.selectedLocation.id });
        const events = response?.data?.data?.history || [];

        if (events) {
          events.map(h => {
            try {
              h.sentContent = JSON.parse(h.sentContent);
            } catch (err) {
              console.error("Failed to parse");
              h.sentContent = null;
            }
            return h;
          });
        }

        this.setState({ events, isLoading: false });
      } catch (error) {
        const errorMsg =
          typeof error.response?.data === "string" ? error.response.data : error.response.data.errors ? error.response.data.errors[0] : "Failed to load dayplanner data";

        console.log("Error loading dayplanner data", error);
        this.props.setAlert(errorMsg, { type: "error" });
        this.setState({ isLoading: false });
      }
    });
  };

  handleShowModal = (e, responseContent) => {
    e.preventDefault();

    let responseContentModal = null;
    try {
      responseContentModal = JSON.parse(responseContent);
    } catch (err) {
      responseContentModal = responseContent;
    }

    this.setState({ responseContentModal });
  };

  handleCloseModal = () => {
    this.setState({ responseContentModal: null });
  };

  renderResponseContentModal = () => {
    const { responseContentModal } = this.state;
    const { t } = this.props;

    return (
      <Modal open={!!responseContentModal} onClose={this.handleCloseModal}>
        <Modal.Header>{t("response_content").message || "Response content"}</Modal.Header>
        <Modal.Content>{JSON.stringify(responseContentModal)}</Modal.Content>
        <Modal.Actions>
          <Button style={{ marginLeft: "auto" }} onClick={this.handleCloseModal}>
            {t("close").message || "Close"}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };

  renderEventTable = () => {
    const { isLoading, events } = this.state;
    const { t } = this.props;

    if (isLoading) {
      return (
        <div className="Table__loading Loader-Placeholder">
          <div className="bounce1"></div>
          <div className="bounce2"></div>
          <div className="bounce3"></div>
          <section>{t("loading_event_log").message || "Loading event log"}</section>
        </div>
      );
    }

    if (!events?.length) return <div className="Table__no-results">{t("no_event_logs_found").message || "No event logs found"}</div>;

    return (
      <div className="AcsesTable-container">
        <ReactTable
          className="ReactTable -floated-table -contained-large"
          data={events}
          showPagination={false}
          showPageSizeOptions={false}
          sortable={false}
          resizable={false}
          defaultPageSize={events.length}
          pageSize={events.length}
          noDataText={
            <div className="Table__no-results">
              <Icon disabled name="key" size="big" />
              <p>{t("no_event_history").message || "No event history"}</p>
            </div>
          }
          column={{
            ...ReactTableDefaults.column,
            headerClassName: "ReactTable__column-header",
            className: "ReactTable__column",
          }}
          columns={[
            {
              id: "locker_id",
              Header: t("locker_id").message || "Locker ID",
              accessor: d => d.sentContent?.lockersystem,
              maxWidth: 140,
            },
            {
              id: "cell_nr",
              Header: t("cell_nr").message || "Cell #",
              accessor: d => d.sentContent?.cellnumber,
              maxWidth: 140,
            },
            {
              id: "event",
              Header: t("event").message || "Event",
              accessor: d => d.sentContent?.eventName,
            },
            {
              id: "date",
              Header: t("date").message || "Date",
              accessor: d => moment(d.sentContent?.eventDate).format("DD-MM-YYYY"),
            },
            {
              id: "wo_nr",
              Header: t("wo_nr").message || "WO #",
              accessor: d => d.sentContent?.booking.name,
            },
            {
              id: "reg_nr",
              Header: t("reg_nr").message || "Reg #",
              accessor: d => d.sentContent?.booking.tag,
            },
            {
              id: "state",
              Header: t("state").message || "State",
              accessor: d => d.sentContent?.booking.state,
            },
            {
              id: "claire_response",
              Header: t("claire_response").message || "Claire response",
              headerClassName: "ReactTable__column-header AcsesClaireResponseHeader",
              accessor: d => {
                if (d.responseStatus === 200)
                  return (
                    <div className="AcsesClaireResponseIcon">
                      <Icon name="checkmark" color="green" />
                    </div>
                  );

                return (
                  <div className="AcsesClaireResponseIcon">
                    <Icon name="close" color="red" onClick={e => this.handleShowModal(e, d.responseContent)} />
                  </div>
                );
              },
              width: 110,
            },
          ]}
        />
      </div>
    );
  };

  render() {
    const { t } = this.props;

    return (
      <div className="AcsesDashboard">
        <h1>{t("acses_dashboard").message || "ACSES Dashboard"}</h1>
        {this.renderEventTable()}
        {this.renderResponseContentModal()}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { globalState: state.global };
};

const mapDispatchToProps = dispatch => ({
  setAlert: alertOptions => dispatch(setAlert(alertOptions)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AcsesDashboard));
