import axios from "axios";

import ENV from "../../util/env-config";
import { getSessionCookie } from "../../util/common";

class Service {
  static requestConfig() {
    return {
      headers: {
        Authorization: `Bearer ${getSessionCookie()}`,
        Pragma: "no-cache",
      },
    };
  }

  static verifyDomainInfo(domain, dealerID) {
    return axios.post(ENV.dealerBaseUrl + "/domains/verify", { name: domain, dealer_id: dealerID }, this.requestConfig());
  }

  static createDomain(domain, dealer_id) {
    return axios.post(ENV.dealerBaseUrl + "/domains/create", { name: domain, dealer_id: dealer_id }, this.requestConfig());
  }

  static deleteDomain(dealer_id) {
    return axios.post(ENV.dealerBaseUrl + "/domains/delete", { dealer_id }, this.requestConfig());
  }

  static createTwilioSubaccount(dealer_id) {
    return axios.post(ENV.twilioBaseUrl + "/sub_accounts/create", { dealer_id }, this.requestConfig());
  }

  static generateTwilioAPIKey(dealer_id) {
    return axios.post(ENV.twilioBaseUrl + "/sub_accounts/generate_api_key", { dealer_id }, this.requestConfig());
  }

  static buyTwilioNumber(dealer_id) {
    return axios.post(ENV.twilioBaseUrl + "/sub_accounts/buy_number", { dealer_id }, this.requestConfig());
  }

  static setMonthlyEuroLimit(monthly_price, dealer_id) {
    return axios.post(ENV.twilioBaseUrl + "/sub_accounts/set_monthly_euro_limit", { monthly_price, dealer_id }, this.requestConfig());
  }
}

export default Service;
