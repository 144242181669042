import React, { Component } from "react";
import { Form, Checkbox, Header, Icon, Segment } from "semantic-ui-react";
import { withTranslation } from "react-i18next";

import UserInput from "../UserInput";
import PasswordInput from "../PasswordInput";

class ShareboxConfiguration extends Component {
  handleInputChange = e => {
    const { location, onLocationChange } = this.props;

    onLocationChange({ ...location, [e.target.name]: e.target.value });
  };

  handleCheckboxChange = (_e, { name, checked }) => {
    const { location, onLocationChange } = this.props;

    onLocationChange({ ...location, [name]: checked });
  };

  render() {
    const { location, t } = this.props;

    return (
      <Segment>
        <Header as="h5">
          <Icon name="wrench" />
          <Header.Content>{t("sharebox_configuration").message || "Sharebox configuration"}</Header.Content>
        </Header>
        <Form>
          <Form.Field style={{ marginLeft: "4px", marginRight: 0, maxWidth: "100%" }}>
            <Checkbox checked={location.is_sharebox_enabled} name="is_sharebox_enabled" label={t("enabled").message || "Enabled"} onChange={this.handleCheckboxChange} />
          </Form.Field>

          <Form.Field className="full-width-input">
            <label>{t("sharebox_api_client_id").message || "Sharebox api client ID"}</label>
            <UserInput
              value={location.sharebox_api_client_id}
              name="sharebox_api_client_id"
              placeholder={t("sharebox_api_client_id").message || "Sharebox api client ID"}
              error={location.is_sharebox_enabled && !location.sharebox_api_client_id}
              onChange={this.handleInputChange}
            />
          </Form.Field>

          <Form.Field className="full-width-input">
            <label>{t("sharebox_api_client_secret").message || "Sharebox api client secret"}</label>
            <PasswordInput
              value={location.sharebox_api_client_secret}
              name="sharebox_api_client_secret"
              placeholder={t("sharebox_api_client_secret").message || "Sharebox api client secret"}
              onChange={this.handleInputChange}
              showAsteriskPlaceholder={location.has_sharebox_api_client_secret}
            />
          </Form.Field>

          <Form.Field className="full-width-input">
            <label>{t("sharebox_customer_id").message || "Sharebox customer ID"}</label>
            <UserInput
              value={location.sharebox_customer_id}
              name="sharebox_customer_id"
              placeholder={t("sharebox_customer_id").message || "Sharebox customer ID"}
              error={location.is_sharebox_enabled && !location.sharebox_customer_id}
              onChange={this.handleInputChange}
            />
          </Form.Field>

          <Form.Field className="full-width-input">
            <label>{t("sharebox_session_id").message || "Sharebox session ID"}</label>
            <UserInput
              value={location.sharebox_session_id}
              name="sharebox_session_id"
              placeholder={t("sharebox_session_id").message || "Sharebox session ID"}
              error={location.is_sharebox_enabled && !location.sharebox_session_id}
              onChange={this.handleInputChange}
            />
          </Form.Field>

          <Form.Field className="full-width-input">
            <label>{t("sharebox_weblink_access_key").message || "Sharebox weblink access key"}</label>
            <PasswordInput
              value={location.sharebox_weblink_access_key}
              name="sharebox_weblink_access_key"
              placeholder={t("sharebox_weblink_access_key").message || "Sharebox weblink access key"}
              onChange={this.handleInputChange}
              showAsteriskPlaceholder={location.has_sharebox_weblink_access_key}
            />
          </Form.Field>

          <Form.Field className="full-width-input">
            <label>{t("sharebox_private_key").message || "Sharebox private key"}</label>
            <PasswordInput
              disabled
              value={location.sharebox_private_key}
              name="sharebox_private_key"
              placeholder={t("sharebox_private_key").message || "Sharebox private key"}
              showAsteriskPlaceholder={location.has_sharebox_private_key}
            />
          </Form.Field>
        </Form>
      </Segment>
    );
  }
}

export default withTranslation()(ShareboxConfiguration);
