import React, { Component, createRef } from "react";
import moment from "moment";
import AlertContainer from "react-alert";
import DatePicker from "react-datepicker";
import { saveAs } from "file-saver";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Button, Checkbox, Dropdown, Form, Header, Icon, Modal, TextArea } from "semantic-ui-react";
import {
  faExclamationTriangle,
  faFileAlt,
  faFileInvoiceDollar,
  faImage,
  faInfo,
  faPaperclip,
  faPhoneAlt,
  faStickyNote,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { faParking, faSteeringWheel } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Can from "../Can";
import CustomConfirm from "../../components/CustomConfirm";
import { deleteAppointmentNote, getAppointment, updateAppointmentNote } from "./store";
import { APPOINTMENT_NOTE_TYPES } from "./util";
import { APPOINTMENT_STATUSES } from "./common";
import { NOTE_TYPES } from "../NoteSuggestions";
import { UserInput } from "../../components";

import Service from "./service";

import "./AppointmentNote.css";

const initialState = {
  id: 0,
  appointment_id: 0,
  appointment_note_type_id: 0,
  note_suggestion_id: null,
  phone_nr: null,
  backorder_date: null,
  attachments: [],
  note: "",
  name: "",
  email: "",
  visible_for_mechanic: false,
  isSubmitingNote: false,
  showDeleteConfirmation: false,
  isDeletingNote: false,
  errorMessage: "",
  phoneOptions: [],
  selectedPhoneOption: "",
  fileDraggedOver: false,
  validationError: false,
  suggestionOptions: [],
};

class AppointmentNote extends Component {
  state = {
    ...initialState,
  };

  fileInputRef = createRef();
  msg = createRef();

  componentDidUpdate(prevProps) {
    const { note, appointment } = this.props;

    if (!prevProps.note && note) {
      const backorder_date =
        note.appointment_note_type_id === APPOINTMENT_NOTE_TYPES.BACK_ORDER ? (note.backorder_date ? note.backorder_date : appointment.time_car_app) : null;

      this.setState({ ...initialState, ...note, backorder_date }, () => {
        if (this.state.appointment_note_type_id === APPOINTMENT_NOTE_TYPES.CALL_CUSTOMER) this.getPhoneOptions();
      });
    }
  }

  handleChangeNote = e => {
    e.persist();
    this.setState({ note: e.target.value });
  };

  handleVisibleForMechanic = (_e, data) => {
    this.setState({ visible_for_mechanic: data.checked });
  };

  handleChangeBackorderDate = date => {
    this.setState({ backorder_date: date ? moment(date).format() : null });
  };

  handleChangeInput = (_e, data) => {
    this.setState({ [data.name]: data.value });
  };

  handleSelectPhone = (_e, data) => {
    if (data.value === "other") {
      this.setState({ selectedPhoneOption: data.value, phone_nr: "" });
      return;
    }

    const [phone_nr, customer_id] = data.value.split(";");
    this.setState({ phone_nr, selectedPhoneOption: `${phone_nr};${customer_id}` });
  };

  handleSuggestionChange = (_, { value, options }) => {
    const note = options.find(o => o.value === value)?.text;

    this.setState({ note_suggestion_id: value, note });
  };

  handleCloseNote = () => {
    this.setState({ ...initialState }, () => this.props.onClose());
  };

  handleCancelDelete = () => {
    this.setState({ showDeleteConfirmation: false });
  };

  showDeleteConfirmationModal = () => {
    this.setState({ showDeleteConfirmation: true });
  };

  getErrorMessage = error => {
    return error?.response?.data?.errors?.shift() || this.props.t("unexpected_error").message || "Unexpected error, please try again.";
  };

  onDeleteNote = () => {
    const { id } = this.state;
    const { t, deleteAppointmentNote, onClose } = this.props;

    if (!id) return;

    this.setState({ isDeletingNote: true }, () => {
      Service.deleteAppointmentNote(id)
        .then(() => {
          deleteAppointmentNote(id);
          onClose();
          this.setState({ isDeletingNote: false, showDeleteConfirmation: false });
        })
        .catch(error => {
          let errorMessage = this.getErrorMessage(error);
          if (errorMessage === "not_allowed_to_delete_note") errorMessage = t("not_allowed_to_delete_note").message;
          this.setState({ errorMessage, isDeletingNote: false });
        });
    });
  };

  handleSubmit = () => {
    const { appointment_note_type_id } = this.state;

    this.setState({ validationError: false }, () => {
      switch (appointment_note_type_id) {
        case APPOINTMENT_NOTE_TYPES.WO:
          return this.handleSubmitWONote();
        case APPOINTMENT_NOTE_TYPES.MAIN:
          return this.handleSubmitMainNote();
        case APPOINTMENT_NOTE_TYPES.CALL_CUSTOMER:
          return this.handleSubmitCallCustomerNote();
        case APPOINTMENT_NOTE_TYPES.BACK_ORDER:
          return this.handleSubmitBackOrderNote();
        case APPOINTMENT_NOTE_TYPES.INFO:
          return this.handleSubmitInfoNote();
        case APPOINTMENT_NOTE_TYPES.ATTACHMENT:
          return this.handleSubmitAttachmentNote();
        case APPOINTMENT_NOTE_TYPES.BILL_NOTE:
          return this.handleSubmitBillNote();
        case APPOINTMENT_NOTE_TYPES.TEMPORARY_DRIVER_NOTE:
          return this.handleSubmitTempDriverNote();
        case APPOINTMENT_NOTE_TYPES.RECURRING_CAR:
          return this.handleSubmitRecurringCarNote();
        case APPOINTMENT_NOTE_TYPES.PARKING:
          return this.handleSubmitParkingNote();
        default:
          return;
      }
    });
  };

  handleSubmitWONote = () => {
    const { id, note, visible_for_mechanic, attachments } = this.state;
    const { appointment, getAppointment, updateAppointmentNote, onClose, authState } = this.props;

    if (!note) {
      this.setState({ validationError: true });
      return;
    }

    if (id > 0) {
      const requestData = { note, visible_for_mechanic, attachments };

      this.setState({ isSubmitingNote: true }, () => {
        Service.updateWONote({ ...requestData, appointment_note_id: id })
          .then(() => {
            updateAppointmentNote(id, { ...requestData, updated_by: authState.user, updated_on: moment() });
            onClose();
            this.setState({ isSubmitingNote: false });
          })
          .catch(error => {
            this.setState({ errorMessage: this.getErrorMessage(error), isSubmitingNote: false });
          });
      });
    } else {
      this.setState({ isSubmitingNote: true }, () => {
        Service.createWONote({ appointment_id: appointment.id, note, visible_for_mechanic, attachments })
          .then(() => {
            getAppointment(appointment.id);
            onClose();
            this.setState({ isSubmitingNote: false });
          })
          .catch(error => {
            this.setState({ errorMessage: this.getErrorMessage(error), isSubmitingNote: false });
          });
      });
    }
  };

  handleSubmitMainNote = () => {
    const { id, note, visible_for_mechanic, attachments } = this.state;
    const { appointment, getAppointment, updateAppointmentNote, onClose, authState } = this.props;

    if (!note) {
      this.setState({ validationError: true });
      return;
    }

    if (id > 0) {
      const requestData = { note, visible_for_mechanic, attachments };

      this.setState({ isSubmitingNote: true }, () => {
        Service.updateMainNote({ ...requestData, appointment_note_id: id })
          .then(() => {
            updateAppointmentNote(id, { ...requestData, updated_by: authState.user, updated_on: moment() });
            onClose();
            this.setState({ isSubmitingNote: false });
          })
          .catch(error => {
            this.setState({ errorMessage: this.getErrorMessage(error), isSubmitingNote: false });
          });
      });
    } else {
      this.setState({ isSubmitingNote: true }, () => {
        Service.createMainNote({ appointment_id: appointment.id, note, visible_for_mechanic, attachments })
          .then(() => {
            getAppointment(appointment.id);
            onClose();
            this.setState({ isSubmitingNote: false });
          })
          .catch(error => {
            this.setState({ errorMessage: this.getErrorMessage(error), isSubmitingNote: false });
          });
      });
    }
  };

  handleSubmitCallCustomerNote = () => {
    const { id, note, phone_nr, attachments } = this.state;
    const { appointment, getAppointment, updateAppointmentNote, onClose, authState } = this.props;

    if (!note || !phone_nr) {
      this.setState({ validationError: true });
      return;
    }

    const requestData = { note, phone_nr, attachments };

    if (id > 0) {
      this.setState({ isSubmitingNote: true }, () => {
        Service.updateCallCustomerNote({ ...requestData, appointment_note_id: id })
          .then(() => {
            onClose();
            updateAppointmentNote(id, { ...requestData, updated_by: authState.user, updated_on: moment() });
            this.setState({ isSubmitingNote: false, note_suggestion_id: null });
          })
          .catch(error => {
            this.setState({ errorMessage: this.getErrorMessage(error), isSubmitingNote: false });
          });
      });
    } else {
      this.setState({ isSubmitingNote: true }, () => {
        Service.createCallCustomerNote({ ...requestData, appointment_id: appointment.id })
          .then(() => {
            getAppointment(appointment.id);
            onClose();
            this.setState({ isSubmitingNote: false, note_suggestion_id: null });
          })
          .catch(error => {
            this.setState({ errorMessage: this.getErrorMessage(error), isSubmitingNote: false });
          });
      });
    }
  };

  handleSubmitAttachmentNote = () => {
    const { id, note, attachments } = this.state;
    const { appointment, getAppointment, updateAppointmentNote, onClose, authState } = this.props;

    if (!attachments.length) {
      this.setState({ validationError: true });
      return;
    }

    if (id > 0) {
      const requestData = { note, attachments };

      this.setState({ isSubmitingNote: true }, () => {
        Service.updateAttachmentNote({ ...requestData, appointment_note_id: id })
          .then(() => {
            updateAppointmentNote(id, { ...requestData, updated_by: authState.user, updated_on: moment() });
            onClose();
            this.setState({ isSubmitingNote: false });
          })
          .catch(error => {
            this.setState({ errorMessage: this.getErrorMessage(error), isSubmitingNote: false });
          });
      });
    } else {
      this.setState({ isSubmitingNote: true }, () => {
        Service.createAttachmentNote({ appointment_id: appointment.id, note, attachments })
          .then(() => {
            getAppointment(appointment.id);
            onClose();
            this.setState({ isSubmitingNote: false });
          })
          .catch(error => {
            this.setState({ errorMessage: this.getErrorMessage(error), isSubmitingNote: false });
          });
      });
    }
  };

  handleSubmitBackOrderNote = () => {
    const { note, attachments, backorder_date } = this.state;
    const { appointment, getAppointment, onClose } = this.props;

    if (!note) {
      this.setState({ validationError: true });
      return;
    }

    this.setState({ isSubmitingNote: true }, () => {
      Service.updateStatus({
        appointment_id: appointment.id,
        new_status_identifier: APPOINTMENT_STATUSES.BACK_ORDER,
        sa_remarks: note,
        back_order_time_car_app: backorder_date,
        note_attachments: attachments,
      })
        .then(() => {
          getAppointment(appointment.id);
          onClose();
          this.setState({ isSubmitingNote: false });
        })
        .catch(error => {
          this.setState({ errorMessage: this.getErrorMessage(error), isSubmitingNote: false });
        });
    });
  };

  handleSubmitInfoNote = () => {
    const { note, attachments } = this.state;
    const { appointment, getAppointment, onClose } = this.props;

    if (!note) {
      this.setState({ validationError: true });
      return;
    }

    this.setState({ isSubmitingNote: true }, () => {
      Service.updateStatus({
        appointment_id: appointment.id,
        new_status_identifier: APPOINTMENT_STATUSES.DIAGNOSE,
        sa_remarks: note,
        note_attachments: attachments,
      })
        .then(() => {
          getAppointment(appointment.id);
          onClose();
          this.setState({ isSubmitingNote: false });
        })
        .catch(error => {
          this.setState({ errorMessage: this.getErrorMessage(error), isSubmitingNote: false });
        });
    });
  };

  handleSubmitBillNote = () => {
    const { id, note, attachments = [] } = this.state;
    const { appointment, getAppointment, updateAppointmentNote, onClose, authState } = this.props;

    if (!attachments.length) {
      this.setState({ validationError: true });
      return;
    }

    const requestData = { note, attachment: attachments[0] };

    if (id > 0) {
      this.setState({ isSubmitingNote: true }, () => {
        Service.updateBillNote({ ...requestData, appointment_note_id: id })
          .then(() => {
            updateAppointmentNote(id, { ...requestData, updated_by: authState.user, updated_on: moment() });
            onClose();
            this.setState({ isSubmitingNote: false });
          })
          .catch(error => {
            this.setState({ errorMessage: this.getErrorMessage(error), isSubmitingNote: false });
          });
      });
    } else {
      this.setState({ isSubmitingNote: true }, () => {
        Service.createBillNote({ appointment_id: appointment.id, ...requestData })
          .then(() => {
            getAppointment(appointment.id);
            onClose();
            this.setState({ isSubmitingNote: false });
          })
          .catch(error => {
            this.setState({ errorMessage: this.getErrorMessage(error), isSubmitingNote: false });
          });
      });
    }
  };

  handleSubmitTempDriverNote = () => {
    const { id, name, phone_nr, email, note } = this.state;
    const { appointment, getAppointment, updateAppointmentNote, onClose, authState } = this.props;

    if (!name || !phone_nr) {
      this.setState({ validationError: true });
      return;
    }

    const requestData = { name, phone_nr, email, note };

    if (id > 0) {
      this.setState({ isSubmitingNote: true }, () => {
        Service.updateTempDriverNote({ ...requestData, appointment_note_id: id, appointment_id: appointment.id })
          .then(() => {
            updateAppointmentNote(id, { ...requestData, updated_by: authState.user, updated_on: moment() });
            onClose();
            this.setState({ isSubmitingNote: false });
          })
          .catch(error => {
            this.setState({ errorMessage: this.getErrorMessage(error), isSubmitingNote: false });
          });
      });
    } else {
      this.setState({ isSubmitingNote: true }, () => {
        Service.createTempDriverNote({ appointment_id: appointment.id, ...requestData })
          .then(() => {
            getAppointment(appointment.id);
            onClose();
            this.setState({ isSubmitingNote: false });
          })
          .catch(error => {
            this.setState({ errorMessage: this.getErrorMessage(error), isSubmitingNote: false });
          });
      });
    }
  };

  handleSubmitRecurringCarNote = () => {
    const { id, note, attachments } = this.state;
    const { appointment, getAppointment, updateAppointmentNote, onClose, authState } = this.props;

    if (!note) {
      this.setState({ validationError: true });
      return;
    }

    if (id > 0) {
      const requestData = { note, attachments };

      this.setState({ isSubmitingNote: true }, () => {
        Service.updateRecurringCarNote({ ...requestData, appointment_note_id: id })
          .then(() => {
            updateAppointmentNote(id, { ...requestData, updated_by: authState.user, updated_on: moment() });
            onClose();
            this.setState({ isSubmitingNote: false });
          })
          .catch(error => {
            this.setState({ errorMessage: this.getErrorMessage(error), isSubmitingNote: false });
          });
      });
    } else {
      this.setState({ isSubmitingNote: true }, () => {
        Service.createRecurringCarNote({ appointment_id: appointment.id, note, attachments })
          .then(() => {
            getAppointment(appointment.id);
            onClose();
            this.setState({ isSubmitingNote: false });
          })
          .catch(error => {
            this.setState({ errorMessage: this.getErrorMessage(error), isSubmitingNote: false });
          });
      });
    }
  };

  handleSubmitParkingNote = () => {
    const { id, note } = this.state;
    const { appointment, getAppointment, updateAppointmentNote, onClose, authState } = this.props;

    if (!note) {
      this.setState({ validationError: true });
      return;
    }

    if (id > 0) {
      this.setState({ isSubmitingNote: true }, () => {
        Service.updateParkingNote({ note, appointment_note_id: id })
          .then(() => {
            updateAppointmentNote(id, { note, updated_by: authState.user, updated_on: moment() });
            onClose();
            this.setState({ isSubmitingNote: false });
          })
          .catch(error => {
            this.setState({ errorMessage: this.getErrorMessage(error), isSubmitingNote: false });
          });
      });
    } else {
      this.setState({ isSubmitingNote: true }, () => {
        Service.createParkingNote({ appointment_id: appointment.id, note })
          .then(() => {
            getAppointment(appointment.id);
            onClose();
            this.setState({ isSubmitingNote: false });
          })
          .catch(error => {
            this.setState({ errorMessage: this.getErrorMessage(error), isSubmitingNote: false });
          });
      });
    }
  };

  getPhoneOptions = () => {
    const { phone_nr } = this.state;
    const { t, appointment } = this.props;

    let selectedPhoneOption = "";
    let phoneOptions = [];

    if (appointment.customer_driver?.id) {
      if (appointment.customer_driver.tel_mobile_business) {
        phoneOptions.push({
          key: 1,
          text: appointment.customer_driver.tel_mobile_business,
          value: appointment.customer_driver.tel_mobile_business + ";" + appointment.customer_driver.id,
          description: t("driver_mobile_business").message || "Driver Mobile Business",
        });

        if (phone_nr === appointment.customer_driver.tel_mobile_business)
          selectedPhoneOption = appointment.customer_driver.tel_mobile_business + ";" + appointment.customer_driver.id;
      }

      if (appointment.customer_driver.tel_mobile_private) {
        phoneOptions.push({
          key: 2,
          text: appointment.customer_driver.tel_mobile_private,
          value: appointment.customer_driver.tel_mobile_private + ";" + appointment.customer_driver.id,
          description: t("driver_mobile_private").message || "Driver Mobile Private",
        });

        if (phone_nr === appointment.customer_driver.tel_mobile_private)
          selectedPhoneOption = appointment.customer_driver.tel_mobile_private + ";" + appointment.customer_driver.id;
      }

      if (appointment.customer_driver.tel_business_nr) {
        phoneOptions.push({
          key: 3,
          text: appointment.customer_driver.tel_business_nr,
          value: appointment.customer_driver.tel_business_nr + ";" + appointment.customer_driver.id,
          description: t("driver_business").message || "Driver Business",
        });

        if (phone_nr === appointment.customer_driver.tel_business_nr)
          selectedPhoneOption = appointment.customer_driver.tel_business_nr + ";" + appointment.customer_driver.id;
      }

      if (appointment.customer_driver.tel_private_nr) {
        phoneOptions.push({
          key: 4,
          text: appointment.customer_driver.tel_private_nr,
          value: appointment.customer_driver.tel_private_nr + ";" + appointment.customer_driver.id,
          description: t("driver_private").message || "Driver Private",
        });

        if (phone_nr === appointment.customer_driver.tel_private_nr)
          selectedPhoneOption = appointment.customer_driver.tel_private_nr + ";" + appointment.customer_driver.id;
      }
    }

    if (appointment.customer_contract?.id) {
      if (appointment.customer_contract.tel_mobile_business) {
        phoneOptions.push({
          key: 5,
          text: appointment.customer_contract.tel_mobile_business,
          value: appointment.customer_contract.tel_mobile_business + ";" + appointment.customer_contract.id,
          description: t("contract_mobile_business").message || "Contract Mobile Business",
        });

        if (phone_nr === appointment.customer_contract.tel_mobile_business)
          selectedPhoneOption = appointment.customer_contract.tel_mobile_business + ";" + appointment.customer_contract.id;
      }

      if (appointment.customer_contract.tel_mobile_private) {
        phoneOptions.push({
          key: 6,
          text: appointment.customer_contract.tel_mobile_private,
          value: appointment.customer_contract.tel_mobile_private + ";" + appointment.customer_contract.id,
          description: t("contract_mobile_private").message || "Contract Mobile Private",
        });

        if (phone_nr === appointment.customer_contract.tel_mobile_private)
          selectedPhoneOption = appointment.customer_contract.tel_mobile_private + ";" + appointment.customer_contract.id;
      }

      if (appointment.customer_contract.tel_business_nr) {
        phoneOptions.push({
          key: 7,
          text: appointment.customer_contract.tel_business_nr,
          value: appointment.customer_contract.tel_business_nr + ";" + appointment.customer_contract.id,
          description: t("contract_business").message || "Contract Business",
        });

        if (phone_nr === appointment.customer_contract.tel_business_nr)
          selectedPhoneOption = appointment.customer_contract.tel_business_nr + ";" + appointment.customer_contract.id;
      }

      if (appointment.customer_contract.tel_private_nr) {
        phoneOptions.push({
          key: 8,
          text: appointment.customer_contract.tel_private_nr,
          value: appointment.customer_contract.tel_private_nr + ";" + appointment.customer_contract.id,
          description: t("contract_private").message || "Contract Private",
        });

        if (phone_nr === appointment.customer_contract.tel_private_nr)
          selectedPhoneOption = appointment.customer_contract.tel_private_nr + ";" + appointment.customer_contract.id;
      }
    }

    if (appointment.customer_owner?.id) {
      if (appointment.customer_owner.tel_mobile_business) {
        phoneOptions.push({
          key: 9,
          text: appointment.customer_owner.tel_mobile_business,
          value: appointment.customer_owner.tel_mobile_business + ";" + appointment.customer_owner.id,
          description: t("owner_mobile_business").message || "Owner Mobile Business",
        });

        if (phone_nr === appointment.customer_owner.tel_mobile_business)
          selectedPhoneOption = appointment.customer_owner.tel_mobile_business + ";" + appointment.customer_owner.id;
      }

      if (appointment.customer_owner.tel_mobile_private) {
        phoneOptions.push({
          key: 10,
          text: appointment.customer_owner.tel_mobile_private,
          value: appointment.customer_owner.tel_mobile_private + ";" + appointment.customer_owner.id,
          description: t("owner_mobile_private").message || "Owner Mobile Private",
        });

        if (phone_nr === appointment.customer_owner.tel_mobile_private)
          selectedPhoneOption = appointment.customer_owner.tel_mobile_private + ";" + appointment.customer_owner.id;
      }

      if (appointment.customer_owner.tel_business_nr) {
        phoneOptions.push({
          key: 11,
          text: appointment.customer_owner.tel_business_nr,
          value: appointment.customer_owner.tel_business_nr + ";" + appointment.customer_owner.id,
          description: t("owner_business").message || "Owner Business",
        });

        if (phone_nr === appointment.customer_owner.tel_business_nr)
          selectedPhoneOption = appointment.customer_owner.tel_business_nr + ";" + appointment.customer_owner.id;
      }

      if (appointment.customer_owner.tel_private_nr) {
        phoneOptions.push({
          key: 11,
          text: appointment.customer_owner.tel_private_nr,
          value: appointment.customer_owner.tel_private_nr + ";" + appointment.customer_owner.id,
          description: t("owner_private").message || "Owner Private",
        });

        if (phone_nr === appointment.customer_owner.tel_private_nr) selectedPhoneOption = appointment.customer_owner.tel_private_nr + ";" + appointment.customer_owner.id;
      }
    }

    phoneOptions.push({
      text: t("other").message || "Other",
      value: "other",
    });

    selectedPhoneOption = phone_nr && !selectedPhoneOption ? "other" : selectedPhoneOption || phoneOptions[0].value;

    this.setState({ phoneOptions, selectedPhoneOption, phone_nr: selectedPhoneOption !== "other" ? selectedPhoneOption.split(";")[0] : phone_nr });
  };

  renderModalHeader = () => {
    const { id, appointment_note_type_id } = this.state;
    const { t } = this.props;

    switch (appointment_note_type_id) {
      default:
      case APPOINTMENT_NOTE_TYPES.WO:
        return (
          <Header>
            <FontAwesomeIcon icon={faFileAlt} color="#4283CA" />
            <span>{id > 0 ? t("edit_note").message || "Edit Note" : t("add_note").message || "Add Note"}</span>
          </Header>
        );
      case APPOINTMENT_NOTE_TYPES.INFO:
        return (
          <Header>
            <FontAwesomeIcon icon={faInfo} color="#4283CA" />
            <span>{t("set_info_status").message || "Set Info status"}</span>
          </Header>
        );
      case APPOINTMENT_NOTE_TYPES.BACK_ORDER:
        return (
          <Header>
            <div className="backorder-icon">
              <span>BO</span>
            </div>
            <span>{t("set_back_order").message || "Set Back Order"}</span>
          </Header>
        );
      case APPOINTMENT_NOTE_TYPES.MAIN:
        return (
          <Header>
            <FontAwesomeIcon icon={faStickyNote} color="red" />
            <span>{id > 0 ? t("edit_main_note").message || "Edit Main Note" : t("add_main_note").message || "Add Main Note"}</span>
          </Header>
        );
      case APPOINTMENT_NOTE_TYPES.CALL_CUSTOMER:
        return (
          <Header>
            <FontAwesomeIcon icon={faPhoneAlt} color="#21BA45" />
            <span>{id > 0 ? t("edit_call_customer_note").message || "Edit Call Customer Note" : t("add_call_customer_note").message || "Add Call Customer Note"}</span>
          </Header>
        );
      case APPOINTMENT_NOTE_TYPES.ATTACHMENT:
        return (
          <Header>
            <FontAwesomeIcon icon={faPaperclip} color="#4283CA" />
            <span>{id > 0 ? t("edit_attachment_note").message || "Edit Attachment Note" : t("add_attachment_note").message || "Add Attachment Note"}</span>
          </Header>
        );
      case APPOINTMENT_NOTE_TYPES.BILL_NOTE:
        return (
          <Header>
            <FontAwesomeIcon icon={faFileInvoiceDollar} color="#46B046" />
            <span>{id > 0 ? t("edit_customer_bill").message || "Edit Customer Bill" : t("add_customer_bill").message || "Add Customer Bill"}</span>
          </Header>
        );

      case APPOINTMENT_NOTE_TYPES.TEMPORARY_DRIVER_NOTE:
        return (
          <Header>
            <FontAwesomeIcon icon={faSteeringWheel} color="#000000" />
            <span>{id > 0 ? t("edit_temporary_driver").message || "Edit Temporary Driver" : t("add_temporary_driver").message || "Add Temporary Driver"}</span>
          </Header>
        );

      case APPOINTMENT_NOTE_TYPES.RECURRING_CAR:
        return (
          <Header>
            <FontAwesomeIcon icon={faExclamationTriangle} color="#C83628" />
            <span>{id > 0 ? t("edit_recurring_car").message || "Edit recurring car" : t("add_recurring_car").message || "Add Recurring Car"}</span>
          </Header>
        );

      case APPOINTMENT_NOTE_TYPES.PARKING:
        return (
          <Header>
            <FontAwesomeIcon icon={faParking} color="#4283CA" />
            <span>{id > 0 ? t("edit_parking_location").message || "Edit Parking Location" : t("add_parking_location").message || "Add Parking Location"}</span>
          </Header>
        );
    }
  };

  renderTemporaryDriverNoteFields = () => {
    const { t } = this.props;
    const { name, phone_nr, email, appointment_note_type_id, validationError } = this.state;

    if (appointment_note_type_id !== APPOINTMENT_NOTE_TYPES.TEMPORARY_DRIVER_NOTE) return null;

    return (
      <>
        <Form.Field required error={validationError && !name}>
          <label>{t("name").message || "Name"}</label>
          <UserInput value={name || ""} name="name" placeholder={t("enter_driver_name").message || "Enter Driver Name"} onChange={this.handleChangeInput} />
        </Form.Field>

        <Form.Field required error={validationError && !phone_nr}>
          <label>{t("phone_nr").message || "Phone number"}</label>
          <UserInput value={phone_nr || ""} name="phone_nr" placeholder={t("enter_phone_number").message || "Enter Phone Number"} onChange={this.handleChangeInput} />
        </Form.Field>

        <Form.Field>
          <label>{t("email").message || "E-mail"}</label>
          <UserInput value={email || ""} name="email" placeholder={t("enter_email").message || "Enter E-mail"} onChange={this.handleChangeInput} />
        </Form.Field>
      </>
    );
  };

  renderTitle = () => {
    const { t } = this.props;

    if (this.state.appointment_note_type_id === APPOINTMENT_NOTE_TYPES.BILL_NOTE) return t("internal_note").message || "Internal Note";
    else return t("note").message || "Note";
  };

  renderOptionalModalContent = () => {
    const { visible_for_mechanic, appointment_note_type_id, backorder_date, phone_nr, selectedPhoneOption, phoneOptions, validationError } = this.state;
    const { t } = this.props;

    if ([APPOINTMENT_NOTE_TYPES.WO, APPOINTMENT_NOTE_TYPES.MAIN].includes(appointment_note_type_id))
      return (
        <Form.Group widths="equal">
          <Form.Field />
          <Form.Field>
            <Checkbox
              toggle
              className="-pull-right"
              checked={visible_for_mechanic}
              onChange={this.handleVisibleForMechanic}
              label={t("visible_for_mechanic").message || "Visible for mechanic"}
            />
          </Form.Field>
        </Form.Group>
      );

    if (appointment_note_type_id === APPOINTMENT_NOTE_TYPES.BACK_ORDER)
      return (
        <Form.Field width={8}>
          <label>{t("note_date").message || "Date"}</label>
          <DatePicker
            showYearDropdown
            showMonthDropdown
            dateFormat="dd-MM-yyyy"
            onChangeRaw={e => e.preventDefault()}
            onChange={this.handleChangeBackorderDate}
            selected={moment(backorder_date).toDate()}
          />
        </Form.Field>
      );

    if (appointment_note_type_id === APPOINTMENT_NOTE_TYPES.CALL_CUSTOMER)
      return (
        <Form.Group>
          <Form.Field width={8}>
            <label>{t("phone").message || "Phone"}</label>
            <Dropdown options={phoneOptions} value={selectedPhoneOption} onChange={this.handleSelectPhone} selection />
          </Form.Field>
          {selectedPhoneOption === "other" && (
            <Form.Field width={8} error={validationError && !phone_nr}>
              <label>&nbsp;</label>
              <UserInput value={phone_nr || ""} name="phone_nr" placeholder={t("enter_phone_number").message || "Enter phone number"} onChange={this.handleChangeInput} />
            </Form.Field>
          )}
        </Form.Group>
      );
  };

  handleDeleteFile = url => {
    const attachments = this.state.attachments.filter(file => file.url !== url);

    this.setState({ attachments });
  };

  handleBrowseFiles = () => {
    this.setState({ validationError: false });
    this.fileInputRef.current.click();
  };

  renderAlert = () => {
    const props = {
      offset: 20,
      position: "top center",
      theme: "light",
      time: 2000,
      transition: "fade",
    };

    return <AlertContainer ref={a => (this.msg = a)} {...props} />;
  };

  handleUploadFile = file => {
    const {
      authState: { user },
      appointment,
      globalState: { selectedLocation },
    } = this.props;

    const extension = file.name.lastIndexOf(".") > -1 ? file.name.slice(file.name.lastIndexOf(".") + 1) : "unknown";
    const uploadFile = new File([file], file.name, { type: file.type || extension });
    const formData = new FormData();

    const date = moment(appointment.created_on).format("YYYY/MM/DD");
    const path = `${selectedLocation.id}_${selectedLocation.name}/${date}/${appointment.id}_${appointment.wo_nr}`;

    formData.append("file", uploadFile);
    formData.append("path", path);

    Service.uploadNoteAttachment(formData)
      .then(res => {
        if (res?.data?.data?.URL) {
          const newFile = {
            url: res.data.data.URL,
            type: file.type || extension,
            name: file.name,
            username: user.first_name + " " + user.last_name,
          };

          this.setState(state => {
            if (state.appointment_note_type_id === APPOINTMENT_NOTE_TYPES.BILL_NOTE) {
              if (state.attachments.length) newFile.id = state.attachments[0].id;
              return { attachments: [newFile] };
            } else {
              return { attachments: [...state.attachments, newFile] };
            }
          });
        } else throw new Error("Error uploading file(s)");
      })
      .catch(error => {
        const errorMsg =
          typeof error === "string"
            ? error
            : error.message
            ? error.message
            : error.response?.data?.errors?.length
            ? error.response.data.errors[0]
            : "Error uploading file(s)";

        this.msg.show(errorMsg, { type: "error" });
      });
  };

  handleDragOver = e => {
    e.preventDefault();
    this.setState({ validationError: false, fileDraggedOver: true });
  };

  handleDragLeave = e => {
    e.preventDefault();
    this.setState({ fileDraggedOver: false });
  };

  handleDropFiles = e => {
    e.preventDefault();

    for (const file of e.dataTransfer.files) {
      this.handleUploadFile(file);
    }

    this.setState({ fileDraggedOver: false });
  };

  handleFileChange = e => {
    for (const file of e.target.files) {
      this.handleUploadFile(file);
    }
  };

  renderAttachments = () => {
    const { id, attachments, fileDraggedOver, appointment_note_type_id, validationError } = this.state;
    const { t } = this.props;

    if ([APPOINTMENT_NOTE_TYPES.PARKING, APPOINTMENT_NOTE_TYPES.TEMPORARY_DRIVER_NOTE].includes(appointment_note_type_id)) return;

    const attachmentsValidationError =
      validationError && [APPOINTMENT_NOTE_TYPES.ATTACHMENT, APPOINTMENT_NOTE_TYPES.BILL_NOTE].includes(appointment_note_type_id) && !attachments.length;

    return (
      <div className="NoteAttachmentsContainer">
        <div
          className={`DragAndDropBox ${fileDraggedOver ? "-dragged-over" : ""} ${attachmentsValidationError ? "-attachments-error" : ""}`}
          onDragLeave={this.handleDragLeave}
          onDragOver={this.handleDragOver}
          onDrop={this.handleDropFiles}
        >
          <>
            {appointment_note_type_id === APPOINTMENT_NOTE_TYPES.BILL_NOTE && id && attachments.length ? (
              <span>{t("file_uploaded").message || "File uploaded"}</span>
            ) : (
              <>
                <span>
                  {t("drag_here_or").message || "Drag here or"}{" "}
                  <span className="browse-files" onClick={this.handleBrowseFiles}>
                    {t("browse").message || "Browse"}
                  </span>
                  <input type="file" ref={this.fileInputRef} multiple hidden onChange={this.handleFileChange} />
                </span>
                <div className="description">{t("upload_max_file_size").message || "Maximum file size: 32MB"}</div>
              </>
            )}
          </>
        </div>

        <div className="FileList">
          {attachments?.length > 0 &&
            attachments.map((file, index) => {
              if (file.failed)
                return (
                  <div className="failed-attachment">
                    <span className="-text-ellipsis failed">{file.name}</span>
                    <Icon className="failed-icon" name="exclamation triangle" color="red" />
                  </div>
                );

              const URL = typeof file === "string" ? file : file.url;

              return (
                <div className="FileRow" key={index}>
                  <div className="FileTitle">
                    <FontAwesomeIcon icon={faImage} />
                    <a
                      href={URL}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={e => {
                        e.preventDefault();
                        saveAs(URL, file.name);
                      }}
                    >
                      {file.name}
                    </a>
                  </div>

                  <div className="FileInfo">
                    <span className="FileAuthor">
                      {t("uploaded_by").message || "Uploaded by"} {file.username}
                    </span>

                    <span className="FileDate">{moment(file.created_on).format("DD-MM-YYYY HH:mm")}</span>

                    <FontAwesomeIcon icon={faTrash} onClick={() => this.handleDeleteFile(URL)} />
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    );
  };

  render() {
    const { id, note, appointment_note_type_id, isDeletingNote, isSubmitingNote, showDeleteConfirmation, errorMessage, validationError, note_suggestion_id } = this.state;
    const { t, globalState } = this.props;

    const suggestionOptions = globalState.selectedLocation?.note_suggestions
      ? globalState.selectedLocation.note_suggestions
          .filter(s => {
            if (appointment_note_type_id === APPOINTMENT_NOTE_TYPES.CALL_CUSTOMER) return s.note_type_id === NOTE_TYPES.CALL_CUSTOMER_NOTE && s.active;
            if (appointment_note_type_id === APPOINTMENT_NOTE_TYPES.PARKING) return s.note_type_id === NOTE_TYPES.PARKING_NOTE && s.active;
            return null;
          })
          .map(suggestion => ({ text: suggestion.content, value: suggestion.order, key: suggestion.order }))
      : [];

    return (
      <>
        <Modal className="AppointmentNoteModal" open={!!this.props.note} closeOnDimmerClick={false} onClose={this.handleCloseNote} size="small">
          {this.renderModalHeader()}
          <Modal.Content scrolling>
            <Form>
              {suggestionOptions.length > 0 && (
                <Dropdown
                  className="-margin-bottom-10"
                  selection
                  fluid
                  selectOnBlur={false}
                  options={suggestionOptions}
                  value={note_suggestion_id}
                  name="note_suggestion_id"
                  placeholder={t("choose_answer").message || "Choose answer"}
                  onChange={this.handleSuggestionChange}
                />
              )}
              {this.renderTemporaryDriverNoteFields()}
              <Form.Field
                error={
                  validationError &&
                  ![APPOINTMENT_NOTE_TYPES.ATTACHMENT, APPOINTMENT_NOTE_TYPES.BILL_NOTE, APPOINTMENT_NOTE_TYPES.TEMPORARY_DRIVER_NOTE].includes(
                    appointment_note_type_id
                  ) &&
                  !note
                }
              >
                <label>{this.renderTitle()}</label>
                <TextArea value={note} placeholder={`${t("type_note_here").message || "Type note here"}...`} onChange={this.handleChangeNote} />
              </Form.Field>
              {this.renderOptionalModalContent()}
              {this.renderAttachments()}
            </Form>
            {errorMessage && <p style={{ color: "red", marginTop: "20px" }}>{errorMessage}</p>}
          </Modal.Content>
          <Modal.Actions style={{ display: "inline-block" }}>
            {id > 0 && ![APPOINTMENT_NOTE_TYPES.BACK_ORDER, APPOINTMENT_NOTE_TYPES.INFO].includes(appointment_note_type_id) && (
              <Can I="delete" the="appointment-notes" or={["appointment-notes-attachments"]}>
                <Button color="red" floated="left" onClick={this.showDeleteConfirmationModal} disabled={isSubmitingNote || isDeletingNote} loading={isDeletingNote}>
                  <Icon name="trash" /> {t("delete").message || "Delete"}
                </Button>
              </Can>
            )}
            <Button color="grey" onClick={this.handleCloseNote}>
              <Icon name="close" />
              {t("cancel").message || "Cancel"}
            </Button>
            <Can I={["create", "update"]} the="appointment-notes" or={["appointment-notes-attachments", "appointment-notes-bills"]}>
              <Button color="green" onClick={this.handleSubmit} disabled={isSubmitingNote || isDeletingNote} loading={isSubmitingNote}>
                <Icon name="checkmark" /> {t("save").message || "Save"}
              </Button>
            </Can>
          </Modal.Actions>

          {this.renderAlert()}
        </Modal>

        <CustomConfirm
          type="danger"
          isLoading={isDeletingNote}
          isOpen={showDeleteConfirmation}
          handleCancel={this.handleCancelDelete}
          handleConfirm={this.onDeleteNote}
          confirmMsg={t("confirm_delete_message").message || "Are you sure that you want to delete this? You can't undo this action."}
          confirmText={t("yes").message || "Yes"}
          cancelText={t("no").message || "No"}
        />
      </>
    );
  }
}

const mapStateToProps = state => {
  return { authState: state.auth, globalState: state.global };
};

const mapDispatchToProps = dispatch => {
  return {
    getAppointment: appId => dispatch(getAppointment(appId)),
    deleteAppointmentNote: id => dispatch(deleteAppointmentNote(id)),
    updateAppointmentNote: (id, updatedNote) => dispatch(updateAppointmentNote(id, updatedNote)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AppointmentNote));
