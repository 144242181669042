import React from "react";
import { Button } from "semantic-ui-react";
import ReactTable, { ReactTableDefaults } from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport } from "@fortawesome/pro-regular-svg-icons";
import { saveAs } from "file-saver";
import moment from "moment";
import "moment-duration-format";

const GeclairedColors = [
  {
    value1: 0,
    value2: 25,
    color: "red",
  },
  {
    value1: 25,
    value2: 50,
    color: "orange",
  },
  {
    value1: 50,
    value2: 80,
    color: "green",
  },
  {
    value1: 80,
    value2: Infinity,
    color: "darkgreen",
  },
];

const QCColors = [
  {
    value1: 0,
    value2: 20,
    color: "red",
  },
  {
    value1: 20,
    value2: Infinity,
    color: "darkgreen",
  },
];

const getGeclairedColor = value => {
  const item = GeclairedColors.find(item => value >= item.value1 && value <= item.value2);
  return item ? item.color : "";
};

const getQCColor = value => {
  const item = QCColors.find(item => value >= item.value1 && value <= item.value2);
  return item ? item.color : "";
};

const LocationsOverview = ({ report, t }) => {
  const data = report.report_data.map(d => JSON.parse(d));

  const exportData = async () => {
    let csv = "";
    let header = (t("location").message || "Location") + ", ";
    header += (t("wo_count").message || "WO count") + ", ";
    header += (t("wo_geclaired").message || "WO worked on") + ", ";
    header += (t("geclaired_percentage").message || "% WO worked on") + ", ";
    header += (t("check_count").message || "Check count") + ", ";
    header += (t("quality_checks_count").message || "Quality check count") + ", ";
    header += (t("quality_checks_percentage").message || "Quality Check %") + ", ";
    header += (t("avg_qc_duration").message || "AVG QC duration") + ", ";
    header += (t("receptionist_check_count").message || "Receptionist check count") + ", ";
    header += (t("receptionist_check_percentage").message || "Receptionist check %") + ", ";
    header += (t("avg_receptionist_duration").message || "AVG receptionist time") + ", ";
    header += (t("mechanic_check_count").message || "Mechanic check count") + ", ";
    header += (t("mechanic_check_percentage").message || "Mechanic check %") + ", ";
    header += (t("avg_mechanic_duration").message || "AVG mechanic time") + ", ";
    header += (t("commercial_check_count").message || "Commercial check count") + ", ";
    header += t("commercial_check_percentage").message || "Commercial check %";

    let values = data
      .map(
        d =>
          d.location +
          ", " +
          d.wo_count +
          ", " +
          d.wo_geclaired +
          ", " +
          d.geclaired_percentage +
          ", " +
          d.check_count +
          ", " +
          d.quality_checks_count +
          ", " +
          (d.quality_checks_percentage || 0) +
          ", " +
          moment.duration(d.avg_qc_duration, "seconds").format("HH:mm:ss", { trim: false }) +
          ", " +
          d.receptionist_check_count +
          ", " +
          (d.receptionist_check_percentage || 0) +
          ", " +
          moment.duration(d.avg_receptionist_duration, "seconds").format("HH:mm:ss", { trim: false }) +
          ", " +
          d.mechanic_check_count +
          ", " +
          (d.mechanic_check_percentage || 0) +
          ", " +
          moment.duration(d.avg_mechanic_duration, "seconds").format("HH:mm:ss", { trim: false }) +
          ", " +
          d.commercial_check_count +
          ", " +
          (d.commercial_check_percentage || 0)
      )
      .join("\n");

    csv += header + "\n" + values;
    saveAs(new File([csv], { type: "text/csv;charset=utf-8" }), "LocationsOverview_" + moment().format("YYYYMMDD") + ".csv");
  };

  return (
    <>
      <h3 className="report-heading">
        {t(report.report_name).message || report.report_name}
        <Button color="green" floated="right" basic onClick={() => exportData()}>
          <span style={{ marginRight: "10px" }}>{t("export").message || "Export"}</span>
          <FontAwesomeIcon icon={faFileExport} />
        </Button>
      </h3>
      <div className="dealer-reports-table-container">
        <ReactTable
          className="ReactTable -floated-table no-overflow all-data-table"
          data={data}
          showPagination={false}
          showPageSizeOptions={false}
          sortable={false}
          resizable={false}
          style={{ maxHeight: "600px" }}
          defaultPageSize={data?.length || 0}
          pageSize={data?.length || 0}
          noDataText={
            <div className="Table__no-results">
              <p>{t("no_data").message || "No data"}</p>
            </div>
          }
          column={{
            ...ReactTableDefaults.column,
            headerClassName: "ReactTable__column-header dealer-table-header",
            className: "ReactTable__column",
          }}
          columns={[
            {
              Header: <strong>{t("location").message || "Location"}</strong>,
              accessor: "location",
            },
            {
              Header: <strong>{t("wo_count").message || "WO count"}</strong>,
              accessor: "wo_count",
            },
            {
              Header: <strong>{t("wo_geclaired").message || "WO worked on"}</strong>,
              accessor: "wo_geclaired",
            },
            {
              Header: <strong>{t("geclaired_percentage").message || "% WO worked on"}</strong>,
              accessor: "geclaired_percentage",
              Cell: cell => <span style={{ color: getGeclairedColor(cell.value) }}>{cell.value}%</span>,
            },
            {
              Header: <strong>{t("check_count").message || "Car check count"}</strong>,
              accessor: "check_count",
            },
            {
              Header: <strong>{t("quality_checks").message || "Quality check count"}</strong>,
              accessor: "quality_checks_count",
            },
            {
              Header: <strong>{t("quality_checks_percentage").message || "Quality check %"}</strong>,
              accessor: "quality_checks_percentage",
              Cell: cell => <span style={{ color: getQCColor(cell.value) }}>{cell.value || 0}%</span>,
            },
            {
              Header: <strong>{t("avg_qc_duration").message || "AVG QC duration"}</strong>,
              accessor: "avg_qc_duration",
              Cell: cell => moment.duration(cell.value, "seconds").format("HH:mm:ss", { trim: false }),
            },
            {
              Header: <strong>{t("receptionist_check_count").message || "Receptionist check count"}</strong>,
              accessor: "receptionist_check_count",
            },
            {
              Header: <strong>{t("receptionist_check_percentage").message || "Receptionist check %"}</strong>,
              accessor: "receptionist_check_percentage",
              Cell: cell => (cell.value || 0) + "%",
            },
            {
              Header: <strong>{t("avg_receptionist_duration").message || "AVG receptionist time"}</strong>,
              accessor: "avg_receptionist_duration",
              Cell: cell => moment.duration(cell.value, "seconds").format("HH:mm:ss", { trim: false }),
            },
            {
              Header: <strong>{t("mechanic_check_count").message || "Mechanic check count"}</strong>,
              accessor: "mechanic_check_count",
            },
            {
              Header: <strong>{t("mechanic_check_percentage").message || "Mechanic check %"}</strong>,
              accessor: "mechanic_check_percentage",
              Cell: cell => (cell.value || 0) + "%",
            },
            {
              Header: <strong>{t("avg_mechanic_duration").message || "AVG mechanic time"}</strong>,
              accessor: "avg_mechanic_duration",
              Cell: cell => moment.duration(cell.value, "seconds").format("HH:mm:ss", { trim: false }),
            },
            {
              Header: <strong>{t("commercial_check_count").message || "Commercial check count"}</strong>,
              accessor: "commercial_check_count",
            },
            {
              Header: <strong>{t("commercial_check_percentage").message || "% commercial check"}</strong>,
              accessor: "commercial_check_percentage",
              Cell: cell => (cell.value || 0) + "%",
            },
          ]}
        />
      </div>
    </>
  );
};

export { LocationsOverview };
