const ROLES = {
  SUPER_ADMIN: 10,
  CLAIRE_ADMIN: 20,
  SUPPORT_ADMIN: 25,
  CONSULTANT: 30,
  DEALER_ADMIN: 40,
  RECEPTIONIST: 50,
  MECHANIC: 60,
  CALL_CENTER: 70,
  SUPERVISOR: 80,
  WARRANTY_MANAGER: 90,
  COUNTER_TABLET: 100,
  MANUFACTURER: 110,
};

const EnforceUserLocations = (user, locations) => {
  let showCustomLocations = [ROLES.CALL_CENTER, ROLES.WARRANTY_MANAGER].includes(user.role_id);

  if (showCustomLocations) {
    if (user.my_locations?.length > 0) {
      return { showCustomLocations, locations: locations.filter(l => l.active && (l.id === user.dealer_location_id || user.my_locations.includes(l.id))) };
    } else if (user.location_ids?.length > 0) {
      return { showCustomLocations, locations: locations.filter(l => l.active && (l.id === user.dealer_location_id || user.location_ids.includes(l.id))) };
    }
  }

  return { showCustomLocations, locations: locations.filter(l => l.active) };
};

export { ROLES, EnforceUserLocations };
