import axios from "axios";

import ENV from "../../util/env-config";
import { getSessionCookie } from "../../util/common";

class Service {
  static requestConfig() {
    return {
      headers: {
        Authorization: `Bearer ${getSessionCookie()}`,
        Pragma: "no-cache",
      },
    };
  }

  static getBrands() {
    return axios.get(ENV.apiBaseB + "/brands/list", this.requestConfig());
  }

  static deleteBrand(requestData) {
    return axios.delete(ENV.apiBaseB + "/brands/delete", { data: requestData, ...this.requestConfig() });
  }

  static createBrand(requestData) {
    return axios.post(ENV.apiBaseB + "/brands/create", requestData, this.requestConfig());
  }

  static updateBrand(requestData) {
    return axios.put(ENV.apiBaseB + "/brands/update", requestData, this.requestConfig());
  }

  static uploadBrandLogo(requestData) {
    return axios.post(ENV.apiBaseB + "/files/upload_brand_logo", requestData, this.requestConfig());
  }
}

export default Service;
