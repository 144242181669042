import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import ReactTable, { ReactTableDefaults } from "react-table";
import { connect } from "react-redux";
import { Button, Checkbox, Dropdown, Grid, Icon, Label, Popup, Search } from "semantic-ui-react";
import AlertContainer from "react-alert";
import moment from "moment";
import _ from "lodash";

import { ROLES } from "../Users/roles";
import DocumentationModal from "./DocumentationModal";
import DealerLocationsDropdown from "../../components/DealerLocationsDropdown";
import { SearchPortal, SubHeader, UserMenuActionsPortal } from "../../components";
import { isAdmin } from "../../util/common";
import Can from "../Can";

import Service from "./service";

import "./index.scss";

export const DOCUMENTATION_CATEGORIES = {
  WARRANTY: 1,
  TECHNICAL: 2,
  COSMETIC: 3,
  COMMERCIAL: 4,
  INTERNAL: 5,
  EXTERNAL: 6,
  MANUAL: 7,
};

const defaultDocumentation = {
  title: "",
  content: "",
  publish_date: moment(new Date()).toDate(),
  url: null,
  is_pinned: false,
  visible_to_mechanic: false,
  visible_to_all: false,
  category_id: DOCUMENTATION_CATEGORIES.MANUAL,
  dealer_ids: [],
  dealer_location_ids: [],
};

class DealerDocumentations extends Component {
  state = {
    dealer_ids: this.props.authState.user.role_id === ROLES.MECHANIC ? [] : [this.props.globalState.selectedDealer.id],
    dealer_location_ids: this.props.authState.user.role_id === ROLES.MECHANIC ? [this.props.authState.user.dealer_location_id] : [],
    modalDocumentation: null,
    category_id: null,
    visible_to_mechanic: false,
    documentations: [],
    originalDocumentations: [],
    searchTerm: "",
    isLoading: false,
    isSavingDocumentation: false,
    isDeletingDocumentation: false,
    showDeleteConfirmation: false,
    deleteError: "",
    modalErrorMsg: "",
  };

  msg = React.createRef();

  categoryOptions = Object.keys(DOCUMENTATION_CATEGORIES).map(key => ({
    key,
    text: this.props.t(key.toLowerCase()).message || key.toLowerCase(),
    value: DOCUMENTATION_CATEGORIES[key],
  }));

  componentDidMount() {
    this.getDocumentations();
  }

  getDocumentations = () => {
    const { dealer_ids, dealer_location_ids, visible_to_mechanic, category_id } = this.state;

    this.setState({ isLoading: true }, async () => {
      try {
        const requestData = {
          dealer_ids,
          dealer_location_ids,
          visible_to_mechanic,
          category_id,
        };

        const response = await Service.getDocumentations(requestData);
        const documentations = response?.data?.data?.documentations || [];

        const sortedDocumentations = this.sortDocumentation(documentations);

        this.setState({ originalDocumentations: sortedDocumentations, documentations: sortedDocumentations, isLoading: false });
      } catch (error) {
        console.log(error);
        const errorMsg =
          typeof error === "string"
            ? error
            : error.message
            ? error.message
            : error.response?.data?.errors?.length
            ? error.response.data.errors[0]
            : this.props.t("error_getting_documentations").message || "Error getting documentations";
        this.msg.show(errorMsg, { type: "error" });
        this.setState({ isLoading: false });
      }
    });
  };

  getDealerNamesById = (dealer_ids, location_ids) => {
    const matchedDealers = this.props.globalState.dealers?.filter(d => dealer_ids?.includes(d.id));

    location_ids?.length &&
      location_ids.forEach(id => {
        const dealer = this.props.globalState.dealers.find(d => d.locations?.some(l => l.id === id));
        if (dealer) matchedDealers.push(dealer);
      });

    if (matchedDealers?.length) {
      const dealerNames = [...new Set(matchedDealers.map(d => d.name))];
      return dealerNames.join(", ");
    }

    return this.props.t("all_dealers").message || "All dealers";
  };

  getLocationNamesById = ids => {
    const matchedLocations = [];

    this.props.globalState.dealers.forEach(dealer => {
      dealer.locations &&
        dealer.locations.forEach(location => {
          if (ids?.includes(location.id)) {
            matchedLocations.push(location.name);
          }
        });
    });

    return matchedLocations.length > 0 ? matchedLocations.join(", ") : this.props.t("all_locations").message || "All locations";
  };

  getCategoryName = category_id => {
    const categoryName = Object.keys(DOCUMENTATION_CATEGORIES).find(key => DOCUMENTATION_CATEGORIES[key] === category_id);

    return this.props.t(categoryName).message || categoryName ? categoryName.toLowerCase() : null;
  };

  filterDocumentations = () => {
    const { originalDocumentations, searchTerm, category_id } = this.state;

    let filteredDocumentations = [...originalDocumentations];

    if (searchTerm)
      filteredDocumentations = filteredDocumentations.filter(d => d.title.toLowerCase().includes(searchTerm.toLowerCase()) || d.category_id === category_id);

    if (category_id) filteredDocumentations = filteredDocumentations.filter(d => d.category_id === category_id);

    this.setState({ documentations: filteredDocumentations });
  };

  handleSearchChange = (_e, data) => {
    this.setState({ searchTerm: data.value }, _.debounce(this.filterDocumentations, 500));
  };

  handleCheckboxChange = (_e, { name, checked }) => {
    this.setState({ [name]: checked }, () => {
      this.getDocumentations();
    });
  };

  handleSelectDealerLocation = ({ dealer_ids, location_ids }) => {
    this.setState({ dealer_ids, dealer_location_ids: location_ids }, () => {
      (this.state.dealer_ids.length || this.state.dealer_location_ids.length) && this.getDocumentations();
    });
  };

  handleSelectCategory = (_e, { value }) => {
    this.setState({ category_id: value }, () => {
      this.filterDocumentations();
    });
  };

  handleShowEditDocumentation = (evt, documentation) => {
    evt.stopPropagation();

    this.setState({ modalDocumentation: { ...documentation, dealer_location_ids: documentation.dealer_location_ids ? [...documentation.dealer_location_ids] : [] } });
  };

  handleReadEditDocumentation = documentation => {
    this.setState({ modalDocumentation: { ...documentation, read_only: true } });
  };

  handleCloseEditDocumentation = () => {
    this.setState({ modalDocumentation: null, validationError: false });
  };

  getIsDocumentationInDealersAndLocationFilterScope = documentation => {
    const { dealer_ids, dealer_location_ids } = this.state;

    if (documentation.visible_to_all || (!documentation.dealer_ids.length && !documentation.dealer_location_ids.length)) return true;

    const inScope = documentation.dealer_ids.some(id => dealer_ids.includes(id)) || documentation.dealer_location_ids.some(id => dealer_location_ids.includes(id));
    if (inScope) return inScope;

    const selectedDeaelrs = this.props.globalState.dealers.filter(d => dealer_ids.includes(d.id));

    return selectedDeaelrs.some(d => d.locations?.some(l => documentation.dealer_location_ids.includes(l.id)));
  };

  sortDocumentation = documentations => {
    return documentations.sort((a, b) => {
      if (a.is_pinned && !b.is_pinned) return -1;
      if (!a.is_pinned && b.is_pinned) return 1;

      return (b.publish_date ? new Date(b.publish_date) : 0) - (a.publish_date ? new Date(a.publish_date) : 0);
    });
  };

  onAddDocumentation = documentation => {
    const { title, category_id, content, dealer_ids, dealer_location_ids, visible_to_all } = documentation;
    const { originalDocumentations, documentations } = this.state;

    if (!title || !category_id || !content || (!dealer_ids.length && !dealer_location_ids.length && !visible_to_all)) {
      this.setState({ validationError: true });
      return;
    }

    this.setState({ isSavingDocumentation: true, validationError: false, modalErrorMsg: "" }, async () => {
      try {
        const response = await Service.createDocumentation(documentation);

        documentation.id = response?.data?.data?.dealer_documentation_id;

        const shouldDisplayDocumentation = this.getIsDocumentationInDealersAndLocationFilterScope(documentation);

        this.setState({
          originalDocumentations: shouldDisplayDocumentation
            ? this.sortDocumentation([{ ...documentation, created_by: this.props.authState.user }, ...originalDocumentations])
            : originalDocumentations,
          documentations: shouldDisplayDocumentation
            ? this.sortDocumentation([{ ...documentation, created_by: this.props.authState.user }, ...documentations])
            : documentations,
          isSavingDocumentation: false,
          modalDocumentation: null,
        });
      } catch (err) {
        console.error(err);
        const errorMsg =
          typeof err === "string"
            ? err
            : err.message
            ? err.message
            : err.response?.data?.errors?.length
            ? err.response.data.errors[0]
            : this.props.t("error_adding_documentation").message || "Error updating documentations";

        this.setState({ isSavingDocumentation: false, modalErrorMsg: errorMsg });
      }
    });
  };

  onUpdateDocumentation = documentation => {
    const { authState } = this.props;
    const { title, category_id, content, dealer_ids, dealer_location_ids, visible_to_all } = documentation;

    if (!title || !category_id || !content || (!dealer_ids.length && !dealer_location_ids.length && !visible_to_all)) {
      this.setState({ validationError: true });
      return;
    }

    this.setState({ isSavingDocumentation: true, validationError: false, modalErrorMsg: "" }, async () => {
      try {
        const { created_by, ...documentationRequest } = documentation;
        await Service.updateDocumentation(documentationRequest);

        documentation = {
          ...documentation,
          updated_by: {
            first_name: authState.user.first_name,
            last_name: authState.user.last_name,
          },
          updated_on: new Date(),
        };

        this.setState({
          originalDocumentations: this.sortDocumentation(this.state.originalDocumentations.map(d => (documentation.id === d.id ? documentation : d))),
          documentations: this.sortDocumentation(this.state.documentations.map(d => (documentation.id === d.id ? documentation : d))),
          isSavingDocumentation: false,
          modalDocumentation: null,
        });
      } catch (err) {
        console.error(err);
        const errorMsg =
          typeof err === "string"
            ? err
            : err.message
            ? err.message
            : err.response?.data?.errors?.length
            ? err.response.data.errors[0]
            : this.props.t("error_updating_documentation").message || "Error updating documentation";

        this.setState({ isSavingDocumentation: false, modalErrorMsg: errorMsg });
      }
    });
  };

  onDeleteDocumentation = () => {
    const { modalDocumentation } = this.state;

    this.setState({ isDeletingDocumentation: true, deleteError: "" }, async () => {
      try {
        await Service.deleteDocumentation({ id: modalDocumentation.id });

        this.setState({
          documentations: this.state.documentations.filter(d => d.id !== modalDocumentation.id),
          originalDocumentations: this.state.originalDocumentations.filter(d => d.id !== modalDocumentation.id),
          isDeletingDocumentation: false,
          modalDocumentation: null,
        });
      } catch (err) {
        console.error(err);
        const deleteError =
          typeof err === "string"
            ? err
            : err.message
            ? err.message
            : err.response?.data?.errors?.length
            ? err.response.data.errors[0]
            : this.props.t("error_deleting_documentation").message || "Error deleting documentation";

        this.setState({ isDeletingDocumentation: false, deleteError });
      }
    });
  };

  toggleDeleteConfirmation = () => {
    this.setState(prevState => ({ showDeleteConfirmation: !prevState.showDeleteConfirmation, deleteError: "" }));
  };

  renderAlert = () => {
    const props = {
      offset: 20,
      position: "top right",
      theme: "light",
      transition: "fade",
    };

    return <AlertContainer ref={a => (this.msg = a)} {...props} />;
  };

  renderTable = () => {
    const { t } = this.props;
    const { isLoading, documentations } = this.state;

    if (isLoading) {
      return (
        <div className="Table__loading Loader-Placeholder" style={{ minHeight: "100vh" }}>
          <div className="bounce1"></div>
          <div className="bounce2"></div>
          <div className="bounce3"></div>
          <section>{t("loading").message || "Loading"}</section>
        </div>
      );
    }

    return (
      <ReactTable
        data={documentations}
        pageSize={documentations.length}
        className="ReactTable -floated-table -contained-large"
        sortable={false}
        resizable={false}
        minRows={0}
        filterable={false}
        showPagination={false}
        getTdProps={(_, rowInfo) => {
          return {
            onClick: e => {
              e.preventDefault();
              this.handleReadEditDocumentation(rowInfo.original);
            },
          };
        }}
        noDataText={
          <div className="Table__no-results">
            <p>{t("no_data").message || "No data"}</p>
          </div>
        }
        column={{
          ...ReactTableDefaults.column,
          headerClassName: "ReactTable__column-header",
          className: "ReactTable__column",
        }}
        columns={[
          {
            id: "is_pinned",
            maxWidth: 20,
            accessor: d => (d.is_pinned ? <Icon name="pin" /> : <Icon name="circle outline" />),
          },
          {
            id: "title",
            Header: t("title").message || "Title",
            accessor: "title",
          },
          ...(isAdmin(this.props.authState.user.role_id)
            ? [
                {
                  id: "dealers",
                  Header: t("dealer").message || "Dealer",
                  accessor: d => {
                    const dealerNames = this.getDealerNamesById(d.dealer_ids, d.dealer_location_ids);

                    return (
                      <Popup
                        trigger={<span>{dealerNames}</span>}
                        content={dealerNames}
                        popperModifiers={{
                          flip: {
                            behavior: ["bottom"],
                          },
                          preventOverflow: {
                            enabled: false,
                          },
                          hide: {
                            enabled: false,
                          },
                        }}
                      />
                    );
                  },
                },
              ]
            : []),
          {
            id: "locations",
            Header: t("location").message || "Location",
            accessor: d => {
              const locationNames = this.getLocationNamesById(d.dealer_location_ids);

              return (
                <Popup
                  trigger={<span>{locationNames}</span>}
                  content={locationNames}
                  popperModifiers={{
                    flip: {
                      behavior: ["bottom"],
                    },
                    preventOverflow: {
                      enabled: false,
                    },
                    hide: {
                      enabled: false,
                    },
                  }}
                />
              );
            },
          },
          {
            id: "publish_date",
            Header: t("publish_date").message || "Publish date",
            accessor: d => <span>{d.publish_date && moment(d.publish_date).format("DD-MM-YYYY")}</span>,
          },
          {
            id: "category_id",
            Header: t("category").message || "Category",
            accessor: d => <Label basic>{this.getCategoryName(d.category_id)}</Label>,
          },
          {
            id: "published_by",
            Header: t("published_by").message || "Published by",
            accessor: d =>
              d.updated_by ? `${d.updated_by.first_name} ${d.updated_by.last_name}` : d.created_by ? `${d.created_by.first_name} ${d.created_by.last_name}` : null,
          },
          {
            id: "edit_button",
            accessor: d => {
              const canEdit =
                d.publish_date === null || moment(d.publish_date).isAfter(moment().endOf("day"))
                  ? this.props.authState.user.id === d.created_by.id
                  : this.props.authState.user.role_id <= d.created_by.role_id;

              if (!canEdit) return null;

              return (
                <Can I="update" the="dealer-documentation">
                  <Button className="edit-button" icon="edit" color="green" onClick={evt => this.handleShowEditDocumentation(evt, d)} style={{ margin: "0px" }} />
                </Can>
              );
            },
          },
        ]}
      />
    );
  };

  render() {
    const { modalDocumentation, searchTerm, dealer_ids, dealer_location_ids, visible_to_mechanic, isDeletingDocumentation, validationError, modalErrorMsg, deleteError } =
      this.state;
    const { t, authState } = this.props;

    return (
      <div className="DealerDocumentationsPage">
        <UserMenuActionsPortal>
          <Icon name="refresh" onClick={this.getDocumentations} />
        </UserMenuActionsPortal>
        <SearchPortal>
          <Search
            minCharacters={4}
            className="-large-search"
            input={{
              icon: "search",
              iconPosition: "left",
              placeholder: t("search").message || "Search",
            }}
            loading={false}
            showNoResults={false}
            onSearchChange={this.handleSearchChange}
            value={searchTerm}
            fluid
          />
        </SearchPortal>

        <SubHeader>
          <Grid.Column width={6} className="header-container">
            <h1>{t("Documentations").message || "Documentations"}</h1>
          </Grid.Column>

          <Grid.Column width={10}>
            <Can I="create" the="dealer-documentation">
              <Button
                className="add-button"
                icon
                labelPosition="left"
                floated="right"
                color="green"
                onClick={evt => this.handleShowEditDocumentation(evt, defaultDocumentation)}
              >
                <Icon name="plus" />
                {t("add").message || "Add"}
              </Button>
            </Can>
          </Grid.Column>
          <Grid className="DealerDocumentationsPage-header">
            <Grid.Column width={6}>
              <DealerLocationsDropdown options={{ dealer_ids, location_ids: dealer_location_ids }} selectDealers onChange={this.handleSelectDealerLocation} />
            </Grid.Column>

            <Grid.Column width={3}>
              <Dropdown
                fluid
                selection
                clearable
                selectOnBlur={false}
                placeholder={t("select_category").message || "Select category"}
                name="category_id"
                onChange={this.handleSelectCategory}
                options={this.categoryOptions}
              />
            </Grid.Column>

            <Grid.Column width={3}>
              <Checkbox
                toggle
                label={t("visible_to_mechanic").message || "Visible to mechanic"}
                checked={visible_to_mechanic}
                onClick={this.handleCheckboxChange}
                name="visible_to_mechanic"
              />
            </Grid.Column>
          </Grid>
        </SubHeader>

        <div className="DealerDocumentationsPage-content">{this.renderTable()}</div>

        {modalDocumentation && (
          <DocumentationModal
            documentation={modalDocumentation}
            onAddDocumentation={this.onAddDocumentation}
            onDeleteDocumentation={this.onDeleteDocumentation}
            onUpdateDocumentation={this.onUpdateDocumentation}
            onClose={this.handleCloseEditDocumentation}
            categoryOptions={this.categoryOptions}
            isDeletingDocumentation={isDeletingDocumentation}
            validationError={validationError}
            user={authState.user}
            modalErrorMsg={modalErrorMsg}
            deleteError={deleteError}
          />
        )}

        {this.renderAlert()}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { globalState: state.global, authState: state.auth };
};

export default withTranslation()(connect(mapStateToProps)(DealerDocumentations));
