import React from "react";
import { Button } from "semantic-ui-react";
import ReactTable, { ReactTableDefaults } from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport } from "@fortawesome/pro-regular-svg-icons";
import { saveAs } from "file-saver";
import moment from "moment";

const LocationCanceledAppointments = ({ report, t }) => {
  if (!Array.isArray(report.report_data) || report.report_data.length < 1) {
    return (
      <>
        <h3 className="report-heading">{t(report.report_name).message || report.report_name}</h3>
        <p className="no-data">{t("no_data").message || "No data"}</p>
      </>
    );
  }

  const data = report.report_data.map(d => JSON.parse(d));

  const exportData = async () => {
    let csv = "";
    let header = (t("location").message || "Location") + ", ";
    header += (t("wo_count").message || "WO Count") + ", ";
    header += (t("canceled_count").message || "Canceled count (WO %)") + ", ";

    const reasons = [];
    for (const column in data[0]) if (column.startsWith("cancel_reason_")) reasons[column] = column;

    for (const r in reasons) header += (t(reasons[r]).message || reasons[r]) + ", ";

    header += t("other_reasons").message || "Other reasons";

    let values = data
      .map(d => {
        let v = d.location + ", " + d.wo_count + ", " + d.canceled_count + ", ";
        for (const r in reasons) v += (d[r] || 0) + ", ";
        v += d.custom_reason_other || 0;
        return v;
      })
      .join("\n");

    csv += header + "\n" + values;
    saveAs(new File([csv], { type: "text/csv;charset=utf-8" }), "LocationCanceledAppointments" + moment().format("YYYYMMDD") + ".csv");
  };

  const reasons = [];
  for (const column in data[0]) {
    if (column.startsWith("cancel_reason_"))
      reasons[column] = {
        Header: <strong>{t(column).message || column}</strong>,
        accessor: column,
        Cell: cell => cell.value || 0,
      };
  }

  const unique_reasons = [];
  for (const r in reasons) unique_reasons.push(reasons[r]);

  return (
    <>
      <h3 className="report-heading">
        {t(report.report_name).message || report.report_name}
        <Button color="green" floated="right" basic onClick={() => exportData()}>
          <span style={{ marginRight: "10px" }}>{t("export").message || "Export"}</span>
          <FontAwesomeIcon icon={faFileExport} />
        </Button>
      </h3>
      <div className="dealer-reports-table-container">
        <ReactTable
          className="ReactTable -floated-table no-overflow all-data-table -initial-margin"
          data={data}
          showPagination={false}
          showPageSizeOptions={false}
          sortable={false}
          style={{ maxHeight: "600px" }}
          resizable={false}
          defaultPageSize={data.length || 0}
          pageSize={data.length || 0}
          noDataText={
            <div className="Table__no-results">
              <p>{t("no_data").message || "No data"}</p>
            </div>
          }
          column={{
            ...ReactTableDefaults.column,
            headerClassName: "ReactTable__column-header dealer-table-header",
            className: "ReactTable__column",
          }}
          columns={[
            {
              Header: <strong>{t("location").message || "Location"}</strong>,
              accessor: "location",
            },
            {
              Header: <strong>{t("wo_count").message || "WO Count"}</strong>,
              accessor: "wo_count",
            },
            {
              Header: <strong>{t("canceled_count").message || "Canceled Count (WO %)"}</strong>,
              accessor: "canceled_count",
            },
            ...unique_reasons,
            {
              Header: <strong>{t("other_reasons").message || "Other reasons"}</strong>,
              accessor: "custom_reason_other",
              Cell: cell => cell.value || 0,
            },
          ]}
        />
      </div>
    </>
  );
};

export { LocationCanceledAppointments };
