import React from "react";
import { Button } from "semantic-ui-react";
import ReactTable, { ReactTableDefaults } from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport } from "@fortawesome/pro-regular-svg-icons";
import { saveAs } from "file-saver";
import moment from "moment";

import { round2 } from "../../../util/common";

const SnoozedItems = ({ report, t }) => {
  const data = report.report_data.map(d => JSON.parse(d));

  let total = {
    location: "Total",
    wo_count: 0,
    next_visit_customer: 0,
    make_offer: 0,
    make_new_appointment: 0,
    remind_customer: 0,
    snooze_total: 0,
    next_visit_customer_percent: 0,
    make_offer_percent: 0,
    make_new_appointment_percent: 0,
    remind_customer_percent: 0,
  };

  const l = data.length;
  if (l > 0) {
    const addToTotal = (acc, cur) => {
      acc.wo_count += cur.wo_count || 0;
      acc.next_visit_customer += cur.next_visit_customer || 0;
      acc.make_offer += cur.make_offer || 0;
      acc.make_new_appointment += cur.make_new_appointment || 0;
      acc.remind_customer += cur.remind_customer || 0;
      acc.snooze_total += cur.snooze_total || 0;
      return acc;
    };

    total = data.reduce((acc, cur) => addToTotal(acc, cur), total);
    total.next_visit_customer_percent = round2((total.next_visit_customer / total.snooze_total) * 100);
    total.make_offer_percent = round2((total.make_offer / total.snooze_total) * 100);
    total.make_new_appointment_percent = round2((total.make_new_appointment / total.snooze_total) * 100);
    total.remind_customer_percent = round2((total.remind_customer / total.snooze_total) * 100);
  }

  data.push(total);

  const exportData = async () => {
    let csv = "";
    let header = (t("location").message || "Location") + ", ";
    header += (t("wo_count").message || "WO count") + ", ";
    header += (t("snooze_total").message || "Snooze count") + ", ";
    header += (t("next_visit_customer").message || "Next visit customer") + ", ";
    header += (t("make_offer").message || "Make offer") + ", ";
    header += (t("make_new_appointment").message || "Make new appointment") + ", ";
    header += t("remind_customer").message || "Remind customer";

    let values = data
      .map(
        d =>
          d.location +
          ", " +
          d.wo_count +
          ", " +
          (d.snooze_total || 0) +
          ", " +
          (d.next_visit_customer || 0) +
          " (" +
          (d.next_visit_customer_percent || 0) +
          "%)" +
          ", " +
          (d.make_offer || 0) +
          " (" +
          (d.make_offer_percent || 0) +
          "%)" +
          ", " +
          (d.make_new_appointment || 0) +
          " (" +
          (d.make_new_appointment_percent || 0) +
          "%)" +
          ", " +
          (d.remind_customer || 0) +
          " (" +
          (d.remind_customer_percent || 0) +
          "%)"
      )
      .join("\n");

    csv += header + "\n" + values;
    saveAs(new File([csv], { type: "text/csv;charset=utf-8" }), "SnoozedItems_" + moment().format("YYYYMMDD") + ".csv");
  };

  return (
    <>
      <h3 className="report-heading">
        {t(report.report_name).message || report.report_name}
        <Button color="green" floated="right" basic onClick={() => exportData()}>
          <span style={{ marginRight: "10px" }}>{t("export").message || "Export"}</span>
          <FontAwesomeIcon icon={faFileExport} />
        </Button>
      </h3>
      <div className="dealer-reports-table-container">
        <ReactTable
          className="ReactTable -floated-table no-overflow all-data-table"
          data={data}
          showPagination={false}
          showPageSizeOptions={false}
          sortable={false}
          resizable={false}
          style={{ maxHeight: "600px" }}
          defaultPageSize={data?.length || 0}
          pageSize={data?.length || 0}
          noDataText={
            <div className="Table__no-results">
              <p>{t("no_data").message || "No data"}</p>
            </div>
          }
          column={{
            ...ReactTableDefaults.column,
            headerClassName: "ReactTable__column-header dealer-table-header",
            className: "ReactTable__column",
          }}
          columns={[
            {
              Header: <strong>{t("location").message || "Location"}</strong>,
              accessor: "location",
            },
            {
              Header: <strong>{t("wo_count").message || "WO count"}</strong>,
              accessor: "wo_count",
            },
            {
              Header: <strong>{t("snooze_total").message || "Snooze count"}</strong>,
              accessor: "snooze_total",
              Cell: cell => cell.value || 0,
            },
            {
              Header: <strong>{t("next_visit_customer").message || "Next visit customer"}</strong>,
              id: "next_visit_customer",
              accessor: d => (d.next_visit_customer || 0) + " (" + (d.next_visit_customer_percent || 0) + "%)",
            },
            {
              Header: <strong>{t("make_offer").message || "Make offer"}</strong>,
              id: "make_offer",
              accessor: d => (d.make_offer || 0) + " (" + (d.make_offer_percent || 0) + "%)",
            },
            {
              Header: <strong>{t("make_new_appointment").message || "Make new appointment"}</strong>,
              id: "make_new_appointment",
              accessor: d => (d.make_new_appointment || 0) + " (" + (d.make_new_appointment_percent || 0) + "%)",
            },
            {
              Header: <strong>{t("remind_customer").message || "Remind customer"}</strong>,
              id: "remind_customer",
              accessor: d => (d.remind_customer || 0) + " (" + (d.remind_customer_percent || 0) + "%)",
            },
          ]}
        />
      </div>
    </>
  );
};

export { SnoozedItems };
