import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Form, Checkbox, Icon } from "semantic-ui-react";
import AlertContainer from "react-alert";

import { UserInput } from "../../components";
import { copyToClipboard } from "../../util/common";
import ENV from "./../../util/env-config";

class Configuration extends Component {
  handleCheckboxChange = (e, { name, checked }) => {
    const { dealer, handleChange } = this.props;

    handleChange({ ...dealer, [name]: checked });
  };

  handleCopyKey = url => {
    copyToClipboard(url);
    this.msg.show(this.props.t("car_check_export_link_copied").message || "Car check export link copied to clipboard", { type: "success" });
  };

  renderAlert = () => {
    const alertOptions = {
      position: "top right",
      theme: "light",
      time: 3000,
    };
    return (
      <div className="dealer-admin-configuration-alert-container">
        <AlertContainer ref={a => (this.msg = a)} {...alertOptions} />
      </div>
    );
  };

  render() {
    const { dealer, t } = this.props;

    return (
      <div>
        <Form>
          <Form.Field className="full-width-input">
            <label>{t("can_add_users").message || "Can add users"}</label>
            <Checkbox
              defaultChecked={dealer.can_add_users}
              name="can_add_users"
              label={t("can_add_users").message || "Can add users"}
              onChange={this.handleCheckboxChange}
            />
          </Form.Field>

          <Form.Field className="full-width-input">
            <label>DBB</label>
            <Checkbox defaultChecked={dealer.dbb_enabled} name="dbb_enabled" label="DBB" onChange={this.handleCheckboxChange} />
          </Form.Field>

          <Form.Field className="full-width-input">
            <label>{t("custom_domain").message || "Custom Domain"}</label>
            <Checkbox
              defaultChecked={dealer.custom_domain_enabled}
              name="custom_domain_enabled"
              label={t("custom_domain").message || "Custom Domain"}
              onChange={this.handleCheckboxChange}
            />
          </Form.Field>

          <Form.Field className="full-width-input">
            <label>{t("car_return_time").message || "Car Return Time"}</label>
            <Checkbox
              defaultChecked={dealer.enable_car_ready}
              name="enable_car_ready"
              label={t("car_return_time").message || "Car Return Time"}
              onChange={this.handleCheckboxChange}
            />
          </Form.Field>

          <Form.Field className="full-width-input">
            <label>{t("advised_critical_history").message || "Advised and critical history"}</label>
            <Checkbox
              defaultChecked={dealer.advised_critical_history_enabled}
              name="advised_critical_history_enabled"
              label={t("advised_critical_history").message || "Advised and critical history"}
              onChange={this.handleCheckboxChange}
            />
          </Form.Field>
          <div className="dealer-admin-configuration-container">
            <Form.Field className="full-width-input">
              <label>{t("daily_car_checks_export").message || "Daily car checks export"}</label>
              <Checkbox
                defaultChecked={dealer.daily_check_export_enabled}
                name="daily_check_export_enabled"
                label={t("daily_car_checks_export").message || "Daily car checks export"}
                onChange={this.handleCheckboxChange}
              />
            </Form.Field>

            {dealer.daily_check_export_enabled && (
              <Form.Field
                className="full-width-input -cursor-pointer"
                onClick={() => {
                  this.handleCopyKey(ENV.storageDailyCheckExport + dealer["daily_check_export_key"]);
                }}
              >
                <Icon name="copy outline"></Icon>
                <UserInput value={ENV.storageDailyCheckExport + dealer["daily_check_export_key"]} disabled className="daily-check-link-input-field" />
              </Form.Field>
            )}

            {this.renderAlert()}
          </div>
        </Form>
      </div>
    );
  }
}

export default withTranslation()(Configuration);
