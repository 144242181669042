import React, { Component } from "react";
import { Button, Form, Icon, List, Label } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import { SortableContainer, SortableElement } from "react-sortable-hoc";

import { UserInput } from "../../../components";

class Dropdown extends Component {
  constructor(props) {
    super(props);

    let label = "";
    let items = [];

    const { default_configuration } = this.props;

    if (default_configuration) {
      label = default_configuration.label || "";
      items = default_configuration.items || [];
    }

    this.state = {
      label,
      items,
      itemName: "",
    };
  }

  handleLabelInput = e => {
    this.setState({ label: e.target.value }, () => {
      this.props.handleQuestionElementUpdate({ label: this.state.label, items: this.state.items });
    });
  };

  handleNameInput = e => {
    this.setState({ itemName: e.target.value });
  };

  handleAppendItem = () => {
    let name = this.state.itemName;

    if (name !== "") {
      let { items } = this.state;
      items = items.concat({ label: name });
      this.setState({ itemName: "", items }, () => {
        this.props.handleQuestionElementUpdate({ label: this.state.label, items: this.state.items });
      });
    }
  };

  handleRemoveItem = index => {
    let items = [...this.state.items];
    items.splice(index, 1);

    this.setState({ items }, () => {
      this.props.handleQuestionElementUpdate({ label: this.state.label, items: this.state.items });
    });
  };

  onSortEnd = ({ oldIndex, newIndex }, event) => {
    if (!event) return;

    let items = [...this.state.items];

    const item = items.splice(oldIndex, 1);
    items.splice(newIndex, 0, item[0]);

    this.setState({ items }, () => {
      this.props.handleQuestionElementUpdate({ label: this.state.label, items: this.state.items });
    });
  };

  renderItems = items => {
    if (items && items.length > 0) {
      const SortableItem = SortableElement(({ offset, value }) => {
        return (
          <Label className="ListItem" size="big" color="blue">
            <Icon
              onClick={() => {
                this.handleRemoveItem(offset);
              }}
              name="delete"
            />{" "}
            {value.label}
          </Label>
        );
      });

      const SortableList = SortableContainer(({ items }) => {
        return (
          <div>
            {items.map((item, key) => (
              <SortableItem key={`item-${key}`} index={key} offset={key} value={item} />
            ))}
          </div>
        );
      });

      return <SortableList items={items} distance={5} onSortEnd={this.onSortEnd} />;
    }
  };

  render() {
    return (
      <div>
        <Form.Field>
          <UserInput label={this.props.t("label").method || "Label"} value={this.state.label} onChange={this.handleLabelInput} />
        </Form.Field>

        <Form.Field>
          <label>{this.props.t("dropdown_items").message || "Dropdown items"}</label>
          <List>{this.renderItems(this.state.items)}</List>
          <UserInput placeholder="Item name" value={this.state.itemName} onChange={this.handleNameInput} />
          <Button fluid color="green" onClick={this.handleAppendItem}>
            <Icon name="plus" /> {this.props.t("add_item").message || "Add item"}
          </Button>
        </Form.Field>
      </div>
    );
  }
}

export default withTranslation()(Dropdown);
