import React from "react";
import { withTranslation } from "react-i18next";
import { Dropdown } from "semantic-ui-react";

const DealerDropdown = ({ className, options, value, trigger, isFluid, onChange, t }) => {
  options = options ? options.filter(option => option.detail.active) : [];
  return (
    <Dropdown
      search
      selection
      button
      className={`DealerDropdown icon -scrollable-dropdown ${className}`}
      floating
      labeled
      icon="home"
      noResultsMessage={t("no_dealers").message || "No dealers"}
      placeholder={t("select_dealer").message || "Select a dealer"}
      options={options}
      value={value}
      // trigger={trigger}
      fluid={isFluid}
      onChange={onChange}
    />
  );
};

export default withTranslation()(DealerDropdown);
