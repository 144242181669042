import React from "react";
import { Form } from "semantic-ui-react";

import { UserInput } from "../../../components";

const QuestionElementDatePicker = ({ id, configuration, configurationChanged }) => {
  return (
    <div>
      <Form.Field>
        <UserInput key={id} placeholder="Label" name="label" defaultValue={configuration.label} onChange={configurationChanged} />
      </Form.Field>
    </div>
  );
};

export default QuestionElementDatePicker;
