import React from "react";
import { withTranslation } from "react-i18next";
import Loader from "react-loader-spinner";

import { SnoozeTable } from "../../components";
import Service from "./service";

class CarDetailSnoozed extends React.Component {
  state = {
    isLoadingSnoozedItems: false,
    nb_pages: null,
    nb_items: null,
    page: 0,
    snoozedItems: [],
  };

  componentDidMount() {
    this.loadSnoozedItems();
  }

  loadSnoozedItems = page => {
    if (!page) page = 1;
    else if (page === this.state.page || this.state.isLoadingSnoozedItems) return;

    this.setState({ isLoadingSnoozedItems: true }, () => {
      Service.getSnoozesByCarId({ page, car_id: this.props.car.id })
        .then(result => {
          const data = result?.data?.data || { items: [], nb_pages: 0, nb_items: 0 };
          let { items, nb_pages, nb_items } = data;

          if (!items) items = [];

          let grouped = [];

          let interventions = [],
            questions = [],
            interventions_offsets = [],
            questions_offsets = [],
            intervention_offset = 0,
            question_offset = 0;

          items.forEach(i => {
            if (i.intervention_id) {
              if (interventions_offsets[i.intervention_id] === undefined) {
                interventions_offsets[i.intervention_id] = intervention_offset++;
                interventions[interventions_offsets[i.intervention_id]] = { ...i, history: [i] };
              } else {
                if (i.created_on > interventions[interventions_offsets[i.intervention_id]].created_on)
                  interventions[interventions_offsets[i.intervention_id]] = {
                    ...i,
                    history: interventions[interventions_offsets[i.intervention_id]].history.concat(i),
                  };
                else interventions[interventions_offsets[i.intervention_id]].history.push(i);
              }
            } else {
              if (questions_offsets[i.question_result_id] === undefined) {
                questions_offsets[i.question_result_id] = question_offset++;
                questions[questions_offsets[i.question_result_id]] = { ...i, history: [i] };
              } else {
                if (i.created_on > questions[questions_offsets[i.question_result_id]].created_on)
                  questions[questions_offsets[i.question_result_id]] = { ...i, history: questions[questions_offsets[i.question_result_id]].history.concat(i) };
                else questions[questions_offsets[i.question_result_id]].history.push(i);
              }
            }
          });

          grouped = [...interventions, ...questions];

          grouped.sort((a, b) => (b.appointment.time_car_app > a.appointment.time_car_app ? 1 : -1));

          this.setState({ isLoadingSnoozedItems: false, snoozedItems: grouped, page, nb_pages, nb_items });
        })
        .catch(error => {
          console.log("Error getting snooze options", error);
          this.setState({ isLoadingSnoozedItems: false });
        });
    });
  };

  handleSnoozeChanged = updatedSnoozeLog => {
    let { snoozedItems } = this.state;

    const updatedItem = updatedSnoozeLog[0];

    snoozedItems = snoozedItems.map(i => {
      if ((i.question_result_id && i.question_result_id === updatedItem.question_result_id) || (i.intervention_id && i.intervention_id === updatedItem.intervention_id)) {
        return { ...updatedItem, history: updatedSnoozeLog };
      }

      return i;
    });

    this.setState({ snoozedItems });
  };

  handleSnoozeDeleted = groupedItem => {
    const snoozedItems = this.state.snoozedItems.map(i => {
      if ((i.question_result_id && i.question_result_id === groupedItem.question_result_id) || (i.intervention_id && i.intervention_id === groupedItem.intervention_id)) {
        i.history = [];
        i.snooze_status_id = i.snooze_type_id = i.snooze_date = null;
        i.snooze_note = "";
        if (i.question_result) i.question_result.snoozed = false;
        else if (i.intervention) i.intervention.snoozed = false;
      }

      return i;
    });

    this.setState({ snoozedItems });
  };

  render() {
    const { t } = this.props;
    const { isLoadingSnoozedItems, nb_pages, snoozedItems, page } = this.state;

    return (
      <div className="CarDetailContainer">
        <h3>{t("snoozed_items").message || "Snoozed items"}</h3>
        {isLoadingSnoozedItems && snoozedItems.length < 1 && (
          <div className="DataLoader">
            <Loader type="Oval" color="#46923d" height="100" width="100" />
          </div>
        )}
        {!isLoadingSnoozedItems && snoozedItems.length < 1 && <div className="NoDataAvailable">{t("no_data_available").message || "No data available"}</div>}
        {snoozedItems.length > 0 && (
          <SnoozeTable
            selectedIsSnoozed
            pages={nb_pages}
            externalLink
            page={page}
            loading={isLoadingSnoozedItems}
            onFetchData={this.loadSnoozedItems}
            items={snoozedItems}
            onSnoozeChanged={this.handleSnoozeChanged}
            onSnoozeDeleted={this.handleSnoozeDeleted}
          />
        )}
      </div>
    );
  }
}

export default withTranslation()(CarDetailSnoozed);
