import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Modal, Form, Button, Label, Dropdown, Checkbox, Icon } from "semantic-ui-react";
import AlertContainer from "react-alert";
import ReactTable, { ReactTableDefaults } from "react-table";

import { ROLES } from "../Users/roles";
import { CustomConfirm } from "../../components";
import Can from "../Can";

import { getDiscounts, addDiscount, addBrandDiscount, updateDiscount, updateBrandDiscount, deleteDiscount, deleteBrandDiscount } from "./store";

import Service from "./service";

import "./index.css";

const initialDiscount = {
  id: null,
  name: "",
  discount: "",
  net_margin: "",
  active: false,
  brand: "",
  category: "",
  tyre_discount_id: null,
  tyre_brand_discounts: [],
};

class TyreTeamDiscount extends Component {
  state = {
    isAddUpdateDiscountModalOpen: false,
    isLoadingCategories: false,
    isBrand: false,
    categories: [],
    brands: [],
    isLoading: false,
    deleteDiscountErrorMsg: "",
    isDeleteConfirmOpen: false,
    isAddDiscount: false,
    selectedDiscount: null,
  };

  msg = React.createRef();

  componentDidMount() {
    this.props.getDiscounts({ dealer_id: this.props.globalState.selectedDealer.id });
    this.handleSetBrandsAndCategoriesOptions();
  }

  componentDidUpdate(prevProps) {
    if (this.props.globalState.selectedDealer.id !== prevProps.globalState.selectedDealer.id) {
      this.props.getDiscounts({ dealer_id: this.props.globalState.selectedDealer.id });
    }
  }

  handleSetBrands = () => {
    let brands = [];

    if (!this.state.brands.length)
      Service.getTiresBrands()
        .then(res => {
          if (res?.data?.data?.brands) {
            brands = res.data.data.brands.map(b => ({ text: b, key: b, value: b }));
            this.setState({ brands });
          }
        })
        .catch(error => {
          console.log(error);
          const errorMsg =
            typeof error === "string"
              ? error
              : error.message
              ? error.message
              : error.response?.data?.errors?.length
              ? error.response.data.errors[0]
              : this.props.t("error_getting_brands").message || "Error getting brands";
          this.msg.show(errorMsg, { type: "error" });
        });
  };

  handleSetCategories = () => {
    let categories = [];

    if (!this.state.categories.length)
      Service.getCategories()
        .then(res => {
          if (res?.data?.data) {
            categories = res.data.data.map(r => ({ text: this.props.t(r.name).message || r.name, key: r.id, value: r.id }));
            this.setState({ categories });
          }
        })
        .catch(error => {
          console.log(error);
          const errorMsg =
            typeof error === "string"
              ? error
              : error.message
              ? error.message
              : error.response?.data?.errors?.length
              ? error.response.data.errors[0]
              : this.props.t("error_getting_categories").message || "Error getting categories";
          this.msg.show(errorMsg, { type: "error" });
        });
  };

  handleSetBrandsAndCategoriesOptions = () => {
    if (this.state.brands.length && this.state.categories.length) return;

    this.handleSetBrands();
    this.handleSetCategories();
  };

  handleAddDiscount = () => {
    const { isBrand, selectedDiscount } = this.state;

    this.setState({ isLoading: true }, async () => {
      try {
        let response;
        const { id: dealer_id } = this.props.globalState.selectedDealer;
        const { name, discount, net_margin, active, category, brand, id: tyre_discount_id, is_brand_discount_only } = selectedDiscount;

        let payload = {
          discount: discount ? Number(discount) : null,
          net_margin: net_margin ? Number(net_margin) : null,
          active,
        };

        if (isBrand) {
          payload = { ...payload, brand, category, tyre_discount_id };
          response = await Service.addBrandDiscount(payload);
          if (response?.data?.data.tyre_brand_discount_id) {
            this.props.addBrandDiscount(tyre_discount_id, { id: response.data.data.tyre_brand_discount_id, ...payload });
          }
        } else {
          payload = { ...payload, name, dealer_id, is_brand_discount_only };
          response = await Service.addDiscount(payload);
          if (response?.data?.data?.tyre_discount_id) {
            this.props.addDiscount({ id: response.data.data.tyre_discount_id, ...payload });
          }
        }

        this.setState({ isAddUpdateDiscountModalOpen: false, isLoading: false, isBrand: false, selectedDiscount: { ...initialDiscount } });
      } catch (error) {
        console.log("Error adding new discount", error);
        this.setState(
          { isLoadingCategories: false, isAddUpdateDiscountModalOpen: false, isLoading: false, isBrand: false, selectedDiscount: { ...initialDiscount } },
          () => {
            const errorMsg =
              typeof error === "string"
                ? error
                : error.message
                ? error.message
                : error.response?.data?.errors?.length
                ? error.response.data.errors[0]
                : this.props.t("error_adding_discount").message || "Error adding discounts";
            this.msg.show(errorMsg, { type: "error" });
          }
        );
      }
    });
  };

  handleUpdateDiscount = () => {
    const { isBrand } = this.state;

    this.setState({ isLoading: true }, async () => {
      try {
        const { selectedDiscount } = this.state;
        const { discount, net_margin } = selectedDiscount;

        let payload = {
          ...selectedDiscount,
          discount: discount ? Number(discount) : null,
          net_margin: net_margin ? Number(net_margin) : null,
        };

        if (isBrand) {
          await Service.updateBrandDiscount(payload);
          this.props.updateBrandDiscount(payload);
        } else {
          await Service.updateDiscount(payload);
          this.props.updateDiscount(payload);
        }

        this.setState({ isAddUpdateDiscountModalOpen: false, isLoading: false, isBrand: false, selectedDiscount: { ...initialDiscount } });
      } catch (error) {
        console.log("Error adding new discount", error);
        this.setState(
          { isLoadingCategories: false, isAddUpdateDiscountModalOpen: false, isLoading: false, isBrand: false, selectedDiscount: { ...initialDiscount } },
          () => {
            const errorMsg =
              typeof error === "string"
                ? error
                : error.message
                ? error.message
                : error.response?.data?.errors?.length
                ? error.response.data.errors[0]
                : this.props.t("error_updating_discount").message || "Error updating discount";
            this.msg.show(errorMsg, { type: "error" });
          }
        );
      }
    });
  };

  handleDeleteDiscount = async () => {
    const { isBrand, selectedDiscount } = this.state;
    const { id, tyre_discount_id } = selectedDiscount;

    this.setState({ isDeletingDiscount: true, deleteDiscountErrorMsg: "" }, async () => {
      try {
        if (isBrand) {
          await Service.deleteBrandDiscount({ tyre_brand_discount_id: id });
          this.props.deleteBrandDiscount(tyre_discount_id, id);
        } else {
          await Service.deleteDiscount({ tyre_discount_id: id });
          this.props.deleteDiscount(id);
        }

        this.setState({ isDeletingDiscount: false, isDeleteConfirmOpen: false, isBrand: false, selectedDiscount: { ...initialDiscount } });
      } catch (error) {
        const deleteDiscountErrorMsg =
          typeof error === "string"
            ? error
            : error.message
            ? error.message
            : error.response?.data?.errors?.length
            ? error.response.data.errors[0]
            : this.props.t("error_deleting_discount").message || "Error deleteing discount";

        this.setState({ isDeletingDiscount: false, deleteDiscountErrorMsg, selectedDiscount: { ...initialDiscount } });
        console.log("Error deleting tyre team discount", error);
      }
    });
  };

  renderDiscountsTable = () => {
    const { categories } = this.state;
    const { t, tyreTeamDiscountState, authState } = this.props;
    const { items, loading } = tyreTeamDiscountState;
    const { user } = authState;

    const itemsToRender = [];

    items.forEach(i => {
      itemsToRender.push(i);
      i.tyre_brand_discounts && itemsToRender.push(...i.tyre_brand_discounts);
    });

    return (
      <ReactTable
        className="TyreTeamDiscountTable -floated-table"
        data={itemsToRender}
        loading={loading}
        showPagination={false}
        showPageSizeOptions={false}
        pageSize={itemsToRender.length}
        sortable={false}
        resizable={false}
        minRows={0}
        loadingText=""
        onPageChange={() => {
          try {
            document.querySelector(".App__module").scrollTo(0, 0);
          } catch (e) {
            document.querySelector(".App__module").scrollTop = 0; // IE Fix
          }
        }}
        column={{
          ...ReactTableDefaults.column,
          headerClassName: "ReactTable__column-header -text-ellipsis",
          className: "ReactTable__column",
        }}
        columns={[
          {
            Header: t("discount_name").message || "Discount Name",
            accessor: "name",
          },

          {
            id: "Brand",
            Header: t("brand").message || "Brand",
            accessor: d => (d.brand ? d.brand : t("default").message || "Default"),
          },

          {
            id: "Category",
            Header: t("category").message || "Category",
            accessor: d => {
              if (d.category) {
                const name = categories.find(c => c.key === d.category)?.text;
                return name;
              }
              return t("default").message || "Default";
            },
          },

          {
            id: "Discount",
            Header: t("discount_on_price").message || "Discount (on price)",

            accessor: d => d.discount && <span>{d.discount}%</span>,
          },

          {
            id: "NettMargin",
            Header: t("nett_plus_margin").message || "Nett + Margin",
            accessor: d => d.net_margin && <span>{d.net_margin.toFixed(2)}</span>,
          },

          {
            id: "Active",
            Header: t("active").message || "Active",
            width: 50,
            accessor: d => <div style={{ textAlign: "center" }}>{d.active ? <Icon name="check circle" color="green" /> : <Icon name="minus circle" color="grey" />}</div>,
          },

          {
            id: "controls",
            Header: "",
            Cell: row => {
              if (user.role_id >= ROLES.RECEPTIONIST) return null;

              return (
                <div style={{ textAlign: "end" }}>
                  {!row.original.tyre_discount_id && (
                    <Label
                      className="controls-btn"
                      basic
                      onClick={
                        !row.original.active
                          ? null
                          : () => this.handleToggleAddUpdateBrandDiscountModal({ ...initialDiscount, id: row.original.id }, true, row.original.tyre_brand_discounts)
                      }
                    >
                      <Icon name="plus" color={!row.original.active ? "grey" : "green"} />
                    </Label>
                  )}

                  <Label
                    className="controls-btn"
                    basic
                    onClick={() => {
                      row.original.tyre_discount_id
                        ? this.handleToggleAddUpdateBrandDiscountModal(row.original, false)
                        : this.handleToggleAddUpdateDiscountModal(row.original, false);
                    }}
                  >
                    <Icon name="pencil" color="green" />
                  </Label>
                  <Label className="controls-btn" basic onClick={() => this.handleToggleDeleteDiscountConfirm(row.original)}>
                    <Icon name="trash" color="green" />
                  </Label>
                </div>
              );
            },
          },
        ]}
      />
    );
  };

  renderAddUpdateDiscountModal = () => {
    if (!this.state.selectedDiscount) return null;

    const { isAddUpdateDiscountModalOpen, isAddDiscount, isLoading, isBrand, brands, categories, selectedDiscount } = this.state;
    const { name, discount, net_margin, is_brand_discount_only, active, brand, category } = selectedDiscount;
    const { t } = this.props;

    const requiredFieldsMissing = !is_brand_discount_only && ((!discount && !net_margin) || (isBrand ? !brand || !category : !name));

    return (
      <Modal open={isAddUpdateDiscountModalOpen}>
        <Modal.Header>
          <h2>{isBrand ? t("brand_discount").message || "Brand Discount" : t("discount").message || "Discount"}</h2>
        </Modal.Header>
        <Modal.Content>
          <Form className="tyre-team-form-container">
            <Form.Group>
              {isBrand ? (
                <Form.Field required>
                  <label>{t("brand").message || "Brand"}</label>
                  <Dropdown selection selectOnBlur={false} clearable options={brands} value={brand} search name="brand" onChange={this.handleDropdownChange} />
                </Form.Field>
              ) : (
                <Form.Field required>
                  <label>{t("discount_name").message || "Discount Name"}</label>
                  <Form.Input name="name" value={name} onChange={this.handleInputChange} />
                </Form.Field>
              )}

              {!isBrand && (
                <Form.Field className="is-brand-discount-only-field">
                  <Checkbox
                    name="is_brand_discount_only"
                    checked={is_brand_discount_only}
                    label={t("is_brand_discount_only").message || "Is brand discount only"}
                    toggle
                    onChange={this.handleCheckboxChange}
                  />
                </Form.Field>
              )}

              {!is_brand_discount_only && (
                <>
                  <Form.Field required>
                    <label>{t("discount").message || "Discount"}</label>
                    <Form.Input type="number" disabled={!!net_margin} name="discount" value={discount} onChange={this.handleInputChange} icon="percent" />
                  </Form.Field>

                  <Form.Field required>
                    <label>{t("net_margin").message || "Nett + Margin"}</label>
                    <Form.Input type="number" disabled={!!discount} name="net_margin" value={net_margin} onChange={this.handleInputChange} icon="euro sign" />
                  </Form.Field>
                </>
              )}
            </Form.Group>

            <Form.Group>
              {isBrand && (
                <Form.Field required>
                  <label>{t("category").message || "Category"}</label>
                  <Dropdown selection selectOnBlur={false} clearable options={categories} value={category} name="category" onChange={this.handleDropdownChange} />
                </Form.Field>
              )}
              <Form.Field className="active-checkbox">
                <Checkbox name="active" checked={active} label={t("active").message || "Active"} toggle onChange={this.handleCheckboxChange} />
              </Form.Field>
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button color="grey" disabled={isLoading} onClick={() => this.handleToggleAddUpdateDiscountModal(null)}>
            {t("cancel").message || "Cancel"}
          </Button>
          <Button
            color="green"
            disabled={isLoading || requiredFieldsMissing}
            loading={isLoading}
            onClick={isAddDiscount ? this.handleAddDiscount : this.handleUpdateDiscount}
          >
            {t("save").message || "Save"}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };

  handleToggleAddUpdateDiscountModal = (selectedDiscount, isAddDiscount) => {
    this.setState(({ isAddUpdateDiscountModalOpen }) => ({
      isAddUpdateDiscountModalOpen: !isAddUpdateDiscountModalOpen,
      isBrand: false,
      isAddDiscount,
      selectedDiscount,
    }));
  };

  handleToggleAddUpdateBrandDiscountModal = (selectedDiscount, isAddDiscount) => {
    this.setState(
      ({ isAddUpdateDiscountModalOpen }) => ({
        isAddUpdateDiscountModalOpen: !isAddUpdateDiscountModalOpen,
        isBrand: true,
        isAddDiscount,
        selectedDiscount,
      }),
      () => {
        const brands = this.state.brands.map(b => {
          const brand = this.state.selectedDiscount.tyre_brand_discounts?.find(bd => bd.brand === b.text);
          if (brand) {
            return {
              ...b,
              disabled: true,
            };
          }

          return {
            ...b,
            disabled: false,
          };
        });

        this.setState({ brands });
      }
    );
  };

  handleToggleDeleteDiscountConfirm = selectedDiscount => {
    this.setState(({ isDeleteConfirmOpen }) => ({ isDeleteConfirmOpen: !isDeleteConfirmOpen, selectedDiscount, isBrand: !!selectedDiscount.tyre_discount_id }));
  };

  handleInputChange = e => {
    const { name, value } = e.target;

    if ((name === "discount" || name === "net_margin") && Number(value) < 0) return;
    else if (name === "discount" && Number(value) > 99.99) return;

    this.setState(({ selectedDiscount }) => ({ selectedDiscount: { ...selectedDiscount, [name]: value } }));
  };

  handleDropdownChange = (_, { name, value }) => {
    this.setState(({ selectedDiscount }) => ({ selectedDiscount: { ...selectedDiscount, [name]: value } }));
  };

  handleCheckboxChange = (_, { name, checked }) => {
    const selectedDiscount = { ...this.state.selectedDiscount };

    if (name === "active") {
      if (this.state.isBrand) {
        const id = selectedDiscount.tyre_discount_id || selectedDiscount.id;
        const defaultDiscount = this.props.tyreTeamDiscountState.items?.find(d => d.id === id);

        if (!defaultDiscount?.active) return;
      }

      if (!checked) {
        selectedDiscount.tyre_brand_discounts && selectedDiscount.tyre_brand_discounts.forEach(tbd => (tbd.active = false));
      }
    } else if (name === "is_brand_discount_only" && checked) {
      selectedDiscount.discount = null;
      selectedDiscount.net_margin = null;
    }

    selectedDiscount[name] = checked;

    this.setState({ selectedDiscount });
  };

  renderDeleteConfirmation = () => {
    const { isDeleteConfirmOpen, isDeletingDiscount, deleteDiscountErrorMsg } = this.state;
    const { t } = this.props;

    return (
      <CustomConfirm
        type="danger"
        isOpen={isDeleteConfirmOpen}
        isLoading={isDeletingDiscount}
        handleConfirm={this.handleDeleteDiscount}
        handleCancel={() => this.handleToggleDeleteDiscountConfirm({})}
        confirmMsg={t("confirm_delete_message").message || "Are you sure that you want to delete this? You can't undo this action."}
        error={deleteDiscountErrorMsg}
      />
    );
  };

  renderAlert = () => {
    const props = {
      offset: 20,
      position: "top right",
      theme: "light",
      transition: "fade",
    };
    return <AlertContainer ref={a => (this.msg = a)} {...props} />;
  };

  render() {
    const { t } = this.props;

    return (
      <div className="TyreTeamDiscount">
        <div className="heading">
          <h1>{t("tyre_team").message || "Tyre Team"}</h1>
          <Can I="add" the="tyre-discount">
            <Button icon basic labelPosition="left" onClick={() => this.handleToggleAddUpdateDiscountModal({ ...initialDiscount }, true)}>
              {t("discount").message || "Discount"}
              <Icon name="plus" color="green" />
            </Button>
          </Can>
        </div>

        {this.renderDiscountsTable()}
        {this.renderAddUpdateDiscountModal()}
        {this.renderDeleteConfirmation()}
        {this.renderAlert()}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    tyreTeamDiscountState: state.tyreTeamDiscount,
    globalState: state.global,
    authState: state.auth,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getDiscounts: payload => dispatch(getDiscounts(payload)),
    addDiscount: payload => dispatch(addDiscount(payload)),
    addBrandDiscount: (id, payload) => dispatch(addBrandDiscount(id, payload)),
    updateDiscount: payload => dispatch(updateDiscount(payload)),
    updateBrandDiscount: (id, payload) => dispatch(updateBrandDiscount(id, payload)),
    deleteDiscount: payload => dispatch(deleteDiscount(payload)),
    deleteBrandDiscount: (discountID, brandDiscountID) => dispatch(deleteBrandDiscount(discountID, brandDiscountID)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TyreTeamDiscount));
