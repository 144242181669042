import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Grid } from "semantic-ui-react";
import moment from "moment";

import Service from "./../App/service";
import { SubHeader } from "./../../components";
import "./PrivacySecurityDashboard.css";

class PrivacySecurityDashboard extends Component {
  state = {
    dataOfficer: {},
    history: [],
  };

  componentDidMount() {
    Service.getDealerPrivacyStatus(this.props.authState.user.dealer_id)
      .then(res => {
        if (res && res.data && res.data.data) {
          this.setState({ dataOfficer: res.data.data.data_officer, history: res.data.data.history });
        }
      })
      .catch(err => {
        console.log("Error getting dealer privacy status ::: ", err);
      });
  }

  render() {
    const { dataOfficer: DO, history } = this.state;

    return (
      <div className="PrivacySecurityDashboard">
        <SubHeader>
          <Grid.Column width={3} className="SubHeader_title -no-padding">
            {this.props.t("privacy_security").message || "Privacy and Security"}
          </Grid.Column>
        </SubHeader>

        <div className="page-content">
          <Grid>
            <Grid.Column width={10}>
              <div style={{ height: "99vh" }}>
                <iframe title="privacyGDoc" src="https://storage.googleapis.com/claire-dpo-resources/GDPR-AVG-Claire.pdf" width="100%" height="100%"></iframe>
              </div>
            </Grid.Column>
            <Grid.Column width={6}>
              <div className="dpo-info">
                <div className="dpo-info-inner">
                  <h2>{this.props.t("data_protection_officer").message || "Data Protection Officer"}</h2>
                  {!DO && <p>{this.props.t("no_dpo").message || "No Data Protection Officer"}</p>}
                  {DO && (
                    <section>
                      <p>
                        <strong>{this.props.t("name").message || "Full name"}</strong>
                        <span>{DO.name}</span>
                      </p>
                      <p>
                        <strong>{this.props.t("address").message || "Address"}</strong>
                        <span>{DO.address}</span>
                      </p>
                      <p>
                        <strong>{this.props.t("phone").message || "Phone"}</strong>
                        <span>{DO.phone}</span>
                      </p>
                      <p>
                        <strong>{this.props.t("email").message || "Email"}</strong>
                        <span>{DO.email}</span>
                      </p>
                    </section>
                  )}
                  {history && history.length > 0 && (
                    <span>
                      <h3 className="-margin-top-10">{this.props.t("accepted_agreements").message || "Accepted agreements"}</h3>
                      <section>
                        {history.map((e, k) => (
                          <p key={k}>
                            {moment(e.created_at).format("DD-MM-YYYY")}
                            &nbsp; &bull; {`${e.user.first_name} ${e.user.last_name}`} &bull; {e.user.email} &bull; &nbsp;
                            {e.agreement_version_id}
                          </p>
                        ))}
                      </section>
                    </span>
                  )}

                  <h3>{this.props.t("claire_data_officer").message || "Claire Automotive Support B.V. Data Protection Officer"}</h3>
                  <section>
                    <p>
                      <strong>{this.props.t("name").message || "Full name"}</strong>
                      <span>dhr A.J.P van den Eijnden</span>
                    </p>
                    <p>
                      <strong>{this.props.t("email").message || "Email"}</strong>
                      <span>dpo@claireit.nl</span>
                    </p>
                  </section>

                  <section style={{ marginTop: "1em" }}>
                    <p>
                      In the following&nbsp;
                      <a href="https://storage.googleapis.com/claire-dpo-resources/Claire-dataoverview.pdf" rel="noopener noreferrer" target="_blank">
                        file
                      </a>
                      &nbsp;you can read a simple privacy overview what and how we process data.
                    </p>
                    <p>
                      <a href="https://storage.googleapis.com/claire-dpo-resources/GDPR-intro-website.pdf" rel="noopener noreferrer" target="_blank">
                        Here
                      </a>
                      &nbsp;you can read what data we collect and process from that you deliver to us and who has access to it.
                    </p>
                    <p>
                      Claire DMSv3 protocol used to pull data from DMS databases is described{" "}
                      <a href="https://storage.googleapis.com/claire-dpo-resources/transparancy_sow.pdf" rel="noopener noreferrer" target="_blank">
                        here
                      </a>{" "}
                      with the flows and security in places. Contact support for more information.
                    </p>
                    <p>The files are guidelines and we will update them if needed with new information.</p>
                  </section>
                </div>
              </div>
            </Grid.Column>
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { authState: state.auth };
};

export default withTranslation()(connect(mapStateToProps, {})(PrivacySecurityDashboard));
