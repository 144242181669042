import React, { Component } from "react";
import { Form, Checkbox } from "semantic-ui-react";

import { UserInput, PasswordInput } from "../../components";

class PonOilConfiguration extends Component {
  handleInputChange = e => {
    let { dealer, handleChange } = this.props;

    if (Object.keys(dealer).length === 0) return;

    dealer[e.target.name] = e.target.value;

    handleChange(dealer);
  };

  handleCheckboxChange = (e, { name, checked }) => {
    const { dealer, handleChange } = this.props;

    handleChange({ ...dealer, [name]: checked });
  };

  render() {
    const { dealer, missingField, t } = this.props;
    const { is_pon_oil_enabled, pon_oil_username, pon_oil_password, has_pon_oil_password } = dealer;

    return (
      <div>
        <Form>
          <Form.Field className="full-width-input">
            <label>{t("enable_pon_oil").message || "Enable PON Oil"}</label>
            <Checkbox toggle name="is_pon_oil_enabled" checked={is_pon_oil_enabled} onChange={this.handleCheckboxChange} />
          </Form.Field>

          {is_pon_oil_enabled && (
            <>
              <Form.Field className="full-width-input" error={missingField && !pon_oil_username}>
                <label>{t("pon_oil_username").message || "PON Oil Username"}</label>
                <UserInput
                  value={pon_oil_username}
                  onChange={this.handleInputChange}
                  name="pon_oil_username"
                  placeholder={t("pon_oil_username").message || "PON Oil Username"}
                />
              </Form.Field>

              <Form.Field className="full-width-input" error={missingField && !has_pon_oil_password && !pon_oil_password}>
                <label>{t("pon_oil_password").message || "PON Oil Password"}</label>
                <PasswordInput
                  value={pon_oil_password}
                  onChange={this.handleInputChange}
                  name="pon_oil_password"
                  showAsteriskPlaceholder={has_pon_oil_password}
                  placeholder={t("pon_oil_password").message || "PON Oil Password"}
                />
              </Form.Field>
            </>
          )}
        </Form>
      </div>
    );
  }
}

export default PonOilConfiguration;
