import React, { Component } from "react";
import { Checkbox, Form } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import { Editor } from "react-draft-wysiwyg";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./Editor.css";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import draftToHtml from "../../lib/draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

import { UserInput } from "../../components";

class AgreementForm extends Component {
  state = {
    editorState: this.props.agreement.text
      ? EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(this.props.agreement.text).contentBlocks))
      : EditorState.createEmpty(),
  };

  handleNameChange = e => {
    let { agreement } = this.props;

    agreement.name = e.target.value;
    this.props.handleUpdate(agreement);
  };

  onChange = editorState => {
    let { agreement } = this.props;

    agreement.text = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    this.setState({ editorState });
    this.props.handleUpdate(agreement);
  };

  handleCheckboxChange = (e, data) => {
    let { agreement } = this.props;
    agreement[data.name] = data.checked;
    this.props.handleUpdate(agreement);
  };

  render() {
    let { agreement } = this.props;
    const { online_check_in_enabled, is_keylocker_enabled, is_kiosk_enabled, desk_check_in_enabled, diagnose_overview_enabled } = this.props.selectedLocation;

    return (
      <Form>
        <Form.Group widths="equal">
          <Form.Field>
            <label>{this.props.t("name").message || "Name"}</label>
            <UserInput type="text" name="name" value={agreement.name} onChange={this.handleNameChange} />
          </Form.Field>
        </Form.Group>

        <Form.Group widths="equal">
          <Form.Field>
            <label>{this.props.t("visibility").message || "Visibility"}</label>
            {online_check_in_enabled && (
              <Checkbox
                style={{ marginRight: "10px" }}
                label={this.props.t("Online_Check_in").message || "Online Check-in"}
                name="visible_online_check_in"
                checked={agreement.visible_online_check_in}
                onChange={this.handleCheckboxChange}
              />
            )}

            {is_keylocker_enabled && (
              <Checkbox
                style={{ marginRight: "10px" }}
                label={this.props.t("keylocker_check_in").message || "Keylocker Check-in"}
                name="visible_keylocker_check_in"
                checked={agreement.visible_keylocker_check_in}
                onChange={this.handleCheckboxChange}
              />
            )}

            {is_kiosk_enabled && (
              <Checkbox
                style={{ marginRight: "10px" }}
                label={this.props.t("kiosk_check_in").message || "Kiosk Check-in"}
                name="visible_kiosk_check_in"
                checked={agreement.visible_kiosk_check_in}
                onChange={this.handleCheckboxChange}
              />
            )}

            {desk_check_in_enabled && (
              <>
                <Checkbox
                  style={{ marginRight: "10px" }}
                  label={this.props.t("desk_check_in").message || "Desk Check-in"}
                  name="visible_desk_check_in"
                  checked={agreement.visible_desk_check_in}
                  onChange={this.handleCheckboxChange}
                />
                <Checkbox
                  style={{ marginRight: "10px" }}
                  label={this.props.t("desk_check_out").message || "Desk Check-out"}
                  name="visible_desk_check_out"
                  checked={agreement.visible_desk_check_out}
                  onChange={this.handleCheckboxChange}
                />
              </>
            )}

            {diagnose_overview_enabled && (
              <Checkbox
                label={this.props.t("diagnose_overview").message || "Diagnose overview"}
                name="visible_customcom"
                checked={agreement.visible_customcom}
                onChange={this.handleCheckboxChange}
              />
            )}
          </Form.Field>
        </Form.Group>

        <Form.Group widths="equal">
          <Form.Field>
            <label>{this.props.t("optional_choice").message || "Optional choice"}</label>
            {online_check_in_enabled && (
              <Checkbox
                style={{ marginRight: "10px" }}
                label={this.props.t("Online_Check_in").message || "Online Check-in"}
                name="optional_online_check_in"
                disabled={!agreement.visible_online_check_in}
                checked={agreement.optional_online_check_in}
                onChange={this.handleCheckboxChange}
              />
            )}

            {is_keylocker_enabled && (
              <Checkbox
                style={{ marginRight: "10px" }}
                label={this.props.t("keylocker_check_in").message || "Keylocker Check-in"}
                name="optional_keylocker_check_in"
                disabled={!agreement.visible_keylocker_check_in}
                checked={agreement.optional_keylocker_check_in}
                onChange={this.handleCheckboxChange}
              />
            )}

            {is_kiosk_enabled && (
              <Checkbox
                style={{ marginRight: "10px" }}
                label={this.props.t("kiosk_check_in").message || "Kiosk Check-in"}
                name="optional_kiosk_check_in"
                disabled={!agreement.visible_kiosk_check_in}
                checked={agreement.optional_kiosk_check_in}
                onChange={this.handleCheckboxChange}
              />
            )}

            {desk_check_in_enabled && (
              <>
                <Checkbox
                  style={{ marginRight: "10px" }}
                  label={this.props.t("desk_check_in").message || "Desk Check-in"}
                  name="optional_desk_check_in"
                  disabled={!agreement.visible_desk_check_in}
                  checked={agreement.optional_desk_check_in}
                  onChange={this.handleCheckboxChange}
                />
                <Checkbox
                  style={{ marginRight: "10px" }}
                  label={this.props.t("desk_check_out").message || "Desk Check-out"}
                  name="optional_desk_check_out"
                  disabled={!agreement.visible_desk_check_out}
                  checked={agreement.optional_desk_check_out}
                  onChange={this.handleCheckboxChange}
                />
              </>
            )}

            {diagnose_overview_enabled && (
              <Checkbox
                label={this.props.t("diagnose_overview").message || "Diagnose overview"}
                name="optional_customcom"
                disabled={!agreement.visible_customcom}
                checked={agreement.optional_customcom}
                onChange={this.handleCheckboxChange}
              />
            )}
          </Form.Field>
        </Form.Group>

        <Form.Group widths="equal">
          <Form.Field>
            <label>{this.props.t("text").message || "Text"}</label>
            <Editor
              editorState={this.state.editorState}
              onEditorStateChange={this.onChange}
              wrapperClassName="editor-input"
              editorStyle={{ minHeight: "400px", padding: "1em" }}
            />
          </Form.Field>
        </Form.Group>
      </Form>
    );
  }
}

export default withTranslation()(AgreementForm);
