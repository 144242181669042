import axios from "axios";

import ENV from "../../util/env-config";
import { getSessionCookie } from "../../util/common";

class Service {
  static requestConfig() {
    return {
      headers: {
        Authorization: `Bearer ${getSessionCookie()}`,
        Pragma: "no-cache",
      },
    };
  }

  static getSettings() {
    return axios.get(ENV.systemBase + "/settings/find", this.requestConfig());
  }

  static getVersions() {
    return axios.get(ENV.apiBaseB + "/versions/list", this.requestConfig());
  }

  static getDealer(id) {
    return axios.post(ENV.apiBaseB + "/dealers/details", { id: id }, this.requestConfig());
  }

  static getDealers() {
    return axios.post(ENV.dealerBaseUrl + "/dealers/list?embed=dealer_location", {}, this.requestConfig());
  }

  static updateDealer(requestData) {
    return axios.post(ENV.dealerBaseUrl + "/dealers/update", requestData, this.requestConfig());
  }

  static getLocation(requestData) {
    return axios.post(ENV.apiBaseB + "/locations/details", requestData, this.requestConfig());
  }

  static getUsersByLocationId(id) {
    return axios.post(ENV.apiBaseB + `/users/list?filter=dealer_location_id==${id}&embed=group`, {}, this.requestConfig());
  }

  static getLocationCarGarantConfig(ID) {
    return axios.get(ENV.carGarantBase + "/car-garant/api/configuration/get/" + ID, this.requestConfig());
  }

  static getDealerPrivacyStatus(dealer_id) {
    return axios.post(ENV.apiBaseB + "/agreements/dealer_privacy_status", { dealer_id }, this.requestConfig());
  }

  static getDealerDataOfficer(dealer_id) {
    return axios.post(ENV.apiBaseB + "/agreements/dealer_data_officer", { dealer_id }, this.requestConfig());
  }

  static acceptPrivacyAgreement(dealer_id) {
    return axios.post(ENV.apiBaseB + "/agreements/accept_privacy", { dealer_id }, this.requestConfig());
  }

  static getPendingRequestsCount(requestData) {
    return axios.post(ENV.apiBaseB + "/manufacturers/pending_requests_count", requestData, this.requestConfig());
  }

  static getListOfNotificationElements() {
    return axios.get(ENV.apiBaseB + "/users/list_notification_elements", this.requestConfig());
  }
}

export default Service;
