import React, { Component } from "react";
import { Form, Label, Icon, List, Button } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import { SortableContainer, SortableElement } from "react-sortable-hoc";

import { UserInput } from "../../../components";

class Tyre extends Component {
  constructor(props) {
    super(props);

    let optional_answers = [];

    const { default_configuration } = this.props;

    if (default_configuration) {
      optional_answers = default_configuration.optional_answers || [];
    }

    this.state = {
      answerName: "",
      optional_answers,
    };
  }

  getElementToUpdate = () => {
    let optional_answers = this.state.optional_answers;
    return { optional_answers };
  };

  handleAnswerInput = e => {
    this.setState({ answerName: e.target.value });
  };

  handleAppendAnswer = () => {
    let name = this.state.answerName;

    if (name !== "") {
      let { optional_answers } = this.state;
      optional_answers = optional_answers.concat(name);
      this.setState({ answerName: "", optional_answers }, () => {
        this.props.handleQuestionElementUpdate(this.getElementToUpdate());
      });
    }
  };

  handleRemoveAnswer = index => {
    let optional_answers = [...this.state.optional_answers];
    optional_answers.splice(index, 1);

    this.setState({ optional_answers }, () => {
      this.props.handleQuestionElementUpdate(this.getElementToUpdate());
    });
  };

  onSortEnd = ({ oldIndex, newIndex }, event) => {
    if (!event) return;

    let optional_answers = [...this.state.optional_answers];

    const answer = optional_answers.splice(oldIndex, 1);
    optional_answers.splice(newIndex, 0, answer[0]);

    this.setState({ optional_answers }, () => {
      this.props.handleQuestionElementUpdate(this.getElementToUpdate());
    });
  };

  renderOptionalAnswers = optional_answers => {
    if (optional_answers && optional_answers.length > 0) {
      const SortableItem = SortableElement(({ offset, value }) => {
        return (
          <Label className="ListItem" size="big" color="blue">
            <Icon
              onClick={() => {
                this.handleRemoveAnswer(offset);
              }}
              name="delete"
            />{" "}
            {value}
          </Label>
        );
      });

      const SortableList = SortableContainer(({ optional_answers }) => {
        return (
          <div>
            {optional_answers.map((answer, key) => (
              <SortableItem key={`answer-${key}`} index={key} offset={key} value={answer} />
            ))}
          </div>
        );
      });

      return <SortableList optional_answers={optional_answers} distance={5} onSortEnd={this.onSortEnd} />;
    }
  };

  render() {
    return (
      <div>
        <Form.Field>
          <label>{this.props.t("optional_answers").message || "Optional answers"}</label>
          <List>{this.renderOptionalAnswers(this.state.optional_answers)}</List>
          <UserInput placeholder="Item name" value={this.state.answerName} onChange={this.handleAnswerInput} />
          <Button fluid color="green" onClick={this.handleAppendAnswer}>
            <Icon name="plus" /> {this.props.t("add_item").message || "Add item"}
          </Button>
        </Form.Field>
      </div>
    );
  }
}

export default withTranslation()(Tyre);
