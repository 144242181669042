import React from "react";

import Controller from "./controller";
import Kiosk from "./Kiosk";

export default () => (
  <Controller>
    <Kiosk />
  </Controller>
);
