import React from "react";
import { withTranslation } from "react-i18next";
import { Dropdown } from "semantic-ui-react";

const LocationDropdown = ({ className, options, optionAllVisible, noIcon, t, ...restProps }) => {
  let locationOptions = options ? options.filter(location => location.detail.active) : [];

  locationOptions.sort((a, b) => {
    const textA = a.text.toUpperCase();
    const textB = b.text.toUpperCase();
    return textA < textB ? -1 : textA > textB ? 1 : 0;
  });

  if (optionAllVisible) {
    locationOptions.unshift({ text: t("ALL").message || "ALL", value: "all", detail: "all", key: "all" });
  }

  const extraProps = {...restProps}
  delete extraProps.tReady;

  return (
    <Dropdown
      className={`LocationDropdown icon -scrollable-dropdown ${className}`}
      noResultsMessage={t("no_locations").message || "No locations"}
      placeholder={t("select_location").message || "Select a location"}
      options={locationOptions}
      {...extraProps}
      {...(!noIcon && { icon: "marker" })}
    />
  );
};

export default withTranslation()(LocationDropdown);
