import axios from "axios";

import ENV from "../../util/env-config";
import { getSessionCookie } from "../../util/common";

class Service {
  static requestConfig() {
    return {
      headers: {
        Authorization: `Bearer ${getSessionCookie()}`,
      },
    };
  }

  static addSnoozeItem(snooze) {
    return axios.post(ENV.appointmentApiBase + "/snoozes/append", snooze, this.requestConfig());
  }

  static deleteSnooze(snooze) {
    return axios.post(ENV.appointmentApiBase + "/snoozes/delete", snooze, this.requestConfig());
  }

  static getSnoozeDropdownOptions() {
    return axios.get(ENV.appointmentApiBase + "/snoozes/options/list", this.requestConfig());
  }
}

export default Service;
