import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/pro-regular-svg-icons";

import "./index.css";

const ImageGallery = ({ lettersMap }) => {
  if (!Array.isArray(lettersMap)) return null;

  const urls = Object.keys(lettersMap);

  if (!urls.length) return null;

  const split = [];
  let j = -1;
  urls.forEach((u, i) => {
    if (i % 4 === 0) {
      j++;
      split[j] = [];
    }

    split[j].push(u);
  });

  const emptyColumns = 4 - split[j].length;

  split[j].push(...Array(emptyColumns).fill(""));

  return (
    <div className="images-container">
      {split.map((row, i) => (
        <div className="image-container-row" key={i}>
          {row.map((url, j) => (
            <div className="image-container" key={j}>
              {lettersMap[url] && (
                <>
                  <div className="img-label">
                    <div className="img-icon-wrapper">
                      <FontAwesomeIcon className="image-video-icon" icon={faCamera} />
                      <span className="report-car-images-letter">{lettersMap[url]}</span>
                    </div>
                  </div>
                  <img alt={`img-${lettersMap[url]}`} className="image-style" src={url} />
                </>
              )}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

ImageGallery.getLettersMap = questionItems => {
  let map = [];
  let currentLetter = 97;
  let currentNumber = 1;

  const setLetter = url => {
    if (currentLetter < 123) {
      map[url] = String.fromCharCode(currentLetter);
      currentLetter++;
    } else {
      map[url] = currentNumber;
      currentNumber++;
    }
  };

  if (Array.isArray(questionItems))
    questionItems.forEach(item => {
      if (item.images) item.images.forEach(img => img.active && setLetter(img.url));
    });

  return map;
};

export default ImageGallery;
