import React from "react";
import { withTranslation } from "react-i18next";
import { Checkbox, Grid, Icon } from "semantic-ui-react";

import { CHANNELS } from "../Appointments/common";

import "./CustomerDetail.css";

const CustomerDetail = ({ customer, loading, isUpdatingCustomer, onChangeCheckbox, onChangePreferredCommunicationChannel, t }) => (
  <div className="CustomerDetail">
    <Grid columns={2}>
      <Grid.Row>
        <Grid.Column>
          <strong>{t("company_name").message || "Company name"}</strong>
          <span>{customer.company}</span>
        </Grid.Column>
        {customer.is_lease_company && (
          <Grid.Column>
            <span className="lease-company-tag">
              <Icon name="building" />
              {t("lease_company").message || "Lease company"}
            </span>
          </Grid.Column>
        )}
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <strong>{t("location").message || "Location"}</strong>
          <span>{customer.place}</span>
        </Grid.Column>
        <Grid.Column>
          <strong>{t("street").message || "Street"}</strong>
          <span>
            {customer.street} {customer.house_nr}
          </span>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <strong>{t("postcode").message || "Postcode"}</strong>
          <span>{customer.postcode}</span>
        </Grid.Column>
        <Grid.Column>
          <strong>{t("business_email").message || "Business email"}</strong>
          <span>{customer.email_business}</span>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <strong>{t("private_email").message || "Private email"}</strong>
          <span>{customer.email_private}</span>
        </Grid.Column>
        <Grid.Column>
          <strong>{t("business_phone").message || "Business phone"}</strong>
          <span>{customer.tel_business_nr}</span>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <strong>{t("private_phone").message || "Private phone"}</strong>
          <span>{customer.tel_private_nr}</span>
        </Grid.Column>
        <Grid.Column>
          <strong>{t("business_mobile_phone").message || "Business mobile phone"}</strong>
          <span>{customer.tel_mobile_business}</span>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <strong>{t("private_mobile_phone").message || "Private mobile phone"}</strong>
          <span>{customer.tel_mobile_private}</span>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Checkbox
            name="is_internal"
            toggle
            checked={customer.is_internal}
            onChange={onChangeCheckbox}
            disabled={loading || isUpdatingCustomer}
            label={t("is_internal").message || "Is internal"}
          />
        </Grid.Column>
        <Grid.Column>
          <Checkbox
            name="block_communication"
            toggle
            checked={customer.block_communication}
            onChange={onChangeCheckbox}
            disabled={loading || isUpdatingCustomer}
            label={t("block_communication").message || "Block communication"}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row style={{ alignItems: "center" }}>
        <Grid.Column width={3}>
          <strong>{t("preferred_channel").message || "Preferred Channel"}</strong>
        </Grid.Column>

        <Grid.Column>
          <button
            className={`preferred-channel-button ${customer.preferred_communication_channel === CHANNELS.EMAIL ? "active-preferred-channel" : ""}`}
            disabled={loading || isUpdatingCustomer}
            onClick={() => onChangePreferredCommunicationChannel(CHANNELS.EMAIL)}
          >
            <Icon name="mail outline" />
            <span>{t("email").message || "Email"}</span>
          </button>
          <button
            className={`preferred-channel-button ${customer.preferred_communication_channel === CHANNELS.SMS ? "active-preferred-channel" : ""}`}
            disabled={loading || isUpdatingCustomer}
            onClick={() => onChangePreferredCommunicationChannel(CHANNELS.SMS)}
          >
            <Icon name="mobile alternate" />
            <span>{t("sms").message || "SMS"}</span>
          </button>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </div>
);

export default withTranslation()(CustomerDetail);
