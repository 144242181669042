import axios from "axios";

import ENV from "../../util/env-config";
import { getSessionCookie } from "../../util/common";

class Service {
  static requestConfig() {
    return {
      headers: {
        Authorization: `Bearer ${getSessionCookie()}`,
      },
    };
  }

  static getDiscounts(requestData) {
    return axios.post(ENV.tyreteamBaseURL + "/discounts/list_discounts", requestData, this.requestConfig());
  }

  static addDiscount(requestData) {
    return axios.post(ENV.tyreteamBaseURL + "/discounts/add", requestData, this.requestConfig());
  }

  static addBrandDiscount(requestData) {
    return axios.post(ENV.tyreteamBaseURL + "/discounts/add_brand", requestData, this.requestConfig());
  }

  static updateDiscount(requestData) {
    return axios.post(ENV.tyreteamBaseURL + "/discounts/update", requestData, this.requestConfig());
  }

  static updateBrandDiscount(requestData) {
    return axios.post(ENV.tyreteamBaseURL + "/discounts/update_brand", requestData, this.requestConfig());
  }

  static deleteDiscount(requestData) {
    return axios.post(ENV.tyreteamBaseURL + "/discounts/delete", requestData, this.requestConfig());
  }

  static deleteBrandDiscount(requestData) {
    return axios.post(ENV.tyreteamBaseURL + "/discounts/delete_brand", requestData, this.requestConfig());
  }

  static getCategories() {
    return axios.get(ENV.tyreteamBaseURL + "/discounts/list_categories", this.requestConfig());
  }

  static getTiresBrands() {
    return axios.get(ENV.apiBaseB + "/tyres/list_brands", this.requestConfig());
  }
}

export default Service;
