import React, { Component } from "react";
import { Button, Icon } from "semantic-ui-react";
import Service from "./service";
import QRCode from "qrcode.react";
import { withTranslation } from "react-i18next";

const TIMEOUT = 60;
let interval;
let timeoutHandler;

class QrLogin extends Component {
  state = {
    qr_token: "",
    timer: -1,
    show_server_error: false,
    generating_code: false,
  };

  componentWillUnmount() {
    clearInterval(interval);
    clearTimeout(timeoutHandler);
  }

  resetData = () => {
    clearInterval(interval);
    this.setState({ timer: -1 });
  };

  handleQRGenerate = () => {
    if (this.state.generating_code) return;

    this.resetData();
    this.setState({ generating_code: true }, () => {
      Service.generateQRCode()
        .then(result => {
          if (result?.data?.data) {
            this.setState({ qr_token: result.data.data.qr_code, timer: TIMEOUT, show_server_error: false, generating_code: false }, () => {
              this.handleTimer();
              this.handleVerifyQRCode();
            });
          } else this.setState({ show_server_error: true, generating_code: false });
        })
        .catch(error => {
          this.setState({ show_server_error: true, generating_code: false });
          console.log(error);
        });
    });
  };

  handleTimer = () => {
    clearInterval(interval);

    interval = setInterval(() => {
      let { timer } = this.state;
      timer--;
      this.setState({ timer }, () => {
        if (timer < 1) {
          this.resetData();
        }
      });
    }, 1000);
  };

  handleVerifyQRCode = () => {
    clearTimeout(timeoutHandler);

    let { timer } = this.state;
    if (timer > 0) {
      Service.verifyQR(this.state.qr_token)
        .then(result => {
          if (result?.data?.data) {
            const { logged_in, token, zoho_asap_jwt, appointment_id } = result.data.data;

            if (logged_in) {
              clearInterval(interval);
              this.props.handleLoginViaQR(token, zoho_asap_jwt, appointment_id);
            } else {
              timeoutHandler = setTimeout(this.handleVerifyQRCode, 1000);
            }
          }
        })
        .catch(error => {
          clearInterval(interval);
          this.setState({ show_server_error: true });
          console.log(error);
        });
    }
  };

  render() {
    let { qr_token, timer, show_server_error, generating_code } = this.state;
    let timer_finished = timer < 0;

    return (
      <div>
        <Button fluid color="blue" onClick={this.handleQRGenerate} className="qr-login" loading={generating_code}>
          {qr_token ? this.props.t("generate_another_code").message || "Generate another code" : this.props.t("login_with_qr_code").message || "Login with QR code"}
        </Button>
        <div className="QRCode_Container">
          {qr_token && (
            <React.Fragment>
              <QRCode
                value={this.state.qr_token}
                renderAs="svg"
                fgColor={timer_finished || show_server_error ? "#e03f3f" : "#000000"}
                style={{ filter: timer_finished || show_server_error ? "blur(7px)" : "" }}
              />

              <div className={"-margin-top-10"}>
                {!show_server_error &&
                  (timer_finished ? (
                    <span className="TextRed">
                      <Icon name="info circle" /> {this.props.t("code_expired").message || "Code expired"}
                    </span>
                  ) : (
                    <React.Fragment>
                      <span style={{ color: "gray" }}>{this.props.t("your_code_will_expire_in").message || "Your code will expire in"}</span> <b>{timer} sec</b>
                    </React.Fragment>
                  ))}
              </div>
            </React.Fragment>
          )}
          {show_server_error && (
            <span className="TextRed">
              <Icon name="exclamation circle" /> {this.props.t("an_error_occurred").message || "An error occurred"}
            </span>
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation()(QrLogin);
