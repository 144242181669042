import React from "react";
import { Grid, Label, Popup } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarCheck } from "@fortawesome/pro-regular-svg-icons";
import { faCheckCircle, faTimesCircle, faEnvelope } from "@fortawesome/pro-solid-svg-icons";
import { withTranslation } from "react-i18next";
import moment from "moment";

import { round2 } from "../../util/common";
import "./CheckInOptionalServices.css";

const CHECKIN_COMMUNICATION_RESULT_STATUS = {
  NOT_SENT: 0,
  PENDING: 1,
  SUCCESS: 2,
  FAILED: 3,
};

const CheckInOptionalServices = ({ disabled, t, includeVAT, vat, services, onHandleCheckInCom, interventions }) => {
  if (!services || !services.length || services.every(service => !service.visible)) return null;

  services.sort((a, b) => (a.id < b.id ? 1 : a.id > b.id ? -1 : 0));

  return (
    <>
      <Grid className="optional-services-container">
        <Grid.Row className="optional-services-header-container">
          <Grid.Column width={9}>
            <FontAwesomeIcon icon={faCalendarCheck} className="optional-services-header-icon" />
            <span className="optional-services-header-title">{t("checkin_service").message || "Online check-in services"}</span>
          </Grid.Column>
          <Grid.Column width={2}>
            <span className="optional-services-header-title-small">{t("no_vat_price").message || "Excl. VAT"}</span>
          </Grid.Column>
          <Grid.Column width={2}>{includeVAT && <span className="optional-services-header-title-small">{t("vat_price").message || "Incl. VAT"}</span>}</Grid.Column>
          <Grid.Column width={3}>
            <span className="optional-services-header-title-small -pull-right">{t("ACTION").message || "ACTION"}</span>
          </Grid.Column>
        </Grid.Row>

        {services
          .filter(item => item.visible)
          .map(item => {
            const notificationEmailItemActive = [CHECKIN_COMMUNICATION_RESULT_STATUS.SUCCESS, CHECKIN_COMMUNICATION_RESULT_STATUS.FAILED].includes(item.status);

            return (
              <Grid.Row key={"service_result_" + item.id} className="optional-services-item">
                <Grid.Column width={9}>
                  <span className="optional-services-remark">{item.name}</span>
                </Grid.Column>
                <Grid.Column width={2}>
                  <span className="optional-services-price">{round2(item.price)}</span>
                </Grid.Column>
                <Grid.Column width={2}>{includeVAT && <span className="optional-services-price">{round2(round2(item.price) * (1 + vat / 100))}</span>}</Grid.Column>
                <Grid.Column width={3}>
                  {item.notification_email && (
                    <div className="optional-services-action -pull-right no-cursor">
                      <Popup
                        position="top right"
                        disabled={!notificationEmailItemActive}
                        trigger={
                          <Label color={notificationEmailItemActive ? "blue" : ""}>
                            <FontAwesomeIcon icon={faEnvelope} color={notificationEmailItemActive ? "white" : "grey"} />
                          </Label>
                        }
                        content={() => {
                          if (item.status === CHECKIN_COMMUNICATION_RESULT_STATUS.SUCCESS)
                            return (
                              <div>{`${t("sent_to").message || "Sent to"} ${item.notification_email} ${t("at").message || "at"} ${moment(item.updated_on).format(
                                "HH:mm"
                              )} ${t("on").message || "on"} ${moment(item.updated_on).format("DD-MM-YYYY")}`}</div>
                            );
                          if (item.status === CHECKIN_COMMUNICATION_RESULT_STATUS.FAILED)
                            return (
                              <>
                                <div>{`${t("failed_to_send_to").message || "Failed to send to"} ${item.notification_email}`}</div>
                                <div>{item.reason}</div>
                              </>
                            );
                        }}
                      />
                    </div>
                  )}

                  {!item.notification_email && (
                    <div className={"optional-services-action -pull-right" + (disabled ? " disabled" : "")}>
                      <Label
                        onClick={() => {
                          !disabled &&
                            onHandleCheckInCom({
                              result: item,
                              openModalConvertToIntervention:
                                !item.receptionist_handled && item.can_convert_to_intervention && !interventions?.some(i => i.check_in_result_id === item.id),
                            });
                        }}
                      >
                        <FontAwesomeIcon
                          className={disabled ? "" : "-cursor-pointer-no-color"}
                          icon={item.receptionist_handled ? faCheckCircle : faTimesCircle}
                          color={item.receptionist_handled ? "green" : "red"}
                        />
                      </Label>
                    </div>
                  )}
                </Grid.Column>
              </Grid.Row>
            );
          })}
      </Grid>
    </>
  );
};

export default withTranslation()(CheckInOptionalServices);
