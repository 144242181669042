import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Form, Dropdown } from "semantic-ui-react";

class LegalForm extends Component {
  handleRetentionChange = (e, data) => {
    let { dealer, onChange } = this.props;
    dealer.customer_data_retention_in_years = data.value;
    onChange(dealer);
  };

  render() {
    const { dealer, t } = this.props;

    return (
      dealer && (
        <Form>
          <Form.Field className="full-width-input">
            <label>{t("customer_data_retention_period_in_years").message || "Customer data retention period (in years)"}</label>
            <Dropdown
              options={[...Array(20).keys()].map(i => ({ text: i + 1, value: i + 1, key: i + 1 }))}
              selection
              value={dealer.customer_data_retention_in_years}
              onChange={this.handleRetentionChange}
            />
          </Form.Field>
        </Form>
      )
    );
  }
}

export default withTranslation()(LegalForm);
