import React, { Component } from "react";
import { Button, Grid, Segment } from "semantic-ui-react";
import Textfield from "./Textfield";
import Textarea from "./Textarea";
import Dropdown from "./Dropdown";
import Spinner from "./Spinner";
import Datepicker from "./Datepicker";
import Number from "./Number";
import Solution from "./Solution";
import DropdownMulti from "./DropdownMulti";
import Tyre from "./Tyre";
import Picture from "./Picture";
import Signature from "./Signature";
import { QUESTION_RESULT_STATUS } from "../../Appointments/common";

class Element extends Component {
  state = {
    disabledT: true,
    disabledA: true,
    disabledN: true,
  };

  componentDidMount() {
    const { default_configuration } = this.props;

    if (default_configuration)
      this.setState({
        disabledT: this.isStatusDisabled(QUESTION_RESULT_STATUS.OK_WITH_REMARKS, default_configuration),
        disabledA: this.isStatusDisabled(QUESTION_RESULT_STATUS.ADVISED, default_configuration),
        disabledN: this.isStatusDisabled(QUESTION_RESULT_STATUS.NECESSARY, default_configuration),
      });
  }

  isStatusDisabled = (elementStatus, config) => {
    if (!config.disabled_statuses || config.disabled_statuses.length === 0) return false;

    return config.disabled_statuses.findIndex(s => s === elementStatus) !== -1;
  };

  handleQuestionElementUpdate = update_default_configuration => {
    this.props.handleQuestionElementUpdate(this.props.index, { ...this.props.default_configuration, ...update_default_configuration });
  };

  handleUpdateDisabledStatuses = (disabledX, question_status) => {
    const { default_configuration } = this.props;

    let disabled_statuses = default_configuration.disabled_statuses ? [...default_configuration.disabled_statuses] : [];

    if (disabledX) disabled_statuses.push(question_status);
    else disabled_statuses = disabled_statuses.filter(s => s !== question_status);

    this.handleQuestionElementUpdate({ ...default_configuration, disabled_statuses });
  };

  handleToggleT = () => {
    this.setState({ disabledT: !this.state.disabledT }, () => {
      this.handleUpdateDisabledStatuses(this.state.disabledT, QUESTION_RESULT_STATUS.OK_WITH_REMARKS);
    });
  };

  handleToggleA = () => {
    this.setState({ disabledA: !this.state.disabledA }, () => {
      this.handleUpdateDisabledStatuses(this.state.disabledA, QUESTION_RESULT_STATUS.ADVISED);
    });
  };

  handleToggleN = () => {
    this.setState({ disabledN: !this.state.disabledN }, () => {
      this.handleUpdateDisabledStatuses(this.state.disabledN, QUESTION_RESULT_STATUS.NECESSARY);
    });
  };

  render() {
    let template = "";
    switch (this.props.template) {
      case "textfield":
        template = <Textfield handleQuestionElementUpdate={this.handleQuestionElementUpdate} default_configuration={this.props.default_configuration} />;
        break;

      case "textarea":
        template = <Textarea handleQuestionElementUpdate={this.handleQuestionElementUpdate} default_configuration={this.props.default_configuration} />;
        break;

      case "dropdown":
        template = <Dropdown handleQuestionElementUpdate={this.handleQuestionElementUpdate} default_configuration={this.props.default_configuration} />;
        break;

      case "dropdown_multi":
        template = <DropdownMulti handleQuestionElementUpdate={this.handleQuestionElementUpdate} default_configuration={this.props.default_configuration} />;
        break;

      case "spinner":
        template = <Spinner handleQuestionElementUpdate={this.handleQuestionElementUpdate} default_configuration={this.props.default_configuration} />;
        break;

      case "datepicker":
        template = <Datepicker handleQuestionElementUpdate={this.handleQuestionElementUpdate} default_configuration={this.props.default_configuration} />;
        break;

      case "number":
        template = <Number handleQuestionElementUpdate={this.handleQuestionElementUpdate} default_configuration={this.props.default_configuration} />;
        break;

      case "solution_element":
        template = <Solution handleQuestionElementUpdate={this.handleQuestionElementUpdate} default_configuration={this.props.default_configuration} />;
        break;

      case "tyre":
      case "truck_tyre":
      case "heavy_van_tyre":
        template = <Tyre handleQuestionElementUpdate={this.handleQuestionElementUpdate} default_configuration={this.props.default_configuration} />;
        break;

      case "picture":
        template = <Picture handleQuestionElementUpdate={this.handleQuestionElementUpdate} />;
        break;

      case "employee_signature":
      case "customer_signature":
        template = <Signature handleQuestionElementUpdate={this.handleQuestionElementUpdate} />;
        break;
      default:
        break;
    }

    return (
      <Segment>
        <Grid container>
          <Grid.Row>
            <Grid.Column>
              <Button.Group>
                <Button icon="trash" negative onClick={this.props.remove_element} />
                <Button icon="angle down" onClick={this.props.move_down} />
                <Button icon="angle up" onClick={this.props.move_up} />
              </Button.Group>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width="12">
              <h2 className="-no-margin">{this.props.name}</h2>
              <h5 className="-no-margin">{this.props.template}</h5>
            </Grid.Column>

            <Grid.Column width="4">
              <Button.Group>
                <Button onClick={this.handleToggleT} className={!this.state.disabledT ? "green" : ""}>
                  T
                </Button>
                <Button onClick={this.handleToggleA} className={!this.state.disabledA ? "yellow" : ""}>
                  A
                </Button>
                <Button onClick={this.handleToggleN} className={!this.state.disabledN ? "red" : ""}>
                  N
                </Button>
              </Button.Group>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>{template}</Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}

export default Element;
