const PREFIX = "preferences-";

export const setPreference = (key, value) => localStorage.setItem(PREFIX + key, JSON.stringify({ value }));

export const getPreference = (key, defaultValue) => {
  let item = localStorage.getItem(PREFIX + key);

  if (!item) return defaultValue !== undefined ? defaultValue : null;

  try {
    item = JSON.parse(item);
    return item.value;
  } catch (err) {
    return null;
  }
};

export const initPreference = userId => {
  const prevUserId = getPreference("user-id");

  if (prevUserId && prevUserId !== userId) {
    for (let key in localStorage) {
      if (key.startsWith(PREFIX)) delete localStorage[key];
    }
  }

  setPreference("user-id", userId);
};
