import Service from "./service";
import { handleErrorMessage } from "./util";
import _ from "lodash";
import { removeProgressBar } from "../../components/ProgressBar";
import { initPreference } from "../../util/preferences";
import { clearSessionCookie } from "../../util/common";

export const AUTH_ACTION_TYPES = {
  GET_TOKEN_SUCCESS: "auth/GET_TOKEN_SUCCESS",
  GET_TOKEN_FAIL: "auth/GET_TOKEN_FAIL",
  GET_TOKEN_VIA_GOOGLE_SUCCESS: "auth/GET_TOKEN_VIA_GOOGLE_SUCCESS",
  GET_TOKEN_VIA_GOOGLE_FAIL: "auth/GET_TOKEN_VIA_GOOGLE_FAIL",
  LOGIN: "auth/LOGIN",
  LOGIN_LOADING: "auth/LOGIN_LOADING",
  LOGIN_SUCCESS: "auth/LOGIN_SUCCESS",
  LOGIN_FAIL: "auth/LOGIN_FAIL",
  LOGOUT: "auth/LOGOUT",
  LOGOUT_SUCCESS: "auth/LOGOUT_SUCCESS",
  LOGOUT_FAIL: "auth/LOGOUT_FAIL",
  REQUEST_RESET_PASSWORD_LOADING: "auth/REQUEST_RESET_PASSWORD_LOADING",
  REQUEST_RESET_PASSWORD_SUCCESS: "auth/REQUEST_RESET_PASSWORD_SUCCESS",
  REQUEST_RESET_PASSWORD_FAIL: "auth/REQUEST_RESET_PASSWORD_FAIL",
  RESET_PASSWORD_SUCCESS: "auth/RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAIL: "auth/RESET_PASSWORD_FAIL",
  UPDATE_USER_SUCCESS: "auth/UPDATE_USER_SUCCESS",
  UPDATE_USER_FAIL: "auth/UPDATE_USER_FAIL",
  UPDATE_USER_INITIAL: "auth/UPDATE_USER_INITIAL",
  SOCKET_UPDATE_USER: "auth/SOCKET_UPDATED_USER",
};

export function loginViaQR(token, zoho_asap_jwt, appointment_id) {
  return dispatch => {
    dispatch(loginLoading());

    sessionStorage.setItem("_urlHandlerAppID", appointment_id);

    dispatch(getTokenSuccess(token, zoho_asap_jwt, false, true));
  };
}

function setSessionCookie(token) {
  document.cookie = "_sessionToken=" + token + ";path=/";
}

function setZohoAsapCookie(zoho_asap_jwt) {
  document.cookie = "_zohoAsapJwt=" + zoho_asap_jwt + ";path=/";
}

export function getZohoAsapCookie() {
  return (
    document.cookie
      .split(";")
      ?.find(x => x.includes("_zohoAsapJwt"))
      ?.trim()
      ?.replace("_zohoAsapJwt=", "") || ""
  );
}

function clearZohoAsapCookie() {
  document.cookie = "_zohoAsapJwt=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}

export function getToken(credentials) {
  return dispatch => {
    dispatch(loginLoading());

    Service.getToken(credentials)
      .then(response => {
        const { token, zoho_asap_jwt, was_logged_in } = response?.data?.data || {};

        dispatch(getTokenSuccess(token, zoho_asap_jwt, was_logged_in));
      })
      .catch(error => {
        dispatch(getTokenFail(handleErrorMessage(error)));
      });
  };
}

export function getTokenSuccess(token, zoho_asap_jwt, wasLoggedIn, isQrLogin) {
  return dispatch =>
    dispatch({
      type: AUTH_ACTION_TYPES.GET_TOKEN_SUCCESS,
      token,
      zoho_asap_jwt,
      wasLoggedIn,
      isQrLogin,
    });
}

export function getTokenFail(errorMessage) {
  return dispatch => dispatch({ type: AUTH_ACTION_TYPES.GET_TOKEN_FAIL, errorMessage });
}

export function getTokenViaGoogle(data) {
  return dispatch => {
    if (!data) {
      dispatch(getTokenViaGoogleFail("Google login error."));
      return;
    }
    dispatch(loginLoading());
    Service.getTokenViaGoogle({ id_token: data })
      .then(response => {
        const { token, zoho_asap_jwt } = response?.data?.data ? response?.data?.data : {};

        dispatch(getTokenViaGoogleSuccess(token, zoho_asap_jwt));
      })
      .catch(error => {
        dispatch(getTokenViaGoogleFail(handleErrorMessage(error)));
      });
  };
}

export function getTokenViaGoogleSuccess(token, zoho_asap_jwt) {
  return dispatch =>
    dispatch({
      type: AUTH_ACTION_TYPES.GET_TOKEN_VIA_GOOGLE_SUCCESS,
      token,
      zoho_asap_jwt,
    });
}

export function getTokenViaGoogleFail(errorMessage) {
  return dispatch => dispatch({ type: AUTH_ACTION_TYPES.GET_TOKEN_VIA_GOOGLE_FAIL, errorMessage });
}

export function getUserFromToken() {
  return (dispatch, getState) => {
    const { token, zoho_asap_jwt } = getState().auth;

    dispatch(loginLoading());
    Service.getUserFromToken(token)
      .then(response => {
        initPreference(response.data.data.id);
        dispatch(loginSuccess(response.data.data, token, zoho_asap_jwt));
      })
      .catch(error => {
        dispatch(loginFail(handleErrorMessage(error)));
      });
  };
}

export function logout() {
  return dispatch => dispatch(logoutSuccess());
}

export function loginLoading() {
  return dispatch => dispatch({ type: AUTH_ACTION_TYPES.LOGIN_LOADING });
}

export function loginSuccess(user, token, zoho_asap_jwt) {
  let uid = user ? user.id : "";
  let timestamp = new Date().getTime();
  let clientId = uid + "" + timestamp.toString();

  localStorage.setItem("isSeenChangePasswordMessage", false);

  setSessionCookie(token);
  setZohoAsapCookie(zoho_asap_jwt);

  if (user) {
    if (!localStorage.getItem("locale")) localStorage.setItem("locale", "en-GB");

    if (user.acl) user.acl = _(user.acl).sortBy("resource").groupBy("resource").value();

    localStorage.setItem(
      "_cl-last-user",
      JSON.stringify({
        email: user.email,
        first_name: user.first_name,
        id: user.id,
        language: user.language,
        last_name: user.last_name,
        username: user.username,
      })
    );
  }

  return dispatch => dispatch({ type: AUTH_ACTION_TYPES.LOGIN_SUCCESS, user, clientId });
}

export function loginFail(errorMessage) {
  return dispatch => dispatch({ type: AUTH_ACTION_TYPES.LOGIN_FAIL, errorMessage });
}

export function logoutSuccess() {
  removeProgressBar();

  if (window.ZohoHCAsap) {
    try {
      window.ZohoHCAsap.Action("hideLauncher");
    } catch (e) {}

    try {
      window.ZohoHCAsap.Logout();
    } catch (e) {}

    try {
      window.ZohoHCAsap.UnInstall();
    } catch (e) {}
  }

  clearSessionCookie();
  clearZohoAsapCookie();

  return dispatch => dispatch({ type: AUTH_ACTION_TYPES.LOGOUT_SUCCESS });
}

export function requestResetPassword(data) {
  return dispatch => {
    dispatch(loginLoading());

    Service.forgotPassword(data)
      .then(response => {
        dispatch(requestResetPasswordSuccess());
      })
      .catch(error => {
        dispatch(requestResetPasswordFail(handleErrorMessage(error)));
      });
  };
}

export function requestResetPasswordSuccess() {
  return dispatch => dispatch({ type: AUTH_ACTION_TYPES.REQUEST_RESET_PASSWORD_SUCCESS });
}

export function requestResetPasswordFail(errorMessage) {
  return dispatch => dispatch({ type: AUTH_ACTION_TYPES.REQUEST_RESET_PASSWORD_FAIL, errorMessage });
}

export function resetPassword(data) {
  return dispatch => {
    dispatch(loginLoading());

    Service.resetPassword(data)
      .then(response => {
        dispatch(resetPasswordSuccess());
      })
      .catch(error => {
        dispatch(resetPasswordFail(handleErrorMessage(error)));
      });
  };
}

export function resetPasswordSuccess() {
  return dispatch => dispatch({ type: AUTH_ACTION_TYPES.RESET_PASSWORD_SUCCESS });
}

export function resetPasswordFail(errorMessage) {
  return dispatch => dispatch({ type: AUTH_ACTION_TYPES.RESET_PASSWORD_FAIL, errorMessage });
}

export function socketUpdateUser(updatePayload) {
  return dispatch => dispatch({ type: AUTH_ACTION_TYPES.SOCKET_UPDATE_USER, updatePayload });
}

export function updateUser({ user, shouldLogout }) {
  return dispatch => {
    Service.updateUser(user)
      .then(res => {
        dispatch(updateUserSuccess(shouldLogout));
      })
      .catch(err => {
        dispatch(updateUserFail(handleErrorMessage(err)));
      });
  };
}

export function updateLocation({ location, shouldLogout }) {
  return dispatch => {
    Service.updateLocation(location)
      .then(res => {
        dispatch(updateUserSuccess(shouldLogout));
      })
      .catch(err => {
        dispatch(updateUserFail(handleErrorMessage(err)));
      });
  };
}

export function updateUserSuccess(user, shouldLogout) {
  return dispatch => dispatch({ type: AUTH_ACTION_TYPES.UPDATE_USER_SUCCESS, user, shouldLogout });
}

export function updateUserFail(errorMessage) {
  return dispatch => dispatch({ type: AUTH_ACTION_TYPES.UPDATE_USER_FAIL, errorMessage });
}

export function resetUserUpdate() {
  return dispatch => dispatch({ type: AUTH_ACTION_TYPES.UPDATE_USER_INITIAL });
}

const initialState = {
  actionType: "",
  clientId: "",
  errorMessage: null,
  hasError: false,
  isLoading: false,
  isLoggedIn: false,
  isResetPasswordSuccessful: false,
  isRequestResetPasswordSuccessful: false,
  token: "",
  wasLoggedIn: false,
  isQrLogin: false,
  appointment_id: "",
  shouldLogout: false,
  zoho_asap_jwt: "",
  user: {},
};
const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_ACTION_TYPES.LOGIN_LOADING:
      return {
        ...state,
        actionType: action.type,
        isLoading: true,
      };
    case AUTH_ACTION_TYPES.GET_TOKEN_SUCCESS:
      return {
        ...state,
        actionType: action.type,
        token: action.token,
        zoho_asap_jwt: action.zoho_asap_jwt,
        wasLoggedIn: action.wasLoggedIn,
        isQrLogin: action.isQrLogin,
        isLoading: false,
      };
    case AUTH_ACTION_TYPES.GET_TOKEN_FAIL:
      return {
        ...state,
        actionType: action.type,
        hasError: true,
        errorMessage: action.errorMessage,
        isLoading: false,
      };
    case AUTH_ACTION_TYPES.GET_TOKEN_VIA_GOOGLE_SUCCESS:
      return {
        ...state,
        actionType: action.type,
        token: action.token,
        zoho_asap_jwt: action.zoho_asap_jwt,
        isLoading: false,
      };
    case AUTH_ACTION_TYPES.GET_TOKEN_VIA_GOOGLE_FAIL:
      return {
        ...state,
        actionType: action.type,
        hasError: true,
        errorMessage: action.errorMessage,
        isLoading: false,
      };
    case AUTH_ACTION_TYPES.LOGIN_SUCCESS:
      return {
        ...state,
        actionType: action.type,
        user: action.user,
        clientId: action.clientId,
        isLoggedIn: true,
        isLoading: false,
      };
    case AUTH_ACTION_TYPES.LOGIN_FAIL:
      return {
        ...state,
        actionType: action.type,
        hasError: true,
        errorMessage: action.errorMessage,
        isLoading: false,
      };
    case AUTH_ACTION_TYPES.REQUEST_RESET_PASSWORD_LOADING:
      return {
        ...state,
        actionType: action.type,
        isLoading: true,
      };
    case AUTH_ACTION_TYPES.REQUEST_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        actionType: action.type,
        isRequestResetPasswordSuccessful: true,
        isLoading: false,
      };
    case AUTH_ACTION_TYPES.REQUEST_RESET_PASSWORD_FAIL:
      return {
        ...state,
        actionType: action.type,
        hasError: true,
        errorMessage: action.errorMessage,
        isRequestResetPasswordSuccessful: false,
        isLoading: false,
      };
    case AUTH_ACTION_TYPES.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        actionType: action.type,
        isResetPasswordSuccessful: true,
        isLoading: false,
      };
    case AUTH_ACTION_TYPES.RESET_PASSWORD_FAIL:
      return {
        ...state,
        actionType: action.type,
        hasError: true,
        errorMessage: action.errorMessage,
        isResetPasswordSuccessful: false,
        isLoading: false,
      };
    case AUTH_ACTION_TYPES.UPDATE_USER_SUCCESS:
      return {
        ...state,
        actionType: action.type,
        user: {
          ...state.user,
          ...action.user,
        },
        shouldLogout: action.shouldLogout,
        isLoading: false,
      };
    case AUTH_ACTION_TYPES.UPDATE_USER_FAIL:
      return {
        ...state,
        actionType: action.type,
        hasError: true,
        errorMessage: action.errorMessage,
        isLoading: false,
      };
    case AUTH_ACTION_TYPES.UPDATE_USER_INITIAL:
      return {
        ...state,
        actionType: action.type,
      };
    case AUTH_ACTION_TYPES.SOCKET_UPDATE_USER:
      return {
        ...state,
        actionType: action.type,
        user: {
          ...state.user,
          ...action.updatePayload,
        },
      };
    case AUTH_ACTION_TYPES.LOGOUT_SUCCESS:
      return {
        ...initialState,
        actionType: action.type,
      };
    case "persist/REHYDRATE":
      // reset the keys that should not be persisted to storage
      var incoming = action.payload;
      if (incoming && incoming.auth) {
        return {
          ...state,
          ...incoming.auth,
          isLoading: false,
          hasError: state.hasError,
          errorMessage: state.errorMessage,
          isRequestResetPasswordSuccessful: false,
        };
      }
      return state;
    default:
      return state;
  }
};

export default authReducer;
