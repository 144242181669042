import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import ReactTable, { ReactTableDefaults } from "react-table";
import { Button, Icon } from "semantic-ui-react";

import { Can } from "../";

import ACTIONS from "./actions";
import Context from "./context";

class KioskTable extends Component {
  static contextType = Context;

  render() {
    const { t } = this.props;
    const { controller, kiosks, action, isLoading, selectedKioskIndex } = this.context;
    const { deactivateKiosk, getKioskLabels, activateKiosk, setAction } = controller;

    if (isLoading && action === ACTIONS.LOAD_KIOSK) {
      return (
        <div className="Table__loading Loader-Placeholder">
          <div className="bounce1"></div>
          <div className="bounce2"></div>
          <div className="bounce3"></div>
          <section>{t("loading_kiosks").message || "Loading Kiosks"}</section>
        </div>
      );
    }

    return (
      <div className="KioskTable">
        <ReactTable
          className="ReactTable -floated-table -contained-large"
          data={kiosks}
          showPagination={false}
          showPageSizeOptions={false}
          sortable={false}
          resizable={false}
          defaultPageSize={kiosks.length}
          pageSize={kiosks.length}
          noDataText={
            <div className="Table__no-results">
              <Icon disabled name="key" size="big" />
              <p>{t("no_kiosks").message || "No Kiosks"}</p>
            </div>
          }
          column={{
            ...ReactTableDefaults.column,
            headerClassName: "ReactTable__column-header",
            className: "ReactTable__column",
          }}
          columns={[
            {
              id: "index",
              Cell: row => {
                return (
                  <div className="kiosk-table-index" style={{ backgroundColor: row.original.active ? "green" : "orange" }}>
                    {row.index + 1}
                  </div>
                );
              },
              maxWidth: 70,
            },
            {
              id: "name",
              Header: t("name").message || "Name",
              accessor: "name",
            },
            {
              id: "version",
              Header: t("version").message || "Version",
              accessor: d => d.front_end_version || null,
            },
            {
              id: "actions",
              Cell: row => {
                const isActiveRow = selectedKioskIndex === row.index;
                return (
                  <div className="kiosk-actions-container">
                    {row.original.active && (
                      <Can I="disable" the="kiosk">
                        <Button
                          size="mini"
                          icon="pause"
                          color="orange"
                          disabled={isLoading}
                          loading={isLoading && action === ACTIONS.DEACTIVATE_KIOSK && isActiveRow}
                          onClick={() => deactivateKiosk(row.index)}
                        />
                      </Can>
                    )}
                    {!row.original.active && (
                      <Can I="enable" the="kiosk">
                        <Button
                          size="mini"
                          icon="play"
                          color="green"
                          disabled={isLoading}
                          loading={isLoading && action === ACTIONS.ACTIVATE_KIOSK && isActiveRow}
                          onClick={() => activateKiosk(row.index)}
                        />
                      </Can>
                    )}
                    <Button
                      size="mini"
                      icon="list"
                      color="purple"
                      disabled={isLoading}
                      loading={isLoading && action === ACTIONS.GET_KIOSK_DETAILS && isActiveRow}
                      onClick={() => getKioskLabels(row.index)}
                    />
                    <Can I="update" the="kiosk">
                      <Button size="mini" icon="edit" color="blue" disabled={isLoading} onClick={() => setAction(ACTIONS.EDIT_MODAL_SHOW, row.index)} />
                    </Can>
                    <Can I="delete" the="kiosk">
                      <Button
                        size="mini"
                        icon="trash"
                        color="red"
                        disabled={isLoading}
                        loading={isLoading && action === ACTIONS.DELETE_KIOSK && isActiveRow}
                        onClick={() => setAction(ACTIONS.CONFIRM_DELETE_KIOSK, row.index)}
                      />
                    </Can>
                  </div>
                );
              },
            },
          ]}
        />
      </div>
    );
  }
}

export default withTranslation()(KioskTable);
