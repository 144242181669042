import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCar, faExclamationCircle, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { faCalendarCheck, faCarGarage, faUserCheck, faWrench } from "@fortawesome/pro-solid-svg-icons";
import { FILTERS } from ".";

const StatusFilter = ({ color, name, count, filter, onClick, disabled }) => {
  const renderIcon = () => {
    switch (filter) {
      case FILTERS.NEW_CAR:
        return <FontAwesomeIcon icon={faCar} />;
      case FILTERS.CAR_IN_SHOP:
        return <FontAwesomeIcon icon={faCarGarage} />;
      case FILTERS.PANIC:
        return <FontAwesomeIcon icon={faExclamationCircle} />;
      case FILTERS.INITIAL_CHECK_DONE:
        return <i className="material-icons">font_download</i>;
      case FILTERS.HANDLE_CHECK_IN:
        return <FontAwesomeIcon icon={faCalendarCheck} />;
      case FILTERS.CAR_CHECK:
        return <FontAwesomeIcon icon={faWrench} />;
      case FILTERS.DIAGNOSE:
        return <FontAwesomeIcon icon={faExclamationTriangle} />;
      case FILTERS.CUSTOMER_ANSWERED:
        return <FontAwesomeIcon icon={faUserCheck} />;
      case FILTERS.CUSTOMER_OK:
        return <i className="material-icons">face</i>;
      case FILTERS.BACK_ORDER:
        return (
          <div className="backorder-icon">
            <span>BO</span>
          </div>
        );
      default:
        return;
    }
  };

  const className = `status-filter ${disabled ? "disabled" : "clickable"}`;

  return (
    <div className={className} style={{ borderColor: color }} onClick={() => onClick(filter)}>
      <div style={{ backgroundColor: color }} className="status-filter-icon">
        {renderIcon()}
      </div>
      <div className="status-filter-count">{count}</div>
    </div>
  );
};

export default StatusFilter;
