import CustomQueryService from "./CustomQueries/service";

export const DMS_ACTION_TYPES = {
  GET_QUERY_HISTORY: "dmsv3/GET_QUERY_HISTORY",
  GET_QUERY_HISTORY_SUCCESS: "dmsv3/GET_QUERY_HISTORY_SUCCESS",
  GET_QUERY_HISTORY_FAIL: "dmsv3/GET_QUERY_HISTORY_FAIL",
  ADD_QUERY_HISTORY: "dmsv3/ADD_QUERY_HISTORY",
  WEB_SOCKET_UPDATE: "dmsv3/WEB_SOCKET_UPDATE",
  NO_UPDATE_TO_APPLY: "dmsv3/NO_UPDATE_TO_APPLY",
};

export function getQueryHistory(dealer_location_id, nextPage) {
  return (dispatch, getState) => {
    dispatch({ type: DMS_ACTION_TYPES.GET_QUERY_HISTORY });

    let page = nextPage || getState().dms.page || 1;

    CustomQueryService.listQueryHistory({ page, dealer_location_id })
      .then(res => {
        const { items: queryHistory = [], nb_pages = 0, nb_queries = 0 } = res?.data?.data || {};

        return dispatch({ type: DMS_ACTION_TYPES.GET_QUERY_HISTORY_SUCCESS, queryHistory, page, nb_pages, nb_queries });
      })
      .catch(error => {
        const errorMessage = typeof error?.response?.data !== "string" ? error.response.data.errors[0] : "Query history failed to load";
        dispatch({ type: DMS_ACTION_TYPES.GET_QUERY_HISTORY_FAIL, errorMessage });
      });
  };
}

export function addQueryToHistory(query) {
  return (dispatch, getState) => {
    let queryHistory = getState().dms.queryHistory;

    if (queryHistory) queryHistory = [{ ...query }, ...queryHistory];
    else queryHistory = [{ ...query }];

    return dispatch({ type: DMS_ACTION_TYPES.ADD_QUERY_HISTORY, queryHistory });
  };
}

export function updateQueryHistory(update) {
  return (dispatch, getState) => {
    let queryHistory = getState().dms.queryHistory;

    queryHistory.some((q, index) => {
      if (q.id === update.id) {
        queryHistory[index] = { ...queryHistory[index], ...update };
        return true;
      }

      return false;
    });

    dispatch({ type: DMS_ACTION_TYPES.WEB_SOCKET_UPDATE, queryHistory, query: update });
  };
}

export function websocketQueryUpdate(payload) {
  const { _topic, body } = payload;

  return dispatch => {
    if (!body.update) return dispatch(noUpdateToApply());

    switch (_topic) {
      case "DMSCustomQueryUpdate":
        return dispatch(updateQueryHistory(body.update));

      default:
        return dispatch(noUpdateToApply());
    }
  };
}

export function noUpdateToApply() {
  return dispatch => dispatch({ type: DMS_ACTION_TYPES.NO_UPDATE_TO_APPLY });
}

const INITIAL_STATE = {
  query: {},
  queryHistory: [],
  page: 0,
  nb_pages: 0,
  nb_queries: 0,
  isLoading: false,
  actionType: "",
  errorMessage: "",
  webSocketUpdate: null,
};

const customQueryReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DMS_ACTION_TYPES.GET_QUERY_HISTORY:
      return {
        ...state,
        actionType: action.type,
        isLoading: true,
      };
    case DMS_ACTION_TYPES.GET_QUERY_HISTORY_SUCCESS:
      return {
        ...state,
        actionType: action.type,
        queryHistory: action.queryHistory,
        page: action.page,
        nb_queries: action.nb_queries,
        nb_pages: action.nb_pages,
        isLoading: false,
      };
    case DMS_ACTION_TYPES.GET_QUERY_HISTORY_FAIL:
      return {
        ...state,
        actionType: action.type,
        errorMessage: action.errorMessage,
        isLoading: false,
      };
    case DMS_ACTION_TYPES.ADD_QUERY_HISTORY:
      return {
        ...state,
        actionType: action.type,
        queryHistory: action.queryHistory,
      };
    case DMS_ACTION_TYPES.NO_UPDATE_TO_APPLY:
      return {
        ...state,
        actionType: action.type,
      };
    case DMS_ACTION_TYPES.WEB_SOCKET_UPDATE:
      return {
        ...state,
        actionType: action.type,
        queryHistory: action.queryHistory,
        query: action.query,
      };
    case "persist/REHYDRATE":
      const incoming = action.payload;
      if (incoming && incoming.dms) {
        return {
          ...state,
          ...incoming.dms,
          actionType: state.actionType,
          errorMessage: state.errorMessage,
          queryHistory: state.queryHistory,
        };
      }
      return state;
    default:
      return state;
  }
};

export default customQueryReducer;
