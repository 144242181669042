import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import ReactTable, { ReactTableDefaults } from "react-table";
import moment from "moment";
import { Popup } from "semantic-ui-react";

import Service from "./service";
import { setAlert } from "../App/store";
import { UserInput } from "../../components";

import "./ShareboxDashboard.scss";

export const SHAREBOX_TRIGGER = {
  SHAREBOX_TRIGGER_LOCKER_SENSOR_OPEN_ACTIVE_RESERVATION: 1,
  SHAREBOX_TRIGGER_RECEIVER_DROP_KEY: 2,
  SHAREBOX_TRIGGER_EMPLOYEE_PICK_UP_KEY: 3,
  SHAREBOX_TRIGGER_EMPLOYEE_DROP_KEY: 4,
  SHAREBOX_TRIGGER_RECEIVER_PICK_UP_KEY: 5,
};
export const SHAREBOX_RESULT = {
  ERROR: 1,
  REG_NUMBER_DO_NOT_MATCH: 2,
  COMPLETED: 3,
};

class ShareboxDashboard extends Component {
  state = {
    logs: [],
    nb_pages: null,
    page: 0,
    loading: false,
  };

  componentDidMount() {
    this.getShareboxLog();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.globalState.selectedLocation.id !== this.props.globalState.selectedLocation.id) this.getShareboxLog();
  }

  getShareboxLog = page => {
    if (!page) page = 1;
    else if (page === this.state.page || this.state.isLoadingTyreLeads) return; // react table is going to fire this incorrectly

    this.setState({ loading: true }, () => {
      Service.getShareboxLog({ dealer_location_id: this.props.globalState.selectedLocation.id, page })
        .then(res => {
          const { nb_pages, items: logs } = res?.data?.data || { nb_pages: null, items: [] };
          this.setState({ nb_pages, logs, page, loading: false });
        })
        .catch(error => {
          this.setState({ loading: false }, () => {
            const errorMessage = error?.response?.data?.errors?.length
              ? error.response.data.errors[0]
              : this.props.t("error_loading_sharebox_logs").message || "Error loading sharebox logs";

            this.props.setAlert({
              type: "error",
              title: errorMessage,
            });
          });
          console.log("Error getting sharebox logs", error);
        });
    });
  };

  renderLogsTable = () => {
    const { logs, nb_pages, page, loading } = this.state;
    const { t } = this.props;

    if (loading && !logs?.length) {
      return (
        <div className="Table__loading Loader-Placeholder">
          <div className="bounce1"></div>
          <div className="bounce2"></div>
          <div className="bounce3"></div>
          <section>{t("loading_sharebox_logs").message || "Loading Sharebox Logs"}</section>
        </div>
      );
    }

    if (!logs?.length) return <div className="Table__no-results">{t("no_logs_found").message || "No logs found"}</div>;

    return (
      <ReactTable
        className="ReactTable -sharebox-logs-table -floated-table -contained-large"
        data={logs || []}
        showPagination={nb_pages > 1}
        page={page - 1}
        pages={nb_pages ?? -1}
        resizable={false}
        showPageSizeOptions={false}
        sortable={false}
        minRows={0}
        defaultPageSize={logs?.length}
        manual
        loading={loading}
        onFetchData={state => this.getShareboxLog(state.page + 1)}
        nextText={t("next").message || "Next"}
        previousText={t("previous").message || "Previous"}
        pageText={t("page").message || "Page"}
        ofText={t("of").message || "of"}
        renderPageJump={({ onChange, value, onBlur, onKeyPress, inputType, pageJumpText }) => (
          <div className="-pageJump">
            <UserInput
              aria-label={pageJumpText}
              type={inputType}
              onChange={evt => {
                onChange(evt);

                const newPage = evt.target.value - 0;
                if (!Number.isNaN(newPage) && newPage > 0 && newPage <= nb_pages) this.getShareboxLog(newPage);
              }}
              value={value}
              onBlur={onBlur}
              onKeyPress={onKeyPress}
            />
          </div>
        )}
        column={{
          ...ReactTableDefaults.column,
          headerClassName: "ReactTable__column-header -text-ellipsis",
          className: "ReactTable__column",
        }}
        columns={[
          {
            Header: t("trigger_id").message || "Trigger ID",
            id: "trigger_id",
            width: 250,
            accessor: d =>
              (
                <Popup
                  content={t(Object.keys(SHAREBOX_TRIGGER)[d.trigger_id - 1]?.toLowerCase()).message}
                  trigger={<span className="-text-elipsis">{t(Object.keys(SHAREBOX_TRIGGER)[d.trigger_id - 1]?.toLowerCase()).message}</span>}
                />
              ) || null,
          },
          {
            Header: t("result_id").message || "Result ID",
            id: "result_id",
            width: 150,
            accessor: d =>
              (
                <Popup
                  content={t(Object.keys(SHAREBOX_RESULT)[d.result_id - 1]?.toLowerCase()).message}
                  trigger={<span className="-text-elipsis">{t(Object.keys(SHAREBOX_RESULT)[d.result_id - 1]?.toLowerCase()).message}</span>}
                />
              ) || null,
          },
          { Header: t("reason").message || "Reason", accessor: "reason" },
          { Header: t("locker_no").message || "Locker No.", accessor: "locker_no" },
          { Header: t("reservation_id").message || "Reservation ID", accessor: "reservation_id" },
          { Header: t("subscription_id").message || "Subscription ID", accessor: "subscription_id" },
          {
            Header: t("subscription_name").message || "Subscription Name",
            id: "subscription_name",
            width: 220,
            accessor: d => <Popup content={d.subscription_name} trigger={<span className="-text-ellipsis">{d.subscription_name}</span>} />,
          },
          {
            Header: t("event_type").message || "Event Type",
            id: "event_type",
            width: 210,
            accessor: d => <Popup content={d.event_type} trigger={<span className="-text-ellipsis">{d.event_type}</span>} />,
          },
          {
            Header: t("event_timestamp").message || "Event Timestamp",
            id: "event_timestamp",
            accessor: d => (
              <Popup
                content={moment(d.event_timestamp).format("YYYY-MM-DD HH:mm")}
                trigger={<span className="-text-ellipsis">{moment(d.event_timestamp).format("YYYY-MM-DD HH:mm")}</span>}
              />
            ),
          },
          {
            Header: t("created_at").message || "Created At",
            id: "created_at",
            accessor: d => (
              <Popup
                content={moment(d.created_on).format("YYYY-MM-DD HH:mm")}
                trigger={<span className="-text-ellipsis">{moment(d.created_on).format("YYYY-MM-DD HH:mm")}</span>}
              />
            ),
          },
        ]}
      />
    );
  };
  render() {
    const { t } = this.props;

    return (
      <div className="SahreBoxDashboard">
        <h1>{t("sharebox_dashboard").message || "Sharebox Dashboard"}</h1>
        {this.renderLogsTable()}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { globalState: state.global };
};

const mapDispatchToProps = dispatch => ({
  setAlert: alertOptions => dispatch(setAlert(alertOptions)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ShareboxDashboard));
