import React, { Component } from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { extractAllNameFields } from "../../../util/common";

const COLORS = ["#278ecf", "#4bd762", "#ff402c", "#ff9416", "#d42ae8", "#535ad7", "#83bfff", "#ffca1f", "#6edb8f", "#ffe366", "#ffc266", "#8784db"];

class StackedBarChart extends Component {
  render() {
    const { data, colors, t, unit } = this.props;

    return (
      <ResponsiveContainer width="100%" height={450} className={"full-width-margin-auto"}>
        <BarChart data={data} margin={{ top: 20, right: 30, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis unit={unit} domain={unit === "%" ? [0, 100] : ["auto", "auto"]} />
          <Tooltip />
          <Legend />

          {extractAllNameFields(data)
            .filter(k => k !== "name")
            .map((k, i) => {
              const item = colors.find(col => (t(col.name).message || col.name) === k);
              const color = item ? item.color : null;

              let stack = "stack_a";
              let name = k;

              if (k.startsWith("stack_a.")) {
                name = k.replace("stack_a.", "");
              } else if (k.startsWith("stack_b.")) {
                stack = "stack_b";
                name = k.replace("stack_b.", "");
              }

              return <Bar stackId={stack} name={name} key={i} dataKey={k} fill={color || COLORS[i % COLORS.length]} unit={unit} isAnimationActive={false} />;
            })}
        </BarChart>
      </ResponsiveContainer>
    );
  }
}

export default StackedBarChart;
