import React from "react";
import { Input, Button, Form, Message } from "semantic-ui-react";
import ResetPasswordHeader from "./ResetPasswordHeader";

const ResetPassword = ({
  handleNavigateToAction,
  className,
  error,
  errorMessage,
  hasEmailError,
  isLoading,
  isRequestSuccessful,
  onInputChange,
  onSubmit,
  resetPasswordEmail,
  warning,
  t,
}) => {
  return (
    <div className={"ResetPassword " + className}>
      <ResetPasswordHeader t={t} handleNavigateToAction={handleNavigateToAction} />

      <Form warning={warning} error={!isRequestSuccessful && error}>
        {!isRequestSuccessful && (
          <span>
            <Form.Field>
              <label>{t("email").message || "Email"}</label>
              <Input
                name="email"
                placeholder={t("email").message || "Email"}
                autoFocus
                error={hasEmailError}
                size="large"
                value={resetPasswordEmail}
                onChange={onInputChange}
              />
            </Form.Field>

            <Button type="submit" onClick={onSubmit} loading={isLoading} positive fluid size="large" className="--margin-bottom-10">
              {t("reset_password").message || "Reset password"}
            </Button>

            <Message warning header={t("please_check_your_input").message || "Check your input"} />
            <Message error header={t("request_problem").message || "There was a problem processing your request"} content={t(errorMessage).message || errorMessage} />
          </span>
        )}

        {isRequestSuccessful && (
          <Message color="green" header={t("email_with_instraction_sent").message || "An email with instructions has been sent to the address you provided."} />
        )}
      </Form>
    </div>
  );
};

export default ResetPassword;
