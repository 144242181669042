import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Form, Header, Icon, Modal, Segment, Button, Message } from "semantic-ui-react";

import UserInput from "../UserInput";

const defaultMCCConfiguration = {
  brand: "",
  vin_prefix: "",
  dealer_code: "",
};

class MCCConfiguration extends Component {
  state = {
    mccDealerLocationCode: { ...defaultMCCConfiguration },
    showModal: false,
    validationError: false,
  };

  handleAddNew = () => {
    this.setState({ mccDealerLocationCode: { ...defaultMCCConfiguration }, showModal: true });
  };

  handleOpenMCCModal = (item, index) => {
    this.setState({ mccDealerLocationCode: { ...item, index }, showModal: true });
  };

  handleCloseMCCModal = () => this.setState({ showModal: false, mccDealerLocationCode: { ...defaultMCCConfiguration }, validationError: false });

  handleInputChange = (_e, data) => {
    this.setState({ mccDealerLocationCode: { ...this.state.mccDealerLocationCode, [data.name]: data.value } });
  };

  handleSave = () => {
    const { mccDealerLocationCode } = this.state;
    const { location, onLocationChange } = this.props;
    let mcc_codes = location.mcc_codes?.length ? [...location.mcc_codes] : [];

    if (!mccDealerLocationCode.brand || !mccDealerLocationCode.vin_prefix || !mccDealerLocationCode.dealer_code) {
      this.setState({ validationError: true });
      return;
    }

    if (mccDealerLocationCode.hasOwnProperty("index")) {
      mcc_codes[mccDealerLocationCode.index] = mccDealerLocationCode;
    } else {
      mcc_codes.push(mccDealerLocationCode);
    }

    onLocationChange({ ...location, mcc_codes });
    this.handleCloseMCCModal();
  };

  handleDelete = index => {
    const { location, onLocationChange } = this.props;
    let mcc_codes = location.mcc_codes?.length ? [...location.mcc_codes] : [];

    mcc_codes.splice(index, 1);
    onLocationChange({ ...location, mcc_codes });
    this.handleCloseMCCModal();
  };

  renderModal = () => {
    const { showModal, validationError, mccDealerLocationCode } = this.state;
    const { t } = this.props;

    return (
      <Modal open={showModal} onClose={this.handleCloseMCCModal}>
        <Modal.Header>
          {mccDealerLocationCode.hasOwnProperty("index")
            ? t("update_location_code").message || "Update location code"
            : t("add_location_code").message || "Add location code"}
        </Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field required error={validationError && !mccDealerLocationCode.brand}>
              <label htmlFor="brand">{t("brand").message || "Brand"}</label>
              <UserInput name="brand" value={mccDealerLocationCode.brand} onChange={this.handleInputChange} />
            </Form.Field>

            <Form.Field required error={validationError && !mccDealerLocationCode.vin_prefix}>
              <label htmlFor="vin_prefix">{t("vin_prefix").message || "VIN Prefix"}</label>
              <UserInput name="vin_prefix" value={mccDealerLocationCode.vin_prefix} onChange={this.handleInputChange} />
            </Form.Field>

            <Form.Field required error={validationError && !mccDealerLocationCode.dealer_code}>
              <label htmlFor="dealer_code">{t("dealer_code").message || "Dealer code"}</label>
              <UserInput name="dealer_code" value={mccDealerLocationCode.dealer_code} onChange={this.handleInputChange} />
            </Form.Field>

            {validationError && (
              <Message negative>
                <p>{t("required_fields_error").message || "Please fill all the required fields"}</p>
              </Message>
            )}
          </Form>
        </Modal.Content>
        <Modal.Actions>
          {mccDealerLocationCode.hasOwnProperty("index") && (
            <Button color="red" onClick={() => this.handleDelete(mccDealerLocationCode.index)} floated="left">
              <Icon name="trash" className="-no-margin" />
            </Button>
          )}

          <Button onClick={this.handleCloseMCCModal} color="grey">
            {t("close").message || "Close"}
          </Button>

          <Button color="green" onClick={this.handleSave}>
            <Icon name="checkmark" /> {mccDealerLocationCode.hasOwnProperty("index") ? t("update").message || "Update" : t("save").message || "Save"}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };

  render() {
    const { t, location, readOnly } = this.props;

    return (
      <Segment>
        <Header as="h5">
          <Icon name="wrench" />
          <Header.Content>{t("mcc_configuration").message || "MCC Configuration"}</Header.Content>
        </Header>
        {!readOnly && (
          <div className="MCCConfiguration_header">
            <Button onClick={this.handleAddNew}>{t("add_new").message || "Add new"}</Button>
          </div>
        )}

        <Segment>
          {!location.mcc_codes?.length && <div className="MCCConfiguration_no-codes">{t("no_location_codes_added").message || "No location codes added"}</div>}

          <div className="MCCConfiguration_container">
            {location.mcc_codes?.length > 0 &&
              location.mcc_codes.map((c, i) => (
                <div
                  className={`MCCConfiguration_container-item ${readOnly ? "MCCConfiguration_container-item-disabled" : ""}`}
                  onClick={readOnly ? null : () => this.handleOpenMCCModal(c, i)}
                  key={i}
                >
                  {`${c.brand} - ${c.vin_prefix} - ${c.dealer_code}`}
                </div>
              ))}
          </div>
        </Segment>
        {this.renderModal()}
      </Segment>
    );
  }
}

export default withTranslation()(MCCConfiguration);
