import axios from "axios";

import ENV from "../../util/env-config";
import { getSessionCookie } from "../../util/common";

class Service {
  static requestConfig() {
    return {
      headers: {
        Authorization: `Bearer ${getSessionCookie()}`,
        Pragma: "no-cache",
      },
    };
  }

  static getCar(carID) {
    return axios.post(
      ENV.apiBaseB + "/cars/details",
      {
        id: carID,
      },
      this.requestConfig()
    );
  }

  static getCarAppointments(carID) {
    return axios.post(
      ENV.apiBaseB + "/cars/get_checks",
      {
        id: carID,
      },
      this.requestConfig()
    );
  }

  static setMCCCar(requestData) {
    return axios.post(ENV.apiBaseB + "/cars/mcc_set_car", requestData, this.requestConfig());
  }

  static getRDW(requestData) {
    return axios.post(ENV.apiBaseB + "/cars/rdw", requestData, this.requestConfig());
  }

  static appendAttachment(requestData) {
    return axios.post(ENV.apiBaseB + "/cars/append_attachment", requestData, this.requestConfig());
  }

  static deleteAttachment(url) {
    return axios.post(ENV.apiBaseB + "/cars/delete_attachment", { url }, this.requestConfig());
  }

  static getCustomersByCar(requestData) {
    return axios.post(ENV.apiBaseB + "/customers/list_by_car", requestData, this.requestConfig());
  }

  static getPinsByCarId(requestData) {
    return axios.post(ENV.appointmentApiBase + "/pins/list_by_car_id", requestData, this.requestConfig());
  }

  static getSnoozesByCarId(requestData) {
    return axios.post(ENV.appointmentApiBase + "/snoozes/list_by_car_id", requestData, this.requestConfig());
  }

  static uploadAttachment(requestData) {
    return axios.post(ENV.apiBaseB + "/files/upload_car_attachment", requestData, this.requestConfig());
  }

  static getDBBLink(requestData) {
    return axios.post(ENV.apiBaseB + "/appointments/portal_dbb", requestData, this.requestConfig());
  }

  static deleteInStorageTires(requestData) {
    return axios.post(ENV.apiBaseB + "/tyres/remove_storage_set", requestData, this.requestConfig());
  }
}

export default Service;
