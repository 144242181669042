import React from "react";
import { Grid } from "semantic-ui-react";

const ResetPasswordHeader = ({ t, handleNavigateToAction }) => {
  return (
    <div>
      <Grid>
        <Grid.Column width={10} className="-no-padding-left">
          <h2>{t("reset_password").message || "Reset password"}</h2>
        </Grid.Column>
        <Grid.Column width={6} className="-padding-top-20 -no-padding-right -text-right">
          <a
            href="#!/login"
            style={{ cursor: "pointer" }}
            onClick={event => {
              event.preventDefault();
              handleNavigateToAction("/login");
              return false;
            }}
          >
            {t("back_to_login").message || "Back to login"}
          </a>
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default ResetPasswordHeader;
